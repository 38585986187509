import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScreenWidthSizeFe } from '../model/screens/ScreenWidthSize';
import { ScreenHeightSizeFe } from '../model/screens/ScreenHeightSize';
import { RouteServiceFe } from '../route/RouteServiceFe';
import { RouteFe } from '../route/RouteFe';
import { RoutesFe } from '../route/RoutesFe';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

public menuCollapsed = null;
private previousScreenWidthSize: ScreenWidthSizeFe = null;
private previousScreenHeightSize: ScreenHeightSizeFe = null;
public currentScreenWidthSize: ScreenWidthSizeFe = null;
public currentScreenHeightSize: ScreenHeightSizeFe = null;

public menuCollapsedSubject = new Subject<boolean>();
public screenWidthSizeSubject = new Subject<ScreenWidthSizeFe>();
public screenHeightSizeSubject = new Subject<ScreenHeightSizeFe>();

  navOpened: any;

  constructor(private routeService: RouteServiceFe) {
    this.resize(window.innerWidth, window.innerHeight, true);
    routeService.activeRouteSubject.subscribe((activeRoute: RouteFe | undefined) => {
      if(activeRoute == RoutesFe.WORKSPACES && this.currentScreenWidthSize.isXSmallSize()) {
        this.closeNav(true)
      }
    })
  }

  public resize(width: number, height: number, initiating: boolean = false) {
    this.previousScreenWidthSize = (this.currentScreenWidthSize) ? this.currentScreenWidthSize : ScreenWidthSizeFe.toScreenSize(width)
    this.currentScreenWidthSize = ScreenWidthSizeFe.toScreenSize(width);
    this.currentScreenHeightSize = ScreenHeightSizeFe.toScreenSize(height);

    if (initiating && width < ScreenWidthSizeFe.WIDTH_MEDIUM.widthRangeStart) {
      this.closeNav(true)
    } else
    if (this.currentScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && this.previousScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && !this.menuCollapsed) {
    } else if (this.currentScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && this.previousScreenWidthSize.greaterOrEqualTo(ScreenWidthSizeFe.WIDTH_SMALL) && !this.menuCollapsed) {
        this.closeNav()
    } else if (this.currentScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && this.menuCollapsed) {

    } else if (this.currentScreenWidthSize.greaterOrEqualTo(ScreenWidthSizeFe.WIDTH_SMALL) && !this.menuCollapsed) {

    } else if (this.currentScreenWidthSize.greaterOrEqualTo(ScreenWidthSizeFe.WIDTH_SMALL) && this.previousScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && this.menuCollapsed) {
        this.openNav()
    } 

    if (this.currentScreenWidthSize != this.previousScreenWidthSize) {
      this.screenWidthSizeSubject.next(this.currentScreenWidthSize)
    }

    if (this.currentScreenHeightSize != this.previousScreenHeightSize) {
      this.screenHeightSizeSubject.next(this.currentScreenHeightSize)
    }

  }

  public closeNav(forced: boolean = false): void {
    if (forced || (!forced && this.currentScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && !this.menuCollapsed)) {
      this.menuCollapsed = true
      this.menuCollapsedSubject.next(this.menuCollapsed)
    }     
  }

  public openNav(): void {
    if ((this.currentScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && this.menuCollapsed) || (this.currentScreenWidthSize.greaterOrEqualTo(ScreenWidthSizeFe.WIDTH_SMALL) && this.previousScreenWidthSize.smallerThan(ScreenWidthSizeFe.WIDTH_MEDIUM) && this.menuCollapsed)) {
        this.menuCollapsed = false
        this.menuCollapsedSubject.next(this.menuCollapsed)
    } 
  }  

  public toggleNav(): void {
    this.menuCollapsed = !this.menuCollapsed;
    this.menuCollapsedSubject.next(this.menuCollapsed);
  }

}
