import { FileDataFe } from "../file/FileDataFe";
import { AutomatedFeedInfoFe } from "./AutomatedFeedInfoFe";

export class AutomatedFeedSystemFe {

  imgSrc: any;

  public constructor(
    public name: string,
    public logoStorageId: string | null,
    public automatedFeeds: AutomatedFeedInfoFe[]
  ){}

  copy(): AutomatedFeedSystemFe{
    let system = new AutomatedFeedSystemFe(this.name, this.logoStorageId, this.automatedFeeds);
    system.imgSrc = this.imgSrc;
    return system;
  }

}


