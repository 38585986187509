import { Component, OnDestroy, OnInit } from '@angular/core';
import {  BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { DataGridServiceInterface } from '../../service/DataGridServiceInterface';
import { DataGridTableData } from '../../model/DataGridTableData';
import { DataService } from '../../service/data.service';
import { MultiEntityDataGridIntegrationService } from '../../service/MultiEntityDataGridIntegrationService';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataGridRow } from '../../model/DataGridRow';
import * as UUID from 'uuid';
import { DataGridStatus } from '../../model/DataGridStatus';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class RowInRefTableHandlerModalComponent implements OnInit, OnDestroy{

  table= new DataGridTableData();
  allRefTables : DataGridTableData[] = [];

  parentInfo:{[key:string]:string} = {};  //stores key of the parent table {childKey:parentKey}
  isANewEntry:{[key:string]:boolean} = {};
  isSelectingFromTable:{[key:string]:boolean} = {};
  refTableService:{[key:string]:MultiEntityDataGridIntegrationService} = {};

  tableData = new FormGroup({});
  selectedRowSub = new Subscription();
  selectedRows:{[key:string]:DataGridRow} = {};
  
  newRowAdded$ = new Subject<DataGridRow>();

  type = 'add';
  updatedRow$ = new Subject<DataGridRow>();

  allRowIds:any[] = [];
  rowHandlerModalResultRow!: {
    row: DataGridRow ,
    rowWithAllExactValues:DataGridRow ,//rowWithAllExactValues : contains data of row with exact values(i.e. not  rowIDs of ref cols) of all cols(nested ref included)
    allRowIds:any[], //allRowIds : contains data of row with rowIDs of all cols(nested ref included)
    updatedParentRowWithAllExactValues:DataGridRow,
    rowInMainTable:DataGridRow,
    allAddedRowsInfo:{[key:string]:DataGridRow};
  };
  
  parentTableToBeUpdated!:DataGridTableData;
  allAddedRowsInfo:{[key:string]:DataGridRow} = {};

  constructor(public modalRef: BsModalRef, 
              private modalService: BsModalService,
              private dataService: DataService) {
  }

  ngOnInit() {
    const formGroup = new FormGroup({});
    this.table.dataSchema.forEach((col,index)=>{
      if(this.type === 'add'){
        formGroup.addControl(`col${index}`, new FormControl('',[Validators.required]));
      }else{
        formGroup.addControl(`col${index}`, new FormControl(this.dataService.detailsOfRowToBeHandled.allExactValues[index],[Validators.required]));
      }
    })
    this.tableData.addControl(this.table.key!, formGroup);

    const recursiveFun = (key:string, parentKey:string )=>{
      this.parentInfo[key] = parentKey;
      this.isANewEntry[key] = this.type.includes("add") ? true : false;
      this.isSelectingFromTable[key] = false;
      const table = this.allRefTables.find(table => table.key === key);
    
      const formGroup = new FormGroup({});
      table?.dataSchema.forEach((col,index)=>{
        if(this.type.includes('add')){
          formGroup.addControl(`col${index}`, new FormControl('',[Validators.required]));
        }else{
          formGroup.addControl(`col${index}`, new FormControl(refValues.splice(0,1)[0],[Validators.required]));
        }
      })
      this.tableData.addControl(table?.key!, formGroup);
      table?.referenceSchema.forEach((refTable, index) => recursiveFun(refTable.referenceKey , key));
    }
    
    
    const refValues = this.dataService.detailsOfRowToBeHandled.allExactValues.slice(this.table.dataSchema.length);   
    this.table.referenceSchema.forEach((refTable, index) => {
      recursiveFun(refTable.referenceKey , this.table.key! );
    })

    this.isANewEntry[this.table.key!] = true;

    
  }

  addNew(key:string){
    const recursiveFun = (key:string)=>{
      this.isANewEntry[key] = true;
      this.isSelectingFromTable[key] = false;
      this.allRefTables.find(table => table.key === key)?.referenceSchema.forEach((x)=>recursiveFun(x.referenceKey));
    };

    recursiveFun(key);
    this.selectedRowSub.unsubscribe();
    
  }

  selectFromTable(key:string){
    const table= this.allRefTables.find(table => table.key === key)!;
    
    const updatedTable = this.createADeepCopyOfTable(table);

    // this.refTables.forEach((table,index)=>{
      // updatedTable.push();
      updatedTable.rows.forEach(row=>{
        const valuesOfDataSchemaCols = row.values.splice(0,table.dataSchema.length);
        row.values.push(...valuesOfDataSchemaCols);
      })
    // })


    this.isANewEntry[key] = false;
    this.isSelectingFromTable[key] = true;

    this.refTableService[key] = new MultiEntityDataGridIntegrationService();
    let refService = this.refTableService[key];
    refService.database =  updatedTable;
    refService.database.pageNumber=1;

    refService.currentPage = new DataGridTableData()
    refService.currentPage.name=refService.database.name;
    refService.currentPage.key =  refService.database.key;
    refService.currentPage.dataSchema=refService.database.dataSchema;
    refService.currentPage.referenceSchema=refService.database.referenceSchema;
    refService.currentPage.ordered=refService.database.ordered;
    refService.currentPage.rowCount=refService.database.rowCount;
    refService.currentPage.pageNumber=refService.database.pageNumber;
    refService.currentPage.pageSize=refService.database.pageSize;
    refService.currentPage.rows=refService.database.rows.slice(0,refService.database.pageSize);

    
    refService.database.referenceSchema.forEach((x)=>{
      this.addRefTable(x.referenceKey, key);
    })

    //deleting already selected rowData
    if(this.selectedRows[key]){
      delete this.selectedRows[key];
    }

    this.selectedRowSub= this.dataService.selectedRow$.pipe(first()).subscribe((isSelected)=>{
      if(isSelected){
        const selectedRowData = this.dataService.selectedRowData;
        
        refService.database.dataSchema.forEach((col,index)=>{
          this.tableData.controls[key].patchValue({['col'+index] : selectedRowData.row.values[index]});
        })
                
        refService.referencedTables.forEach((refTable, tableNo)=>{
          refTable.dataSchema.forEach((col,index)=>{
            const number = refService.database.dataSchema.length + tableNo*refTable.dataSchema.length + index;
            this.tableData.controls[refTable.key!].patchValue({['col'+index] : selectedRowData.row.values[number]});
          });
        });

        this.selectedRows[key] = selectedRowData.row;
      }
    })

  }

  createADeepCopyOfTable(table:DataGridTableData){
    const copiedTable  = new DataGridTableData()
    copiedTable.name = table.name;
    copiedTable.dataSchema = [...table.dataSchema];
    copiedTable.referenceSchema = [...table.referenceSchema];
    copiedTable.rows = []
    table.rows.forEach(row => {
      copiedTable.rows.push({
        rowId: row.rowId,
        status: row.status,
        values: [...row.values],
        ordinal: row.ordinal
      })
    });

    copiedTable.ordered = table.ordered;
    copiedTable.pageNumber = table.pageNumber;
    copiedTable.pageSize = table.pageSize;
    copiedTable.key = table.key;
    
    return {...copiedTable};
  }

  addRefTable(key:string , mainTableKey:string){
    const table = this.allRefTables.find(table=>table.key == key)!;

    const updatedRefTable = this.createADeepCopyOfTable(table);
    updatedRefTable.rows.forEach(row=>{
      const valuesOfDataSchemaCols = row.values.splice(0,table.dataSchema.length);
      row.values.push(...valuesOfDataSchemaCols);
    })

    this.isANewEntry[key] = false; //setting the subsequent ref table to be not new
    this.isSelectingFromTable[key] = false; //setting the subsequent ref table to be not selecting from table
    this.refTableService[mainTableKey].referencedTables.push(updatedRefTable);
    table.referenceSchema.forEach((x)=>{this.addRefTable(x.referenceKey , mainTableKey);});
  }

  handleRow(){
  
    this.addRowFunction(this.table.key!, this.table);
  
    const row = this.type == "add" ? this.table.rows[this.table.rows.length-1]:this.dataService.detailsOfRowToBeHandled.row
    let rowWithAllExactValues = {...row};
    rowWithAllExactValues.values = [];
    
    Object.values(this.tableData.value).forEach((table:any)=>{  
      Object.values(table).forEach((colValue:any)=>{
        rowWithAllExactValues.values.push(colValue);
      })
    });

    let updatedParentRowWithAllExactValues = new DataGridRow();

    if(this.type == "add Parent"){
      updatedParentRowWithAllExactValues = this.isANewEntry[this.parentTableToBeUpdated.key!] ? 
        {...this.parentTableToBeUpdated.rows[this.parentTableToBeUpdated.rows.length-1]}
        :{...this.parentTableToBeUpdated.rows.find(x=>x.rowId == row.values[this.table.dataSchema.length-1])!}

      updatedParentRowWithAllExactValues.values =[...rowWithAllExactValues.values.slice(this.table.dataSchema.length)];
    }

    if(this.type!="add Parent"){
      this.table.dataSchema.forEach(()=>{
        this.allRowIds.push(row.rowId);
      })
    }

    for(let refTable of this.allRefTables){
      if(this.isANewEntry[refTable.key!]){
        refTable.dataSchema.forEach((col,index)=>{
          this.allRowIds.push(refTable.rows[refTable.rows.length-1].rowId);
        });
      }else if(this.isSelectingFromTable[refTable.key!]){
        this.allRowIds=[...this.allRowIds , ...this.dataService.selectedRowData.allRowIds];
        break;
      }else if(this.type == "edit"){
        this.allRowIds = [...this.allRowIds , ...this.dataService.detailsOfRowToBeHandled.allRowIds.slice(this.table.dataSchema.length)];
        break;
      }
    };
    
           
    this.rowHandlerModalResultRow = {
      row, 
      rowWithAllExactValues , 
      updatedParentRowWithAllExactValues,
      allRowIds: this.allRowIds,
      rowInMainTable: this.dataService.detailsOfRowToBeHandled.rowInMainTable,
      allAddedRowsInfo: this.allAddedRowsInfo
    };
    this.closeModal();

  }

  addRowFunction(key:string, table : DataGridTableData){
    if(this.isANewEntry[key]){
      let refSchemaValues: any[] = [];
      table.referenceSchema.forEach((x, tableNo)=>{
        const refTable = this.allRefTables.find(table => table.key === x.referenceKey)!
        if(this.isANewEntry[x.referenceKey]){
          const addedRow = this.addRowFunction(x.referenceKey, refTable);
          refSchemaValues.push(addedRow?.rowId); //pushed row Id for refSchema
        }else if(this.isSelectingFromTable[x.referenceKey]){
          refSchemaValues.push(this.selectedRows[x.referenceKey]?.rowId);
        }else{
          //applicable only for main table
          const number = this.table.dataSchema.length + tableNo*refTable.dataSchema.length;
          refSchemaValues.push(this.dataService.detailsOfRowToBeHandled.allRowIds[number]);
        }
      });

      if(key === this.table.key && (this.type=="edit" || this.type=="add Parent")){
        this.dataService.detailsOfRowToBeHandled.row.status = DataGridStatus.MODIFIED;
        this.dataService.detailsOfRowToBeHandled.row.values = [...Object.values(this.tableData.value[key]), ...refSchemaValues];
      }else{
        const row = {
          rowId: UUID.v4(),
          values: [...Object.values(this.tableData.value[key]), ...refSchemaValues],
          status: DataGridStatus.ADDED,
          ordinal: table.rows.length + 1
        }
    
        table.rows.push(row);
        this.allAddedRowsInfo[table.key!]=row; 
  
        return row;
      }

    }
    return;
  }

  closeModal(): void{
    this.modalRef.hide();
    document.body.classList.remove('modal-open');
  }

  ngOnDestroy(): void {
    this.selectedRowSub.unsubscribe();
    this.newRowAdded$.unsubscribe();
  }
}
