export class PredefinedNumberOptionFe {
  unit: string;
  measurementKey: string;
  value: number;

  constructor();
  constructor(unit: string, measurementKey: string, value?: number);
  constructor(unit?: string, measurementKey?: string, value?: number) {
    this.unit = unit;
    this.measurementKey = measurementKey;
    this.value = value;
  }

  public static fromJsonObj(obj: any): PredefinedNumberOptionFe {
    return new PredefinedNumberOptionFe(obj.unit, obj.measurementKey, obj.value)
  }
}
