<div class="card-body pt-0" id="accordionWrap">
  <div role="tablist" aria-multiselectable="true" style="overflow-y: auto" class="card px-3 pt-3 mb-1 border-0">
    <div class="accordion accordion-icon-rotate mb-2 row-design">
      <a [id]="'item'" class="p-0 collapsed my-2" data-bs-toggle="collapse" [href]="'#accordion0'" aria-expanded="true" [attr.aria-controls]="'accordion0'" style="max-height: 45px" (click)="isUserOpen = !isUserOpen">
        <div class="row mx-auto w-100 py-1 leftBorder">
          <div class="col-11">
            <div class="d-flex align-items-center h-100">
              <i class="fs-3 la la-user"></i>
              <h6 class="m-0 icon-text">{{ locale('locale_key.pages.profile.user_information_section.title') }}</h6>
            </div>
          </div>
          <div class="col-1 float-end">
            <div class="d-flex flex-row ms-auto h-100 w-100">
              <i class="mt-3 ms-auto float-end mb-3" [ngClass]="isUserOpen ? 'la la-angle-up' : 'la la-angle-down'"></i>
            </div>
          </div>
        </div>
      </a>
      <div id="accordion0" role="tabpanel" data-bs-parent="#accordionWrap" attr.aria-labelledby="item" class="collapse mt-0 pt-0">
        <div class="card-content">
          <div class="card-body px-1">
            <form>
              <div class="form-group ps-3">
                <div class="row ps-3 mb-2">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-12">
                    <label class="pe-2">{{ locale('locale_key.general.user_information.first_name') }}</label>
                  </div>

                  <div class="col-6">
                    <input type="text" class="form-control col-3" value="{{ firstName }}" readonly style="max-width: 250px" />
                  </div>
                </div>
                <div class="row ps-3">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-12">
                    <label class="pe-2">{{ locale('locale_key.general.user_information.last_name') }}</label>
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control col-3" value="{{ lastName }}" readonly style="max-width: 250px" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div role="tablist" aria-multiselectable="true" style="overflow-y: auto" class="card px-3 pt-1 mb-1 border-0">
    <div class="accordion accordion-icon-rotate mb-2 row-design">
      <a [id]="'item'" class="p-0 collapsed" data-bs-toggle="collapse" [href]="'#accordion'" aria-expanded="true" [attr.aria-controls]="'accordion'" style="max-height: 45px" (click)="isPasswordOpen = !isPasswordOpen">
        <div class="row mx-auto w-100 py-1 leftBorder">
          <div class="col-11">
            <div class="d-flex align-items-center h-100">
              <i class="fs-3 la la-shield"></i>
              <h6 class="m-0 icon-text">{{ locale('locale_key.pages.profile.security_section.title') }}</h6>
            </div>
          </div>
          <div class="col-1 float-end">
            <div class="d-flex flex-row ms-auto h-100 w-100">
              <i class="mt-3 ms-auto float-end mb-3" [ngClass]="isPasswordOpen ? 'la la-angle-up' : 'la la-angle-down'"></i>
            </div>
          </div>
        </div>
      </a>
      <div [id]="'accordion'" role="tabpanel" data-bs-parent="#accordionWrap" attr.aria-labelledby="item" class="collapse mt-0 pt-0">
        <div class="card-content bg-transparent">
          <div class="card-body px-1">
            <form [ngClass]="{'border-b': !passChange}">
              <div class="form-group ps-3">
                <div class="d-flex flex-row mb-2 mt-3" *ngIf="!passChange">
                  <label class="col-md-3 col-12 me-0 my-auto">{{ locale('locale_key.pages.profile.security.change_password') }}</label>
                  <input type="password" class="form-control col-3 ms-2" placeholder="*********" style="max-width: 250px" disabled [ngClass]="{ 'my-2': screenSize.isXSmallSize() }" />
                  <div class="d-flex flex-row align-items-center mx-2">
                    <button type="button" class="btn primary-button custom-button ms-2" (click)="passChange = true" style="position: relative; bottom: 0px">
                      {{ locale('locale_key.general.buttons.confirm') }}
                    </button>
                    <div *ngIf="passwordChanged">
                      <span class="mx-3"> {{ locale('locale_key.pages.profile.security.password.change_success') }}</span>
                      <i class="la la-check-circle-o grad-green me-1 fs-4"></i>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="passChange">
                  <div>
                    <span class="mb-0 password-info" for="currentPassword">{{ locale("locale_key.pages.profile.security.password.minimum_requirements") }}</span>
                  </div>
                  <div class="d-flex flex-row mb-2 mt-3">
                    <label class="col-2">{{ locale('locale_key.pages.profile.security.current_password') }}</label>
                    <div class="input-group">
                      <input
                        type="password"
                        class="form-control custom-input col-3"
                        placeholder="{{ locale('locale_key.pages.profile.security.current_password') }}"
                        [formControl]="currentPass"
                        [ngClass]="{ 'is-invalid': currentPass.errors && currentPass.touched }"
                      />
                      <div *ngIf="currentPass.errors && currentPass.touched" class="invalid-feedback">
                        <div *ngIf="currentPass.errors.required">
                          {{ passwordMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <label class="col-2" for="password">{{ locale('locale_key.pages.profile.security.new_password') }}</label>
                    <div class="input-group">
                      <input
                        type="password"
                        class="form-control custom-input col-3"
                        placeholder="{{ locale('locale_key.pages.profile.security.new_password') }}"
                        [formControl]="newPass"
                        [ngClass]="{ 'is-invalid': newPass.errors && newPass.touched }"
                      />
                      <div *ngIf="newPass.errors && newPass.touched" class="invalid-feedback">
                        <div *ngIf="newPass.errors.required">{{ passwordMessage }}</div>
                        <div *ngIf="newPass.errors.pattern">{{ passwordPatternMessage }}</div>
                        <div *ngIf="newPass.errors.previouslyUsed"></div> 
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row mb-2">
                    <label class="col-2" for="confirm-password">{{ locale('locale_key.pages.profile.security.confirm_password') }}</label>
                    <div class="input-group">
                      <input
                        type="password"
                        class="form-control custom-input col-3"
                        placeholder="{{ locale('locale_key.pages.profile.security.confirm_password') }}"
                        [formControl]="confirmPass"
                        [ngClass]="{ 'is-invalid': confirmPass.errors && confirmPass.touched }"
                      />
                      <div *ngIf="confirmPass.errors && confirmPass.touched" class="invalid-feedback">
                        <div *ngIf="confirmPass.errors.required">{{ passwordMessage }}</div>
                        <div *ngIf="confirmPass.errors.notMatch">{{ locale("locale_key.general.validation_message.new_password_confirm_not_match") }}</div>
                        <div *ngIf="confirmPass.errors.previouslyUsed">{{ locale("locale_key.pages.profile.security.password.used_previously") }}</div> 
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </form>
            <div *ngIf="!passChange" class="col-12 ms-3 my-3">
              <h5>{{ locale('locale_key.pages.profile.security.multi_factor_authentication') }}</h5>
              <div class="card p-1 me-4">
                <div class="card-content">
                  <div class="card-body px-3">
                    <div class="row">
                      <div class="col-12">
                        <ng-container *ngFor="let userPhone of userPhones; index as i">
                          <div class="row border-b d-flex flex-row justify-content-between pb-1 mb-1">
                            <div class="">
                              <label class="ms-0 me-2 mt-1 d-block my-0 py-0"> <i class="flag-icon me-2" [ngClass]="getFlagClass(userPhone.countryCode)"></i>{{ userPhone.number }} </label>
                            </div>
                            <div class="">
                              <label class="mt-1 d-block my-0 py-0"> <i class="la la-check-circle-o success me-2"></i>{{ locale('locale_key.pages.profile.security.multi_factor_authentication.phone_number_successfully_added_message') }} </label>
                            </div>
                            <div class="row">
                              <label class="ms-0 me-2 mt-1 d-block my-0 py-0 truncate"> {{ locale('locale_key.pages.profile.security.multi_factor_authentication.phone_number_association_to_company') }}: </label>
                              <input type="text" class="mt-1 d-block my-auto py-0 form-control truncate" placeholder="johns@sustainlab.co" readonly style="width: fit-content; max-width: 250px" />
                            </div>
                            <div class="text-left mb-0 pb-0">
                              <a class="me-auto mb-0 pb-0" (click)="deletePhoneNumber(i)">
                                <i class="la la-trash-o m-auto mb-0 pb-0 me-auto" style="position: relative; top: 4px"></i>
                              </a>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="row mx-0 px-0" *ngIf="false">
                        <div class="col-2 text-end ms-0 ps-0" *ngIf="userPhones.length == 0 || addNewPhone">
                          <div class="dropdown ms-0 ps-0">
                            <button class="btn dropdown-toggle ms-0 ps-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span><i class="flag-icon" [ngClass]="getFlagClass(selectedCountryCode)"></i> {{ selectedCountryCode }}</span>
                            </button>
                            <div class="dropdown-menu m-0" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item m-0" (click)="setSelectedCountryCode('+44')"><i class="fi fi-gb"></i> +44 </a>
                              <a class="dropdown-item m-0" (click)="setSelectedCountryCode('+46')"><i class="fi fi-se"></i> +46 </a>
                              <!-- add more country codes/flags here as needed -->
                            </div>
                          </div>
                        </div>
                        <div class="col-10" *ngIf="userPhones.length == 0 || addNewPhone">
                          <div class="row ms-3 d-flex flex-row justify-content-start align-center">
                            <input class="form-control w-50 my-auto ms-0" type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required #phoneNumberInput />
                            <button class="btn primary-button ms-2 my-auto mb-1" (click)="addPhoneNumber()">
                              {{ locale('locale_key.pages.profile.security.multi_factor_authentication.button.add_phone_number') }}
                            </button>
                          </div>
                        </div>
                        <div class="col-12 ms-0 ps-0" *ngIf="!addNewPhone">
                          <a class="me-auto" (click)="addNewPhoneNumber()"><i class="la la-plus me-2"></i>{{ locale('locale_key.pages.profile.security.multi_factor_authentication.button.add_new_phone_number') }}</a>
                        </div>
                      </div>
                      <div class="intl_tel_input make-col p-2">
                        <div class="phone_group_wrapper m-2" [formGroup]="phoneForm">
                          <ngx-intl-tel-input
                            [cssClass]="'custom'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.Sweden"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="false"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="phone"
                            formControlName="phone"
                          ></ngx-intl-tel-input>
                        </div>
                        <button class="btn primary-button my-auto mb-1 add-number-btn" (click)="addPhoneNumber()" [ngClass]="screenSize.isXSmallSize() ? 'ms-0' : 'ms-2'">
                          {{ locale('locale_key.pages.profile.security.multi_factor_authentication.button.add_phone_number') }}
                        </button>
                      </div>
                      <div class="col-12 mt-2 ms-0 ps-0" *ngIf="!addNewPhone">
                        <p>{{ locale('locale_key.pages.profile.security.multi_factor_authentication.phone_registration.sent_message') }}</p>
                        <div class="row ms-0 d-flex flex-row justify-content-start align-center">
                          <input type="text" class="form-control my-auto" placeholder="Enter verification code" [(ngModel)]="verificationCode" style="width: 25%" />
                          <button class="btn primary-button custom-button ms-2 my-auto mb-1">{{ locale('locale_key.pages.profile.security.multi_factor_authentication.button.verify') }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer float-end p-1" style="background: transparent; border: none" *ngIf="passChange">
            <ng-container *ngIf="!inProgress">
              <button type="button" class="btn secondary-button custom-button me-3 mb-1" (click)="passChange = false">{{ locale('locale_key.general.buttons.cancel') }}</button>
              <button type="button" class="btn primary-button custom-button me-3 mb-1" (click)="changePassword()" [disabled]="!isPasswordOnChange()">{{ locale('locale_key.general.buttons.save') }}</button>
            </ng-container>
            <div class="ball-pulse loader-primary" *ngIf="inProgress">
              <div style="background-color: var(--main-dark-color1)"></div>
              <div style="background-color: var(--main-dark-color1)"></div>
              <div style="background-color: var(--main-dark-color1)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div role="tablist" aria-multiselectable="true" class="card px-3 pt-1 mt-1 border-0">
    <div class="accordion accordion-icon-rotate mb-2 row-design">
      <a [id]="'item2'" class="p-0 collapsed" data-bs-toggle="collapse" [href]="'#accordion2'" aria-expanded="true" [attr.aria-controls]="'accordion2'" style="max-height: 45px" (click)="isSettingsOpen = !isSettingsOpen">
        <div class="row mx-auto w-100 py-1 leftBorder">
          <div class="col-11">
            <div class="d-flex align-items-center h-100">
              <i class="fs-3 la la-language"></i>
              <h6 class="m-0 icon-text">{{ locale('locale_key.pages.profile.language_settings_section.title') }}</h6>
            </div>
          </div>
          <div class="col-1 float-end">
            <div class="d-flex flex-row ms-auto h-100 w-100">
              <i class="mt-3 ms-auto float-end mb-3" [ngClass]="isSettingsOpen ? 'la la-angle-up' : 'la la-angle-down'"></i>
            </div>
          </div>
        </div>
      </a>
      <div [id]="'accordion2'" role="tabpanel" data-bs-parent="#accordionWrap" attr.aria-labelledby="item2" class="collapse mt-0 pt-0 mb-4">
        <div class="card-content">
          <div class="card-body px-1">
            <div class="col-12">
              <div class="d-flex align-items-center ps-3">
                <div class="row w-100 align-items-center">
                  <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                    <p>{{ locale('locale_key.pages.settings.platform_language.label') }}</p>
                    <div id="lang-dropdown" class="btn-group me-3 mb-1">
                      <button class="btn dropdown-toggle custom-lang-dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span *ngIf="selectedLang"><i class="{{ selectedLang.icon }} me-2"></i>{{ selectedLang.label }}</span>
                      </button>
                      <div class="dropdown-menu">
                        <ng-container *ngFor="let language of languages">
                          <a class="dropdown-item m-0 px-3" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedLang?.code != language.code" (click)="changePlatformLanguage(language)"
                            ><i class="{{ language.icon }} me-2"></i> {{ language.label }}
                          </a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-9 col-md-9 col-lg-8 mt-3">
                    <p class="m-0">{{ locale('locale_key.pages.settings.platform_language.message') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center ps-3">
                <div class="row w-100 pt-5 align-items-center">
                  <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                    <p class="mb-1">{{ locale('locale_key.pages.settings.email_language.label') }}</p>
                    <div id="lang-dropdown" class="btn-group me-3 mb-1">
                      <button class="btn dropdown-toggle custom-lang-dropdown" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span *ngIf="selectedEmailLang"><i class="{{ selectedEmailLang.icon }} me-2"></i>{{ selectedEmailLang.label }}</span>
                      </button>
                      <div class="dropdown-menu">
                        <ng-container *ngFor="let language of languages">
                          <a class="dropdown-item m-0 px-3" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedEmailLang?.code != language.code" (click)="changeEmailLanguage(language)"
                            ><i class="{{ language.icon }} me-2"></i> {{ language.label }}
                          </a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-9 col-md-9 col-lg-8 mt-3">
                    <p class="m-0">{{ locale('locale_key.pages.settings.email_language.message.language') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer float-end p-2" style="background: transparent; border: none">
            <ng-container *ngIf="!inProgress">
              <button [disabled]="isSaveButtonDisabled" type="button" class="btn primary-button custom-button me-3 mb-1" (click)="changeLanguage()">{{ locale('locale_key.general.buttons.save') }}</button>
            </ng-container>
            <div class="d-flex flex-row align-items-center" *ngIf="inProgress">
              <div class="d-flex flex-row align-items-center m-2" *ngIf="!isPlatfomAndEmailChanges()">
                <span class="p-2 email-saving_message">
                  {{ getChangedLanguageMessage()}}
                </span>
                <i class="fs-3 me-2" [ngClass]="getChangedLanguageFlag()"></i> {{ getChangedLanguageLabel() }}
              </div>
              <div class="d-flex flex-row align-items-center m-2" *ngIf="isPlatfomAndEmailChanges()">
                <span class="p-2 email-saving_message">
                  {{ locale('locale_key.pages.settings.email_language.message.setting_platform_language_to') }}
                </span>
                <i class="fs-3 {{selectedLang.icon}} me-2"></i> {{ selectedLang.label }}
                <span class="p-2 email-saving_message">
                  {{ locale('locale_key.pages.kpi_library.kpi_wizard.summary.and') }}
                  {{ locale('locale_key.pages.settings.email_language.message.setting_email_language_to') }}
                </span>
                <i class="fs-3 {{selectedEmailLang.icon}} me-2"></i> {{ selectedEmailLang.label }}
              </div>
              <div class="ball-pulse loader-primary">
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
