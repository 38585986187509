import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestFe } from 'src/app/model/data-suppliers/request/RequestFe';
import { TaskFe } from 'src/app/model/data-suppliers/task/TaskFe';
import { AbstractActivityFe } from 'src/app/model/data-suppliers/timeline/AbstractActivityFe';
import { AcceptSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe';
import { CloseRequestActivityFe } from 'src/app/model/data-suppliers/timeline/CloseRequestActivityFe';
import { CreateRequestActivityFe } from 'src/app/model/data-suppliers/timeline/CreateRequestActivityFe';
import { RejectSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe';
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe';
import { SubmitMessageActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitMessageActivityFe';
import { SubmitMessageActivity_AddFe } from 'src/app/model/data-suppliers/timeline/add/SubmitMessageActivityFe_AddFe';
import { AffiliationRoleFe } from 'src/app/model/user/AffiliationRoleFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { RequestServiceFe } from 'src/app/services/RequestServiceFe';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { IdUtil } from 'src/app/utils/IdUtil';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})

export class ChatBoxComponent extends AbstractLanguageComponent implements OnInit {

  @Input() requestTask: RequestFe | TaskFe;
  @Output() chatClosed = new Subject<boolean>();
  
  requestTitle: string;
  message = null;
  loadingInProgress = false;
  
  constructor(private stateService: StateServiceFe, languageService: LanguageService, public requestService: RequestServiceFe) { 
    super(languageService);
  }

  ngOnInit(): void {
    this.requestTitle = this.requestTask.timelineItems[0].requestGroupLabel
  }

  ngAfterViewInit(): void {
    this.scrollDown();
  }

  ngOnChanges(): void {
    this.scrollDown();
  }

  closeChat() {
    this.chatClosed.next(true);
  }
  
  async sendMessage() {
    this.loadingInProgress = true;
    if (this.isDO() && this.requestTask instanceof TaskFe) {
      let activity = new SubmitMessageActivity_AddFe(IdUtil.next(), this.requestTask.managementCompanyId, this.message, false, this.requestTask.id, this.requestTask.requestGroupId, this.requestTitle);
      await this.stateService.addTaskTimelineItem(activity, this.requestTask);
    } else {
      let activity = new SubmitMessageActivity_AddFe(IdUtil.next(), this.stateService.activeWorkspace.companyId, this.message, false, this.requestTask.id, this.requestTask.requestGroupId, this.requestTitle);
      await this.stateService.addRequestTimelineItem(activity, this.requestTask.requestGroupId, this.requestTask.id);
    }
    this.message = null;
    this.loadingInProgress = false;
  }

  isSubmitterDO(activity) {
    return activity.submitterAffId == this.requestTask.assigneeAffId;
  }

  scrollDown() {
    let el = document.querySelector(".scrollContainer.chatBox");
    el.scrollTop = el.scrollHeight;
  }

  isDO() {
    return this.stateService.activeWorkspace.affiliationRole == AffiliationRoleFe.DATA_OWNER;
  }

}
