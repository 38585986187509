import { AbstractSignInMethodFe } from "./AbstractSignInMethodFe"
import { SignInMethodProviderFe } from "./SignInMethodProviderFe"

export class AffMicrosoftSignInMethodFe extends AbstractSignInMethodFe{

  tenantId: string
  
  constructor (
    isEnabled: boolean,
    tenantId: string
  ) {
    super (
      SignInMethodProviderFe.MICROSOFT,
      isEnabled
    )
    this.tenantId = tenantId
  }
  
  public static fromTransfer (transfer: any): AffMicrosoftSignInMethodFe {
    let singInMethod = new AffMicrosoftSignInMethodFe(
      transfer.isEnabled,
      transfer.tenantId
    )
    return singInMethod
  }
}