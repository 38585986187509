import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesFe } from 'src/app/route/RoutesFe';
import { AlertServiceFe } from 'src/app/services/AlertServiceFe';
import { RouterFe } from 'src/app/route/RouterFe';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex';
@Component({
  selector: 'app-resetforgotpassword',
  templateUrl: './resetforgotpassword.component.html',
  styleUrls: ['./resetforgotpassword.component.scss']
})
export class ResetforgotpasswordComponent extends AbstractLanguageComponent implements OnInit {
  
  resetPasswordForm = new FormGroup({
    newPassword: new FormControl("", [Validators.required, Validators.pattern(ValidationRegex.NewPasswordRegex)]),
    confirmPassword:new FormControl("", [Validators.required, Validators.pattern(ValidationRegex.NewPasswordRegex)]),

  });
  forbiddenEmails: any;
  successMessage: string;
  loading = false;
  IsvalidForm = true;
  submitted = false;
  Message: string;
  validLogin: boolean;
  uuid: any;
  constructor(private formBuilder: FormBuilder, private router: Router, 
    private route: ActivatedRoute, private backendService: RouterFe, 
    private stateService: StateServiceFe, private alertService: AlertServiceFe, private ErrorsFe: ErrorsFe, languageService: LanguageService) {
    super(languageService)
    this.route.params.subscribe(
      (params) => {
        this.uuid = params
      },
      (error) => { console.log(error) })

  }

  async ngOnInit() {
    try {
      let valid = await this.backendService.isResetPasswordValid(this.uuid.uuid)
      if (valid) {
        this.uuid= this.uuid
      } else {
        this.alertService.showError('The link for reset password has expired, please send a new request!')
      }  
    } catch(err) {
      let knownError = this.ErrorsFe.matchError(err)
      if (knownError) {
        if (knownError == OpenErrorsFe.RESE_PASS_LINK_EXPIRED_ERROR) {
          this.alertService.showError(OpenErrorsFe.RESE_PASS_LINK_EXPIRED_ERROR.message)
        }
      } 
    }

    this.resetPasswordForm 
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    this.loading = false;
    if (this.resetPasswordForm.invalid){
      return;
    }
    if (this.resetPasswordForm.controls.newPassword.value !== this.resetPasswordForm.controls.confirmPassword.value) {
      this.resetPasswordForm.controls.newPassword.setErrors({ notMatch: true });
      this.resetPasswordForm.controls.confirmPassword.setErrors({ notMatch: true });
      return;
    }
    var passwordRequest = {
      newPassword: this.resetPasswordForm.value.newPassword,
      confirmPassword: this.resetPasswordForm.value.confirmPassword,
      uuid: this.uuid
    }
    this.loading = true;
    try {
      let reset = await this.backendService.resetPassword(passwordRequest)
      if (reset) {
        this.loading = false;
        this.validLogin = true;
        this.alertService.showSuccess('Your password was successfully reset!')
        this.router.navigate([RoutesFe.LOGIN.fullPath()]);
      } else {
        this.loading = false;
        this.validLogin = false;  
        this.alertService.showError('It was not possible to change your password now, please try again!')
      }
    } catch (err) {
      this.loading = false;
      this.validLogin = false;
      
      let knownError = this.ErrorsFe.matchError(err.error)
      if (knownError) {
        if (knownError === OpenErrorsFe.PASSWORD_IS_ALREADY_USED) {
          this.resetPasswordForm.get("newPassword").setErrors({ previouslyUsed: true });
          this.resetPasswordForm.get("confirmPassword").setErrors({ previouslyUsed: true });
        } else if (knownError == OpenErrorsFe.RESE_PASS_LINK_EXPIRED_ERROR) {
          this.alertService.showError(knownError.message)
        } else {
          this.alertService.showError('It was not possible to change your password now, please try again!')
        }
      } 
    }
  }

}

