import { CompanyFe } from "./CompanyFe"
import { PartnerInvitationFe } from "./PartnerInvitationFe"


export class PartnerInvitationnIdClashCompanyFe {
  invitation: PartnerInvitationFe
  idClashCompany: CompanyFe | undefined

  constructor(
    invitation: PartnerInvitationFe,
    idClashCompany: CompanyFe | undefined
    ) {
      this.invitation = invitation
      this.idClashCompany = idClashCompany
    }

    public static fromTransfer(transfer: any): PartnerInvitationnIdClashCompanyFe {
      let clashedCompany = transfer['idClashCompany'] ? CompanyFe.fromTransfer(transfer['idClashCompany']) : undefined;
      let details = new PartnerInvitationnIdClashCompanyFe(
        PartnerInvitationFe.fromTransfer(transfer['invitation']),
        clashedCompany
      )
      return details
    }
}