import { ActivityTypeFe } from "../../timeline/ActivityTypeFe";
import { AbstractActivity_AddFe } from "./AbstractActivity_AddFe";

export class RejectSubmissionActivity_AddFe extends AbstractActivity_AddFe {
  rejectedActivityId: string

  constructor(
    id: string,
    rejectedActivityId: string,
    message: string | null,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string
  ) {
    super(
      id,
      ActivityTypeFe.REJECT_SUBMISSION,
      message,
      false,
      requestTaskId,
      requestGroupId,
      requestGroupLabel
    )
    this.rejectedActivityId = rejectedActivityId;
  } 

}
