import { ActivityTypeFe } from "../ActivityTypeFe"
import { AnswerFe } from "../answer/AnswerFe"
import { AbstractActivity_AddFe } from "./AbstractActivity_AddFe"

export class ConnectSectionsActivity_AddFe extends AbstractActivity_AddFe {

  constructor(
    id: string,
    public managementCompanyId: string,
    public submittedAnswerActivityId: string,
    public connectedAnswers: AnswerFe[],
    message: string | null,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string,
  ) {
    super(
      id,
      ActivityTypeFe.CONNECT_SECTIONS,
      message,
      false,
      requestTaskId,
      requestGroupId,
      requestGroupLabel
    )
  }

}
