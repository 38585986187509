import { SharedResourceTypeFe } from "../SharedResourceTypeFe"
import { AbstractSharedResource_AddFe } from "./AbstractSharedResource_AddFe"
import { RecipientInfo_AddFe } from "./RecipientInfo_AddFe"

export class SharedDashboard_AddFe extends AbstractSharedResource_AddFe {

  constructor(
    objectId: string,
    recipients: RecipientInfo_AddFe[],
    sharedId: string
  ) {
    super(
      objectId,
      SharedResourceTypeFe.DASHBOARD,
      recipients,
      sharedId
    )
  }
  
}