<div class="supplier-modal">
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white">{{ mode == "add" ? locale('locale_key.pages.clients.invite_manager_wizard.invite_manager.title') : locale('locale_key.pages.clients.invite_manager_wizard.edit_manager.title') }}</h4>
    <button type="button" class="btn close text-white" (click)="closeModal()">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300;" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-min-h scroll-example" style="border-top:none;">
    <div class="col-lg-12" *ngIf="activeFormNo == 2 || activeFormNo == 3">
      <div class="row mt-3">
        <div class="container">
          <div class="progress-container mb-3 mx-5 justify-content-center" [ngClass]="screenSize.isXSmallSize() ? 'd-flex flex-column' : ''">
            <ng-container *ngIf="!screenSize.isXSmallSize()">
              <div class="col-4 text-center d-flex flox-row align-items-center">
                <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(1) }">
                  <span *ngIf="!isCompleted(1)">1</span>
                  <span *ngIf="isCompleted(1)" class="fs-4 la la-check text-white"></span>
                </div>
                <h6 class="ms-4 mt-2">{{locale('locale_key.pages.clients.invite_manager_wizard.manager_info')}}</h6>
              </div>
                <div class="col-1 align-self-center">
                <div>
                  <span class="fs-4 la la-angle-right pt-1"></span>
                </div>
              </div>
              <div class="col-3 text-center d-flex flox-row align-items-center">
                <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(2) }">
                  <span *ngIf="!isCompleted(2)">2</span>
                  <span *ngIf="isCompleted(2)" class="fs-4 la la-check text-white"></span>
                </div>
                <h6 class="mt-2">{{locale('locale_key.pages.clients.invite_manager_wizard.invitation_email.title')}}</h6>
              </div>
            </ng-container>
            <ng-container *ngIf="screenSize.isXSmallSize()">
              <div class="d-flex flex-row justify-content-center mb-1">
                <div class="d-block text-center">
                  <div class="col stepper text-center" *ngFor="let step of steps; let i = index" [class.active-stepper]="currentActive === i +1 || step.completed">
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center mb-1">
                <h6 class="text-center my-auto">{{ steps[currentActive-1].title }} ({{currentActive}}/{{steps.length}})</h6>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <hr class="mt-0 pt-0 mx-3" />
    </div>
    <form class="task-form" [formGroup]="form2" *ngIf="activeFormNo == 2">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <i class="la la-user fs-5 my-auto me-2"></i>
              <h4 class="me-2 my-auto">{{locale('locale_key.pages.clients.invite_manager_wizard.manager_info')}}</h4>
              <i class="la la-info-circle dropdown-toggle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
              <div class="dropdown-menu arrow p-50 info-popover">
                <p>{{locale('locale_key.pages.clients.invite_manager_wizard.manager_details.desc')}}</p>
              </div>
            </div>
            <div class="row px-3 mt-3">
              <div class="col-12">
                <div class="form-group">
                  <label>{{locale('locale_key.pages.clients.invite_manager_wizard.first_name')}}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{locale('locale_key.pages.clients.invite_manager_wizard.first_name.placeholder')}}"
                    required
                    formControlName="managerFirstName"
                    [ngClass]="{
                      'is-invalid': form2.get('managerFirstName').errors && (form2.get('managerFirstName').touched || form2.get('managerFirstName').dirty)
                    }"
                  />
                  <div *ngIf="form2.get('managerFirstName').errors && (form2.get('managerFirstName').touched || form2.get('managerFirstName').dirty)" class="invalid-feedback">
                    <div *ngIf="form2.get('managerFirstName').errors.required">
                      {{ firstNameMessage }}
                    </div>
                    <div *ngIf="form2.get('managerFirstName').hasError('pattern')">
                      {{ lastNameMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>{{locale('locale_key.pages.clients.invite_manager_wizard.last_name')}}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{locale('locale_key.pages.clients.invite_manager_wizard.last_name.placeholder')}}"
                    required
                    formControlName="managerLastName"
                    [ngClass]="{
                      'is-invalid': form2.get('managerLastName').errors && (form2.get('managerLastName').touched || form2.get('managerLastName').dirty)
                    }"
                  />
                  <div *ngIf="form2.get('managerLastName').errors && (form2.get('managerLastName').touched || form2.get('managerLastName').dirty)" class="invalid-feedback">
                    <div *ngIf="form2.get('managerLastName').errors.required">
                      {{ lastNameMessage }}
                    </div>
                    <div *ngIf="form2.get('managerLastName').hasError('pattern')">
                      {{ lastNameMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>{{locale('locale_key.pages.clients.invite_manager_wizard.email_address')}}*</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.clients.invite_manager_wizard.email_address.placeholder')}}"
                    required
                    formControlName="managerEmail"
                    [ngClass]="{
                      'is-invalid': form2.get('managerEmail').errors && (form2.get('managerEmail').touched || form2.get('managerEmail').dirty)
                    }"
                  />
                  <div *ngIf="form2.get('managerEmail').errors && (form2.get('managerEmail').touched || form2.get('managerEmail').dirty)" class="invalid-feedback">
                    <div *ngIf="form2.get('managerEmail').errors.required">
                      {{ emailMessage }}
                    </div>
                    <div *ngIf="form2.get('managerEmail').hasError('pattern')">
                      {{ lowerCaseEmail }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>{{locale('locale_key.pages.clients.invite_manager_wizard.company')}}* </label>
                  <select
                    formControlName="companyId"
                    class="form-select form-control"
                    [ngClass]="{
                      'is-invalid': form2.get('companyId').errors && form2.get('companyId').touched
                    }"
                  >
                    <option *ngFor="let company of managementCompanies" [value]="company.companyId">{{ company.companyName }}</option>
                  </select>
                  <div *ngIf="form2.get('companyId').errors && form2.get('companyId').touched" class="invalid-feedback">
                    <div *ngIf="form2.get('companyId').errors.required">{{ locale("locale_key.pages.manager-entry.company_is_required") }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form class="task-form" [formGroup]="form3" *ngIf="activeFormNo == 3">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <span class="fs-3 la la-envelope-o me-1"></span>
              <h6 class="m-0">{{ locale('locale_key.pages.clients.invite_manager_wizard.invitation_email.title') }}</h6>
              <i class="fs-5 la la-info-circle m-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
              <div class="dropdown-menu arrow p-2 info-popover">
                <p>{{ locale('locale_key.pages.data.supplier.invite_partner.message') }}</p>
              </div>
            </div>
            <div class="row mt-3 px-3">
              <div class="col-12 mt-1 mb-5">
                <div class="form-group d-flex flex-row align-items-center">
                  <div id="lang-dropdown" class="btn-group me-3 mb-1" style="width: 150px">
                    <button class="form-select border border-secondary" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span *ngIf="selectedLang"><i class="{{ selectedLang.icon }} me-2"></i>{{ selectedLang.label }}</span>
                    </button>
                    <div class="dropdown-menu">
                      <ng-container *ngFor="let language of languages">
                        <a class="dropdown-item m-0 px-3" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedLang?.code != language.code" (click)="updateLanguage(language)"
                          ><i class="{{ language.icon }} me-2"></i> {{ language.label }}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="includeEnglish" (change)="onIncludeEnglishChange($event)" [disabled]="disableEnglish" [checked]="includeEnglish" />
                    <label class="form-check-label ml-2" style="font-size: 12px"> {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.include_en_translation') }} </label>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 p-3">
                <div class="form-group row">
                  <div class="col-12">
                    <p>{{ locale('locale_key.pages.data.supplier.invite_partner.email_template.message') }}</p>
                    <button (click)="openModal()" class="text-decoration-underline bg-white border-0 p-0">{{ locale('locale_key.pages.dashboard.email_template.edit.link') }}</button>
                  </div>
                  <confirm-cancel-modal
                    (actionClicked)="navigateToEditTemplate()"
                    (cancelClicked)="onCancel()"
                    [isVisible]="showNavigateModal"
                    [headerText]="modalHeaderText"
                    [bodyText]="modalBodyText"
                    [cancelButtonText]="cancelButtonText"
                    [actionButtonText]="actionButtonText"
                  ></confirm-cancel-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.general.buttons.cancel')}}</button>
      <button class="btn secondary-button custom-button" (click)="prev()" [hidden]="activeFormNo == 2">{{locale('locale_key.general.buttons.previous')}}</button>
      <button class="btn btn-add text-white custom-button" [disabled]="!form2.valid" (click)="activeFormNo == 2 && form2.valid ? getLink() : activeFormNo == 3 ? addNewManager() : next()">
        {{ activeFormNo == 3 ? locale('locale_key.pages.clients.invite_manager_wizard.button.add_manager') : locale('locale_key.general.buttons.next') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</div>
