import { RecipientInfo_AddFe } from '../shared/add/RecipientInfo_AddFe'

export class RecipientInfoFe {
  objectSharedId: string
  email: string
  firstName: string
  lastName: string
  companyName: string
  affId: string | null
  companyId: string | null
  accessType: string
  emailLanguage: string
  includeEnglish: boolean

  confirmRemoval = false

  constructor(objectSharedId: string, email: string, firstName: string, lastName: string, companyName: string, affId: string | null, companyId: string | null, accessType: string, emailLanguage: string, includeEnglish: boolean) {
    this.objectSharedId = objectSharedId
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.companyName = companyName
    this.affId = affId
    this.companyId = companyId
    this.accessType = accessType
    this.emailLanguage = emailLanguage
    this.includeEnglish = includeEnglish
  }

  public static fromTransfer(transfer: any): RecipientInfoFe {
    let info = new RecipientInfoFe(transfer.objectSharedId, transfer.email, transfer.firstName, transfer.lastName, transfer.companyName, transfer.affId, transfer.companyId, transfer.accessType, transfer.emailLanguage, transfer.includeEnglish)
    return info
  }

  public static fromRecipientInfoAdd(recipient: RecipientInfo_AddFe, objectSharedId: string): RecipientInfoFe {
    let info = new RecipientInfoFe(objectSharedId, recipient.email, recipient.firstName, recipient.lastName, recipient.companyName, recipient.affId, recipient.companyId, recipient.accessType, recipient.emailLanguage, recipient.includeEnglish)
    return info
  }
}
