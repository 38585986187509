import { AbstractEmissionFactorFe, EmissionFactorTypeFe } from "./AbstractEmissionFactorFe";
import { GlobalDatabaseFe } from "./GlobalDatabaseFe";

export class GlobalEmissionFactorFe extends AbstractEmissionFactorFe {

  relatedDatabase: GlobalDatabaseFe

  constructor(
    public id: string,
    public sourceId: string,
    public sourceName: string,
    public sourceUnit: string,
    public conversionFactor: number,
    public conversionUnit: string,
    public origin: string,
    public additionalDetails: {[key:string]: string},
    public databaseId: string,
    public databaseName: string
  ){
    super(
      id,
      EmissionFactorTypeFe.GLOBAL_EMISSION_FACTOR,
      sourceId,
      sourceName,
      sourceUnit,
      conversionFactor,
      conversionUnit,
      origin,
      additionalDetails
    )
  }

  public static fromTransfer(transfer: any): GlobalEmissionFactorFe {
    let ef = new GlobalEmissionFactorFe(
      transfer.id,
      transfer.sourceId,
      transfer.sourceName,
      transfer.sourceUnit,
      transfer.conversionFactor,
      transfer.conversionUnit,
      transfer.origin,
      transfer.additionalDetails,
      transfer.databaseId,
      transfer.databaseName
    )

    return ef;
  }
}