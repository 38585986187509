import { Injectable } from "@angular/core";

export class UserTypeFe {
  public static AFFILIATED_USER = 'AFFILIATED_USER';
  public static VISITOR = 'VISITOR';
}

@Injectable({
  providedIn: 'root'
})

export class UserServiceFe {

  userType: string

  constructor () {
  }

  setUserAsAffiliated () {
    this.userType = UserTypeFe.AFFILIATED_USER;
  }

  setUserAsVisitor () {
    this.userType = UserTypeFe.VISITOR;
  }

  isUserAVisitor () {
    return this.userType == UserTypeFe.VISITOR;
  }
}