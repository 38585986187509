import { MasterTableDataGridIntergationService } from "src/app/model/taxonomy/MasterTableDataGridIntergationService";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ColumnSchemaFe } from "../schema/ColumnSchemaFe";
import { AbstractEntityFe } from "./AbstractEntityFe";
import { TaxonomyAttributeFe } from "./TaxonomyAttributeFe";
import { TaxonomyInfoFe } from "./TaxonomyInfoFe";
import { Language } from "src/app/utils/language/Language";

export class EntityFe extends AbstractEntityFe {
  public table: boolean;
  public tableName: string;
  public columns: TaxonomyAttributeFe[] = [];
  public ordinal: number;
  public rows: number = -1;
  public deletedAttributes: TaxonomyAttributeFe[] = [];
  public oldLabel: { [key: string]: string };
  public deployed = true;
  public labelMatch: boolean = true;
  public attributesMatch: boolean = true;
  public modified = false;
  public editText = false;
  public isInValid = false;
  public isEdited = false;
  public isRemoved = false;
  public isNew = false;

  public dataGridService: MasterTableDataGridIntergationService;
  public selectedRowId: string

  public static fromTransfer(transfer: any, languageService: LanguageService): EntityFe {
    let entity = new EntityFe(languageService);
    entity.key = transfer["key"];
    entity.label = transfer["label"];entity.table = transfer["table"];
    entity.tableName = transfer["tableName"];
    entity.icon = transfer["icon"];
    entity.color = transfer["color"];
    if (transfer["columns"]) {
      entity.columns = transfer["columns"].map(
        (c: any) => new TaxonomyAttributeFe(c, languageService)
      );
    } else if (transfer["attributes"]) {
      entity.columns = transfer["attributes"].map(
        (c: any) => new TaxonomyAttributeFe(c, languageService)
      );
    }
    entity.ordinal = transfer["ordinal"];
    entity.isEdited = transfer["isEdited"];
    entity.isRemoved = transfer["isRemoved"];
    entity.isNew = transfer["isNew"];
    return entity;
  }

  adjustKey(column: TaxonomyAttributeFe) {
    let i = 1;
    let baseKey = "nokey";
    if (column.getLabel() && column.getLabel() !== "") {
      baseKey = column.getLabel().replace(/[^a-zA-Z0-9_]/gi, "_").toLowerCase();
    }
    let otherColumns = this.columns.filter((col) => col !== column);
    let baseMatch = otherColumns.find((col) => col.key == baseKey);
    let key;
    if (!baseMatch) {
      key = baseKey;
    } else {
      let keyMatch = false;
      while (!keyMatch) {
        key = baseKey + i;
        let item = this.getColByKey(key);
        if (!item) {
          keyMatch = true;
        } else {
          i++;
        }
      }
    }
    column.key = key;
  }

  public columnSchemas(): ColumnSchemaFe[] {
    let columnSchemas = this.columns.map((c) => c.columnSchema());
    return columnSchemas;
  }

  diffDeployment(deployedEnity: EntityFe | undefined) {
    this.deletedAttributes = [];
    this.oldLabel = undefined;

    if (deployedEnity) {
      this.deployed = true;

      if (this.label != deployedEnity.label) {
        this.labelMatch = false;
        this.oldLabel = deployedEnity.label;
      }

      deployedEnity.columns.forEach((da) => {
        let attr = this.columns.find((a) => a.key == da.key);
        if (!attr) {
          this.deletedAttributes.push(da);
        }
      });

      this.columns.forEach((a: TaxonomyAttributeFe) => {
        let da = deployedEnity.columns.find((da) => da.key == a.key);
        a.diffDeployment(da);
      });

      let modifiedAttr = this.columns.find((a) => !a.deployed || a.modified);

      this.attributesMatch =
        this.deletedAttributes.length == 0 && (modifiedAttr ? false : true);
      this.modified = this.labelMatch && this.attributesMatch ? false : true;
    } else {
      this.deployed = false;
      this.modified = true;
    }
  }

  labelIsModified() {
    this.editText = false;
    this.modified = true;
  }

  getTaxonomyTitle(taxonomy: TaxonomyInfoFe): string {
    let rootParentEntity = taxonomy?.rootParentEntity(this.key);
    let parentEntity = taxonomy?.parentEntity(this.key);
    if (rootParentEntity && rootParentEntity != parentEntity) {
      return `${rootParentEntity?.getLabel()} > ${parentEntity?.getLabel()} > ${this.getLabel()}`;
    } else if (parentEntity) {
      return `${parentEntity?.getLabel()} > ${this.getLabel()}`;
    } else {
      return this.getLabel();
    }
  }

  getColByKey(key: string): TaxonomyAttributeFe {
    return this.columns.find((a) => a.key == key);
  }

  isLevel1Entity(): boolean {
    let components = this.key.split('.')
    return components.length == 1
  }
  
  isLevel2Entity(): boolean {
    let components = this.key.split('.')
    return components.length == 2
  }
  
  isLevel3Entity(): boolean {
    let components = this.key.split('.')
    return components.length == 3
  }

  resolveLabel(activeLanguage: Language): string {
    return this.label[activeLanguage.code] || this.label["en"]
  }
}
