<div class="predefine-answer">
  <div class="modal-header" style="border-bottom: none;background-color: #E6E6E6;height: 50px;">
    <h4 class="modal-title">{{ locale("locale_key.pages.data_request.edit.request_title", {title: question.titleNumber})
      }}</h4>
  </div>

  <div class="m-2 d-flex flex-column" style="min-height: 30rem">
    <p class="m-0 p-2" style="font-size: 16px; font-weight: 400;">
      {{ locale("locale_key.pages.data_request.edit.what_input_should_the_do_provide") }}
    </p>

    <!-- Number Question -->
    <div *ngIf="questionType === 'number'" class="number-options overflow-auto scroll-container h-40v mt-1">
      <div class="d-flex flex-row">
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('default')}" (click)="selectOption('default')">
          {{ locale("locale_key.pages.data_request.edit.value_and_unit") }}
        </span>
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('unit')}" (click)="selectOption('unit')">
          {{ locale("locale_key.pages.data_request.edit.value_with_predefined_unit") }}
        </span>
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('select')}" (click)="selectOption('select')">
          {{ locale("locale_key.pages.data_request.edit.select_from_predefined_options") }}
        </span>
      </div>

      <div class="d-flex flex-row" *ngIf="selectedOption === 'unit'">
        <div class="d-flex flex-column p-3">
          <p style="font-size: 16px; font-weight: 400;">
            {{ locale('locale_key.pages.data_request.edit.define_the_unit_that_will_be_reported_in') }}
          </p>
          <unit-selector [mode]="'select'" [selectBy]="'symbol'" (selectedUnitChanged)="setUnit($event, predefinedOptions[0])"
          [source]="'add_datapoint_modal'" [selectedUnitSymbol]='predefinedOptions[0].unit'
          [selectedUnitContainer]="getSelectedUnitContainer(predefinedOptions[0].unit)"
          [includeMeasurementTypes]="getMeasurementType()" [customUnits]="getCustomUnits()"
          [unitsByMeasurementType]="getUnitsByMeasurementType()" [skipInitNoUnit]="skipInitNoUnit()"
          [selectedUnitSymbol]="predefinedOptions[0].unit || question['unitSymbol']">
        </unit-selector>
        </div>
      </div>

      <div class="d-flex flex-column" *ngIf="selectedOption === 'select'">
        <div class="flex-row mx-4 p-3">
          <div class="d-flex flex-row align-items-center">
            <div class="flex-fill">
              <p class="mb-0" style="font-size: 16px; font-weight: 400;">
                {{ locale('locale_key.pages.data_request.edit.define_the_selectable_options_for_the_data_reporter') }}
              </p>
            </div>
            <div class="ms-auto d-flex align-items-center user-select-none"
              [ngClass]="{'disabled': isActionButtonDisabled()}" (click)="showRemoveAllModal()">
              <i class="fs-4 la la-trash-alt"></i>
              <span>{{locale("locale_key.general.buttons.remove_all")}}</span>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div *ngFor="let option of predefinedOptions; let i = index" class="row align-items-center mb-2">
            <div class="col-5 px-2">
              <input type="number" (keydown)="filterNumberInput(i, $event)" [ngModel]="option.value" min="0"
                (ngModelChange)="updateNumberValue(i, $event)" class="form-control rounded-0" [ngModel]="option.value"
                [placeholder]="locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.enter_value.placeholder')" />
            </div>

            <div class="col-5 px-2">
              <unit-selector [mode]="'select'" [selectBy]="'symbol'" (selectedUnitChanged)="setUnit($event, option)"
                [source]="'add_datapoint_modal'" [selectedUnitSymbol]='option.unit'
                [selectedUnitContainer]="getSelectedUnitContainer(option.unit)"
                [includeMeasurementTypes]="getMeasurementType()" [customUnits]="getCustomUnits()"
                [unitsByMeasurementType]="getUnitsByMeasurementType()" [skipInitNoUnit]="skipInitNoUnit()"
                [selectedUnitSymbol]="predefinedOptions[0].unit || question['unitSymbol']"
                [selectedUnitSymbol]="predefinedOptions[0].unit || question['unitSymbol']">
              </unit-selector>
            </div>

            <div class="col-1 text-end">
              <i *ngIf="i > 0" class="fs-4 la la-trash-alt" (click)="removeRow(i)"></i>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex align-items-center user-select-none border border-secondary rounded p-2">
              <i class="icon las la-plus me-2" (click)="addRow()"></i>
              <span (click)="addRow()">{{locale("locale_key.general.add_new_option")}}</span>
            </div>
            <p class="mb-0" style="font-style: italic; font-size: 10px; font-weight: 400;">
              {{ locale("locale_key.pages.data_request.edit.option_will_be_displayed_in_order") }}
            </p>
          </div>
        </div>

      </div>
    </div>

    <!-- Text Question -->
    <div *ngIf="questionType === 'text'" class="text-options overflow-auto scroll-container h-40v mt-1">
      <div class="d-flex flex-row">
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('default')}" (click)="selectOption('default')">
          {{ slocale("Any text") }}
        </span>
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('select')}" (click)="selectOption('select')">
          {{ slocale("Select from pre-defined options") }}
        </span>
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('select-prefill')}" (click)="selectOption('select-prefill')">
          {{ slocale("Content is pre-filled") }}
        </span>
      </div>

      <div class="d-flex flex-column" *ngIf="selectedOption === 'select'">
        <div class="flex-row mx-4 p-3 ">
          <div class="d-flex flex-row align-items-center">
            <div class="flex-fill">
              <p class="mb-0" style="font-size: 16px; font-weight: 400;">
                {{ slocale('Define the selectable options for the data reporter:') }}
              </p>
            </div>
            <div class="ms-auto d-flex align-items-center user-select-none"
              [ngClass]="{'disabled': isActionButtonDisabled()}" (click)="showRemoveAllModal()">
              <i class="fs-4 la la-trash-alt"></i>
              <span>{{slocale("Remove all")}}</span>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div *ngFor="let option of predefinedOptions; let i = index" class="d-flex flex-row">
            <div class="row w-100 pt-2">
              <div class="col px-2">
                <div class="d-flex flex-row align-items-center">
                  <input type="text" class="form-control input-m input-text" [(ngModel)]="predefinedOptions[i].text"  placeholder="Enter option"/>
                  <i *ngIf="i > 0" class="fs-4 la la-trash-alt p-2" (click)="removeRow(i)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex align-items-center user-select-none border border-secondary rounded p-2">
              <i class="icon las la-plus me-2" (click)="addRow()"></i>
              <span (click)="addRow()">{{slocale("Add new option")}}</span>
            </div>
            <p class="mb-0" style="font-style: italic; font-size: 10px; font-weight: 400;">
              {{ slocale("Options will be displayed in alphabetical order.") }}
            </p>
          </div>
        </div>

      </div>

    </div>

    <!-- Date Question -->
    <div *ngIf="questionType === 'date' || questionType === 'dateRange'"
      class="date-options overflow-auto scroll-container h-40v mt-1">
      <div class="d-flex flex-row">
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('default')}" (click)="selectOption('default')">
          {{ locale("locale_key.pages.data_request.edit.any_date") }}
        </span>
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('select')}" (click)="selectOption('select')">
          {{ locale("locale_key.pages.data_request.edit.select_from_predefined_options") }}
        </span>
      </div>

      <div class="d-flex flex-column" *ngIf="selectedOption === 'select'">
        <div class="flex-row mx-4 p-3 ">
          <div class="d-flex flex-row align-items-center">
            <div class="flex-fill">
              <p class="mb-0" style="font-size: 16px; font-weight: 400;">
                {{ locale("locale_key.pages.data_request.edit.define_the_selectable_options_for_the_data_reporter") }}
              </p>
            </div>
            <div class="ms-auto d-flex align-items-center user-select-none"
              [ngClass]="{'disabled': isActionButtonDisabled()}" (click)="showRemoveAllModal()">
              <i class="fs-4 la la-trash-alt"></i>
              <span>{{locale("locale_key.general.buttons.remove_all")}}</span>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div *ngFor="let option of predefinedOptions; let i = index" class="d-flex flex-row">
            <div class="row w-100 pt-2">
              <div class="col px-2">
                <div class="d-flex flex-row align-items-center">
                  <input type="date" class="form-control input-m" [ngModel]="option.from | date : 'yyyy-MM-dd'"
                    placeholder="dd-mm-yyyy" (ngModelChange)="onFromDateChange($event, i)"
                    [ngClass]="{'is-invalid': isInvalidDate(i)}" />

                  <span *ngIf="questionType === 'dateRange'" class="px-3">{{ locale("locale_key.general.email.to")
                    }}</span>

                  <input *ngIf="questionType === 'dateRange'" type="date" class="form-control input-m"
                    [ngModel]="option.to | date : 'yyyy-MM-dd'" placeholder="dd-mm-yyyy"
                    (ngModelChange)="onToDateChange($event, i)" [attr.min]="getMinDate(i)"
                    [ngClass]="{'is-invalid': isInvalidDate(i)}" />

                  <i *ngIf="i > 0" class="fs-4 la la-trash-alt p-2" (click)="removeRow(i)"></i>
                </div>
                <div *ngIf="isInvalidDate(i)" class="text-danger mt-1">
                  {{ locale('locale_key.pages.data_request.edit.to_date_must_be_on_or_after_from_date') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex align-items-center user-select-none border border-secondary rounded p-2">
              <i class="icon las la-plus me-2" (click)="addRow()"></i>
              <span (click)="addRow()">{{locale("locale_key.general.add_new_option")}}</span>
            </div>
            <p *ngIf="questionType === 'date'" class="mb-0"
              style="font-style: italic; font-size: 10px; font-weight: 400;">
              {{ locale("locale_key.pages.data_request.edit.options_will_be_displayed_in_order_of_earliest_to_latest")
              }}
            </p>
            <p *ngIf="questionType === 'dateRange'" class="mb-0"
              style="font-style: italic; font-size: 10px; font-weight: 400;">
              {{
              locale("locale_key.pages.data_request.edit.options_will_be_displayed_in_order_of_earliest_to_latest_start_date")
              }}
            </p>
          </div>
        </div>

      </div>
    </div>

    <!-- Emission Factor -->
    <div *ngIf="questionType === 'emissionFactor'" class="date-options overflow-auto scroll-container h-40v mt-1">
      <div class="d-flex flex-row">
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('default')}" (click)="selectOption('default')">
          {{ locale("locale_key.pages.data_request.edit.any_emission_factor") }}
        </span>
        <span class="border border-secondary rounded user-select-none m-1 px-3"
          [ngClass]="{'active-question-option': isOptionActive('select')}" (click)="selectOption('select')">
          {{ locale("locale_key.pages.data_request.edit.select_from_predefined_options") }}
        </span>
      </div>

      <div class="d-flex flex-column" *ngIf="selectedOption === 'select'">
        <div class="flex-row mx-4 p-3 ">
          <div class="d-flex flex-row align-items-center">
            <div class="flex-fill">
              <p class="mb-0" style="font-size: 16px; font-weight: 400;">
                {{ locale("locale_key.pages.data_request.edit.define_the_selectable_options_for_the_data_reporter") }}
              </p>
            </div>
            <div class="ms-auto d-flex align-items-center user-select-none"
              [ngClass]="{'disabled': isActionButtonDisabled()}" (click)="showRemoveAllModal()">
              <i class="fs-4 la la-trash-alt"></i>
              <span>{{locale("locale_key.general.buttons.remove_all")}}</span>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div *ngFor="let option of predefinedOptions; let i = index" class="row align-items-center mb-2">
            <div class="col-6">
              <emission-factor (emissionFactorSelected)="setEmissionFactor(i, $event)"
                [selectedEF]="option.selectedEmissionFactor" [isButtonFullWidth]=true
                [displayStyle]="'SOURCE_UNIT'"
                [isEditable]=true></emission-factor>
            </div>
            <div class="col-1 p-0">
              <i *ngIf="i > 0" class="fs-4 la la-trash-alt" (click)="removeRow(i)"></i>
            </div>
          </div>
        </div>

        <div class="flex-row mx-4 p-3">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex align-items-center user-select-none border border-secondary rounded p-2">
              <i class="icon las la-plus me-2" (click)="addRow()"></i>
              <span (click)="addRow()">{{locale("locale_key.general.add_new_option")}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="modal-footer" style="border-top: 1px solid lightgray !important">
    <ng-container>
      <button type="button" class="btn tertiary-button custom-button" (click)="closeSelfModal()">{{
        locale("locale_key.general.buttons.cancel")}}</button>
      <button type="button" class="btn primary-button px-0 custom-button"
        [ngClass]="{'disabled': isActionButtonDisabled()}" (click)="apply()">{{locale("locale_key.general.apply")
        }}</button>
    </ng-container>
  </div>
</div>

<ng-template #removeAllModal>
  <div class="p-3">
    <div class="modal-header border-0">
      <div class="title"
        style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 24px; font-style: normal; font-weight: 700; line-height: normal">
        {{ locale('locale_key.pages.data_request.edit.remove_all_options') }}
      </div>
      <div class="close_control">
        <i class="las la-times" (click)="cancelRemoveAllModal()"
          style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"></i>
      </div>
    </div>
    <div class="m-2 px-2">
      <p class="m-0"
        tyle="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal">
        {{ locale("locale_key.pages.data_request.edit.all_defined_selection_options_for_question_removed") }}
      </p>
      <p
        tyle="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal">
        {{ locale('locale_key.pages.data_request.edit.are_you_sure_you_would_like_to_proceed') }}
      </p>
    </div>
    <div class="modal-footer border-0">
      <ng-container>
        <button type="button" class="btn tertiary-button custom-button" (click)="cancelRemoveAllModal()">{{
          locale('locale_key.delete_confirmation_modal.button.cancel') }}</button>
        <button type="button" class="btn delete-custom-button px-0 custom-button" (click)="removeAllRows()">{{
          locale("locale_key.general.buttons.remove_all") }}</button>
      </ng-container>
    </div>
  </div>
</ng-template>