<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">

    </div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 class="text-upppercase">{{ locale("locale_key.pages.organization.title") }}</h3>
           <!-- dropdown menu instead of toolbar for smaller pages-->
      <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
        <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 pt-0 mt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 pt-0 mt-0" style="rotate: 90deg;"></i>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
          <ng-container *ngFor="let toolbarGroup of pageToolbar ; let groupIndex=index">
            <div>
              <ng-container *ngFor="let toolbarButton of toolbarGroup">
                <li role="menuitem">
                  <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)"
                  *ngIf="toolbarButton.visible()"
                  >
                    <i [class]="toolbarButton.icon"></i>
                    <span>{{ toolbarButton.longLabel }}</span>
                  </button>
                </li>
              </ng-container>
              <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
            </div>
          </ng-container>
        </ul>
      </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
          <div class="ball-pulse loader-primary" *ngIf="orgTableInfos[selectedOrgTableIndex]?.dataGridService.saveInProgress || upgradingInProgress || savingChartInProgress">
            <div></div>
            <div></div>
            <div></div>
          </div>       
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper mt-5" *ngIf="loadInProgress">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex h-100 flex-column me-2" *ngIf="!loadInProgress">
          <ul class="nav nav-tabs" style="border: none">
            <li class="d-flex flex-row nav-item">
              <a class="nav-link py-0" [ngClass]="{ active: isChartActive }" data-bs-toggle="tab" (click)="viewChart()" *ngIf="!isNewVersionActive"> <i class="la la-sitemap me-1"></i>{{ locale("locale_key.pages.organization.diagram") }} </a>
              <a class="nav-link py-0" [ngClass]="{ active: isChartActive }" data-bs-toggle="tab" (click)="viewChart()" *ngIf="isNewVersionActive"> <i class="la la-sitemap margin-ft"></i>{{ locale("locale_key.pages.organization.diagram") }} {{ locale('locale_key.pages.organization.diagram.revision') }} </a>
              <ng-container *ngFor="let orgTableInfo of orgTableInfos; let tableIndex = index">
                <a class="nav-link py-0" [ngClass]="{ active: orgTableInfo.isActive }" data-bs-toggle="tab" (click)="viewTable(tableIndex)">
                  <i class="la la-table margin-ft" *ngIf="!orgTableInfo.dataGridService.loadInProgress"></i>
                  <i class="la la-spinner spinner" *ngIf="orgTableInfo.dataGridService.loadInProgress"></i>{{ orgTableInfo.entity.label }}
                  <i class="las la-times ms-3" (click)="closeTableTab(orgTableInfo)"></i>
                </a>
              </ng-container>
            </li>
          </ul>
          <div class="tab-content col mx-0 pt-1" *ngIf="!loadInProgress">
            <div class="row frame" *ngIf="isChartActive && !isNewVersionActive" style="max-height: 100vh; height: 100vh">
              <div class="col mx-0 px-0">
                <div class="card-body p-0" style="height: 90vh">
                  <div id="home" class="tab-pane w-100 vh-100 ps-e-75" [ngClass]="{ active: isChartActive }">
                    <flowChart [orgChartService]="depOrgIntegrationService"></flowChart>
                  </div>
                </div>
              </div>
            </div>
            <div class="row frame" *ngIf="isChartActive && isNewVersionActive" style="max-height: 100vh; height: 100vh">
              <div class="col mx-0 px-0">
                <div class="card-body p-0 ms-0 vh-100">
                  <div id="home" class="tab-pane vh-100 ps-e-75" [ngClass]="{ active: isChartActive }">
                    <flowChart [orgChartService]="newOrgIntegrationService"></flowChart>
                  </div>
                </div>
              </div>
            </div>
            <div class="row frame" *ngIf="!isChartActive" style="max-height: 100vh; height: 100vh">
              <ng-container *ngFor="let orgTableInfo of orgTableInfos; let fileIndex = index">
                <div class="col-11" [ngClass]="{ active: orgTableInfo.isActive }" *ngIf="orgTableInfo.isActive">
                  <div class="card main-card m-0" style="height: 100vh">
                    <div *ngIf="orgTableInfo.dataGridService.loadInProgress">
                      <div class="loader-wrapper mt-5">
                        <div class="loader-container">
                          <div class="ball-grid-pulse loader-primary">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row frame" *ngIf="!loadInProgress" style="max-height: 100vh; height: 100vh">
                      <div class="col" style="max-height: 100vh; height: 100vh">
                        <div class="card-body">
                          <datagrid-table-editor [dataGrid]="orgTableInfo.dataGridService"></datagrid-table-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex h-100 flex-column mr-50" *ngIf="!loadInProgress"> 
          <ul class="nav nav-tabs" style="border: none">
                  <li class="nav-item">
                    <a class="nav-link py-0" [ngClass]="{ active: isChartActive }" data-bs-toggle="tab" (click)="viewChart()" *ngIf="!isNewVersionActive"> <i class="la la-sitemap margin-ft"></i>{{ locale("locale_keyword.organization.chart") }} </a>
                    <a class="nav-link py-0" [ngClass]="{ active: isChartActive }" data-bs-toggle="tab" (click)="viewChart()" *ngIf="isNewVersionActive"> <i class="la la-sitemap margin-ft"></i>{{ locale("locale_keyword.organization.chart") }} (revision) </a>
                    <ng-container *ngFor="let orgTableInfo of orgTableInfos; let tableIndex = index">
                      <a class="nav-link py-0" [ngClass]="{ active: orgTableInfo.isActive }" data-bs-toggle="tab" (click)="viewTable(tableIndex)">
                        <i class="la la-table margin-ft" *ngIf="!orgTableInfo.dataGridService.loadInProgress"></i>
                        <i class="la la-spinner spinner" *ngIf="orgTableInfo.dataGridService.loadInProgress"></i>{{ orgTableInfo.entity.label }}
                        <i class="las la-times ml-1" (click)="closeTableTab(orgTableInfo)"></i>
                      </a>
                    </ng-container>
                  </li>
          </ul>
          <div class="tab-content col mx-0 pt-25" *ngIf="!loadInProgress">
                  <div class="row frame" *ngIf="isChartActive && !isNewVersionActive" style="max-height: 100vh; height: 100vh">
                    <div class="col mx-0 px-0">
                      <div class="card-body p-0" style="max-height: 90vh;">
                        <div id="home" class="tab-pane" [ngClass]="{ active: isChartActive }" style="height:100vh; width: 100%">
                          <flowChart [orgChartService]="depOrgIntegrationService"></flowChart>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row frame" *ngIf="isChartActive && isNewVersionActive" style="max-height: 100vh; height: 100vh">
                    <div class="col mx-0 px-0">
                      <div class="card-body p-0 ml-0" style="height: 100vh">
                        <div id="home" class="tab-pane" [ngClass]="{ active: isChartActive }" style="height: 100vh">
                          <flowChart [orgChartService]="newOrgIntegrationService"></flowChart>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row frame" *ngIf="!isChartActive" style="max-height: 100vh; height: 100vh">
                    <ng-container *ngFor="let orgTableInfo of orgTableInfos; let fileIndex = index">
                      <div class="col-12" [ngClass]="{ active: orgTableInfo.isActive }" *ngIf="orgTableInfo.isActive" style="margin-left: -22px">
                        <div class="card main-card m-0" style="height: 100vh">
                          <div *ngIf="orgTableInfo.dataGridService.loadInProgress">
                            <div class="loader-wrapper mt-5">
                              <div class="loader-container">
                                <div class="ball-grid-pulse loader-primary">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row frame" *ngIf="!loadInProgress" style="max-height: 100vh; height: 100vh">
                            <div class="col" style="max-height: 100vh; height: 100vh">
                              <div class="card-body">
                                <datagrid-table-editor [dataGrid]="orgTableInfo.dataGridService"></datagrid-table-editor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
           </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<ng-template #reviseOrganizaionInfoModal>
  <revise-organization [mode]="'info'" (isInfoConfirmed)="onReviseOrganizationInfoConfirmed($event)"></revise-organization>
</ng-template>
<ng-template #upgradeOrganizaionInfoModal>
  <revise-organization [mode]="'upgrade'" [orgInfo]="newOrg" (isUpgradeConfirmed)="onUpgradeOrganizationConfirmed($event)"></revise-organization>
</ng-template>
