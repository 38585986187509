export abstract class AbstractSignInMethodFe {

  provider: string
  isEnabled: boolean = true

  constructor (
    provider: string,
    isEnabled: boolean
  ) {
    this.provider = provider
    this.isEnabled = isEnabled
  }
}