import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { DataGridRow } from "../projects/data-grid-ui/model/DataGridRow";
import { DataGridTableData } from "../projects/data-grid-ui/model/DataGridTableData";
import { DataGridTableMode } from "../projects/data-grid-ui/model/DataGridTableMode";
import { DataGridObserverInterface } from "../projects/data-grid-ui/service/DataGridObserverInterface";
import { DataGridServiceInterface } from "../projects/data-grid-ui/service/DataGridServiceInterface";

export class TabFileDataGridIntergationService implements DataGridServiceInterface {
  currentPageData: TableDataFe
  observer: DataGridObserverInterface | undefined
  mode: string = DataGridTableMode.EDIT_TABLE_DATA
  public modified: boolean = false
  
  constructor(tabFileDataFe: TableDataFe) {
    this.currentPageData = tabFileDataFe;
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMode(): string {
    return this.mode
  }

  getMainTableData(): DataGridTableData {
    let dgtd = this.currentPageData.toDataGridTableData()
    return dgtd
  }

  hasMainTablePagination(): boolean {
    return true
  }

  async loadMainTablePage(pageSize: number = 10, pageNumber: number = 1): Promise<DataGridTableData> {
    let dgtd = this.currentPageData.toDataGridTableData()
    return dgtd
  }

  async saveChages() {
    return
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {
    return
  }

  hasDataModifiedElseWhere(): boolean {
    return false
  }

  saveReferenceTable(table:DataGridTableData): void {

  }

  getReferenceTables(): DataGridTableData[] {
    return []
  }
  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
  }

  getNoOfMinRows(): number {
    return 10;
  }

  downloadCSV (label: string) {
    let fileName = `${label}_Table.csv`;
    let columnNames = [];
    for (let col of this.currentPageData.dataSchema) {
      columnNames.push(col.label)
    }

    let header = columnNames.join(',');
    let csv = header;

    this.currentPageData.rows.forEach(r => {
      csv += '\r\n';
      let answer = []
      r.values.map((val) => answer.push(val))
      csv += answer.join(',')
    })
    var blob = new Blob([csv], { type: "text/csv" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}