<div class="drDatapointsViewComponent">
    <!-- STATS -->
    <div class="stats">
        <div class="lhs">
            <div class="stat totalDatapoints">
                <div class="name">
                    {{ slocale("TOTAL DATA POINTS TO BE COLLECTED") }}
                </div>
                <div class="value">
                    {{ stats.totalDatapoints }}
                </div>
            </div>
        </div>
        <div class="rhs">
            <div class="stat materialDatapoints">
                <div class="name">{{ slocale("MATERIAL DATA POINTS") }}</div>
                <div class="value">{{ stats.materialDatapoints }}</div>
            </div>
            <div class="stat nonMaterialDatapointsVoluntary">
                <div class="name">{{ slocale("NOT MATERIAL DATA POINTS - VOLUNTARY") }}</div>
                <div class="value">{{ stats.nonMaterialVoluntaryDatapoints }}</div>
            </div>
            <div class="stat materialDatapointsOmitted">
                <div class="name">{{ slocale("MATERIAL DATA POINTS - OMITTED") }}</div>
                <div class="value">{{ stats.materialOmittedDatapoints }}</div>
            </div>
            <div class="stat nonMaterialDatapoints">
                <div class="name">{{ slocale("NOT - MATERIAL DATA POINTS") }}</div>
                <div class="value">{{ stats.nonMaterialDatapoints }}</div>
            </div>
        </div>
    </div>
    <!-- HEADER -->
    <div class="header">
        <div class="infos">
            <div class="title">
                {{ slocale("ESRS data point") }}
            </div>
            <div class="switchers">
                <div class="showAllInfo switcherBox">
                    <div class="switch" id="showAllInfoSwitcher" (click)="toggleSwitch('showAllInfo')">
                        <input type="checkbox" [checked]="showAllInfo" />
                        <span class="slider round"></span>
                    </div>
                    <label for="showAllInfoSwitcher"> {{ slocale("Show all info and descriptions") }}</label>
                </div>
                <div class="showAllComments switcherBox">
                    <div class="switch" id="showAllCommentsSwitcher" (click)="toggleSwitch('showAllComments')">
                        <input type="checkbox" [checked]="showAllComments" />
                        <span class="slider round"></span>
                    </div>
                    <label for="showAllCommentsSwitcher"> {{ slocale("Show all internal comments") }}</label>
                </div>
            </div>
        </div>
        <div class="editAssessment" (click)="editAssessment()">
            <i class="la la-edit icon"></i>
            {{ slocale("Edit assessment") }}
        </div>
    </div>
    <!-- DATAPOINTS LIST -->
    <div class="datapointList">
        <ng-container *ngFor="let datapoint of csrdDatapoints">
            <div class="datapoint">
                <div class="info">
                    <div class="title">
                        <div class="status phaseIn" *ngIf="datapoint.phaseIn">
                            <i class="la la-clock icon"></i>
                        </div>
                        <div class="status mayDisclose" *ngIf="datapoint.isVoluntary">
                            <i class="la la-question icon"></i>
                        </div>
                        <div class="label">{{ datapoint.id }} {{ datapoint.name }}</div>
                        <div class="status questions">
                            <i class="la la-eye icon"></i>
                        </div>
                        <div class="status descriptionIconBox" [class.open]="datapoint.showMoreInfo" (click)="toggleShowMoreInfo(datapoint)">
                            <i class="la la-info-circle icon"></i>
                        </div>
                    </div>
                    <div class="description" *ngIf="datapoint.showMoreInfo">
                        <div class="header">
                            {{ slocale("Description from the standards") }}
                        </div>
                        <div class="body">
                            {{ datapoint.description }}
                        </div>
                        <!-- <div class="footer">
                            {{ slocale("See guidance") }} <i class="las la-external-link-alt"></i>
                        </div> -->
                    </div>
                    <div class="comments" *ngIf="datapoint.comment && datapoint.showComment">
                        <div class="header">
                            {{ slocale("Internal comment") }}
                        </div>
                        <div class="body">
                            {{ datapoint.comment }}
                        </div>
                    </div>
                </div>
                <div class="dpBadges">
                    <div class="dpBadge notMaterial" *ngIf="!datapoint.isMaterial">
                        {{ slocale("Not material") }}
                    </div>
                    <div class="dpBadge material" *ngIf="datapoint.isMaterial">
                        {{ slocale("Material") }}
                    </div>
                    <div class="dpBadge voluntary" *ngIf="datapoint.collectVoluntarily">
                        {{ slocale("Voluntary") }}
                    </div>
                    <div class="dpBadge omitted" *ngIf="datapoint.isOmitted">
                        {{ slocale("Omitted") }}
                    </div>
                    <div class="dpBadge comments" *ngIf="datapoint.comment" [class.open]="datapoint.showComment" (click)="toggleShowComment(datapoint)">
                        <i class="las la-comment icon"></i>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

</div>