<div class="animated slideInUp text-left" id="slideInBottom" *ngIf="attachments.length > 0">
  <div role="tabpanel" aria-labelledby="headingCollapse2" class="collapse show">
    <div class="modal-content" resizer>
      <div class="modal-header bg-white p-0" style="margin-top: 1px">
        <ul class="nav nav-tabs nav-underline no-hover-bg nav-justified w-100">
          <li class="nav-item w-50" *ngFor="let attachment of attachments; let i = index">
            <a class="nav-link w-100" [ngClass]="{ active: attachment.isActive }" (click)="viewAttachment(i)">
              <ng-container *ngIf="attachment.isTable">
                <i class="la la-table d-inline"></i>
                <p class="d-inline text-underline">{{locale('locale_key.pages.data_request.question_type.data_table')}} {{ attachment.submissionDate ? "(" + (attachment.submissionDate | date) + ")" : "" }}</p>
              </ng-container>
              <ng-container *ngIf="attachment.isFile">
                <i class="la {{ attachment.file.type.icon }} margin-ft" [ngStyle]="{ color: attachment.file.type.color }"></i>
                <p class="d-inline text-underline">{{ attachment.file.label }}</p>
              </ng-container>
              <button type="button" class="btn close float-end" aria-label="Close" (click)="closeAttachment(i)"><i class="las la-times"></i></button>
            </a>
          </li>
        </ul>
      </div>
      <div class="modal-body bg-white">
        <ng-container *ngFor="let attachment of attachments">
          <div *ngIf="attachment.isActive">
            <datagrid-table-editor *ngIf="attachment.isTable" [dataGrid]="attachment.tableDataGridService"></datagrid-table-editor>
            <file-viewer *ngIf="attachment.isFile" [inputFile]="attachment.file" [mode]="'view'"></file-viewer>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
