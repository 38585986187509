import { TaxonomyInfoFe } from "../../taxonomy/TaxonomyInfoFe";
import { AbstractActivityFe } from "../timeline/AbstractActivityFe";
import { QuestionnaireFe } from "./questionnaire/QuestionnaireFe";

export class REQUEST_TASK_STATUS {
  public static CREATED: string = "CREATED"
  public static IN_PROGRESS: string = "IN_PROGRESS"
  public static APPROVED: string = "APPROVED"
  public static CLOSED: string = "CLOSED"  
}

export class READ_STATUS{
  public static READ: string = "READ"
  public static UNREAD: string = "UNREAD"
}

export class RequestFe {

  id: string
  supplierCompanyId: string
  supplierCompanyName: string
  assigneeAffId: string
  assigneeFirstName: string
  assigneeLastName: string
  status: string
  readStatus: string  
  requestGroupId: string
  questionnaire: QuestionnaireFe
  
  timelineItems: AbstractActivityFe[] = []
  noOfUnreadActivities = 0
  
  constructor(
    id: string,
    supplierCompanyId: string,
    supplierCompanyName: string,
    assigneeAffId: string,
    assigneeFirstName: string,
    assigneeLastName: string,
    status: string,
    readStatus: string,
    requestGroupId: string,
    questionnaire: QuestionnaireFe
  ) {
    this.id = id
    this.supplierCompanyId = supplierCompanyId
    this.supplierCompanyName = supplierCompanyName
    this.assigneeAffId = assigneeAffId
    this.assigneeFirstName = assigneeFirstName
    this.assigneeLastName = assigneeLastName
    this.status = status
    this.readStatus = readStatus
    this.requestGroupId = requestGroupId
    this.questionnaire = questionnaire
  }

  public static formTransfer(transfer: any, taxonomyInfo: TaxonomyInfoFe) : RequestFe{
    const taskFe = new RequestFe(
      transfer.id,
      transfer.supplierCompanyId,
      transfer.supplierCompanyName,
      transfer.assigneeAffId,
      transfer.assigneeFirstName,
      transfer.assigneeLastName,
      transfer.status,
      transfer.readStatus,
      transfer.requestGroupId,
      QuestionnaireFe.fromTransfer(transfer.questionnaire, taxonomyInfo)
    );
    return taskFe;
  }

  getStatusTextKeyword() {
    switch (this.status) {
      case REQUEST_TASK_STATUS.CREATED : return 'locale_key.general.state.sent'; 
      case REQUEST_TASK_STATUS.APPROVED : return 'locale_key.general.state.completed';
      case REQUEST_TASK_STATUS.IN_PROGRESS : return 'locale_key.general.state.in_progress';
      case REQUEST_TASK_STATUS.CLOSED : return 'locale_key.general.state.closed';
    }
  }

  getStatusClass() {
    switch (this.status) {
      case REQUEST_TASK_STATUS.CREATED : return 'badge-turquoise'; 
      case REQUEST_TASK_STATUS.APPROVED : return 'badge-green';
      case REQUEST_TASK_STATUS.IN_PROGRESS : return 'badge-orange';
      case REQUEST_TASK_STATUS.CLOSED : return 'badge-gray';
    }
  }

  getAssigneeName() {
    return `${this.assigneeFirstName} ${this.assigneeLastName}`
  }
}