import { RequestEntryDataGridIntegrationService } from "../../../../components/data-suppliers/data-supplier-request/RequestEntryDataGridIntegrationService";
import { TableDataFe } from "../../../schema/TableDataFe";
import { ColumnSchemaFe } from "../../../schema/ColumnSchemaFe";
import { DataTableRequestFieldFe } from "./DataTableRequestFieldFe";
import { QuestionFe } from "./QuestionFe";
import { BooleanStatementFe } from "./condition/BooleanStatementFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { AnswerDataTable_AddFe } from "../../timeline/add/answer/AnswerDataTable_AddFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { AnswerDataTableFe } from "../../timeline/answer/AnswerDataTableFe";
import { QUESTION_TYPE } from "./QuestionTypeFe";
import { BooleanStatementBuilderFe } from "./condition/BooleanStatementBuilderFe";
import { SectionFe } from "./SectionFe";
import { DataGridTableMode } from "src/app/components/projects/data-grid-ui/model/DataGridTableMode";
import { DataGridStatus } from "src/app/components/projects/data-grid-ui/model/DataGridStatus";
import { DataGridColumnMode } from "src/app/components/projects/data-grid-ui/model/DataGridColumnMode";
import { DataGridColumnSchema } from "src/app/components/projects/data-grid-ui/model/DataGridColumnSchema";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";

export class QuestionDataTableFe extends QuestionFe {
  fields: DataTableRequestFieldFe[]
  table: boolean
  rowLimit: number 
  
  newAnswer: AnswerDataTable_AddFe = new AnswerDataTable_AddFe(IdUtil.next(), this.sectionId, this.id, null, null, null);
  answer: AnswerDataTableFe
  
  requestItemDataGridService: RequestEntryDataGridIntegrationService
  customFields: DataTableRequestFieldFe[] = []
  editSettings = false
  taxonomyInfo: TaxonomyInfoFe
  mappedTaxonomy: EntityFe

  constructor(
    id: string, 
    sectionId: string,
    ordinal: number | null,
    followUpCondition: BooleanStatementFe | null,
    question: string,
    desc: string | null,
    comments: boolean,
    required: boolean,
    mappedToColKeys: string[] | null,
    fields: DataTableRequestFieldFe[],
    table: boolean = true,
    rowLimit: number = -1,
    ) {
    super(
      id, 
      sectionId,
      QUESTION_TYPE.DATA_TABLE, 
      ordinal, 
      followUpCondition, 
      question, 
      desc, 
      comments, 
      required,
      mappedToColKeys
    );
    this.fields = fields
    this.table = table
    this.rowLimit = rowLimit
    this.customFields = this.fields?.filter(f => f.isCustomField)
    this.setRequestDataGridService();
  }

  public static fromTransfer(transfer: any, sectionId: string): QuestionDataTableFe {
    transfer.followUpCondition = transfer.followUpCondition ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition) : null
    let item = this.setQuestion(transfer, sectionId)
    return item
  }
  
  public static setQuestion (transfer: any, sectionId?): QuestionDataTableFe {
    let item = new QuestionDataTableFe (
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.mappedToColKeys,
      transfer.fields.map((f: any) => DataTableRequestFieldFe.fromTransfer(f)),
      transfer.table,
      transfer.rowLimit
    )
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }

  setRequestDataGridService () {
    const columns: DataGridColumnSchema[] = [];
    this.fields.forEach((field) => {
      columns.push({
        name: field.label,
        label: field.label,
        type: field.datatype,
        mode: DataGridColumnMode.REQUIRED,
        status: DataGridStatus.STORED,
        width: "10rem",
        deployed: true,
        modified: false,
        isCalculated: false,
        unitSymbol: field.unitSymbol,
        measurementKey: field.measurementKey,
        showUnitCol: true,
        predefinedOptions: field.predefinedOptions
      });
    });

    this.requestItemDataGridService = new RequestEntryDataGridIntegrationService(new TableDataFe());
    this.requestItemDataGridService.table.dataSchema = columns.map((c) => ColumnSchemaFe.fromDataGridColumnSchema(c));
    this.requestItemDataGridService.mode = DataGridTableMode.VIEW_WITH_PREDEFINED_OPTIONS;
    this.requestItemDataGridService.observer?.renderNewDataTable();
  }

  setPredefinedOptions () {
    this.fields.forEach((field, index) => {
      field.predefinedOptions = this.requestItemDataGridService?.observer?.table?.dataSchema?.[index]?.predefinedOptions || [];
    });
  }

  setAnswerTaskDataGridService () {
    const columns: DataGridColumnSchema[] = [];
    this.fields.forEach((field) => {
      columns.push({
        name: field.label,
        label: field.label,
        type: field.datatype,
        mode: DataGridColumnMode.REQUIRED,
        status: DataGridStatus.STORED,
        width: "10rem",
        deployed: true,
        modified: false,
        isCalculated: false,
        unitSymbol: field.unitSymbol,
        measurementKey: field.measurementKey,
        showUnitCol: true,
        emissionFactor: field.datapointEf ? field.datapointEf as AbstractEmissionFactorFe : null,
        predefinedOptions: field.predefinedOptions
      });
    });

    this.newAnswer.dataGridService.table.dataSchema = columns.map((c) => ColumnSchemaFe.fromDataGridColumnSchema(c));
    this.newAnswer.dataGridService.observer?.renderNewDataTable();
  }

  isAnyCustomField(): boolean {
    let isAny = this.fields.some((dfr) => dfr.isCustomField == true);
    return isAny;
  }

  onTaxonomyChange (itemNo: number) {
    // this.fields = []
    // // this.mappingForm.controls.mappings = new FormGroup({})
    // this.setRequestDataGridService();
    // if (itemNo != 2) {
    //   this.taxonomyForm.controls.item2.patchValue("")
    //   this.mappingForm.controls.taxonomy['controls'].item2.patchValue("")
    // } else {
    //   this.mappingForm.controls.taxonomy["controls"].item0.patchValue(this.taxonomyForm.controls.item0.value)
    //   this.mappingForm.controls.taxonomy["controls"].item1.patchValue(this.taxonomyForm.controls.item1.value)
    //   this.mappingForm.controls.taxonomy["controls"].item2.patchValue(this.taxonomyForm.controls.item2.value)
    //   let taxonomy = this.taxonomyInfo.entityByKey(this.taxonomyForm.controls.item2.value);
    //   this.taxonomyKey = taxonomy.key;
    //   this.taxonomyLabel = taxonomy.label;
    //   this.toggleMappingControls();
    // }
  }

  // onMappedTaxonomyChange(itemNo: number) {
  //   if (itemNo != 2) {
  //     this.mappingForm.controls.taxonomy['controls'].item2.patchValue("")
  //   }
  // }

  adjustDataFieldRequestKey(field: any) {
    let i = 1;
    let baseKey = "nokey";
    if (field.label && field.label !== "") {
      baseKey = field.label.replace(/[^a-zA-Z0-9_]/gi, "_").toLowerCase();
    }
    let otherFields = this.fields.filter((dfr) => dfr !== field).map((dfr) => dfr);
    let baseMatch = otherFields.find((field) => field.key == baseKey);
    let key;
    if (!baseMatch) {
      key = baseKey;
    } else {
      let keyMatch = false;
      while (!keyMatch) {
        key = baseKey + i;
        let item = this.fields.find((dfr) => dfr.key == key);
        if (!item) {
          keyMatch = true;
        } else {
          i++;
        }
      }
    }
    field.key = key;
  }

  addEntityColumn (col: TaxonomyAttributeFe | DataTableRequestFieldFe) {
    let field: DataTableRequestFieldFe;
    if (col instanceof DataTableRequestFieldFe) {
      col.isCustomField = true
      this.adjustDataFieldRequestKey(col);
      col.isSelected = true;
      field = col;
    } else {
      let f = new DataTableRequestFieldFe(col.key, col.getLabel(), col.getDescription(), col.datatype, true, [], [], false,
        col.unit?.symbol, col.unit?.measurementType, col.emissionFactors[0]?.value);
      field = f;
    }
    this.fields.push(field)
    this.setRequestDataGridService();
  }

  removeEntityColumn (col: TaxonomyAttributeFe | DataTableRequestFieldFe) {
    let index = this.fields.findIndex(f => f.key == col.key);
    // (this.mappingForm.get("mappings") as FormGroup).removeControl(col.key);
    this.fields.splice(index, 1);
    this.setRequestDataGridService();
  }

  // toggleEditSettings() {
  //   this.editSettings = !this.editSettings;
  //   this.toggleMappingControls();
  // }

  // toggleMappingControls() {
  //   if (this.editSettings) {
  //     Object.keys(this.mappingForm.controls.taxonomy["controls"]).forEach((key) => {
  //       let control = this.mappingForm.controls.taxonomy["controls"][key] as FormControl;
  //       control.enable();
  //     });
  //     Object.keys(this.mappingForm.controls.mappings["controls"]).forEach((key) => {
  //       let control = this.mappingForm.controls.mappings["controls"][key] as FormControl;
  //       control.enable();
  //     });
  //   } else {
  //     Object.keys(this.mappingForm.controls.taxonomy["controls"]).forEach((key) => {
  //       let control = this.mappingForm.controls.taxonomy["controls"][key] as FormControl;
  //       control.disable();
  //     });
  //     Object.keys(this.mappingForm.controls.mappings["controls"]).forEach((key) => {
  //       let control = this.mappingForm.controls.mappings["controls"][key] as FormControl;
  //       control.disable();
  //     });
  //   }
  // }

  // setMapping () {
  //   let mappedTaxonomyKey = this.mappingForm.controls.taxonomy["controls"].item2.value;
  //   let mappedTaxonomy = this.taxonomyInfo.entityByKey(mappedTaxonomyKey);
  //   let mappings: MappingColumnSchema[] = [];
  //   Object.keys(this.mappingForm.controls.mappings["controls"]).forEach((key, i) => {
  //     let value = this.mappingForm.controls.mappings["controls"][key].value;
  //     if (value !== "doNotConnect") {
  //       let info = new MappingColumnSchema(this.fields[i].key, value);
  //       mappings.push(info);
  //     }
  //   });
  //   let trasnformationMapping = new TableTransformationMappingFe(mappings, mappedTaxonomy.key, this.taxonomyInfo.version, mappedTaxonomy.label);
  //   this.transformationMapping = trasnformationMapping;
  // }

  setQuestionFromOld (question: QuestionDataTableFe, section: SectionFe) {
    // let keys = question.taxonomyKey.split(".");
    // if (keys.length > 3) {
    //   question.taxonomyKey = `${keys[3]}.${keys[4]}.${keys[5]}`;
    // }
    // let item0Key = this.taxonomyInfo.rootParentKey(question.taxonomyKey);
    // let item1Key = this.taxonomyInfo.parentEntity(question.taxonomyKey)?.key;
    // this.taxonomyForm.patchValue({
    //   item0: item0Key,
    //   item1: item1Key,
    //   item2: question.taxonomyKey
    // });
    
    this.fields.forEach(f => f.isSelected = true);
    let columns = this.taxonomyInfo.entityByKey(section.taxonomyKey).columns;
    columns.forEach(c => {
      if (this.fields.some(f => f.key == c.key)) {
        c.isSelected = true;
      }
    })

    // let mappedTaxonomykey = question.transformationMapping.taxonomyKey;
    // let mappedItem0Key = this.taxonomyInfo.rootParentKey(mappedTaxonomykey);
    // let mappedItem1Key = this.taxonomyInfo.parentEntity(mappedTaxonomykey)?.key;
    // this.mappingForm.controls.taxonomy["controls"].item0.patchValue(mappedItem0Key)
    // this.mappingForm.controls.taxonomy["controls"].item1.patchValue(mappedItem1Key)
    // this.mappingForm.controls.taxonomy["controls"].item2.patchValue(mappedTaxonomykey)
    // question.fields.forEach(field => {
    //   let mIndex = question.transformationMapping.mappings.findIndex(m => m.mappedFromColKey == field.key);
    //   if (mIndex!=-1) {
    //     let m = question.transformationMapping.mappings[mIndex];
    //     (this.mappingForm.get("mappings") as FormGroup).addControl(field.key, new FormControl(`${m.mappedToColKey}`, Validators.required))
    //   } else {
    //     (this.mappingForm.get("mappings") as FormGroup).addControl(field.key, new FormControl(`doNotConnect`, Validators.required))
    //   }
    // })
  }

  public static toTransfer(transfer: any): any {
    let q = {
      id: transfer.id,
      type: transfer.type,
      ordinal: transfer.ordinal,
      followUpCondition: transfer.followUpCondition,
      question: transfer.question,
      desc: transfer.desc,
      comments: transfer.comments,
      required: transfer.required,
      taxonomyKey: transfer.taxonomyKey,
      taxonomyLabel: transfer.taxonomyLabel,
      fields: transfer.fields,
      transformationMapping: transfer.transformationMapping,
      table: transfer.table,
      rowLimit: transfer.rowLimit
    }
    return q;
  }
}