<body class="horizontal-layout horizontal-menu-padding 1-column blank-page pace-done menu-hide vertical-layout vertical-overlay-menu fixed-navbar" data-open="click" data-menu="horizontal-menu" data-col="1-column" data-gr-c-s-loaded="true">
  <div class="pace pace-inactive">
    <div class="pace-progress" data-progress-text="100%" data-progress="99" style="transform: translate3d(100%, 0px, 0px)">
      <div class="pace-progress-inner"></div>
    </div>
    <div class="pace-activity"></div>
  </div>
  <div class="app-content container center-layout">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row"></div>
      <div class="content-body">
        <section class="row flexbox-container" *ngIf="!mfaRequired">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0 bg-white">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <p class="mt-4 px-4 text-justify" *ngIf="existingUser">We recognized that you are already registered in our platform. Please log in to complete your new invitation.</p>
                  <p class="mt-4 px-4 text-justify" *ngIf="firstTimeUser">Please login with your new credentials to complete your invitation.</p>
                  <p class="mt-4 px-4 text-justify" *ngIf="existingUserNewEmail">Please login with your current account.</p>
                  <div class="card-body">
                    <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                      <div class="alert-message text-center alert alert-warning alert-dismissible" *ngIf="invalidLogin" role="alert">
                        <strong>{{ ErrorMessage }}</strong>
                        <button type="button" class="btn close" data-dismiss="alert" aria-label="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0">Your Email Address</label>
                        <input type="text" formControlName="email" class="form-control" spellcheck="false" placeholder="Your Email Address" [ngClass]="{ 'is-invalid': isSubmitted && formFields.email.errors }" />
                        <div *ngIf="isSubmitted && formFields.email.errors" class="invalid-feedback">
                          <div *ngIf="formFields.email.errors.required">Email is required</div>
                          <div *ngIf="loginForm.controls.email.hasError('pattern')">Please Enter Valid Email.</div>
                        </div>
                      </fieldset>
                      <fieldset class="form-group floating-label-form-group">
                        <label class="mb-0">Enter Password</label>
                        <div *ngIf="isSubmitted && formFields.password.errors" class="invalid-feedback">
                          <div *ngIf="formFields.password.errors.required">
                            {{ passwordMessage }}
                          </div>
                        </div>
                        <div class="input-group">
                          <input type="password" id="password" placeholder="Enter Password" formControlName="password" class="form-control pr-2" [ngClass]="{ 'is-invalid': isSubmitted && formFields.password.errors }" />
                          <i *ngIf="!isPasswordVisible" (click)="togglePassword()" class="passwordToggle la la-eye-slash" aria-hidden="true"></i>
                          <i *ngIf="isPasswordVisible" (click)="togglePassword()" class="passwordToggle la la-eye" aria-hidden="true"></i>
                        </div>
                      </fieldset>
                      <button type="submit" class="btn btn-outline-info btn-block w-100" *ngIf="!isLoggingIn"><i class="fas fa-unlock"></i> Login</button>
                      <div class="row mb-2">
                        <div class="col-sm-6 text-start ">
                        </div>
                        <div class="col-sm-6 text-lg-end text-md-end text-sm-start"><a routerLink="/op/forgotpassword" class="underline dark-text"><small>Forgot Password?</small></a></div>
                      </div>
                      <div class="container-line mb-3">
                        <span class="line"></span>
                        <span class="text">OR</span>
                        <span class="line"></span>
                      </div>                      
                      <a class="mb-3">
                        <div id="customBtn" class="customGPlusSignIn text-center" (click)="redirectToGoogle()">
                          <img src="../../../assets/img/logos/google_logo.png" style="height:30px; width:30px;z-index:-1"/>
                          <span class="buttonText text-center mx-auto my-auto pt-1">Login with Google</span>
                        </div>
                      </a>
                      <div class="my-3" ></div>
                      <a  class="mt-3">
                        <div id="customBtn" class="customGPlusSignIn text-center" (click)="redirectToMicrosoft()">
                          <img src="../../../assets/img/logos/microsoft_logo.jpg" class="ms-e pt-1" style="height:27px; width:27px;z-index:-1"/>
                          <span class="buttonText text-center mx-auto my-auto pt-1 ps-1">Login with Microsoft</span>
                        </div>
                      </a>        
                      <div class="ball-pulse loader-primary" *ngIf="isLoggingIn" style="margin-left: 40%;">
                        <div style="background-color:var( --main-dark-color1)"></div>
                        <div style="background-color:var( --main-dark-color1)"></div>
                        <div style="background-color:var( --main-dark-color1)"></div>
                      </div>    
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section class="row flexbox-container" *ngIf="mfaRequired && mfaScreen == 'ADD_PHONE'">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img id="sustainlab_recaptcha" class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <h3 class="mx-auto mb-3">Two factor authentication</h3>
                      <p>To increase the security of your account,
                        <b>two factor authentication</b> is required.
                        Please register a <b>phone number</b> to continue.</p>    
                      <!-- <small>Enter phone number in international format like +46xxxxxxxxxx</small>                                         -->
                    </div>
                    <ng-container *ngFor="let userPhone of userPhones; index as i">
                      <div class="row border-b d-flex flex-row justify-content-between pb-3 mb-3">
                        <div class="">
                          <label class="mx-2 mt-1 d-block my-0 py-0">
                            <i class="fi fi-se me-2"></i>{{userPhone.phoneNumber}}
                          </label>
                        </div>
                        <div class="text-end mb-0 pb-0">
                          <a class="ms-auto mb-0 pb-0" (click)="deletePhoneNumber(i)">
                            <i class="la la-trash-o m-auto mb-0 pb-0" style="position:relative; top:4px;"></i>
                          </a>
                        </div>
                      </div>
                    </ng-container>                    
                    <div class="row" style="align-items: center;flex-wrap: nowrap;">
                      <div class="phone_group_wrapper" [formGroup]="phoneForm">
                        <ngx-intl-tel-input
                        [cssClass]="'custom'"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.Sweden"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="false"
                        [numberFormat]="PhoneNumberFormat.National"
                        name="phone"
                        formControlName="phone"
                        ></ngx-intl-tel-input>
                      </div>
                      <!-- <i class="la la-plus mr-50" style="font-weight: bold;"></i>
                      <input type="text" placeholder="Add phone number" style="width:100%;" [(ngModel)]="newPhonenumber"> -->

                    </div>
                    <ng-container *ngIf="!isLoading">
                    <div class="row text-end">
                      <button class="btn primary-button custom-button mt-e ms-auto" (click)="sendVerifcationCode({mfaBtnID:'sustainlab_recaptcha',mfaType:'enroll'})">Send code</button>
                    </div> 
                    </ng-container>
                    <div class="ball-pulse loader-primary" *ngIf="isLoading">
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                    </div>                             
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="row flexbox-container" *ngIf="mfaRequired && mfaScreen == 'SELECT_PHONE'">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img id="sustainlab_recaptcha" class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <h3 class="mx-auto mb-3" *ngIf="userPhones.length == 0">Registered phone number</h3>
                      <p *ngIf="userPhones.length == 0">Press <b>Send code</b> below to recieve the <b>verification code</b>.</p>                                            
                      <h3 class="mx-auto mb-3" *ngIf="userPhones.length > 0">Select phone number</h3>
                      <p *ngIf="userPhones.length > 0">Which phone number should we send the <b>verification code</b> to?</p>
                    </div>
                    <div class="row border-b d-flex flex-row justify-content-between pb-3 mb-3" style="flex-wrap: nowrap;" *ngFor="let userPhone of userPhones;">
                      <div class="col-2">
                        <input type="radio" class="mt-1" value="{{userPhone.idx}}" name="mfaPhoneIdx" [(ngModel)]="mfaPhoneIdx">
                      </div>
                      <div class="col-10">
                        <label class="mx-2 mt-1 d-block my-0 py-0" style="width:100%;">{{userPhone.phoneNumber}}</label>                  
                      </div>
                    </div>
                    <ng-container *ngIf="!isLoading">
                    <div class="row text-end">
                      <button class="btn primary-button custom-button mt-3 ms-auto" (click)="sendVerifcationCode({mfaBtnID:'sustainlab_recaptcha',mfaType:'auth'})">Send code</button>
                    </div> 
                  </ng-container> 
                    <div class="ball-pulse loader-primary" *ngIf="isLoading">
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                    </div>                            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section class="row flexbox-container" *ngIf="phone">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="">
                        <div class="side-menu pe-1">
                          <div class="mx-auto mb-1">
                            <a type="button" class="btn btn-float first nav-link"  (click)="goToLogin()">
                              <i class="la la-arrow-left mx-auto"></i> <span class="mx-auto" > Back </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <p class="my-auto pt-3 ps-2">Back to main login screen</p>
                      </div>
                    </div>
                    <div class="row mb-0 pb-0">
                      <p>Please select the phone number that you would like to receive the <b>verification code</b>.</p>
                    </div>
                    <div class="row border-b ">
                      <input type="radio" class="mt-1">
                      <label class="mx-2 mt-1 d-block my-0 py-0"><i class="fi fi-se me-2"></i>+46 123 456 7890</label> 
                      <div class="w-100 m-0 p-0 ps-4 pb-2">
                        <small class=" m-0 p-0" style="color: gray;">Standard rates apply</small>
                      </div>                   
                    </div>
                    <div class="row border-b pt-2">
                      <input type="radio" class="mt-1">
                      <label class="mx-2 mt-1 d-block my-0 py-0"><i class="fi fi-se me-2"></i>+46 123 456 7890</label> 
                      <div class="w-100 m-0 p-0 ps-4 pb-2">
                        <small class=" m-0 p-0" style="color: gray;">Standard rates apply</small>
                      </div>                     
                    </div> 
                    <div class="row text-end">
                      <button class="btn primary-button custom-button mt-3 ms-auto">Send code</button>
                    </div> 
                    <div class="ball-pulse loader-primary" *ngIf="isLoading">
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                    </div>                            
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="row flexbox-container" *ngIf="mfaRequired && mfaScreen == 'VERIFY_CODE'">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="">
                        <div class="side-menu pe-1">
                          <div class="mx-auto mb-1">
                            <a type="button" class="btn btn-float first nav-link" (click)="goToLogin()">
                              <i class="la la-arrow-left mx-auto"></i> <span class="mx-auto"> Back </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <p class="my-auto pt-3 ps-2">Back to select phone number</p>
                      </div>
                    </div>
                    <div class="row">
                      <h3 class="mx-auto mb-3"><i class="la la-check-circle-o grad-green me-1" style="font-size:2rem; position: relative;top:3px;"></i>Verification code sent!</h3>
                      <ng-container *ngFor="let userPhone of userPhones">
                        <p>We have sent a <b>verification code</b> to your phone number
                          <b>ending in {{userPhones[0].phoneNumber}}</b>. To proceed, please enter the code you received in the space provided below.</p>
                      </ng-container>                    
                        <div class="error-message" *ngIf="showErrorMessage">
                          The verification code you entered doesn't seem to match our records. Could you please try again?
                        </div>                      
                    </div>
                    <div class="row mb-40">
                     <input type="text" class="form-control" placeholder="Enter verification code" [(ngModel)]="mfaVerificationCode" >                                   
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-12 col-lg-8 text-start ms-0 ps-0 text-lg-start text-sm-start">
                          <small > Haven't received it yet?<a class="underline dark-text ms-2" (click)="sendVerifcationCode({mfaBtnID:'sustainlab_recaptcha',mfaType:'auth'})"><b>Resend new code</b></a> </small>
                      </div>
                      <ng-container *ngIf="!isLoading">
                      <div class="col-sm-12 col-lg-4 text-lg-right mx-0 px-0 text-sm-start">
                        <button class="btn primary-button custom-button mt-2 ms-0" (click)="verifyMfaCode()">Verify</button>
                      </div>
                    </ng-container>
                      <div class="ball-pulse loader-primary" *ngIf="isLoading">
                        <div style="background-color: var(--main-dark-color1)"></div>
                        <div style="background-color: var(--main-dark-color1)"></div>
                        <div style="background-color: var(--main-dark-color1)"></div>
                      </div>
                    </div>                                                                                 
                  </div>
                  <div class="mt-auto text-right">
                    <a class="underline dark-text ms-auto"><small>Did not receive code? Help</small></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="row flexbox-container" *ngIf="sendLink">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">                   
                      <p class="text-justify">To continue, please verify it is you. Then we will send a link to update your registered phone number to
                        get the verification code.</p>
                        <div class="error-message" *ngIf="showErrorMessage">
                          The verification code you entered doesn't seem to match our records. Could you please try again?
                        </div>                      
                    </div>
                    <div class="row mb-3">
                     <input type="email" class="form-control mb-3" value="john@email.com" readonly/>
                     <input type="password" class="form-control"  placeholder="Your password" />                            
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-12 mx-0 px-0 text-end">
                        <button class="btn primary-button custom-button mt-2 ms-auto" routerLink="/op/forgotpassword" >Send link</button>
                      </div>
                    </div>                                                                                 
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="row flexbox-container" *ngIf="addPhone">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <h3 class="mx-auto mb-3">Two factor authentication</h3>
                      <p>To increase the security of your account,
                        <b>two factor authentication</b> is required.
                        Please register a <b>phone number</b> to continue.</p>                                            
                    </div>
                    <ng-container *ngFor="let userPhone of userPhones; index as i">
                      <div class="row border-b d-flex flex-row justify-content-between pb-3 mb-3">
                        <div class="">
                          <label class="mx-2 mt-1 d-block my-0 py-0">
                            <i class="fi fi-se me-2"></i>{{userPhone.number}}
                          </label>
                        </div>
                        <div class="text-end mb-0 pb-0">
                          <a class="ms-auto mb-0 pb-0" (click)="deletePhoneNumber(i)">
                            <i class="la la-trash-o m-auto mb-0 pb-0" style="position:relative; top:4px;"></i>
                          </a>
                        </div>
                      </div>
                    </ng-container>                  
                    <div class="row ">
                      <a class="mx-2 mt-1 d-block my-0 py-0"><i class="la la-plus me-2" style="font-weight: bold;"></i>Add phone number</a>        
                    </div>
                    <div class="row text-end">
                      <button class="btn primary-button custom-button mt-3 ms-auto">Done</button>
                    </div>
                    <div class="ball-pulse loader-primary" *ngIf="isLoading">
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                      <div style="background-color: var(--main-dark-color1)"></div>
                    </div>                             
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="row flexbox-container" *ngIf="editPhone">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="col-lg-5 col-md-8 col-10 box-shadow-2 pb-0 pe-0 ps-0 mb-4">
              <div class="card border-grey border-lighten-3 px-3 py-3 m-0">
                <div class="card-header border-0 pb-0">
                  <div class="card-title text-center">
                    <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" />
                  </div>
                </div>
                <div class="card-content">
                  <div class="card-body">
                    <div class="row">
                      <h3 class="mx-auto mb-3">Registered phone numbers</h3>
                      <p>You can add or remove phone numbers to get a verification code.</p>                                            
                    </div>
                    <ng-container *ngFor="let userPhone of userPhones; index as i">
                      <div class="row border-b d-flex flex-row justify-content-between pb-3 mb-3">
                        <div class="">
                          <label class="mx-2 mt-1 d-block my-0 py-0">
                            <i class="fi fi-se me-2"></i>{{userPhone.number}}
                          </label>
                        </div>
                        <div class="text-end mb-0 pb-0">
                          <a class="ml-auto mb-0 pb-0" (click)="deletePhoneNumber(i)">
                            <i class="la la-trash-o m-auto mb-0 pb-0" style="position:relative; top:4px;"></i>
                          </a>
                        </div>
                      </div>
                    </ng-container>  
                    <div class="row ">
                      <div class="col-3 mx-0 px-0">
                        <div class="dropdown ms-0 ps-0" >
                          <button class="btn dropdown-toggle ms-0 ps-2" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span ><i class="fi fi-se"></i> +46</span>
                          </button>
                          <div class="dropdown-menu m-0" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item m-0 "   ><i class="fi fi-gb"></i> +44 </a>
                            <a class="dropdown-item m-0 "  ><i class="fi fi-se"></i> +46 </a>
                            <a class="dropdown-item m-0 "   ><i class="fi fi-gb"></i> +44 </a>
                            <a class="dropdown-item m-0 "  ><i class="fi fi-se"></i> +46 </a>
                            <a class="dropdown-item m-0 "   ><i class="fi fi-gb"></i> +44 </a>
                            <a class="dropdown-item m-0 "  ><i class="fi fi-se"></i> +46 </a>
                            <a class="dropdown-item m-0 "   ><i class="fi fi-gb"></i> +44 </a>
                            <a class="dropdown-item m-0 "  ><i class="fi fi-se"></i> +46 </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-9 me-0 pe-0">
                        <input class="form-control w-100" type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required>
                      </div>    
                    </div>
                    <div class="row float-end">
                      <button class="btn secondary-button custom-button mt-3 me-2">Cancel</button>
                      <button class="btn primary-button custom-button mt-3 ms-auto">Confirm</button>
                    </div>                             
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    
      </div>
    </div>
  </div>
  <script src="https://apis.google.com/js/platform.js?onload=renderButton" async defer></script>
</body>
