export class TimeOptionFe {
  constructor(public readonly timeScale: string, public readonly values: string[]){}

  public timeScaleToWord() {
    if (this.timeScale == 'year') {
      return 'Year'
    } else if (this.timeScale == 'quarter') {
      return 'Quarter'
    } else if (this.timeScale == 'month' ) {
      return 'Month'
    } else if (this.timeScale == 'week' ) {
      return 'Week'
    } else {
      return this.timeScale
    }
  }

  public timeScaleToAdverb() {
    if (this.timeScale == 'year') {
      return 'Yearly'
    } else if (this.timeScale == 'quarter') {
      return 'Quarterly'
    } else if (this.timeScale == 'month' ) {
      return 'Monthly'
    } else if (this.timeScale == 'week' ) {
      return 'Weekly'
    } else {
      return this.timeScale
    }
  }

}