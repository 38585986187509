import { DateUtil } from "src/app/utils/DateUtil";
import { QUESTION_TYPE } from "../../request/questionnaire/QuestionTypeFe";;
import { AnswerFe } from "./AnswerFe";

export class AnswerDateFe extends AnswerFe{
  dateFrom: Date
  dateTo: Date | null

  constructor(
    id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    dateFrom: Date,
    dateTo: Date | null
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.DATE,
      comments
    )
    this.dateFrom = dateFrom ? new Date(dateFrom) : dateFrom
    this.dateTo = dateTo ? new Date(dateTo) : dateTo
  }

  public static fromTransfer(transfer: any): AnswerDateFe {
    let answer = new AnswerDateFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.dateFrom,
      transfer.dateTo
    )
    return answer;
  } 

  getDateFromString () {
    if (this.dateFrom) {
      this.dateFrom = new Date(this.dateFrom)
      return DateUtil.toString2(this.dateFrom)
    }
  }

  getDateToString () {
    if (this.dateTo) {
      this.dateTo = new Date(this.dateTo)
      return DateUtil.toString2(this.dateTo)
    }
  }

  public hasAnyValue(): boolean {
    return !!this.dateFrom 
  }
}
