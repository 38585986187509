import { QUESTION_TYPE } from "../../request/questionnaire/QuestionTypeFe";;
import { AnswerFe } from "./AnswerFe";

export class AnswerSingleChoiceFe extends AnswerFe{
  selectedOptionId: string

  constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    selectedOptionId: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.SINGLE_CHOICE,
      comments
    )
    this.selectedOptionId = selectedOptionId
  }

  public static fromTransfer(transfer: any): AnswerSingleChoiceFe {
    let answer = new AnswerSingleChoiceFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.selectedOptionId
    )
    return answer;
  }

  public hasAnyValue(): boolean {
    return this.selectedOptionId ? true : false
  }

}
