import { HostListener, Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { AutomatedFeedInfoFe } from "src/app/model/automatedFeed/AutomatedFeedInfoFe";
import { AutomatedFeedSystemFe } from "src/app/model/automatedFeed/AutomatedFeedSystemFe";
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe";
import { RequestFe } from "src/app/model/data-suppliers/request/RequestFe";
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { MAX_RECENTLY_OPENED_ITEMS, RecentlyOpenedItemFe, RecentlyOpenedItemTypeFe } from "src/app/model/datahub/RecentlyOpenedItemFe";
import { View } from "src/app/model/datahub/View";
import { DirectEntryInfoFe } from "src/app/model/directEntry/DirectEntryInfoFe";
import { FileDataFe } from "src/app/model/file/FileDataFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { ContactFe } from "src/app/model/user/ContactFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Injectable({
  providedIn: 'root',
})


export class DatahubService extends AbstractLanguageComponent {

  filter: string = 'allData';
  filterChanged= new Subject<string>();
  selectedItemToFilter: EntityFe | RequestGroupFe | SupplierCompanyFe | ContactFe 
  itemSelectedToFilterSubject = new Subject<EntityFe | RequestGroupFe | SupplierCompanyFe | ContactFe>();

  views = {}
  activeView: View
  previousView: View

  selectedRg: RequestGroupFe = null
  selectedScrollRg: RequestGroupFe = null
  selectedEntity: EntityFe = null
  selectedDataEntry: DirectEntryInfoFe = null
  selectedFile: FileDataFe = null
  selectedAutomatedFeed: AutomatedFeedInfoFe = null

  exportRgResult = new Subject<boolean>();
  exportMasterTable = new Subject<boolean>();
  connecToPipeline = new Subject<boolean>();
  disconnectFromPipeline = new Subject<boolean>();
  openFileExtractor = new Subject<boolean>();
  exportDirectEntry = new Subject<boolean>();
  exportFile = new Subject<boolean>();
  exportFileType: string

  taxonomy: TaxonomyInfoFe;

  constructor(public languageService: LanguageService, private stateService: StateServiceFe) {
    super(languageService)
    this.stateService.getTaxonomyInfos().then(({depTaxonomy, newTaxonomy})=> {
      this.taxonomy = depTaxonomy
    });
    this.languageService.languageSubject.subscribe(changed => this.setView(this.activeView?.name))
    this.stateService.workspaceSubject.subscribe(changed => this.resetAll());
  }

  resetAll() {
    this.filter = 'allData'; 
    this.selectedItemToFilter = null;
    this.activeView = null
    this.previousView = null
    this.selectedRg = null
    this.selectedEntity = null
    this.selectedDataEntry = null
    this.selectedFile = null
    this.selectedAutomatedFeed = null
    this.taxonomy = null
    this.stateService.getTaxonomyInfos().then(({depTaxonomy, newTaxonomy})=> {
      this.taxonomy = depTaxonomy
    });
  }

  setFilter(filter: string) {
    this.filter = filter;
    this.selectItemToFilter(null)
    this.filterChanged.next(filter);
  }

  isFilter(filter: string) {
    return this.filter == filter;
  }

  isAnyFilter() {
    return this.filter != 'allData'
  }

  isDataCategoryFilter() {
    return this.filter == 'dataCategory'
  }

  isRequestFilter() {
    return this.filter == 'request'
  }

  showRgResult(rg: RequestGroupFe) {
    this.selectedRg = rg
    let item = new RecentlyOpenedItemFe(rg.id, RecentlyOpenedItemTypeFe.REQUEST, new Date())
    item.item = rg
    this.addRecentlyOpenedItem(item)
    this.setView('rgResultView');
  }

  showEntityMasterTable(entity: EntityFe) {
    this.selectedEntity = entity
    let item = new RecentlyOpenedItemFe(entity.key, RecentlyOpenedItemTypeFe.MASTER_TABLE, new Date())
    item.item = entity
    this.addRecentlyOpenedItem(item)
    this.setView('masterTableView');
  }

  showDataEntry(info: DirectEntryInfoFe) {
    this.selectedDataEntry = info
    let item = new RecentlyOpenedItemFe(info.id, RecentlyOpenedItemTypeFe.DIRECT_ENTRY, new Date())
    item.item = info
    this.addRecentlyOpenedItem(item)
    this.setView('dataEntryView');
  }
  
  showFile(file: FileDataFe) {
    this.selectedFile = file
    let item = new RecentlyOpenedItemFe(file.id, RecentlyOpenedItemTypeFe.FILE, new Date())
    item.item = file
    this.addRecentlyOpenedItem(item)
    this.setView('fileView');
  }

  showFeed(info: AutomatedFeedInfoFe) {
    this.selectedAutomatedFeed = info
    let item = new RecentlyOpenedItemFe(info.id, RecentlyOpenedItemTypeFe.AUTOMATED_FEED, new Date())
    item.item = info
    this.addRecentlyOpenedItem(item)
    this.setView('automatedFeedView');
  }

  addRecentlyOpenedItem(item: RecentlyOpenedItemFe) {
    this.stateService.activeWorkspace.recentlyOpenedItems = this.stateService.activeWorkspace.recentlyOpenedItems.filter(i => i.id != item.id)
    let newLength = this.stateService.activeWorkspace.recentlyOpenedItems.unshift(item)
    if (newLength > MAX_RECENTLY_OPENED_ITEMS) {
      this.stateService.activeWorkspace.recentlyOpenedItems = this.stateService.activeWorkspace.recentlyOpenedItems.splice(0, MAX_RECENTLY_OPENED_ITEMS);
    }
  }
  
  renderViews() {
    this.views = {
      'homeView': new View('homeView', [this.locale('locale_key.pages.data_hub.title')]),
      'doSubmissionsView': new View('doSubmissionsView', [this.locale('locale_key.pages.data_hub.title'),  this.locale('locale_key.pages.datahub.data_hub_home.table_header.data_reporter_submissions')], 'homeView'),
      'rgResultView': new View('rgResultView', [this.locale('locale_key.pages.data_hub.title'),  this.locale('locale_key.pages.datahub.data_hub_home.table_header.data_reporter_submissions'), this.selectedRg?.title], 'doSubmissionsView'),
      'masterTablePortalView': new View('masterTablePortalView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.button.master_tables')], 'homeView'),
      'masterTableView': new View('masterTableView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.button.master_tables'), this.selectedEntity?.getTaxonomyTitle(this.taxonomy)], 'masterTablePortalView'),
      'dataEntriesPortalView': new View('dataEntriesPortalView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.table_header.direct_data_entries')], 'homeView'),
      'dataEntryView': new View('dataEntryView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.table_header.direct_data_entries'), this.selectedDataEntry?.taxonomyName(this.languageService)], 'dataEntriesPortalView'),
      'filesPortalView': new View('filesPortalView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.table_header.files')], 'homeView'),
      'fileView': new View('fileView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.table_header.files'), this.selectedFile?.label], 'filesPortalView'),
      'automatedFeedPortalView': new View('automatedFeedPortalView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed')], 'homeView'),
      'automatedFeedView': new View('automatedFeedView', [this.locale('locale_key.pages.data_hub.title'), this.locale('locale_key.pages.datahub.data_hub_home.table_header.automated_data_feed'), this.selectedAutomatedFeed?.taxonomyName()], 'automatedFeedPortalView'),
    }
  }

  setView(viewName: string) {
    this.previousView = this.activeView;
    this.renderViews();
    this.activeView = this.views[viewName];
  }

  setAsDataHubView() {
    this.setFilter('allData')
    this.setView('homeView')
  }

  isAllDataFilter() {
    return this.filter === 'allData'
  }

  isView(name: string): boolean {
    return this.activeView.name === name;
  }

  goToPrevious() {
    this.setView(this.activeView.backViewName);
  }
  
  setAsHomeView() {
    this.setView('homeView')
  }

  isHomeView() {
    return this.activeView.name === 'homeView';
  }

  isDOSubmissionsView() {
    return this.activeView.name === 'doSubmissionsView';
  }

  isRgResultView() {
    return this.activeView.name === 'rgResultView';
  }

  isMasterTablePortalView() {
    return this.activeView.name === 'masterTablePortalView';
  }

  isMasterTableView() {
    return this.activeView.name === 'masterTableView';
  }

  isDataEntriesPortalView() {
    return this.activeView.name === 'dataEntriesPortalView';
  }
  
  isDataEntryView() {
    return this.activeView.name === 'dataEntryView';
  }
  
  isFilesPortalView() {
    return this.activeView.name === 'filesPortalView';
  }

  isFilesView() {
    return this.activeView.name === 'fileView';
  }

  selectItemToFilter(item: EntityFe | RequestGroupFe | SupplierCompanyFe | ContactFe): void {
    this.selectedItemToFilter = item;
    this.itemSelectedToFilterSubject.next(item);
  }

  getSelectdTaxonomyName(taxonomy: TaxonomyInfoFe): string {
    let entity = this.selectedItemToFilter
    if (entity instanceof EntityFe) {
      let parent = taxonomy.parentEntity(entity.key)
      let root = taxonomy.rootParentEntity(entity.key)
      return `${root.getLabel()} > ${parent.getLabel()} > ${entity.getLabel()}`
    }
  }

  getSelectedContactName(): string {
    let contact = this.selectedItemToFilter
    if (contact instanceof ContactFe) {
      return contact.getName()
    }
  }

  getSelectedFilterString(): string {
    switch (this.filter) {
      case 'dataCategory': return 'locale_key.pages.datahub.button.data_category';
      case 'company': return 'locale_key.pages.datahub.button.company';
      case 'dataReporter': return 'locale_key.pages.datahub.button.data_reporter';
      case 'dataRequest': return 'locale_key.pages.datahub.button.request';
      default: return 'locale_key.pages.datahub.button.all_data';
    }
  }

  async getFilteredRequestGroups(): Promise<RequestGroupFe[]> {

    let allRequestGroups = await this.stateService.getRequestGroups();
    let filteredRequestGroups: RequestGroupFe[] = []
    if (this.selectedItemToFilter instanceof EntityFe) {
      allRequestGroups.forEach((requestGroup) => {
        if (requestGroup.requests[0]?.questionnaire?.sections) {
          for (let section of requestGroup.requests[0].questionnaire.sections) {
            if (section.taxonomyKey == this.selectedItemToFilter['key']) {
              filteredRequestGroups.push(requestGroup)
              return;
            }
          }
        }
      })

    } else if (this.selectedItemToFilter instanceof RequestGroupFe) { 
      return [this.selectedItemToFilter]
      
    } else if (this.selectedItemToFilter instanceof SupplierCompanyFe) {
      
      allRequestGroups.forEach((requestGroup) => {
        if (requestGroup.requests) {
          for (let request of requestGroup.requests) {
            if (request.supplierCompanyId == this.selectedItemToFilter['supplierCompanyId']) {
              filteredRequestGroups.push(requestGroup)
              return;
            }
          }
        }
      })
      
    } else if (this.selectedItemToFilter instanceof ContactFe) {
      
      allRequestGroups.forEach((requestGroup) => {
        if (requestGroup.requests) {
          for (let request of requestGroup.requests) {
            if (request.assigneeAffId == this.selectedItemToFilter['affiliationId']) {
              filteredRequestGroups.push(requestGroup)
              return;
            }
          }
        }
      })
      
    } else {
      filteredRequestGroups = allRequestGroups
    }
    
    return filteredRequestGroups
  }

  async getFilteredFiles() {
    let allFiles = await this.stateService.getAllFiles();
    let filteredFiles = []
    if (this.selectedItemToFilter instanceof EntityFe) {
      let filteredRequests: RequestFe[] = []
      let filteredRGs = await this.getFilteredRequestGroups();
      filteredRGs.forEach((requestGroup) => {
        filteredRequests.push(...requestGroup.requests)
      })
      
      allFiles.forEach(file => {
        if (filteredRequests.find(req => req.id == file.requestId)) {
          filteredFiles.push(file)
        }
      })

    } else if (this.selectedItemToFilter instanceof RequestGroupFe) {
            
      allFiles.forEach(file => {
        if (this.selectedItemToFilter['requests'].find(req => file.requestId)) {
          filteredFiles.push(file)
        }
      })

    } else if (this.selectedItemToFilter instanceof SupplierCompanyFe) {
      filteredFiles = allFiles.filter(file => file.uploaderCompanyId == this.selectedItemToFilter['supplierCompanyId'])

    } else if (this.selectedItemToFilter instanceof ContactFe) {
      filteredFiles = allFiles.filter(file => file.uplaoderAffId == this.selectedItemToFilter['affiliationId'])

    } else {
      filteredFiles = allFiles
    }

    return filteredFiles
  }

  async getFilteredEntites(): Promise<EntityFe[]>{
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    let allEntities = depTaxonomy.entities
    if (this.selectedItemToFilter instanceof EntityFe) {
      return [this.selectedItemToFilter]

    } else if (this.selectedItemToFilter instanceof RequestGroupFe) {
      let taxonomyKeys: Set<string> = new Set();
      this.selectedItemToFilter.requests[0]?.questionnaire?.sections.forEach(section => {
        if (section.taxonomyKey) {
          taxonomyKeys.add(section.taxonomyKey)
        }
      })
      let entities = allEntities.filter(entity => taxonomyKeys.has(entity.key))
      return entities

    } else if (this.selectedItemToFilter instanceof SupplierCompanyFe) {
      
      let requestGroups = await this.stateService.getRequestGroups();
      let filteredRequestGroups = []
      requestGroups.forEach((requestGroup) => {
        if (requestGroup.requests) {
          for (let request of requestGroup.requests) {
            if (request.supplierCompanyId == this.selectedItemToFilter['supplierCompanyId']) {
              filteredRequestGroups.push(requestGroup)
              return;
            }
          }
        }
      })
      
      let taxonomyKeys: Set<string> = new Set();
      filteredRequestGroups.forEach(rg => {
        rg.requests[0]?.questionnaire?.sections.forEach(section => {
          if (section.taxonomyKey) {
            taxonomyKeys.add(section.taxonomyKey)
          }
        })
      })

      let entities = allEntities.filter(entity => taxonomyKeys.has(entity.key))
      return entities
    } else if (this.selectedItemToFilter instanceof ContactFe) {
      
      let requestGroups = await this.stateService.getRequestGroups();
      let filteredRequestGroups = []
      requestGroups.forEach((requestGroup) => {
        if (requestGroup.requests) {
          for (let request of requestGroup.requests) {
            if (request.assigneeAffId == this.selectedItemToFilter['affiliationId']) {
              filteredRequestGroups.push(requestGroup)
              return;
            }
          }
        }
      })
      
      let taxonomyKeys: Set<string> = new Set();
      filteredRequestGroups.forEach(rg => {
        rg.requests[0]?.questionnaire?.sections.forEach(section => {
          if (section.taxonomyKey) {
            taxonomyKeys.add(section.taxonomyKey)
          }
        })
      })      
      let entities = allEntities.filter(entity => taxonomyKeys.has(entity.key))
      return entities

    } else {
      return depTaxonomy.childrenSortedByOrdinal()
    }
  }
  
  async getFilteredDirectEntries(): Promise<DirectEntryInfoFe[]>{
    let directEntries = await this.stateService.getStageTableInfos();
    let filteredDirectEntries = []
    if (this.selectedItemToFilter instanceof EntityFe) {
      directEntries.forEach(entry => {
        if ((this.selectedItemToFilter as EntityFe).key == entry.taxonomyKey) {
          filteredDirectEntries.push(entry)
        }
      })
    } else if (this.selectedItemToFilter instanceof RequestGroupFe || this.selectedItemToFilter instanceof SupplierCompanyFe || this.selectedItemToFilter instanceof ContactFe) {
      return []
    } else {
      filteredDirectEntries = directEntries
    }

    return filteredDirectEntries
  }

  async getFilteredAutomatedFeeds(): Promise<AutomatedFeedInfoFe[]>{
    let automatedFeedSystems = await this.stateService.getAutomatedFeedSystems();
    let automatedFeeds = []
    automatedFeedSystems.forEach(system => automatedFeeds.push(...system.automatedFeeds))
    let filteredFeeds = []
    if (this.selectedItemToFilter instanceof EntityFe) {
      automatedFeeds.forEach(feed => {
        if ((this.selectedItemToFilter as EntityFe).key == feed.taxonomyKey) {
          filteredFeeds.push(feed);
        }
      })
    } else if (this.selectedItemToFilter instanceof RequestGroupFe || this.selectedItemToFilter instanceof SupplierCompanyFe || this.selectedItemToFilter instanceof ContactFe) {
      return []
    } else {
      filteredFeeds = automatedFeeds
    }
    return filteredFeeds
  }

  async getFilteredAutomatedSystems(): Promise<AutomatedFeedSystemFe[]>{
    let automatedFeedSystems = await this.stateService.getAutomatedFeedSystems();
    let filteredSystems = automatedFeedSystems.map(system => system.copy());
    if (this.selectedItemToFilter instanceof EntityFe) {
      automatedFeedSystems.forEach(system => {
        let feeds = system.automatedFeeds.filter(feed => (this.selectedItemToFilter as EntityFe).key == feed.taxonomyKey)
        system.automatedFeeds = feeds;
      })
      filteredSystems = automatedFeedSystems.filter(system => system.automatedFeeds.length > 0);
    } else if (this.selectedItemToFilter instanceof RequestGroupFe || this.selectedItemToFilter instanceof SupplierCompanyFe || this.selectedItemToFilter instanceof ContactFe) {
      return []
    } 
    return filteredSystems
  }

  setExportFileType(type: string) {
    this.exportFileType = type
  }
}