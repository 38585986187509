import { QUESTION_TYPE } from "../../request/questionnaire/QuestionTypeFe";
import { AnswerFe } from "./AnswerFe"

export class AnswerMultipleChoiceFe extends AnswerFe{
  selectedOptionIds: string[]

  constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    selectedOptionIds: string[]
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.MULTIPLE_CHOICE,
      comments
    )
    this.selectedOptionIds = selectedOptionIds
  }

  public static fromTransfer(transfer: any): AnswerMultipleChoiceFe {
    let answer = new AnswerMultipleChoiceFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.selectedOptionIds
    )
    return answer;
  }

  public hasAnyValue(): boolean {
    return this.selectedOptionIds.length != 0
  }
}
