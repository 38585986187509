export class RGB {

  constructor(public r: number, public g: number, public b:number){}

  public static hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return new RGB(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16))
    } else {
      return null
    }
  }

  public rgba(opacity: number): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${opacity})`
  } 
}