import { FormControl, Validators } from "@angular/forms"

export class QuestionChoiceOptionFe {
    id: string
    value: string
    desc: string | null
    
    showDesc = false
    noOfDataOwners = 0
    valueControl = new FormControl(null, Validators.required)
    descControl = new FormControl(null, Validators.required)

    constructor (
        id: string, 
        value: string,
        desc: string | null
    ) {
        this.id = id;
        this.value = value;
        this.desc = desc;
    }

    public static fromTransfer(transfer: any): QuestionChoiceOptionFe {
        let item = new QuestionChoiceOptionFe (
            transfer.id,
            transfer.value,
            transfer.desc
        )
        item.showDesc = !!transfer.desc
        return item
      }
}