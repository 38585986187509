import { Language } from 'src/app/utils/language/Language'
import { CompanyCustomEmailTemplateFe } from './CompanyCustomEmailTemplateFe'

export class EmailTemplateFe {
  fileName: string
  html: string
  companyCustomEmailTemplates: CompanyCustomEmailTemplateFe[] | undefined
  transitLanguage: Language

  constructor(fileName: string, html: string, companyCustomEmailTemplates: CompanyCustomEmailTemplateFe[] | undefined, language?: Language) {
    this.fileName = fileName
    this.html = html
    this.companyCustomEmailTemplates = companyCustomEmailTemplates
    this.transitLanguage = language
  }

  public static fromTransfer(transfer: any): EmailTemplateFe {
    return new EmailTemplateFe(transfer.fileName, transfer.html, transfer.companyCustomEmailTemplates)
  }
}
