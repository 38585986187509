import { ColumnSchemaFe } from "./ColumnSchemaFe"
import { ReferenceColumnSchemaFe } from "./ReferenceColumnSchemaFe"

export class TableDefFe {
  public key: string
  public label: string
  public name: string
  public managementSchema: ColumnSchemaFe[] = []
  public referenceSchema: ReferenceColumnSchemaFe[] = []
  public dataSchema: ColumnSchemaFe[] = []
}