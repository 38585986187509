export class QUESTION_TYPE {
    public static DATA_TABLE: string = "DATA TABLE"
    public static DATE: string = "DATE"
    public static MULTIPLE_CHOICE: string = "MULTIPLE CHOICE"
    public static SINGLE_CHOICE: string = "SINGLE CHOICE"
    public static NUMBER: string = "NUMBER"
    public static TEXT: string = "TEXT"
    public static ATTACHMENT: string = "ATTACHMENT"
    public static EMISSION_FACTOR: string = "EMISSION_FACTOR"

}