import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesFe } from 'src/app/route/RoutesFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { UserServiceFe } from 'src/app/services/UserServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';

@Component({
  selector: 'cache-loading',
  templateUrl: './cache-loading-progress.component.html',
  styleUrls: ['./cache-loading-progress.component.scss']
})
export class CacheLoadingProgressComponent extends AbstractLanguageComponent implements OnInit {

  constructor(private stateService: StateServiceFe, private router: Router, private userService: UserServiceFe,  languageService: LanguageService) { 
    super(languageService)
    if (stateService.cacheLoaded) {
      if (userService.isUserAVisitor()) {
        this.router.navigate([RoutesFe.VISITOR_SHARED_DASHBOARD.fullPath()]);
      } else {
        this.router.navigate([RoutesFe.HOME.fullPath()]);
      }
    }
  }

  ngOnInit(): void {
  }

}
