
<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <nav>
          <ul class="nav">
            <ng-container *ngFor="let item of datahubService.activeView?.header; let first = first; let last = last">
              <li class="navbar-item" *ngIf="!first">
                <h3 class="mt-2 mx-3"><i class="la la-angle-right font-medium-1"></i></h3>
              </li>
              <li class="navbar-item cursor">
                <a (click)="first ? datahubService.setView('homeView') : !last ? datahubService.setView(datahubService.activeView.backViewName) : null">
                  <h3 class="mt-2">{{ getItemString(item) }}</h3>
                </a>
              </li>
            </ng-container>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
            <div class="mx-auto" *ngIf="isLoaderVisibleInToolbar()">
              <div class="ball-pulse loader-primary">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible() && toolbarButton.actionName !=='export_master_table' && toolbarButton.actionName !=='export_data_entry' && toolbarButton.actionName !== 'export_file'"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
              <div class="dropdown" *ngIf="toolbarButton.visible() && (toolbarButton.actionName === 'export_master_table' || toolbarButton.actionName === 'export_data_entry' || toolbarButton.actionName === 'export_file')">
                <button
                  type="button"
                  class="btn page-toolbar-button"
                  data-bs-toggle="dropdown"
                  data-placement="top"
                  [title]="toolbarButton.tooltip"
                  show-delay="3000"
                >
                  <i [class]="toolbarButton.icon" class="fs-4"></i>
                  <span>{{ toolbarButton.shortLabel }}</span>
                </button>
                <ul class="dropdown-menu custom-dropdown-menu">
                  <li><a class="dropdown-item" (click)="handleExportType(toolbarButton.actionName, 'xlsx')">xlsx</a></li>
                  <li><a class="dropdown-item" (click)="handleExportType(toolbarButton.actionName, 'csv')">csv</a></li>
                </ul>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="mx-auto" *ngIf="isLoaderVisibleInToolbar()">
          <div class="ball-pulse loader-primary">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div *ngIf="loadingInProgress">
            <div class="loader-wrapper mt-5">
              <div class="loader-container">
                <div class="ball-grid-pulse loader-primary">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="card " style="height: 84vh" *ngIf="!loadingInProgress">
            <div class="card-header my-auto bg-light-blue">
              <div class="row my-auto" *ngIf="datahubService.isHomeView()">
                <div class="col-xl-6 col-md-12 my-2">
                  <div class="row my-auto" >
                    <div class="col-xl-6 col-lg-6 col-md-12 col-12 my-auto" [ngClass]="{ 'mb-2': screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize() }">
                      <b *ngIf="datahubService.isHomeView()" class="my-auto me-1 text-wrap mb-0 pb-0 dark-text my-auto">{{ locale("locale_key.pages.datahub.card_header") }}</b>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-12 " [ngClass]="{'text-start': screenSize.isSmallerSize() || screenSize.isXSmallSize(), 'text-end': screenSize.isLargeSize() || screenSize.isMediumSize()}">
                      <i class="la la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">{{ locale("locale_key.pages.datahub.info_icon") }}</div>
                      <span class="my-auto me-2">{{ locale("locale_key.pages.datahub.view_by_filter_title") }}</span>
                      <button class="btn bg-white my-auto custom-button mr-1 dark-text" [ngClass]="{ selectedFilter: datahubService.isFilter('allData') }" (click)="datahubService.setFilter('allData')">{{ locale("locale_key.pages.datahub.button.all_data") }}</button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-md-12 my-2">
                  <div class="row d-flex flex-row justify-content-between">
                    <div class="col-6 col-md-3" [ngClass]="{ ' mb-3 ': screenSize.isSmallerSize() || screenSize.isXSmallSize() }">
                      <button class="btn bg-white m-auto col-11 dark-text" *ngIf="datahubService.isHomeView()" [ngClass]="{ selectedFilter: datahubService.isFilter('dataCategory') }" (click)="datahubService.setFilter('dataCategory')">
                        {{ locale("locale_key.pages.datahub.button.data_category") }}
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button class="btn bg-white m-auto col-11 dark-text" *ngIf="datahubService.isHomeView()" [ngClass]="{ selectedFilter: datahubService.isFilter('company') }" (click)="datahubService.setFilter('company')">
                        {{ locale("locale_key.pages.datahub.button.company") }}
                      </button>
                    </div>
                    <div class="col-6 col-md-3" [ngClass]="{ ' mb-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }">
                      <button class="btn bg-white m-auto col-11 dark-text" *ngIf="datahubService.isHomeView()" [ngClass]="{ selectedFilter: datahubService.isFilter('dataReporter') }" (click)="datahubService.setFilter('dataReporter')">
                        {{ locale("locale_key.pages.datahub.button.data_reporter") }}
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button class="btn bg-white m-auto col-11 dark-text" *ngIf="datahubService.isHomeView()" [ngClass]="{ selectedFilter: datahubService.isFilter('dataRequest') }" (click)="datahubService.setFilter('dataRequest')">
                        {{ locale("locale_key.pages.datahub.button.request") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row my-auto float-start" *ngIf="!datahubService.isHomeView()">
                <span class="my-auto me-2">{{ locale("locale_key.pages.datahub.view_by_filter_title") }}</span>
                <button class="btn text-white bg-pink m-auto me-3" [disabled]="!datahubService.isHomeView()">{{ locale(datahubService.getSelectedFilterString()) }}</button>
                <p class="header-text-blue ps-2 mb-0 mt-2" *ngIf="!datahubService.isFilter('allData')" >
                  {{ locale("locale_key.pages.datahub.data_description_paragraph") }}
                  <span class="bold header-text-blue" *ngIf="datahubService.isFilter('dataCategory')">{{ taxonomyName() }}</span>
                  <span class="bold header-text-blue" *ngIf="datahubService.isFilter('dataRequest')">{{ datahubService.selectedItemToFilter["title"] }}</span>
                  <span class="bold header-text-blue" *ngIf="datahubService.isFilter('company')">{{ datahubService.selectedItemToFilter["supplierCompanyname"] }}</span>
                  <span class="bold header-text-blue" *ngIf="datahubService.isFilter('dataReporter')">{{ datahubService.getSelectedContactName() }}</span>
                </p>
              </div>
            </div>
            <hr class="mt-0 pt-0 mx-0 px-0" />
            <div class="card-body mt-0 pt-0" style="border-bottom: none; overflow-x: hidden; overflow-y: scroll; max-height: 80vh" [ngClass]="{ 'px-2': screenSize.isXSmallSize() || screenSize.isSmallerSize() }">
              <datahub-home *ngIf="datahubService.isHomeView()"></datahub-home>
              <do-submissions *ngIf="datahubService.isView('doSubmissionsView')"></do-submissions>
              <data-inventory
                *ngIf="datahubService.isView('rgResultView')"
                [showRequestPortalButton]="true"
                [showHeader]="false"
                [showToolbar]="false"
                [requestGroup]="datahubService.selectedRg"
                (closeInventory)="datahubService.setView('doSubmissionsView')"
              ></data-inventory>
              <master-table-portal *ngIf="datahubService.isView('masterTablePortalView')"></master-table-portal>
              <master-table *ngIf="datahubService.isView('masterTableView')"></master-table>
              <data-entries-portal *ngIf="datahubService.isView('dataEntriesPortalView')"></data-entries-portal>
              <data-entry *ngIf="datahubService.isView('dataEntryView')"></data-entry>
              <files-portal *ngIf="datahubService.isView('filesPortalView')"></files-portal>
              <file-viewer *ngIf="datahubService.isView('fileView')" [inputFile]="datahubService.selectedFile" [mode]="'view'"></file-viewer>
              <automated-feed-portal *ngIf="datahubService.isView('automatedFeedPortalView')"></automated-feed-portal>
              <automated-feed *ngIf="datahubService.isView('automatedFeedView')"></automated-feed>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #initStageTableModal>
  <insert-data-entry (closeModalEvent)="closeModal()"></insert-data-entry>
</ng-template>

<ng-template #upload>
  <div class="card m-0">
    <div class="main-modal-header modal-header py-0 px-3">
      <h4 class="modal-title text-white">{{ locale("locale_key.pages.file_upload.upload_file.title") }}</h4>
      <button type="button" class="ms-auto btn close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="false" class="list-inline text-white"
          ><a (click)="closeModal()"><i class="las la-times text-white"></i></a
        ></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mt-0 px-1">
        <div class="col-sm-12">
          <div class="card hoverable dropZone mt-0 pt-0 mb-1 pb-1" style="border: 2px dotted lightgray; border-radius: 20px">
            <div class="card-body" id="dotted">
               <h5 class="pt-3 mx-auto">{{ locale("locale_key.pages.file_explorer.upload_modal.drag_drop.title") }}</h5>
              <input type="file" class="form-control" id="uploadCaptureInputFile" multiple (change)="selectFilesForUpload($event)"  id="formFile" />
              <small *ngIf="filesToUpload.length > 0">
                {{ filesToUpload.length === 1 ? filesToUpload.length + locale('locale_key.pages.datahub.file') : filesToUpload.length + locale('locale_key.pages.datahub.files') }}
              </small>
             
            </div>
          </div>
        </div>
        <div class="row px-1 mh-200">
          <div class="col-sm-12">
            <div class="files-list">
              <div class="d-flex flex-row my-3" *ngFor="let fileToUpload of filesToUpload; let i = index">
                <div class="px-1 my-auto">
                  <i class="las la-file-medical fs-4 my-auto mb-0 pb-0"></i>
                </div>
                <div class="flex-grow-1 my-auto d-flex flex-row my-auto">
                  <input type="file" class="form-control" id="uploadCaptureInputFile" multiple (change)="selectFilesForUpload($event)" title="{{ filesToUpload.length }} file" placeholder="{{ filesToUpload.length }} file" id="formFile" hidden />
                  <label class="form-label my-auto" for="formFile">{{ fileToUpload?.name }}</label>
                  &nbsp;
                  <label class="fonticon-unit mb-0 ms-auto my-auto">{{ formatBytes(fileToUpload?.size) }}</label>
                  <!-- <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress[i]" aria-valuemin="0" aria-valuemax="100"></div>
                  </div> -->
                  <h4 class="name">
                    {{ fileUploadMessages[i] }}
                  </h4>
                </div>
                <div class="delete my-auto" *ngIf="uploadProgress[i] == 0" (click)="deleteFileFromUpload(i)">
                  <i class="las la-trash-alt px-1 my-auto"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form class="uploadForm" method="post" enctype="multipart/form-data">
      <div class="modal-footer">
        <button type="button" class="btn secondary-button custom-button" id="btn" (click)="closeModal()"> {{ locale("locale_key.general.buttons.close") }}</button>
        <button (click)="uploadFiles()" type="submit" class="btn btn-add custom-button" [disabled]="filesToUpload.length === 0"><i class="fa fa-user"></i>{{ locale("locale_key.pages.file_explorer.upload_modal.button.upload_files") }}</button>
      </div>
    </form>
  </div>
</ng-template>
