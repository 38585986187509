import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EmailFe } from 'src/app/model/email/EmailFe';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';

@Component({
  selector: 'app-email-table',
  templateUrl: './email-table.component.html',
  styleUrls: ['./email-table.component.scss']
})
export class EmailTableComponent extends AbstractLanguageComponent implements OnInit {

  emails: EmailFe[] = [];
  isLoadingData = false;
  sanitizedEmails: SafeHtml[] = [];

  url = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  pageToolbar = [,
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info", actionName:"toggle_quick_tips" , visible:()=> true, disabled: false}, 
    ],        
  ]

  constructor (private stateService: StateServiceFe, languageService: LanguageService, private responsive: ResponsiveService, private displayService: DisplayServiceFe, private sanitizer: DomSanitizer) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
    this.screenSize = responsive.currentScreenWidthSize;
  }

  async ngOnInit(): Promise<void> {
    this.isLoadingData = true;
    this.emails = await this.stateService.getEmails();
    this.sanitizeEmails();
    this.isLoadingData = false;
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  handleToolbarAction(actionName: string) {
    switch(actionName) {
      case "toggle_quick_tips":
        this.toggleTips()
        break;
    }
  }

  isHtml(str: string): boolean {
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlRegex.test(str);
  }

  sanitizeEmails() {
    this.emails.forEach(email => {
      if (this.isHtml(email.body)) {
        email.sanitizedBody = this.sanitizer.bypassSecurityTrustHtml(email.body);
      } else {
        email.body = email.body;
      }
    });
  }
}