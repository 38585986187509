import { AbstractRecipientInfoFe } from './AbstractRecipientInfoFe'
import { RecipientInfo_ActionFe } from './RecipientInfo_ActionFe'

export class RecipientInfo_AddFe extends AbstractRecipientInfoFe {
  firstName: string
  lastName: string
  companyName: string
  affId: string | null
  companyId: string | null
  accessType: string
  resourceName: string
  emailLanguage: string
  includeEnglish: boolean

  constructor(email: string, firstName: string, lastName: string, companyName: string, affId: string | null, companyId: string | null, accessType: string, resourceName: string, emailLanguage?: string, includeEnglish?: boolean) {
    super(email, RecipientInfo_ActionFe.ADD)
    this.firstName = firstName
    this.lastName = lastName
    this.companyName = companyName
    this.affId = affId
    this.companyId = companyId
    this.accessType = accessType
    this.resourceName = resourceName
    this.emailLanguage = emailLanguage
    this.includeEnglish = includeEnglish
  }
}
