export class OrganizationAttributeFe {
  public key: string;
  public label: string;
  public datatype: string;
  public deployed = true

  public oldLabel: string;
  public oldDatatype: string;
  public labelMatch: boolean = true
  public datatypeMatch: boolean = true
  public modified: boolean = false

  constructor(key: string, label: string, datatype: string) {
    this.key = key
    this.label = label
    this.datatype = datatype
  }

  public static fromTransfer(transfer: any): OrganizationAttributeFe {
    let att = new OrganizationAttributeFe(transfer.key, transfer.label, transfer.datatype)
    return att
  }

  public dbColName() {
    return this.key.toUpperCase().replace(/[^a-zA-Z0-9_]/gi, '_')
  }

  diffDeployment(da: OrganizationAttributeFe | undefined) {
    if (da) {
      this.deployed = true
      if (this.label != da.label) {
        this.labelMatch = false
        this.oldLabel = da.label
      } else {
        this.labelMatch = true
      }

      if (this.datatype != da.datatype) {
        this.datatypeMatch = false
        this.oldDatatype = da.datatype
      } else {
        this.datatypeMatch = true
      }

      this.modified = (this.labelMatch && this.datatypeMatch) ? false : true  
    } else {
      this.deployed = false
    }
  }

}