<div *ngIf="isLoadingData">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card m-0" *ngIf="!isLoadingData">
  <div class="main-modal-header modal-header py-0 px-3">
      <h4 *ngIf="mode == 'create'" class="modal-title text-white">{{locale('locale_key.pages.direct_data_entries.add_data_entry_modal.title')}}</h4>
      <h4 *ngIf="mode == 'edit'" class="modal-title text-white">{{locale('locale_key.pages.direct_data_entries.edit_data_entry_modal.title')}}</h4>
      <h4 *ngIf="mode == 'copy'" class="modal-title text-white">{{locale('locale_key.pages.direct_data_entries.copy_data_entry_modal.title')}}</h4>
      <button type="button" class="btn ms-auto close" data-dismiss="modal" aria-label="Close" *ngIf="!inProgress">
         <span aria-hidden="false" class="list-inline text-white" ><a (click)="cancel()"><i class="las la-times text-white" ></i></a></span>
      </button>
  </div>
    <form [formGroup]="form">
      <div class="modal-body">
      <div class="content clearfix">
        <div class="form-group">
          <div class="row px-3">
            <div class="col-md-12">
              <label>{{locale('locale_key.pages.direct_data_entries.modal.data_category.title')}}</label>
              <!-- adds disabled -->
              <input type="text" class="form-control" value="{{taxonomyInfo.categoryFQN(stageTableInfo.taxonomyKey)}}" *ngIf="mode != 'create'" [disabled]="mode !='create'" [readonly]="mode != 'create'">
              <select placeholder="Categories open for data collection" class="form-select form-control" formControlName="taxonomyKey" *ngIf="mode == 'create'" 
              [ngClass]="{
                'is-invalid':
                  form.get('taxonomyKey').errors &&
                  form.get('taxonomyKey').touched 
              }"
              required
              >
              <option value="">{{locale('locale_key.pages.direct_data_entries.modal.placeholder.data_category')}}</option>
            
                <option *ngFor="let t of tableEntities" [ngValue]="t.key">{{taxonomyInfo.categoryFQN(t.key)}}
                </option>
              </select>
            <div
              *ngIf="
              form.get('taxonomyKey').errors &&
              form.get('taxonomyKey').touched 
              "
              class="invalid-feedback"
            >
              <div *ngIf=" form.get('taxonomyKey').errors.required">
                {{dataCategoryMessage}}
              </div>
            </div>
            </div>
          </div>
          <div class="px-3">
          <label>{{locale('locale_key.pages.direct_data_entries.modal.time_period.title')}}</label>
            <div class="card border-light mx-auto">
              <div class="card-body">
                <div class="row " [ngClass]="screenSize.isXSmallSize() ? 'px-0' : 'px-3'">
                  <div class="col-md-6 col-6" [ngClass]="screenSize.isXSmallSize() ? 'ps-0' : ''">
                    <label>{{locale('locale_key.pages.direct_data_entries.from_date.title')}}</label>
                  </div>
                  <div class="col-md-6 col-6">
                    <label>{{locale('locale_key.pages.direct_data_entries.to.title')}}</label>
                  </div>
                </div>
                <div class="row " [ngClass]="screenSize.isXSmallSize() ? 'px-0' : 'px-3'">
                  <div class="col-md-6 col-6" [ngClass]="screenSize.isXSmallSize() ? 'ps-0' : ''">
                    <input type="date" class="form-control m-0" formControlName="timeFrom" [readonly]="mode == 'edit'"  #timeFrom (change)="timeFromChanged()"
                    [ngClass]="{
                      'is-invalid':
                        form.get('timeFrom').errors &&
                        (form.get('timeFrom').touched || form.get('timeFrom').dirty)
                    }"
                    required
                  >
                  <div
                    *ngIf="
                    form.get('timeFrom').errors &&
                    (form.get('timeFrom').touched || form.get('timeFrom').dirty)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.get('timeFrom').errors.required">
                     {{dataStartDateMessage}}
                    </div>
                  </div>
                  </div>
                  <div class="col-md-6 col-6">
                    <input type="date" class="form-control m-0" formControlName="timeTo" [readonly]="mode == 'edit'"
                    [ngClass]="{
                      'is-invalid':
                        form.get('timeTo').errors &&
                        (form.get('timeTo').touched || form.get('timeTo').dirty)
                        
                    }" [min]="timeFrom.value"
                    required
                  >
                  <div
                    *ngIf="
                    form.get('timeTo').errors &&
                    (form.get('timeTo').touched || form.get('timeTo').dirty)
                    "
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.get('timeTo').errors.required">
                     {{dataEndDateMessage}}
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-3">
            <div class="col-md-12">
              <label>{{locale('locale_key.pages.direct_data_entries.modal.source.title')}}</label>
              <input type="text" class="form-control" formControlName="sourceFile"
             
            >
            <!-- <div
              *ngIf="
              form.get('sourceFile').errors &&
              (form.get('sourceFile').touched || form.get('sourceFile').dirty)
              "
              class="invalid-feedback"
            >
              <div *ngIf="form.get('sourceFile').errors?.required">
               {{dataSourceFileMessage}}
              </div>
            </div> -->
            </div>
          </div>

        </div>
      </div>
    </div>
    </form>
    <div class="modal-footer">
      <div class="ball-grid-pulse loader-primary" *ngIf="inProgress">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
      <ng-container *ngIf="!inProgress">
        <button type="submit" class="btn secondary-button custom-button"
          (click)="cancel()">{{locale('locale_key.general.buttons.cancel')}}</button>
        <button type="submit" class="btn btn-add custom-button" 
        [disabled]="!form.valid"
          (click)="saveChages()" *ngIf="mode == 'create'">{{locale('locale_key.pages.direct_data_entries.button.add_data')}}</button>
        <button type="submit" class="btn btn-add custom-button" 
          (click)="saveChages()" *ngIf="mode == 'edit'">{{locale('locale_key.pages.direct_data_entries.button.edit_data')}}</button>
          <button type="submit" class="btn btn-add " 
          (click)="saveChages()" *ngIf="mode == 'copy'">{{locale('locale_key.pages.direct_data_entries.copy_data_entry_modal.title')}}</button>
        <button type="submit" class="btn btn-add custom-button" 
           *ngIf="mode == 'edit'" (click)="cancel();openModal(deleteConfirmation, 'modal-md')">{{locale('locale_key.pages.direct_data_entries.button.delete')}}</button>         
      </ng-container>      
    </div>
  </div>

  <!-- Modal -->
  <ng-template #deleteConfirmation>
      <div class="modal-header py-2 border-0">
        <i class="la la-exclamation-circle fs-4 me-2" style="color:var(--warning-color);"></i><h4 class="modal-title text-truncate" id="exampleModalLabel">{{locale('locale_key.add_data_point.modal.title.message')}}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
      </div>
      <div class="modal-body">
        <h5>{{locale("locale_key.add_data_point.modal.delete.message")}}</h5>
      </div>
      <div class="modal-footer border-0">
        <button type="submit" class="btn secondary-button custom-button"
        (click)="cancel()">{{locale('locale_key.general.buttons.cancel')}}</button>
        <button type="button" class="btn primary-button custom-button" (click)="delete();cancel()">{{locale('locale_key.pages.file_explorer.delete_file_confirmation_modal.button.delete')}}</button>
      </div>
  </ng-template>

