<body class="vertical-layout vertical-menu content-left-sidebar todo-application  menu-expanded pace-done ml-3"
  data-open="click" data-menu="vertical-menu" data-col="content-left-sidebar">
  <!-- BEGIN: Content-->
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="todo-sidebar d-flex">
        <span class="sidebar-close-icon">
          <i class="las la-times"></i>
        </span>
        <!-- todo app menu -->
        <div class="todo-app-menu ml-2">
          <!-- sidebar list start -->
          <div class="sidebar-menu-list  ps ps--active-y overflow-auto">
            <div class="list-group">
              <a (click)="loadAll()" class="list-group-item border-0" [ngClass]="{'active': tab==='Inbox'}">
                <span class="fonticon-wrap mr-50">
                  <i class="ft-align-justify"></i>
                </span>
                <span>{{ locale("locale_key.pages.data_request.inbox_section.title") }}</span>
              </a>
              <a (click)="loadDeleted()" class="list-group-item border-0 " [ngClass]="{'active': tab==='Deleted'}">
                <span class="fonticon-wrap mr-50">
                  <i class="ft-trash-2"></i>
                </span>
                <span>{{ locale("locale_key.wizard.notification.deleted") }}</span>
              </a>
            </div>
            <label class="filter-label mt-2 mb-1 pt-25">{{ locale("locale_key.wizard.notification.filters") }}</label>
            <div class="list-group">
              <a (click)="loadRequests()"
                class="list-group-item border-0 d-flex align-items-center justify-content-between "
                [ngClass]="{'active': tab==='Requests'}">
                <span>{{ locale("locale_key.sustainability_manager_portal.main_navbar.connect.data_requests") }}</span>
                <span class="bullet bullet-sm bullet-primary"></span>
              </a>
              <a (click)="loadFiles()"
                class="list-group-item border-0 d-flex align-items-center justify-content-between "
                [ngClass]="{'active': tab==='Files'}">
                <span>{{ locale("locale_key.pages.datahub.data_hub_home.table_header.files") }}</span>
                <span class="bullet bullet-sm bullet-success"></span>
              </a>
            </div>

          </div>
          <!-- sidebar list end -->
        </div>
      </div>
      <!-- todo new task sidebar -->
    </div>
  </div>
  <div class="content-right">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
      </div>
      <div class="content-body">
        <div class="app-content-overlay"></div>
        <div class="todo-app-area">
          <div class="todo-app-list-wrapper">
            <div class="todo-app-list">
              <div class="todo-fixed-search d-flex justify-content-between align-items-center">
                <div class="sidebar-toggle d-block d-lg-none">
                  <i class="ft-align-justify"></i>
                </div>
                <fieldset class="form-group position-relative has-icon-left m-0 flex-grow-1 pl-2">
                  <input type="text" class="form-control todo-search" id="todo-search" placeholder="{{locale('locale_key.general.search')}}"
                    name="search" [(ngModel)]="searchText" autocomplete="off">
                  <div class="form-control-position">
                    <i class="las la-search"></i>
                  </div>
                </fieldset>
              </div>
              <div class="todo-task-list list-group ps ps--active-y overflow-auto">
                <!-- task list start -->
                <ul class="todo-task-list-wrapper list-unstyled" id="todo-task-list-drag">
                  <li class="todo-item" *ngFor="let notification of notifications; let i = index">
                    <a id="headingCollapse{{i}}" class="collapsable-area" data-bs-toggle="collapse" href="#collapse{{i}}"
                      aria-expanded="false" aria-controls="collapse1" (click)="markRead(notification);">
                      <div class="notification-area">
                        <div
                          class="todo-title-wrapper d-flex justify-content-sm-between justify-content-end align-items-center">
                          <div class="todo-title-area d-flex">

                            <p *ngIf="notification.status == 'NEW'; else otherNotifications"
                              class="todo-title mx-50 m-0 truncate font-weight-bold" style="padding-left: 15px;">
                              <span *ngIf="notification.type == 'file.new' "
                                class="bullet bullet-sm bullet-success mr-2"></span>
                              <span *ngIf="notification.type == 'external.request' "
                                class="bullet bullet-sm bullet-primary mr-2"></span>
                              A {{notification.title}}
                            </p>

                            <ng-template #otherNotifications>
                              <p class="todo-title mx-50 m-0 truncate" style="padding-left: 15px;">
                                <span *ngIf="notification.type == 'file.new' "
                                  class="bullet bullet-sm bullet-success mr-2"></span>
                                <span *ngIf="notification.type == 'external.request' "
                                  class="bullet bullet-sm bullet-primary mr-2"></span>
                                A {{notification.title}}
                              </p>
                            </ng-template>
                          </div>
                          <div class="todo-item-action d-flex align-items-center">
                            <time class="media-meta text-muted"
                              datetime="2015-06-11T18:29:20+08:00">{{notification.time_stamp.value}}</time>&nbsp;

                            <div class="todo-badge-wrapper d-flex">
                              <span *ngIf="notification.type == 'external.request' "
                                class="badge badge-primary badge-pill">{{ locale("locale_key.pages.data_request.toolbar.add") }}</span>
                              <span *ngIf="notification.type == 'file.new' "
                                class="badge badge-success badge-pill">{{ locale("locale_key.pages.datahub.data_hub_home.file") }}</span>
                            </div>
                            <a class="todo-item-delete ml-75" href="javascript:void(0);"
                              (click)="markDeleted(notification)" *ngIf="tab != 'Deleted'"><i
                                class="ft-trash-2"></i></a>
                            <a class="todo-item-delete ml-75" href="javascript:void(0);"
                              (click)="delete(notification)" *ngIf="tab == 'Deleted'"><i
                                class="ft-trash-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="collapse{{i}}" role="tabpanel" aria-labelledby="headingCollapse" class="collapse ml-2">
                      <div class="card-content">
                        <div class="card-body">
                          <div class="bs-callout-success callout-transparent">
                            <div class="media align-items-stretch">
                              <div class="media-body pl-1 mr-0">
                                <p *ngFor="let r of parse(notification.summary).rows">
                                  <b>{{r.label}}:</b>  {{r.value}}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- task list end -->
                <div class="no-results">
                  <h5>{{ locale("locale_key.wizard.not_items_found") }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- END: Content-->
</body>