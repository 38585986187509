import { BooleanStatementFe } from "./BooleanStatementFe"
export abstract class ValueStatementFe extends BooleanStatementFe {

  public value: any

  constructor(value: any) {
    super()
    this.value = value
  }
  
}
