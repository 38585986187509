<div class="details card" style="height:100%">
  <div class="card-body mb-auto p-3" *ngIf="wrapper">
    <div class="row">
      <div class="col-12 px-2">
        <h3>{{locale('locale_key.pages.dashboard.chart_settings.title_for_chart')}}</h3>
      </div>
      <div class="col-12" *ngIf="!isASharedDashboard">
        <button data-bs-toggle="dropdown" type="button" class="btn btn-block action-icon form-select d-flex justify-content-between text-start mb-2">
          <span class="btn-min-width-15">{{ wrapper?.setting.titleOptionText() }}</span>
        </button>
        <ul class="dropdown-menu btn-min-width-18 p-0 mh-200" role="menu">
          <a class="dropdown-item" (click)="wrapper?.setting.setTitleOption('kpi'); onChangedSetting()">{{locale('locale_key.pages.dashboard.chart_settings.options.reuse_kpi_title')}}</a>
          <a class="dropdown-item" (click)="wrapper?.setting.setTitleOption('custom'); onChangedSetting()">{{locale('locale_key.pages.dashboard.chart_settings.options.create_custom_title')}}</a>
          <a class="dropdown-item" (click)="wrapper?.setting.setTitleOption('no'); onChangedSetting()">{{locale('locale_key.pages.dashboard.chart_settings.options.no_title')}}</a>
        </ul>
        <input type="text" class="form-control mt-1" [ngModel]="wrapper?.setting.selectTitle(wrapper?.insight)" [disabled]="true" [readonly]="true" *ngIf="wrapper?.setting.isTitleKpi()" />
        <input type="text" class="form-control mt-1" [(ngModel)]="wrapper?.setting.title" (change)="onChangedSetting();" *ngIf="wrapper?.setting.isTitleCustom()" />
        <input type="text" class="form-control mt-1" value="" [readonly]="true" *ngIf="wrapper?.setting.isNoKpi()" />
      </div>
      <div class="col-12" *ngIf="isASharedDashboard">
        <p>{{wrapper?.insight.title}}</p>
      </div>
    </div>

    <div *ngIf="wrapper?.options.chartTypes && wrapper?.options.chartTypes.length > 1">
      <div class="row pt-3">
        <div class="col-12 px-2">
          <h5>{{locale('locale_key.pages.dashboard.chart_settings.graph_type.title')}}</h5>
        </div>
        <div class="col-12">
          <ng-container *ngFor="let type of wrapper?.options.chartTypes">
            <button type="button" class="btn btn-icon action-icon mt-1" *ngIf="type.id.startsWith('bar')" (click)="wrapper?.setting.setChartType(type); onChangedSetting()"><i class="las la-chart-bar" style="color: purple"></i>{{locale('locale_key.pages.dashboard.chart_settings.graph_type.options.bar')}}</button>
            <button type="button" class="btn btn-icon action-icon mt-1" *ngIf="type.id.startsWith('stack')" (click)="wrapper?.setting.setChartType(type); onChangedSetting()"><i class="las la-layer-group" style="color: purple"></i>{{locale('locale_key.pages.dashboard.chart_settings.graph_type.options.stack')}}</button>
            <button type="button" class="btn btn-icon action-icon mt-1" *ngIf="type.id.startsWith('line')" (click)="wrapper?.setting.setChartType(type); onChangedSetting()"><i class="las la-chart-line" style="color: purple"></i>{{locale('locale_key.pages.dashboard.chart_settings.graph_type.options.line')}}</button>
            <button type="button" class="btn btn-icon action-icon mt-1" *ngIf="type.id.startsWith('pie')" (click)="wrapper?.setting.setChartType(type); onChangedSetting()"><i class="las la-chart-pie" style="color: purple"></i>{{locale('locale_key.pages.dashboard.chart_settings.graph_type.options.pie')}}</button>
            <button type="button" class="btn btn-icon action-icon mt-1" *ngIf="type.id.startsWith('doughnut')" (click)="wrapper?.setting.setChartType(type); onChangedSetting()"><i class="lab la-chrome" style="color: purple"></i>{{locale('locale_key.pages.dashboard.chart_settings.graph_type.options.doughnut')}}</button>
            <button type="button" class="btn btn-icon action-icon mt-1" *ngIf="type.id.startsWith('table')" (click)="wrapper?.setting.setChartType(type); wrapper?.renderTable()"><i class="las la-table" style="color: purple"></i>{{locale('locale_key.pages.data_request_answers.table_view.title')}}</button>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-12 px-2">
        <h5>{{locale('locale_key.pages.dashboard.chart_settings.compare.title')}}</h5>
      </div>
      <div class="col-12">
        <button data-bs-toggle="dropdown" type="button" class="btn btn-block action-icon form-select d-flex justify-content-between text-start">
          <span class="btn-min-width-15">{{ wrapper?.setting.principalComparisionText() }}</span>
        </button>
        <ul class="dropdown-menu btn-min-width-18 p-0" id="menuChoices" role="menu">
          <a class="dropdown-item" (click)="wrapper?.setting.selectPrincipalComparisonTime(); onChangedSetting()" *ngIf="wrapper?.insight.isTimed()">{{locale('locale_key.pages.dashboard.chart_settings.compare.options.time')}}</a>
          <a class="dropdown-item" (click)="wrapper?.setting.selectPrincipalCategoryFieldIndex(j); onChangedSetting()" *ngFor="let category of wrapper?.options.categoryOptions; let j = index">{{ category.getLabel() }}</a>
        </ul>
      </div>
    </div>

    <div class="row pt-3" *ngIf="wrapper?.insight.isTimed()">
      <div class="col-12 px-2">
        <h5>{{locale('locale_key.pages.dashboard.chart_settings.results.title')}}</h5>
      </div>
      <div class="col-12">
        <button data-bs-toggle="dropdown" type="button" class="btn btn-block action-icon form-select d-flex justify-content-between text-start mb-2">
          <span [ngClass]="wrapper?.insight.isTimed() && wrapper?.setting.principalCategoryFieldIndex > -1 ? 'btn-min-width' : 'btn-min-width-15'">
            {{ locale('locale_key.insight-detail.time_options.' + wrapper?.setting.timeOption()?.timeScaleToAdverb().toLowerCase()) }}
          </span>
        </button>
        <ul class="dropdown-menu btn-min-width-18 p-0" id="menuChoices" role="menu">
          <a class="dropdown-item" *ngFor="let timeOption of wrapper?.options.timeOptions; let timeOptionIndex = index" (click)="wrapper?.setting.setTimeScaleIndex(timeOptionIndex); onChangedSetting()">{{ locale('locale_key.insight-detail.time_options.'+timeOption.timeScaleToAdverb().toLowerCase()) }} </a>
        </ul>

        <div *ngIf="wrapper?.insight.isTimed() && wrapper?.setting.principalCategoryFieldIndex > -1">
          <button data-bs-toggle="dropdown" type="button" class="btn btn-block action-icon form-select d-flex justify-content-between text-start pt-1">
            <span class="btn-min-width-3 me-2">{{ wrapper?.setting.timeValue }} </span>
          </button>
          <ul class="dropdown-menu btn-min-width-18 p-0" id="menuChoices" role="menu">
            <a class="dropdown-item" *ngFor="let timeValue of wrapper?.setting.timeValues()" (click)="wrapper?.setting.setTimeValue(timeValue); onChangedSetting()">{{ timeValue }}</a>
          </ul>
        </div>
      </div>
    </div>

    <div class="row pt-2" *ngFor="let categoryOption of wrapper?.setting.nonPricipalCategoryOptions()">
      <a class="col-12">{{ categoryOption.getLabel() }}</a>
      <div class="col-12">
        <button data-bs-toggle="dropdown" type="button" class="btn btn-block action-icon form-select d-flex justify-content-between text-start">
          <span class="btn-min-width-15">{{ wrapper?.setting.categoryValue(categoryOption.field) }}</span>
        </button>
        <ul class="dropdown-menu p-0 mh-200" id="menuChoices" role="menu">
          <li>
            <a class="dropdown-item" *ngFor="let catVal of categoryOption.values" (click)="wrapper?.setting.setCategoryValue(categoryOption.field, catVal); onChangedSetting()">{{ catVal }}</a>
          </li>
        </ul>
      </div>
    </div>
    
    <div class="row pt-3" *ngIf="wrapper?.insight.definition.filter">
      <div class="col-12 px-2">
        <h5>{{locale('locale_key.pages.dashboard.chart_settings.data_customization')}}</h5>
      </div>
      <div class="col-12">
        <p class="m-0 px-3 py-1 font-small-3" style="border: 1px solid #e4e5ec;border-radius: 7px;" 
          [innerHTML]="wrapper?.insight.definition.filter.getInnerHTML()"></p>
      </div>
    </div>
    <div class="float-end pt-3">
      <a class="btn tertiary-button me-3" (click)="openModal(dataTable, 'modal-xl')">
        <i class="la la-table" *ngIf="screenSize.isXLargeSize()"></i> {{locale('locale_key.pages.data_hub.button.master_table.show_data_table')}}
      </a>
    </div>
  </div>
  <div class="card-footer mt-auto d-flex flex-row justify-content-end pt-3 pb-3">
    <div class="dropdown">
      <button class="btn tertiary-button custom-button me-2" 
      role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="isExportAllowed()"> 
       {{locale('locale_key.general.buttons.download')}} <i class="las la-angle-down"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a class="dropdown-item" (click)="downloadImg()"><i class="la la-file-image-o" style="color: #f2921d"></i>{{locale('locale_key.general.buttons.download_img')}}</a>
        <a class="dropdown-item" (click)="downloadXlsx()"><i class="la la-file-excel-o" style="color: #6dc49b"></i>{{locale('locale_key.general.buttons.download_xlsx')}}</a>
        <a class="dropdown-item" (click)="downloadCsv()"><i class="la la-file-excel-o" style="color: #6dc49b"></i>{{locale('locale_key.general.buttons.download_csv')}}</a>
        <a class="dropdown-item" (click)="downloadPDF()"><i class="la la-file-pdf-o" style="color: #c65c5c"></i>{{locale('locale_key.general.buttons.download_pdf')}}</a>
      </div>
    </div>
    <button type="button" class="btn tertiary-button custom-button" data-dismiss="modal" (click)="deleteChart()" *ngIf="!isASharedDashboard">{{locale('locale_key.general.buttons.delete')}}</button>
  </div>
</div>

<ng-template #dataTable>
  <app-trace-data [chartWrapper]="wrapper" ></app-trace-data>
</ng-template>



