import { Injectable } from "@angular/core";
import { ColumnSchemaFe } from "../model/schema/ColumnSchemaFe";
import { ReferenceColumnSchemaFe } from "../model/schema/ReferenceColumnSchemaFe";
import { RowFe } from "../model/schema/RowFe";
import { TableDataFe } from "../model/schema/TableDataFe";
import { FileDataFe } from "../model/file/FileDataFe";
import { DomSanitizer } from "@angular/platform-browser";
import { StateServiceFe } from "./StateServiceFe";
import { FileTypeFe } from "../model/file/FileTypeFe";
import { IdUtil } from "../utils/IdUtil";

@Injectable({
  providedIn: "root",
})
export class TableStorageServiceFe {
  files: { [key: string]: string } = {};

  constructor(private domSanitizer: DomSanitizer, private stateService: StateServiceFe) {}

  ngOnInit() {}

  convertTableIntoFile(table: TableDataFe): FileDataFe {
    //store it as a file

    table.key = IdUtil.next();
    let data = new TableDataStorageFe(table.key, table.name, new Date(), null, table);
    var blob = new Blob([JSON.stringify(data)], { type: "application/json" });
    // this.files[data.id] = JSON.stringify(data);
    let file = FileDataFe.fromBlob(data.id)
    file.addBlobContent(blob, this.domSanitizer)
    file.id = data.id;
    return file;
  }

  async convertFileIntoTable(blob: Blob, callback){
   
    let reader = new FileReader();
     reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const obj = JSON.parse(bstr);
      // TODO check if need to update the types of the received blob, like date columns into a specific format
      let table = obj.table
      let dataTable = new TableDataFe();
      dataTable.dataSchema = table.dataSchema.map((schema) => ColumnSchemaFe.fromDataGridColumnSchema(schema));
      dataTable.referenceSchema = table.referenceSchema.map((schema) => ReferenceColumnSchemaFe.fromDataGridColumnSchema(schema));
      dataTable.label = table.label;
      dataTable.name = table.name;
      dataTable.rows = table.rows.map((row) => RowFe.fromDataGridRow(row));
      dataTable.key = table.key;
      dataTable.rowCount = table.rowCount;
      dataTable.pageSize = table.pageSize;
      dataTable.pageNumber = table.pageNumber;
      dataTable.ordered = table.ordered;
      callback(dataTable);
    };

    reader.readAsText(blob, 'UTF-8');
  }
}

export class TableDataStorageFe {
  id: string;
  name: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  table: TableDataFe;

  constructor(id: string, name: string, createdAt: Date, updatedAt: Date | null, table: TableDataFe) {
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.table = table;
  }
}
