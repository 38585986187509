import { Injectable } from "@angular/core"
import { LanguageService } from "../services/LanguageServiceFe"
import { ServerErrorFe } from "./ServerErrorFe"

@Injectable({
  providedIn: "root",
})

export class ErrorsFe {
  public NO_ACTIVE_WORKSPACE_ERROR: ServerErrorFe;
  public UNAUTHORIZED_ACCESS_TO_WORKSPACE_ERROR: ServerErrorFe;
  public USER_DOESNT_HAVE_DATA_OWNER_ROLE_IN_WORKSPACEROLE: ServerErrorFe;
  public USER_ISNT_AFFILIATED_IN_WORKSPACE: ServerErrorFe;
  public COMPANY_EXISTS_ERROR: ServerErrorFe;
  public COMPANY_PURPOSE_TYPE_UNRECONGNIZED: ServerErrorFe;
  public BUCKET_NAME_EXISTS_ERROR: ServerErrorFe;
  public BUCKET_FOLDER_CREATION_ERROR: ServerErrorFe;
  public SERVICE_ACCOUNT_ACCESS_ERROR: ServerErrorFe;
  public INSERT_QUERY_VALUE_MISMATCH_ERROR: ServerErrorFe;
  public INSERT_QUERY_VALUE_TYPE_ERROR: ServerErrorFe;
  public ACTIVITY_CANNOT_BE_READ: ServerErrorFe;
  public ACTIVITY_TYPE_NOT_FOUND: ServerErrorFe;
  public REQUEST_GROUP_ID_NOT_FOUND: ServerErrorFe;
  public REQUEST_GROUP_ID_NOT_UNIQUE: ServerErrorFe;
  public MASTER_TABLE_NAME_NOT_GENERATED_ERROR: ServerErrorFe;
  public MASTER_TABLE_ALREADY_EXISTS: ServerErrorFe;
  public STAGE_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING: ServerErrorFe;
  public STAGE_TABLE_NOT_FOUND_ERROR_WHILE_DISCONNECTING: ServerErrorFe;
  public STAGE_TABLE_ALREADY_EXISTS: ServerErrorFe;
  public ORG_TABLE_NOT_FOUND_ERROR: ServerErrorFe;
  public ORG_NOT_SEEDED_ERROR: ServerErrorFe;
  public ORG_NOT_SAVED_ERROR: ServerErrorFe;
  public ORG_NOT_DEPLOYED_ERROR: ServerErrorFe;
  public ORG_ENTITY_NOT_FOUND_WHILE_READING_TABLE: ServerErrorFe;
  public ORG_ENTITY_NOT_FOUND_WHILE_UPDATING: ServerErrorFe;
  public TAXONOMY_NOT_LOADED_ERROR: ServerErrorFe;
  public TAXONOMY_NOT_SEEDED_ERROR: ServerErrorFe;
  public TAXONOMY_NOT_SAVED_ERROR: ServerErrorFe;
  public TAXONOMY_NOT_DEPLOYED_ERROR: ServerErrorFe;
  public TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING: ServerErrorFe;
  public TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING: ServerErrorFe;
  public INVALID_VALUE_IN_DATA_TABLE: ServerErrorFe;
  public ALL_ERRORS : ServerErrorFe[];

  constructor (private languageService: LanguageService) {
    languageService.languageSubject.subscribe(language => {
      this.setErrors()
    })
  }

  setErrors(): void {
    this.NO_ACTIVE_WORKSPACE_ERROR = new ServerErrorFe(401, 'NO_ACTIVE_WORKSPACE_ERROR', this.languageService.getLocale('locale_key.general.error_message.no_active_workspace_error')) 
    this.UNAUTHORIZED_ACCESS_TO_WORKSPACE_ERROR = new ServerErrorFe(401, 'UNAUTHORIZED_ACCESS_TO_WORKSPACE_ERROR', this.languageService.getLocale('locale_key.general.error_message.unauthorized_access_to_workspace_error'))
    this.USER_DOESNT_HAVE_DATA_OWNER_ROLE_IN_WORKSPACEROLE = new ServerErrorFe(401, 'USER_DOESNT_HAVE_DATA_OWNER_ROLE_IN_WORKSPACEROLE', this.languageService.getLocale('locale_key.general.error_message.user_doesnt_have_data_owner_role_in_workspacerole'))
    this.USER_ISNT_AFFILIATED_IN_WORKSPACE = new ServerErrorFe(401, 'USER_ISNT_AFFILIATED_IN_WORKSPACE', this.languageService.getLocale('locale_key.general.error_message.user_isnt_affiliated_in_workspace'))
  
    this.COMPANY_EXISTS_ERROR = new ServerErrorFe(401, 'COMPANY_EXISTS_ERROR', this.languageService.getLocale('locale_key.general.error_message.company_exists_error'))
    this.COMPANY_PURPOSE_TYPE_UNRECONGNIZED = new ServerErrorFe(401, 'COMPANY_PURPOSE_TYPE_UNRECONGNIZED', this.languageService.getLocale('locale_key.general.error_message.company_purpose_type_unrecognized'))
    this.BUCKET_NAME_EXISTS_ERROR = new ServerErrorFe(401, 'BUCKET_NAME_EXISTS_ERROR', this.languageService.getLocale('locale_key.general.error_message.bucket_name_exists_error'))
    this.BUCKET_FOLDER_CREATION_ERROR = new ServerErrorFe(401, 'BUCKET_FOLDER_CREATION_ERROR', this.languageService.getLocale('locale_key.general.error_message.bucket_folder_creation_error'))
    this.SERVICE_ACCOUNT_ACCESS_ERROR = new ServerErrorFe(401, 'SERVICE_ACCOUNT_ACCESS_ERROR', this.languageService.getLocale('locale_key.general.error_message.service_account_access_error'))
  
    this.INSERT_QUERY_VALUE_MISMATCH_ERROR = new ServerErrorFe(401, 'INSERT_QUERY_VALUE_MISMATCH_ERROR', this.languageService.getLocale('locale_key.general.error_message.insert_query_value_mismatch_error'))
    this.INSERT_QUERY_VALUE_TYPE_ERROR = new ServerErrorFe(401, 'INSERT_QUERY_VALUE_TYPE_ERROR', this.languageService.getLocale('locale_key.general.error_message.insert_query_value_type_error'))
    this.ACTIVITY_CANNOT_BE_READ = new ServerErrorFe(401, 'ACTIVITY_CANNOT_BE_READ', this.languageService.getLocale('locale_key.general.error_message.activity_cannot_be_read'))
    this.ACTIVITY_TYPE_NOT_FOUND = new ServerErrorFe(401, 'ACTIVITY_TYPE_NOT_FOUND', this.languageService.getLocale('locale_key.general.error_message.activity_type_not_found'))
  
    this.REQUEST_GROUP_ID_NOT_FOUND = new ServerErrorFe(401, 'REQUEST_GROUP_ID_NOT_FOUND', this.languageService.getLocale('locale_key.general.error_message.request_group_id_not_found'))
    this.REQUEST_GROUP_ID_NOT_UNIQUE = new ServerErrorFe(401, 'REQUEST_GROUP_ID_NOT_UNIQUE', this.languageService.getLocale('locale_key.general.error_message.request_group_id_not_unique'))
  
    this.MASTER_TABLE_NAME_NOT_GENERATED_ERROR = new ServerErrorFe(401, 'MASTER_TABLE_NAME_NOT_GENERATED_ERROR', this.languageService.getLocale('locale_key.general.error_message.master_table_name_not_generated_error'), true)
    this.MASTER_TABLE_ALREADY_EXISTS = new ServerErrorFe(401, 'MASTER_TABLE_ALREADY_EXISTS', this.languageService.getLocale('locale_key.general.error_message.master_table_already_exists'), true)
  
    this.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING = new ServerErrorFe(401, 'STAGE_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING', this.languageService.getLocale('locale_key.general.error_message.stage_table_not_found_error_while_connecting'), true)
    this.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_DISCONNECTING = new ServerErrorFe(401, 'STAGE_TABLE_NOT_FOUND_ERROR_WHILE_DISCONNECTING', this.languageService.getLocale('locale_key.general.error_message.stage_table_not_found_error_while_disconnecting'), true)
    this.STAGE_TABLE_ALREADY_EXISTS = new ServerErrorFe(401, 'STAGE_TABLE_ALREADY_EXISTS', this.languageService.getLocale('locale_key.general.error_message.stage_table_already_exists'))
  
    this.ORG_TABLE_NOT_FOUND_ERROR = new ServerErrorFe(401, 'ORG_TABLE_NOT_FOUND_ERROR', this.languageService.getLocale('locale_key.general.error_message.org_table_not_found_error'))
    this.ORG_NOT_SEEDED_ERROR = new ServerErrorFe(401, 'ORG_NOT_SEEDED_ERROR', this.languageService.getLocale('locale_key.general.error_message.org_not_seeded_error'))
    this.ORG_NOT_SAVED_ERROR = new ServerErrorFe(401, 'ORG_NOT_SAVED_ERROR', this.languageService.getLocale('locale_key.general.error_message.org_not_saved_error'))
    this.ORG_NOT_DEPLOYED_ERROR = new ServerErrorFe(401, 'ORG_NOT_DEPLOYED_ERROR', this.languageService.getLocale('locale_key.general.error_message.org_not_deployed_error'))
    this.ORG_ENTITY_NOT_FOUND_WHILE_READING_TABLE = new ServerErrorFe(401, 'TAXONOMY_ENTITY_NOT_FOUND', this.languageService.getLocale('locale_key.general.error_message.org_entity_not_found_while_reading_table'))
    this.ORG_ENTITY_NOT_FOUND_WHILE_UPDATING = new ServerErrorFe(401, 'TAXONOMY_ENTITY_NOT_FOUND', this.languageService.getLocale('locale_key.general.error_message.org_entity_not_found_while_updating'))
  
    this.TAXONOMY_NOT_LOADED_ERROR = new ServerErrorFe(401, 'TAXONOMY_NOT_LOADED_ERROR', this.languageService.getLocale('locale_key.general.error_message.taxonomy_not_loaded_error'), false)
    this.TAXONOMY_NOT_SEEDED_ERROR = new ServerErrorFe(401, 'TAXONOMY_NOT_SEEDED_ERROR', this.languageService.getLocale('locale_key.general.error_message.taxonomy_not_seeded_error'), true)
    this.TAXONOMY_NOT_SAVED_ERROR = new ServerErrorFe(401, 'TAXONOMY_NOT_SAVED_ERROR', this.languageService.getLocale('locale_key.general.error_message.taxonomy_not_saved_error'), true)
    this.TAXONOMY_NOT_DEPLOYED_ERROR = new ServerErrorFe(401, 'TAXONOMY_NOT_DEPLOYED_ERROR', this.languageService.getLocale('locale_key.general.error_message.taxonomy_not_deployed_error'), true)
    this.TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING = new ServerErrorFe(401, 'TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING', this.languageService.getLocale('locale_key.general.error_message.taxonomy_entity_not_found_while_connecting'), true)
    this.TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING = new ServerErrorFe(401, 'TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING', this.languageService.getLocale('locale_key.general.error_message.taxonomy_entity_not_found_while_disconnecting'), true)
  
    this.INVALID_VALUE_IN_DATA_TABLE = new ServerErrorFe(401, 'INVALID_VALUE_IN_DATA_TABLE', this.languageService.getLocale('locale_key.general.error_message.invalid_value_in_data_table'), true)
  
    this.ALL_ERRORS = [
      this.NO_ACTIVE_WORKSPACE_ERROR,
      this.UNAUTHORIZED_ACCESS_TO_WORKSPACE_ERROR,
      this.USER_DOESNT_HAVE_DATA_OWNER_ROLE_IN_WORKSPACEROLE,
      this.USER_ISNT_AFFILIATED_IN_WORKSPACE,
      this.COMPANY_EXISTS_ERROR,
      this.COMPANY_PURPOSE_TYPE_UNRECONGNIZED,
      this.BUCKET_NAME_EXISTS_ERROR,
      this.BUCKET_FOLDER_CREATION_ERROR,
      this.SERVICE_ACCOUNT_ACCESS_ERROR,
      this.INSERT_QUERY_VALUE_MISMATCH_ERROR,
      this.INSERT_QUERY_VALUE_TYPE_ERROR,
      this.ACTIVITY_CANNOT_BE_READ,
      this.ACTIVITY_TYPE_NOT_FOUND,
      this.REQUEST_GROUP_ID_NOT_FOUND,
      this.REQUEST_GROUP_ID_NOT_UNIQUE,
      this.MASTER_TABLE_NAME_NOT_GENERATED_ERROR,
      this.MASTER_TABLE_ALREADY_EXISTS,
      this.STAGE_TABLE_ALREADY_EXISTS,
      this.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING,
      this.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_DISCONNECTING,
      this.ORG_TABLE_NOT_FOUND_ERROR,
      this.ORG_NOT_SEEDED_ERROR,
      this.ORG_NOT_SAVED_ERROR,
      this.ORG_NOT_DEPLOYED_ERROR,
      this.ORG_ENTITY_NOT_FOUND_WHILE_READING_TABLE,
      this.ORG_ENTITY_NOT_FOUND_WHILE_UPDATING,
      this.TAXONOMY_NOT_SEEDED_ERROR,
      this.TAXONOMY_NOT_SAVED_ERROR,
      this.TAXONOMY_NOT_DEPLOYED_ERROR,
      this.TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING,
      this.TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING,
      this.INVALID_VALUE_IN_DATA_TABLE,    
      OpenErrorsFe.JWT_TOKEN_EXPIRED_ERROR,
      OpenErrorsFe.USER_SESSION_ERROR,
      OpenErrorsFe.USER_NOT_FOUND_ERROR,
      OpenErrorsFe.INCORRECT_PASS_ERROR,
      OpenErrorsFe.UNMATCHED_PASSES_ERROR,
      OpenErrorsFe.PASSWORD_IS_ALREADY_USED,
      OpenErrorsFe.NO_PROPER_CREDENTIALS_ERROR,
      OpenErrorsFe.RESE_PASS_LINK_EXPIRED_ERROR,
      OpenErrorsFe.TOO_MANY_FAILED_ATTEMPTS,
      OpenErrorsFe.PROVIDER_ACCOUNT_NOT_LINKED,
      OpenErrorsFe.MFA_ENROLL_REQUIRED,
      OpenErrorsFe.MFA_AUTH_REQUIRED,
      OpenErrorsFe.WEB_STORAGE_NOT_SUPPORTED,
      OpenErrorsFe.INCORRECT_PASS_ERROR_WHILE_CHANGING_PASS,
      OpenErrorsFe.UNMATCHED_PASSES_ERROR_WHILE_CHANGING_PASS,
      OpenErrorsFe.INVITATION_LINK_EXPIRED_ERROR,
      OpenErrorsFe.INVITATION_LINK_NOT_FOUND_ERROR,
      OpenErrorsFe.INVITATION_LINK_ALRADY_EXIST_ERROR,
      OpenErrorsFe.USER_ID_NOT_FOUND,
      OpenErrorsFe.REGISTRATION_ERROR_USER_ID_ALREADY_EXISED,
      OpenErrorsFe.CREDENTIAL_NOT_PROVIDED_ERROR_USER_ID_ALREADY_EXISED,
      OpenErrorsFe.AFFILIATION_ALREADY_EXISED,
      OpenErrorsFe.DATA_CONSISTENCY_NOT_UNIQUE_AFFILATION,
      OpenErrorsFe.COMPANY_ID_NOT_FOUND,
      OpenErrorsFe.ID_CLASHING_COMPANY_ID_NOT_FOUND,
      OpenErrorsFe.EXISTED_COMPANY_ID_NOT_FOUND,
      OpenErrorsFe.COMPANY_ID_NOT_UNQUE,
      OpenErrorsFe.PARTNERSHIP_ALREADY_EXIST,
      OpenErrorsFe.CONTACT_ALREADY_EXIST,
      OpenErrorsFe.SHARED_DASHBOARD_NOT_FOUND,
      OpenErrorsFe.NO_ACCESS_TO_SHARED_DASHBOARD,
      OpenErrorsFe.ENTERED_WRONG_VERIFICATION_CODE,
      OpenErrorsFe.SIGNIN_METHOD_NOT_ALLOWED_FOR_WORKSPACE_ERROR,
      OpenErrorsFe.RESTRICTED_TO_ONLY_ADMINS,
      OpenErrorsFe.SIGN_IN_METHOD_UPDATE_REQUIRED_DETAILS_NOT_FOUND,
      OpenErrorsFe.USER_ALREADY_EXISTS_ERROR
    ]
  }

  public matchError: Function = (errorName: string): ServerErrorFe | undefined => {
    return this.ALL_ERRORS.find(e => e.name == errorName)
  }  
}

export class OpenErrorsFe {

  public static JWT_TOKEN_EXPIRED_ERROR = new ServerErrorFe(401, 'JWT_TOKEN_EXPIRED_ERROR', ``)
  public static USER_SESSION_ERROR = new ServerErrorFe(401, 'USER_SESSION_ERROR', ``)
  public static USER_NOT_FOUND_ERROR = new ServerErrorFe(401, 'USER_NOT_FOUND_ERROR', 'Unable to find the same user!') //login, resetpass, changepass
  public static PASSWORD_IS_ALREADY_USED = new ServerErrorFe(401, 'PASSWORD_IS_ALREADY_USED', 'The password has been previously used as one of the last five passwords. Please choose a new and unique password', true)
  public static USER_ALREADY_EXISTS_ERROR = new ServerErrorFe(401, 'USER_ALREADY_EXISTS_ERROR', 'Unable to add the user as a user with those details already exists on our platform!')
  public static INCORRECT_PASS_ERROR = new ServerErrorFe(401, 'INCORRECT_PASS_ERROR', 'Please make sure you have entered your credentials correctly!')
  public static UNMATCHED_PASSES_ERROR = new ServerErrorFe(401, 'UNMATCHED_PASSES_ERROR', `The new passwords do not match!`, true) //resetpass, signup
  public static NO_PROPER_CREDENTIALS_ERROR = new ServerErrorFe(401, 'NO_PROPER_CREDENTIALS_ERROR', 'Please make sure you have entered your credentials correctly!') 
  public static RESE_PASS_LINK_EXPIRED_ERROR = new ServerErrorFe(401, 'RESE_PASS_LINK_EXPIRED_ERROR', 'The link for reset password has expired, please send a new request!')
  public static TOO_MANY_FAILED_ATTEMPTS = new ServerErrorFe(401, 'TOO_MANY_FAILED_ATTEMPTS', 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.')
  public static PROVIDER_ACCOUNT_NOT_LINKED = new ServerErrorFe(401, 'PROVIDER_ACCOUNT_NOT_LINKED', 'Provider account not linked to sustainlab');
  public static MFA_ENROLL_REQUIRED = new ServerErrorFe(401, 'MFA_ENROLL_REQUIRED', 'mfa enroll required');
  public static MFA_AUTH_REQUIRED = new ServerErrorFe(401, 'MFA_AUTH_REQUIRED', 'mfa auth required');
  public static WEB_STORAGE_NOT_SUPPORTED = new ServerErrorFe(401, 'WEB_STORAGE_NOT_SUPPORTED', 'Web storage disabled or not supported by browser ( login will not work in incognito mode )');
  public static RESTRICTED_TO_ONLY_ADMINS = new ServerErrorFe(401, 'RESTRICTED_TO_ONLY_ADMINS', 'The functionalities that you are trying to use are only allowed for admins');

  public static INCORRECT_PASS_ERROR_WHILE_CHANGING_PASS = new ServerErrorFe(401, 'INCORRECT_PASS_ERROR_WHILE_CHANGING_PASS', 'You entered incorrect password, please try again!', true);
  public static UNMATCHED_PASSES_ERROR_WHILE_CHANGING_PASS = new ServerErrorFe(401, 'UNMATCHED_PASSES_ERROR_WHILE_CHANGING_PASS', 'Passwords do not match, please try again!', true);

  public static INVITATION_LINK_EXPIRED_ERROR = new ServerErrorFe(401, 'INVITATION_LINK_EXPIRED_ERROR', `If you have activated your account already, use the login button and follow to the login page.`, true)
  public static INVITATION_LINK_NOT_FOUND_ERROR = new ServerErrorFe(401, 'INVITATION_LINK_NOT_FOUND_ERROR', 'The invitation link you are trying to use is not valid, please use a correct one!')
  public static INVITATION_LINK_ALRADY_EXIST_ERROR = new ServerErrorFe(401, 'INVITATION_LINK_ALRADY_EXIST_ERROR', 'The user you are tryng to invite is already in the platform!')

  public static USER_ID_NOT_FOUND = new ServerErrorFe(401, 'USER_ID_NOT_FOUND', 'Please make sure you have entered your credentials correctly!')
  public static REGISTRATION_ERROR_USER_ID_ALREADY_EXISED = new ServerErrorFe(401, 'REGISTRATION_ERROR_USER_ID_ALREADY_EXISED', 'The user already exists in the platform!')
  public static CREDENTIAL_NOT_PROVIDED_ERROR_USER_ID_ALREADY_EXISED = new ServerErrorFe(401, 'CREDENTIAL_NOT_PROVIDED_ERROR_USER_ID_ALREADY_EXISED','Please make sure you have entered your credentials correctly!')
  public static AFFILIATION_ALREADY_EXISED = new ServerErrorFe(401, 'AFFILIATION_ALREADY_EXISED', 'You are already affiliated to this company!', true)
  public static DATA_CONSISTENCY_NOT_UNIQUE_AFFILATION = new ServerErrorFe(401, 'DATA_CONSISTENCY_NOT_UNIQUE_AFFILATION', 'Unfortunately, we were unable to complete the process as system could find more than one affiliation with the same identifier. Please contact our customer service to fix this for you!')

  public static COMPANY_ID_NOT_FOUND = new ServerErrorFe(401, 'COMPANY_ID_NOT_FOUND', 'Unfortunately, we were unable to complete the process as system could not find the identifier. Please contact our customer service to fix this for you!')
  public static ID_CLASHING_COMPANY_ID_NOT_FOUND = new ServerErrorFe(401, 'ID_CLASHING_COMPANY_ID_NOT_FOUND', 'Unfortunately, we were unable to complete the process as system could find more than one company with the same identifier! Please try again!')
  public static EXISTED_COMPANY_ID_NOT_FOUND = new ServerErrorFe(401, 'EXISTED_COMPANY_ID_NOT_FOUND', 'Unfortunately, we were unable to complete the process as system could not find the company. Please contact our customer service to fix this for you!')
  public static COMPANY_ID_NOT_UNQUE = new ServerErrorFe(401, 'COMPANY_ID_NOT_UNQUE', 'Unfortunately, we were unable to complete the process as system could find more than one company with the same identifier. Please contact our customer service to fix this for you!')

  public static PARTNERSHIP_ALREADY_EXIST = new ServerErrorFe(401, 'PARTNERSHIP_ALREADY_EXIST', 'You are trying to add a company which already exists in the platform!')
  public static CONTACT_ALREADY_EXIST = new ServerErrorFe(401, 'CONTACT_ALREADY_EXIST', 'You are trying to add a contact which already exists in our platform!')

  public static SHARED_DASHBOARD_NOT_FOUND = new ServerErrorFe(401, 'SHARED_DASHBOARD_NOT_FOUND', 'The link you are using to access the shared dashboard is wrong, please use a correct one!', true)
  public static NO_ACCESS_TO_SHARED_DASHBOARD = new ServerErrorFe(401, 'NO_ACCESS_TO_SHARED_DASHBOARD', `You don't have privilege to open the dashboard. Please follow up with your contact person to grant access.`, true)
 
  public static ENTERED_WRONG_VERIFICATION_CODE = new ServerErrorFe(401, 'ENTERED_WRONG_VERIFICATION_CODE', `The code you entered is not correct. Please check and re-enter the verification that was sent to you in an email.`, true)

  public static SIGNIN_METHOD_NOT_ALLOWED_FOR_WORKSPACE_ERROR = new ServerErrorFe(401, 'SIGNIN_METHOD_NOT_ALLOWED_FOR_WORKSPACE_ERROR', `The signin method used is not allowed for workspace.`, false)
  public static SIGN_IN_METHOD_PROVIDER_NOT_FOUND = new ServerErrorFe(401, 'SIGN_IN_METHOD_PROVIDER_NOT_FOUND', `The provider for signed in method is not supported.`, false)
  public static SIGN_IN_METHOD_UPDATE_REQUIRED_DETAILS_NOT_FOUND = new ServerErrorFe(401, 'SIGN_IN_METHOD_UPDATE_REQUIRED_DETAILS_NOT_FOUND', `Please provide all the details for completing the process.`, false)

}


