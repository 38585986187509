import { StageTableDataGridIntergationService } from "src/app/components/data/StageTableDataGridIntergationService";
import { DateUtil } from "src/app/utils/DateUtil";
import { FileTypeFe } from "../file/FileTypeFe";
import { TaxonomyInfoFe } from "../taxonomy/TaxonomyInfoFe";
import { EntityFe } from "../taxonomy/EntityFe";
import { TableDataFe } from "../schema/TableDataFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { ErrorsFe } from "src/app/utils/KNOWN_ERRORS";
import { AlertServiceFe } from "src/app/services/AlertServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";

export const STAGE_TABLE_KEYWORD: string = 'STAGE2__'
export const OLD_STAGE_TABLE_KEYWORD: string = 'STAGE__'

export class DirectEntryInfoFe {

  public id: string
  public sourceFile: string;
  public taxonomyKey: string;
  public timeFrom: Date;
  public timeTo: Date;
  public transformed: boolean = false;
  public modified: boolean = false;
  public lastModified: Date;
  public creatorAffId: string
  public creatorFirstName: string
  public creatorLastName: string

  public timeFromString: string;
  public timeToString: string;
  public lastModifiedString: string;
  public dataGridService: StageTableDataGridIntergationService
  public isActive: boolean = false;
  public isOpen: boolean = false;
  public loadInProgress: boolean = false;
  public connectionInProgress: boolean = false;
  public saveInProgress: boolean = false
  public updateInfoInProgress: boolean = false

  public rootParentEntity: EntityFe
  public parentEntity: EntityFe
  public taxonomyEntity: EntityFe
  public tableData: TableDataFe = null

  public static fromTransfer(transfer: any, taxonomyInfo: TaxonomyInfoFe): DirectEntryInfoFe {
    let stageTableInfo = new DirectEntryInfoFe()
    stageTableInfo.id = transfer['id'];
    stageTableInfo.sourceFile = transfer['sourceFile'];
    stageTableInfo.taxonomyKey = transfer['taxonomyKey'];
    stageTableInfo.rootParentEntity = taxonomyInfo.rootParentEntity(stageTableInfo.taxonomyKey)
    stageTableInfo.parentEntity = taxonomyInfo.parentEntity(stageTableInfo.taxonomyKey)
    stageTableInfo.taxonomyEntity = taxonomyInfo.entityByKey(stageTableInfo.taxonomyKey)
    stageTableInfo.timeFrom = new Date(transfer['timeFrom']);
    stageTableInfo.timeTo = new Date(transfer['timeTo'])
    stageTableInfo.timeFromString = (stageTableInfo.timeFrom) ? DateUtil.toString2(stageTableInfo.timeFrom) : '-';
    stageTableInfo.timeToString = (stageTableInfo.timeTo) ? DateUtil.toString2(stageTableInfo.timeTo): '-';
    stageTableInfo.transformed = transfer['transformed'];
    stageTableInfo.modified = transfer['modified'];
    stageTableInfo.lastModified = transfer['lastModified'] ? new Date(transfer['lastModified']):  new Date();
    stageTableInfo.lastModifiedString = DateUtil.toString3(stageTableInfo.lastModified);
    stageTableInfo.creatorAffId = transfer['creatorAffId'];
    stageTableInfo.creatorFirstName = transfer['creatorFirstName'];
    stageTableInfo.creatorLastName = transfer['creatorLastName'];
    return stageTableInfo
  }

  public isLoading(): boolean {
    return this.loadInProgress || this.connectionInProgress || this.saveInProgress || this.updateInfoInProgress
  }

  public hasContent(): boolean {
    return (this.tableData) ? true : false
  }

  public fileType(): FileTypeFe {
    let type = FileTypeFe.fromName(this.sourceFile)
    return type
  }

  public statusIcon(): string {
    return (this.transformed) ? 'la-link' : 'la-unlink'
  }

  public statusLocaleKey(): string {
    if (this.transformed && !this.modified) {
      return 'locale_key.general.state.added'
    } else if (this.transformed && this.modified){
      return 'locale_key.general.state.modified'
    } else {
      return 'locale_key.general.state.not_added'
    }
  }

  public statusColor(): string {
    if (this.transformed && !this.modified) {
      return 'green'
    } else if (this.transformed && this.modified){
      return 'orange'
    } else {
      return 'gray'
    }
  }

  public static sort(i1: DirectEntryInfoFe, i2: DirectEntryInfoFe): number {
    if (i1.taxonomyEntity && i2.taxonomyEntity) {
      if (i1.taxonomyEntity.ordinal < i2.taxonomyEntity.ordinal) {
        return -1
      } else if (i1.taxonomyEntity.ordinal == i2.taxonomyEntity.ordinal) {
        if (i1.timeFrom < i2.timeFrom) {
          return -1
        } else {
          return 1
        }
      } else {
        return 1
      }
    } else {
      return 1
    }
  }

  public dbTableName(): string {
    if (this.taxonomyKey && this.timeFrom && this.timeTo) {
      let from = DateUtil.toString(this.timeFrom)
      let to = DateUtil.toString(this.timeTo)
      return STAGE_TABLE_KEYWORD + this.taxonomyKey.replace(/\./gi, `__`).toUpperCase() + `__` + from.replace(/\-/gi, `_`) + `__` + to.replace(/\-/gi, `_`);
    }
  }

  public taxonomyName(languageService: LanguageService): string {
    if (this.taxonomyEntity) {
      return `${this.rootParentEntity?.getLabel()} > ${this.parentEntity?.getLabel()} > ${this.taxonomyEntity.getLabel() }`
    } else {
      return this.taxonomyKey
    }
  }

  async connecToPipeline(stateService: StateServiceFe, errorsFe: ErrorsFe, alertService: AlertServiceFe) {
    try {
      await stateService.connecStageTableToPipeline(this)
    } catch (error) {
      let knownError = errorsFe.matchError(error)
      if(knownError && (knownError == errorsFe.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING || knownError == errorsFe.TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING)){
        alertService.showError(knownError.message);
      } else{
        alertService.showError('Unfortunately we were unable to connect the table to the pipeline due to some internal reasons! Please try again!')
      }
    }
  } 

  async disconnectFromPipeline(stateService: StateServiceFe, errorsFe: ErrorsFe, alertService: AlertServiceFe) {
    try{
      await stateService.disconnectStageTableFromPipeline(this)
    } catch (error) {
      let knownError = errorsFe.matchError(error)
      if(knownError && (knownError == errorsFe.STAGE_TABLE_NOT_FOUND_ERROR_WHILE_DISCONNECTING || knownError == errorsFe.TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING)){
        alertService.showError(knownError.message);
      } else{
        alertService.showError('Unfortunately we were unable to disconnect the table from the pipeline due to some internal reasons! Please try again!')
      }
    }
  } 

}