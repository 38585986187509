import { Component, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { Subject, Subscription } from "rxjs"
import { DirectEntryInfoFe } from "src/app/model/directEntry/DirectEntryInfoFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { StageTableInfoSubjectFe } from "src/app/model/subject/StageTableInfoSubjectFe"
import { DatahubService } from "./DatahubService"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { DateUtil } from "src/app/utils/DateUtil"
import { ErrorsFe } from "src/app/utils/KNOWN_ERRORS"
import { AlertServiceFe } from "src/app/services/AlertServiceFe"
import {StageTableDataGridIntergationService} from 'src/app/components/data/StageTableDataGridIntergationService'

@Component({
  selector: "data-entries-portal",
  templateUrl: "./dataEntriesPortal.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class DataEntriesPortalComponent extends AbstractLanguageComponent implements OnInit {
  
  @Output() showDataEntry: Subject<DirectEntryInfoFe> = new Subject;
  menuCollapsed: boolean

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInfo: boolean = false
  loadingInProgress: boolean = false
  datahubFilteredStageTableInfos: DirectEntryInfoFe[] = []
  stageTableInfos: DirectEntryInfoFe[] = []
  filteredStageTableInfos: DirectEntryInfoFe[] = []
  filteredGroupedStageTableInfos: {name:string | EntityFe, entries: DirectEntryInfoFe[]}[] = []
  depTaxonomy: TaxonomyInfoFe
  selectedDataEntry: DirectEntryInfoFe = null
  toCopyStageTableInfo: DirectEntryInfoFe; 
  subscriptions = new Subscription()
  toEditStageTableInfo: DirectEntryInfoFe; 
  searchKeyword: string = '';
  
  groupedBy = null
  groupedByOptions = [
    {name: this.locale('locale_key.pages.datahub.group.option.categories'), value: 'entities'},
    {name: this.locale('locale_key.pages.datahub.group.option.pipeline_status'), value: 'pipelineStatus'}
  ]
  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.starting_period'), value: 'timeFrom', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.starting_period_desc'), value: 'timeFromDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.ending_period'), value: 'timeTo', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.ending_period_desc'), value: 'timeToDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]

  selectedSatusesLocaleKey = new Set<string>();
  customMultipleFilters: {name: string, value: EntityFe | Date, value2?: Date, level?: number}[] = []
  filteredEntities: EntityFe[] = []
  date1: Date = new Date()
  date2: Date = new Date()
  periodFilter: string = null

  constructor(languageService: LanguageService, private responsive: ResponsiveService, private stateService: StateServiceFe,  
    public datahubService: DatahubService, private ErrorsFe: ErrorsFe, private alertService: AlertServiceFe,
    private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService) {
    super(languageService)
    
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.screenSize = this.responsive.currentScreenWidthSize
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    let sub = stateService.stageTableInfoSubject.subscribe(async (subject: StageTableInfoSubjectFe) => {
      await this.loadCompanyData()
    })
    this.subscriptions.add(sub)
  }

  async ngOnInit() {
    await this.loadCompanyData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadCompanyData() {
    this.loadingInProgress = true
    this.datahubFilteredStageTableInfos = await this.datahubService.getFilteredDirectEntries()
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
    this.depTaxonomy = depTaxonomy
    this.filterEntities()
    this.applyFilters();
    this.loadingInProgress = false
  }

  search($event: any) {
    throw new Error("Method not implemented.")
  }

  openDirectDataEntry(info: DirectEntryInfoFe) {
    this.datahubService.showDataEntry(info);
  }
  
  updateDetailsData() {
    this.detailsData = {
    };
    this.displayService.updateDetailsData(this.detailsData);
  }

  async connecToPipeline(stageTable: DirectEntryInfoFe) {
    await stageTable.connecToPipeline(this.stateService, this.ErrorsFe, this.alertService)
  } 

  async disconnectFromPipeline(stageTable: DirectEntryInfoFe) {
    await stageTable.disconnectFromPipeline(this.stateService, this.ErrorsFe, this.alertService)
  } 

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string = 'modal-md') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: clazz,
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }
  
  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open');
  }

  group(option?) {
    if (option) {
      this.groupedBy = option;
    }
    this.filteredGroupedStageTableInfos = [];
    if (this.groupedBy) {
      if (this.groupedBy.value == 'entities') {
        let entities = new Map<EntityFe, DirectEntryInfoFe[]>();
        this.filteredStageTableInfos.forEach(directEntry => {
          if (entities.has(directEntry.rootParentEntity)) {
            entities.get(directEntry.rootParentEntity).push(directEntry);
          } else if (directEntry.rootParentEntity) {
            entities.set(directEntry.rootParentEntity, [directEntry]);
          }
        })
        entities.forEach((entries, entity)=> this.filteredGroupedStageTableInfos.push({name: entity, entries}) )
  
      } else if (this.groupedBy.value == 'pipelineStatus') {
        let statuses = new Map<string, DirectEntryInfoFe[]>();
        this.filteredStageTableInfos.forEach(directEntry => {
          if (statuses.has(directEntry.statusLocaleKey())) {
            statuses.get(directEntry.statusLocaleKey()).push(directEntry);
          } else {
            statuses.set(directEntry.statusLocaleKey(), [directEntry]);
          }
        })
        statuses.forEach((entries, localeKey)=> this.filteredGroupedStageTableInfos.push({name: this.locale(localeKey), entries}) )
      }
    }
    if (this.sortedBy) {
      this.sort();
    }
  }

  async sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let sortFun;
    if (this.sortedBy.value == 'timeFrom') {
      sortFun = (a: DirectEntryInfoFe, b: DirectEntryInfoFe) => {
        return DateUtil.getTimeDifference(a.timeFrom , b.timeFrom)
      }
    } else if (this.sortedBy.value == 'timeFromDes') {
      sortFun = (a: DirectEntryInfoFe, b: DirectEntryInfoFe) => {
        return DateUtil.getTimeDifference(b.timeFrom , a.timeFrom)
      }
    } else if (this.sortedBy.value == 'timeTo') {
      sortFun = (a: DirectEntryInfoFe, b: DirectEntryInfoFe) => {
        return DateUtil.getTimeDifference(a.timeTo , b.timeTo)
      }
    } else if (this.sortedBy.value == 'timeToDes') {
      sortFun = (a: DirectEntryInfoFe, b: DirectEntryInfoFe) => {
        return DateUtil.getTimeDifference(b.timeTo , a.timeTo)
      }
    } else if (this.sortedBy.value == 'name') {
      sortFun = (a: DirectEntryInfoFe, b: DirectEntryInfoFe) => {
        if (a.taxonomyEntity?.getLabel()?.toLowerCase() < b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return -1 
        } else if (a.taxonomyEntity?.getLabel()?.toLowerCase() > b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'nameDes') {
      sortFun = (a: DirectEntryInfoFe, b: DirectEntryInfoFe) => {
        if (a.taxonomyEntity?.getLabel()?.toLowerCase() < b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return 1 
        } else if (a.taxonomyEntity?.getLabel()?.toLowerCase() > b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      if (this.groupedBy) {
        this.filteredGroupedStageTableInfos.forEach(group => group.entries = group.entries.sort(sortFun))
      } else {
        this.filteredStageTableInfos = this.filteredStageTableInfos.sort(sortFun);
      }
    }
  }

  isStatusSelected(status: string) {
    return this.selectedSatusesLocaleKey.has(status);
  }  
  
  applyFilters() {
    this.applyShowFilter();
  }

  changeShowFilter(event: Event) {
    let el = event.target as HTMLInputElement
    if (el.checked) {
      this.selectedSatusesLocaleKey.add(el.id)
    } else {
      this.selectedSatusesLocaleKey.delete(el.id)
    }
    this.applyFilters();
  }

  getSelectedStatusLocaleKeys(): string[]{
    return Array.from(this.selectedSatusesLocaleKey)
  }

  applyShowFilter() {
    if (this.selectedSatusesLocaleKey.size > 0) {
      this.stageTableInfos = this.datahubFilteredStageTableInfos.filter(f => this.selectedSatusesLocaleKey.has(f.statusLocaleKey()))
    } else {
      this.stageTableInfos = this.datahubFilteredStageTableInfos;
    }
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedSatusesLocaleKey.clear();
    this.clearDateFilter();
  }

  handleDateCustomFilters() {
    this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value instanceof EntityFe)
    if (this.date1){
      let name, date1 = new Date(this.date1), date2;
      if (this.date2) {
        date2 = new Date(this.date2)
      }

      if (this.periodFilter == 'startIs'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'startIsBetween' && this.date2){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is_in_between', {date1: DateUtil.toString3(date1), date2: DateUtil.toString3(date2)})
      } else if (this.periodFilter == 'startIsBefore'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is_before', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'startIsAfter'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is_after', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'endIs'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'endIsBetween' && this.date2){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is_in_between', {date1: DateUtil.toString3(date1), date2: DateUtil.toString3(date2)})
      } else if (this.periodFilter == 'endIsBefore'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is_before', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'endIsAfter'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is_after', {date: DateUtil.toString3(date1)})
      }
      this.customMultipleFilters.push({name, value: date1, value2: date2})
    }
    this.applyFilters();
  }

  clearDateFilter() {
    this.periodFilter = null;
    this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value instanceof EntityFe)
    this.date1 = null;
    this.date2 = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: EntityFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      this.customMultipleFilters.push({ name: value.getTaxonomyTitle(this.depTaxonomy), value, level})
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.stageTableInfos = this.stageTableInfos.filter(info => {
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof EntityFe) {
            let taxonomyKeys = new Set<string>();
            if (f.level == 0) {
              let parent = this.depTaxonomy.childrenSortedByOrdinal(value.key)
              let child = []
              parent.forEach(item => child.push(...this.depTaxonomy.childrenSortedByOrdinal(item.key)))
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else if (f.level == 1) {
              let child = this.depTaxonomy.childrenSortedByOrdinal(value.key)
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else {
              taxonomyKeys.add(value.key)
            }
            if (taxonomyKeys.has(info.taxonomyKey))
              return true;
          } else if (value instanceof Date) {
            if (this.checkCustomDateFilter(info, value, f.value2)) {
              return true;
            }
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  checkCustomDateFilter(info: DirectEntryInfoFe, date1: Date, date2: Date): boolean{
    if (this.periodFilter == 'startIs'){
      return DateUtil.getTimeDifference(date1, info.timeFrom) > -1 && DateUtil.getTimeDifference(date1, info.timeFrom) < 1
    } else if (this.periodFilter == 'startIsBetween' && date2){
      return DateUtil.getTimeDifference(date1, info.timeFrom) < 0 && DateUtil.getTimeDifference(date2, info.timeFrom) > 0
    } else if (this.periodFilter == 'startIsBefore'){
      return DateUtil.getTimeDifference(date1, info.timeFrom) > 0
    } else if (this.periodFilter == 'startIsAfter'){
      return DateUtil.getTimeDifference(date1, info.timeFrom) < 0
    } else if (this.periodFilter == 'endIs'){
      return DateUtil.getTimeDifference(date1, info.timeTo) > -1 && DateUtil.getTimeDifference(date1, info.timeTo) < 1
    } else if (this.periodFilter == 'endIsBetween' && date2){
      return DateUtil.getTimeDifference(date1, info.timeTo) < 0 && DateUtil.getTimeDifference(date2, info.timeTo) > 0
    } else if (this.periodFilter == 'endIsBefore'){
      return DateUtil.getTimeDifference(date1, info.timeTo) > 0
    } else if (this.periodFilter == 'endIsAfter'){
      return DateUtil.getTimeDifference(date1, info.timeTo) < 0
    }
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == "") {
      this.searchKeyword = keyword;
    }
    this.filteredStageTableInfos = this.stageTableInfos.filter(info => {
      if (info.taxonomyEntity) {
        return info.taxonomyEntity.getLabel().match(new RegExp(this.searchKeyword, "i")) 
          || info.parentEntity?.getLabel().match(new RegExp(this.searchKeyword, "i")) 
          || info.rootParentEntity?.getLabel().match(new RegExp(this.searchKeyword, "i"))
      }
      return false
    })
    this.group();    
  }

  async filterEntities(keyword: string = '') {
    let relatedEntitiesSet = new Set<EntityFe>();
    this.datahubFilteredStageTableInfos.forEach(info => relatedEntitiesSet.add(info.rootParentEntity))
    let relatedEntities = Array.from(relatedEntitiesSet)
    relatedEntities = relatedEntities.filter(entity => entity)
    this.filteredEntities = relatedEntities.filter(root => {
      if (root.getLabel().match(new RegExp(keyword, "i")) ) {
        return true
      }
      let parents = this.depTaxonomy.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().match(new RegExp(keyword, "i")) )) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.depTaxonomy.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().match(new RegExp(keyword, "i")) )) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    this.filteredEntities.forEach(enitity => { 
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
      let parentEntities = this.depTaxonomy.childrenSortedByOrdinal(enitity.key)
      parentEntities.forEach(parent => {
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != parent)
        let childEntities = this.depTaxonomy.childrenSortedByOrdinal(parent.key)
        childEntities.forEach(child => {
          this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != child)
        })  
      })
    })
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    this.filteredEntities.forEach(enitity => { 
      if (!this.customMultipleFilters.find(f => f.value == enitity)) {
        this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.depTaxonomy), value: enitity})
      } 
      let parentEntities = this.depTaxonomy.childrenSortedByOrdinal(enitity.key)
      parentEntities.forEach(parent => {
        if (!this.customMultipleFilters.find(f => f.value == parent)) {
          this.customMultipleFilters.push({ name: parent.getTaxonomyTitle(this.depTaxonomy), value: parent})
        } 
        let childEntities = this.depTaxonomy.childrenSortedByOrdinal(parent.key)
        childEntities.forEach(child => {
          if (!this.customMultipleFilters.find(f => f.value == child)) {
            this.customMultipleFilters.push({ name: child.getTaxonomyTitle(this.depTaxonomy), value: child})
          } 
        })
      })
    })
    this.applyFilters();
  }
  
  getCustomFilterText() {
    let text = '';
    text+= this.getSelectedStatusLocaleKeys().map(key => `${this.locale(key)} ${ this.locale('locale_key.pages.datahub.files_portal.filter_text.tables')}`).join(', ');
    if (this.selectedSatusesLocaleKey.size>0 && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }


  async downloadDateEntry(item: DirectEntryInfoFe, event: MouseEvent, exportType: string) {
    event.stopPropagation();
    if(!item.dataGridService) {
      item.dataGridService = new StageTableDataGridIntergationService(item, item.taxonomyEntity, this.stateService.getBackendService(), this.languageService)
    } 
    await item.dataGridService.downloadDataEntry(exportType);
  }
}
