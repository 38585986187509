import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { RequestFe, REQUEST_TASK_STATUS } from "src/app/model/data-suppliers/request/RequestFe";
import { RequestGroupFe, REQUEST_GROUP__STATUS } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { DateUtil } from "src/app/utils/DateUtil";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import moment from "moment";
import parser from "cron-parser";
import * as _ from "lodash"
import { SchedulingOccurenceGeneratorFe, SchedulingOccurenceGeneratorFromTypes } from "src/app/model/scheduling/SchedulingOccurenceGeneratorFe";
import { RequestGroup_AddFe } from "src/app/model/data-suppliers/request/add/RequestGroup_AddFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { Request_AddFe } from "src/app/model/data-suppliers/request/add/Request_AddFe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ReminderScheduleFe } from "src/app/model/data-suppliers/request/recurrence/ReminderScheduleFe";
import { CustomRepeatScheduleFe } from "src/app/model/data-suppliers/request/recurrence/CustomRepeatScheduleFe";
import { IntervalFe } from "src/app/model/data-suppliers/request/recurrence/IntervalFe";
import { NamedOccurenceFe } from "src/app/model/data-suppliers/request/recurrence/NamedOccurenceFe";
import { RecurrenceFe } from "src/app/model/data-suppliers/request/recurrence/RecurrenceFe";
import { RepeatScheduleFe } from "src/app/model/data-suppliers/request/recurrence/RepeatScheduleFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { QUESTION_TYPE } from "src/app/model/data-suppliers/request/questionnaire/QuestionTypeFe";
interface Period {
  label: string;
  name: string;
  startDate: string;
  endDate: string;
}
enum ModalNames {
  stopRecurrence = "stopRecurrence",
  remindNowModal = "remindNowModal",
  scheduleReminderModal = "scheduleReminderModal",
  updateRecurrenceModal = "updateRecurrenceModal",
}
@Component({
  selector: "request-details",
  templateUrl: "./details.component.html",
  styleUrls: ["../../details/details.component.scss"],
})
export class RequestDetailsComponent extends AbstractLanguageComponent implements OnInit {
  requestGroup: RequestGroupFe;
  request: RequestFe | null;
  rightModalTitleReq: string = "Request Info";
  changeDeadlineOnHover: boolean;
  selectedOption: any
  showAdditionalInputs = false
  recurrences: number = 0;
  periods: Period[] = [
    { label: '1st request period', name: '', startDate: '', endDate: '' },
    { label: '2nd request period', name: '', startDate: '', endDate: '' }
  ];
  intervals: boolean = false
  reminder: boolean = false
  intervalsView: boolean = false
  morePeriods: boolean = false
  selectedValues: string[] = [];
  reminderEdit: boolean = false

  isEditingRecurrence = false
  isEditingReminder

  recurrenceType = "FIXED";
  repeatPresets = [];
  repeatPresetsValueMap = {};
  selectedRepeatPreset = "WEEKLY";
  customRepeatMonthlyPresets = [];
  customRepeatMonthlyPresetsValueMap = {};
  selectedCustomRepeatUnit = "DAILY";
  selectedCustomRepeatValue = 1;
  selectedCustomRepeatDayOfWeek = "";
  selectedCustomRepeatMonthType = ""
  selectedRecurrenceEnds = "NEVER";
  nextDeadline = moment().add(1, 'month').startOf('day').toISOString().split('T')[0];
  maxOccurrences = 0;
  sendOutType = "DATE";
  sendOutIntervalType = "BEFORE_DEADLINE"
  sendOutIntervalUnit = "DAY";
  sendOutIntervalValue = 1;
  repeatType = "PRESET";
  occurrences = [];
  occurrencesMap = {};
  occurrencesNameCache = {}
  neverOccurrences = [];
  neverOccurrencesMap = {};
  onDateOccurrences = [];
  onDateOccurrencesMap = {};
  onDateOccurrencesNameCache = {};
  maxDateUI = "";
  maxDate = "";
  reminders: ReminderScheduleFe[] = [new ReminderScheduleFe("BEFORE_DEADLINE", "WEEK", 1, "WEEK_1")];
  sendReminderToCreator: boolean = false;
  showDeadlineError: boolean = false;
  deadlineErrorMsg = this.locale('locale_key.general.validation_message.deadline_required');
  showRecurrenceTypeError: boolean = false;
  recurrenceTypeErrorMsg = this.locale('locale_key.general.validation_message.request_frequency_required');
  sendOutDayErrorMsg = this.locale('locale_key.general.validation_message.past_send_out_dates')
  showSendOutDayError: boolean
  maxDateErrorMsg: string = this.locale('locale_key.general.validation_message.max_date_required');
  showMaxDateError: boolean

  updatingReminder: boolean = false;
  todaysDate = moment().utc().startOf('day').toISOString().split('T')[0];
  loadingInProgress = false;
  reviewMessage = "";
  isLoading = false;
  updatingRecurrence: boolean = false;

  @ViewChild(`${ModalNames.remindNowModal}`, { static: true }) remindNowModal: TemplateRef<any>;
  @ViewChild(`${ModalNames.scheduleReminderModal}`, { static: true }) scheduleReminderModal: TemplateRef<any>;
  @ViewChild(`${ModalNames.updateRecurrenceModal}`, { static: true }) updateRecurrenceModal: TemplateRef<any>;
  @ViewChild(`${ModalNames.stopRecurrence}`, { static: true }) stopReccurenceModal: TemplateRef<any>;
  modals;

  constructor(private modalRef: BsModalRef, private modalService: BsModalService, private displayService: DisplayServiceFe, public stateService: StateServiceFe, languageService: LanguageService, private loginService: LoginServiceFe) {
    super(languageService);
    stateService.requestGroupUpdatedSubject.subscribe(rg => {
      if (rg.id == this.requestGroup.id) {
        this.requestGroup = rg;
      }
    })
  }

  ngOnInit() {
    this.setupModals();
    if (this.displayService.detailsData) {
      this.requestGroup = this.displayService.detailsData.requestGroup;
      this.request = this.displayService.detailsData.request;
      this.updateRecurrenceInputs();
      this.updateRepeatPresets();
    }
    this.displayService.detailsDataUpdatedSubject.subscribe((detailsData) => {
      const data = this.displayService.detailsData;
      if (data) {
        this.requestGroup = data.requestGroup;
        this.request = data.request;
      }
    });
    this.updateRepeatPresets();
  }

  setupModals() {
    this.modals = {
      [`${ModalNames.remindNowModal}`]: { template: this.remindNowModal, class: `modal-md ${ModalNames.remindNowModal}` },
      [`${ModalNames.scheduleReminderModal}`]: { template: this.scheduleReminderModal, class: `modal-md ${ModalNames.scheduleReminderModal}` },
      [`${ModalNames.updateRecurrenceModal}`]: { template: this.updateRecurrenceModal, class: `modal-md ${ModalNames.updateRecurrenceModal}` },
      [`${ModalNames.stopRecurrence}`]: { template: this.stopReccurenceModal }
    }
  }
  updateTabReq(title: string) {
    this.rightModalTitleReq == title;
  }

  changeView(viewNo: number) {
    const data = { viewNo };
    this.displayService.updateDataFromDetails(data);
  }

  getDeadline(date: Date) {
    return DateUtil.getDeadlineClass(new Date(date));
  }

  getRequestGroupStatus() {

  }

  //Recurrence 
  async startStopRecurrence() {
    this.openModal({ modal: ModalNames.stopRecurrence });
  }

  async confirmStopRecurrence() {
    this.isLoading = true;
    const newRequestGroup = this.genRequestGroup();
    newRequestGroup.recurrence.stopped = true;
    this.isEditingRecurrence = false;
    await this.stateService.stopRecurrence(newRequestGroup)
    //close modal
    this.closeModal({ modal: ModalNames.stopRecurrence });
    this.isLoading = false;
  }

  async cancelStopRecurrence() {
    this.closeModal({ modal: ModalNames.stopRecurrence });
  }

  addRequestingPeriod() {
    const newRequestingPeriodHtml = `
      <div class="form-group">
      <label for="date-input" class="mb-0 pb-0">Aug 6, 2022 - Sep 6, 2022</label>
        <input type="text" class="form-control" id="requesting-period-name" placeholder="Name of requesting period">
      </div>
    `
    const newRequestingPeriod = document.createElement("div")
    newRequestingPeriod.innerHTML = newRequestingPeriodHtml
    const anchorElement = document.querySelector("#new-requesting-period")
    anchorElement.insertAdjacentElement("beforebegin", newRequestingPeriod.firstElementChild)
  }

  onDateChange() {
    this.showAdditionalInputs = true
  }

  getArray(n: number): number[] {
    return Array(n).fill(0).map((_, i) => i);
  }

  addPeriod() {
    const label = `${this.periods.length + 1}th request period`;
    const newPeriod: Period = { label, name: '', startDate: '', endDate: '' };
    this.periods.push(newPeriod);
  }

  showIntervals() {
    this.morePeriods == false ? this.morePeriods = true : this.morePeriods = false
  }

  addReminder(type) {
    const reminder = new ReminderScheduleFe(type, "WEEK", 1, "WEEK_1")
    this.reminders.push(reminder);
  }

  removeReminder(index: number) {
    // Remove the reminder from the reminders array
    this.reminders.splice(index, 1);

    // Remove the corresponding value from the selectedValues array
    this.selectedValues.splice(index + 1, 1);
  }

  onSelectChange(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedValues[index] = selectElement.value;
  }

  isRecurring(requestGroup: RequestGroupFe) {
    if (requestGroup.recurrence) {
      return requestGroup.recurrence.namedOccurences.length > 0 && !requestGroup.recurrence.stopped;
    }
    return false;
  }

  hasReminders(requestGroup: RequestGroupFe) {
    return requestGroup.recurrence 
          && requestGroup.recurrence.sendScheduledReminders 
          && requestGroup.recurrence.reminders 
          && requestGroup.recurrence.reminders.length > 0
  }

  editRecurrence() {
    this.isEditingRecurrence = true;
  }

  cancelEditingRecurrence() {
    this.isEditingRecurrence = false;
    this.updateRecurrenceInputs();
    this.updateRepeatPresets();
  }

  startUpdateRecurrence() {
    this.openModal({ modal: ModalNames.updateRecurrenceModal });
  }

  cancelUpdateRecurrence() {
    this.closeModal({ modal: ModalNames.updateRecurrenceModal });
  }

  async confirmUpdateRecurrence() {
    
    if (_.isEmpty(this.recurrenceType)) {
      this.showRecurrenceTypeError = true;
      return;
    }

    if (_.isEmpty(this.nextDeadline)) {
      this.showDeadlineError = true;
      return;
    }

    if (this.selectedRecurrenceEnds == "ON_DATE" && this.maxDateUI == "") {
      this.showMaxDateError = true;
      return;
    }

    this.updatingRecurrence = true
    const newRequestGroup = { ...this.genRequestGroup() };
    await this.stateService.updateRecurrence(newRequestGroup);

    this.requestGroup.recurrence = newRequestGroup.recurrence;

    this.closeModal({ modal: ModalNames.updateRecurrenceModal });
    this.updatingRecurrence = false
    this.isEditingRecurrence = false;
  }

  editReminder() {
    this.isEditingReminder = true;
  }

  cancelEditingReminder() {
    this.isEditingReminder = false;
    this.updateRecurrenceInputs();
    this.updateRepeatPresets();
  }

  startRemindNow() {
    this.openModal({ modal: ModalNames.remindNowModal });
  }

  cancelRemindNow() {
    this.closeModal({ modal: ModalNames.remindNowModal });
  }

  async confirmRemindNow() {
    this.isLoading = true;
    const requestGroup = { ...this.genRequestGroup() };

    const parentID = requestGroup.id;
    let requestGroups = (await this.stateService.getRequestGroups()).filter(requestGroup => requestGroup.id == parentID || requestGroup.recurringParent == parentID);
    requestGroups = _.orderBy(requestGroups, requestGroup => requestGroup.recurrence.nextDeadline, 'desc');
    const currentRequestGroup = this.genRequestGroupAdd(requestGroups[0]);

    await this.stateService.remindNow(currentRequestGroup);
    this.isEditingReminder = false;
    this.isLoading = false;
    this.closeModal({ modal: ModalNames.remindNowModal });
  }

  startScheduleReminder() {
    this.openModal({ modal: ModalNames.scheduleReminderModal });
  }

  cancelScheduleReminder() {
    this.closeModal({ modal: ModalNames.scheduleReminderModal });
  }

  async confirmScheduleReminder() {
    this.updatingReminder = true;
    const newRequestGroup = { ...this.genRequestGroup() };
    newRequestGroup.recurrence.sendScheduledReminders = true;
    await this.stateService.updateReminder(newRequestGroup);
    this.requestGroup.recurrence.reminders = newRequestGroup.recurrence.reminders;
    this.requestGroup.recurrence.sendScheduledReminders = true;
    this.closeModal({ modal: ModalNames.scheduleReminderModal });
    this.updatingReminder = false;
    this.isEditingReminder = false;
  }

  setCustomRepeatDayOfWeek(dayOfWeek) {
    this.selectedCustomRepeatDayOfWeek = dayOfWeek;
    this.repeatValueChanged();
  }

  isSelectedCustomRepeatDayOfWeek(dayOfWeek) {
    return this.selectedCustomRepeatDayOfWeek == dayOfWeek;
  }

  getRepeatPresets(nextDeadline = moment().toISOString()) {

    const momentObj = moment(nextDeadline);

    const isoWeekDays = { "1": "Monday", "2": "Tuesday", "3": "Wednesday", "4": "Thursday", "5": "Friday", "6": "Saturday", "7": "Sunday" };
    const weekly_day = momentObj.isoWeekday();
    const weekly = this.locale(`locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.weekly_on`, {weekly_day: isoWeekDays[weekly_day]});
    const weekly_cron = `5 0 * * ${weekly_day}`;

    const monthly_date = momentObj.date();
    const monthly = this.locale(`locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.monthly_on`, {monthly_date: monthly_date});
    const monthly_cron = `5 0 ${monthly_date} * *`;

    const quaterly_month_pos = { "January": { "quarter": 1, "monthPos": 1 }, "February": { "quarter": 1, "monthPos": 2 }, "March": { "quarter": 1, "monthPos": 3 }, "April": { "quarter": 2, "monthPos": 1 }, "May": { "quarter": 2, "monthPos": 2 }, "June": { "quarter": 2, "monthPos": 3 }, "July": { "quarter": 3, "monthPos": 1 }, "August": { "quarter": 3, "monthPos": 2 }, "September": { "quarter": 3, "monthPos": 3 }, "October": { "quarter": 4, "monthPos": 1 }, "November": { "quarter": 4, "monthPos": 2 }, "December": { "quarter": 4, "monthPos": 3 } };
    const months_in_a_year = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month_pos_sufx = { "1": "st", "2": "nd", "3": "rd" };

    // Maps which months are the 1st, 2nd or 3rd in a quater
    const month_pos_to_cron = { "1": "1,4,7,10", "2": "2,5,8,11", "3": "3,6,9,12" };

    const yearly_month = momentObj.month();
    // cron months start from 1, while moment months start from 0

    const yearly_cron_month = yearly_month + 1;
    const monthPos = quaterly_month_pos[months_in_a_year[yearly_month]].monthPos;
    const quarterly_month = `${monthPos}${month_pos_sufx[monthPos]}`;

    const quarterly = this.locale('locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.quarterly_on', {monthly_date: monthly_date, quarterly_month: quarterly_month});
    const quarterly_cron = `5 0 ${monthly_date} ${month_pos_to_cron[monthPos]} *`;

    const yearly = this.locale('locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.yearly_on', {monthly_date: monthly_date, yearly_month: months_in_a_year[yearly_month]});
    const yearly_cron = `5 0 ${monthly_date} ${yearly_cron_month} *`;

    return [
      {
        human: weekly,
        cron: weekly_cron,
        value: "WEEKLY"
      },
      {
        human: monthly,
        cron: monthly_cron,
        value: "MONTHLY"
      },
      {
        human: quarterly,
        cron: quarterly_cron,
        value: "QUATERLY"
      },
      {
        human: yearly,
        cron: yearly_cron,
        value: "YEARLY"
      }
    ]
  }

  getCustomRepeatMonthlyPresets(nextDeadline = moment().toISOString()) {

    const get_date_of_month = (isodate) => {
      const date = moment(isodate).date();
      const human = this.locale('locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.on_date_of_month', {date})
      const cron = `5 0 ${date} * *`
      return {
        human,
        cron
      }
    }

    const get_day_occurence_in_month = (isodate) => {

      const days_in_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = moment(isodate).day();
      const day_str = days_in_week[day];

      const start_month = moment(isodate);
      start_month.set('date', 1);
      const startIsoDate = start_month.toISOString();

      const interval = parser.parseExpression(`0 3 * * ${day}`, { currentDate: startIsoDate });
      const occurences = {};
      const occurencesArray = [];
      if (start_month.day() == day) {
        occurencesArray.push(startIsoDate.split('T')[0])
      }
      for (let i = 0; i < 5; i++) {
        const date = interval.next().toISOString();
        const monthStr = date.split('T')[0];
        occurencesArray.push(monthStr);
      }
      occurencesArray.forEach((occurence, idx) => {
        occurences[occurence] = (idx + 1);
      });

      const month_str = isodate.split('T')[0];
      const day_occurence_pfix = occurences[month_str];
      const day_occurence_sfix = { "1": "st", "2": "nd", "3": "rd", "4": "th" };
      const day_occurence = `${day_occurence_pfix}${day_occurence_sfix[day_occurence_pfix] || 'th'}`;

      const human = this.locale('locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.on_day_of_month', {day_occurence, day_str});

      const cron = `5 0 0 ? * ${day}#${day_occurence_pfix}`;

      return {
        human,
        cron
      }
    }

    const get_last_day_occurence_in_month = (isodate) => {
      const days_in_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = moment(isodate).day();
      const day_str = days_in_week[day];

      const human = this.locale('locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.last_day_of_month', {day_str});
      const cron = `5 0 0 * * ${day}L`;
      return {
        human,
        cron
      }
    }

    const isodate = moment(nextDeadline).toISOString();
    const dom = get_date_of_month(isodate);
    const doim = get_day_occurence_in_month(isodate);
    const ldoim = get_last_day_occurence_in_month(isodate);

    return [
      {
        ...dom,
        "value": "DATE_OF_MONTH"
      },
      {
        ...doim,
        "value": "DAY_OCCURENCE_IN_MONTH"
      },
      {
        ...ldoim,
        "value": "LAST_DAY_OCCURENCE_IN_MONTH"
      }

    ]
  }

  nextDeadlineChanged() {
    this.showDeadlineError = false
    if (_.isEmpty(this.nextDeadline)) {
      this.showDeadlineError = true;
    }
    this.maxDateUI = "";
    this.maxDate = "";
    this.updateRepeatPresets();
    this.clearOccurences();
    this.validateSendOut();
  }
  sendOutChanged() {
    this.updateRepeatPresets();
    this.clearOccurences();
    this.validateSendOut();
  }

  validateSendOut() {
    const nextDeadline = moment(this.nextDeadline).utc(true).toISOString();
    const occurences = this.getNextOccurrences({ numOccurences: this.maxOccurrences, nextDeadline });

    let firstOccurenceDate: any = Object.keys(occurences)[0]
    firstOccurenceDate = new Date(firstOccurenceDate)
    let todaysDate: any = new Date().toDateString()
    todaysDate = new Date(todaysDate)

    if (DateUtil.getTimeDifference(firstOccurenceDate, todaysDate) < 0) {
      this.showSendOutDayError = true;
    } else {
      this.showSendOutDayError = false;
    }
  }

  reRenderNamedOccurrences() {
    if (this.maxDateUI) {
      this.maxDateChanged();
    }
    if (this.maxOccurrences) {
      this.maxOccurrencesChanged();
    }
  }

  repeatTypeChanged() {
    this.repeatType = "PRESET"
    if (this.selectedRepeatPreset == "CUSTOM") {
      this.repeatType = "CUSTOM"
    }
    this.clearMaxDate();
    this.clearOccurences();
    this.reRenderNamedOccurrences();
  }

  repeatValueChanged() {
    this.clearMaxDate();
    this.clearOccurences();
    this.reRenderNamedOccurrences();
  }

  clearMaxDate() {
    this.maxDateUI = "";
    this.maxDate = "";
  }

  updateRepeatPresets() {
    //timezone issues
    const moment_str = moment(this.nextDeadline).utc(true).toISOString();
    this.repeatPresets = this.getRepeatPresets(moment_str);
    this.repeatPresets.forEach(preset => {
      this.repeatPresetsValueMap[`${preset.value}`] = preset;
    });
    this.customRepeatMonthlyPresets = this.getCustomRepeatMonthlyPresets(moment_str);
    this.customRepeatMonthlyPresets.forEach(preset => {
      this.customRepeatMonthlyPresetsValueMap[`${preset.value}`] = preset;
    })
  }

  formatUIOccurrence(occurence) {
    const date_format = "DD MMM, YYYY";
    occurence.SEND_OUT_DATE_UI = moment(occurence.SEND_OUT_DATE).format(date_format);
    occurence.NEXT_DEADLINE_UI = moment(occurence.NEXT_DEADLINE).format(date_format);
    return occurence;
  }

  maxOccurrencesChanged() {
    if (this.maxOccurrences < 1) {
      this.maxOccurrences = 1;
    }
    //timezone issues
    const nextDeadline = moment(this.nextDeadline).utc(true).toISOString();
    const occurences = this.getNextOccurrences({ numOccurences: this.maxOccurrences, nextDeadline });
    const consolidated = {};
    Object.keys(occurences).forEach(SEND_OUT_DATE => { consolidated[SEND_OUT_DATE] = this.occurrencesMap[SEND_OUT_DATE] || occurences[SEND_OUT_DATE] });

    this.occurrencesMap = consolidated;
    this.occurrences = Object.keys(consolidated).sort().map((date, idx) => {
      const occurence = consolidated[date];
      const occurenceNo = idx + 1;
      const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" }
      const occurenceNoSuffix = occurenceNoSuffixes[occurenceNo] || "th"
      const defaultName = `${occurenceNo}${occurenceNoSuffix} requesting period`;
      occurence.NAME = defaultName;
      this.occurrencesMap[date].NAME = defaultName;

      const cachedName = this.occurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`];
      if (!_.isEmpty(cachedName)) {
        occurence.NAME = cachedName;
        this.occurrencesMap[date].NAME = cachedName;
      }

      return this.formatUIOccurrence(occurence);
    });
  }

  generateCustomRepeatCron({ unit, value }) {
    let cron = ""
    switch (unit) {
      case 'DAILY':
        cron = `5 0 */${value} * *`
        break;
      case 'WEEKLY':
        break;
      case 'MONTHLY':
        break;
      case 'YEARLY':
        break;
    }
    return cron;
  }

  getNextOccurrences({ numOccurences, nextDeadline }) {
    const sendOutUnitToMomentMap = { "DAY": "d", "WEEK": "w", "MONTH": "M", "YEAR": "y" };
    const momentDuration = sendOutUnitToMomentMap[this.sendOutIntervalUnit];

    const repeatPreset = this.selectedRepeatPreset;
    let cron = "";

    switch (repeatPreset) {
      case 'CUSTOM':
        cron = this.generateCustomRepeatCron({ unit: this.selectedCustomRepeatUnit, value: this.selectedCustomRepeatValue })
        break;
      default:
        cron = this.repeatPresetsValueMap[repeatPreset].cron;
        break;
    }

    const interval = parser.parseExpression(cron, { currentDate: nextDeadline });
    const occurences = {};

    const sendOutDate = moment(nextDeadline).subtract(this.sendOutIntervalValue, momentDuration).toISOString();
    occurences[sendOutDate] = {
      SEND_OUT_DATE: sendOutDate,
      NEXT_DEADLINE: nextDeadline,
      NAME: ""
    }

    for (let i = 0; i < numOccurences - 1; i++) {
      const newDeadline = interval.next().toISOString();
      const newSendOutDate = moment(newDeadline).subtract(this.sendOutIntervalValue, momentDuration).toISOString();
      occurences[newSendOutDate] = {
        SEND_OUT_DATE: newSendOutDate,
        NEXT_DEADLINE: newDeadline,
        NAME: ""
      }
    }

    return occurences;
  }

  occurenceUpdated(occurence) {
    this.occurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`] = occurence.NAME;
    this.occurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME;
  }

  neverOccurrenceUpdated(occurence) {
    this.neverOccurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME;
  }

  addNeverOccurence() {
    let nextDeadline = "";
    let SEND_OUT_DATE = "";

    if (this.neverOccurrences.length == 0) {
      //timezone issues
      nextDeadline = moment(this.nextDeadline).utc(true).toISOString();
    } else {
      nextDeadline = this.neverOccurrences[(this.neverOccurrences.length - 1)].NEXT_DEADLINE;
    }

    const occurences = this.getNextOccurrences({ numOccurences: 2, nextDeadline });

    if (this.neverOccurrences.length == 0) {
      SEND_OUT_DATE = Object.keys(occurences)[0];
    } else {
      SEND_OUT_DATE = Object.keys(occurences).pop();
    }

    const occurence = occurences[SEND_OUT_DATE];

    const occurenceNo = this.neverOccurrences.length + 1;
    const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" }
    const occurenceNosuffix = occurenceNoSuffixes[occurenceNo] || "th"
    const defaultName = `${occurenceNo}${occurenceNosuffix} requesting period`;
    occurence.NAME = defaultName;

    this.neverOccurrencesMap[SEND_OUT_DATE] = this.formatUIOccurrence(occurence);
    this.neverOccurrences.push(occurence);
  }

  isLastNeverOccurence(idx) {
    const lastIdx = this.neverOccurrences.length - 1;
    return idx == lastIdx;
  }

  removeLastNeverOccurrence() {
    this.neverOccurrences.pop();
  }

  maxDateChanged() {
    //timezone issues
    if (this.maxDateUI == "") {
      this.showMaxDateError = true;
      return;
    } else {
      this.showMaxDateError = false;
    }

    this.maxDate = moment(this.maxDateUI).utc(true).toISOString();

    const occurenceGenerator = new SchedulingOccurenceGeneratorFe();
    const recurrence = this.genRecurrence();
    const recurrenceBuffer = { ...recurrence };
    const rawOccurrences = []
    let occurenceBuffer = occurenceGenerator.generateOccurences({ recurrence: recurrenceBuffer, fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE, numOccurrences: 2 });
    let shouldContinue = true
    do {
      rawOccurrences.push(occurenceBuffer[0]);
      if (moment(occurenceBuffer[1].nextDeadline).isAfter(recurrence.maxDate)) {
        shouldContinue = false;
      } else {
        recurrenceBuffer.nextDeadline = occurenceBuffer[1].nextDeadline
        occurenceBuffer = occurenceGenerator.generateOccurences({ recurrence: recurrenceBuffer, fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE, numOccurrences: 2 });
      }
    } while (shouldContinue);
    const occurrences = {}
    rawOccurrences.forEach(occurrence => {
      const sendOutDate = occurrence.sendOutDay;
      occurrences[sendOutDate] = {
        SEND_OUT_DATE: sendOutDate,
        NEXT_DEADLINE: occurrence.nextDeadline,
        NAME: ""
      }
    });

    const consolidated = {};
    Object.keys(occurrences).forEach(SEND_OUT_DATE => { consolidated[SEND_OUT_DATE] = this.onDateOccurrencesMap[SEND_OUT_DATE] || occurrences[SEND_OUT_DATE] });
    this.onDateOccurrencesMap = consolidated;
    this.onDateOccurrences = Object.keys(consolidated).sort().map((date, idx) => {
      const occurence = consolidated[date];
      const occurenceNo = idx + 1;
      const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" }
      const occurenceNoSuffix = occurenceNoSuffixes[occurenceNo] || "th"
      const defaultName = `${occurenceNo}${occurenceNoSuffix} requesting period`;
      occurence.NAME = defaultName;
      this.onDateOccurrencesMap[date].NAME = defaultName;

      const cachedName = this.onDateOccurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`];
      if (!_.isEmpty(cachedName)) {
        occurence.NAME = cachedName;
        this.onDateOccurrencesMap[date].NAME = cachedName;
      }

      return this.formatUIOccurrence(occurence);
    });
  }

  onDateOccurrenceUpdated(occurence) {
    this.onDateOccurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`] = occurence.NAME;
    this.onDateOccurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME;
  }

  clearOccurences() {
    this.occurrences = [];
    this.occurrencesMap = {};
    this.occurrencesNameCache = {}
    this.neverOccurrences = [];
    this.neverOccurrencesMap = {};
    this.onDateOccurrences = [];
    this.onDateOccurrencesMap = {};
    this.onDateOccurrencesNameCache = {};
  }

  getPreviousRequestPeriods(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getPreviousRequestPeriods(requestGroup);
    return periods;
  }

  getCurrentRequestPeriod(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getCurrentRequestPeriod(requestGroup);
    return periods;
  }

  getUpcomingRequestPeriods(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getUpcomingRequestPeriods(requestGroup);
    return periods;
  }

  getLastRequestPeriod(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getLastRequestPeriod(requestGroup);
    return periods;
  }

  getScheduledReminders(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const reminders = occurenceGenrator.getScheduledReminders(requestGroup);
    return reminders;
  }

  getLastSentReminder(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const lastReminder = occurenceGenrator.getLastSentReminder(requestGroup);
    return lastReminder;
  }

  updateRecurrenceInputs() {
    if (this.requestGroup.recurrence) {

      this.nextDeadline = moment(this.requestGroup.recurrence.nextDeadline).format("YYYY-MM-DD");
      this.recurrenceType = this.requestGroup.recurrence.type;
      this.sendOutType = this.requestGroup.recurrence.sendOutDate;
      this.sendOutIntervalType = this.requestGroup.recurrence.sendOutInterval.type
      this.sendOutIntervalUnit = this.requestGroup.recurrence.sendOutInterval.unit
      this.sendOutIntervalValue = this.requestGroup.recurrence.sendOutInterval.value
      this.repeatType = this.requestGroup.recurrence.repeatSchedule.type;
      this.selectedRepeatPreset = this.requestGroup.recurrence.repeatSchedule.preset
      this.selectedCustomRepeatUnit = this.requestGroup.recurrence.repeatSchedule.custom.unit
      this.selectedCustomRepeatDayOfWeek = this.requestGroup.recurrence.repeatSchedule.custom.dayOfWeek
      this.selectedCustomRepeatMonthType = this.requestGroup.recurrence.repeatSchedule.custom.monthType
      this.selectedCustomRepeatValue = this.requestGroup.recurrence.repeatSchedule.custom.value
      this.maxDate = this.requestGroup.recurrence.maxDate
      this.maxDateUI = moment(this.requestGroup.recurrence.maxDate).format("YYYY-MM-DD");
      this.maxOccurrences = this.requestGroup.recurrence.maxOccurences
      this.reminders = [...this.requestGroup.recurrence.reminders]
      this.sendReminderToCreator = this.requestGroup.recurrence.sendReminderToCreator
      this.reminder = this.requestGroup.recurrence.sendScheduledReminders
  
      this.selectedRecurrenceEnds = this.requestGroup.recurrence.ends
  
      const occurences = {};
      this.requestGroup.recurrence.namedOccurences.forEach(namedOccurrence => {
        const rawOccurence: any = { ...namedOccurrence };
        rawOccurence.NAME = rawOccurence.name
        rawOccurence.SEND_OUT_DATE = rawOccurence.sendOutDate
        rawOccurence.NEXT_DEADLINE = rawOccurence.nextDeadline
        const key = rawOccurence.sendOutDate
        const occurence = this.formatUIOccurrence(rawOccurence);
        occurences[key] = occurence;
      });
  
      switch (this.selectedRecurrenceEnds) {
        case "NEVER":
          this.neverOccurrencesMap = occurences;
          this.neverOccurrences = Object.keys(occurences).sort().map(date => {
            const occurence = occurences[date];
            return occurence;
          });
          break;
        case "ON_DATE":
          this.onDateOccurrencesMap = occurences;
          this.onDateOccurrences = Object.keys(occurences).sort().map(date => {
            const occurence = occurences[date];
            return occurence;
          });
          break;
        case "AFTER_MAX_OCCURENCES":
          this.occurrencesMap = occurences;
          this.occurrences = Object.keys(occurences).sort().map(date => {
            const occurence = occurences[date];
            return occurence;
          });
          break;
      }
    }

  }

  private genRecurrence() {
    let namedOccurences = {}
    let namedOccurenceFe: NamedOccurenceFe[] = []
    if (this.selectedRecurrenceEnds == "NEVER") {
      namedOccurences = this.neverOccurrencesMap;
    }
    if (this.selectedRecurrenceEnds == "ON_DATE") {
      namedOccurences = this.onDateOccurrencesMap;
    }
    if (this.selectedRecurrenceEnds == "AFTER_MAX_OCCURENCES") {
      namedOccurences = this.occurrencesMap;
    }


    Object.keys(namedOccurences).forEach(key => {
      const occurence_raw = namedOccurences[key];
      const occurence = new NamedOccurenceFe(occurence_raw.SEND_OUT_DATE, occurence_raw.NEXT_DEADLINE, occurence_raw.NAME)
      namedOccurenceFe.push(occurence);
    })


    const deduplicatedRemindersMap: { [key: string]: ReminderScheduleFe } = {}
    const deduplicatedReminders: ReminderScheduleFe[] = []
    this.reminders.forEach(reminder => {
      const key = `${reminder.type}_${reminder.unit}_${reminder.value}_${reminder.preset}`;
      deduplicatedRemindersMap[key] = reminder;
    });
    Object.keys(deduplicatedRemindersMap).forEach(key => {
      deduplicatedReminders.push(deduplicatedRemindersMap[key]);
    });

    const nextDeadline = moment(this.nextDeadline).utc(true).startOf('day').toISOString()
    let sendOutDate = moment(this.requestGroup.date).utc(true).startOf('day').toISOString();
    if (this.requestGroup.recurrence) {
      sendOutDate = moment(this.requestGroup.recurrence.sendOutDate).utc(true).startOf('day').toISOString();
    }
    if (this.sendOutType == "INTERVAL") {
      const unitToMomentMap: any = { "DAY": "d", "WEEK": "w", "MONTH": "M", "YEAR": "y", "weekly": "w", "monthly": "M", "quaterly": "Q", "yearly": "y", "daily": "d" };
      const sendOutIntervalUnit = this.sendOutIntervalUnit;
      const sendOutIntervalValue = this.sendOutIntervalValue
      const sendOutMomentDuration = unitToMomentMap[sendOutIntervalUnit];
      sendOutDate = moment(nextDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration).utc(true).startOf('day').toISOString();
    }

    const recurrence = new RecurrenceFe(
      this.recurrenceType,
      this.sendOutType,
      sendOutDate,
      new IntervalFe(
        this.sendOutIntervalType,
        this.sendOutIntervalUnit,
        this.sendOutIntervalValue
      ),
      nextDeadline,
      namedOccurenceFe,
      new RepeatScheduleFe(
        nextDeadline,
        this.repeatType,
        this.selectedRepeatPreset,
        "",
        new CustomRepeatScheduleFe(
          this.selectedCustomRepeatUnit,
          this.selectedCustomRepeatDayOfWeek,
          this.selectedCustomRepeatMonthType,
          this.selectedCustomRepeatValue,
          ""
        )
      ),
      this.selectedRecurrenceEnds,
      this.maxDate,
      this.maxOccurrences,
      deduplicatedReminders,
      this.sendReminderToCreator,
      this.reminder,
      false
    )

    if (this.repeatPresetsValueMap[this.selectedRepeatPreset]) {
      recurrence.repeatSchedule.presetCron = this.repeatPresetsValueMap[this.selectedRepeatPreset].cron;
    }
    return recurrence;
  }

  private genRequestGroup() {
    const newRecurrence = this.genRecurrence();
    const requests: Request_AddFe[] = this.requestGroup.requests.map(request => new Request_AddFe(request.id, request.supplierCompanyId, request.assigneeAffId));
    
    const requestGroup = new RequestGroup_AddFe(
      this.requestGroup.id,
      this.requestGroup.title,
      this.requestGroup.description,
      this.requestGroup.requests[0].questionnaire,
      requests,
      this.requestGroup.dueDate,
      this.requestGroup.draft,
      newRecurrence,
      this.requestGroup.isChildRecurring,
      this.requestGroup.recurringParent,
      this.stateService.activeWorkspace.affiliationId,
      this.loginService.loginUser.firstName,
      this.loginService.loginUser.lastName,
      this.stateService.activeWorkspace.companyId,
      this.stateService.activeWorkspace.companyName,
      this.loginService.loginUser.userId
    )

    let newRg = {...requestGroup}
    newRg.questionnaire.sections.forEach(section => {
      section.questions = section.questions.map(q => q.type == QUESTION_TYPE.DATA_TABLE ? QuestionDataTableFe.toTransfer(q) : q)
    })
    newRg.questionnaire.relatedActivity = null
    return newRg;
  }

  private genRequestGroupAdd(requestGroupFe: RequestGroupFe) {
    const requests: Request_AddFe[] = requestGroupFe.requests.map(request => new Request_AddFe(request.id, request.supplierCompanyId, request.assigneeAffId));
    const requestGroup = new RequestGroup_AddFe(
      requestGroupFe.id,
      requestGroupFe.title,
      requestGroupFe.description,
      requestGroupFe.requests[0].questionnaire,
      requests,
      requestGroupFe.dueDate,
      requestGroupFe.draft,
      requestGroupFe.recurrence,
      requestGroupFe.isChildRecurring,
      requestGroupFe.recurringParent,
      this.stateService.activeWorkspace.affiliationId,
      this.loginService.loginUser.firstName,
      this.loginService.loginUser.lastName,
      this.stateService.activeWorkspace.companyId,
      this.stateService.activeWorkspace.companyName,
      this.loginService.loginUser.userId
    )
    return requestGroup;
  }


  openModal({ modal }) {
    const template = this.modals[modal].template;
    this.modals[modal].ref = this.modalService.show(template, { backdrop: "static", keyboard: true });
  }

  closeModal({ modal }) {
    const modalRef = this.modals[modal].ref;
    this.modalService.hide(modalRef.id);
  }

  reminderValueChanged({ idx, value }) {
    if (value <= 0) {
      this.reminders[idx].value = 1;
    }
  }
}
