<div class="card m-0">
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white">{{mode == 'add' ? 'Add new' : 'Edit'}} column</h4>
    <button type="button" class="ms-auto btn close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="false" class="list-inline text-white"><a (click)='closeModal()'><i class="las la-times text-white"></i></a></span>
    </button>
  </div>
  <div class="modal-body" *ngIf="column">
    <div class="content clearfix">
      <div class="form-group">
        <div>
          <div class="d-flex flex-row mb-2">
            <div class="col-4">
              <label>Column Name</label>
            </div>
            <div class="col-8">
              <input class="form-control" placeholder="Enter new column name" [(ngModel)]='column.label'>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-4">
              <label>Column Type</label>
            </div>
            <div class="col-8">
              <select id="type" class="form-control" [(ngModel)]='column.type' [disabled]="disableDataType">
                <option value="STRING">Text</option>
                <option value="NUMERIC">Number</option>
                <option value="DATE">Time</option>
                <option value="BOOLEAN">Yes/No</option>
                <!-- <option value="INTEGER">Number</option> -->
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn secondary-button custom-button" (click)='closeModal()'>Cancel</button>
    <button type="submit" class="btn btn-add custom-button" (click)="modifyCol()"
      [disabled]='column.label=="" || (column.label=="INTEGER" && unit=="")'>
      {{mode | titlecase }}
    </button>
  </div>
</div>