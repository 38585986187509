import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatabaseInfoFe } from 'src/app/model/emission/DatabaseInfoFe';
import { DatabaseInfoUiFe } from 'src/app/model/emission/DatabaseInfoUiFe';
import { EmissionFactorFe } from 'src/app/model/emission/EmissionFactorFe';
import { RouterFe } from 'src/app/route/RouterFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';

@Component({
  selector: 'emission-portal',
  templateUrl: './emission-portal.component.html',
  styleUrls: ['./emission-portal.component.scss']
})

export class EmissionPortalComponent extends AbstractLanguageComponent implements OnInit {
  searchSentence;
  searchSentenceSuggestions= [];
  searchResults;
  pageIndex;
  selectedRowIndex = -1;
  selectedRecordColumns;
  selectedRecord;
  databases: DatabaseInfoUiFe[] = [];
  @Output() emissionSelectedEvent = new EventEmitter<EmissionFactorFe>();
  @Output() cancelEvent = new EventEmitter<boolean>();

  constructor(private backendService: RouterFe, languageService: LanguageService) { 
    super(languageService)
  }

  ngOnInit(): void {
    this.loadEmissionDbList()
  }

  loadEmissionDbList() {
    this.backendService.getEmissionDatabases().subscribe(async (data) => {
      this.databases = data
      if (this.databases && this.databases.length > 0) {
        this.databases.forEach(d => d.selected = false)
      }
      this.databases[0].selected = true
    })
  }

  
  public searchInDB(textInput) {
    this.searchSentenceSuggestions = []
    let selectedDbs = this.databases.filter(db => db.selected == true)
    if(selectedDbs.length === 0){
      return
    } else {
      this.backendService.searchEmissions(textInput, selectedDbs).subscribe(async (res) => {
        this.searchResults = res
        if (this.searchResults && this.searchResults.length > 0) {
          this.pageIndex = 0
          this.resetSelection()
        }
      })  
    }
  }

  public selectSearchSuggestion(suggestion) {
    this.searchSentence = suggestion
    this.searchSentenceSuggestions = []
  }

  public onSearchSentenceChange(textInput) {
    // COMMENTING UNTIL MLSERVICE GETS FIXED
    // if (textInput.length > 0 && textInput[textInput.length - 1] == ' ') {
    //   this.backendService.suggestionsForEmissionSearch(textInput).subscribe(async (res) => {
    //     if (res) {
    //       this.searchSentenceSuggestions = res['suggestions']
    //     }
    //   })  
    // }
  }

  selectResultPage(pageIndex) {
    this.pageIndex = pageIndex
    this.resetSelection()
  }

  resetSelection() {
    this.selectedRowIndex = -1
    this.selectedRecordColumns = null
    this.selectedRecord = null  
  }

  selectRecord(index, columns, record) {
    if (this.selectedRowIndex != index) {
      this.selectedRowIndex = index
      this.selectedRecordColumns = columns
      this.selectedRecord = record  
    } else {
      this.resetSelection()
    }
  }

  notifySelection(record) {
    let db: DatabaseInfoFe = this.searchResults[this.pageIndex].metadata
    let ef = new EmissionFactorFe(db, record)
    this.emissionSelectedEvent.emit(ef)
  }

  cancel() {
    this.cancelEvent.emit(true)    
  }

}
