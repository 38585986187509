import { RecipientInfo_AddFe } from "./RecipientInfo_AddFe"

export abstract class AbstractSharedResource_AddFe {
  objectId: string
  type: string
  recipients: RecipientInfo_AddFe[]
  sharedId: string

  constructor(
    objectId: string,
    type: string,
    recipients: RecipientInfo_AddFe[],
    sharedId: string
  ) {
    this.objectId = objectId
    this.type = type
    this.recipients = recipients
    this.sharedId = sharedId
  }
}