<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">

    </div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 >{{locale('locale_key.pages.data_categories.title')}}</h3>
           <!-- dropdown menu instead of toolbar for smaller pages-->
      <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
        <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg;"></i>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
          <ng-container *ngFor="let toolbarGroup of pageToolbar ; let groupIndex=index">
            <div>
              <ng-container *ngFor="let toolbarButton of toolbarGroup">
                <li role="menuitem">
                  <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)"
                  *ngIf="toolbarButton.visible()"
                  >
                    <i [class]="toolbarButton.icon"></i>
                    <span>{{ toolbarButton.longLabel }}</span>
                  </button>
                </li>
              </ng-container>
              <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
            </div>
          </ng-container>
        </ul>
      </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
             
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper mt-5" *ngIf="isLoading">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex h-100 flex-row mr-50" *ngIf="!isLoading">
          <div class="col-3 p-0 scroll card" style="height: 91.3vh !important;max-height: fit-content !important">
            <div class="col-12">
              <div class="card-header px-0 m-0">
                <div class="card-title py-0 d-flex" *ngIf="!isDirty">
                </div>
                <div class="heading-elements">
                  <div class="d-felx flex-row">
                    <div class="btn-group btn-group-sm">
                      <div class="ball-pulse loader-primary" *ngIf="isInProgress">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body ps-2 m-0 w-100">
                <ng-container *ngFor="let item0 of getTaxonomyInfo().childrenSortedByOrdinal(); let i = index;">
                  <div class="card my-3 mx-0 px-0 w-100 border-0" n [ngStyle]="{'border-color': getTaxonomyInfo().color(item0)}">
                    <div class="card-head py-0 d-flex justify-content-between">
                      <div class="card-title d-flex py-0 m-0">
                        <i class="{{getTaxonomyInfo().icon(item0)}} fs-5 my-auto" [ngStyle]="{'color': getTaxonomyInfo().color(item0)}"></i>
                        <h5 class="fw-normal ps-1 my-auto" [ngStyle]="{'color': getTaxonomyInfo().color(item0)}" *ngIf="!item0.editText">{{item0.label}}</h5>
                        <input type="text" class="form-control" [(ngModel)]="item0.label" *ngIf="item0.editText"
                          (keydown.enter)="item0.labelIsModified(); isDirty=true" />
                         
                      </div>
                      <div class="heading-elements">
                        <div class="d-felx flex-row btn-group">
                          <button type="button" class="btn btn-icon action-icon p-0 border-0"
                            (click)="removeCategory(item0)" *ngIf="isEditMode">
                            <i class="las la-trash fs-6 margin-ft"></i>
                          </button>
                          <button type="button" class="btn btn-icon action-icon p-0 border-0"
                            (click)="item0.editText=true" *ngIf="isEditMode">
                            <i class="las la-edit fs-6 margin-ft"></i>
                          </button>
                          <button type="button" class="btn btn-icon action-icon p-0 border-0" (click)="moveup(item0)"
                            *ngIf="isEditMode  && getTaxonomyInfo().hasPrevSibling(item0)">
                            <i class="las la-arrow-up fs-6 margin-ft"></i>
                          </button>
                          <button type="button" class="btn btn-icon action-icon p-0 border-0" (click)="movedown(item0)"
                            *ngIf="isEditMode && getTaxonomyInfo().hasNextSibling(item0)">
                            <i class="las la-arrow-down fs-6"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body py-0 px-2">
                      <ng-container *ngFor="let item1 of getTaxonomyInfo().childrenSortedByOrdinal(item0.key); let i1 = index;">
                        <div class="card my-3 border-top-0 border-bottom-0 border-end-0" style="border-left:1px solid" [ngStyle]="{'border-color': getTaxonomyInfo().color(item1)}">
                          <div class="card-head py-0 d-flex justify-content-between">
                            <div class="card-title m-0 ms-3">
                              <h5 class="fw-normal" [ngStyle]="{'color': getTaxonomyInfo().color(item1)}" *ngIf="!item1.editText">{{item1.label}}</h5>
                              <input type="text" class="form-control" [(ngModel)]="item1.label" *ngIf="item1.editText"
                                (keydown.enter)="item1.labelIsModified(); isDirty=true" />
                            </div>
                            <div class="heading-elements">
                              <div class="d-felx flex-row btn-group">
                                <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                  (click)="removeCategory(item1)" *ngIf="isEditMode">
                                  <i class="las la-trash fs-6 margin-ft"></i>
                                </button>
                                <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                  (click)="item1.editText=true" *ngIf="isEditMode">
                                  <i class="las la-edit fs-6 margin-ft"></i>
                                </button>
                                <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                  (click)="moveup(item1)" *ngIf="isEditMode && getTaxonomyInfo().hasPrevSibling(item1)">
                                  <i class="las la-arrow-up fs-6 margin-ft"></i>
                                </button>
                                <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                  (click)="movedown(item1)" *ngIf="isEditMode && getTaxonomyInfo().hasNextSibling(item1)">
                                  <i class="las la-arrow-down fs-6"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="card-body py-0">
                            <ng-container *ngFor="let item2 of getTaxonomyInfo().childrenSortedByOrdinal(item1.key); let i2 = index;">
                              <div class="card my-3" style="border:1px solid"
                                [ngStyle]="{'border-color': getTaxonomyInfo().color(item2)}">
                                <div class="card-head d-flex justify-content-between p-2"
                                  [ngClass]="{'bg-light': this.getSelectedEntity() && this.selectedEntityKey == item2.key}">
                                  <a role="button" class="d-flex" (click)="selectItem(item2.key)" 
                                  data-bs-toggle="tooltip" data-placement="bottom" title="Open"
                                  show-delay="300">
                                    <span class="bullet bullet-sm my-auto me-1" [ngClass]="{'bullet-success': item2.deployed && !item2.modified, 'bullet-yellow': !item2.deployed, 'bullet-orange': item2.modified && item2.deployed }" *ngIf="isEditMode"></span>
                                    <h5 class="card-title m-0" [ngStyle]="{'color': getTaxonomyInfo().color(item2)}"
                                      *ngIf="!item2.editText">{{item2.label}}</h5>
                                    <input type="text" class="form-control" [(ngModel)]="item2.label"
                                      *ngIf="item2.editText" (keydown.enter)="item2.labelIsModified(); isDirty=true" />
                                  </a>

                                  <div class="heading-elements">
                                    <div class="d-flex flex-row btn-group">
                                      <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                        (click)="removeCategory(item2)" *ngIf="isEditMode">
                                        <i class="las la-trash  fs-6 margin-ft"></i>
                                      </button>
                                      <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                        (click)="item2.editText=true" *ngIf="isEditMode">
                                        <i class="las la-edit fs-6 margin-ft"></i>
                                      </button>
                                      <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                        (click)="moveup(item2)" *ngIf="isEditMode && getTaxonomyInfo().hasPrevSibling(item2)">
                                        <i class="las la-arrow-up fs-6 margin-ft"></i>
                                      </button>
                                      <button type="button" class="btn btn-icon action-icon p-0 border-0"
                                        (click)="movedown(item2)" *ngIf="isEditMode && getTaxonomyInfo().hasNextSibling(item2)">
                                        <i class="las la-arrow-down fs-6"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <!-- Adds bg-white to make the background white TEMPORARY -->
                          <div class="card-footer border-0 py-1 bg-white" *ngIf="isEditMode">
                            <button id="popup2" class="btn btn-light dropdown-toggle w-100" data-bs-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <i class="las la-plus"></i> {{item1.label}} -- pin #2
                            </button>
                            <div class="dropdown-menu arrow">
                              <ng-container *ngFor="let item2 of remainingChildren(item1.key); let i = index;">
                                <button class="dropdown-item" [ngStyle]="{'color': getTaxonomyInfo().color(item2)}" type="button"
                                  (click)="addCategory(item2)">
                                  <i class="me-3 {{getTaxonomyInfo().icon(item2)}}"></i>{{item2.label}}
                                </button>
                              </ng-container>
                              <input type="text" class="dropdown-item" placeholder="{{locale('locale_key.pages.data_categories.button.add_custom_category')}}"
                                [(ngModel)]="customCategoryLabel" (keydown.enter)="addCustomCategory('popup2', item1)">
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="card-footer border-0 py-1 bg-white" *ngIf="isEditMode">
                      <button id="popup1" class="btn btn-light dropdown-toggle w-100" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="las la-plus"></i> {{item0.label}} -- pin #1
                      </button>
                      <div class="dropdown-menu arrow taxonomy-dropdown">
                        <ng-container *ngFor="let item1 of remainingChildren(item0.key); let i = index;">
                          <button class="dropdown-item" [ngStyle]="{'color': getTaxonomyInfo().color(item1)}" type="button"
                            (click)="addCategory(item1)">
                            <i class="me-3 {{getTaxonomyInfo().icon(item1)}}"></i>{{item1.label}}
                          </button>
                        </ng-container>
                        <input type="text" class="dropdown-item" placeholder="{{locale('locale_key.pages.data_categories.button.add_custom_category')}}"
                          [(ngModel)]="customCategoryLabel" (keydown.enter)="addCustomCategory('popup1', item0)">
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="card-footer border-0 py-3" >
                <ng-container *ngIf="isEditMode && remainingChildren().length > 0" >
                  <button id='popup0' class="btn btn-light btn-block" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="las la-plus"></i> Main Class
                  </button>
                  <div class="dropdown-menu arrow scrollable ps-4 taxonomy-dropdown">
                    <ng-container *ngFor="let item0 of remainingChildren(); let i = index;">
                      <button class="dropdown-item" [ngStyle]="{'color': getTaxonomyInfo().color(item0)}" type="button"
                        (click)="addCategory(item0)">
                        <i class="me-3 {{getTaxonomyInfo().icon(item0)}}"></i>{{item0.label}}
                      </button>
                    </ng-container>
                    <input type="text" class="dropdown-item" placeholder="{{locale('locale_key.pages.data_categories.button.add_custom_category')}}"
                      [(ngModel)]="customCategoryLabel" (keydown.enter)="addCustomCategory('popup0', null)">
                  </div>
                </ng-container>              
              </div>
            </div>
          </div>
          <div class="col-9 p-0 px-2" style="overflow-y: auto !important;">
            <div class="card" *ngIf="selectedEntityKey" style="overflow-y: scroll !important;height: 91.3vh !important;">
              <div class="card-header py-3 border-1">
                <div class="card-title d-flex">
                  <h4>{{getSelectedEntity().getLabel()}}</h4>
                </div>
              </div>
              <div class="card-body" *ngIf="unselectedAttrs().length > 0 && isEditMode" >
                <h5 class="card-title">{{locale('locale_key.master_table.data_points.suggested_data_points')}}</h5>
                <h6 class="card-subtitle mt-3 mb-2 text-muted">{{locale('locale_key.master_table.data_points.suggested_data_points.selection_description')}}
                </h6>
                <ng-container *ngFor="let attr of unselectedAttrs()">
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-sm btn-light p-0 me-3" (click)="selectAttr(attr)">
                        <i class="la la-plus"></i>
                      </button>
                      <label>{{attr.description}}</label>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="card-body">
                <h5 class="card-title mb-4">{{locale('locale_key.master_table.data_points.selected_data_points')}}</h5>
                <div class="row" *ngIf="getSelectedEntity().columns.length > 0">
                  <div class="col-md-6">
                    <label>{{locale('locale_key.master_table.data_point.desc')}}</label>
                  </div>
                  <div class="col-md-3">
                    <label>{{locale('locale_key.master_table.data_point.short_name')}}</label>
                  </div>
                  <div class="col-md-2">
                    <label>{{locale('locale_key.master_table.data_point.type')}}</label>
                  </div>
                  <div class="col-md-1">
                  </div>
                </div>
                <ng-container *ngFor="let attr of getSelectedEntity().columns">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <span class="bullet bullet-sm" 
                      style="position: absolute;left: 20px; margin-top: 0.8rem;"
                      [ngClass]="{'bullet-success': attr.deployed && !attr.modified, 'bullet-yellow': !attr.deployed, 'bullet-orange':  attr.deployed && attr.modified}" 
                      *ngIf="isEditMode"></span>
                      <input type="text" class="form-control d-inline ps-4" [(ngModel)]="attr.description" (ngModelChange)="changedDesc(attr)"
                        [readOnly] = '!isEditMode'
                        [formControl]="attr.descriptionControl"
                        [ngClass]="{ 'is-invalid':  attr.descriptionControl.errors && (attr.descriptionControl.dirty || attr.descriptionControl.touched) }"
                        required />
                        <div  class="w-100 d-block" *ngIf="attr.descriptionControl.errors && (attr.descriptionControl.dirty || attr.descriptionControl.touched)" class="invalid-feedback">
                          <div class="w-100 d-block" *ngIf="attr.descriptionControl.errors.required">
                            {{dataTypeMessage}}
                          </div>
                          
                        </div>
                    </div>
                    <div class="col-md-3 d-flex flex-column">
                      <input type="text" class="form-control" [(ngModel)]="attr.label" (ngModelChange)="changedLabel(attr)"
                      [readOnly] = '!isEditMode || attr.deployed'
                      [formControl]="attr.labelControl"
                      [ngClass]="{ 'is-invalid':  attr.labelControl.errors && (attr.labelControl.dirty || attr.labelControl.touched) }"
                      required />
                      <div *ngIf="attr.labelControl.errors && (attr.labelControl.dirty || attr.labelControl.touched)" class="invalid-feedback">
                        <div *ngIf="attr.labelControl.errors.required">
                          {{dataShortNameMessage}}
                        </div>
                        
                      </div>
                    </div>
                    <div class="col-md-2">
                      <select class="form-select form-control" [(ngModel)]="attr.datatype" (ngModelChange)="changedDataType(attr)" 
                      [disabled] = '!isEditMode || attr.deployed'
                      #datatype="ngModel"
                      [ngClass]="{ 'is-invalid':  datatype.errors && (datatype.dirty || datatype.touched) }"
                      required
                      >
                      <option value="STRING">{{locale('locale_key.general.data_type.text')}}</option>
                      <option value="NUMERIC">{{locale('locale_key.general.data_type.number')}}</option>
                      <option value="DATE">{{locale('locale_key.general.data_type.date')}}</option>
                      <option value="BOOLEAN">{{locale('locale_key.general.data_type.boolean')}}</option>                       
                      </select>
                      <div *ngIf="datatype.errors && (datatype.dirty || datatype.touched)" class="invalid-feedback">
                        <div *ngIf="datatype.errors.required">
                          {{dataTypeMessage}}
                        </div>                     
                      </div>
                    </div>
                    <div class="col-md-1">
                      <button class="btn btn-sm btn-light p-0 me-3" (click)="deselectAttr(attr)" *ngIf="isEditMode">
                        <i class="la la-minus"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-md-2">
                  </div>
                  <div class="col-md-6" *ngIf="isEditMode">
                    <button class="btn btn-light w-100" (click)="addEmptyAttr()">
                      <i class="las la-plus"></i> {{locale('locale_key.master_table.data_points.button.add_custom_data_point')}}
                    </button>
                  </div>
                  <div class="col-lg-4 col-md-4" *ngIf="isEditMode">
                    <button type="button" class="btn btn-add btn-min-width me-3 mb-3" (click)="saveNewTaxonomyChanges()"
                      [disabled]="checkIsInvalid() || !isDirty" *ngIf="!isInProgress">
                       {{locale('locale_key.general.buttons.save_changes')}}
                    </button>
                    <div class="ball-pulse loader-primary" *ngIf="isInProgress">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="col-md-2">
                  </div>
                </div>
              </div>
              <div class="card-body">
                <h5 class="card-title mb-2"> {{locale('locale_key.master_table.data_points.calculated_data_points')}} </h5>
                <ng-container *ngFor="let attr of getSelectedEntity().columns">
                  <div class="row mb-1" *ngIf="attr.calculation">
                    <div class="col-md-2 pe-0">
                      <label>{{attr.label}} = </label>
                    </div>
                    <div class="col-md-9 d-flex ps-0">
                      <ng-container *ngFor="let item of attr.calculation.flatten(); let j = index">
                        <div *ngIf="isPlaceholder(item)">
                          <button id="dropdown{{j}}" class="btn btn-light dropdown-toggle me-3" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="las la-plus"></i>
                          </button>
                          <div id="popup3" class="dropdown-menu" attr.aria-labelledby="dropdown{{j}}">
                            <button class="dropdown-item" type="button"
                              (click)="addTwoOprandStatement(attr, item, 'add')">( + )</button>
                            <button class="dropdown-item" type="button"
                              (click)="addTwoOprandStatement(attr, item, 'sub')">( - )</button>
                            <button class="dropdown-item" type="button"
                              (click)="addTwoOprandStatement(attr, item, 'mul')">( * )</button>
                            <button class="dropdown-item" type="button"
                              (click)="addTwoOprandStatement(attr, item, 'div')">( / )</button>
                            <div class="dropdown-divider" *ngIf="numericalAttrs(attr).length > 0"></div>
                            <ng-container *ngFor="let variable of numericalAttrs(attr)">
                              <button class="dropdown-item" type="button"
                                (click)="addVariable(attr, item, variable)">{{variable.label}}</button>
                            </ng-container>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item" type="button" (click)="selectEmissionFactor(attr, item); openModal(emissionPortal, 'modal-xl')">{{locale('locale_key.master_table.data_points.calculated_data_points.emission_factors')}}</button>
                            <input type="text" class="dropdown-item" placeholder="{{locale('locale_key.pages.data_categories.add_custom_value')}}"
                              [(ngModel)]="equationCustomValue" (keydown.enter)="addValue(attr, item)">
                          </div>
                        </div>
                        <a class="me-3" *ngIf="!isPlaceholder(item) && tooltip(item)"  data-bs-toggle="tooltip" data-placement="top" title="{{tooltip(item)}}" >{{item}}</a>
                        <a class="me-3" *ngIf="!isPlaceholder(item) && !tooltip(item)">{{item}}</a>
                      </ng-container>
<!--                       <div class="modal" style="display: block;" tabindex="-1" role="dialog" aria-modal="true" style="display: block;" *ngIf="openEmissionPortal">
                        <div class="modal-dialog modal-dialog-centered modal-xl mt-5" style="overflow-y: initial; margin-left: 6%;">
                          <div class="modal-content">
                            <div class="modal-body" style="overflow-y: auto; height: 80vh;">
                              <emission-portal (selectedRecordEvent)="setEmissionFactor($event)"></emission-portal>
                            </div>
                          </div>
                        </div>  
                      </div>  -->                         

                    </div>
                    <div class="col-md-1">
                      <button class="btn btn-sm btn-light p-0 me-3" (click)="resetCalculation(attr)">
                        <i class="la la-minus"></i>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-md-2">
                  </div>
                  <div class="col-md-6"  *ngIf="isEditMode">
                    <button class="btn btn-light btn-block dropdown-toggle w-100" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <i class="las la-plus"></i> {{locale('locale_key.master_table.data_points.button.calculations')}} -- pin #3
                    </button>
                    <div class="dropdown-menu arrow">
                      <ng-container *ngFor="let attr of numericalAttrs(); let i = index;">
                        <button class="dropdown-item" type="button" (click)="addCalculation(attr)">
                          <i class="me-3"></i>{{attr.label}}
                        </button>
                      </ng-container>
                    </div>
                  </div>
                  <!-- make the below button responsiveness -->
                  <div class="col-lg-4 col-md-4"  *ngIf="isEditMode">
                    <button type="button" class="btn btn-add btn-min-width me-3 mb-1" (click)="saveNewTaxonomyChanges()"
                      [disabled]="checkIsInvalid() || !isDirty" *ngIf="!isInProgress">
                      {{locale('locale_key.general.buttons.save_changes')}}
                    </button>
                    <div class="ball-pulse loader-primary" *ngIf="isInProgress">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="col-md-2">
                  </div>
                </div>
              </div>
            </div>
          </div>        
        </div>       
      </div>
    </div>
  </div>
</div>
<ng-template #emissionPortal>
  <emission-portal (emissionSelectedEvent)="setEmissionFactor($event)" (cancelEvent)="closeModal($event)"></emission-portal>
</ng-template> 