import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";
import { DataGridColumnSchema } from "./DataGridColumnSchema";
import { DataGridColumnType } from "./DataGridColumnType";
import { InputColumnType } from "./InputColumnType";

export class ColumnType {
  constructor (
    public original: string, 
    public generic: string, 
    public showUnitCol: boolean, 
    public isCalculated: boolean, 
    public emissionFactor: AbstractEmissionFactorFe | null,
    public unitSymbol?: string, 
    public measurementKey?: string,
    public isPreDetermined?: boolean,
    public predefinedOptions?: any[]
  ) { 
  }
  public static fromDataGridColumnType (element: DataGridColumnSchema): ColumnType{
    switch (element.type){
      case 'BOOLEAN':
      case 'BOOL':   
        return new ColumnType(DataGridColumnType.BOOLEAN, InputColumnType.checkbox, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
      case DataGridColumnType.MEASUREMENT: 
        return new ColumnType(DataGridColumnType.MEASUREMENT, InputColumnType.number, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
      case 'INTEGER':
      case 'NUMERIC':
      case 'FLOAT':
      case 'FLOAT64': 
        return new ColumnType(DataGridColumnType.NUMERIC, InputColumnType.number, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
      case 'DATE':
      case 'TIMESTAMP':
      case 'RECORD':
        return new ColumnType(DataGridColumnType.DATE, InputColumnType.date, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
      case 'STRING':
        return new ColumnType(DataGridColumnType.STRING, InputColumnType.text, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
      case DataGridColumnType.EMISSION_FACTOR:
        return new ColumnType(DataGridColumnType.EMISSION_FACTOR, DataGridColumnType.EMISSION_FACTOR, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
      default: 
        return new ColumnType(element.type, InputColumnType.text, element.showUnitCol, element.isCalculated, element.emissionFactor, element.unitSymbol, element.measurementKey, element.isPreDetermined, element.predefinedOptions);
    }
  }

  getDataType () {
    switch (this.original) {
      case DataGridColumnType.NUMERIC: return 'Number';
      case DataGridColumnType.STRING: return 'Text';
      case DataGridColumnType.DATE: return 'Time';
      case DataGridColumnType.BOOLEAN: return 'Yes/No';
      default: return 'Text';
    }
  }
}