export class CompanyCustomEmailTemplateFe {
  html: string
  langCode: string
  fileName: string

  constructor(html: string, langCode: string, fileName: string) {
    this.html = html
    this.langCode = langCode
    this.fileName = fileName
  }

  public static fromTransfer(transfer: any): CompanyCustomEmailTemplateFe {
    return new CompanyCustomEmailTemplateFe(transfer.html, transfer.langCode, transfer.fileName)
  }
}
