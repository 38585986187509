import { Component, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { Subject, Subscription } from "rxjs"
import { DirectEntryInfoFe } from "src/app/model/directEntry/DirectEntryInfoFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { StageTableInfoSubjectFe } from "src/app/model/subject/StageTableInfoSubjectFe"
import { FileDataFe } from "src/app/model/file/FileDataFe"
import { DomSanitizer } from "@angular/platform-browser"
import { TableStorageServiceFe } from "src/app/services/TableStorageServiceFe"
import { RouterFe } from "src/app/route/RouterFe"
import { DatahubService } from "./DatahubService"
import { FileSourceFe } from "src/app/model/file/FileSourceFe"
import { DateUtil } from "src/app/utils/DateUtil"
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe"
import { ContactFe } from "src/app/model/user/ContactFe"
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe"

@Component({
  selector: "files-portal",
  templateUrl: "./filesPortal.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class FilesPortalComponent extends AbstractLanguageComponent implements OnInit {
  
  @Output() showFile = new Subject<FileDataFe>()
  menuCollapsed: boolean

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInProgress: boolean = false
  downloadingFiles = false
  groupedBy = null
  groupedByOptions = [
    {name: this.locale('locale_key.pages.datahub.group.option.company'), value: 'company'},
    {name: this.locale('locale_key.pages.datahub.group.option.request'), value: 'request'},
    {name: this.locale('locale_key.pages.datahub.group.option.source_of_file'), value: 'source'},
    {name: this.locale('locale_key.pages.datahub.group.option.upload_year'), value: 'uploadYear'},
  ]
  sortedBy = {name: this.locale('locale_key.pages.datahub.sort.option.date_desc'), value: 'dateDes'}
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.date'), value: 'date'},
    {name: this.locale('locale_key.pages.datahub.sort.option.date_desc'), value: 'dateDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.file_name'), value: 'file', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.file_name_desc'), value: 'fileDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.person_name'), value: 'person', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.person_name_desc'), value: 'personDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.company_name'), value: 'company', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.company_name_desc'), value: 'companyDes'}
  ]

  selectedSource = null;
  customMultipleFilters: {name: string, value: SupplierCompanyFe | ContactFe | RequestGroupFe | Date, value2?: Date}[] = []
  datahubFilteredFiles: FileDataFe[] = []
  allRequestGroups: RequestGroupFe[] = []
  allFiles: FileDataFe[] = []
  filteredGroupedFiles: {name:string, files:FileDataFe[]}[] = []
  filteredFiles: FileDataFe[] = []
  filteredCompanies: SupplierCompanyFe[] = []
  filteredDataReporters: ContactFe[] = []
  filteredRgs: RequestGroupFe[] = []
  selectedFile: FileDataFe = null
  subscriptions = new Subscription()
  searchKeyword: string = '';

  date1: Date = new Date()
  date2: Date = new Date()
  dateFilter: string = null
  showDownloadButton = true;
  openDownloadButtonGroupIndex = -1;
  inProgress = false;

  constructor(languageService: LanguageService, private responsive: ResponsiveService, private tableStorageService: TableStorageServiceFe, 
     private domSanitizer: DomSanitizer,  public datahubService: DatahubService, public stateService: StateServiceFe, 
     private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService, private backendService: RouterFe) {
    super(languageService)
    
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize;

    let sub = stateService.explorerFilesUpdatedSubject.subscribe(async (subject: FileDataFe) => {
      await this.loadCompanyData()
    })
    this.subscriptions.add(sub)
  }

  async ngOnInit() {
    await this.loadCompanyData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadCompanyData() {
    this.loadingInProgress = true
    this.datahubFilteredFiles = await this.datahubService.getFilteredFiles()
    this.allRequestGroups = await this.stateService.getRequestGroups();
    this.applyFilters ();
    this.filterCompanies();
    this.filterDataReporters();
    this.filterRequest();
    this.loadingInProgress = false
  }

  search($event: any) {
    throw new Error("Method not implemented.")
  }

  openFile(file: FileDataFe) {
    this.datahubService.showFile(file);
  }
  
  updateDetailsData() {
    this.detailsData = {
    };
    this.displayService.updateDetailsData(this.detailsData);
  }

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string = 'modal-md') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: clazz,
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }
  
  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open');
  }

  async downloadFile(file: FileDataFe, event?: MouseEvent) {
    event?.stopImmediatePropagation();
    this.selectedFile = file;
    if (!file.hasContent()) {
      await file.loadFile(this.stateService, this.domSanitizer, this.tableStorageService, this.backendService, true);
    }
  }

  groupFiles(option?) {
    if (option) {
      this.groupedBy = option
    }
    this.filteredGroupedFiles = [];
    if (this.groupedBy) {

      if (this.groupedBy.value == 'company') {
        let companies = new Map<string, string>();
        this.filteredFiles.forEach((file) => {
          if (file.uploaderCompany && file.uploaderCompanyId) {
            companies.set(file.uploaderCompanyId, file.uploaderCompany);
          }
        })
  
        companies.forEach((name, id) => {
          let files = this.filteredFiles.filter(file => file.uploaderCompanyId == id)
          this.filteredGroupedFiles.push({name, files})
        })
  
      } else if (this.groupedBy.value == 'request') {
  
        let requestGroups = new Set<string>();
        this.filteredFiles.forEach((file) => {
          if (file.requestGroupId) {
            requestGroups.add(file.requestGroupId);
          }
        })
  
        requestGroups.forEach(id => {
          let files = this.filteredFiles.filter(file => file.requestGroupId == id)
          let name = this.allRequestGroups.find(requestGroup => requestGroup.id == id).title
          this.filteredGroupedFiles.push({name, files})
        })
  
      } else if (this.groupedBy.value == 'source') {
  
        this.filteredGroupedFiles.push({name: 'Uploaded files', files: []})
        this.filteredGroupedFiles.push({name: 'Files from requests', files: []})
        this.filteredFiles.forEach(file => {
          if (file.source == FileSourceFe.EXPLORER) {
            this.filteredGroupedFiles[0].files.push(file);
          } else {
            this.filteredGroupedFiles[1].files.push(file);
          }
        })

        this.filteredGroupedFiles = this.filteredGroupedFiles.filter(group => group.files.length > 0);
      } else if (this.groupedBy.value == 'uploadYear') {
  
        let years = new Set<number>();
        this.filteredFiles.forEach((file) => years.add(file.timeCreated.getFullYear()))
  
        years.forEach(year => {
          let files = this.filteredFiles.filter(file => file.timeCreated.getFullYear() == year)
          this.filteredGroupedFiles.push({name: `${year}`, files})
        })
      }
    }
    
    if (this.sortedBy) {
      this.sort();
    }
  }

  async sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let requestGroups = await this.stateService.getRequestGroups();
    let sortFun;
    if (this.sortedBy.value == 'date') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        return DateUtil.getTimeDifference(a.timeCreated , b.timeCreated)
      }
    } else if (this.sortedBy.value == 'dateDes') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        return DateUtil.getTimeDifference(b.timeCreated , a.timeCreated)
      }
    } else if (this.sortedBy.value == 'file') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1 
        } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'fileDes') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return 1 
        } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'person') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        if (a.getUploaderName()?.toLowerCase() < b.getUploaderName()?.toLowerCase()) {
          return -1 
        } else if (a.getUploaderName()?.toLowerCase() > b.getUploaderName()?.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'personDes') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        if (a.getUploaderName()?.toLowerCase() < b.getUploaderName()?.toLowerCase()) {
          return 1 
        } else if (a.getUploaderName()?.toLowerCase() > b.getUploaderName()?.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'company') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        if (a.uploaderCompany?.toLowerCase() < b.uploaderCompany?.toLowerCase()) {
          return -1 
        } else if (a.uploaderCompany?.toLowerCase() > b.uploaderCompany?.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'companyDes') {
      sortFun = (a: FileDataFe, b: FileDataFe) => {
        if (a.uploaderCompany?.toLowerCase() < b.uploaderCompany?.toLowerCase()) {
          return 1 
        } else if (a.uploaderCompany?.toLowerCase() > b.uploaderCompany?.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      if (this.groupedBy) {
        this.filteredGroupedFiles.forEach(group => group.files = group.files.sort(sortFun))
      } else {
        this.filteredFiles = this.filteredFiles.sort(sortFun);
      }
    }
  }

  applyShowOnlyFilter() {
    if (this.selectedSource) {
      this.allFiles = this.datahubFilteredFiles.filter(f => f.source == this.selectedSource)
    } else {
      this.allFiles = this.datahubFilteredFiles;
    }
  }

  applyFilters() {
    this.applyShowOnlyFilter();
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedSource = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | ContactFe | RequestGroupFe | Date) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof SupplierCompanyFe) {
        this.customMultipleFilters.push({name: value.supplierCompanyname, value})
      } else if (value instanceof ContactFe) {
        this.customMultipleFilters.push({name: value.getName(), value})
      } else if (value instanceof RequestGroupFe) {
        this.customMultipleFilters.push({name: value.title, value})
      } else if (value instanceof Date) {
        this.customMultipleFilters.push({ name: DateUtil.toString3(value), value})
      } 
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.allFiles = this.allFiles.filter(file => {
        let isPresent = false;
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof SupplierCompanyFe) {
            isPresent = file.uploaderCompanyId == value['supplierCompanyId'];
          } else if (value instanceof ContactFe) {
            isPresent = file.uplaoderAffId == value['affiliationId'];
          } else if (value instanceof RequestGroupFe) {
            isPresent = file.requestGroupId == value['id'];
          } else if (value instanceof Date) {
            if (this.checkCustomDateFilter(file, value, f.value2)) {
              return true;
            }
          }
          if (isPresent) {
            return true;
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  checkCustomDateFilter(info: FileDataFe, date1: Date, date2: Date): boolean{
    if (this.dateFilter == 'dateIs'){
      return DateUtil.getTimeDifference(date1, info.timeCreated) > -1 && DateUtil.getTimeDifference(date1, info.timeCreated) < 1
    } else if (this.dateFilter == 'dateIsBetween' && date2){
      return DateUtil.getTimeDifference(date1, info.timeCreated) < 0 && DateUtil.getTimeDifference(date2, info.timeCreated) > 0
    } else if (this.dateFilter == 'dateIsBefore'){
      return DateUtil.getTimeDifference(date1, info.timeCreated) > 0
    } else if (this.dateFilter == 'dateIsAfter'){
      return DateUtil.getTimeDifference(date1, info.timeCreated) < 0
    }
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == "") {
      this.searchKeyword = keyword;
    }
    this.filteredFiles = this.allFiles.filter(info => {
      let word = info.label + (info.getUploaderName() ? info.getUploaderName() : '') + (info.uploaderCompany ? info.uploaderCompany : '');
      return word.match(new RegExp(this.searchKeyword, "i"))
    })
    
    this.groupFiles();    
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Set();
    this.datahubFilteredFiles.forEach(file => relatedCompanyIds.add(file.uploaderCompanyId));
    let companies = await this.stateService.getPartners()
    let relatedCompanies = companies.filter(company => relatedCompanyIds.has(company.supplierCompanyId));
    this.filteredCompanies = relatedCompanies.filter(f => f.supplierCompanyname.match(new RegExp(keyword, "i")) )
  }

  async filterDataReporters(keyword: string = '') {
    let relatedContactAffIds = new Set();
    this.datahubFilteredFiles.forEach(file => relatedContactAffIds.add(file.uplaoderAffId));
    let contacts = await this.stateService.getContacts()
    let relatedContacts = contacts.filter(contact => relatedContactAffIds.has(contact.affiliationId));
    this.filteredDataReporters = relatedContacts.filter(f => f.getName().match(new RegExp(keyword, "i")) )
  }

  async filterRequest(keyword: string = '') {
    let relatedRgIds = new Set();
    this.datahubFilteredFiles.forEach(file => relatedRgIds.add(file.requestGroupId));
    let groups = await this.stateService.getRequestGroups()
    let relatedGroups = groups.filter(rg => relatedRgIds.has(rg.id));
    this.filteredRgs = relatedGroups.filter(f => f.title.includes(keyword))
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != company)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != contact)
      })
    } else if (className == 'requestCheckbox') {
      this.filteredRgs.forEach(rg => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != rg)
      })
    }
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        if (!this.customMultipleFilters.find(f => f.value == company)) {
          this.customMultipleFilters.push({name: company.supplierCompanyname, value: company})
        } 
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        if (!this.customMultipleFilters.find(f => f.value == contact)) {
          this.customMultipleFilters.push({name: contact.getName(), value: contact})
        } 
      })
    } else if (className == 'requestCheckbox') {
      this.filteredRgs.forEach(rg => { 
        if (!this.customMultipleFilters.find(f => f.value == rg)) {
          this.customMultipleFilters.push({name: rg.title, value: rg})
        } 
      })
    }
    this.applyFilters();
  }
  
  handleDateCustomFilters() {
    this.customMultipleFilters = this.customMultipleFilters.filter(f => !(f.value instanceof Date))
    if (this.date1){
      let name, date1 = new Date(this.date1), date2;
      if (this.date2) {
        date2 = new Date(this.date2)
      }
      if (this.dateFilter == 'dateIs'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.date_is', { date: DateUtil.toString3(date1)})
      } else if (this.dateFilter == 'dateIsBetween' && this.date2){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.date_is_in_between', { date1: DateUtil.toString3(date1), date2: DateUtil.toString3(date2)})
      } else if (this.dateFilter == 'dateIsBefore'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.date_is_before', { date: DateUtil.toString3(date1)})
      } else if (this.dateFilter == 'dateIsAfter'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.date_is_after', { date: DateUtil.toString3(date1)})
      }
      this.customMultipleFilters.push({name, value: date1, value2: date2})
    }
    this.applyFilters();
  }

  clearDateFilter() {
    this.dateFilter = null;
    this.customMultipleFilters = this.customMultipleFilters.filter(f => !(f.value instanceof Date))
    this.date1 = null;
    this.date2 = null;
    this.applyFilters();
  }

  handleAllFiles(event: Event, className: string, files: FileDataFe[]) {
    let allCheckboxes = document.getElementsByClassName(className);
    if ((event.target as HTMLInputElement).checked) {
      (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
      this.selectedFiles =  files;
    } else {
      (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
      this.selectedFiles =  [];
    }
  }

  selectedFiles: FileDataFe[] = [];

  fileSelectedForDownload(event: Event, file: FileDataFe) {
    if ((event.target as HTMLInputElement).checked) {
      this.selectedFiles.push(file);
    } else {
      this.selectedFiles =  this.selectedFiles.filter(f => f!=file);
    }
  }

  cancelDownload(event?) {
    event?.stopImmediatePropagation();
    this.openDownloadButtonGroupIndex= -1;
    this.showDownloadButton = true;
    this.selectedFiles = [];
  }

  async downloadSelectedFiles(event: MouseEvent) {
    this.stopProgation(event)
    this.downloadingFiles = true;
    let promises = [];
    this.selectedFiles.forEach((file) => promises.push(this.downloadFile(file)));
    await Promise.all(promises);
    this.downloadingFiles = false;
    this.cancelDownload()
  }

  stopProgation(event) {
    event.stopImmediatePropagation();
  }

  deletePopup(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray ' }));
  }

  async deleteFileFromStorage() {
    this.inProgress = true;
    let fileName = this.selectedFile.label
    await this.stateService.deleteExplorerFile(fileName)
    this.datahubFilteredFiles = this.datahubFilteredFiles.filter(f => f.label != fileName)
    this.applyFilters();
    this.closeModal();
    this.inProgress = false;
  }

}
