import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { QuestionTextFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionTextFe";
import { QuestionnaireFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe";
import { SectionFe } from "src/app/model/data-suppliers/request/questionnaire/SectionFe";
import { View } from "src/app/model/datahub/View";
import { CCProjectInfoFe } from "src/app/model/project/CCProjectInfoFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Component({
  selector: "project-request",
  templateUrl: "./project-request.component.html",
  styleUrls: ["./project-request.component.scss", "../overview/carbonlab-overview.component.scss"]
})
export class ProjectRequestComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild("saveDraft") saveDraft: TemplateRef<any>;
  @Output() close = new EventEmitter<boolean>()

  filter(arg0: any) {
    throw new Error("Method not implemented.");
  }
  collapseAll() {
    throw new Error("Method not implemented.");
  }
  expandAll() {
    throw new Error("Method not implemented.");
  }

  loadingInProgress: boolean = false;

  url: string = "";
  initCacheInProgress: boolean;
  menuCollapsed: boolean;
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE;
  loadingData: boolean = false;

  activeFormNo = 1;
  progress: HTMLElement | undefined;
  circles: any;
  prevButton: HTMLButtonElement | undefined;
  nextButton: HTMLButtonElement | undefined;
  currentActive: number = 1;
  inProgress = true;
  projectName = "";
  saveBeforeLeave = false;
  bizTravel: boolean = false;
  emissionsEmpty: boolean = true;
  allProjects: boolean = false;
  activeTab: string = !this.allProjects ? "tab31" : "tab35";
  pullDataFromPlatform: boolean = false;
  emissionFormSustainLab: boolean = false;
  carbonEmissions2023: boolean = true;
  licencedMaterial: boolean = true;
  cardHeader: boolean = false;
  cardFooter: boolean = false;
  selectedOption: string = "";
  tabNames = {
    tab31: "All data entries",
    tab32: "Per emission source",
    tab33: "Per organizational unit",
    tab34: "Request overview"
  };

  @Input() project: CCProjectInfoFe;
  calculationMethod: string;
  askProofDocuments = false;
  proofDocQues = new FormControl(
    this.slocale(
      "Proof documentation may include supporting evidence such as invoices, utility bills, or operational records. This documentation validates and verifies the reported data, ensuring accuracy and reliability in the carbon calculation process."
    ),
    [Validators.required]
  );
  askEF = false;
  reqDetailsForm = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    desc: new FormControl(null)
  });

  questionnaire = new QuestionnaireFe(
    [new SectionFe("s1", "Section 1", null, null, [new QuestionTextFe("q1", "s1", 1, null, "Write answer", null, true, true, null)])],
    false,
    ""
  );

  stepsRequestData = [
    { title: this.slocale("Select calculation method"), completed: false },
    { title: this.slocale("Request title & description"), completed: false },
    { title: this.slocale("Questionnaire preview"), completed: false },
    { title: this.slocale("Submission details"), completed: false }
  ];

  editQuestionnaire = false;

  steps = [
    { title: this.slocale("Learn about CarbonLab"), completed: false },
    { title: this.slocale("Pick project name"), completed: false },
    { title: this.slocale("Select emissions categories"), completed: false },
    { title: this.slocale("Define data storage"), completed: false }
  ];

  requestTitleMessage = this.locale("locale_key.general.validation_message.request_title_required");

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe
  ) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress;
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress;
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed;
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize;
    });

    this.screenSize = responsive.currentScreenWidthSize;
  }

  ngOnInit(): void {}

  toggleTips() {
    this.displayService.toggleTips();
  }

  openModal(templateRef: TemplateRef<any>, size: string = "") {
    this.modalService.show(templateRef, { class: size });
  }

  update() {
    this.circles = this._document.querySelectorAll(".circle1");

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add("active-circle1");
      } else {
        circle.classList.remove("active-circle1");
      }
    });

    const actives = this._document.querySelectorAll(".active-circle1");
  }

  next() {
    // if (this.activeFormNo == 1) {
    //   this.reqDetailsForm.markAllAsTouched();
    //   if (this.reqDetailsForm.invalid) {
    //     return;
    //   }
    // } else {
    //   this.validateQuestionnaire();
    //   if (this.isQuestionnaireInvalid) {
    //     return;
    //   }
    // }
    this.currentActive++;
    this.activeFormNo++;
    this.update();
  }

  prev(): void {
    this.currentActive--;
    if (this.currentActive < 1) {
      this.currentActive = 1;
    }
    this.activeFormNo--;
    this.update();
  }

  closeModal() {
    this.modalService.hide();
  }

  saveProject() {
    this.modalService.hide();
    this.saveBeforeLeave = false;
  }

  selectTab(tabId: string) {
    this.activeTab = tabId;
  }

  setDateOption(option: string) {
    this.selectedOption = option;
  }

  saveDraftModal(saveDraft: boolean) {
    this.modalService.show(this.saveDraft, { class: "modal-md" });
  }

  autoGrowTextZone(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight < 575 ? e.target.scrollHeight + 25 : 600) + "px";
    e.target.maxHeight = "600px";
  }

  closeCreatingProject() {
    this.close.emit(true);
  }
}
