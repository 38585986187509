<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-start">
        <nav>
          <ul class="nav">
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ locale("locale_key.pages.data_request.create_wizard.title") }}</h3>
              </a>
            </li>
            <ng-container>
              <li class="navbar-item">
                <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
              </li>
              <li class="navbar-item">
                <a>
                  <h3 class="mt-2" *ngIf="reqGroupToBeDuplicated?.draft">{{ locale("locale_key.general.state.continue.draft_request", { request_name: reqDetailsForm.controls.title.value }) }}</h3>
                  <h3 class="mt-2" *ngIf="!reqGroupToBeDuplicated || !reqGroupToBeDuplicated?.draft">{{ locale("locale_key.general.state.creating.new_request", { request_name: reqDetailsForm.controls.title.value }) }}</h3>
                </a>
              </li>
            </ng-container>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ml-4 mt-" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div class="card me-2" style="height: 84vh; max-width: 100vw !important">
            <div class="card-header pb-0">
              <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                <div class="col-lg-12">
                  <div class="d-flex flex-row">
                    <div class="col-lg-12 col-md-12 mt-0 pt-0 m-auto d-flex flex-row justify-content-center">
                      <div class="col-lg-3 col-md-3 mt-0 pt-0 mx-0 px-0">
                        <div class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                          <div class="circle1 active-circle1 mx-0 px-0">1</div>
                          <h6 class="my-auto ms-2">{{ locale("locale_key.pages.data_request.create.title_desc") }}</h6>
                          <span class="ms-2 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-12 text-start mx-0 px-0">
                        <div class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                          <div class="circle1 active mx-0 px-0">2</div>
                          <h6 class="my-auto ms-2">{{ locale("locale_key.pages.data_request.create.questions") }}</h6>
                          <span class="ms-2 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-12 text-start mx-0 px-0">
                        <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center mt-1">
                          <div class="circle1 active mx-0 px-0">3</div>
                          <h6 class="my-auto ms-2 pt-1">{{ locale("locale_key.pages.data_request.create.submission_details") }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                  <div class="d-block text-center">
                    <div class="col stepper text-center" *ngFor="let step of stepsQuest; let i = index" [class.active-stepper]="currentActive === i + 1 || step.completed"></div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-center mb-1">
                  <h6 class="text-center m-0">{{ stepsQuest[currentActive - 1].title }} ({{ currentActive }}/{{ stepsQuest.length }})</h6>
                </div>
              </ng-container>
            </div>
            <hr class="mt-0 pt-0 mx-0 px-0" />
            <div class="card-body mt-0 pt-0" style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important">
              <div *ngIf="activeFormNo == 1">
                <h3>{{ locale("locale_key.pages.data_request.create.title_desc") }}</h3>
                <div class="row ms-4 mt-1" style="max-width: 40%" [formGroup]="reqDetailsForm">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="title">{{ locale("locale_key.pages.data_request.request.title") }}*</label>
                      <input
                        type="text"
                        class="form-control"
                        id="title"
                        placeholder="{{ locale('locale_key.pages.data_request.create.title.placeholder') }}"
                        formControlName="title"
                        [ngClass]="{
                          'is-invalid': reqDetailsForm.get('title').errors && reqDetailsForm.get('title').touched
                        }"
                        data-test="request-title-field"
                      />
                      <div *ngIf="reqDetailsForm.get('title').errors && reqDetailsForm.get('title').touched" class="invalid-feedback">
                        <div *ngIf="reqDetailsForm.get('title').errors.required">
                          {{ requestTitleMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="form-group">
                      <label for="title">{{ locale("locale_key.pages.data_request.create.desc.title") }}</label>
                      <textarea
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="{{ locale('locale_key.pages.data_request.create.desc.placeholder') }}"
                        formControlName="desc"
                        style="min-height: 250px"
                        (keyup)="autoGrowTextZone($event)"
                        data-test="request-description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="activeFormNo == 2">
                <edit-questionnaire [sections]="sections" (deletedSections)="sections = $event"></edit-questionnaire>
              </div>
              <div *ngIf="activeFormNo == 3">
                <submission-details-questionnaire
                  [form3]="form3"
                  [recurringRequest]="recurringRequest"
                  (recurringRequestChanged)="recurringRequest = $event"
                  [allDataOwners]="allDataOwners"
                  [nextDeadline]="nextDeadline"
                  (deadlineChanged)="nextDeadline = $event"
                ></submission-details-questionnaire>
              </div>
            </div>
            <div id="card-footer" class="card-footer float-end mt-auto text-end quest-footer" style="max-width: 100vw">
              <span>
                <div class="connectedActionBtn p-1 d-flex flex-row justify-content-start align-items-center" *ngIf="isConnectedAction()">
                  <div class="icon-circle-dark d-flex align-items-center" style="max-width: 40px !important">
                    <span class="text-white">{{ categoryService.totalChanges() }} </span>
                  </div>
                  <span>
                    {{ locale("locale_key.general.connected_actions.title") }}
                  </span>
                  <i class="las la-external-link-square-alt" (click)="displayService.openConnectedActionsTab()"></i>
                </div>
              </span>
              <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
              </div>
              <ng-container *ngIf="!loadingInProgress">
                <button type="button" class="btn mx-1 tertiary-button custom-button" (click)="closeQuestionnaire()">{{ locale("locale_key.general.buttons.cancel") }}</button>
                <button type="button" class="btn mx-1 secondary-button custom-button" *ngIf="activeFormNo != 1" (click)="saveAsDraft = true; openConnectedActionsModal()">{{ locale("locale_key.button.save_as_draft") }}</button>
                <button type="button" class="btn mx-1 tertiary-button custom-button" *ngIf="activeFormNo != 1" (click)="prev()">{{ locale("locale_key.general.buttons.previous") }}</button>
                <button type="button" class="btn mx-1 primary-button custom-button" *ngIf="activeFormNo != 3" (click)="next()">{{ locale("locale_key.general.buttons.next") }}</button>
                <button type="button" class="btn mx-1 primary-button custom-button" *ngIf="activeFormNo == 3" (click)="saveAsDraft = false; openConnectedActionsModal()">
                  {{ locale("locale_key.pages.data_request.create_wizard.button.send_request") }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmSendRequest>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title">{{ locale("locale_key.pages.data_request.send_request_confirmation_modal.title") }}</h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal()" style="cursor: pointer">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="m-2 px-2">
    <p class="m-0">{{ locale("locale_key.pages.data_request.send_request_confirmation_modal.desc") }}</p>
    <p *ngIf="isFromDraft">{{ locale("locale_key.data_request.send_request_modal.draft_desc") }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn px-0 secondary-button custom-button" *ngIf="isFromDraft" (click)="sendRequest(true, true)">{{ locale("locale_key.general.buttons.send_and_keep") }}</button>
      <button type="button" class="btn primary-button custom-button" *ngIf="isFromDraft" (click)="sendRequest(true)">{{ locale("locale_key.general.buttons.send_and_remove") }}</button>
      <button type="button" class="btn primary-button custom-button" *ngIf="!isFromDraft" (click)="sendRequest(true)">{{ locale("locale_key.general.buttons.send") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmDeleteQues>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title">{{ locale("locale_key.pages.data_request.edit.delete_question_with_follow_up") }}</h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal()" style="cursor: pointer">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="m-2 px-2">
    <p class="m-0">{{ locale("locale_key.pages.data_request.edit.deleting_this_question_follow_up") }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn px-0 warning-button custom-button" (click)="deleteQues(selectedQuestionIndex, selectedSection)">{{ locale("locale_key.general.buttons.delete") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmSaveAsDraft>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title">{{ locale("locale_key.button.save_as_draft") }}</h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal()" style="cursor: pointer">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="m-2 px-2">
    <p>{{ locale("locale_key.data_request.save_draft_modal.description_request_name", { request_name: reqDetailsForm.controls.title.value }) }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn secondary-button custom-button" (click)="saveDraft(true)">{{ locale("locale_key.general.buttons.save_as_new") }}</button>
      <button type="button" class="btn primary-button custom-button px-0" *ngIf="isDraftExists()" (click)="saveDraft(false)">{{ locale("locale_key.general.buttons.replace_existing") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #questionnairePreview>
  <questionnaire-preview [request]="requestForPreview" [requestTitle]="reqDetailsForm.controls.title.value" [showMapping]="false"></questionnaire-preview>
</ng-template>

<ng-template #addDataCategory>
  <add-data-category (closeEvent)="closeModal()"></add-data-category>
</ng-template>

<ng-template #addDataPoint>
  <add-data-point (closeEvent)="closeModal()" [isCalculatedColumn]="false" [entityKey]="selectedEntityKey"></add-data-point>
</ng-template>

<ng-template #confirmDeploy>
  <div class="modal-header main-modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      {{ locale("locale_key.wizard.create_questionnaire.create_subject", { subject: "request" }) }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale("locale_key.connected-actions.connected-actions.connected-actions.create_subject_warning", { subject: "request" }) }}</p>
    <p>{{ locale("locale_key.connected-actions.apply_changes_on_creation", { subject: "request" }) }}</p>
    <div class="row mb-2" *ngIf="categoryService.newEntities.length > 0">
      <div class="accordion" id="entitesAccordion">
        <h2 class="accordion-header" id="entityHeading">
          <div class="accordion-button bg-light-gray" type="button" data-bs-toggle="collapse" data-bs-target="#entityCollapse" aria-expanded="true" aria-controls="entityCollapse">
            <i class="las la-table fs-3 pe-2"></i>
            {{ locale("locale_key.connected_actions.data_categories_to_be_added") }}
          </div>
        </h2>
        <div id="entityCollapse" class="accordion-collapse collapse show" aria-labelledby="entityHeading" data-bs-parent="#entitesAccordion">
          <span class="badge badge-pink w-auto py-auto m-2" *ngFor="let entity of categoryService.newEntities">
            {{ entity.getTaxonomyTitle(taxonomyInfo) }}
          </span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="categoryService.dataPointsLength() > 0">
      <div class="accordion" id="dataPointAcc">
        <h2 class="accordion-header" id="dpHeading">
          <div class="accordion-button bg-light-gray" type="button" data-bs-toggle="collapse" data-bs-target="#dpCollapse" aria-expanded="true" aria-controls="dpCollapse">
            <i class="las la-table fs-3 pe-2"></i>
            {{ locale("locale_key.connected_actions.data_points_to_be_added") }}
          </div>
        </h2>
        <div id="dpCollapse" class="accordion-collapse collapse show" aria-labelledby="dpHeading" data-bs-parent="#dataPointAcc">
          <ng-container *ngFor="let entity of categoryService.getNewDataPointEntites()">
            <span class="badge badge-info w-auto py-auto m-2 mb-0">
              {{ entity.getTaxonomyTitle(taxonomyInfo) }}
            </span>
            <ng-container *ngFor="let dp of categoryService.getNewDataPoints(entity); let last = last">
              <span class="badge badge-pink w-auto py-auto mx-2">
                {{ dp.getLabel() }}
              </span>
              <ng-container *ngIf="!last">/</ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="deployTaxonomy()">{{ locale("locale_key.pages.insight_detail.create_and_apply") }}</button>
    </ng-container>
    <div class="ball-grid-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>