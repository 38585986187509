import { SharedResourceTypeFe } from "../shared/SharedResourceTypeFe"

export class VisitorUserFe {
  token: string
  email: string
  verificationCode: string
  purpose: string
  emailLang: string | undefined

  constructor(
    token: string,
    email: string,
    verificationCode: string,
    emailLang?: string
  ) { 
    this.token = token
    this.email = email
    this.verificationCode = verificationCode
    this.purpose = SharedResourceTypeFe.DASHBOARD
    this.emailLang = emailLang
  }

  public static fromTransfer (transfer: any): VisitorUserFe { 
    let user = new VisitorUserFe (
      transfer.token,
      transfer.email,
      transfer.verificationCode,
      transfer.emailLang
    )
    return user
  }
}