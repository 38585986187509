import { Component, TemplateRef, ViewChild } from "@angular/core";
import { DashboardFe } from "src/app/model/dashboard/DashboardFe";
import { SharedDashboardFe } from "src/app/model/shared/SharedDashboardFe";
import { SharedResourceAccessTypeFe } from "src/app/model/shared/SharedResourceAccessTypeFe";
import { ChartSettingSubjectFe, ChartSettingTypeFe } from "src/app/model/subject/ChartSettingSubjectFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { AbstractDashboardComponent } from "./abstract.dashboard.component";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ChartWrapperFeNew } from "src/app/model/chart/ChartWrapperFeNew";

@Component({
  selector: "chart-settings",
  templateUrl: "./chart-settings.component.html",
  styleUrls: ["./chart-settings.component.scss"],
})
export class ChartSettingsComponent extends AbstractLanguageComponent {
  @ViewChild("dataTable", { static: true }) dataTable: TemplateRef<any>
  selectedChartIndex: number;
  wrapper: ChartWrapperFeNew;
  dashboard: DashboardFe | SharedDashboardFe;
  isASharedDashboard: boolean = false;
  url:string =''
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  showDefinition: string = 'Show'
  showDefinitionIcon: string = 'la la-arrows';
  showMore: string = 'Show more' 
  showMore1: string = 'Show more'
  showMore2: string = 'Show more'
  isLoadingData: boolean = false;

  pageToolbarModal = [
    [{ shortLabel: this.locale('locale_key.general.toolbar.button.export'), longLabel: this.locale('locale_key.general.toolbar.button.export'), tooltip: this.locale('locale_key.general.toolbar.button.export'), icon: "las la-download", actionName: "add_kpi",  visible: () =>  true  , disabled: false }]

  ]

  constructor(private displayService: DisplayServiceFe, laguageService: LanguageService, private stateService: StateServiceFe,private responsive: ResponsiveService, private modalService: BsModalService,private modalRef: BsModalRef) {
    super(laguageService);
  }

  ngOnInit() {
    if (this.displayService.detailsData) {
      this.selectedChartIndex = this.displayService.detailsData.selectedChartIndex;
      this.dashboard = this.displayService.detailsData.dashboard;
      this.isASharedDashboard = this.dashboard instanceof SharedDashboardFe
      this.wrapper = this.displayService.detailsData.selectedChartWrapper;
    }

    this.displayService.detailsDataUpdatedSubject.subscribe((isUpdated) => {
      if (isUpdated && this.displayService.detailsData) {
        this.selectedChartIndex = this.displayService.detailsData.selectedChartIndex;
        this.dashboard = this.displayService.detailsData.dashboard;
        this.isASharedDashboard = this.dashboard instanceof SharedDashboardFe
        this.wrapper = this.displayService.detailsData.selectedChartWrapper;
      }
    });
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
  }

  onChangedSetting(): void {
    let subject = new ChartSettingSubjectFe(ChartSettingTypeFe.SETTINGS, this.selectedChartIndex)
    this.displayService.chartSettingsChanged.next(subject);
  }

  downloadImg() {
    let subject = new ChartSettingSubjectFe(ChartSettingTypeFe.DOWNLOAD_IMAGE, this.selectedChartIndex)
    this.displayService.chartSettingsChanged.next(subject);
  }

  downloadXlsx() {
    let subject = new ChartSettingSubjectFe(ChartSettingTypeFe.DOWNLOAD_XLSX, this.selectedChartIndex)
    this.displayService.chartSettingsChanged.next(subject);
  }

  downloadCsv() {
    let subject = new ChartSettingSubjectFe(ChartSettingTypeFe.DOWNLOAD_CSV, this.selectedChartIndex)
    this.displayService.chartSettingsChanged.next(subject);
  }

  downloadPDF() {
    let subject = new ChartSettingSubjectFe(ChartSettingTypeFe.DOWNLOAD_PDF, this.selectedChartIndex)
    this.displayService.chartSettingsChanged.next(subject);
  }

  deleteChart(): void {
    let subject = new ChartSettingSubjectFe(ChartSettingTypeFe.DELETE, this.selectedChartIndex)
    this.displayService.closeDetails();
    this.displayService.chartSettingsChanged.next(subject);
  }

  isExportAllowed () {
    if (this.dashboard instanceof SharedDashboardFe) {
      return this.dashboard.accessType === SharedResourceAccessTypeFe.VIEW_AND_EXPORT
    }
    return true;
  }

  handleToolbarAction(actionName: string) {
    
  }

  expandModal() {
    this.showDefinition == 'Show' ? this.showDefinition = 'Hide' :  this.showDefinition ='Show' 
    this.showDefinition == 'Show' ? this.showDefinitionIcon = 'la la-arrows' : this.showDefinitionIcon = 'ft-minimize' 
    let modalId = document.getElementById('modalContent')
    modalId.classList.toggle('kpiModalHeight')
  }

  addActiveClass(event: any){
    const elements = document.querySelectorAll('[id^="acc"]');
    elements.forEach((element:any) => {
      element.classList.remove('active-accordion')
    });
    let elementId = event.currentTarget.id
    let element = document.getElementById(elementId)
    element.classList.toggle('active-accordion')
  }

  showMoreShowLess(event: any){
    if(event.currentTarget.id == 'link0'){
    this.showMore == 'Show more' ? this.showMore = 'Show less' :  this.showMore ='Show more'
    }
    else if (event.currentTarget.id == 'link1'){
      this.showMore1 == 'Show more' ? this.showMore1 = 'Show less' :  this.showMore1 ='Show more'
    }else {
      this.showMore2 == 'Show more' ? this.showMore2 = 'Show less' :  this.showMore2 ='Show more'
    }
  }

  openModal (modalTemplateRef: TemplateRef<any> | string, className: string = 'modal-xl') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: className
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open');
    }
  }

  changeView() {
    throw new Error('Method not implemented.');
    }
}
