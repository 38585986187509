<body class="d-flex" *ngIf="!initCacheInProgress">
  <div class="flex-fill" style="overflow: hidden;"> <!--disables vertical scrolling on the main div-->
    <div class="d-flex flex-row justify-content-between h-100" >
      <!--shadow sidebar behind the left navbar-->
        <app-sidebar [ngStyle]="{
          'transform': translateX,
          'opacity': opacity,
          'display': display,
          'transition': transition
        }" style="position: fixed; z-index: 10000; height: 100%;"></app-sidebar>
        <div style="min-width: 5.5rem;" *ngIf="!screenSize.isSmallerSize()"></div>
      <div class="flex-fill">
        <div class="d-flex flex-column justify-content-between h-100">
          <!--shadow header behind the top header-->
          <app-header></app-header>
          <!-- <div style="min-height: 47px;"></div> -->
          <div class="flex-fill">
            <router-outlet></router-outlet>
          </div>
          <!--shadow footer behind the top footer-->
          <app-footer></app-footer>
          <!-- <div style="min-height: 32px;"></div> -->
        </div>
      </div>
    </div>
  </div>
  <app-details></app-details>
  <app-attachment-details></app-attachment-details>
</body>
