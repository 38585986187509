import { Operator } from "./OperatorFe";

export class OneOperandOperatorFe extends Operator{

  static readonly NOT  = new OneOperandOperatorFe('not', '!', 'not eqaul to');

  private constructor(key: string, symbol: string, label: string) {
    super(key, symbol, label);
  }

  public static opByKey(key: string): OneOperandOperatorFe{
    if (key == OneOperandOperatorFe.NOT.key) {
      return OneOperandOperatorFe.NOT
    } else {
      // throw new ServerError(ServerErrorCode.UNRECOGNIZED_ONE_OPERAND_OPERATOR)
    }
  }
  
}