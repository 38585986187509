import { RouterFe } from 'src/app/route/RouterFe';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { FileDataFe } from 'src/app/model/file/FileDataFe';
import { LoginUserFe } from 'src/app/model/org/LoginUserFe';
import { AlertServiceFe } from 'src/app/services/AlertServiceFe';
import { FileTypeFe } from 'src/app/model/file/FileTypeFe';
import { IdUtil } from 'src/app/utils/IdUtil';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnSchemaFe } from 'src/app/model/schema/ColumnSchemaFe';
import { TableDataFe } from 'src/app/model/schema/TableDataFe';
import { StageTableDataGridIntergationService } from '../data/StageTableDataGridIntergationService';
import { TaskDataGridIntegrationService } from '../data-suppliers/data-supplier-task/TaskDataGridIntegrationService';
import { LoginServiceFe } from 'src/app/services/LoginServiceFe';
import { AffiliationRoleFe } from 'src/app/model/data-suppliers/company/AffiliationRoleFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { DataGridTableData } from '../projects/data-grid-ui/model/DataGridTableData';
import { DataGridTableDef } from '../projects/data-grid-ui/model/DataGridTableDef';
import { DataGridTableMode } from '../projects/data-grid-ui/model/DataGridTableMode';
import { DataGridTaxonomyEntity } from '../projects/data-grid-ui/model/DataGridTaxonomyEntity';
import { DataExtractorObserverInterface } from '../projects/data-grid-ui/service/DataExtractorObserverInterface';
import { ExcelViewerObserverInterface } from '../projects/data-grid-ui/service/ExcxelViewerObserverInterface';

@Component({
  selector: 'file-upload-picker',
  templateUrl: './file-upload-picker.component.html',
  styleUrls: ['./file-upload-picker.component.scss']
})
export class FileUploaderPickerComponent extends AbstractLanguageComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() selectedFileEvent = new EventEmitter<FileDataFe>();

  inProgress = false
  public allFiles: FileDataFe[] = [];
  public sortType: string;
  public sortAsc: boolean = false;
  isCollapse: boolean;
  isCollapsed: boolean;
  selectedFile: FileDataFe
  viewNo = 1;
  @Output() dataExtractedEvent = new EventEmitter<TableDataFe>();
  @Input() destTableService: StageTableDataGridIntergationService | TaskDataGridIntegrationService
  isEnabled = true;
  modalRef: BsModalRef

  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  constructor (private modalService: BsModalService, private backendService: RouterFe, private stateService: StateServiceFe, private alertService: AlertServiceFe,
    private domSanitizer: DomSanitizer, private changeDetector: ChangeDetectorRef, private loginService: LoginServiceFe, languageService: LanguageService) {
    super(languageService)
  }

  ngOnInit(): void {
    if (this.destTableService instanceof StageTableDataGridIntergationService) {
      this.destTableService.currentPageData.rows = this.destTableService.currentPageData.rows.slice(0, 5)
    } else if (this.destTableService instanceof TaskDataGridIntegrationService) {
      this.destTableService.table.rows = this.destTableService.table.rows.slice(0, 5)
    }
    if (this.loginService.loginUser.activeWorkspaceRoleType != AffiliationRoleFe.DATA_OWNER) {
      this.loadCompanyData()
    }
  }

  sort(property = 'time') {
    if (this.sortType == property) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }
    this.sortType = property;
    let sortFunction = FileDataFe.sortFunction(property, this.sortAsc)
    this.allFiles.sort(sortFunction);
  }

  public async loadCompanyData() {
    this.inProgress = true
    this.allFiles = await this.stateService.getAllFiles()
    this.sort()
    this.inProgress = false
  }

  async selectFile(fileData: FileDataFe) {
    this.selectedFile = fileData
    await this.viewFile();
  }

  fileSelected(): boolean {
    let file = this.allFiles.find(f => f.isSelected)
    return file ? true : false
  }

  close() {
    this.closeEvent.emit(true);
  }

  selectFilesForUpload(event): void {
    this.inProgress = true;
    let file: File;
    Array.from(event.target.files).forEach((uploadedFile: File) => {
      file = uploadedFile
    });

    var reader = new FileReader()
    reader.onload = async (e: any) => {
      let blob = new Blob([e.target.result], { type: file.type })
      let newFile = FileDataFe.fromBlob(file.name)
      newFile.type = FileTypeFe.fromName(newFile.label)
      newFile.id = IdUtil.next()
      newFile.contentType = file.type
      newFile.addBlobContent(blob, this.domSanitizer)
      this.selectedFile = newFile
      this.inProgress = false
      await this.viewFile()
    }
    reader.readAsArrayBuffer(file)
  }

  async viewFile() {
    this.inProgress = true;
    this.viewNo++;
    let fileData: FileDataFe = this.selectedFile
    if (!fileData.hasContent()) {
      fileData.loadingFileInProgress = true
      fileData.isOpen = true
      await fileData.loadFile(this.stateService, this.domSanitizer, null, this.backendService, false);
      this.inProgress = false;
      this.setMainViewer();
    } else {
      fileData.isOpen = true
      this.inProgress = false;
    }
  }

  async convertToImg(fileData: FileDataFe) {
    await (this.observer as DataExtractorObserverInterface)?.convertToImg();
    this.setCropperViewer();
  }

  convertCroppedToText(fileData: FileDataFe): void {
    (this.observer as DataExtractorObserverInterface).convertCroppedToText();
    this.setExtractorViewer();
  }

  addNewColumn() {
    return (this.observer as DataExtractorObserverInterface).addNewColumn()
  }

  /**
   * ExcelViewerServiceInterface methods
   */
  observer: ExcelViewerObserverInterface
  isExcelDataMarked: boolean = false
  @Input() isMappingToTaxonomy = true;

  registerObserver(observer: ExcelViewerObserverInterface | DataExtractorObserverInterface): void {
    this.observer = observer
  }

  async getTaxonomyEntities(): Promise<DataGridTaxonomyEntity[]> {
    if (this.destTableService) {
      if (this.isMappingToTaxonomy && this.destTableService instanceof StageTableDataGridIntergationService) {
        let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos();
        let taxonomy = depTaxonomy
        let taxonomyKey = this.destTableService.entity.key
        let e = taxonomy.entityByKey(taxonomyKey)
        let entity = new DataGridTaxonomyEntity(e.key, taxonomy.categoryFQN(e.key), e.getLabel(), e.ordinal)
        return [entity]
      } else {
        return []
      }
    } else {
      let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos();
      let taxonomy = depTaxonomy
      let tableEntiteies = taxonomy.entities.filter(e => e.table == true)
      let entities = tableEntiteies.map(e => {
        let entity = new DataGridTaxonomyEntity(e.key, taxonomy.categoryFQN(e.key), e.getLabel(), e.ordinal)
        return entity
      })
      return entities
    }
  }

  async getEntityTableDef(entity: DataGridTaxonomyEntity): Promise<DataGridTableDef> {
    let tableDef = new DataGridTableDef()
    if (this.isMappingToTaxonomy) {
      let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos();
      let taxonomy = depTaxonomy
      let e = taxonomy.entityByKey(entity.key)
      let schema: ColumnSchemaFe[] = e.columnSchemas()
      tableDef.dataSchema = schema.map(s => s.toDataGridColumnSchema())
    } else if (this.destTableService instanceof TaskDataGridIntegrationService) {
      let schema: ColumnSchemaFe[] = this.destTableService.table.dataSchema
      tableDef.dataSchema = schema.map((s: ColumnSchemaFe) => s.toDataGridColumnSchema())
    }
    return tableDef
  }

  async exportTableData(dgtd: DataGridTableData): Promise<void> {
    let tableData = TableDataFe.fromDataGridTableData(dgtd)
    this.dataExtractedEvent.emit(tableData)
  }

  async getExcelBlob(): Promise<Blob> {
    return this.selectedFile.blob
  }

  async getFileName(): Promise<string> {
    return this.selectedFile.label
  }

  markDataSelected(isSelected: boolean): void {
    this.isExcelDataMarked = isSelected
  }

  /**
   * DataExtractorServiceInterface methods
   */

  tableData: TableDataFe;
  viewer: string;

  getViewer(): string {
    return this.viewer
  }

  setMainViewer() {
    this.viewer = 'main';
    this.selectedFile.isConvertedToImg = false;
    this.selectedFile.isCropped = false;
  }

  setCropperViewer() {
    this.viewer = 'cropper';
    this.selectedFile.isConvertedToImg = true;
    this.selectedFile.isCropped = false;
  }

  isCroppperViewer(): boolean {
    return this.viewer == 'cropper'
  }

  setExtractorViewer() {
    this.viewer = 'extractor';
    this.selectedFile.isCropped = true;
  }

  isExtractorViewer(): boolean {
    return this.viewer == 'extractor'
  }

  async getPDFBlob(): Promise<Blob> {
    if (this.selectedFile.isPdf()) {
      this.setMainViewer();
      return this.selectedFile.blob
    }
    else
      return null
  }

  async getImageBlob(): Promise<Blob> {
    if (this.selectedFile.isImage()) {
      this.setMainViewer();
      return this.selectedFile.blob
    }
    else
      return null;
  }

  previousView() {
    this.viewNo--;
    this.closeModal();
    this.isEnabled = false;
    this.isExcelDataMarked = false;
    this.changeDetector.detectChanges();
    this.isEnabled = true;
  }

  mapToTaxonomy() {
    this.observer.mapToTaxonomy();
    this.observer.setDataGridMode(DataGridTableMode.MAP_EXTRACTED_DATA);
    this.viewNo++;
  }

  extract () {
    let table = this.observer.getExtractedTableData();
    let tableData = TableDataFe.fromDataGridTableData(table)
    this.dataExtractedEvent.emit(tableData)
  }

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string = 'modal-md') {
    let config = {
      class: clazz
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal () {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open');
  }
}

