<div class="supplier-modal">
  <div class="main-modal-header modal-header">
    <i class="la la-copy pb-0 mb-0 me-2" style="font-size: 20px;" *ngIf="mode == 'duplicate'"></i>
    <h4 class="modal-title text-white me-auto text-truncate">{{ mode == "add" ? locale('locale_key.pages.clients.add_new_management_company_modal.title') : locale('locale_key.pages.clients.button.duplicate_management_company')}}</h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!isLoading">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300;" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body scroll-example" style="border-top:none;" >
    <form [formGroup]="form1" *ngIf="activeFormNo == 1">
      <div class="modal-body modal-min-h overflow-scroll">
        <div class="content clearfix">
          <div class="form-group">
            <div class="d-flex align-items-center">
              <ng-container *ngIf="mode == 'duplicate'">
                <h5>{{locale('locale_key.pages.clients.duplicate_management_company_modal.description')}}</h5>
              </ng-container>
              <ng-container *ngIf='mode == "add"'>
                <i class="la la-building fs-5 me-2 my-auto"></i>
                <h4 class="me-2 my-auto">{{locale('locale_key.pages.clients.new_management_company_modal.company_info.title')}}</h4>
                <i class="la la-info-circle dropdown-toggle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div class="dropdown-menu arrow p-2 info-popover">
                  <p>{{locale('locale_key.pages.clients.new_management_company_modal.right_name.info')}}</p>
                </div>
              </ng-container>
            </div>
            <div class="d-flex flex-row p-1 mt-2 make-col">
              <ng-container *ngIf="mode == 'duplicate'">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="d-flex ms-0">
                    <i class="la la-building me-2" style="font-size: 25px;"></i>
                    <h3>{{locale('locale_key.pages.clients.duplicate_management_company_modal.existing_company_info.title')}}</h3>
                  </div>
                  <div class="row px-1 mt-1">
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{locale('locale_key.pages.clients.modal.company_name')}}</label>
                        <input type="text" class="form-control input-p" disabled [value]="company.name" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{locale('locale_key.pages.clients.modal.account_purpose.title')}}</label>
                        <input type="text" class="form-control input-p" disabled [value]="company.companyAccountPurpose" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{locale('locale_key.pages.clients.modal.country')}}</label>
                        <input type="text" class="form-control input-p" disabled [value]="company.country" />
                      </div>
                    </div>
                    <div class="col-12">
                      <label>{{locale('locale_key.pages.clients.modal.industry')}}</label>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <input type="text" class="form-control input-p" disabled [value]="company.industryGroup" />
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <input type="text" class="form-control input-p" disabled [value]="company.industry" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{locale('locale_key.pages.clients.modal.web_address')}}</label>
                        <input type="text" class="form-control input-p" disabled [value]="company.website" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <!-- temporary commented -->
                  <!-- <div class="vr"></div> -->
                </div>
              </ng-container>
              <div [ngClass]="{'col-lg-6 col-md-12 col-sm-12' : mode == 'duplicate'}">
                <div class="d-flex ml-1" *ngIf="mode == 'duplicate'">
                  <i class="la la-building mt-40 mr-50" style="font-size: 25px;"></i>
                  <h3>{{locale('locale_key.pages.clients.duplicate_management_company_modal.title')}}</h3>
                </div>
                <div class="row px-1 mt-1">
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{locale('locale_key.pages.clients.modal.company_name')}}*</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{ locale('locale_key.pages.clients.modal.company_name.placeholder') }}"
                        required
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid': form1.get('name').errors && (form1.get('name').touched || form1.get('name').dirty)
                        }"
                      />
                      <div *ngIf="form1.get('name').errors && (form1.get('name').touched || form1.get('name').dirty)" class="invalid-feedback">
                        <div *ngIf="form1.get('name').errors.required">
                          {{ companyNameMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{locale('locale_key.pages.clients.modal.account_purpose.title')}}*</label>
                      <select
                        formControlName="companyAccountPurpose"
                        class="form-select form-control"
                        [ngClass]="{
                          'is-invalid': form1.get('companyAccountPurpose').errors && form1.get('companyAccountPurpose').touched
                        }"
                      >
                        <option value="CLIENT">{{locale('locale_key.pages.clients.modal.options.client')}}</option>
                        <option value="TRIAL">{{locale('locale_key.pages.clients.modal.options.trial')}}</option>
                        <option value="DEMO">{{locale('locale_key.pages.clients.modal.options.demo')}}</option>
                        <option value="TEMPLATE">{{locale('locale_key.pages.clients.modal.options.template')}}</option>
                        <option value="TEST">{{locale('locale_key.pages.clients.modal.options.test')}}</option>
                      </select>
                      <div *ngIf="form1.get('companyAccountPurpose').errors && form1.get('companyAccountPurpose').touched" class="invalid-feedback">
                        <div *ngIf="form1.get('companyAccountPurpose').errors.required">{{ locale("locale_key.pages.clients.account_purpose_is_required") }}</div>
                      </div>
                    </div>
                  </div>
    
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{locale('locale_key.pages.clients.modal.country')}}*</label>
                      <select
                        formControlName="country"
                        class="form-select form-control"
                        [ngClass]="{
                          'is-invalid': form1.get('country').errors && form1.get('country').touched
                        }"
                      >
                        <option *ngFor="let countryData of countries" [value]="countryData[0]">{{ resolveLabel(countryData[1]) }}</option>
                      </select>
                      <div *ngIf="form1.get('country').errors && form1.get('country').touched" class="invalid-feedback">
                        <div *ngIf="form1.get('country').errors.required">
                          {{ companyCountry }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <label>{{ locale('locale_key.pages.clients.modal.industry' )}}*</label>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <select
                            class="form-select form-control"
                            formControlName="industryGroup"
                            [ngClass]="{
                              'is-invalid': (form1.get('industryGroup').errors && form1.get('industryGroup').touched) || form1.get('industryGroup').value == industries[0].industryGroup[activeLanguage.code]
                            }"
                            required
                          >
                            <option *ngFor="let industryData of industries" [value]="industryData.industryGroup[activeLanguage.code]">
                              {{ industryData.industryGroup[activeLanguage.code] }}
                            </option>
                          </select>
                          <div *ngIf="(form1.get('industryGroup').errors && form1.get('industryGroup').touched) || form1.get('industryGroup').value == industries[0].industryGroup[activeLanguage.code]" class="invalid-feedback">
                            <div *ngIf="form1.get('industryGroup').errors?.required || form1.get('industryGroup').value == industries[0].industryGroup[activeLanguage.code]">
                              {{ companyIndustryGroup }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <select
                            class="form-select form-control"
                            formControlName="industry"
                            [ngClass]="{
                              'is-invalid': (form1.get('industry').errors && form1.get('industry').touched) || form1.get('industry').value == industries[0].industry[0][activeLanguage.code]
                            }"
                            required
                          >
                            <option *ngFor="let industry of getIndustry()" [value]="industry">
                              {{ industry }}
                            </option>
                          </select>
                          <div *ngIf="(form1.get('industry').errors && form1.get('industry').touched) || form1.get('industry').value == industries[0].industry[0][activeLanguage.code]" class="invalid-feedback">
                            <div *ngIf="form1.get('industry').errors?.required || form1.get('industry').value == industries[0].industry[0][activeLanguage.code]">
                              {{ companyIndustry }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{locale('locale_key.pages.clients.modal.web_address')}}*</label>
                      <input
                        type="url"
                        class="form-control"
                        placeholder="{{locale('locale_key.pages.clients.modal.web_address')}}"
                        formControlName="website"
                        [ngClass]="{
                          'is-invalid': form1.get('website').errors && (form1.get('website').touched || form1.get('website').dirty)
                        }"
                      />
                      <div *ngIf="form1.get('website').errors && (form1.get('website').touched || form1.get('website').dirty)" class="invalid-feedback">
                        <div *ngIf="form1.get('website').errors.required">
                          {{ companyWebAddress }}
                        </div>
                        <div *ngIf="form1.get('website').errors?.pattern">
                          {{ companyWebFromatMessage }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="mode=='duplicate' && activeFormNo==2">
      <h5 class="m-2">{{locale('locale_key.pages.clients.duplicate_management_company_modal.copying_data_from', {company: company.name})}}</h5>
      <ol>
        <li>{{locale('locale_key.pages.clients.duplicate_management_company_modal.copy_taxonomy')}}
          <div class="d-inline ball-pulse loader-primary" *ngIf="!isTaxonomyCopied">
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
          </div>
          <i class="la la-check success" *ngIf="isTaxonomyCopied"></i>
        </li>
        <li>{{locale('locale_key.pages.clients.duplicate_management_company_modal.copy_kpis')}}
          <div class="d-inline ball-pulse loader-primary" *ngIf="isTaxonomyCopied && !isKPICopied">
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
          </div>
          <i class="la la-check success" *ngIf="isKPICopied"></i>
        </li>
        <li>{{locale('locale_key.pages.clients.duplicate_management_company_modal.copy_dashboards')}}
          <div class="d-inline ball-pulse loader-primary" *ngIf="isTaxonomyCopied && isKPICopied && !isDashboardCopied">
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
          </div>
          <i class="la la-check success" *ngIf="isDashboardCopied"></i>
        </li>
        <li>{{locale('locale_key.pages.clients.duplicate_management_company_modal.copy_questionnaires')}}
          <div class="d-inline ball-pulse loader-primary" *ngIf="isTaxonomyCopied && isKPICopied && isDashboardCopied && !isDraftRequestCopied">
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
            <div style="background-color:var( --main-dark-color1)"></div>
          </div>
          <i class="la la-check success" *ngIf="isDraftRequestCopied"></i>
        </li>
      </ol>
    </div>
  </div>
  <div class="modal-footer" *ngIf="activeFormNo == 1">
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.general.buttons.cancel')}}</button>
      <button class="btn btn-add white custom-button" [disabled]="form1.invalid || form1.get('industryGroup').value == industries[0].industryGroup[activeLanguage.code] || 
      form1.get('industry').value == industries[0].industry[0][activeLanguage.code]" (click)="addNewCompany()">{{locale('locale_key.pages.clients.modal.button.add_company')}}</button>
    </ng-container>    
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color:var( --main-dark-color1)"></div>
      <div style="background-color:var( --main-dark-color1)"></div>
      <div style="background-color:var( --main-dark-color1)"></div>
    </div>
  </div>
</div>
