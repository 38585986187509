import { Component, OnInit } from "@angular/core";
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { TaskFe } from "src/app/model/data-suppliers/task/TaskFe";
import { SchedulingOccurenceGeneratorFe } from "src/app/model/scheduling/SchedulingOccurenceGeneratorFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { DateUtil } from "src/app/utils/DateUtil";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Component({
  selector: "task-details",
  templateUrl: "./details.component.html",
  styleUrls: ["../../details/details.component.scss"],
})
export class TaskDetailsComponent extends AbstractLanguageComponent implements OnInit {
  task: TaskFe;
  requestGroup: RequestGroupFe
  requestGroups: RequestGroupFe[] = []
  changeDeadlineOnHover: boolean
  morePeriods: boolean = false

  constructor(private displayService: DisplayServiceFe, languageService: LanguageService, public stateService: StateServiceFe) {
    super(languageService);
  }

  async ngOnInit() {

    this.requestGroups = await this.stateService.getRequestGroups();

    if (this.displayService.detailsData) {
      this.task = this.displayService.detailsData.task;
      this.requestGroup = this.requestGroups.find(rg => rg.id == this.task.requestGroupId)
    }
    this.displayService.detailsDataUpdatedSubject.subscribe((detailsData) => {
      this.task = this.displayService.detailsData.task;
      this.requestGroup = this.requestGroups.find(rg => rg.id == this.task.requestGroupId)
    });
    
  }

  ngOnDestroy() {
  }

  getDeadline(date: Date) {
    return DateUtil.getDeadlineClass(new Date(date));
  }

  displayDeadlineText() {
    let due = document.getElementById("due");
    if(due.classList.contains("badge-red")){
      return "Overdue";
    }else if (due.classList.contains("badge-orange")){
      return "Upcoming";
    }
    return "Due";
  }
  
  showIntervals() {
    this.morePeriods == false ? this.morePeriods = true : this.morePeriods = false
  }

  isRecurring() {
    return this.requestGroup.recurrence && this.requestGroup.recurrence.type === "FIXED";
  }

  getPreviousRequestPeriods() {
    let requestGroup = this.requestGroup
    while (requestGroup.recurringParent) {
      requestGroup = this.requestGroups.find(rg => rg.id === requestGroup.recurringParent)
    }
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getPreviousRequestPeriods(requestGroup);
    return periods;
  }

  getCurrentRequestPeriod() {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getCurrentRequestPeriod(this.requestGroup);
    return periods;
  }

  getUpcomingRequestPeriods() {
    let requestGroup = this.requestGroup
    while (requestGroup.recurringParent) {
      requestGroup = this.requestGroups.find(rg => rg.id === requestGroup.recurringParent)
    }
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getUpcomingRequestPeriods(requestGroup);
    if(this.getCurrentRequestPeriod() == this.getLastRequestPeriod())
      return [];
    return periods;
  }

  getLastRequestPeriod() {
    let requestGroup = this.requestGroup
    while (requestGroup.recurringParent) {
      requestGroup = this.requestGroups.find(rg => rg.id === requestGroup.recurringParent)
    }
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getLastRequestPeriod(requestGroup);
    if(this.getCurrentRequestPeriod() == periods)
      return [];
    return periods;
  }

 
}
