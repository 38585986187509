<div class="logo-img">
  <img class="demo-bg" src="/assets/img/logos/Logo-Sustainlab.png" alt="" />
  <div class="col-12 bg-gray-light">
    <div class="row">
      <nav class="w-100 mt-3">
        <ul class="nav float-end text-end me-3">
          <li>
            <div class="btn-group float-end text-end">
              <div class="float-end text-end d-flex align-baseline">
                <h5 class="d-inline me-2 mt-2">{{locale('locale_key.main_navbar.logout')}}</h5>
                <div class="icon-backg d-inline mt-1">
                  <i
                    class="la la-power-off black"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#warning"
                    data-backdrop="false"
                    title="{{locale('locale_key.main_navbar.logout')}}"
                    style="font-size: 2rem; background-color: transparent; border-radius: 50%; position: relative; left: -7px; top: -7px"
                  ></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <!-- Modal -->
    <div class="modal fade text-start" id="warning" tabindex="-1" role="dialog" aria-labelledby="myModalLabel12" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header alert-modal-header">
            <div class="icon-backg d-inline mt-1" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidwhite">
              <i class="las la-exclamation-circle text-white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: -2px; top: -2px"></i>
            </div>
            <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12">{{locale('locale_key.main_navbar.logout')}}</h4>
            <button type="button" class="btn close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="las la-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <h5>{{locale('locale_key.modal.logout_confirmation_modal.description')}}</h5>
          </div>
          <div class="modal-footer" style="border-top: none">
            <button type="button" class="btn secondary-button custom-button" data-dismiss="modal">{{locale('locale_key.general.buttons.cancel')}}</button>
            <button type="button" class="btn btn-add white custom-button" (click)="logout()">{{locale('locale_key.general.buttons.continue')}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row make-cols">
      <div class="col-lg-3 col-md-12 col-12 d-flex justify-content-center align-content-center my-auto" style="height: 84vh" [ngStyle]="{ 'overflow-y': loginUser.isAdmin ? 'scroll' : 'unset' }">
        <div class="w-100 d-flex justify-content-center align-content-center my-auto" style="border-right: 3px solid lightgray" [ngClass]="loginUser.isAdmin ? 'h-100' : 'h-75'">
          <div class="content-body w-100 my-auto">
            <div class="col-md-12 px-4">
              <div class="row mb-3">
                <div class="m-auto" style="height: 45px; width: 45px; color: var(--main-dark-color1); background: white; border-radius: 50%; border: 2px solid lightgray">
                  <h3 class="d-flex justify-content-center pt-2">{{ loginUser.nameInitials() }}</h3>              
                </div>
              </div>
              <div class="col my-3 text-center">
                <p class="mx-auto mb-3">{{ locale('locale_key.general.workspace_switcher.logged_in.title', {'email': loginUser.loginTimeEmail}) }}</p>
                <h2 class="m-auto"> {{ locale('locale_key.general.workspace_switcher.workspace.title', { firstName: loginUser.firstName }) }}</h2>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-6 col-12 m-auto">
                  <div class="card" *ngIf="stateService.activeWorkspace && stateService.activeWorkspace.companyId != 'ADMIN'">
                    <div class="card-body" style="border: solid; border-color: var(--main-contrast-color)">
                      <div class="card-content">
                        <h2 [innerHTML]='locale("locale_key.general.workspace_switcher.company_as_role.title", {companyName: stateService.activeWorkspace.companyName , 
                          affiliationRole: stateService.activeWorkspace.affiliationRole == "DATA_OWNER" ? this.locale("locale_key.general.user_role.data_reporter") : this.locale("locale_key.general.user_role.sustainability_manager")})'></h2>
                        <div class="row mb-2" [ngStyle]="{ visibility: stateService.activeWorkspace.affiliationRole == 'DATA_OWNER' ? 'hidden' : 'visible' }">
                          <div class="col-10">
                            <div class="d-inline-block"><i class="la la-inbox mt-1" style="position: relative; top: 2px"></i></div>
                            <h4 class="ms-2">{{ locale('locale_key.general.workspace_switcher.to_review.title') }}</h4>
                          </div>
                          <div class="col-2 mt-2">
                            <div
                              class="d-flex justify-content-center align-content-center m-auto"
                              [ngClass]="{
                                'round-pink': stateService.activeWorkspace.noOfToReviewRequests > 0
                              }"
                            >
                              <h4
                                class="nr my-auto"
                                [ngClass]="{
                                  white: stateService.activeWorkspace.noOfToReviewRequests > 0
                                }"
                              >
                                {{ stateService.activeWorkspace.noOfToReviewRequests }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-10">
                            <div class="d-inline-block">
                              <i class="la la-envelope" style="position: relative; top: 2px"></i>
                            </div>
                            <h4 class="ms-2">{{ locale('locale_key.general.workspace_switcher.new_activities.title') }}</h4>
                          </div>
                          <div class="col-2 mt-2">
                            <div
                              class="d-flex justify-content-center align-content-center m-auto"
                              [ngClass]="{
                                'round-pink': stateService.activeWorkspace.noOfUnreadActivities > 0
                              }"
                            >
                              <h4
                                class="nr my-auto"
                                [ngClass]="{
                                  white: stateService.activeWorkspace.noOfUnreadActivities > 0
                                }"
                              >
                                {{ stateService.activeWorkspace.noOfUnreadActivities }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-10">
                            <span class="d-inline-block"> <i class="la la-exclamation-circle" style="position: relative; top: 2px"></i></span>
                            <h4 class="ms-2">{{ locale('locale_key.general.workspace_switcher.upcoming_deadlines.title') }}</h4>
                          </div>
                          <div class="col-2 mt-2">
                            <div
                              class="d-flex justify-content-center align-content-center m-auto"
                              [ngClass]="{
                                'round-pink': stateService.activeWorkspace.noOfUpcomingDeadlines > 0
                              }"
                            >
                              <h4
                                class="nr my-auto"
                                [ngClass]="{
                                  white: stateService.activeWorkspace.noOfUpcomingDeadlines > 0
                                }"
                              >
                                {{ stateService.activeWorkspace.noOfUpcomingDeadlines }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                          <button class="btn primary-button custom-button mt-3" routerLink="/ws/home" (click)="changeWorkspace(stateService.activeWorkspace)">{{ locale('locale_key.general.buttons.open') }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3" *ngIf="stateService.activeWorkspace && stateService.activeWorkspace.companyId != 'ADMIN'">
                <h4 class="text-center">{{ locale('locale_key.general.workspace_switcher.active_workspace.title') }}</h4>
              </div>
              <div class="row my-2" style="height: 15rem" *ngIf="!stateService.activeWorkspace || (stateService.activeWorkspace && stateService.activeWorkspace.companyId == 'ADMIN')">
                <h4 class="text-center m-auto font-italic">{{ locale('locale_key.general.workspace_switcher.no_active_workspace.title') }}</h4>
              </div>
              <ng-container *ngIf="loginUser.isAdmin">
                <div class="horizontal-divider m-3"></div>
                <div class="row">
                  <div class="col-lg-12 col-md-6 col-12 m-auto">
                    <div class="card" style="height: 15rem">
                      <div class="card-body d-flex justify-content-center align-items-center" [ngClass]="{ activeWorkspace: stateService.activeWorkspace && stateService.activeWorkspace.companyId == 'ADMIN' }">
                        <div class="card-content">
                          <div class="text-center">
                            <i class="la la-user fs-4"></i>
                            <h2>{{ locale('locale_key.general.workspace_switcher.administration.title') }}</h2>
                            <button class="btn primary-button custom-button mt-1" (click)="changeToAdmin()">{{ locale('locale_key.general.buttons.open') }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2" *ngIf="stateService.activeWorkspace && stateService.activeWorkspace.companyId == 'ADMIN'">
                  <h4 class="text-center m-auto">{{ locale('locale_key.general.workspace_switcher.active_workspace.title') }}</h4>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12 col-12 d-flex justify-content-center align-content-center my-auto mx-2" style="height: 84vh">
        <div class="w-100 h-75 d-flex flex-column justify-content-center align-content-center my-auto">
          <div class="row text-start w-50 d-flex flex-column align-content-start">
            <input type="search" class="ms-3 form-control w-50 mb-3" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter()" [(ngModel)]="searchKeyword" />
          </div>
          <div class="content-body w-100 row my-auto vertical-scroll">
            <ng-container *ngFor="let workspace of filteredCompanies; let i = index">
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" *ngIf="!(stateService.activeWorkspace && workspace.companyId == stateService.activeWorkspace.companyId && 
              workspace.affiliationRole== stateService.activeWorkspace.affiliationRole) && !(loginUser.isAdmin && workspace.companyType == 'SUPPLIER')">
                <div class="card mb-4">
                  <div class="card-body">
                    <div class="card-content">
                      <h2 [innerHTML]='locale("locale_key.general.workspace_switcher.company_as_role.title", {companyName: workspace.companyName , 
                        affiliationRole: workspace.affiliationRole == "DATA_OWNER" ? this.locale("locale_key.general.user_role.data_reporter") :  this.locale("locale_key.general.user_role.sustainability_manager")})'></h2>
                      <div class="row mb-2" [ngStyle]="{'visibility': workspace.affiliationRole == 'DATA_OWNER' ? 'hidden' : 'visible'}">
                        <div class="col-10">
                          <div class="d-inline-block"><i class="la la-inbox mt-1" style="position:relative; top:2px;"></i></div>
                          <h4 class="ms-2">{{ locale('locale_key.general.workspace_switcher.to_review.title') }}</h4>
                        </div>
                        <div class="col-2 mt-2">          
                          <div class="d-flex justify-content-center align-content-center" [ngClass]="{
                            'round-pink m-auto': workspace.noOfToReviewRequests > 0
                          }"><h4 class="nr my-auto" [ngClass]="{
                            'white': workspace.noOfToReviewRequests > 0
                          }">{{workspace.noOfToReviewRequests}}</h4></div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-10">
                          <div class=" d-inline-block">
                            <i class="la la-envelope"  style="position:relative; top:2px;"></i>
                          </div>
                          <h4 class="ms-2">{{ locale('locale_key.general.workspace_switcher.new_activities.title') }}</h4>
                        </div>
                        <div class="col-2 mt-2">
                          <div class="d-flex justify-content-center align-content-center m-auto" [ngClass]="{
                            'round-pink': workspace.noOfUnreadActivities > 0
                          }"><h4 class="nr my-auto" [ngClass]="{
                            'white': workspace.noOfUnreadActivities > 0
                          }">{{workspace.noOfUnreadActivities}}</h4></div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-10">
                          <span class=" d-inline-block"> <i class="la la-exclamation-circle " style="position:relative; top:2px;"></i></span>
                          <h4 class="ms-2">{{ locale('locale_key.general.workspace_switcher.upcoming_deadlines.title') }}</h4>
                        </div>
                        <div class="col-2 mt-2">
                          <div class="d-flex justify-content-center align-content-center m-auto" [ngClass]="{
                            'round-pink': workspace.noOfUpcomingDeadlines > 0
                          }"><h4 class="nr my-auto" [ngClass]="{
                            'white': workspace.noOfUpcomingDeadlines > 0
                          }">{{workspace.noOfUpcomingDeadlines}}</h4></div>
                        </div>
                      </div>
                      <div class="text-center">
                        <button class="btn primary-button custom-button mt-3" (click)="changeWorkspace(workspace)">{{ locale('locale_key.general.buttons.open') }}
                        </button>
                      </div>
                      <ng-container *ngIf="!loginUser.isAdmin && requireLogin(workspace)">
                        <div class="card-overlay"></div>
                        <div class="text-center">
                          <button *ngIf="isPasswordLoginRequired(workspace)" class="btn tertiary-button transparent-button pt-0 mt-0" (click)="loginToWorkspace({type:'password',workspace})"><i class="la la-key me-2" ></i>{{ locale("locale_key.general.workspace_switcher.login_with_password") }}</button>                        
                          <div id="googleBtn" *ngIf="isGoogleLoginRequired(workspace)" class="customGPlusSignIn text-center transparent-button" (click)="loginToWorkspace({type:'google',workspace})">
                            <img src="../../../assets/img/logos/google_logo.png" style="height:30px; width:30px;z-index:-1"/>
                            <span class="buttonText text-center mx-auto my-auto my-auto">{{ locale('locale_key.general.workspace_switcher.login_with_google.title') }}</span>
                          </div>                        
                          <div id="microsoftBtn" *ngIf="isMicrosoftLoginRequired(workspace)" class="customGPlusSignIn text-center transparent-button" (click)="loginToWorkspace({type:'microsoft',workspace})">
                            <img src="../../../assets/img/logos/microsoft_logo.jpg" class="ms-3 my-auto" style="height:27px; width:27px;z-index:-1"/>
                            <span class="buttonText text-center mx-auto my-auto pt-1 ps-1">{{ locale('locale_key.general.workspace_switcher.login_with_microsoft.title') }}</span>
                          </div>
                          
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>          
            </ng-container>                 
          </div>
        </div>
      </div>
      <div class="col-12 float-end mb-4 mt-0 pt-0 px-4 pb-4">
        <img class="brand-logo img-responsive img-fluid float-end" src="/assets/img/logos/SL_Logo_gradient@2x.png" style="width: 10rem" routerLink="/ws/home" />
      </div>
    </div>
  </div>
</div>
