import { AbstractSignInMethodFe } from "../signInMethod/AbstractSignInMethodFe"
import { SignInMethodConversionFe } from "../signInMethod/SignInMethodConversionFe"
import { AccessibleCompanyFe } from "./AccessibleCompanyFe"

export class LoginUserFe {
  token: string
  userId: string
  firstName: string
  lastName: string
  loginTimeEmail: string
  isAdmin: boolean
  activeWorkspaceCompanyId: string | null
  activeWorkspaceRoleType: string | null
  accessibleCompanies: AccessibleCompanyFe[]
  language: string
  signedInMethod: AbstractSignInMethodFe
  emailLanguage: string
  passwordUpdateTimeStamp: Date | null

  constructor(  token: string,
    userId: string,
    firstName: string,
    lastName: string,
    loginTimeEmail: string,
    isAdmin: boolean,
    activeWorkspaceCompanyId: string | null,
    activeWorkspaceRoleType: string | null,
    accessibleCompanies: AccessibleCompanyFe[],
    language: string,
    signedInMethod: AbstractSignInMethodFe,
    emailLanguage: string,
    passwordUpdateTimeStamp: Date | null
  ) { 
    this.token = token
    this.userId = userId
    this.firstName = firstName
    this.lastName = lastName
    this.loginTimeEmail = loginTimeEmail
    this.isAdmin = isAdmin
    this.activeWorkspaceCompanyId = activeWorkspaceCompanyId
    this.activeWorkspaceRoleType = activeWorkspaceRoleType
    this.accessibleCompanies = accessibleCompanies.sort((ac1, ac2) => ac1.companyName.localeCompare(ac2.companyName))
    this.language = language
    this.signedInMethod = signedInMethod
    this.emailLanguage = emailLanguage
    this.passwordUpdateTimeStamp = passwordUpdateTimeStamp
  }

  public nameInitials(): string {
    return this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase()
  }

  public static fromTransfer(transfer: any): LoginUserFe {
    let token = transfer.token
    let userId = transfer.userId
    let firstName = transfer.firstName
    let lastName = transfer.lastName
    let loginTimeEmail = transfer.loginTimeEmail
    let isAdmin = transfer.isAdmin
    let activeWorkspaceCompanyId = transfer.activeWorkspaceCompanyId
    let activeWorkspaceRoleType = transfer.activeWorkspaceRoleType
    let accessibleCompanies = transfer.accessibleCompanies.map(c => AccessibleCompanyFe.fromTransfer(c))
    let language = transfer.language
    let signedInMethod = SignInMethodConversionFe.fromTransfer(transfer.signedInMethod)
    let emailLanguage = transfer.emailLanguage
    let passwordUpdateTimeStamp = transfer.passwordUpdateTimeStamp
    let user = new LoginUserFe(token, userId, firstName, lastName, loginTimeEmail, isAdmin, activeWorkspaceCompanyId, activeWorkspaceRoleType, accessibleCompanies, language, signedInMethod, emailLanguage, passwordUpdateTimeStamp)
    return user
  }
}