import { StringUtilFe } from 'src/app/utils/StringUtilFe'

export class ManagerInvitation_AddFe {
  clientCompanyId: string
  clientCompanyName: string
  managerFirstName: string
  managerLastName: string
  managerEmail: string
  uuid: string
  link: string
  emailLanguage: string
  includeEnglish: boolean

  constructor(uuid: string, clientCompanyId: string, clientCompanyName: string, managerFirstName: string, managerLastName: string, primaryContactEmail: string, emailTitle: string, emailBody: string, link: string,   emailLanguage: string,
    includeEnglish: boolean) {
    this.clientCompanyName = clientCompanyName
    this.clientCompanyId = clientCompanyId
    this.managerFirstName = managerFirstName
    this.managerLastName = managerLastName
    this.managerEmail = StringUtilFe.trimEmail(primaryContactEmail)
    this.uuid = uuid
    this.link = link
    this.emailLanguage = emailLanguage
    this.includeEnglish = includeEnglish
  }
}
