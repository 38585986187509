import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DirectEntryInfoFe } from 'src/app/model/directEntry/DirectEntryInfoFe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrganizationInfoFe } from 'src/app/model/organization/OrganizationInfoFe';
import { OrganizationEntityFe } from 'src/app/model/organization/OrganizationEntityFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { DataGridColumnType } from '../projects/data-grid-ui/model/DataGridColumnType';

@Component({
  selector: 'revise-organization',
  templateUrl: './ReviseOrganizationModal.html',
  styleUrls: ['./ReviseOrganizationModal.scss']
})
export class ReviseOrganizationModal extends AbstractLanguageComponent {
  @Input() mode: string = 'info' //info, upgrade
  @Input() orgInfo: OrganizationInfoFe
  @Output() isInfoConfirmed = new EventEmitter<boolean>();
  @Output() isUpgradeConfirmed = new EventEmitter<boolean>();

  modalRef: BsModalRef
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE;

  constructor(modalRef: BsModalRef, languageService: LanguageService, private responsive: ResponsiveService) { 
    super(languageService)
    this.modalRef = modalRef
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize=screenSize;
    })
    this.screenSize = responsive.currentScreenWidthSize;
  }
  
  infoConfirmed() {
    this.isInfoConfirmed.emit(true); 
  }

  upgradeConfirmed() {
    this.isUpgradeConfirmed.emit(true); 
  }

  cancel() {
    if (this.mode == 'info') {
      this.isInfoConfirmed.emit(false); 
    } else if (this.mode == 'upgrade') {
      this.isUpgradeConfirmed.emit(false)
    }
  }

  entitiesWithDeletedAttributes(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => e.deletedAttributes.length > 0)
    return entities
  }

  deletedAttributeListOf(entity: OrganizationEntityFe): string {
    entity.deletedAttributes.forEach(a=>a.datatype == DataGridColumnType.STRING? "text": (a.datatype == DataGridColumnType.NUMERIC? "number": (a.datatype == DataGridColumnType.DATE? "time": "yes/no")))
    let attrs = entity.deletedAttributes.map(a => `${a.label} (${a.datatype})`).join(', ')
    return attrs
  }

  newEntities(): OrganizationEntityFe[] {
    let entiies = this.orgInfo.entities.filter(e => e.deployed == false)
    return entiies
  }

  entieisWithAddedAttribues(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => {
      let newAttrs = e.attributes.filter(a => a.deployed == false)
      return (newAttrs.length > 0)
    })
    return entities
  }

  entitiesWithDeletedParents(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => e.deletedParents.length > 0)
    return entities
  }
  
  entitiesWithAddedParents(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => e.addedParents.length > 0)
    return entities
  }

  renamedEntities(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => !e.labelMatch)
    return entities
  }

  entieisWithRenamedAttribues(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => {
      let renamedAttrs = e.attributes.filter(a => a.labelMatch == false)
      return (renamedAttrs.length > 0)
    })
    return entities
  }

  entieisWithNewTypeAttribues(): OrganizationEntityFe[] {
    let entities = this.orgInfo.entities.filter(e => {
      let newTypeAttrs = e.attributes.filter(a => a.datatypeMatch == false)
      return (newTypeAttrs.length > 0)
    })
    return entities
  }

  newAttributeListOf(entity: OrganizationEntityFe): string {
    entity.deletedAttributes.forEach(a=>a.datatype == DataGridColumnType.STRING? "text": (a.datatype == DataGridColumnType.NUMERIC? "number": (a.datatype == DataGridColumnType.DATE? "time": "yes/no")))
    let attrs = entity.attributes.filter(a => a.deployed == false).map(a => a.label).join(', ')
    return attrs
  }

  renamedAttributeListOf(entity: OrganizationEntityFe): string {
    let attrs = entity.attributes.filter(a => a.labelMatch == false).map(a => `${a.oldLabel} to ${a.label}`).join(', ')
    return attrs
  }

  reTypedAttributeListOf(entity: OrganizationEntityFe): string {
    let attrs = entity.attributes.filter(a => a.datatypeMatch == false).map(a => `${a.label} from ${a.oldDatatype} to ${a.datatype}`).join(', ')
    return attrs
  }

}
