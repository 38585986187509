import { TextUtils } from "src/app/utils/text.utils"

export class CategoryOptionFeNew {
  constructor(
    public readonly field: string,
    public readonly label: string,
    public readonly values: string[]
    ) {}
  
  public getLabel(): string {
    let capField = TextUtils.capitalizeFirstLetterAllWords(this.label)
    return capField
  }
}