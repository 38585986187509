import { UserCredentialWithCustomClaims } from "src/app/services/GoogleIdentityPlatformService"

export class Contact_RegisterFe {
  invitationUuid: string
  correctedFirstName: string
  correctedLastName: string
  existedUserEmail: string | undefined
  existedUserPassword: string | undefined
  newUserPassword: string | undefined
  newUserConfirmedPassword: string | undefined
  googleUserCreds: UserCredentialWithCustomClaims
  
  constructor(
    invitationUuid: string, 
    correctedFirstName: string,
    correctedLastName: string,
    existedUserEmail: string | undefined,
    existedUserPassword: string | undefined,
    newUserPassword: string | undefined,
    newUserConfirmedPassword: string | undefined
    ) {
    this.invitationUuid = invitationUuid
    this.correctedFirstName = correctedFirstName
    this.correctedLastName = correctedLastName
    this.existedUserEmail = existedUserEmail
    this.existedUserPassword = existedUserPassword
    this.newUserPassword = newUserPassword
    this.newUserConfirmedPassword = newUserConfirmedPassword
  }

  public static fromTransfer(transfer: any): Contact_RegisterFe {
    let contact = new Contact_RegisterFe(
      transfer.invitationUuid,
      transfer.correctedFirstName,
      transfer.correctedLastName, 
      transfer.existedUserEmail,
      transfer.existedUserPassword,
      transfer.newUserPassword,
      transfer.newUserConfirmedPassword
    )
    return contact
  } 

}