import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractEntityFe } from "./AbstractEntityFe";
import { GlobalAttributeFe } from "./GlobalAttributeFe";

export class GlobalEntityFe extends AbstractEntityFe {
  attributes: GlobalAttributeFe[] = []

  constructor(jsonObj: any, languageService: LanguageService) {
    super(languageService)
    this.key = jsonObj["key"];
    this.label = jsonObj["label"];
    this.icon = jsonObj["icon"];
    this.color = jsonObj["color"];
    this.attributes = jsonObj["attributes"].map(a => new GlobalAttributeFe(a, languageService))
  }
}
