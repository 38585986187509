import { VariableStatementFe } from "./VariableStatementFe";
import { TwoOperandStatementFe } from "./TwoOperandStatementFe";
import { NumericalValueStatementFe } from "./NumericalValueStatementFe";
import { OneOperandStatementFe } from "./OneOperandStatementFe";
import { BooleanValueStatementFe } from "./BooleanValueStatementFe";
import { StringValueStatementFe } from "./StringValueStatementFe";
import { BooleanStatementFe } from "./BooleanStatementFe";
import { DateValueStatementFe } from "./DateValueStatementFe";
import { NullValueStatementFe } from "./NullValueStatementFe";

export class BooleanStatementBuilderFe {

  public static fromFormulaString(formula: string): BooleanStatementFe{
  switch (formula[0]) {
      case TwoOperandStatementFe.START_KEYWORD:
        return TwoOperandStatementFe.fromFormulaString(formula)
      case OneOperandStatementFe.START_KEYWORD:
        return OneOperandStatementFe.fromFormulaString(formula)    
      case StringValueStatementFe.START_KEYWORD:
        return StringValueStatementFe.fromFormulaString(formula)
      case NumericalValueStatementFe.START_KEYWORD:
        return NumericalValueStatementFe.fromFormulaString(formula) 
      case BooleanValueStatementFe.START_KEYWORD:
        return BooleanValueStatementFe.fromFormulaString(formula) 
      case VariableStatementFe.START_KEYWORD:
        return VariableStatementFe.fromFormulaString(formula)
      case DateValueStatementFe.START_KEYWORD:
        return DateValueStatementFe.fromFormulaString(formula)
      case NullValueStatementFe.START_KEYWORD:
        return NullValueStatementFe.fromFormulaString(formula)
      default:
        // throw new ServerError(ServerErrorCode.UNRECOGNIZED_QUESTION_TYPE);  
    }
  }

  public static fromTransfer(transfer: any): BooleanStatementFe {
    let type: string = transfer['type']
    switch (type) {
      case TwoOperandStatementFe.TYPE_KEY:
        return TwoOperandStatementFe.fromTransfer(transfer)
      case OneOperandStatementFe.TYPE_KEY:
        return OneOperandStatementFe.fromTransfer(transfer)    
      case StringValueStatementFe.TYPE_KEY:
        return StringValueStatementFe.fromTransfer(transfer)
      case NumericalValueStatementFe.TYPE_KEY:
        return NumericalValueStatementFe.fromTransfer(transfer) 
      case BooleanValueStatementFe.TYPE_KEY:
        return BooleanValueStatementFe.fromTransfer(transfer) 
      case VariableStatementFe.TYPE_KEY:
        return VariableStatementFe.fromTransfer(transfer)
      case DateValueStatementFe.TYPE_KEY:
        return DateValueStatementFe.fromTransfer(transfer)
      case NullValueStatementFe.TYPE_KEY:
        return NullValueStatementFe.fromTransfer(transfer)
    }
    
  }
}