import { SectionFe } from "../../request/questionnaire/SectionFe";
import { ActivityTypeFe } from "../ActivityTypeFe";
import { AbstractActivity_AddFe } from "./AbstractActivity_AddFe";

export class DisconnectSectionsActivity_AddFe extends AbstractActivity_AddFe {

  constructor(
    id: string,
    public submittedAnswerActivityId: string,
    public disconnectedSections: SectionFe[],
    message: string | null,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string
  ) {
    super(
      id,
      ActivityTypeFe.DISCONNECT_SECTIONS,
      message,
      false,
      requestTaskId,
      requestGroupId,
      requestGroupLabel
    )
  }
}
