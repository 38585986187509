import { RouteFe } from "src/app/route/RouteFe"
import { RouteServiceFe } from "src/app/route/RouteServiceFe"

export class SidebarItem {
  name: string;
  icon: string;
  openBefore: boolean = false
  open: boolean = false
  visible: boolean = true
  route: RouteFe;
  children: SidebarItem[];
  currentActivePage: RouteFe;

  constructor(name: string, icon: string, route: RouteFe, routeService: RouteServiceFe, children: SidebarItem[] = []) {
    this.name = name;
    this.icon = icon;
    this.route = route;
    this.children = children
    routeService.activeRouteSubject.subscribe((activeRoute: RouteFe | undefined) => {
      this.currentActivePage = activeRoute
    })
    this.currentActivePage = routeService.activeRoute
  }

  public isMenuHighlighted() {
    return (this.children.length > 0 && this.open) || (this.children.length == 0 && this.isActivePage())
  }

  setOpen() {
    this.openBefore = this.open
    this.open = true
  }

  setClose() {
    this.openBefore = this.open
    this.open = false
  }

  public getAnimation(): string {
    if (this.openBefore && !this.open) {
      return "400ms ease-out"
    } else if (!this.openBefore && this.open) {
      return "400ms ease-in"
    } else {
      return ""
    }
  }

  public getAnimationName(): string {
    if (this.openBefore && !this.open) {
      return "closed"
    } else if (!this.openBefore && this.open) {
      return "open"
    } else {
      return ""
    }
  }

  public isActivePage(): boolean {
    return (this.currentActivePage === this.route)
  }

}

