import { create, all } from "mathjs";
import { DepGraph } from "dependency-graph";
import _ from "lodash";
import { Formula } from "../../data-categories/calculation-builder/model/CalculationBuilderModels";
import { UnitMeasurementTypes } from "./UnitMeasurementType";
import { StateServiceFe } from "src/app/services/StateServiceFe";

type UnitConstructor = {
  name: string;
  symbol: string;
  leftSymbol?: string;
  rightSymbol?: string;
  definition?: string;
  formula?: Formula;
  offset?: string;
  aliases?: string;
  baseName?: string;
  description?: string;
  isCustom?: boolean;
  isConvertible?: boolean;
  isSI?: boolean;
  isMetric?: boolean;
  isUSCustomary?: boolean;
  isImperial?: boolean;
  isOther?: boolean;
  measurementType?: string;
  shouldDisplay?: boolean;
  isCommon?: boolean;
  isStandard?: boolean;
  isCompound?: boolean;
  prefixes?: string;
  hasPrefixes?: boolean;
  isBaseUnit?: boolean;
  baseUnit?: string;
};
export class UnitFe {
  name: string = "";
  symbol: string = "";
  leftSymbol: string = "";
  rightSymbol: string = "";
  definition: string = "";
  formula: Formula = [];
  offset: string = "";
  aliases: string = "";
  baseName: string = "";
  description: string = "";
  isCustom: boolean = false;
  isConvertible: boolean = false;
  isSI: boolean = false;
  isMetric: boolean = false;
  isUSCustomary: boolean = false;
  isImperial: boolean = false;
  isOther: boolean = false;
  measurementType: string = "";
  MTCode: string = "";
  shouldDisplay: boolean = false;
  isCommon: boolean = false;
  isStandard: boolean = false;
  isCompound: boolean = false;
  prefixes: string;
  hasPrefixes: boolean = false;
  isBaseUnit: boolean = true;
  baseUnit: string;
  prefixList: UnitFe[];

  // Pure frontend, not needed in backend
  collapsed: boolean = true; // determines if prefixes should be displayed on unit systems page

  constructor({
    name,
    symbol,
    leftSymbol,
    rightSymbol,
    definition,
    formula,
    offset,
    aliases,
    baseName,
    description,
    isCustom,
    isConvertible,
    isSI,
    isMetric,
    isUSCustomary,
    isImperial,
    isOther,
    measurementType,
    shouldDisplay,
    isCommon,
    isStandard,
    isCompound,
    prefixes,
    hasPrefixes,
    isBaseUnit,
    baseUnit
  }: UnitConstructor) {
    this.name = name;
    this.symbol = symbol;
    this.leftSymbol = leftSymbol ?? "";
    this.rightSymbol = rightSymbol ?? "";
    this.definition = definition ?? "";
    this.formula = formula ?? [];
    this.offset = offset ?? "";
    this.aliases = aliases ?? "";
    this.baseName = baseName ?? "";
    this.description = description ?? "";
    this.isCustom = isCustom ?? false;
    this.isConvertible = isConvertible ?? false;
    this.isSI = isSI ?? false;
    this.isMetric = isMetric ?? false;
    this.isUSCustomary = isUSCustomary ?? false;
    this.isImperial = isImperial ?? false;
    this.isOther = isOther ?? false;
    this.measurementType = measurementType ?? "";
    this.shouldDisplay = shouldDisplay ?? false;
    this.isCommon = isCommon ?? false;
    this.isStandard = isStandard ?? false;
    this.isCompound = isCompound ?? false;
    this.prefixes = prefixes ?? "";
    this.hasPrefixes = hasPrefixes ?? false;
    this.isBaseUnit = isBaseUnit ?? false;
    this.baseUnit = baseUnit ?? "";
  }

}
