import { Directive, OnInit, Renderer2, Input, ElementRef, Output, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[resizeColumn]'
})
export class ResizeColumnDirective implements OnInit {
  @Input('resizeColumn') resizable!: boolean;
  @Input() width!:string;
  @Input() index!: number;
  @Output() resized= new Subject<number>();

  startX!: number;
  startWidth!: number;
  column!: HTMLElement;
  table!: HTMLElement;
  pressed!: boolean;
  unlistenMouseDown!: () => void;
  unlistenMouseMove!: () => void;
  unlistenMouseUp!: () => void;

  constructor(private renderer: Renderer2, private el: ElementRef , private ngZone: NgZone) {
    this.column = this.el.nativeElement;
  }

  ngOnInit(): void{
    this.setInitialWidth();
    if (this.resizable) {
      const row = this.renderer.parentNode(this.column);
      const thead = this.renderer.parentNode(row);
      this.table = this.renderer.parentNode(thead);

      const resizer = this.renderer.createElement('span');
      this.renderer.addClass(resizer, 'resize-holder');
      this.renderer.appendChild(this.column, resizer);
      this.unlistenMouseDown = this.renderer.listen(resizer, 'mousedown', this.onMouseDown);
    }
  }

  onMouseDown = (event: MouseEvent) => {
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
    
    this.unlistenMouseMove = this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
    this.unlistenMouseUp = this.renderer.listen('document', 'mouseup', this.onMouseUp);
  }

  onMouseMove = (event: MouseEvent) => {
    if (this.pressed && event.buttons) {
      this.renderer.addClass(this.table, 'resizing');

      // Calculate width of column
      const width =
      this.startWidth + (event.pageX - this.startX);
      // event.pageX 

      const tableCells = Array.from(this.table.querySelectorAll('.table-row')).map(
        (row: any) => {
          return row.querySelectorAll(".tableData").item(this.index);
        }
      );

      // Set table header width
      this.renderer.setStyle(this.column, 'width', `${width}px`);
      this.renderer.addClass(this.column, 'resized');
      this.resized.next((event.pageX - this.startX));
      // Set table cells width
      for (const cell of tableCells) {
        this.renderer.setStyle(cell, 'width', `${width}px`);
      }
    }
  }

  setInitialWidth(){
    this.renderer.setStyle(this.column, 'width', `${this.width}`);
  }

  onMouseUp = (event: MouseEvent) => {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(this.table, 'resizing');
    }
    this.unlistenMouseMove();
    this.unlistenMouseUp();
  }

  ngOnDestroy(){
    if(this.unlistenMouseDown){
      this.unlistenMouseDown();
    }
  }
}
