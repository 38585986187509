import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractActivityFe } from "./AbstractActivityFe";
import { ActivityTypeFe } from "./ActivityTypeFe";

export class AcceptSubmissionActivityFe extends AbstractActivityFe {
  acceptedActivityId: string;
  icon = "la la-check-circle grad-green"
  isLoading = false

  constructor(
    id: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,
    requestId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    acceptedActivityId: string,
    languageService: LanguageService
  ) {
    super(
      id,
      ActivityTypeFe.ACCEPT_SUBMISSION,
      submitterAffId,
      submitterFirstName,
      submitterLastName,
      submitterCompanyId,
      submitterCompanyName,
      submissionDate,
      message,
      draft,
      readStatus,
      requestId,
      requestGroupId,
      requestGroupLabel,
      languageService
    )
    this.acceptedActivityId = acceptedActivityId;
    this.homePageLocaleKey = `locale_key.pages.data_request.activity.approved`
    this.homePageLocaleKeyDO = `locale_key.pages.data_request.activity.approved`
    this.activityHistoryLocaleKey = `locale_key.pages.data_request.activity.approved`
  }

  public static fromTransfer(transfer: any, languageService: LanguageService): AcceptSubmissionActivityFe {
    let activity = new AcceptSubmissionActivityFe(
      transfer.id,
      transfer.submitterAffId,
      transfer.submitterFirstName,
      transfer.submitterLastName,
      transfer.submitterCompanyId,
      transfer.submitterCompanyName,
      transfer.submissionDate,
      transfer.message,
      transfer.draft,
      transfer.readStatus,
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      transfer.acceptedActivityId,
      languageService
    )
    return activity;
  }  
}
