<div class="modal-header main-modal-header" style="border-bottom: none">
  <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
    <ng-container [ngSwitch]="action">
      <ng-container *ngSwitchCase="'global'">
        {{ locale('locale_key.pages.emission.search_in_emission_databases') }}
      </ng-container>
      <ng-container *ngSwitchCase="'custom'">
        {{ locale("locale_key.pages.emission.select_from_my_company") }}
      </ng-container>
      <ng-container *ngSwitchCase="'addCustom'">
        {{ locale('locale_key.pages.emission.add_custom_emission_factor') }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ locale('locale_key.pages.emission.emission_portal') }}
      </ng-container>
    </ng-container>
  </h4>

  <button type="button" class="btn close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body" style="min-height: 60vh;">
  <div class="loader-wrapper mt-5" *ngIf="isLoading">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="viewNo == 1">
      <h3 class="my-3 text-center">{{locale('locale_key.pages.emission.choose_your_action')}}</h3>
      <div class="d-flex">
        <div class="align-center m-2 box border">
          <i class="my-1 las la-search fs-2"></i>
          <p class="my-1 text-center">{{locale('locale_key.pages.emission.search_in_emission_databases')}}</p>
          <button class="my-1 btn custom-button primary-button" (click)="goToSelectGlobalEF()">
            {{locale('locale_key.general.search')}}
          </button>
        </div>
        <div class="align-center m-2 box border">
          <i class="my-1 las la-database fs-2"></i>
          <p class="my-1 text-center">{{ locale("locale_key.pages.emission.select_from_my_company") }}</p>
          <button class="my-1 btn custom-button primary-button" (click)="setAction('custom')">
            {{locale('locale_key.general.buttons.select')}}
          </button>
        </div>
        <div class="align-center m-2 box border">
          <i class="my-1 las la-plus fs-2"></i>
          <p class="my-1 text-center">{{locale('locale_key.pages.emission.add_custom_emission_factor')}}</p>
          <button class="my-1 btn custom-button primary-button" (click)="setAction('addCustom')">
            {{locale('locale_key.pages.emission.add_custom')}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="viewNo == 2">
      <ng-container *ngIf="action == 'global'">
        <div class="row" *ngIf="subAction == 'selectDB'">
          <div class="col tableCol database-picker">
            <div class="d-flex justify-content-between cursor-pointer">
              <span (click)="goBackToMenu()"><i class="las la-arrow-left pe-2"></i>{{locale('locale_key.general.action.back_to_menu')}}</span>

              <div class="search">
                <i class="las la-search icon"></i>
                <input type="text" placeholder="{{ locale('locale_key.pages.emission.type_to_search') }}" [(ngModel)]="globalDBsFilterQuery" (keyup)="filterGlobalDBs()">
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-4">
              <b>{{locale('locale_key.pages.emission.select_databases_you_want')}}</b>
            </div>
            <div class="select_deselect_all_container d-flex mb-2">
              <div class="select_all me-4" (click)="selectAllGlobalDBs()">{{ locale('locale_key.general.action.select_all') }}</div>
              <div class="deselect_all" (click)="deselectAllGlobalDBs()">{{ locale('locale_key.general.action.deselect_all') }}</div>
            </div>
            <div class="table-responsive">
              <table class="table global-dbs-table">
                <thead>
                  <tr>
                    <td></td>
                    <td>
                      <span>{{ locale("locale_key.pages.emission.database_name") }}</span>
                    </td>
                    <td>
                      <span>{{ locale('locale_key.pages.emission.applicable_time_period') }}</span>
                    </td>
                    <td>
                      <span>{{ locale('locale_key.pages.emission.applicable_geography') }}</span>
                    </td>
                    <td>
                      <span>{{ locale('locale_key.pages.emission.contained_scope') }}</span>
                    </td>
                    <td>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let db of filteredGlobalDBs" [class.active]="isGlobalDBSelected({globalDB:db})">
                    <td class="pb-2 pt-0 px-0">
                      <span class="p-2">
                        <input type="checkbox" (click)="toggleGlobalDBSelected({globalDB:db})" class="mt-3" [checked]="isGlobalDBSelected({globalDB:db})">
                      </span>
                    </td>
                    <td class="pb-2 pt-0 px-0">
                      <span class="p-2" (click)="toggleGlobalDBSelected({globalDB:db})">{{ db.name }}</span>
                    </td>
                    <td class="pb-2 pt-0 px-0">
                      <span class="p-2" (click)="toggleGlobalDBSelected({globalDB:db})">{{ getApplicableTimePeriod({globalDB:db}) }}</span>
                    </td>
                    <td class="pb-2 pt-0 px-0">
                      <span class="p-2" (click)="toggleGlobalDBSelected({globalDB:db})">{{ db.appliedGeography }}</span>
                    </td>
                    <td class="pb-2 pt-0 px-0">
                      <span class="p-2 scopes" (click)="toggleGlobalDBSelected({globalDB:db})">
                        {{ globalDBScopes[db.id].join(", ") }}
                      </span>
                    </td>
                    <td class="pb-2 pt-0 px-0">
                      <span class="p-2">
                        <i class="la la-info-circle detailIcon" (click)="toggleViewGlobalDB({globalDB:db})"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col detailsCol database-details" *ngIf="!isEmpty(globalDBToView)">
            <ef-details [emissionFactor]="fakeEmissionFactorToViewGlobalDb" [showOnlyDatabaseInfo]="true" [isEFDetailOpen]="false" (closeEfDetails)="closeViewGlobalDB()"></ef-details>
          </div>

        </div>
        <div class="row" *ngIf="subAction == 'selectEF'">
          <div class="col tableCol">
            <div class="d-flex justify-content-between cursor-pointer">
              <span (click)="goBackToMenu()"><i class="las la-arrow-left pe-2"></i>{{locale('locale_key.general.action.back_to_menu')}}</span>
            </div>
            <div class="selectedGlobalDBs d-flex px-4 py-2 my-2">
              <div class="info me-4">
                <div class="info_title">{{ locale('locale_key.pages.emission.searching_in_databases') }}</div>
                <div class="edit_selection" (click)="goToSelectGlobalEF()">
                  {{ locale('locale_key.pages.emission.edit_selection') }}
                </div>
              </div>
              <div class="dbs d-flex flex-wrap align-items-center">
                  <div class="db d-flex px-4 py-2" *ngFor="let id of getSelectedGlobalDBs()" (click)="toggleViewGlobalDBByID({id})">
                    <div class="db_lhs me-4">
                      <div class="db_name" title="{{ getSelectedDBName({id}) }}">{{ getSelectedDBName({id}) }}</div>
                      <div class="db_date">{{ getSelectedDBDate({id}) }}</div>
                    </div>
                    <div class="db_rhs">
                      <i class="la la-info-circle"></i>
                    </div>
                  </div>
                  <div class="see_more" *ngIf="(selectedGlobalDBs.size > selectedGlobalDBsSliceSize) && seeingLessGlobalDBs" (click)="seeMoreGlobalDBs()">{{ locale('locale_key.pages.task_portal.details.button.see_more') }}</div>
                  <div class="see_less" *ngIf="(selectedGlobalDBs.size > selectedGlobalDBsSliceSize) && seeingMoreGlobalDBs" (click)="seeLessGlobalDBs()">{{ locale('locale_key.pages.task_portal.details.button.see_less') }}</div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <b>{{locale('locale_key.pages.emission.click_on_the_emission_factor_to_select')}}</b>
              <i *ngIf="sourceMeasurementType && conversionMeasurementType">{{locale('locale_key.pages.emission.list_only_showing_emission_factors')}}</i>
            </div>
            <div class="table-responsive emissionFactorList">
              <cdk-virtual-scroll-viewport itemSize="50" style="height: 50vh;">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <td>
                        <span>{{locale('locale_key.pages.emission.emission_source')}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('sourceName')" (filterUpdated)="updateFilter('sourceName', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.source_unit')}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('sourceUnit')" (filterUpdated)="updateFilter('sourceUnit', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.conversion_unit')}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('conversionUnit')" (filterUpdated)="updateFilter('conversionUnit', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.conversion_factor')}}</span>
                      </td>
                      <td>
                        <span>{{locale("locale_key.pages.emission.database_name")}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('databaseName')" (filterUpdated)="updateFilter('databaseName', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.additional_details')}}</span>
                        <span data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="la la-search ms-2 cursor" style="font-size: 15px" [ngClass]="{'pink': searchKeyword && searchKeyword.length>0}"></i>
                        </span>
                        <div class="dropdown-menu ms-0 p-0" style="min-width: fit-content !important">
                          <input class="form-control mb-1" style="width: 10rem, border-none;" placeholder="Search" type="search" [(ngModel)]="searchKeyword" (keyup)="applyFilters()">
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *cdkVirtualFor="let ef of filteredGlobalEfs" (click)="selectEmissionFactor({ef})" class="cursor-pointer" [ngClass]="{'selected': selectedEf == ef}">
                      <td>
                        <span>{{ef?.sourceName}}</span>
                      </td>
                      <td>
                        <span>{{ef.sourceUnit}} <span class="measurementTypeSubscript">({{getMt(ef.sourceUnit)}})</span> </span>
                      </td>
                      <td>
                        <span>{{ef.conversionUnit}} <span class="measurementTypeSubscript">({{getMt(ef.conversionUnit)}})</span> </span>
                      </td>
                      <td>
                        <span>{{ef?.conversionFactor}}</span>
                      </td>
                      <td>
                        <span>{{ef?.databaseName}}</span>
                      </td>
                      <td>
                        <span *ngFor="let detail of ef.getAdditionDetails()" class="infoBox bg-orange border">
                          {{detail[0] | titlecase}} : {{detail[1] | titlecase}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </cdk-virtual-scroll-viewport>
            </div>
          </div>
          <div class="col detailsCol" *ngIf="!isEmpty(selectedEf)">
            <ef-details [emissionFactor]="selectedEf"></ef-details>
          </div>
          <div class="col detailsCol database-details" *ngIf="!isEmpty(globalDBToView)">
            <ef-details [emissionFactor]="fakeEmissionFactorToViewGlobalDb" [showOnlyDatabaseInfo]="true" [isEFDetailOpen]="false"></ef-details>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="action == 'custom'">
        <div class="row">
          <div class="col tableCol">
            <div class="d-flex justify-content-between cursor-pointer">
              <span (click)="goBackToMenu()"><i class="las la-arrow-left pe-2"></i>{{locale('locale_key.general.action.back_to_menu')}}</span>
            </div>
            <div class="d-flex align-items-center">
              <b>{{locale('locale_key.pages.emission.click_on_the_emission_factor_to_select')}}</b>
              <i *ngIf="sourceMeasurementType && conversionMeasurementType">{{locale('locale_key.pages.emission.list_only_showing_emission_factors')}}</i>
            </div>
            <div class="table-responsive emissionFactorList">
              <cdk-virtual-scroll-viewport itemSize="50" style="height: 50vh;">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <td>
                        <span>{{locale('locale_key.pages.emission.emission_source')}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('sourceName')" (filterUpdated)="updateFilter('sourceName', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.source_unit')}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('sourceUnit')" (filterUpdated)="updateFilter('sourceUnit', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.conversion_unit')}}</span>
                        <ef-filter [valuesToFilterFrom]="getFilterValues('conversionUnit')" (filterUpdated)="updateFilter('conversionUnit', $event)">
                        </ef-filter>
                      </td>
                      <td>
                        <span>{{locale('locale_key.pages.emission.conversion_factor')}}</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *cdkVirtualFor="let ef of filteredCustomEfs" (click)="selectedEf = ef" class="cursor-pointer" [ngClass]="{'selected': selectedEf == ef}">
                      <td>{{ef.sourceName}}</td>
                      <td>{{ef.sourceUnit}} <span class="measurementTypeSubscript">({{getMt(ef.sourceUnit)}})</span> </td>
                      <td>{{ef.conversionUnit}} <span class="measurementTypeSubscript">({{getMt(ef.conversionUnit)}})</span></td>
                      <td>{{ef.conversionFactor}}</td>
                    </tr>
                  </tbody>
                </table>
              </cdk-virtual-scroll-viewport>
            </div>
          </div>
          <div class="col detailsCol" *ngIf="!isEmpty(selectedEf)">
            <ef-details [emissionFactor]="selectedEf" [showDatabaseInfo]="false"></ef-details>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="action == 'addCustom'">
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between cursor-pointer">
              <span (click)="goBackToMenu()"><i class="las la-arrow-left pe-2"></i>{{locale('locale_key.general.action.back_to_menu')}}</span>
            </div>
            <b>{{locale('locale_key.pages.emission.complete_all_mandatory_fields')}}</b>
          </div>
        </div>
        <div [formGroup]="newEmissionFactor">
          <div class="row m-2">
            <div class="col-6 p-2">
              <b>{{locale('locale_key.pages.emission.emission_source')}}*</b>
              <input formControlName="sourceName" class="form-control" placeholder="{{locale('locale_key.pages.emission.example.fuel')}}"
                [ngClass]="{'is-invalid': showNewEmissionFactorErrors && newEmissionFactor.get('sourceName').errors && newEmissionFactor.get('sourceName').touched}">
              <span class="error_msg" *ngIf="showNewEmissionFactorErrors && newEmissionFactor.get('sourceName').errors && newEmissionFactor.get('sourceName').touched">
                {{locale('locale_key.pages.emission.emission_source_is_required')}}
              </span>
            </div>
            <div class="col-6 p-2">
              <b>{{locale('locale_key.pages.emission.conversion_factor')}}*</b>
              <input formControlName="conversionFactor" class="form-control" placeholder="{{locale('locale_key.pages.emission.example.number')}}"
                [ngClass]="{'is-invalid': showNewEmissionFactorErrors && newEmissionFactor.get('conversionFactor').errors && newEmissionFactor.get('conversionFactor').touched}">
              <span class="error_msg" *ngIf="showNewEmissionFactorErrors && newEmissionFactor.get('conversionFactor').errors && newEmissionFactor.get('conversionFactor').touched">
                {{locale('locale_key.pages.emission.conversion_factor_is_required')}}
              </span>
            </div>
          </div>
          <div class="row m-2 me-4">
            <b>{{locale('locale_key.pages.emission.emission_factor_unit')}}*</b>
            <div class="border mx-2">
              <div class="row m-2 py-2">
                <div class="col-6 p-2">
                  <b>{{locale('locale_key.pages.emission.conversion_unit')}}*</b>
                  <span class="d-flex align-items-center">
                    <unit-selector [mode]="'select'" [selectBy]="'symbol'"  (selectedUnitChanged)="conversionUnit = $event.symbol"
                      [source]="showNewEmissionFactorErrors && newEmissionFactor.touched && (!conversionUnit || conversionUnit == '') ? 'add_datapoint_modal is-invalid' : 'add_datapoint_modal'" 
                      [selectedUnitSymbol]='conversionUnit' style="width: 90%;" [includeMeasurementTypes]="getConversionUnitMeasurementType()"
                      [customUnits]="getCustomUnits()" [unitsByMeasurementType]="getUnitsByMeasurementType()">
                    </unit-selector>
                    <b class="ps-2 col">{{locale('locale_key.pages.data_categories_formula.per')}}</b>
                  </span>
                  <span class="error_msg" *ngIf="showNewEmissionFactorErrors && newEmissionFactor.touched && (!conversionUnit || conversionUnit == '')">
                    {{locale('locale_key.pages.emission.conversion_unit_is_required')}}
                  </span>
                </div>
                <div class="col-6 p-2">
                  <b>{{locale('locale_key.pages.emission.emission_source_unit')}}*</b>
                  <span class="d-flex align-items-center">
                    <unit-selector [mode]="'select'" [selectBy]="'symbol'"  (selectedUnitChanged)="sourceUnit = $event.symbol"
                      [source]="showNewEmissionFactorErrors && newEmissionFactor.touched && (!sourceUnit || sourceUnit == '') ? 'add_datapoint_modal is-invalid' : 'add_datapoint_modal'" 
                      [selectedUnitSymbol]='sourceUnit' style="width: 90%;" [includeMeasurementTypes]="getSourceUnitMeasurementType()"
                      [customUnits]="getCustomUnits()" [unitsByMeasurementType]="getUnitsByMeasurementType()">
                    </unit-selector>
                    <span class="ps-2 col">{{slocale('E.g.')}}<b class="ps-2">{{slocale('kgCO2/kWh')}}</b></span>
                  </span>
                  <span class="error_msg" *ngIf="showNewEmissionFactorErrors && newEmissionFactor.touched && (!sourceUnit || sourceUnit == '')">
                    {{locale('locale_key.pages.emission.emission_source_unit_is_required')}}
                  </span>
                </div>
                <i *ngIf="sourceMeasurementType && conversionMeasurementType">{{locale('locale_key.pages.emission.only_showing_units_compatible')}}</i>
              </div>
            </div>
          </div>
          <div class="row m-2 my-3">
            <b>{{locale('locale_key.pages.emission.conversion_factor_origin')}}</b>
            <p class="mb-0">{{locale('locale_key.pages.emission.explain_the_origin_emission_factor')}}</p>
            <textarea class="mx-2 form-control" formControlName="origin" cols="3" placeholder="{{locale('locale_key.pages.emission.enter_origin')}}"></textarea>
          </div>
          <div class="row m-2 my-3 info_warning">
            <div>
              <i class="la la-info-circle"></i>
              {{ locale("locale_key.pages.emission.once_the_definition_of_custom_factor") }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="spacer mt-2"></div>
  <div class="units-with-issues p-2" *ngIf="unitsWithIssues.length > 0">
    <strong>{{ locale("locale_key.pages.data_categories_formula.error") }}</strong> 
    <p>{{ locale("locale_key.pages.emission.system_unable_to_process", {units: unitsWithIssues.join(", ")}) }} </p>
  </div>
</div>
<div class="card-footer p-3 w-100 text-end mt-auto mb-0 text-wrap thin-border-t">
  <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
    <div style="background-color: var(--main-dark-color1)"></div>
    <div style="background-color: var(--main-dark-color1)"></div>
    <div style="background-color: var(--main-dark-color1)"></div>
  </div>
  <ng-container *ngIf="!loadingInProgress">
    <button type="button" class="btn tertiary-button float-start me-2 my-1" *ngIf="viewNo == 2 && action=='custom'" (click)="setAction('addCustom')">
      {{ locale("locale_key.pages.emission.add_new_emission_factor") }}
    </button>
    <button *ngIf="action!='addCustom'" type="button" class="btn secondary-button mx-2 custom-button" (click)="closeModal()">
      {{locale('locale_key.general.buttons.cancel') }}
    </button>
    <button *ngIf="action=='addCustom'" type="button" class="btn secondary-button mx-2 custom-button" (click)="startBackToMenu()">
      {{ locale('locale_key.general.toolbar.button.back') }}
    </button>
    <button *ngIf="viewNo == 2 && action === 'global' && subAction=='selectDB'" type="button" class="btn primary-button mx-2 custom-button" (click)="goToSelectEmissionFactor()" [disabled]="selectedGlobalDBs.size == 0">
      {{locale('locale_key.general.buttons.confirm') }}
    </button>
    <button *ngIf="viewNo == 2 && (action === 'global' && subAction === 'selectEF' || action === 'custom' || action === 'addCustom')" type="button" class="btn mx-2 custom-button-fit-size" [disabled]="!selectedEf && action !== 'addCustom'"  (click)="selectedEf = null; applyEf()" [ngClass]="action === 'addCustom' ? 'primary-button' : 'tertiary-button'">
      {{ action === 'addCustom'? locale('locale_key.general.action.save_and_apply'): locale('locale_key.pages.emission.apply_no_factor') }}
    </button>
    <button *ngIf="viewNo == 2 && (action === 'global' && subAction === 'selectEF' || action === 'custom')" type="button" [disabled]="!selectedEf" class="btn primary-button mx-2 custom-button" (click)="applyEf()">
      {{locale('locale_key.pages.emission.apply_factor') }}
    </button>
  </ng-container>
</div>

<ng-template #backToMenuModal>
  <div class="delete_category_modal p-3">
    <div class="header p-3 d-flex justify-content-between align-items-center">
      <h4 class="modal-title">{{ locale('locale_key.general.action.back_to_menu') }}</h4>
      <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="cancelBackToMenu()">
        <span aria-hidden="true"><i class="las la-times"></i></span>
      </button>
    </div>
    <div class="body pt-3 pb-4">
      <h5>
        {{ locale("locale_key.pages.emission.if_you_leave_this_page_changes_wont_saved") }}
      </h5>
    </div>
    <div class="footer">
      <div class="controls d-flex justify-content-end align-items-center">
        <button type="button" class="btn secondary-button mx-2" (click)="cancelBackToMenu()">
          {{ locale("locale_key.general.action.return_to_page") }}
        </button>
        <button type="button" class="btn primary-button mx-2 custom-button" (click)="confirmBackToMenu()">
          {{ locale("locale_key.general.action.exit_page") }}
        </button>
      </div>
    </div>
  </div>
</ng-template>