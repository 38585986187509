export class ManagerInvitation_Reject {
  reason: string | null
  explanation: string  | null
  uuid: string

  constructor(
    uuid: string,
    reason: string | null,
    explanation: string  | null
    ) {
      this.reason = reason
      this.explanation = explanation
      this.uuid = uuid
    }

  public static fromTransfer(transfer: any): ManagerInvitation_Reject {
    let rejectionReason = transfer['reason'];
    let rejectionExplanation = transfer['explanation'];
    let uuid = transfer['uuid'];
    let invitation = new ManagerInvitation_Reject(uuid, rejectionReason, rejectionExplanation)
    return invitation  
  }
}