import { BooleanStatementFe } from "./BooleanStatementFe";
import { BooleanStatementBuilderFe } from "./BooleanStatementBuilderFe";
import { OneOperandOperatorFe } from "./OneOperandOperatorFe";

export class OneOperandStatementFe extends BooleanStatementFe {
  public static TYPE_KEY = 'ONE_OP' 
  public type = OneOperandStatementFe.TYPE_KEY 
  public static START_KEYWORD = '{'
  public static END_KEYWORD = '}'
  
  public operand: BooleanStatementFe
  public operator: OneOperandOperatorFe
  
  constructor(operand: BooleanStatementFe, operator: OneOperandOperatorFe) {
    super()
    this.operand = operand
    this.operator = operator
  }

  public toFormulaString(): any {
    if (this.operand && this.operator) {
      let str = [`${OneOperandStatementFe.START_KEYWORD}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${this.operator.key}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${this.operand.toFormulaString()}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${OneOperandStatementFe.END_KEYWORD}`].join('');
      return str
    }
  }
  
  public static fromFormulaString(formula: string): OneOperandStatementFe {
    let prevIndex: any = OneOperandStatementFe.START_KEYWORD.length + BooleanStatementFe.SPACE.length;

    let nextSpaceIndex = formula.indexOf(BooleanStatementFe.SPACE, prevIndex);
    let operatorValue = formula.substring(prevIndex, nextSpaceIndex);
    let operator = OneOperandOperatorFe.opByKey(operatorValue)

    prevIndex = nextSpaceIndex + BooleanStatementFe.SPACE.length
    
    let operand = BooleanStatementBuilderFe.fromFormulaString(formula.substring(prevIndex))

    let statement = new OneOperandStatementFe(operand, operator)
    return statement
  }

  public evaluate(values: Map<string, any>): boolean | undefined {
    if (this.operator && this.operand) {
      let operandEvaluation = this.operand.evaluate(values)
      let formula = `${this.operator.symbol} ${operandEvaluation}`
      let ans = eval(formula)
      return ans
    } else {
      // throw new ServerError(ServerErrorCode.UNRECOGNIZED_ONE_OPERAND_OPERATOR)
    }
  }

  public static fromTransfer (transfer: any): OneOperandStatementFe {
    let operatorStr = transfer['operator']
    let operatorKey = operatorStr['key']
    let operator = OneOperandOperatorFe.opByKey(operatorKey)

    let operandStr = transfer['operator']
    let operand = BooleanStatementBuilderFe.fromTransfer(operandStr)
  
    let statement = new OneOperandStatementFe(operand, operator)
    return statement
  }
}
