<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <h3 class="col-12">{{ locale("locale_key.pages.insight_hub.insights_hub") | uppercase }}</h3>
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-5" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div
        class="flex-fill"
        [ngStyle]="{
          'max-width': screenSize.isXLargeSize() || screenSize.isLargeSize() || screenSize.isMediumSize() ? '95vw' : '100vw',
          important: true
        }"
      >
        <div class="loader-wrapper mt-5" *ngIf="isLoadingData">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <!-- Dropdown ended -->
        <div class="d-flex flex-row me-2" *ngIf="!isLoadingData">
          <ng-container *ngIf="!insightAction">
            <div class="flex-fill scroll" style="height: 90%">
              <div class="d-flex h-100 flex-row me-1">
                <div class="tab-content h-100 w-100">
                  <div class="w-100 h-100">
                    <div class="card p-2 h-100" style="border-bottom-left-radius: 0%; border-bottom-right-radius: 0%">
                      <div class="row mt-2">
                        <div class="col-md-6 mb-3 col-lg-3">
                          <input type="text" class="form-control" placeholder="{{ locale('locale_key.general.search') }}" #search (input)="applySearchFilter(search.value)" />
                        </div>
                        <!-- <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
                          <button type="button" class="form-control" id="group-button" dropdownToggle aria-controls="group-dropdown-menu" style="text-align: left;">
                            {{ locale('locale_key.pages.datahub.filter_group_by') }} {{ groupedBy ? groupedBy.name : locale('locale_key.pages.datahub.filter_none')}}
                          </button>
                          <div class="ms-3 dropdown-menu" id="group-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="group-button">
                            <a class="dropdown-item" (click)="groupedBy = null; group()">{{ locale('locale_key.pages.datahub.filter_none') }}</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" *ngFor='let option of groupedByOptions' (click)="group(option)">{{ option.name }}</a>
                          </div>
                        </div> -->
                        <div class="col-md-12 col-lg-6 mb-3 btn-group" dropdown container="body" [insideClick]="true">
                          <button type="button" class="form-control filter-button" id="filter-button" dropdownToggle aria-controls="filter-dropdown-menu" style="text-align: left">
                            <p class="d-inline m-0"><i class="la la-filter"></i> {{ locale("locale_key.pages.datahub.filter_dropdown") }}</p>
                            <p class="text-muted filter-text text-truncate col-7 m-0" [title]="getCustomFilterText()" *ngIf="customMultipleFilters.length > 0 || selectedStatus">:{{ getCustomFilterText() }}</p>
                          </button>
                          <div class="ms-3 dropdown-menu" id="filter-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="filter-button" [ngClass]="{ 'width-dropdown': screenSize.isXSmallSize() }">
                            <div class="accordion-body px-0 pt-0">
                              <div class="bg-light-gray col pt-3">
                                <div class="d-flex flex-row justify-content-between px-2">
                                  <span class="bold">{{ locale("locale_key.pages.datahub.applied_filters") }}</span>
                                  <a class="underline" (click)="clearAllFilters()">{{ locale("locale_key.pages.datahub.button.clear_all_filters") }}</a>
                                </div>
                                <div class="row px-2">
                                  <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">{{ locale("locale_key.pages.datahub.filter_none") }}</i>
                                  <p class="text-muted text-truncate" *ngIf="customMultipleFilters.length > 0 || selectedStatus">
                                    {{ getCustomFilterText() }}
                                  </p>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="accordion" id="accordionExample1">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#companyCollapse" aria-expanded="false" aria-controls="companyCollapse">
                                        aria-controls="companyCollapse">
                                        <span class="cursor" data-bs-toggle="tooltip" data-placement="top">{{ locale("locale_key.pages.data_hub.master_table.data_categories.title") }}</span>
                                      </button>
                                    </h2>
                                    <div id="companyCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                      <div class="accordion-body scroll" style="max-height: 20rem">
                                        <div class="row">
                                          <div class="col-md-6 col-lg-6 mb-3">
                                            <input type="text" class="form-control" placeholder="{{ locale('locale_key.general.search') }}" (input)="filterEntities(entitySearch.value)" #entitySearch />
                                          </div>
                                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                                            <a class="underline" (click)="selectAll('entityCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                            <a class="underline" (click)="deselectAll('entityCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                          </div>
                                        </div>
                                        <div *ngFor="let item0 of filteredEntities; let i = index">
                                          <input type="checkbox" class="form-check-input" class="entityCheckbox" id="{{ item0.key }}" [checked]="isCustomFilterSelected(item0)" (change)="changeCustomFilters($event, item0, 0)" />
                                          <i class="{{ taxonomyInfo.icon(item0) }} me-2 fs-5" [ngStyle]="{ color: taxonomyInfo.color(item0) }"></i>
                                          <label>{{ item0.getLabel() }}</label>
                                          <div class="ms-3">
                                            <div *ngFor="let item1 of taxonomyInfo.childrenSortedByOrdinal(item0.key); let i = index">
                                              <input type="checkbox" class="form-check-input" class="entityCheckbox" id="{{ item1.key }}" [checked]="isCustomFilterSelected(item1)" (change)="changeCustomFilters($event, item1, 1)" />
                                              <label>{{ item1.getLabel() }}</label>
                                              <div class="ms-3">
                                                <div *ngFor="let item2 of taxonomyInfo.childrenSortedByOrdinal(item1.key); let i = index">
                                                  <input type="checkbox" class="form-check-input" class="entityCheckbox" id="{{ item2.key }}" [checked]="isCustomFilterSelected(item2)" (change)="changeCustomFilters($event, item2, 2)" />
                                                  <label>{{ item2.getLabel() }}</label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
                          <button type="button" class="form-control" id="group-button" dropdownToggle aria-controls="group-dropdown-menu" style="text-align: left">
                            {{ locale("locale_key.pages.datahub.filter_sort_by") }} {{ sortedBy ? sortedBy.name : locale("locale_key.pages.datahub.filter_none") }}
                          </button>
                          <div class="ms-3 dropdown-menu" id="group-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="group-button">
                            <ng-container *ngFor="let option of sortedByOptions">
                              <div class="dropdown-divider" *ngIf="option.divider"></div>
                              <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="scroll" style="max-height: 79vh; max-width: 100% !important" *ngIf="!groupedBy">
                        <a
                          class="accordion accordion-button m-auto row-design py-3 flex-wrap d-flex flex-row flex-container align-content-center border-t"
                          id="insightsHeader"
                          data-bs-toggle="collapse"
                          href="#insights"
                          aria-expanded="false"
                          attr.aria-controls="insights"
                          style="max-width: 100vw !important"
                        >
                          <div class="flex-grow-1">
                            <h5 class="text-truncate dark-text d-inline" *ngIf="!searchKeyword || searchKeyword == ''">{{ locale("locale_key.pages.insight_hub.all_insights") }}</h5>
                            <h5 class="text-truncate dark-text d-inline" *ngIf="searchKeyword && searchKeyword != ''">{{ locale("locale_key.pages.datahub.search_result", { keyword: searchKeyword }) }}</h5>
                          </div>
                        </a>
                        <div id="insights" role="tabpanel" attr.aria-labelledby="insightsHeader" class="border show flex-container pb-5" style="overflow: hidden !important; background-color: var(--complementary-color1); max-width: 100% !important">
                          <div class="card-content" style="max-width: 100% !important">
                            <div class="row p-2 scroll-y" style="max-width: 100% !important">
                              <div
                                class="p-3"
                                *ngFor="let wrapper of filteredChartWrappers; let i = index"
                                [ngClass]="{ 'col-4': screenSize.isSize(['L', 'XL']), 'col-6': screenSize.isSize(['M']), 'col-11 mx-auto': screenSize.isSize(['XS', 'S']) || selectedChartWrapper == wrapper }"
                              >
                                <div class="card" *ngIf="!wrapper.hasError" [ngClass]="{ newKPIBorder: isNewInsight(wrapper) }">
                                  <div *ngIf="isNewInsight(wrapper)" class="newKPI"></div>
                                  <div class="insight">
                                    <div class="mx-3 mt-3" [ngClass]="{ 'mb-3': selectedChartWrapper == wrapper }">
                                      <div class="row">
                                        <div class="col-11">
                                          <h5 class="bold">{{ wrapper.insight.title }}</h5>
                                          <p [ngClass]="{ truncate: !selectedChartWrapper }">
                                            <ng-container *ngFor="let entity of wrapper.insight.entities; let first = first; let last = last">
                                              <ng-container *ngIf="!first">,</ng-container>
                                              <i class="fs-5 {{ taxonomyInfo.icon(entity) }}"></i>
                                              {{ entity.getTaxonomyTitle(taxonomyInfo) }}
                                            </ng-container>
                                          </p>
                                        </div>
                                        <div class="col-1" *ngIf="selectedChartWrapper == wrapper">
                                          <i class="las float-end me-3 cursor la-compress-arrows-alt fs-2" (click)="deselectChart()"></i>
                                        </div>
                                      </div>
                                      <div class="btn-toolbar d-flex justify-content-between" role="toolbar" *ngIf="selectedChartWrapper == wrapper">
                                        <div class="d-flex flex-row-reverse p-0 flex-grow-1 m-0 make-col">
                                          <ul class="list-inline m-0 make-col">
                                            <a class="me-3">{{ locale("locale_key.pages.insight_hub.select_view") }}</a>
                                            <li class="list-inline-item" *ngFor="let type of wrapper.options.chartTypes">
                                              <button
                                                type="button"
                                                class="btn btn-icon action-icon"
                                                data-bs-toggle="tooltip"
                                                data-placement="top"
                                                title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.bar') }}"
                                                show-delay="300"
                                                *ngIf="type.id.startsWith('bar')"
                                                (click)="wrapper.setting.setChartType(type); onChangedSetting()"
                                              >
                                                <i class="las la-chart-bar"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-icon action-icon"
                                                data-bs-toggle="tooltip"
                                                data-placement="top"
                                                title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.stack') }}"
                                                show-delay="300"
                                                *ngIf="type.id.startsWith('stack')"
                                                (click)="wrapper.setting.setChartType(type); onChangedSetting()"
                                              >
                                                <i class="las la-layer-group"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-icon action-icon"
                                                data-bs-toggle="tooltip"
                                                data-placement="top"
                                                title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.line') }}"
                                                show-delay="300"
                                                *ngIf="type.id.startsWith('line')"
                                                (click)="wrapper.setting.setChartType(type); onChangedSetting()"
                                              >
                                                <i class="las la-chart-line"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-icon action-icon"
                                                data-bs-toggle="tooltip"
                                                data-placement="top"
                                                title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.pie') }}"
                                                show-delay="300"
                                                *ngIf="type.id.startsWith('pie')"
                                                (click)="wrapper.setting.setChartType(type); onChangedSetting()"
                                              >
                                                <i class="las la-chart-pie"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-icon action-icon"
                                                data-bs-toggle="tooltip"
                                                data-placement="top"
                                                title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.doughnut') }}"
                                                show-delay="300"
                                                *ngIf="type.id.startsWith('doughnut')"
                                                (click)="wrapper.setting.setChartType(type); onChangedSetting()"
                                              >
                                                <i class="lab la-chrome"></i>
                                              </button>
                                              <button
                                                type="button"
                                                class="btn btn-icon action-icon"
                                                data-bs-toggle="tooltip"
                                                data-placement="top"
                                                title="{{ locale('locale_key.pages.insight_hub.table_view') }}"
                                                show-delay="300"
                                                *ngIf="type.id.startsWith('table')"
                                                (click)="wrapper.setting.setChartType(type); setKpiTable()"
                                              >
                                                <i class="las la-table"></i>
                                              </button>
                                            </li>
                                            <li class="ms-auto list-inline-item m-0">
                                              <a class="btn tertiary-button ms-auto" (click)="showInsightData(wrapper, $event)">
                                                <i class="la la-table" *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"></i>
                                                {{ locale("locale_key.pages.insight_hub.trace_data") }}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div class="btn-toolbar d-flex justify-content-between" role="toolbar" *ngIf="selectedChartWrapper == wrapper">
                                        <div class="d-flex flex-row p-0 flex-grow-1 m-0 make-col">
                                          <ul class="list-inline m-0 make-col">
                                            <a class="me-3">{{ locale("locale_key.pages.kpi_library.compare_on.title") }}</a>
                                            <li class="list-inline-item">
                                              <button data-bs-toggle="dropdown" type="button" class="btn btn-icon action-icon d-flex justify-content-between px-0" [ngClass]="{ 'w-100': screenSize.isXSmallSize() }">
                                                <span class="btn-min-width-5">{{ wrapper.setting.principalComparisionText() }}</span>
                                              </button>
                                              <ul class="dropdown-menu btn-min-width p-0" id="menuChoices" role="menu">
                                                <a class="dropdown-item" (click)="wrapper.setting.selectPrincipalComparisonTime(); onChangedSetting()" *ngIf="wrapper.insight.isTimed()">{{ locale("locale_key.pages.insight_hub.time") }}</a>
                                                <a class="dropdown-item" (click)="wrapper.setting.selectPrincipalCategoryFieldIndex(j); onChangedSetting()" *ngFor="let category of wrapper.options.categoryOptions; let j = index">{{
                                                  category.getLabel()
                                                }}</a>
                                              </ul>
                                            </li>
                                          </ul>
                                          <ul class="list-inline m-0 make-col" *ngIf="wrapper.insight.isTimed()">
                                            <a class="mx-3">{{ locale("locale_key.pages.data_request.data_requests_section.button.show_results") }}:</a>
                                            <li class="list-inline-item">
                                              <button data-bs-toggle="dropdown" type="button" class="btn btn-icon action-icon d-flex justify-content-between px-0" [ngClass]="{ 'w-100': screenSize.isXSmallSize() }">
                                                <span class="btn-min-width-5"> {{ locale("locale_key.insight-detail.time_options." + wrapper.setting.timeOption()?.timeScaleToAdverb().toLowerCase()) }} </span>
                                              </button>
                                              <ul class="dropdown-menu p-0 btn-min-width" id="menuChoices" role="menu">
                                                <a class="dropdown-item" *ngFor="let timeOption of wrapper.options.timeOptions; let timeOptionIndex = index" (click)="wrapper.setting.setTimeScaleIndex(timeOptionIndex); onChangedSetting()">{{
                                                  locale("locale_key.insight-detail.time_options." + timeOption.timeScaleToAdverb().toLowerCase())
                                                }}</a>
                                              </ul>
                                            </li>
                                          </ul>

                                          <ul class="list-inline mx-1 m-0 make-col" *ngIf="wrapper.insight.isTimed() && wrapper.setting.principalCategoryFieldIndex > -1">
                                            <li class="list-inline-item">
                                              <button data-bs-toggle="dropdown" type="button" class="btn btn-icon action-icon d-flex justify-content-between px-0">
                                                <span class="btn-min-width-3">{{ wrapper.setting.timeValue }} </span>
                                              </button>
                                              <ul class="dropdown-menu btn-min-width-5 p-0" id="menuChoices" role="menu">
                                                <a class="dropdown-item" *ngFor="let timeValue of wrapper.setting.timeValues()" (click)="wrapper.setting.setTimeValue(timeValue); onChangedSetting()">{{ timeValue }}</a>
                                              </ul>
                                            </li>
                                          </ul>

                                          <ul class="list-inline m-0 make-col" *ngFor="let categoryOption of wrapper.setting.nonPricipalCategoryOptions()">
                                            <a class="mx-3">{{ categoryOption.getLabel() }}</a>
                                            <li class="list-inline-item">
                                              <button data-bs-toggle="dropdown" type="button" class="btn btn-icon action-icon arrow" [ngClass]="{ 'w-100': screenSize.isXSmallSize() }">
                                                <span class="btn-min-width-5 float-start">{{ wrapper.setting.categoryValue(categoryOption.field) }}</span>
                                                <i class="las la-angle-down mt-1 float-end"></i>
                                              </button>
                                              <ul class="dropdown-menu p-0" id="menuChoices" role="menu">
                                                <li class="">
                                                  <a class="dropdown-item" *ngFor="let catVal of categoryOption.values" (click)="wrapper.setting.setCategoryValue(categoryOption.field, catVal); onChangedSetting()">{{ catVal }}</a>
                                                </li>
                                              </ul>
                                            </li>
                                          </ul>
                                          <ul class="list-inline m-0 make-col">
                                            <li class="list-inline-item insights-unit-switcher_container">
                                              <!-- [includeMeasurementTypes]="getInsightMeasurementTypeLimits({ wrapper })" -->
                                              <unit-selector
                                                [mode]="'select'"
                                                [selectBy]="'symbol'"
                                                [selectedUnitSymbol]="wrapper.insight.definition.unitSymbol"
                                                [source]="'insights-unit-switcher btn btn-icon action-icon'"
                                                [customUnits]="customUnits"
                                                [includeMeasurementTypes]="getInsightMeasurementTypeLimits({ wrapper })"
                                                (selectedUnitChanged)="insightUnitChanged({ newUnit: $event, wrapper })"
                                                [unitsByMeasurementType]="unitsByMeasurementType"
                                              ></unit-selector>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div class="d-flex flex-row">
                                        <div class="list-inline-item d-flex flex-column px-0 me-0" *ngIf="selectedChartWrapper == wrapper">
                                          <ul class="list-inline m-0">
                                            <div class="p-0">
                                              <li class="list-inline-item d-flex flex-column">
                                                <button
                                                  type="button"
                                                  class="btn btn-icon action-icon"
                                                  data-bs-toggle="tooltip"
                                                  data-placement="top"
                                                  title="{{ locale('locale_key.pages.kpi_library.tooltip.download_img') }}"
                                                  show-delay="300"
                                                  *ngIf="!wrapper.setting.chartType.id.startsWith('table')"
                                                  (click)="downloadImage(i)"
                                                >
                                                  <i class="las la-image"></i>
                                                </button>
                                                <div class="dropdown">
                                                  <button type="button" class="btn btn-icon action-icon" data-bs-toggle="dropdown" data-placement="top" title="{{ locale('locale_key.general.buttons.download_excel') }}" show-delay="300">
                                                    <i class="las la-file-alt"></i>
                                                  </button>
                                                  <ul class="dropdown-menu min-width-auto">
                                                    <li><a class="dropdown-item" (click)="downloadExcel('xlsx')">xlsx</a></li>
                                                    <li><a class="dropdown-item" (click)="downloadExcel('csv')">csv</a></li>
                                                  </ul>
                                                </div>
                                                <button
                                                  type="button"
                                                  class="btn btn-icon action-icon"
                                                  data-bs-toggle="tooltip"
                                                  data-placement="top"
                                                  title="{{ locale('locale_key.pages.kpi_library.tooltip.download_pdf') }}"
                                                  show-delay="300"
                                                  *ngIf="!wrapper.setting.chartType.id.startsWith('table')"
                                                  (click)="downloadPDF(i)"
                                                >
                                                  <i class="la la-file-pdf-o"></i>
                                                </button>
                                              </li>
                                            </div>
                                            <div class="p-0">
                                              <li class="list-inline-item d-flex flex-column">
                                                <button
                                                  type="button"
                                                  class="btn btn-icon action-icon"
                                                  data-bs-toggle="tooltip"
                                                  data-placement="top"
                                                  title="{{ locale('locale_key.pages.kpi_library.tooltip.edit') }}"
                                                  show-delay="300"
                                                  (click)="editInsight(wrapper)"
                                                >
                                                  <i class="las la-edit"></i>
                                                </button>
                                                <button
                                                  type="button"
                                                  class="btn btn-icon action-icon"
                                                  data-bs-toggle="tooltip"
                                                  data-placement="top"
                                                  title="{{ locale('locale_key.pages.kpi_library.tooltip.duplicate') }}"
                                                  show-delay="300"
                                                  (click)="duplicateInsight(wrapper)"
                                                >
                                                  <i class="la la-copy"></i>
                                                </button>
                                                <button
                                                  type="button"
                                                  class="btn btn-icon action-icon"
                                                  data-bs-toggle="tooltip"
                                                  data-placement="top"
                                                  title="{{ locale('locale_key.pages.kpi_library.tooltip.delete') }}"
                                                  show-delay="300"
                                                  (click)="delete(dashboardsIncludeInsightModal, wrapper)"
                                                >
                                                  <i class="las la-trash"></i>
                                                </button>
                                              </li>
                                            </div>
                                          </ul>
                                        </div>
                                        <div id="parent-canvas{{ i }}" [ngClass]="{ col: selectedChartWrapper != wrapper }" class="card-body ps-2 w-100" #canvascontainer>
                                          <canvas id="canvas{{ i }}" #canvas [hidden]="wrapper.setting.chartType.id.startsWith('table')" [ngClass]="{ selectedChartWrapper: selectedChartWrapper == wrapper }"></canvas>
                                          <datagrid-table-editor [dataGrid]="wrapper.insight.kpiDataGridService" *ngIf="wrapper.setting.chartType.id.startsWith('table')"></datagrid-table-editor>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="overlay" *ngIf="selectedChartWrapper != wrapper">
                                      <span class="circle" (click)="selectChart(wrapper)">
                                        <i class="las cursor la-expand-arrows-alt fs-2"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="border-t mb-3 p-1" *ngIf="selectedChartWrapper != wrapper">
                                    <li class="list-inline-item float-end fs-5 d-flex flex-row">
                                      <i class="las la-edit cursor" title="{{ locale('locale_key.pages.kpi_library.tooltip.edit') }}" (click)="editInsight(wrapper)"></i>
                                      <i class="la la-copy cursor" title="{{ locale('locale_key.pages.kpi_library.tooltip.duplicate') }}" (click)="duplicateInsight(wrapper)"></i>
                                      <i class="las la-trash cursor" title="{{ locale('locale_key.pages.kpi_library.tooltip.delete') }}" (click)="delete(dashboardsIncludeInsightModal, wrapper)"></i>
                                    </li>
                                  </div>
                                </div>
                                <div class="card hasError" id="item-{{ i }}" *ngIf="wrapper.hasError">
                                  <div class="row mt-3 mx-3">
                                    <div class="col-11">
                                      <h5 class="bold">{{ wrapper.insight.title }}</h5>
                                    </div>
                                  </div>
                                  <div class="d-flex flex-row" style="margin-top: auto; margin-bottom: auto">
                                    <div class="errorCard">
                                      <div class="icon-background d-inline mt-2 mx-2" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidtext-white">
                                        <i class="ft-alert-circle text-white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 2px; top: 1px"></i>
                                      </div>
                                      <span style="text-align: center">
                                        {{ locale("locale_key.pages.kpi_library.kpi_definition_error_message") }}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="border-t mb-3 p-1" *ngIf="selectedChartWrapper != wrapper">
                                    <li class="list-inline-item float-end fs-5 d-flex flex-row">
                                      <i class="las la-edit cursor" title="{{ locale('locale_key.pages.kpi_library.tooltip.edit') }}" (click)="editInsight(wrapper)"></i>
                                      <i class="las la-trash cursor" title="{{ locale('locale_key.pages.kpi_library.tooltip.delete') }}" (click)="delete(dashboardsIncludeInsightModal, wrapper)"></i>
                                    </li>
                                    <div id="parent-canvas{{ i }}" class="card-body width-90-per p-0" #canvascontainer>
                                      <canvas id="canvas{{ i }}" width="0" height="0" #canvas></canvas>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="insightAction">
            <div class="col flex-column w-100">
              <insight-detail *ngIf="insightAction == 'add'" (closeEvent)="closeInsight()"></insight-detail>
              <insight-detail [edit]="true" [insightDef]="selectedChartWrapper.insight" *ngIf="insightAction == 'edit'" (closeEvent)="closeInsight()"></insight-detail>
              <insight-detail [duplicate]="true" [insightDef]="selectedChartWrapper.insight" *ngIf="insightAction == 'duplicate'" (closeEvent)="closeInsight()"></insight-detail>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #dashboardsIncludeInsightModal>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel10">
      <i class="la la-exclamation-circle" style="font-size: 1.5rem; color: var(--warning-color); position: relative; left: -2px; top: 2px"></i>
      {{ locale("locale_key.pages.kpi_library.delete_kpi_modal.title", { kpiTitle: insightToDelete.title }) }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal(true)" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5 *ngIf="dashboardsIncludingInsight.length === 0">{{ locale("locale_key.pages.kpi_library.delete_kpi_modal.desc", { kpiTitle: insightToDelete.title }) }} ?</h5>
    <h5 *ngIf="dashboardsIncludingInsight.length == 1">{{ locale("locale_key.pages.kpi_library.delete_kpi_modal.desc.present_in_1_dashboard", { kpiTitle: insightToDelete.title }) }}</h5>
    <h5 *ngIf="dashboardsIncludingInsight.length > 1">{{ locale("locale_key.pages.kpi_library.delete_kpi_modal.desc.present_in_multiple_dashboards", { kpiTitle: insightToDelete.title, noOfDashboards: dashboardsIncludingInsight.length }) }}</h5>
    <p *ngIf="dashboardsIncludingInsight.length > 0">{{ locale("locale_key.pages.kpi_library.delete_kpi_modal.desc2") }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!inProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal(true)">{{ locale("locale_key.pages.kpi_library.delete_kpi_modal.button.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="deleteInsight(insightToDelete)">{{ locale("locale_key.pages.kpi_library.delete_kpi_modal.button.delete") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #insightModal>
  <insight-detail></insight-detail>
</ng-template>

<ng-template #traceData>
  <app-trace-data [chartWrapper]="selectedChartWrapper"></app-trace-data>
</ng-template>
