import { SidebarItem } from "./SidebarItem";

export class SidebarItemGroup {
  items: SidebarItem[]
  public relevantWorkspace: () => boolean

  constructor(items: SidebarItem[], relevantWorkspace) {
    this.items = items
    this.relevantWorkspace = relevantWorkspace
  }
  public closeAll() {
    this.items.forEach(item => {
      item.open = false
    })
    this.relevantWorkspace = this.relevantWorkspace;
  }

  public setVisibleAll(visible: boolean) {
    this.items.forEach(item => {
      item.visible = visible
    })
  }

  // public resetActivePages() {
  //   this.items.forEach(item => {
  //     item.resetActivePages();
  //   })
  // }

  public hasVisibleItem(): boolean {
    return this.items.some(item => item.visible)
  }
}