import { DashboardChartFe } from "../DashboardChartFe";

export class Dashboard_AddFe {
  public id: string;
  public name: string;
  public description: string;
  public charts: DashboardChartFe[] = []

  constructor (
    id: string,
    name: string,
    description: string,
    charts: DashboardChartFe[],
  ) {
    this.id = id;
    this.name = name
    this.description = description
    this.charts = charts
  }

  public static toTransfer (dashboard: Dashboard_AddFe): Dashboard_AddFe {
    let transfer = new Dashboard_AddFe (
      dashboard.id,
      dashboard.name,
      dashboard.description,
      dashboard.charts.map(c => c.toTransfer())
    ) 
    return transfer
  }
}