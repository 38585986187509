<div class=" m-0 box-border">
  <div class="card-header pb-0">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-0 pt-0 m-auto d-flex flex-row justify-content-center">
          <div class="col-lg-2 col-md-12 mt-0 pt-0 mx-0 px-0">
            <div class="mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <h6 class="mt-3 ms-0 ps-3 pb-1"><b>{{locale('locale_key.pages.file_upload_picker.data_extraction')}}</b></h6>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 mt-0 pt-0 mx-0 px-0">
            <div
              class="mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <div class="circle1 mx-0 px-0" [ngClass]="{'active-circle1': viewNo>=1}">1</div>
              <h6 class="mt-3 ms-0 ps-3 pb-1">{{locale('locale_key.pages.file_upload_picker.choose_a_file')}}</h6>
              <span class="ms-2 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 text-start mx-0 px-0">
            <div class="mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <div class="circle1 mx-0 px-0" [ngClass]="{'active-circle1': viewNo>=2}">2</div>
              <h6 class="mt-3 ms-0 ps-3 pb-1">{{locale('locale_key.pages.file_upload_picker.select_data.title')}}</h6>
              <span class="ms-2 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 text-start mx-0 px-0">
            <div class="mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <div class="circle1 mx-0 px-0" [ngClass]="{'active-circle1': viewNo>=3}">3</div>
              <h6 class="ms-0 mt-3 ps-3 pb-1">{{locale('locale_key.pages.file_upload_picker.match_requested_data')}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body p-3" *ngIf="!inProgress" style="max-height: 60vh; overflow: auto;">
    <ng-container *ngIf="viewNo == 1">
      <div class="p-3">
        <h6>{{locale('locale_key.pages.file_upload_picker.select_file')}}
          <i class="la la-info-circle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
          <div class="dropdown-menu arrow p-50 info-popover">
            <p>{{locale('locale_key.pages.file_upload_picker.select_file.description')}}</p>
          </div>      
        </h6>
      </div>
      <div class="card-body px-3 pt-0">
        <div class="nav nav-underline nav-tabs px-3">
          <ul class="nav nav-underline nav-tabs no-hover-bg nav-justified"
            style="width: 49%; border-bottom: none !important">
            <li class="nav-item"  *ngIf="allFiles.length > 0">
              <a class="nav-link active tab1" id="baseIcon-tab" data-bs-toggle="tab" aria-controls="tabIcon-1" href="#tabIcon-1" aria-expanded="true">{{locale('locale_key.pages.file_upload_picker.from_file_explorer')}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link tab2" [ngClass]="{active: allFiles.length == 0}" id="baseIcon-tab-2" data-bs-toggle="tab" aria-controls="tabIcon-2" href="#tabIcon-2" aria-expanded="false">{{locale('locale_key.pages.file_upload_picker.upload_new_file')}}</a>
            </li>
          </ul>
        </div>
        <div class="tab-content px-0 h-100">
          <div class="tab-pane active"  *ngIf="allFiles.length > 0" id="tabIcon-1" aria-expanded="true" aria-labelledby="baseIcon-tab">
            <div class="table-responsive">
              <table class="table table-striped" style="font-size: 0.8rem; font-weight: 400;">
                <thead>
                  <tr>
                    <th class="p-1" style="font-weight: 500;">{{locale('locale_key.pages.file_upload_picker.file_name')}}</th>
                    <th class="p-1" style="font-weight: 500;">{{locale('locale_key.pages.file_upload_picker.uploaded_date')}} </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let fileData of allFiles; let i = index">
                    <tr (click)="selectFile(fileData)" [ngClass]="{'unread': fileData.isSelected}"
                      *ngIf="fileData.isExcel() || fileData.isImage() || fileData.isPdf() || fileData.isZip()">
                      <th class="p-1" style="font-weight: 400;">
                        <i class="la {{fileData.type.icon}} margin-ft" [ngStyle]="{'color': fileData.type.color}"></i>{{ fileData.label }}
                      </th>
                      <th class="p-1" style="font-weight: 400;">{{ fileData.localTimeCreated() }}</th>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane" [ngClass]="{active: allFiles.length == 0}" id="tabIcon-2" aria-labelledby="baseIcon-tab-2">
            <div class="card m-0">
              <div class="modal-body">
                <div class="row mt-0 px-1">
                  <div class="col-sm-12">
                    <div class="card hoverable border-0 dropZone mt-0 pt-0 mb-1 pb-1">
                      <div class="card-body border border-dotted" id="dotted">
                        <input type="file" id="uploadCaptureInputFile" (change)="selectFilesForUpload($event)" title=" " />
                        <h5>{{locale('locale_key.pages.file_upload_picker.drag_and_drop')}}</h5>
                      </div>
                      <h5>{{ locale('locale_key.general.or') }}</h5>
                      <div class="row justify-content-center">
                        <input type="file" id="uploadCaptureInputFile" #fileInput style="display: none" (change)="selectFilesForUpload($event)" title=" "/>
                        <button id="browsefilebutton" (click)="fileInput.click()" class="btn btn-block custom-button"><span class="glyphicon glyphicon-upload"></span> {{locale('locale_key.pages.file_upload_picker.button.browse_files')}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="viewNo >= 2">
      <div class="card main-card m-0">
        <div class="p-3">
          <h6>{{locale('locale_key.pages.file_upload_picker.check_table')}}
            <i class="la la-info-circle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>{{locale('locale_key.pages.file_upload_picker.check_table.description')}}</p>
            </div>
            <a class="collapsed" (click)="isCollapse=!isCollapse" data-bs-toggle="collapse" href="#collapseDataGrid" aria-expanded="false" aria-controls="desc">
              <i class="las " [ngClass]="{'la-angle-down': !isCollapse, 'la-angle-up': isCollapse}"></i>
            </a>
          </h6>
          <div class="collapse ms-0 ps-0" id="collapseDataGrid">
            <datagrid-table-editor [dataGrid]="destTableService"></datagrid-table-editor>
          </div>
        </div>
        <hr>
        <div class="p-3">
          <h6 *ngIf="viewNo ==2">{{locale('locale_key.pages.file_upload_picker.select_data')}}
            <i class="la la-info-circle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>
                {{locale('locale_key.pages.file_upload_picker.select_data.description')}}
              </p>
            </div>
            <a class="" (click)="isCollapsed=!isCollapsed" data-bs-toggle="collapse" href="#collapseHeadline" aria-expanded="true" aria-controls="headline">
              <i class="las " [ngClass]="{'la-angle-up': isCollapsed, 'la-angle-down': !isCollapsed}"></i>
            </a>
          </h6>
          <p *ngIf="viewNo ==2" class="font-small-2 collapse show" id="collapseHeadline">
            {{locale('locale_key.pages.file_upload_picker.select_data.description_headline_row')}}
          </p>
        </div>
        <h6 *ngIf="viewNo ==3" class="ps-3">{{locale('locale_key.pages.file_upload_picker.select_data.where_to_insert_selected_columns')}}
          <i class="la la-info-circle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
          <div class="dropdown-menu arrow p-50 info-popover">
            <p>
             {{locale('locale_key.pages.file_upload_picker.select_data.where_to_insert_selected_columns.description')}} 
            </p>
          </div>
        </h6>
        <div class="px-3 pb-3">        
          <div *ngIf="selectedFile.inProgress()">
            <div class="loader-wrapper mt-5">
              <div class="loader-container">
                <div class="ball-grid-pulse loader-primary">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedFile.isMainViewer() && !selectedFile.inProgress()">
            <div *ngIf="selectedFile.isPdf() || selectedFile.isImage()">
              <div class="row">
                <div class="col-10 main-section p-0">
                  <div class="card-body p-0" style="height: 100%">
                    <data-extractor [imgExtractor]="this"></data-extractor>
                  </div>
                </div>
                <div class="col-2 tool-bar-col">
                  <button type="button" class="btn btn-icon action-icon crop-button mb-1 me-3" container="body" containerClass="tooltipClass"
                  data-bs-toggle="tooltip" data-placement="right" title="{{locale('locale_key.pages.file_upload_picker.data_extraction.button.tooltip.crop_section')}}"
                  (click)="convertToImg(selectedFile)" *ngIf="!selectedFile.isConvertedToImg"><i class="ft-crop"></i></button>
                  <button type="button" class="btn btn-icon action-icon back-button mb-1 me-4" container="body" containerClass="tooltipClass" 
                  data-bs-toggle="tooltip" data-placement="right" title="{{locale('locale_key.pages.file_upload_picker.data_extraction.button.tooltip.back_to_original_file')}}"
                  (click)="setMainViewer()" *ngIf="selectedFile.isConvertedToImg && !selectedFile.isCropped"><i class="ft-rewind"></i></button>
                  <button type="button" class="btn btn-icon action-icon crop-button me-3" container="body" containerClass="tooltipClass"
                  data-bs-toggle="tooltip" data-placement="right" title="{{locale('locale_key.pages.file_upload_picker.data_extraction.button.tooltip.convert_to_text')}}"
                  (click)="convertCroppedToText(selectedFile)" *ngIf="selectedFile.isConvertedToImg && !selectedFile.isCropped">
                    <i class="las la-file-alt"></i>
                  </button>
                  <button type="button" class="btn btn-icon action-icon back-button mb-1 me-4" container="body" containerClass="tooltipClass" 
                  data-bs-toggle="tooltip" data-placement="right" title="{{locale('locale_key.pages.file_upload_picker.data_extraction.button.tooltip.back_to_cropping')}}"
                  (click)="setCropperViewer()" *ngIf="selectedFile.isConvertedToImg && selectedFile.isCropped"><i class="ft-rewind"></i></button>
                </div>
              </div>
            </div>
            <div class="card-body p-0" *ngIf="selectedFile.isDoc()">
              <ngx-doc-viewer [url]="selectedFile.publicUrl" viewer="office"> </ngx-doc-viewer>
            </div>
            <div class="card-body p-0" *ngIf="selectedFile.isExcel()">
              <datagrid-excel-viewer [excelViewerService]="this" mode="EXPORT_EXCEL_TABLE_DATA" *ngIf="isEnabled"></datagrid-excel-viewer>
            </div>
          </div>
          <div *ngIf="selectedFile.isTextViewer() && !selectedFile.inProgress()">
            <div class="row">
              <div class="col-11 main-section p-0">
                <div class="card-body p-0">
                  <pre style="font-family: monospace">{{ selectedFile.txtContent }}</pre>
                </div>
              </div>
              <div class="col-1 tool-bar-col">
                <button type="button" class="btn btn-icon action-icon back-button mb-1 me-4" container="body" containerClass="tooltipClass" 
                data-bs-toggle="tooltip" data-placement="right" title="{{locale('locale_key.pages.file_upload_picker.data_extraction.button.tooltip.back_to_original_file')}}"
                (click)="selectedFile.setMainViewer()"></button>
                <button type="button" class="btn btn-icon action-icon crop-button mb-1 me-3" container="body" containerClass="tooltipClass" 
                data-bs-toggle="tooltip" data-placement="right" title="{{locale('locale_key.pages.file_upload_picker.data_extraction.button.tooltip.back_to_cropping')}}"
                (click)="selectedFile.setCropperViewer()"><i class="ft-crop"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </ng-container>
  </div>
  <div *ngIf="inProgress">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer mt-2 d-flex justify-content-end align-content-center" *ngIf="!inProgress">
    <button type="submit" class="btn tertiary-button custom-button me-2 ms-auto" (click)="close()">{{locale('locale_key.general.buttons.cancel')}}</button>
    <button type="submit" class="btn secondary-button custom-button me-2" *ngIf="viewNo>1" (click)="openModal(previousViewModal)">{{locale('locale_key.general.buttons.previous')}}</button>
    <button type="submit" class="btn primary-button custom-button me-2" *ngIf="viewNo==2" [disabled]="!(isExcelDataMarked && observer) && !isExtractorViewer()" (click)="mapToTaxonomy()">{{locale('locale_key.general.buttons.extract')}}</button>
    <button type="submit" class="btn primary-button custom-button me-2" *ngIf="viewNo==3" [disabled]="!(isExcelDataMarked && observer) && !isExtractorViewer()" (click)="extract()">{{locale('locale_key.general.buttons.extract')}}</button>
  </div>
</div>

<ng-template #previousViewModal>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel10" *ngIf="viewNo == 3">
      <i class="las la-exclamation-circle p-0 " style="font-size: 1.5rem; color:var(--orange);"></i>
      {{locale('locale_key.pages.file_upload_picker.previous_view_modal.title.back_to_data')}}
    </h4>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel10" *ngIf="viewNo != 3">
      <i class="las la-exclamation-circle p-0 " style="font-size: 1.5rem; color:var(--orange);"></i>
      {{locale('locale_key.pages.file_upload_picker.previous_view_modal.title.back_to_file')}}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="viewNo == 3">{{locale('locale_key.pages.file_upload_picker.previous_view_modal.title.back_to_data.description')}}</p>
    <p *ngIf="viewNo != 3">{{locale('locale_key.pages.file_upload_picker.previous_view_modal.title.back_to_file.description')}}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.pages.file_upload_picker.previous_view_modal.button.cancel')}}</button>
    <button type="button" class="btn primary-button custom-button" (click)="previousView()">{{locale('locale_key.pages.file_upload_picker.previous_view_modal.button.continue')}}</button>
  </div>
</ng-template>