import { ChartSettingFeNew } from "../chart/ChartSettingFeNew"
import { ChartWrapperFeNew } from "../chart/ChartWrapperFeNew"

export class DashboardChartFe {
  public kpiId: string
  public ordinal: number = 0
  public setting: ChartSettingFeNew
  public wrapper: ChartWrapperFeNew

  public static fromTransfer(transfer: any): DashboardChartFe {
    let c = new DashboardChartFe()
    c.kpiId = transfer.kpiId
    c.ordinal = transfer.ordinal
    c.setting = ChartSettingFeNew.fromTransfer(transfer.setting)
    return c
  }

  toTransfer(): DashboardChartFe {
    let transfer = new DashboardChartFe()
    transfer.kpiId = this.kpiId
    transfer.ordinal = this.ordinal
    if (this.setting) {
      transfer.setting = this.setting.toTransfer()
    }
    return transfer
  }
}