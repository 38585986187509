export class ChartSettingTypeFe {
    public static DOWNLOAD_IMAGE = 'downloadImage';
    public static DOWNLOAD_XLSX = 'downloadXlsx';
    public static DOWNLOAD_CSV = 'downloadCsv';
    public static DOWNLOAD_PDF = 'downloadPdf';
    public static SETTINGS = 'settings';
    public static DELETE = 'delete';
}

export class ChartSettingSubjectFe{
    constructor(public type: ChartSettingTypeFe, public selectedChartIndex: number){}
}