export class Company_AddFe{
  name: string
  countryCode: string
  industryGroup: string
  industry: string
  website: string
  purpose: string

  constructor(name: string,
    countryCode: string,
    industryGroup: string,
    industry: string,
    website: string,
    purpose: string) {
      this.name = name
      this.countryCode = countryCode
      this.industryGroup = industryGroup
      this.industry = industry
      this.website = website,
      this.purpose = purpose
  }
}