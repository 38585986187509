import { DataGridRow } from "./DataGridRow";
import { DataGridTableDef } from "./DataGridTableDef";

export class DataGridTableData extends DataGridTableDef{
  public key: string | undefined = undefined
  public rows: DataGridRow[] = [];
  public rowCount: number | undefined = 0;
  public pageSize  = 25;
  public pageNumber = 1;
  public ordered = false;
}