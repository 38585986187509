import { Component, HostListener, Inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { FormControl } from "@angular/forms";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { UserServiceFe} from "src/app/services/UserServiceFe";
import { VisitorServiceFe } from "src/app/services/VisitorServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { ScreenHeightSizeFe } from "src/app/model/screens/ScreenHeightSize";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DashboardFe } from "src/app/model/dashboard/DashboardFe";
import { RouteFe } from "src/app/route/RouteFe";
import { LoginUserFe } from "src/app/model/org/LoginUserFe";
import { KpiDefFe } from "src/app/model/kpi/KpiDefFe";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [trigger("transitionState", [state("setupOpen", style({ opacity: 1 })), state("setupClosed", style({ opacity: 0, height: 0 })), transition("setupOpen => setupClosed", animate("400ms ease-out")), transition("setupClosed => setupOpen", animate("400ms ease-in")), state("trackOpen", style({ opacity: 1})), state("trackClosed", style({ opacity: 0, height: 0 })), transition("trackOpen => trackClosed", animate("400ms ease-out")), transition("trackClosed => trackOpen", animate("400ms ease-in")), state("connectOpen", style({ opacity: 1 })), state("connectClosed", style({ opacity: 0, height: 0 })), transition("connectOpen => connectClosed", animate("400ms ease-out")), transition("connectClosed => connectOpen", animate("400ms ease-in")), state("adminOpen", style({ opacity: 1})), state("adminClosed", style({ opacity: 0, height: 0 })), transition("adminOpen => adminClosed", animate("400ms ease-out")), transition("adminClosed => adminOpen", animate("400ms ease-in"))])],
})
export class HeaderComponent extends AbstractLanguageComponent implements OnInit{
  @ViewChild('logoutModal', { static: true }) logoutModal: TemplateRef<any>;
  initCacheInProgress = true
  user = new FormControl(0);
  marginLeft = null;
  transition = null;
  screenWidthSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  screenHeightSize: ScreenHeightSizeFe = ScreenHeightSizeFe.HEIGHT_LARGE

  url: string = '';
  public userInfo: LoginUserFe;
  public fullScreen: boolean = false;
  public submenuAccessOpen: boolean = false;
  public accessManagement: string;
  public mobileDevice: boolean = false;
  public windowDevice: boolean = true;
  public newNotificationCounter = 0;
  public leftNaveExpand: boolean = false;
  public modalRef: BsModalRef;
  public dashboards: DashboardFe[];
  public activeRoute: RouteFe | undefined;
  public toEditDashboard: DashboardFe;
  public kpis: KpiDefFe[];

  public setupState = "setupClosed";
  public trackState = "trackClosed";
  public connectState = "connectClosed";
  public adminState = "adminClosed";
  public inventoryState = "inventoryClosed";
  public activeMenu = "home";
  isLoading: boolean = false;

  constructor(public stateService: StateServiceFe, private loginService: LoginServiceFe, private visitorService: VisitorServiceFe, private userService: UserServiceFe, languageService: LanguageService,
  private responsive: ResponsiveService, private modalService: BsModalService) {
    super(languageService);
    this.responsive.menuCollapsedSubject.subscribe(collapsed => {
      if (collapsed) {
        this.marginLeft = '-20%';
        this.transition = 'margin-left 1.0s ease-in-out';
      } else {
        this.marginLeft = '0';
      	this.transition = 'margin-left 1.0s ease-in-out';
      }
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenWidthSize: ScreenWidthSizeFe) => {
      this.screenWidthSize = screenWidthSize
    }); 
    this.screenWidthSize = responsive.currentScreenWidthSize;

    this.responsive.screenHeightSizeSubject.subscribe((screenHeightSize: ScreenHeightSizeFe) => {
      this.screenHeightSize = screenHeightSize
    }); 
    this.screenHeightSize = responsive.currentScreenHeightSize;

  } 

  async ngOnInit() {}

  @HostListener('window:beforeunload', ['$event']) updateRecentlyOpenedItems($event: any): void {
    this.stateService.updateRecentlyOpenedItems()
  }


  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.responsive.resize($event.target.innerWidth, $event.target.innerHeight)
  }

  public toggleMenu() {
    this.responsive.toggleNav();
    const btn = document.querySelector('#btn');
    if(btn) {
      btn.classList.toggle('menu-btn')
    }
  }

  async logout() {
    this.isLoading = true;
    if (this.isUserAVisitor()) {
      await this.visitorService.logout()
    } else {
      await this.stateService.updateRecentlyOpenedItems();
      await this.loginService.logout();
    }
    this.isLoading = false;
    this.close();
  }

  public isUserAVisitor () {
    return this.userService.isUserAVisitor();
  }

  openModal (templateRef: TemplateRef<any>, size: string = '') {
    this.modalService.show(templateRef, { class: size });
  }

  close() {
    this.modalService.hide();
  }
}
