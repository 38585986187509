export class InputFilterUtil {

  public static isValidNumberInput(event: KeyboardEvent): boolean {
    if (event) {
      const target = event.target as HTMLInputElement;
      const newValue = target.value + event.key;
  
      const isNumberPatternValid = /^(-?\d*\.?\d*)?$/.test(newValue);
  
      const hasValidDash = !(event.key === "-" && newValue.indexOf("-") > 0);
  
      const isControlOrModifierKey =
        event.key === "Backspace" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Delete" ||
        event.ctrlKey ||
        event.metaKey;
  
      return isNumberPatternValid && hasValidDash || isControlOrModifierKey;
    }
    return true;
  }
}
