import { BooleanStatementFe } from "./BooleanStatementFe";
import { BooleanStatementBuilderFe } from "./BooleanStatementBuilderFe";
import { StringValueStatementFe } from "./StringValueStatementFe";
import { TwoOperandOperatorFe } from "./TwoOperandOperatorFe";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { BooleanValueStatementFe } from "./BooleanValueStatementFe";
import { NumericalValueStatementFe } from "./NumericalValueStatementFe";
import { VariableStatementFe } from "./VariableStatementFe";
import { DateValueStatementFe } from "./DateValueStatementFe";
import { NullValueStatementFe } from "./NullValueStatementFe";
import { DataGridColumnType } from "src/app/components/projects/data-grid-ui/model/DataGridColumnType";

export class TwoOperandStatementFe extends BooleanStatementFe {
  public static TYPE_KEY = 'TWO_OPS' 
  public type = TwoOperandStatementFe.TYPE_KEY 
  public static START_KEYWORD = '('
  public static END_KEYWORD = ')'

  public leftStatement: BooleanStatementFe
  public rightStatement: BooleanStatementFe 
  public operator: TwoOperandOperatorFe

   
  filteredColumns: TaxonomyAttributeFe[] = []
  filteredValues: any[] = []
  searchCategoryLabel = null
  searchValueLabel = null
  selectedColumn: TaxonomyAttributeFe
  selectedColumnKey: string
  value: any
  isLoadingValues = false

  
  constructor (leftStatement: BooleanStatementFe, operator: TwoOperandOperatorFe, rightStatement: BooleanStatementFe) {
    super()
    this.leftStatement = leftStatement
    this.rightStatement = rightStatement
    this.operator = operator
  }

  public toFormulaString(): any {
    if (this.leftStatement && this.operator && this.rightStatement) {
      let str = [`${TwoOperandStatementFe.START_KEYWORD}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${this.leftStatement.toFormulaString()}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${this.operator.key}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${this.rightStatement.toFormulaString()}`,
                 `${BooleanStatementFe.SPACE}`,
                 `${TwoOperandStatementFe.END_KEYWORD}`].join('');
      return str
    }
  }
  
  public static fromFormulaString(formula: string): TwoOperandStatementFe {
    let prevIndex: any = TwoOperandStatementFe.START_KEYWORD.length + BooleanStatementFe.SPACE.length;
    
    let leftStatement = BooleanStatementBuilderFe.fromFormulaString(formula.substring(prevIndex));
    let leftStatementStr = leftStatement.toFormulaString()
   
    prevIndex += leftStatementStr.length + BooleanStatementFe.SPACE.length
    let nextSpaceIndex = formula.indexOf(BooleanStatementFe.SPACE, prevIndex);
    let operatorValue = formula.substring(prevIndex, nextSpaceIndex);
    let operator  = TwoOperandOperatorFe.opByKey(operatorValue)

    prevIndex = nextSpaceIndex + BooleanStatementFe.SPACE.length
    
    let rightStatement = BooleanStatementBuilderFe.fromFormulaString(formula.substring(prevIndex))
    let rightStatementStr = rightStatement.toFormulaString()

    let statement = new TwoOperandStatementFe(leftStatement, operator, rightStatement);
    return statement
  }

  public evaluate(values: Map<string, any>): boolean | undefined {
    if (this.leftStatement && this.operator && this.rightStatement) {
      let leftEvaluation = this.leftStatement.evaluate(values)
      let rightEvaluation = this.rightStatement.evaluate(values)
      let formula = `${leftEvaluation} ${this.operator.symbol} ${rightEvaluation}`
      if (this.leftStatement instanceof StringValueStatementFe || this.rightStatement instanceof StringValueStatementFe) {
        formula = StringValueStatementFe.compareVaue(this.leftStatement.evaluate(values), this.operator, this.rightStatement.evaluate(values));
      }
      let ans = eval(formula)
      return ans
    }
  }

  public static fromTransfer (transfer: any): TwoOperandStatementFe {
    let leftStatementStr = transfer['leftStatement']
    let leftStatement = BooleanStatementBuilderFe.fromTransfer(leftStatementStr)
    let rightStatementStr = transfer['rightStatement']
    let rightStatement = BooleanStatementBuilderFe.fromTransfer(rightStatementStr)
    let operatorStr = transfer['operator']
    let operatorKey = operatorStr['key']
    let operator = TwoOperandOperatorFe.opByKey(operatorKey)
    let statement = new TwoOperandStatementFe(leftStatement, operator, rightStatement)
    return statement
  }


  setAttributes (attributes:TaxonomyAttributeFe[]) {
    this.filteredColumns = attributes
  }

  selectColumnn (col: TaxonomyAttributeFe) {
    this.selectedColumn = col;
    this.leftStatement = new VariableStatementFe(col.key)
  }

  selectOperator (operator: TwoOperandOperatorFe) {
    this.operator = operator;
    if (this.operator == TwoOperandOperatorFe.NOTNULL) {
      this.rightStatement = new NullValueStatementFe() 
    }
  }

  setValue (value: any) {
    this.value = value;
    if (this.selectedColumn.datatype == DataGridColumnType.STRING) {
      this.rightStatement = new StringValueStatementFe(value) 
    } else if (this.selectedColumn.datatype == DataGridColumnType.NUMERIC) {
      this.rightStatement = new NumericalValueStatementFe(value)
    } else if (this.selectedColumn.datatype == DataGridColumnType.BOOLEAN) {
      this.rightStatement = new BooleanValueStatementFe(value)
    } else if (this.selectedColumn.datatype == DataGridColumnType.DATE) {
      this.rightStatement = new DateValueStatementFe(value)
    }
  }
}
