import { Component, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { Subject, Subscription } from "rxjs"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { StageTableInfoSubjectFe } from "src/app/model/subject/StageTableInfoSubjectFe"
import { AutomatedFeedInfoFe } from "src/app/model/automatedFeed/AutomatedFeedInfoFe"
import { AutomatedFeedSystemFe } from "src/app/model/automatedFeed/AutomatedFeedSystemFe"
import { DatahubService } from "./DatahubService"
import { DateUtil } from "src/app/utils/DateUtil"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"

@Component({
  selector: "automated-feed-portal",
  templateUrl: "./automatedFeedsPortal.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class AutomatedFeedsPortalComponent extends AbstractLanguageComponent implements OnInit {

  @Output() showFeed: Subject<AutomatedFeedInfoFe> = new Subject;
  menuCollapsed: boolean

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInfo: boolean = false
  loadingInProgress: boolean = false

  datahubFilteredFeeds: AutomatedFeedInfoFe[] = []
  datahubFilteredSystems: AutomatedFeedSystemFe[] = []
  filteredAutomatedFeeds: AutomatedFeedInfoFe[] = []
  automatedFeedSystems: AutomatedFeedSystemFe[] = []
  depTaxonomy: TaxonomyInfoFe
  selectedInfo: AutomatedFeedInfoFe = null
  ErrorsFe: any
  alertService: any
  subscriptions = new Subscription()
  searchKeyword: string = '';

  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.starting_period'), value: 'timeFrom'},
    {name: this.locale('locale_key.pages.datahub.sort.option.starting_period_desc'), value: 'timeFromDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.ending_period'), value: 'timeTo', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.ending_period_desc'), value: 'timeToDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]
  

  selectedSatusesLocaleKey = new Set<string>();
  customMultipleFilters: {name: string, value: AutomatedFeedSystemFe | EntityFe | Date, value2?: Date, level?: number}[] = []
  filteredEntities: EntityFe[] = []
  filteredSystemsForCustomFilters: AutomatedFeedSystemFe[] = []
  date1: Date = new Date()
  date2: Date = new Date()
  periodFilter: string = null



  constructor(languageService: LanguageService, private responsive: ResponsiveService, private stateService: StateServiceFe, public datahubService: DatahubService,
    private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService) {
    super(languageService)

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.screenSize = this.responsive.currentScreenWidthSize
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize;

    let sub = stateService.stageTableInfoSubject.subscribe(async (subject: StageTableInfoSubjectFe) => {
      await this.loadCompanyData()
    })
    this.subscriptions.add(sub)
  }

  async ngOnInit() {
    await this.loadCompanyData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadCompanyData() {
    this.loadingInProgress = true
    this.datahubFilteredFeeds = await this.datahubService.getFilteredAutomatedFeeds()
    this.datahubFilteredSystems = await this.datahubService.getFilteredAutomatedSystems()
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos();
    this.depTaxonomy = depTaxonomy
    this.filterSystems();
    this.filterEntities();
    this.applyFilters();
    this.loadingInProgress = false
  }

  search($event: any) {
    throw new Error("Method not implemented.")
  }

  openAutomatedFeed(info: AutomatedFeedInfoFe) {
    this.datahubService.showFeed(info);
  }

  updateDetailsData() {
    this.detailsData = {
    };
    this.displayService.updateDetailsData(this.detailsData);
  }

  async connecToPipeline(info: AutomatedFeedInfoFe) {
    try {
      await this.stateService.connecAutomatedFeedToPipeline(info)
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error)
      if (knownError && (knownError == this.ErrorsFe.AUTOMATED_FEED_TABLE_NOT_FOUND_ERROR_WHILE_CONNECTING || knownError == this.ErrorsFe.TAXONOMY_ENTITY_NOT_FOUND_WHILE_CONNECTING)) {
        this.alertService.showError(knownError.message);
      } else {
        this.alertService.showError('Unfortunately we were unable to connect the table to the pipeline due to some internal reasons! Please try again!')
      }
    }
  }

  async disconnectFromPipeline(info: AutomatedFeedInfoFe) {
    try {
      await this.stateService.disconnectAutomatedFeedFromPipeline(info)
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error)
      if (knownError && (knownError == this.ErrorsFe.AUTOMATED_FEED_TABLE_NOT_FOUND_ERROR_WHILE_ISCONNECTING || knownError == this.ErrorsFe.TAXONOMY_ENTITY_NOT_FOUND_WHILE_DISCONNECTING)) {
        this.alertService.showError(knownError.message);
      } else {
        this.alertService.showError('Unfortunately we were unable to disconnect the table from the pipeline due to some internal reasons! Please try again!')
      }
    }
  }

  public openModal(modalTemplateRef: TemplateRef<any>, clazz: string = 'modal-md') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: clazz,
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open');
  }

  async delete(info: AutomatedFeedInfoFe, system: AutomatedFeedSystemFe) {
    let deleted = await this.stateService.deleteAutomatedFeedInfo(info, system)
  }

  async sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let sortFun;
    if (this.sortedBy.value == 'timeFrom') {
      sortFun = (a: AutomatedFeedInfoFe, b: AutomatedFeedInfoFe) => {
        return DateUtil.getTimeDifference(a.timeFrom , b.timeFrom)
      }
    } else if (this.sortedBy.value == 'timeFromDes') {
      sortFun = (a: AutomatedFeedInfoFe, b: AutomatedFeedInfoFe) => {
        return DateUtil.getTimeDifference(b.timeFrom , a.timeFrom)
      }
    } else if (this.sortedBy.value == 'timeTo') {
      sortFun = (a: AutomatedFeedInfoFe, b: AutomatedFeedInfoFe) => {
        return DateUtil.getTimeDifference(a.timeTo , b.timeTo)
      }
    } else if (this.sortedBy.value == 'timeToDes') {
      sortFun = (a: AutomatedFeedInfoFe, b: AutomatedFeedInfoFe) => {
        return DateUtil.getTimeDifference(b.timeTo , a.timeTo)
      }
    } else if (this.sortedBy.value == 'name') {
      sortFun = (a: AutomatedFeedInfoFe, b: AutomatedFeedInfoFe) => {
        if (a.taxonomyEntity?.getLabel()?.toLowerCase() < b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return -1 
        } else if (a.taxonomyEntity?.getLabel()?.toLowerCase() > b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'nameDes') {
      sortFun = (a: AutomatedFeedInfoFe, b: AutomatedFeedInfoFe) => {
        if (a.taxonomyEntity?.getLabel()?.toLowerCase() < b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return 1 
        } else if (a.taxonomyEntity?.getLabel()?.toLowerCase() > b.taxonomyEntity?.getLabel()?.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      this.automatedFeedSystems.forEach(system => system.automatedFeeds = system.automatedFeeds.sort(sortFun))
    }
  }

  isStatusSelected(status: string) {
    this.selectedSatusesLocaleKey.has(status);
  }  
  
  applyFilters() {
    this.applyShowFilter();
  }

  changeShowFilter(event: Event) {
    let el = event.target as HTMLInputElement
    if (el.checked) {
      this.selectedSatusesLocaleKey.add(el.id)
    } else {
      this.selectedSatusesLocaleKey.delete(el.id)
    }
    this.applyFilters();
  }

  getSelectedStatusLocaleKeys(): string[]{
    return Array.from(this.selectedSatusesLocaleKey)
  }

  applyShowFilter() {
    this.automatedFeedSystems = this.datahubFilteredSystems.map(system => system.copy())
    if (this.selectedSatusesLocaleKey.size > 0) {
      this.automatedFeedSystems.forEach(system => {
        system.automatedFeeds = system.automatedFeeds.filter(feed => this.selectedSatusesLocaleKey.has(feed.statusLocaleKey()))
      })
      this.automatedFeedSystems = this.automatedFeedSystems.filter(system => system.automatedFeeds.length > 0)
    }
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedSatusesLocaleKey.clear();
    this.applyFilters();
  }

  handleDateCustomFilters() {
    this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value instanceof EntityFe)
    if (this.date1){
      let name, date1 = new Date(this.date1), date2;
      if (this.date2) {
        date2 = new Date(this.date2)
      }

      if (this.periodFilter == 'startIs'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'startIsBetween' && this.date2){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is_in_between', {date1: DateUtil.toString3(date1), date2: DateUtil.toString3(date2)})
      } else if (this.periodFilter == 'startIsBefore'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is_before', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'startIsAfter'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.starting_date_is_after', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'endIs'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'endIsBetween' && this.date2){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is_in_between', {date1: DateUtil.toString3(date1), date2: DateUtil.toString3(date2)})
      } else if (this.periodFilter == 'endIsBefore'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is_before', {date: DateUtil.toString3(date1)})
      } else if (this.periodFilter == 'endIsAfter'){
        name = this.locale('locale_key.pages.datahub.files_portal.filter_text.end_date_is_after', {date: DateUtil.toString3(date1)})
      }
      this.customMultipleFilters.push({name, value: date1, value2: date2})
    }
    this.applyFilters();
  }

  clearDateFilter() {
    this.periodFilter = null;
    this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value instanceof EntityFe)
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: EntityFe | AutomatedFeedSystemFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof EntityFe) {
        this.customMultipleFilters.push({ name: value.getTaxonomyTitle(this.depTaxonomy), value, level})
      } else if (value instanceof AutomatedFeedSystemFe) {
        this.customMultipleFilters.push({name: value.name, value})
      }
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.automatedFeedSystems.filter(system => {
        system.automatedFeeds = system.automatedFeeds.filter(feed =>{
          for (let filter of this.customMultipleFilters) {
            let value = filter.value
            if (value instanceof EntityFe) {
              let taxonomyKeys = new Set<string>();
              if (filter.level == 0) {
                let parent = this.depTaxonomy.childrenSortedByOrdinal(value.key)
                let child = []
                parent.forEach(item => child.push(...this.depTaxonomy.childrenSortedByOrdinal(item.key)))
                child.forEach(childItem => taxonomyKeys.add(childItem.key))
              } else if (filter.level == 1) {
                let child = this.depTaxonomy.childrenSortedByOrdinal(value.key)
                child.forEach(childItem => taxonomyKeys.add(childItem.key))
              } else {
                taxonomyKeys.add(value.key)
              }
              if (taxonomyKeys.has(feed.taxonomyKey))
                return true;
            } else if (value instanceof Date) {
              return this.checkCustomDateFilter(feed, value, filter.value2)
            } else if (value instanceof AutomatedFeedSystemFe) {
              return feed.systemName == value.name
            }
          }
        })  
      });
      this.automatedFeedSystems = this.automatedFeedSystems.filter(f => f.automatedFeeds.length > 0);
    }
    this.applySearchFilter();
  }

  checkCustomDateFilter(info: AutomatedFeedInfoFe, date1: Date, date2: Date): boolean{
    if (this.periodFilter == 'startIs'){
      return DateUtil.getTimeDifference(date1, info.timeFrom) == 0
    } else if (this.periodFilter == 'startIsBetween' && date2){
      return DateUtil.getTimeDifference(date1, info.timeFrom) < 0 && DateUtil.getTimeDifference(date2, info.timeFrom) > 0
    } else if (this.periodFilter == 'startIsBefore'){
      return DateUtil.getTimeDifference(date1, info.timeFrom) > 0
    } else if (this.periodFilter == 'startIsAfter'){
      return DateUtil.getTimeDifference(date1, info.timeFrom) < 0
    } else if (this.periodFilter == 'endIs'){
      return DateUtil.getTimeDifference(date1, info.timeTo) == 0
    } else if (this.periodFilter == 'endIsBetween' && date2){
      return DateUtil.getTimeDifference(date1, info.timeTo) < 0 && DateUtil.getTimeDifference(date2, info.timeTo) > 0
    } else if (this.periodFilter == 'endIsBefore'){
      return DateUtil.getTimeDifference(date1, info.timeTo) > 0
    } else if (this.periodFilter == 'endIsAfter'){
      return DateUtil.getTimeDifference(date1, info.timeTo) < 0
    }
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == "") {
      this.searchKeyword = keyword;
    }
    this.filteredAutomatedFeeds = []
    this.automatedFeedSystems.forEach(system => {
      let feeds = system.automatedFeeds.filter(feed =>{
        if (feed.taxonomyEntity) {
          return feed.taxonomyEntity.getLabel().match(new RegExp(this.searchKeyword, "i"))
            || feed.parentEntity?.getLabel().match(new RegExp(this.searchKeyword, "i"))
            || feed.rootParentEntity?.getLabel().match(new RegExp(this.searchKeyword, "i"))
        }
      })  
      this.filteredAutomatedFeeds.push(...feeds)
    })

    if (this.sortedBy) {
      this.sort();
    }   
  }

  async filterEntities(keyword: string = '') {
    let relatedEntitiesSet = new Set<EntityFe>();
    this.datahubFilteredFeeds.forEach(info => relatedEntitiesSet.add(info.rootParentEntity))
    let relatedEntities = Array.from(relatedEntitiesSet)
    this.filteredEntities = relatedEntities.filter(root => {
      if (root.getLabel().toLowerCase().includes(keyword.toLowerCase())) {
        return true
      }
      let parents = this.depTaxonomy.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().toLowerCase().includes(keyword.toLowerCase()))) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.depTaxonomy.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().toLowerCase().includes(keyword.toLowerCase()))) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  async filterSystems(keyword: string = '') {
    let systems = await this.stateService.getAutomatedFeedSystems();
    this.filteredSystemsForCustomFilters = systems.filter(system => system.name.includes(keyword));
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    this.filteredEntities.forEach(enitity => { 
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
    })
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    this.filteredEntities.forEach(enitity => { 
      if (!this.customMultipleFilters.find(f => f.value == enitity)) {
        this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.depTaxonomy), value: enitity})
      } 
    })
    this.applyFilters();
  }

  getCustomFilterText() {
    let text = '';
    text+= this.getSelectedStatusLocaleKeys().map(key => `${this.locale(key)} ${ this.locale('locale_key.pages.datahub.files_portal.filter_text.tables')}`).join(', ');
    if (this.selectedSatusesLocaleKey.size>0 && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }
}
