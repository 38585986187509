import { RouterFe } from 'src/app/route/RouterFe';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { FileDataFe } from 'src/app/model/file/FileDataFe';
import { ExcelViewerIntegrationService } from './ExcelViewerIntegrationService';
import { AlertServiceFe } from 'src/app/services/AlertServiceFe';
import { FileDataExtractorIntegrationService } from './FileDataExtractorIntegrationService';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { FileTypeFe } from 'src/app/model/file/FileTypeFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { ExcelViewerServiceInterface } from '../projects/data-grid-ui/service/ExcelViewerServiceInterface';

@Component({
  selector: 'file-portal',
  templateUrl: './file-portal.component.html',
  styleUrls: ['./file-portal.component.scss']
})
export class FilePortalComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('upload', { static: true }) uploadFile: TemplateRef<any>;
  @ViewChild('deleteWindow', { static: true }) deleteWindow: TemplateRef<any>;

  inProgress = false
  selectedTabFileIndex: number;

  public modalRef: BsModalRef;
  public filesToUpload: any[] = [];
  public uploadProgress: number[] = [];
  public fileUploadMessages: string[] = [];

  public fileIndextoDelete: any;
  public deleteAlerts: { type: string, message: string }[] = [];

  public allFiles: FileDataFe[] = [];
  public sortType: string;
  public sortAsc: boolean = false;

  public isDisabled: boolean = true;
  url:string = '';
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode;

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.pages.file_explorer.toolbar.upload'),
        longLabel: this.locale('locale_key.pages.file_explorer.toolbar.upload'),
        tooltip: this.locale('locale_key.pages.file_explorer.toolbar.upload'),
        icon: "la la-upload",
        actionName: "open_upload_popup",
        visible: () => this.homeActive(),
        disabled: false
      }
    ],
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info", actionName:"toggle_quick_tips" , visible:()=> true, disabled: false}, 
    ], 
  ];
// move to the three dots
  btnGroup = [
    {
      title: this.locale('locale_key.general.buttons.download'),
      icon: "las la-download",
      actionBtn: "download",
    },
    {
      title: this.locale('locale_key.general.buttons.delete'),
      icon: "las la-trash-alt",
      actionBtn: "delete",
    }
  ]


  constructor(private modalService: BsModalService, private domSanitizer: DomSanitizer,
    private backendService: RouterFe, private stateService: StateServiceFe,
    private alertService: AlertServiceFe, private displayService: DisplayServiceFe, languageService: LanguageService, private responsive: ResponsiveService) {
    super(languageService)
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
  }

  async ngOnInit() {
    await this.loadCompanyData()
    if(this.allFiles.length == 0){
      this.displayService.updateQuickTipsData({page:'files'})
    }
  }

  sort(property = 'time') {
    if (this.sortType == property) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }
    this.sortType = property;
    let sortFunction = FileDataFe.sortFunction(property, this.sortAsc)
    this.allFiles.sort(sortFunction);
  }

  public async loadCompanyData() {
    this.inProgress = true
    this.allFiles = await this.stateService.getAllFiles()
    this.sortType = undefined
    this.sort()
    this.inProgress = false
  }

  public closeAlert(alert): void {
    this.deleteAlerts.splice(alert, 1);
  }

  public uploadPopup(template: TemplateRef<any> | string, size: string): void {
    this.filesToUpload = [];
    this.uploadProgress = [];
    this.fileUploadMessages = [];
      this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray modal-md mt-3 pt-3' }));
  }

  public deletePopup(template: TemplateRef<any>, index): void {
    this.fileIndextoDelete = index;
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray ' }));
  }

  async deleteFileFromStorage() {
    let fileName = this.allFiles[this.fileIndextoDelete].label
    try {
      await this.stateService.deleteExplorerFile(fileName)
      this.deleteAlerts.push({type: 'success', message:`File ${fileName} is successfully deleted!`})
      this.allFiles = await this.stateService.getAllFiles();
      setTimeout(() => { this.closeAlert(alert) }, 5000);  
      window.scrollTo(0, 0)
    } catch (err) {
      this.alertService.showError(`File ${fileName} couldn't be deleted, please try again!`)
    }
  }

  
  closeModal() {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open');
    }
  }

  public downloadFile(index): void {
    let fileData = this.allFiles[index]
    if (fileData.hasContent()) {
      fileData.saveFile()
    } else {
      this.backendService.downloadExplorerFile(fileData.label).subscribe(
        (content) => {
          fileData.addBlobContent(content, this.domSanitizer)
          fileData.saveFile()
        },
        (error) => {
        })
    }
  }

  public closeTab(fileData: FileDataFe) {
    fileData.isActive = false
    fileData.isOpen = false
  }

  public homeActive(): boolean {
    let fileActive = this.allFiles.find(f => f.isActive)
    return fileActive ? false : true;
  }

  viewHome() {
    this.selectedTabFileIndex = -1;
    this.allFiles.forEach(f => f.isActive = false)
    this.displayService.updateQuickTipsData({page:'files'})
  }

  viewFile(index): void {
    let fileData = this.allFiles[index]
    this.selectedTabFileIndex = index
    if (!fileData.hasContent()) {
      fileData.loadingFileInProgress = true
      fileData.isOpen = true
      this.backendService.downloadExplorerFile(fileData.label).subscribe(
        (content) => {
          fileData.addBlobContent(content, this.domSanitizer)
          let dataExtractorService = new FileDataExtractorIntegrationService(this.stateService,fileData)
          fileData.dataExtractorService = dataExtractorService;
          dataExtractorService.setMainViewer()
          this.allFiles.forEach(f => f.isActive = false)
          fileData.isActive = true
          this.displayService.updateQuickTipsData({viewMode: 'view_2', page:'files'})
     
        },
        (error) => {
          fileData.loadingFileInProgress = false
        }
      )
    } else {
      fileData.isOpen = true
      if (fileData.dataExtractorService) {
        fileData.dataExtractorService.setMainViewer()
      } else {
        let dataExtractorService = new FileDataExtractorIntegrationService(this.stateService,fileData)
        fileData.dataExtractorService = dataExtractorService;
        dataExtractorService.setMainViewer()
      }
      this.allFiles.forEach(f => f.isActive = false)
      fileData.isActive = true
      this.displayService.updateQuickTipsData({viewMode: 'view_2', page:'files'})
    }
  }

  async convertToImg(fileData: FileDataFe) {
    await fileData.dataExtractorService.observer?.convertToImg();
  }
  
  convertCroppedToText(fileData: FileDataFe): void  {
    fileData.dataExtractorService.observer?.convertCroppedToText();
  }

  public selectFilesForUpload(event): void {
    Array.from(event.target.files).forEach(file => {
      this.filesToUpload.push(file);
      this.uploadProgress.push(0)
    });
    (document.getElementById('uploadCaptureInputFile') as HTMLInputElement).value = '';
    this.enableButton();
  } 

  public deleteFileFromUpload(index: number): void {
    this.filesToUpload.splice(index, 1);
  }

  public async uploadFiles() {
    for (let index = 0; index < this.filesToUpload.length; index++) {
      const file = this.filesToUpload[index];
      const formData = new FormData();
      formData.append('files', file);
      let newFile = FileDataFe.fromBlob(file.name);
      newFile.type = FileTypeFe.fromName(newFile.label);
      newFile.timeCreated = new Date();
      await this.upload(formData, newFile, index);  
    }
    setTimeout(() => { 
      this.loadCompanyData(); 
      this.modalRef.hide();
      document.body.classList.remove('modal-open');
    }, 500);
    
  }

  public async upload(formData, newFile, index) {
    this.uploadProgress[index] = 30;
    try {
      let data = await this.stateService.uploadExplorerFile(formData, newFile)
      this.uploadProgress[index] += data.progress
      this.uploadProgress[index] += 20  
      this.fileUploadMessages[index] = data.info
    } catch (error) {
      this.alertService.showError(`File ${newFile.name} could not be uploaded!`)
    }   
   }

  public formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed()) + ' ' + sizes[i];
  }

  public enableButton() {
    if (this.filesToUpload.length > 0) {
      this.isDisabled = false
    }
    else {
      this.isDisabled = true;
    }
  }

  public getExcelViewerService(fileData: FileDataFe): ExcelViewerServiceInterface {
    let service = new ExcelViewerIntegrationService(this.stateService, fileData, this.languageService)
    return service
  }
  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "open_upload_popup":
        this.uploadPopup(this.uploadFile, 'modal-md');
        break;
      case "toggle_quick_tips":
        this.toggleTips();
        break;
    }
  }
  
  toggleTips(){
    this.displayService.toggleTips();
  }

  handleBtnGroup(actionBtn: any, index: number) {
    switch(actionBtn) {
      case "delete":
        this.deletePopup(this.deleteWindow, index);
        break;
      case "download":
        this.downloadFile(index);
        break;
    }
  }

}