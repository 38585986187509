<body class="horizontal-layout horizontal-menu-padding 1-column bg-corporate-blue blank-page pace-done menu-hide vertical-layout vertical-overlay-menu fixed-navbar pt-1" data-open="click" data-menu="horizontal-menu" data-col="1-column">
  <div class="ball-pulse loader-primary my-3" *ngIf="loadingInfo" style="margin-left: 50%; margin-top: 30% !important">
    <div style="background-color: #405369"></div>
    <div style="background-color: #405369"></div>
    <div style="background-color: #405369"></div>
  </div>
  <ng-container *ngIf="!loadingInfo">
    <!--------------------------------------- MANAGER FLOW --------------------------------------->
    <ng-container *ngIf="isManagerInvitation">
      <div class="app-content container center-layout">
        <div class="content-wrapper">
          <div class="content-body">
            <section class="row flexbox-container mt-1" *ngIf="!invitationRejected && !invitationAccepted">
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="col-lg-8 col-md-12 col-10 box-shadow-2 p-0">
                  <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                    <div class="card-header pb-0">
                      <div class="card-title text-center">
                        <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                      </div>
                    </div>
                    <div class="card-content">
                      <div class="card-body pb-0 pt-0">
                        <form class="form form-horizontal text-center p-1">
                          <h1 class="my-2">Welcome to SustainLab</h1>
                          <div class="row p-1">
                            <p class="text-justify mb-1">
                              Hey <b>{{ invitationInfo["managerFirstName"] + " " + invitationInfo["managerLastName"] }}</b
                              >,
                              <br />
                              <br />
                              You have an invitation from <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b> at <b>SustainLab</b> to sign up to your company <b>{{ invitationInfo["clientCompanyName"] }}</b> on SustainLab's platform. To activate your account, please continue to confirm your information.
                              <br />
                              <br />
                            </p>
                            <label for="isCompanyConfirmed">
                              <input  type="checkbox" class="mb-50 mr-50 large-checkbox" id="isCompanyConfirmed" name="isCompanyConfirmed" [(ngModel)]="isCompanyConfirmed" />
                              I confirm that I am affiliated with company <b>{{ invitationInfo["clientCompanyName"] }}</b
                              >.
                            </label>
                          </div>
                          <div class="modal-footer" style="border-top: none">
                            <button class="btn secondary-button m-1" (click)="rejectInvitation()">Reject Invitation</button>
                            <button class="btn primary-button m-1" (click)="isManagerOrContact=true; acceptInvitation();" [disabled]="!isCompanyConfirmed">Accept Invitation</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            
            <!-- Inviation Accepted -->
            <!-- Manager Exist -->
            <ng-container *ngIf="invitationAccepted">
              <ng-container *ngIf="managerInvitationExtended.managerEmailExist">
                <section class="row flexbox-container my-1" *ngIf="viewNo == 2">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" (ngSubmit)="register()">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Our system recognised that you are already registered in our system with {{ managerInvitationExtended.invitation.managerEmail }} email address. Please provide your current credentials that system can add your new role and your new workspaces to the same user account.</p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" readonly />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button m-1" routerLink="/op/forgotpassword">Forgot Password</button>
                                  <button type="submit" class="btn primary-button m-1" style="width: 132px">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-container>
              <ng-container *ngIf="!managerInvitationExtended.managerEmailExist">
                <section class="row flexbox-container my-1" *ngIf="viewNo == 2">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Is this the first time you are invited to SustainLab or have you ever used SustainLab with another email?</p>
                                <select class="form-control" [formControl]="isNewUser" (change)="onIsNewUserChange()">
                                  <option value =-1 selected>Select an answer</option>
                                  <option value="true">I am totally new to the SustainLab Platform</option>
                                  <option value="false">I am already a user of SustainLab Platform with another email</option>
                                </select>
                              </div>
                              <div class="modal-footer float-end" style="border-top: none">
                                <button type="submit" [disabled]="isNewUser.value == '-1'" class="btn primary-button custom-button" (click)="viewNo = 3">Continue</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- New User -->
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 3 && isNewUser.value == 'true'">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal" [formGroup]="userInfo" (ngSubmit)="registerNewUser()">
                              <h4 class="form-section py-3"><i class="la la-eye"></i> Profile Info</h4>
                              <div class="form-group row">
                                <label class="col-md-3" for="firstName">First Name</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="firstName" name="firstName" class="form-control" />
                                  <div class="invalid-feedback">
                                    <div>First Name is required</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="lastName">Last Name</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="lastName" name="lastName" class="form-control" />
                                  <div class="invalid-feedback">
                                    <div>Last Name is required</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="companyName">Company</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="companyName" class="form-control" readonly />
                                </div>
                              </div>
                              <h4 class="form-section py-3"><i class="ft-mail"></i> Credential's Info</h4>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" readonly />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                    <div *ngIf="userInfo.get('password').errors.previouslyUsed"></div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="cpassword">Confirm Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="cpassword"
                                    class="form-control"
                                    placeholder="Confirm Password"
                                    [(ngModel)]="cpassword"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('cpassword').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('cpassword').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('cpassword').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('cpassword').errors.notMatch">New Password & Confirm Password does not match.</div>
                                    <div *ngIf="userInfo.get('cpassword').errors.previouslyUsed">This password has been previously used as one of the last five passwords. Please choose a new and unique password.</div> 
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>

                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button custom-button m-1" (click)="resetUserInfo()">Reset</button>
                                  <button type="submit" class="btn primary-button custom-button m-1">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!--Manager -->
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 3 && isNewUser.value != 'true'">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" (ngSubmit)="register()">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Please provide your current credentials that system can add your new role and new workspace to the same user account. Later you can login with either of your emails to access your workspaces.</p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button m-1" routerLink="/op/forgotpassword">Forgot Password</button>
                                  <button type="submit" class="btn primary-button m-1" style="width: 132px">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-container>
            </ng-container>

            <!-- Invitation Rejected -->
            <ng-container *ngIf="invitationRejected">
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 2">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1">
                              <p class="text-justify mb-2">
                                Are you sure you want to reject the invitation of
                                <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b> from <b>{{ invitationInfo["inviterCompanyName"] }}</b> ? Can you please specfy the reason or write a message for <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b
                                >?
                              </p>
                              <label>Please specify the reason for reject the invitation</label>
                              <select class="form-control" [formControl]="rejectionReason">
                                <option selected>Reason for rejecting the invite</option>
                                <option value="I don't know this person or their company">I don't know this person or their company</option>
                                <option value="I wasn't aware that we are going to connect through SustainLab, I need to check it first">I wasn't aware that we are going to connect through SustainLab, I need to check it first</option>
                                <option value="Other reasons">Other reasons</option>
                              </select>
                              <label class="mt-2">Kindly provide below your reasons for rejecting this invitation.</label>
                              <textarea class="form-control" rows="5" [formControl]="rejectionExplanation" [placeholder]="'It would be great if you can write a message to' + invitationInfo?.inviterFirstName + '' + invitationInfo?.inviterLastName + 'that he knows  why you are rejecting this invitation.'"></textarea>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button" *ngIf="!loadInProgress" (click)="confirmRejectInvitation()">Reject Invitation</button>
                              <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="row flexbox-container mb-1" *ngIf="viewNo == 3">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1">
                              <p class="text-justify mb-1">
                                Thank you for letting us know you that this was not a right invitation. We will inform
                                <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b> from <b>{{ invitationInfo["inviterCompanyName"] }}</b>
                                about it.
                              </p>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button custom-button" routerLink="op/login">Home</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <!--------------------------------------- CONTACT FLOW --------------------------------------->
    <ng-container *ngIf="isContactInvitation">
      <div class="app-content container center-layout">
        <div class="content-wrapper">
          <div class="content-body">
            <section class="row flexbox-container mt-1" *ngIf="!invitationRejected && !invitationAccepted">
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                  <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                    <div class="card-header pb-0">
                      <div class="card-title text-center">
                        <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                      </div>
                    </div>
                    <div class="card-content">
                      <div class="card-body pb-0 pt-0">
                        <form class="form form-horizontal text-center p-1">
                          <h1 class="my-2">Welcome to SustainLab</h1>
                          <div class="row p-1">
                            <p class="text-justify mb-1">
                              Hey <b>{{ invitationInfo["contactFirstName"] + " " + invitationInfo["contactLastName"] }}</b
                              >,
                              <br />
                              <br />
                              <ng-container *ngIf="contactInvitationExtended.invitation.managementCompanyId == contactInvitationExtended.invitation.contactCompanyId">
                                You have an invitation by <b>{{ contactInvitationExtended.invitation.inviterFirstName + " " + contactInvitationExtended.invitation.inviterLastName }}</b> from <b>{{ contactInvitationExtended.invitation.inviterCompanyName }}</b> to provide sustainability data to <b>{{ contactInvitationExtended.invitation.managementCompanyName }}</b>.
                                To complete your registration, please continue and confirm your information.
                              </ng-container>
                              <ng-container *ngIf="contactInvitationExtended.invitation.managementCompanyId !== contactInvitationExtended.invitation.contactCompanyId">
                                You have an invitation by <b>{{ contactInvitationExtended.invitation.inviterFirstName + " " + contactInvitationExtended.invitation.inviterLastName }}</b> from <b>{{ contactInvitationExtended.invitation.inviterCompanyName }}</b> to provide sustainability data to <b>{{ contactInvitationExtended.invitation.managementCompanyName }}</b> as part of your affiliation to <b>{{ contactInvitationExtended.invitation.contactCompanyName }}</b>.. 
                                To complete your registration, please continue and confirm your information.
                              </ng-container>
                            </p>
                            <label for="isCompanyConfirmed">
                              <input type="checkbox" class="mb-50 mr-50 large-checkbox" id="isCompanyConfirmed" name="isCompanyConfirmed" [(ngModel)]="isCompanyConfirmed" />
                              I confirm that I am affiliated with company <b>{{ contactInvitationExtended.invitation.contactCompanyName }}</b>.
                            </label> 
                          </div>
                          <div class="modal-footer" style="border-top: none">
                            <button type="button" class="btn secondary-button m-1" (click)="rejectInvitation()">Reject Invitation</button>
                            <button type="submit" class="btn primary-button m-1" (click)="isManagerOrContact=true;acceptInvitation()" [disabled]="!isCompanyConfirmed">Accept Invitation</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <ng-container *ngIf="invitationAccepted">
              <ng-container *ngIf="contactInvitationExtended.contactEmailExists">
                <section class="row flexbox-container my-1" *ngIf="viewNo == 2">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" (ngSubmit)="register()">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">
                                  Our system recognised that you are already registered in our system with
                                  {{ contactInvitationExtended.invitation.contactEmail }}
                                  email address. Please provide your current credentials that system can add your new role and your new workspaces to the same user account.
                                </p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" readonly />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"                                    
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button m-1" routerLink="/op/forgotpassword">Forgot Password</button>
                                  <button type="submit" class="btn primary-button m-1" style="width: 132px">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-container>
              <ng-container *ngIf="!contactInvitationExtended.contactEmailExists">
                <section class="row flexbox-container my-1" *ngIf="viewNo == 2">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Is this the first time you are invited to SustainLab or do you use SustainLab with another email?</p>
                                <select class="form-control" [formControl]="isNewUser" (change)="onIsNewUserChange()">
                                  <option value=-1 selected>Select an answer</option>
                                  <option value="true">I am totally new to the SustainLab Platform</option>
                                  <option value="false">I am already a user of SustainLab Platform with another email</option>
                                </select>
                              </div>
                              <div class="modal-footer float-end" style="border-top: none">
                                <button type="submit" [disabled]="isNewUser.value == '-1'" class="btn primary-button custom-button" (click)="viewNo = 3">Continue</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 3 && isNewUser.value == 'true'">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal" [formGroup]="userInfo" (ngSubmit)="registerNewUser()">
                              <h4 class="form-section py-3"><i class="la la-eye"></i> Profile Info</h4>
                              <div class="form-group row">
                                <label class="col-md-3" for="firstName">First Name</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="firstName" name="firstName" class="form-control" />
                                  <div class="invalid-feedback">
                                    <div>First Name is required</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="lastName">Last Name</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="lastName" name="lastName" class="form-control" />
                                  <div class="invalid-feedback">
                                    <div>Last Name is required</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="companyName">Company</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="companyName" class="form-control" readonly />
                                </div>
                              </div>
                              <h4 class="form-section py-3"><i class="ft-mail"></i> Credential's Info</h4>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" readonly />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                    <div *ngIf="userInfo.get('password').errors.previouslyUsed"></div> 
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="cpassword">Confirm Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="cpassword"
                                    class="form-control"
                                    placeholder="Confirm Password"
                                    [(ngModel)]="cpassword"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('cpassword').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('cpassword').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('cpassword').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('cpassword').errors.notMatch">{{ confirmPasswordMessage }}</div>
                                    <div *ngIf="userInfo.get('cpassword').errors.previouslyUsed">This password has been previously used as one of the last five passwords. Please choose a new and unique password.</div> 
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols </span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button custom-button m-1" (click)="resetUserInfo()">Reset</button>
                                  <button type="submit" class="btn primary-button custom-button m-1">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!--Contact -->
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 3 && isNewUser.value != 'true'">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" (ngSubmit)="register()">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Please provide your current credentials that system can add your new role and new workspace to the same user account. Later you can login with either of your emails to access your workspaces.</p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" value="" />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button m-1" routerLink="/op/forgotpassword">Forgot Password</button>
                                  <button type="submit" class="btn primary-button m-1" style="width: 132px">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="invitationRejected">
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 2">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1">
                              <p class="text-justify mb-2">
                                Are you sure you want to reject the invitation of
                                <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b> from <b>{{ invitationInfo["inviterCompanyName"] }}</b>
                                ? Can you please specfy the reason or write a message for Simon?
                              </p>
                              <label>Please specify the reason for reject the invitation</label>
                              <select class="form-control" [formControl]="rejectionReason">
                                <option selected>Reason for rejecting the invite</option>
                                <option value="I don't know this person or their company">I don't know this person or their company</option>
                                <option value="I wasn't aware that we are going to connect through SustainLab, I need to check it first">I wasn't aware that we are going to connect through SustainLab, I need to check it first</option>
                                <option value="Other reasons">Other reasons</option>
                              </select>
                              <label class="mt-2">Kindly provide below your reasons for rejecting this invitation.</label>
                              <textarea class="form-control" rows="5" [formControl]="rejectionExplanation" [placeholder]="'It would be great if you can write a message to' + invitationInfo?.inviterFirstName + '' + invitationInfo?.inviterLastName + 'that he knows  why you are rejecting this invitation.'"></textarea>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button" *ngIf="!loadInProgress" (click)="confirmRejectInvitation()">Reject Invitation</button>
                              <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="row flexbox-container mb-1" *ngIf="viewNo == 3">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1">
                              <p class="text-justify mb-1">
                                Thank you for letting us know you that this was not a right invitation. We will inform
                                <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b> from <b>{{ invitationInfo["inviterCompanyName"] }}</b>
                                about it.
                              </p>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button custom-button" routerLink="op/login">Home</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <!--------------------------------------- PARTNER FLOW --------------------------------------->
    <ng-container *ngIf="isPartnerInvitation">
      <div class="app-content container center-layout">
        <div class="content-wrapper">
          <div class="content-body">
            <section class="row flexbox-container mt-1" *ngIf="!invitationRejected && !invitationAccepted">
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="col-lg-8 col-md-12 col-10 box-shadow-2 p-0">
                  <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                    <div class="card-header pb-0">
                      <div class="card-title text-center">
                        <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                      </div>
                    </div>
                    <div class="card-content">
                      <div class="card-body pb-0 pt-0">
                        <form class="form form-horizontal text-center p-1">
                          <h1 class="my-2">Welcome to SustainLab</h1>
                          <div class="row p-1">
                            <p class="text-justify mb-1">
                              Hi <b>{{ invitationInfo["primaryContactFirstName"] + " " + invitationInfo["primaryContactLastName"] }}</b
                              >,
                              <br />
                              <br />
                              You have an invitation by <b>{{ partnerInvitationExtended.invitation.inviterFirstName + " " + partnerInvitationExtended.invitation.inviterLastName }}</b> from <b>{{ partnerInvitationExtended.invitation.inviterCompanyName }}</b> to provide sustainability data to <b>{{ partnerInvitationExtended.invitation.managementCompanyName }}</b> as part of your affiliation to <b>{{ partnerInvitationExtended.invitation.partnerName }}</b>.
                              To complete your registration, please continue and confirm your information.
                            </p>
                            <label for="isCompanyConfirmed">
                              <input type="checkbox" class="mb-50 mr-50 large-checkbox" id="isCompanyConfirmed"     name="isCompanyConfirmed" [(ngModel)]="isCompanyConfirmed" />
                              I confirm that I am affiliated with company <b>{{ invitationInfo["partnerName"] }}</b
                              >.
                            </label>
                          </div>
                          <div class="modal-footer" style="border-top: none">
                            <button type="button" class="btn secondary-button m-1" (click)="rejectInvitation()">Reject Invitation</button>
                            <button type="submit" class="btn primary-button m-1" (click)="acceptInvitation()" [disabled]="!isCompanyConfirmed">Accept Invitation</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Partner Exist -->
            <ng-container *ngIf="invitationAccepted">
              <ng-container *ngIf="partnerInvitationExtended.primaryContactEmailExist">
                <section class="row flexbox-container my-1" *ngIf="viewNo == 2">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" (ngSubmit)="checkExistingUserCompanies()">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">
                                  Our system recognised that you are already registered in our system with
                                  {{ partnerInvitationExtended.invitation.primaryContactEmail }}
                                  email address. Please provide your current credentials that system can add your new role and your new workspaces to the same user account.
                                </p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" readonly />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button m-1" routerLink="/op/forgotpassword">Forgot Password</button>
                                  <button type="submit" class="btn primary-button m-1" style="width: 132px">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-container>
              <ng-container *ngIf="!partnerInvitationExtended.primaryContactEmailExist">
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 2">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Is this the first time you are invited to SustainLab or do you use SustainLab with another email?</p>
                                <select class="form-control" [formControl]="isNewUser" (change)="onIsNewUserChange()">
                                  <option value= -1 >Select an answer</option>
                                  <option value="true">I am totally new to the SustainLab Platform</option>
                                  <option value="false">I am already a user of SustainLab Platform with another email</option>
                                </select>
                              </div>
                              <div class="modal-footer float-end" style="border-top: none">
                                <button type="submit" [disabled]="isNewUser.value == '-1'" class="btn primary-button custom-button" (click)="viewNo = 3">Continue</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <!-- New User -->
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 3 && isNewUser.value == 'true'">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal" [formGroup]="userInfo" (ngSubmit)="setCompanyDetailsForm(true)">
                              <h4 class="form-section py-3"><i class="la la-eye"></i> Profile Info</h4>
                              <div class="form-group row">
                                <label class="col-md-3" for="firstName">First Name</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="firstName" name="firstName" class="form-control" />
                                  <div class="invalid-feedback">
                                    <div>First Name is required</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="lastName">Last Name</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="lastName" name="lastName" class="form-control" />
                                  <div class="invalid-feedback">
                                    <div>Last Name is required</div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="companyName">Company</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="companyName" class="form-control" readonly />
                                </div>
                              </div>
                              <h4 class="form-section py-3"><i class="ft-mail"></i> Credential's Info</h4>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" readonly />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                    <div *ngIf="userInfo.get('password').errors.previouslyUsed"></div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="cpassword">Confirm Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="cpassword"
                                    class="form-control"
                                    placeholder="Confirm Password"
                                    [(ngModel)]="cpassword"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('cpassword').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('cpassword').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('cpassword').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('cpassword').errors.notMatch"></div>
                                    <div *ngIf="userInfo.get('cpassword').errors.previouslyUsed">This password has been previously used as one of the last five passwords. Please choose a new and unique password.</div> 
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button custom-button m-1" (click)="resetUserInfo()">Reset</button>
                                  <button type="submit" class="btn primary-button custom-button m-1">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <!-- Existing User -->
                <section class="row flexbox-container mt-1" *ngIf="viewNo == 3 && isNewUser.value != 'true'">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                        <div class="card-header pb-0">
                          <div class="card-title text-center">
                            <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                          </div>
                        </div>
                        <div class="card-content">
                          <div class="card-body pb-0 pt-0">
                            <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" (ngSubmit)="checkExistingUserCompanies()">
                              <div class="row p-1 text-center">
                                <h1 class="m-auto">Welcome to SustainLab</h1>
                                <p class="text-justify my-2">Please provide your current credentials that system can add your new role and new workspace to the same user account. Later you can login with either of your emails to access your workspaces.</p>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="email">Your Email</label>
                                <div class="col-md-9 mx-auto">
                                  <input type="text" formControlName="email" class="form-control" />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-3" for="password">Password</label>
                                <div class="col-md-9 mx-auto">
                                  <input
                                    type="password"
                                    formControlName="password"
                                    class="form-control"
                                    placeholder="Enter Password"
                                    [(ngModel)]="password"
                                    [ngClass]="{
                                      'is-invalid': isSubmitted && userInfo.get('password').errors
                                    }"
                                  />
                                  <div *ngIf="isSubmitted && userInfo.get('password').errors" class="invalid-feedback">
                                    <div *ngIf="userInfo.get('password').errors.required">{{ passwordMessage }}</div>
                                    <div *ngIf="userInfo.get('password').errors.pattern">The password does not meet the requirements. Please review them and try again.</div>
                                    <div *ngIf="userInfo.get('password').errors.previouslyUsed"></div>
                                  </div>
                                </div>
                                <span class="mb-0 password-info pt-2">Minimum password length is 8 characters. it must include a combination of upper and lower case characters, digits, and symbols</span>
                              </div>
                              <div class="modal-footer" style="border-top: none">
                                <ng-container *ngIf="!loadInProgress">
                                  <button type="button" class="btn secondary-button m-1" routerLink="/op/forgotpassword">Forgot Password</button>
                                  <button type="submit" class="btn primary-button m-1" style="width: 132px">Continue</button>
                                </ng-container>
                                <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                  <div style="background-color: #405369"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </ng-container>
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 4">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1 text-center">
                              <h1 class="m-auto">Welcome to SustainLab</h1>
                              <p class="text-justify my-2">
                                {{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}
                                have selected you as the primary person to confirm the detail information about
                                <b> {{ invitationInfo["partnerName"] }} </b>
                                .Please help us to identify
                                <b> {{ invitationInfo["partnerName"] }} </b>
                                correctly. <br />
                                Is
                                <b>{{ invitationInfo["managementCompanyName"] }}</b>
                                the same as a company that you are affiliated in SustainLab platform now or in the past?
                              </p>
                              <select class="form-control" [formControl]="sameAsExisitingCompany">
                                <option value="-1">
                                  No, I have never been affiliated with
                                  {{ invitationInfo["managementCompanyName"] }}
                                  through SustainLab platform
                                </option>
                                <option [value]="company.id" *ngFor="let company of existingCompanies; let i = index">Yes, it is {{ company.name }}</option>
                              </select>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <ng-container *ngIf="!loadInProgress">
                                <button type="submit" class="btn primary-button custom-button" (click)="continueExistingUserCompanyForm()">Continue</button>
                              </ng-container>
                              <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 5 && sameAsExisitingCompany.value == '-1'">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="height: 70px; width: 200px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1" [formGroup]="confirmCompanyDetailsForm" (ngSubmit)="checkIdClash()">
                            <div class="row p-1 text-center">
                              <p class="text-justify my-2">
                                Is the below information provided for
                                <b> {{ invitationInfo["partnerName"] }} </b>
                                correct? Please modify the values if something is not accurate.
                              </p>
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label for="companyName" class="text-left">The complete legal name</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="companyName"
                                    placeholder="Partner Company Name"
                                    formControlName="companyName"
                                    [value]="invitationInfo['partnerName']"
                                    [ngClass]="{
                                      'is-invalid': confirmCompanyDetailsForm.get('companyName').errors && confirmCompanyDetailsForm.get('companyName').touched
                                    }"
                                  />
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="form-group text-left">
                                  <label for="industryGroup" class="text-left">Industry group</label>
                                  <select
                                    class="form-control"
                                    formControlName="industryGroup"
                                    [ngClass]="{
                                      'is-invalid': confirmCompanyDetailsForm.get('industryGroup').errors && confirmCompanyDetailsForm.get('industryGroup').touched
                                    }"
                                    required
                                  >
                                    <option *ngFor="let industryGroup of getAllIndustryGroups()" [value]="industryGroup">
                                      {{ industryGroup }}
                                    </option>
                                  </select>

                                  <label for="industry" class="text-left">Industry</label>
                                  <select
                                    class="form-control"
                                    formControlName="industry"
                                    [ngClass]="{
                                      'is-invalid': confirmCompanyDetailsForm.get('industry').errors && confirmCompanyDetailsForm.get('industry').touched
                                    }"
                                    required
                                  >
                                    <option *ngFor="let industry of getIndustriesForSelectedGroup()" [value]="industry">
                                      {{ industry }}
                                    </option>
                                  </select>

                                  <label for="country" class="text-left"
                                    >In which country is
                                    {{ invitationInfo["partnerName"] }}
                                    registered? If it is registered in more than one country, select the branch from which
                                    {{ invitationInfo["partnerName"] }} serves
                                    {{ invitationInfo["managementCompanyName"] }}
                                  </label>
                                  <select
                                    formControlName="country"
                                    class="form-control"
                                    [ngClass]="{
                                      'is-invalid': confirmCompanyDetailsForm.get('country').errors && confirmCompanyDetailsForm.get('country').touched
                                    }"
                                  >
                                    <option *ngFor="let countryData of countries" [value]="countryData[0]" style="font-family: 'Open Sans'; color: #405369">
                                      {{ resolveLabel(countryData[1]) }}
                                    </option>
                                  </select>
                                  <label for="website" class="text-left">Complete website address (e.g., https://www.example.com)</label>
                                  <input type="text" class="form-control" id="website" placeholder="Partner wesite" formControlName="website" />
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button custom-button" *ngIf="!loadInProgress">Continue</button>
                              <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 6">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="height: 70px; width: 200px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <p class="py-1 text-justify">We identified another company in our system with a very similar information as your's. Please compare their details and help us to know whether both are related to the same company or not.</p>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <h2>Your company Information</h2>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <h2>Similar company identified</h2>
                              </div>
                            </div>
                          </div>
                          <form action="#" style="height: 65vh; overflow-y: auto; overflow-x: hidden">
                            <div class="form-body">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Complete legal name</label>
                                    <input type="text" class="form-control" readonly [value]="partnerInvitationExtended.invitation.partnerName" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Complete legal name</label>
                                    <input type="text" class="form-control" readonly [value]="clashedCompany.name" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Industry group</label>
                                    <input type="text" class="form-control" readonly [value]="partnerInvitationExtended.invitation.partnerIndustryGroup" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Industry group</label>
                                    <input type="text" class="form-control" readonly [value]="clashedCompany.industryGroup" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Industry</label>
                                    <input type="text" class="form-control" readonly [value]="partnerInvitationExtended.invitation.partnerIndustry" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Industry</label>
                                    <input type="text" class="form-control" readonly [value]="clashedCompany.industry" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Registered country</label>
                                    <input type="text" class="form-control" readonly [value]="getCountryByKey(partnerInvitationExtended.invitation.partnerCountryCode)" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Registered country</label>
                                    <input type="text" class="form-control" readonly [value]="getCountryByKey(clashedCompany.country)" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Website</label>
                                    <input type="text" class="form-control" readonly [value]="partnerInvitationExtended.invitation.partnerWebsite" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label>Website</label>
                                    <input type="text" class="form-control" readonly [value]="clashedCompany.website" />
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-md-8">
                                  <div class="form-group">
                                    <select type="text" class="form-control" [formControl]="whichCompanyInformationIsCorrect">
                                      <option value="null" selected>Which option is correct</option>
                                      <option value="partnerInvitation">Both refer to the same company, but my information is more accurate</option>
                                      <option value="clashedCompany">Both refer to the same company, but other information is more accurate</option>
                                      <option value="none">Each of the information refer to a separate company</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group text-right float-end">
                                    <button type="submit" class="btn primary-button custom-button" *ngIf="!loadInProgress" (click)="continueClashedCompanyForm()">Submit</button>
                                    <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                      <div style="background-color: #405369"></div>
                                      <div style="background-color: #405369"></div>
                                      <div style="background-color: #405369"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 7">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="height: 70px; width: 200px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form action="#">
                            <div class="form-body">
                              <p class="py-1 text-justify">
                                Unfortunately we cannot register
                                {{ partnerInvitationExtended.invitation.partnerName }}. Please contact our administrator at hello@sustainlab.co
                              </p>
                              <div class="mt-2 text-right">
                                <button type="submit" class="btn primary-button custom-button" routerLink="/op/login">Home</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ng-container>
            <ng-container *ngIf="invitationRejected">
              <section class="row flexbox-container mt-1" *ngIf="viewNo == 2">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1">
                              <p class="text-justify mb-2">
                                Are you sure you want to reject the invitation of
                                <b>{{ invitationInfo?.getInviterName() }}</b> from <b>{{ invitationInfo["inviterCompanyName"] }}</b> ? Can you please specfy the reason or write a message for <b>{{ invitationInfo?.inviterFirstName + " " + invitationInfo?.inviterLastName }}</b
                                >?
                              </p>
                              <label>Please specify the reason for reject the invitation</label>
                              <select class="form-control" [formControl]="rejectionReason">
                                <option selected>Reason for rejecting the invite</option>
                                <option value="I don't know this person or their company">I don't know this person or their company</option>
                                <option value="I wasn't aware that we are going to connect through SustainLab, I need to check it first">I wasn't aware that we are going to connect through SustainLab, I need to check it first</option>
                                <option value="Other reasons">Other reasons</option>
                              </select>
                              <label class="mt-2">Kindly provide below your reasons for rejecting this invitation.</label>
                              <textarea class="form-control" rows="5" [formControl]="rejectionExplanation" [placeholder]="'It would be great if you can write a message to' + invitationInfo?.inviterFirstName + '' + invitationInfo?.inviterLastName + 'that he knows  why you are rejecting this invitation.'"></textarea>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button" *ngIf="!loadInProgress" [disabled]="rejectionReason.errors['required']" (click)="confirmRejectInvitation()">Reject Invitation</button>
                              <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                                <div style="background-color: #405369"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="row flexbox-container mb-1" *ngIf="viewNo == 3">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                      <div class="card-header pb-0">
                        <div class="card-title text-center">
                          <img class="img-responsive img-fluid" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
                        </div>
                      </div>
                      <div class="card-content">
                        <div class="card-body pb-0 pt-0">
                          <form class="form form-horizontal text-center p-1">
                            <div class="row p-1">
                              <p class="text-justify mb-1">
                                Thank you for letting us know you that this was not a right invitation. We will inform
                                <b>{{ invitationInfo?.getInviterName() }}</b> from <b>{{ invitationInfo["inviterCompanyName"] }}</b>
                                about it.
                              </p>
                            </div>
                            <div class="modal-footer float-end" style="border-top: none">
                              <button type="submit" class="btn primary-button custom-button" routerLink="op/login">Home</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>


  <!--Register new user with existing email-->
  <div class="app-content container center-layout" *ngIf="isNewUser.value === 'false'">
    <div class="content-wrapper">
      <div class="content-body">
  <section class="row flexbox-container mt-5" >
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
          <div class="card-header pb-0">
            <div class="card-title text-center">
              <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="width: 150px; height: 60px" />
            </div>
          </div>
          <div class="card-content">
            <div class="card-body pb-0 pt-0">
              <form class="form form-horizontal text-center p-1" [formGroup]="userInfo" >
                <div class="row p-1 text-center">
                  <h1 class="m-auto">Welcome to SustainLab</h1>
                  <p class="text-justify my-2 mx-auto">We are completing your registration, you will be redirected shortly to the Login page.</p>
                </div>
                
                <div class="modal-footer" style="border-top: none">
                  
                  <div class="ball-pulse loader-primary mx-auto" *ngIf="loadInProgress">
                    <div style="background-color: #405369"></div>
                    <div style="background-color: #405369"></div>
                    <div style="background-color: #405369"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</div>
</div>
  
  </ng-container>

</body>
