<div class="formula_operator_dropdown">
  <div class="title">{{ locale("locale_key.pages.emission.select_what_you_like_insert_here") }}</div>
  <div class="operator_groups">
    <div class="operator_list operator_group">
      <div class="operator plus" (click)="setOperator({ tokenIdx, operator:'plus' })">
        <svg class="operator_icon operator_icon_plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M11.25 3.75V11.25H3.75V12.75H11.25V20.25H12.75V12.75H20.25V11.25H12.75V3.75H11.25Z" fill="#374659" />
        </svg>
      </div>
      <div class="operator minus" (click)="setOperator({ tokenIdx, operator:'minus' })">
        <svg class="operator_icon operator_icon_minus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M3.75 11.25V12.75H20.25V11.25H3.75Z" fill="#374659" />
        </svg>
      </div>
      <div class="operator times" (click)="setOperator({ tokenIdx, operator:'times' })">
        <svg class="operator_icon operator_icon_times" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18 6L6 18" stroke="#374659" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6 6L18 18" stroke="#374659" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="operator divide" (click)="setOperator({ tokenIdx, operator:'divide' })">
        <svg class="operator_icon operator_icon_divide" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 4.5C10.7666 4.5 9.75 5.5166 9.75 6.75C9.75 7.9834 10.7666 9 12 9C13.2334 9 14.25 7.9834 14.25 6.75C14.25 5.5166 13.2334 4.5 12 4.5ZM12 6C12.4219 6 12.75 6.32812 12.75 6.75C12.75 7.17188 12.4219 7.5 12 7.5C11.5781 7.5 11.25 7.17188 11.25 6.75C11.25 6.32812 11.5781 6 12 6ZM3.75 11.25V12.75H20.25V11.25H3.75ZM12 15C10.7666 15 9.75 16.0166 9.75 17.25C9.75 18.4834 10.7666 19.5 12 19.5C13.2334 19.5 14.25 18.4834 14.25 17.25C14.25 16.0166 13.2334 15 12 15ZM12 16.5C12.4219 16.5 12.75 16.8281 12.75 17.25C12.75 17.6719 12.4219 18 12 18C11.5781 18 11.25 17.6719 11.25 17.25C11.25 16.8281 11.5781 16.5 12 16.5Z"
            fill="#374659" />
        </svg>
      </div>
      <div class="operator open_bracket" (click)="setOperator({ tokenIdx, operator:'openBracket' })">
        <svg class="operator_icon operator_icon_open_bracket" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M8 12.1075C8 10.6114 8.14334 9.15118 8.43001 7.72676C8.71669 6.29339 9.16013 4.92721 9.76036 3.62822C10.3606 2.32027 11.1221 1.11086 12.0448 0H15.4043C14.1501 1.72004 13.196 3.61926 12.542 5.69765C11.897 7.77604 11.5745 9.9037 11.5745 12.0806C11.5745 13.4961 11.7178 14.9071 12.0045 16.3135C12.2912 17.7111 12.7167 19.0594 13.2811 20.3583C13.8544 21.6573 14.5532 22.8712 15.3774 24H12.0448C11.1221 22.925 10.3606 21.7469 9.76036 20.4658C9.16013 19.1937 8.71669 17.8544 8.43001 16.4479C8.14334 15.0325 8 13.5857 8 12.1075Z"
            fill="#374659" />
        </svg>
      </div>
      <div class="operator close_bracket" (click)="setOperator({ tokenIdx, operator:'closeBracket' })">
        <svg class="operator_icon operator_icon_close_bracket" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M15.4043 12.1075C15.4043 10.6114 15.261 9.15118 14.9743 7.72676C14.6876 6.29339 14.2442 4.92721 13.6439 3.62822C13.0437 2.32027 12.2822 1.11086 11.3595 0H8.00004C9.25424 1.72004 10.2083 3.61926 10.8623 5.69765C11.5073 7.77604 11.8298 9.9037 11.8298 12.0806C11.8298 13.4961 11.6865 14.9071 11.3998 16.3135C11.1131 17.7111 10.6876 19.0594 10.1232 20.3583C9.54987 21.6573 8.85111 22.8712 8.02692 24H11.3595C12.2822 22.925 13.0437 21.7469 13.6439 20.4658C14.2442 19.1937 14.6876 17.8544 14.9743 16.4479C15.261 15.0325 15.4043 13.5857 15.4043 12.1075Z"
            fill="#374659" />
        </svg>
      </div>
    </div>
    <div class="separator"></div>
    <div class="operator_group">
      <div class="add_datapoint_fixed_number_container" [class.adding]="showAddFixedNumberInput">
        <div *ngIf="showDatapoints" class="add_datapoint_btn" (click)="setSelectDataInputOperand({ tokenIdx })">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12" stroke="#374659" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 8C16.9706 8 21 6.65685 21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5C3 6.65685 7.02944 8 12 8Z" stroke="#374659" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 5V19C3 20.66 7 22 12 22C17 22 21 20.66 21 19V5" stroke="#374659" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="label">Datapoint</span>
        </div>
      </div>
      <div class="add_datapoint_fixed_number_container" [class.adding]="showAddFixedNumberInput">
        <div class="add_fixed_number_input_container">
          <input type="number" step="any" class="add_fixed_number_input" [(ngModel)]="addFixedNumberValue" placeholder="{{ locale('locale_key.pages.data_supplier_questionnaire.type_number') }}" />
          <unit-selector [includeMeasurementTypes]="includeMeasurementTypes" [mode]="'select'" [selectBy]="'symbol'" [selectedUnitContainer]="unitInput" [source]="'formula_builder_dropdown'" [customUnits]="customUnits"
            [unitsByMeasurementType]="unitsByMeasurementType"></unit-selector>
          <div class="add_fixed_number_input_control" (click)="addFixedNumber({ tokenIdx })">
            <i class="icon las la-plus"></i>
            <span class="label">Add</span>
          </div>
        </div>
        <div class="add_fixed_number_btn" (click)="startAddFixedNumber()">
          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M21 9.75H14.25V3C14.25 2.17172 13.5783 1.5 12.75 1.5H11.25C10.4217 1.5 9.75 2.17172 9.75 3V9.75H3C2.17172 9.75 1.5 10.4217 1.5 11.25V12.75C1.5 13.5783 2.17172 14.25 3 14.25H9.75V21C9.75 21.8283 10.4217 22.5 11.25 22.5H12.75C13.5783 22.5 14.25 21.8283 14.25 21V14.25H21C21.8283 14.25 22.5 13.5783 22.5 12.75V11.25C22.5 10.4217 21.8283 9.75 21 9.75Z"
              fill="#374659" />
          </svg>
          <span class="label">Add fixed number</span>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="operator_group">
      <div class="add_before_after_container">
        <div class="add_before_btn" (click)="addControlBefore({ tokenIdx })">
          <i class="icon las la-plus-circle"></i>
          <span class="label">Add before</span>
        </div>
        <div class="add_after_btn" (click)="addControlAfter({ tokenIdx })">
          <i class="icon las la-plus-circle"></i>
          <span class="label">Add after</span>
        </div>
      </div>
      <div class="separator"></div>
      <div class="remove_btn" (click)="removeToken({ tokenIdx })">
        <i class="icon las la-trash-alt"></i>
        <span class="label">Remove</span>
      </div>
    </div>
  </div>
</div>
<ng-template #emissionFactorFireIcon>
  <svg style="margin-right: .2rem;" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.59912 0.292969L6.35205 4.04004L4.96484 2.6543L4.4375 3.25342C1.82525 6.21442 0.5 8.90475 0.5 11.25C0.5 14.9715 3.8645 18 8 18C12.1355 18 15.5 14.9715 15.5 11.25C15.5 7.6785 11.6023 3.27455 9.27881 0.966797L8.59912 0.292969ZM8.89355 2.71729C10.8256 4.73179 14 8.466 14 11.25C14 13.0434 12.9652 14.6282 11.3926 15.5757C11.6165 15.0406 11.75 14.4506 11.75 13.8223C11.75 12.0365 10.4865 9.91829 9.42676 8.45654L8.79395 7.58203L7.13281 10.0737L6.07812 9.01904L5.58301 9.83643C4.69876 11.2959 4.25 12.6365 4.25 13.8223C4.25 14.4506 4.38349 15.0406 4.60742 15.5757C3.0348 14.6282 2 13.0434 2 11.25C2 9.45825 3.04823 7.25753 5.04248 4.85303L6.64795 6.45996L8.89355 2.71729ZM8.82764 10.2363C9.75014 11.6741 10.25 12.9253 10.25 13.8223C10.25 15.299 9.2405 16.5 8 16.5C6.7595 16.5 5.75 15.299 5.75 13.8223C5.75 13.1308 5.96768 12.3392 6.39893 11.4609L7.36719 12.4277L8.82764 10.2363Z"
      fill="#374659" />
  </svg>
</ng-template>
