import { Component, OnInit } from "@angular/core";
import { CompanyFe } from "src/app/model/data-suppliers/company/CompanyFe";
import { PartnerInvitationFe } from "src/app/model/data-suppliers/company/PartnerInvitationFe";
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import COUNTRIES_DATA from "../../../model/COUNTRIES.json"

@Component({
  selector: "partner-details",
  templateUrl: "./details.component.html",
  styleUrls: ["../../details/details.component.scss"],
})
export class PartnerDetailsComponent extends AbstractLanguageComponent implements OnInit {
  data: PartnerInvitationFe | SupplierCompanyFe
  invitation: PartnerInvitationFe;
  company: any;
  viewNo: number = 1;

  constructor(private displayService: DisplayServiceFe, languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit() {
    if (this.displayService.detailsData) {
      this.data = this.displayService.detailsData.data;
      if (this.data instanceof PartnerInvitationFe) {
        this.invitation = this.data;
      }else{
        this.company = this.data;
      }
    }
    this.displayService.detailsDataUpdatedSubject.subscribe((isUpdated) => {
      if (isUpdated) {
        const data = this.displayService.detailsData;
        this.data = data.data;
        if (this.data instanceof PartnerInvitationFe) {
          this.invitation = this.data;
          this.company = null
        }else{
          this.invitation = null
          this.company = this.data;
        }
      }
    });
  }

  resolveLabel(label: any): string {
    if (!label) {
      return ''
    }

    return label[this.activeLanguage?.code] || label['en'] || label
  }
  
  getCountry(code: string){
    let data = Object.entries(COUNTRIES_DATA).find(data=> data[0]== code)
    return this.resolveLabel(data[1])
  }
}
