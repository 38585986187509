import { DataGridRow } from "src/app/components/projects/data-grid-ui/model/DataGridRow";
import { DataGridTableData } from "src/app/components/projects/data-grid-ui/model/DataGridTableData";
import { DataGridTableMode } from "src/app/components/projects/data-grid-ui/model/DataGridTableMode";
import { DataGridObserverInterface } from "src/app/components/projects/data-grid-ui/service/DataGridObserverInterface";
import { DataGridServiceInterface } from "src/app/components/projects/data-grid-ui/service/DataGridServiceInterface";

export class DataGridIntegrationService implements DataGridServiceInterface {
  
  database: DataGridTableData = 
  {
    name: '',
    dataSchema: [],
    referenceSchema: [],
    rows: [],
    pageNumber: 1,
    pageSize: 10,
    rowCount: 0,
    ordered: true,
    key: ''
  };
 
  referencedTables : DataGridTableData[] = [];
  observer: DataGridObserverInterface | undefined
  modified: boolean = false
  mode = DataGridTableMode.EDIT_TABLE_SCHEMA; //was multi select for multi table and changing schema
  hasPagination = false;

  constructor() {}
 
  registerObserver(observer: DataGridObserverInterface): void{
    this.observer = observer;
  }

  getMode(): string{
    return this.mode;
  }

  getMainTableData(): DataGridTableData{
    return this.database;
  }

  hasMainTablePagination(): boolean{
    return this.hasPagination;
  }

  loadMainTablePage(pageSize: number, pageNumber: number): Promise<DataGridTableData>{
    return  new Promise<DataGridTableData>((resolve)=>{
        resolve(this.database)
    });
  }

  markDataModified(modified: boolean, isDataValid: boolean): void{
      this.modified=modified;
  }

  hasDataModifiedElseWhere(){
    return this.modified;
  }

  getReferenceTables(){
    return this.referencedTables;
  }

  saveReferenceTable(table:DataGridTableData){
  }
  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
  }

  getNoOfMinRows(): number {
    return 10;
  }

}