import { AbstractSignInMethodFe } from "./AbstractSignInMethodFe"

export class SigninMethodUpdateTypeFe {
  public static COMPANY = 'company';
  public static USER = 'user';
}

export class SignInMethodUpdateFe {

  signInMethods: AbstractSignInMethodFe[];
  mfaRequired: boolean;
  prevMfaRequired: boolean;
  type: string;
  userId: string | null;
  affiliationId: string | null;
  email: string | null;
  companyId: string | null;

  constructor (
    signInMethods: AbstractSignInMethodFe[],
    mfaRequired: boolean,
    prevMfaRequired: boolean,
    type: string
  ) {
    this.signInMethods = signInMethods;
    this.mfaRequired = mfaRequired;
    this.prevMfaRequired = prevMfaRequired;
    this.type = type;
  }

  setUserDetails(userId: string, affiliationId: string, email: string) {
    this.userId = userId;
    this.affiliationId = affiliationId;
    this.email = email;
  }

  setCompanyDetails(companyId: string) {
    this.companyId = companyId;
  }
}