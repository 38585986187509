import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe"

export class PredefinedEmissionFactorOptionFe{
  selectedEmissionFactor: AbstractEmissionFactorFe

  constructor(selectedEmissionFactor?: AbstractEmissionFactorFe) {
    this.selectedEmissionFactor = selectedEmissionFactor
  }

  public static fromJsonObj(obj: any): PredefinedEmissionFactorOptionFe {
    return new PredefinedEmissionFactorOptionFe(obj.selectedEmissionFactor)
  }
}
