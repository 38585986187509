import { MathStatementFe } from "./MathStatementFe";

export class VariableStatementFe extends MathStatementFe {
  public static TYPE_KEY = 'VARIABLE'
  public type = VariableStatementFe.TYPE_KEY;

  constructor(public readonly unit: string, public readonly entityKey: string, public readonly entityLabel: string, public readonly attributeKey: string, public readonly attributeLabel: string) {
    super()
  }

  public toString(): string {
    return this.attributeLabel 
  }

  public flatten(): any[] {
    return [this]
  }

  isPlaceholder() {
    return false
  }

  public tooltip(): string {
    let attr = this.attributeLabel ? this.attributeLabel.toLocaleLowerCase() : '';
    let entity = this.entityLabel ? this.entityLabel.toLocaleLowerCase() : '';
    return `The ${attr} of ${entity}`  
  }

}