import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { FlowChartOrganizationEntity } from "../model/FlowChartOrganizationEntity";
import { FlowChartOrganizationInfo } from "../model/FlowChartOrganizationInfo";
import { CanvasFlow } from "./ng-flowchart-canvas.service";
import { OrganizationChartIntegrationInterface } from "./OrganizationChartIntegrationInterface";

@Injectable({
    providedIn: "root"
})

export class ShareDataService{

    dataUpdated$ = new Subject<FlowChartOrganizationEntity>();
    chartUpdated$ = new Subject();
    refreshChart$ = new Subject<FlowChartOrganizationEntity[]>();
    public newOrgFlow! : FlowChartOrganizationInfo;
    public canvasFlow!: CanvasFlow
    public orgChartService!:OrganizationChartIntegrationInterface;

    updateOrgInfo(data:FlowChartOrganizationEntity){
        this.dataUpdated$.next(data);
    }

}