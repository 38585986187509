import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import COUNTRIES_DATA from "./../../model/COUNTRIES.json";
import INDUSTRIES_DATA from "./../../model/INDUSTRIES.json"
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { ValidationMessages } from "./../../model/form-validation/FormValidationMessages";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { Company_AddFe } from "src/app/model/invitation/Company_AddFe";
import { RouterFe } from "src/app/route/RouterFe";
import { CompanyFe } from "src/app/model/data-suppliers/company/CompanyFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";


@Component({
  selector: 'app-customer-entry',
  templateUrl: './customer-entry.component.html',
  styleUrls: ['./customer-entry.component.scss']
})
export class CustomerEntryComponent extends AbstractLanguageComponent implements OnInit {
  countries = Object.entries(COUNTRIES_DATA);
  industries = INDUSTRIES_DATA;
  form1 = new FormGroup({
    name: new FormControl("", [Validators.required]),
    companyAccountPurpose: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.required]),
    industryGroup: new FormControl( null, [Validators.required]),
    industry: new FormControl(null, [Validators.required]),
    website: new FormControl("", [Validators.required, Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)]),
  });  
  
  form2 = new FormGroup({
    primaryContactFirstName: new FormControl("", [Validators.required, Validators.pattern("^[A-Z]+[a-zA-Z]*$")]),
    primaryContactLastName: new FormControl("", [Validators.required, Validators.pattern("^[A-Z]+[a-zA-Z]*$")]),
    primaryContactEmail: new FormControl("", [Validators.required, Validators.email]),
  });

  form3 = new FormGroup({
    emailSubject: new FormControl("Invitation from SustainLab", [Validators.required]),
    emailContent: new FormControl("", [Validators.required]),
  });
  activeFormNo = 1;
  inviterCompany: string = "SL";
  progress: HTMLElement | undefined;
  circles: any;
  prevButton: HTMLButtonElement | undefined;
  nextButton: HTMLButtonElement | undefined;
  currentActive: number = 1;
  companyNameMessage = this.locale('locale_key.general.validation_message.company_name_required')
  companyCountry = this.locale('locale_key.general.validation_message.country_required')
  companyIndustryGroup = this.locale('locale_key.general.validation_message.industry_group_required')
  companyIndustry = this.locale('locale_key.general.validation_message.industry_required')
  companyWebAddress = this.locale('locale_key.general.validation_message.company_web_address_required');
  companyWebFromatMessage = this.locale('locale_key.general.validation_message.invalid_web_address');
  firstNameMessage = this.locale('locale_key.general.validation_message.first_name_required')
  lastNameMessage = this.locale('locale_key.general.validation_message.last_name_required')
  emailMessage = this.locale('locale_key.general.validation_message.email_required');
  emailFormatMessage = this.locale('locale_key.general.validation_message.invalid_email');
  industryGroupChangeVal: boolean = false;
  industryChangeVal: boolean = false;
  isLoading: boolean = false;

  isCompanyCreated = false;
  isTaxonomyCopied = false;
  isKPICopied = false;
  isDashboardCopied = false;
  isDraftRequestCopied = false;

  
  @Input() mode = "add";
  invitationLink: { uuid: string; serverAddress: string };
  invitationLinkGenerated = false;
  
  @Input() company: CompanyFe

  constructor(private modalRef: BsModalRef, private modalService: BsModalService,
  private stateService: StateServiceFe, languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit(): void {
    if (this.company) {
      this.form1.controls.name.setValue(`${this.company.name}(2)`);
      this.form1.controls.companyAccountPurpose.setValue(this.company.companyAccountPurpose);
      this.form1.controls.country.setValue(this.company.countryCode);
      this.form1.controls.industryGroup.setValue(this.company.industryGroup);
      this.form1.controls.industry.setValue(this.company.industry);
      this.form1.controls.website.setValue(`${this.company.website}(2)`);
    }
  }

  resolveLabel(label: any): string {
    if (!label) {
      return ''
    }

    return label[this.activeLanguage?.code] || label['en'] || label
  }
  
  async addNewCompany() {
    try {
      this.isLoading = true;

      let responseGroup, responseIndustry
      this.industries.forEach((data, index) => {
        if (index != 0 && !responseGroup && !responseIndustry) {
          if (data.industryGroup[this.activeLanguage.code] == this.form1.controls.industryGroup.value) {
            responseGroup = data.industryGroup['en']

            data.industry.forEach(ind => {
              if (ind[this.activeLanguage.code] == this.form1.controls.industry.value) {
                responseIndustry = ind['en']
              }
            });
          }
        }
      })

      const newCompany = new Company_AddFe(this.form1.controls.name.value, this.form1.controls.country.value, responseGroup, responseIndustry, 
      this.form1.controls.website.value, this.form1.controls.companyAccountPurpose.value);
      let newCompanyId = await this.stateService.addManagementCompany(newCompany);
      this.isCompanyCreated = true;
      if (this.mode=='duplicate') {
        this.activeFormNo++;
        await this.stateService.copyTaxonomyDataToNewCompany(newCompanyId, this.company.id);
        await this.stateService.upgradeTaxnomyDataForNewCompany(newCompanyId);
        this.isTaxonomyCopied = true;
        await this.stateService.copyKPIsToNewCompany(newCompanyId, this.company.id);
        this.isKPICopied = true;
        await this.stateService.copyDashboardsToNewCompany(newCompanyId, this.company.id);
        this.isDashboardCopied = true;
        await this.stateService.copyDraftRequestsToNewCompany(newCompanyId, this.company.id);
        this.isDraftRequestCopied = true;
      }
      this.isLoading = false;
      this.closeModal();
    } catch (err) {
      this.isLoading = false;
    }
  }

  closeModal(): void {
    this.modalService.hide();
    document.body.classList.remove("modal-open");
  }


  getIndustry(): string[]{
    let industryGroup = this.industries.find(data => data.industryGroup[this.activeLanguage.code] == this.form1.controls.industryGroup.value);
    if (industryGroup) {
      return industryGroup.industry.map(ind=> ind[this.activeLanguage.code]) as string[]
    }
    return []
  }

}
