import { OrganizationEntityFe } from "./OrganizationEntityFe";

export class OrganizationInfoFe {
  public version: number
  public entities: OrganizationEntityFe[]
  public deletedEntities: OrganizationEntityFe[] = []

  public modified = false

  constructor(version:number, entities: OrganizationEntityFe[]) {
    this.version = version
    this.entities = entities
  }

  public static fromTransfer(transfer: any, toCompareWith: OrganizationInfoFe | undefined): OrganizationInfoFe {
    let entities = transfer.entities.map(be => OrganizationEntityFe.fromTransfer(be));
    let orgInfo = new OrganizationInfoFe(transfer.version, entities)
    if (toCompareWith) {
      orgInfo.diffDeployment(toCompareWith)
    }
    return orgInfo
  }

  diffDeployment(depOrg: OrganizationInfoFe) {
    this.deletedEntities = []
    depOrg.entities.forEach(de => {
      let entity = this.entities.find(e => e.key == de.key)
      if (!entity) {
        this.deletedEntities.push(de)
      }
    })
    this.entities.forEach(e1 => {
      let deployedEnity = depOrg.entities.find(e2 => e2.key == e1.key)
      if (deployedEnity) {
        e1.diffDeployment(e1.getParents(this), deployedEnity, deployedEnity.getParents(depOrg))
      } else {
        e1.diffDeployment(e1.getParents(this), undefined, undefined)
      }
    })

    let modifiedEntity = this.entities.find(e => !e.deployed || e.modified)

    this.modified = (this.deletedEntities.length > 0 || modifiedEntity) ? true : false
  }

}