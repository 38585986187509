import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';


@Component({
  selector: 'app-reference-widgets',
  templateUrl: './reference-widgets.component.html',
  styleUrls: ['./reference-widgets.component.scss'],

})

export class ReferenceWidgetsComponent extends AbstractLanguageComponent implements OnInit {
  colors: any = ['var(--main-dark-color1)', 'var(--main-light-color)', 'var(--main-gradient-start-color)',
    'var( --main-gradient-end-color)', 'var(--main-gradient-color)', 'var(--main-contrast-color)', 'var(--background-dark-color)', 'var(--background-light-color)',

    'var(--complementary-color1)', 'var(--complementary-color2)', 'var(--complementary-color3)',

    'var(--warning-color)', 'var(--danger-color)', 'var(--orange)',
    'var(--success-color)', 'var(--info-color)', 'var(--light-color)', 'var(--dark-color)', 'var(--main-color)',
    'var(--purple)', 'var(--green)', 'var(--cropper-overlay-color)', 'var(--cropper-outline-color)'

  ];




  constructor(languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit(): void {
  }

}
