import { QUESTION_TYPE } from "../../../request/questionnaire/QuestionTypeFe";
import { AnswerFe } from "../../answer/AnswerFe";
import { Answer_AddFe } from "./Answer_AddFe";

export class AnswerNumber_AddFe extends Answer_AddFe{
  value: number
  unit: string

 constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    value: number,
    unit: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.NUMBER,
      comments
    )
    this.value = value
    this.unit = unit
  }

  public isInvalid (isRequired: boolean): boolean {
    if (isRequired) {
      return !this.hasAnyValue()
    }
    return false
  }

  public hasAnyValue(): boolean {
    return this.value || this.value==0 ? true : false
  }

  public reset (): void {
    this.value = null
  }

  public toTransfer() {
    let ans = new AnswerNumber_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.value,
      this.unit
    )
    return ans
  }

}
