import { Component, OnInit, EventEmitter, Input, Output, TemplateRef, ViewChild, HostListener, ElementRef } from "@angular/core"
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { KpiDefFe } from "src/app/model/kpi/KpiDefFe"
import { PipelineDefFe } from "src/app/model/pipeline/PipelineDefFe"
import { PipelineFe } from "src/app/model/pipeline/PipelineFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { IdUtil } from "src/app/utils/IdUtil"
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { Chart, ChartConfiguration } from "chart.js"
import { RGB } from "src/app/model/chart/RGB"
import { KpiFilterFe } from "src/app/model/kpi/filter/KpiFilterFe"
import { TwoOperandOperatorFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandOperatorFe"
import { TwoOperandStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandStatementFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { AttachmentFe } from "src/app/model/details/AttachmentFe"
import { MasterTableDataGridIntergationService } from "../../model/taxonomy/MasterTableDataGridIntergationService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { DataPointFe } from "src/app/model/insight/DataPointFe"
import { DataSeriesFe } from "src/app/model/insight/DataSeriesFe"
import { InsightDefFe } from "src/app/model/insight/InsightDefFe"
import { TimeGroupingFe } from "src/app/model/insight/TimeGroupingFe"
import { GroupingFe } from "src/app/model/insight/GroupingFe"
import { InsightProcessingPipelineDefFe } from "src/app/model/insight/InsightProcessingPipelineDefFe"
import { PipelineFunctionFe } from "src/app/model/insight/PipelineFunctionFe"
import { Calculation, CalculationCondition, CalculationThenOptions, Condition, ConditionComparators } from "../data-categories/calculation-builder/model/CalculationBuilderModels"
import { KpiFilterFe2 } from "src/app/model/kpi/filter/KpiFilterFe2"
import { DataCategoryServiceFe } from "src/app/services/DataCategoryServiceFe"
import { DataGridColumnType } from "../projects/data-grid-ui/model/DataGridColumnType"
import { groupUnitsByMeasurementTypeAndSystem } from "../unit-systems/model/utils"
import { UnitFe } from "../unit-systems/model/UnitFe"
import { UnitMeasurementTypes } from "../unit-systems/model/UnitMeasurementType"
import { UnitServiceFe } from "src/app/services/UnitServiceFe"

@Component({
  selector: "insight-detail",
  templateUrl: "./insight.detail.component.html",
  styleUrls: ["./insight.detail.component.scss"],
})
export class InsightDetailComponent extends AbstractLanguageComponent implements OnInit {
  isLoadingData = true
  inProgress = false
  loadingMasterTable = false
  taxonomyInfo: TaxonomyInfoFe = undefined
  timely: boolean = false
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  @ViewChild("mainContainer") mainContainer: ElementRef
  containerHeight: number
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.adjustContainerHeight()
  }

  baseUnitOptions = ["liter", "ton", "kg", "kwh", "mwh"]
  @Input() edit: boolean = false
  @Input() duplicate: boolean = false
  @Input() insightDef: InsightDefFe
  @Output() closeEvent = new EventEmitter<boolean>()

  viewNo = 1
  taxonomyForm = new FormGroup({
    item0: new FormControl("", Validators.required),
    item1: new FormControl({ value: "", disabled: true }, Validators.required),
    item2: new FormControl({ value: "", disabled: true }, Validators.required),
  })
  selectedTaxonomyKeys = {
    item0: null,
    item1: null,
    item2: null,
  }
  categoryChangedTo: { itemNo: number; label: string; key: string }
  dataScopeMessage = this.locale("locale_key.general.validation_message.data_category_required")

  measurementType: string
  unitSymbol: string
  unitLabel: string
  modalRef: BsModalRef
  title = ""
  kpiId = null
  @ViewChild("canvas") canvas
  kpiPrevieChart: Chart
  chartType: string
  compareOn: GroupingFe
  data: any
  isFilter = false
  filters: KpiFilterFe2[] = []
  error = { taxonomy: false, measurementField: false, noOfMeasurementField: 0, groupingField: false, noOfGroupingField: 0, filter: false, noOfFilter: 0 }
  steps = [
    { title: this.locale("locale_key.pages.kpi_library.kpi_wizard.setup_kpi.title"), completed: false },
    { title: this.locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.title"), completed: false },
    { title: this.locale("locale_key.pages.kpi_library.kpi_wizard.name.title"), completed: false },
  ]

  //
  dataSeries: DataSeriesFe[] = []
  selectedDataSeries: DataSeriesFe
  selectedDataSeriesIndex: number
  define = null
  timeGrouping: TimeGroupingFe
  groupings: GroupingFe[] = []
  selectedGroupingIndex: number
  uniqueEntities: Map<string, EntityFe> = new Map()
  pipelineFunctions = PipelineFunctionFe.getAllFunctions()
  filterCondition: Condition
  selectedEntityKey

  units = []
  customUnits = []
  unitsByMeasurementType = []

  constructor(
    private readonly FormBuilder: FormBuilder,
    private modalService: BsModalService,
    public displayService: DisplayServiceFe,
    private stateService: StateServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    public categoryService: DataCategoryServiceFe,
    private unitService: UnitServiceFe
  ) {
    super(languageService)

    this.measurementType = this.unitService.getNoUnit().measurementType

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits()
    })
    this.containerHeight = window.innerHeight - 200
  }

  async ngOnInit(): Promise<void> {
    this.isLoadingData = true
    this.taxonomyInfo = await this.categoryService.getTaxonomy()
    await this.loadUnits()
    if (this.insightDef) {
      await this.viewKpi()
    }
    this.isLoadingData = false
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits()
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom)
  }

  ngAfterViewInit() {
    if (!this.insightDef) {
      this.addDataSeries()
      setTimeout(() => this.buildChart())
    }
    this.adjustContainerHeight()
  }

  private adjustContainerHeight() {
    this.screenSize = this.responsive.currentScreenWidthSize
    if (this.screenSize == ScreenWidthSizeFe.WIDTH_SMALL || this.screenSize == ScreenWidthSizeFe.WIDTH_MEDIUM || this.screenSize == ScreenWidthSizeFe.WIDTH_XSMALL) {
      this.containerHeight = window.innerHeight - 300
    } else {
      this.containerHeight = window.innerHeight - 250
    }
  }

  async viewKpi() {
    this.isLoadingData = true
    this.kpiId = this.duplicate ? IdUtil.next() : this.insightDef.id
    this.title = this.duplicate ? `Copy of ${this.insightDef.title}` : this.insightDef.title
    let pipelineDef = this.insightDef.definition.clone()
    this.dataSeries = pipelineDef.dataSeries
    this.unitLabel = pipelineDef.unitLabel
    this.unitSymbol = pipelineDef.unitSymbol
    const noUnit = this.unitService.getNoUnit()
    this.measurementType = this.unitSymbol ? this.units.find((unit) => unit.symbol == this.unitSymbol)?.measurementType : noUnit.measurementType
    this.timeGrouping = pipelineDef.timeGrouping
    this.groupings = pipelineDef.groupings
    this.filters = pipelineDef.filters
    this.compareOn = this.groupings[0]
    this.setUniqueEntities()
    this.isLoadingData = false
    setTimeout(() => {
      this.buildChart()
    })
  }

  async openConnectedActionsModal(templateRef: TemplateRef<any>) {
    if (this.categoryService.totalChanges() > 0) {
      this.openModal(templateRef)
    } else {
      this.createInsight()
    }
  }

  async deployTaxonomy() {
    this.inProgress = true
    await this.categoryService.deploy()
    await this.createInsight()
    this.closeModal()
    this.inProgress = false
  }

  async createInsight() {
    this.inProgress = true
    if (this.unitSymbol) {
      this.unitLabel = null
    }
    let insightPipelineDef = InsightProcessingPipelineDefFe.toTransfer(this.dataSeries, this.unitLabel, this.unitSymbol, this.timeGrouping, this.groupings, this.filters)
    let insightDef = new InsightDefFe(this.kpiId, this.title, new Date(), insightPipelineDef)
    if (this.edit) {
      await this.stateService.modifyInsightDef(insightDef)
    } else {
      insightDef.id = IdUtil.next()
      await this.stateService.addInsightDef(insightDef)
    }
    this.closeEvent.emit(true)
    this.inProgress = false
  }

  delete() {
    this.stateService.deleteInsightDef(this.insightDef.id)
    this.closeEvent.emit(true)
  }

  cancel() {
    this.closeEvent.emit(true)
    this.closeModal()
  }

  findColumnsByType(types: string[]): TaxonomyAttributeFe[] {
    if (this.taxonomyForm.controls.item2 && this.taxonomyInfo) {
      let table = this.taxonomyInfo.entities.find((t) => t.key == this.taxonomyForm.controls.item2.value)
      if (table) {
        return table.columns.filter((c) => types.includes(c.datatype))
      } else {
        return []
      }
    } else {
      return []
    }
  }

  isTimeScaleSelected(value: string) {
    let isSelected = this.timeGrouping.timeScales.includes(value)
    return isSelected
  }

  toggleTimeScale(event: any) {
    if (event.currentTarget.checked) {
      this.timeGrouping.timeScales.push(event.currentTarget.value)
    } else {
      this.timeGrouping.timeScales = this.timeGrouping.timeScales.filter((v) => v != event.currentTarget.value)
    }
  }

  previous() {
    this.steps[this.viewNo - 1].completed = false
    this.viewNo--
  }

  next() {
    this.steps[this.viewNo - 1].completed = true
    this.viewNo++
    if (this.viewNo == 2) {
      this.setUniqueEntities()
      this.error.measurementField = false
      this.groupings.forEach((grouping) => {
        this.uniqueEntities.forEach((entity, key) => {
          let hasDataPoint = grouping.dataPoints.find((d) => d.taxonomyKey == key)
          if (!hasDataPoint) {
            let dp = new DataPointFe()
            dp.setTaxonomy(key)
            let stringCols = this.findEntityColumnsByType(["STRING", "BOOLEAN"], key)
            dp.taxonomyKey = key
            dp.attributeKey = stringCols[0]?.key
            grouping.dataPoints.push(dp)
          }
        })
        grouping.dataPoints = grouping.dataPoints.filter((dp) => this.uniqueEntities.has(dp.taxonomyKey))
      })
    } else {
      this.error.groupingField = false
      this.error.filter = false
    }
    if (this.viewNo == 3 && !this.insightDef) {
      this.recommendTitle()
    }
  }

  recommendTitle() {
    let title = ""
    this.dataSeries.forEach((field, index) => {
      if (index > 0) {
        title += " and "
      }
      title += `${field.label}`
    })

    this.groupings.forEach((grouping, index) => {
      if (index == 0) {
        title += ` per `
      } else {
        title += `, `
      }
      title += `${grouping.label}`
    })
    this.title = title
  }

  getTaxonomyChildren(value: string) {
    return this.taxonomyInfo?.childrenSortedByOrdinal(value)
  }

  onTaxonomyChange(itemNo: number, templateRef: TemplateRef<any> = null) {
    let item = `item${itemNo}`
    if (!this.selectedTaxonomyKeys[item]) {
      this.selectedTaxonomyKeys[item] = this.taxonomyForm.controls[item].value
      this.handleTaxonomyControls(itemNo)
    } else {
      let selectedEntity = this.taxonomyInfo.entityByKey(this.taxonomyForm.controls[item].value)
      let label = selectedEntity ? selectedEntity.getLabel() : undefined
      let key = selectedEntity ? selectedEntity.key : undefined
      this.categoryChangedTo = { itemNo, label, key }
      this.openModal(templateRef)
    }
    this.error.taxonomy = false
  }

  handleTaxonomyControls(itemNo) {
    if (itemNo == 0) {
      if (this.taxonomyForm.controls.item0.value == "") {
        this.taxonomyForm.controls.item1.disable()
      } else {
        this.taxonomyForm.controls.item1.enable()
      }
      this.taxonomyForm.controls.item1.setValue("")
      this.selectedTaxonomyKeys.item1 = ""
      this.taxonomyForm.controls.item2.disable()
      this.taxonomyForm.controls.item2.setValue("")
      this.selectedTaxonomyKeys.item2 = ""
    } else if (itemNo == 1) {
      if (this.taxonomyForm.controls.item1.value == "") {
        this.taxonomyForm.controls.item2.disable()
      } else {
        this.taxonomyForm.controls.item2.enable()
      }
      this.taxonomyForm.controls.item2.setValue("")
      this.selectedTaxonomyKeys.item2 = ""
    }
  }

  getTaxonomyEntityText() {
    if (this.selectedTaxonomyKeys.item2) {
      let rootParent = this.taxonomyInfo.entityByKey(this.selectedTaxonomyKeys.item0)
      let parent = this.taxonomyInfo.entityByKey(this.selectedTaxonomyKeys.item1)
      let entity = this.taxonomyInfo.entityByKey(this.selectedTaxonomyKeys.item2)
      if (entity) {
        let label = `${rootParent.getLabel()} > ${parent.getLabel()} > ${entity.getLabel()}`
        return label
      }
    }
  }

  openModal(modalTemplateRef: TemplateRef<any>, clazz: string = "modal-md") {
    let config = {
      class: clazz,
      ignoreBackdropClick: true,
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove("modal-open")
  }

  buildChart() {
    let data = [
      { year: `2010`, count: 10 },
      { year: `2011`, count: 20 },
      { year: `2012`, count: 15 },
      { year: `2013`, count: 25 },
      { year: `2014`, count: 22 },
      { year: `2015`, count: 30 },
      { year: `2016`, count: 28 },
    ]
    if (this.compareOn) {
      data = [
        { year: `${this.compareOn.label} 1`, count: 20 },
        { year: `${this.compareOn.label} 2`, count: 15 },
        { year: `${this.compareOn.label} 3`, count: 25 },
        { year: `${this.compareOn.label} 4`, count: 22 },
        { year: `${this.compareOn.label} 5`, count: 30 },
        { year: `${this.compareOn.label} 6`, count: 28 },
        { year: `${this.compareOn.label} 7`, count: 18 },
      ]
    }
    let chartColors = [
      "#4E5BA6",
      "#6dc49b",
      "#F2E416",
      "#F2921D",
      "#985FF5",
      "#CEF2DB",
      "#19bfd3",
      "#3366cc",
      "#dc3912",
      "#ff9900",
      "#109618",
      "#990099",
      "#0099c6",
      "#dd4477",
      "#66aa00",
      "#b82e2e",
      "#316395",
      "#3366cc",
      "#994499",
      "#22aa99",
      "#aaaa11",
      "#6633cc",
      "#e67300",
      "#8b0707",
      "#651067",
      "#329262",
      "#5574a6",
      "#3b3eac",
      "#b77322",
      "#16d620",
      "#b91383",
      "#f4359e",
      "#9c5935",
      "#a9c413",
      "#2a778d",
      "#668d1c",
      "#bea413",
      "#0c5922",
      "#743411",
    ]

    let borderColors = chartColors.map((c) => RGB.hexToRgb(c).rgba(1.0))
    let backgroundColors = chartColors.map((c) => RGB.hexToRgb(c).rgba(0.7))
    let datasets = this.dataSeries.map((f, i) => {
      return {
        label: f.label,
        data: data.map((row) => row.count),
        borderWidth: 2,
        borderColor: borderColors[i],
        backgroundColor: backgroundColors[i],
        hoverBackgroundColor: backgroundColors[i],
      }
    })

    this.kpiPrevieChart?.destroy()
    let chartData: ChartConfiguration = {
      type: this.chartType == "line" ? "line" : "bar",
      data: {
        labels: data.map((row) => row.year),
        datasets: datasets,
      },
    }

    if (this.chartType == "stack") {
      chartData["options"] = {
        responsive: true,
        scales: {
          xAxis: { stacked: true, reverse: false },
          yAxis: { stacked: true },
        },
      }
    }
    this.kpiPrevieChart = new Chart(this.canvas.nativeElement.getContext("2d"), chartData)
  }

  onChangedSetting(field: GroupingFe) {
    this.compareOn = field
    this.buildChart()
  }

  chartTypeChanged(type: string) {
    this.chartType = type
    this.buildChart()
  }

  searchCategory(statement: TwoOperandStatementFe) {
    let table = this.taxonomyInfo.entities.find((t) => t.key == this.taxonomyForm.controls.item2.value)
    statement.filteredColumns = table.columns.filter((c) => c.getLabel().toLowerCase().includes(statement.searchCategoryLabel))
  }

  searchValue(statement: TwoOperandStatementFe, event: any) {
    statement.searchValueLabel = event.target.value
    let values = statement.selectedColumn.distinctValues
    statement.filteredValues = values.filter((v) => v.toLowerCase().includes(statement.searchValueLabel))
  }

  async fetchColumnData(col: TaxonomyAttributeFe, statement: TwoOperandStatementFe) {
    if (statement.selectedColumn.datatype == DataGridColumnType.STRING || statement.selectedColumn.datatype == DataGridColumnType.BOOLEAN) {
      statement.isLoadingValues = true
      statement.searchValueLabel = null
      let table = this.taxonomyInfo.entities.find((t) => t.key == this.taxonomyForm.controls.item2.value)
      let values = await this.stateService.getDistinctColumnValues(col.key, table.tableName)
      col.distinctValues = values
      statement.filteredValues = col.distinctValues
      statement.isLoadingValues = false
    }
  }

  // handleMultipleCondition(event: any) {
  //   if (event.target.checked) {
  //     this.addCondition();
  //   } else {
  //     this.filter.statement = this.filter.unitTwoOperandStatements[0]
  //     this.filter.unitTwoOperandStatements = [this.filter.unitTwoOperandStatements[0]];
  //   }
  // }

  // addCondition() {
  //   let table = this.taxonomyInfo.entities.find(t => t.key == this.taxonomyForm.controls.item2.value)
  //   let statement = new TwoOperandStatementFe(null, TwoOperandOperatorFe.EQ, null)
  //   statement.setAttributes(table.columns)
  //   this.filter.unitTwoOperandStatements.push(statement)
  // }

  // mainOperatorChanged() {
  // let op = TwoOperandOperatorFe.opByKey(this.mainConnector)
  // let leftStatement = this.filter.statement.leftStatement
  // while (leftStatement instanceof TwoOperandStatementFe) {
  //   leftStatement.operator = op;
  //   leftStatement = leftStatement.leftStatement;
  // }
  // }

  // removeCondition(index: number) {
  //   this.filter.unitTwoOperandStatements.splice(index, 1);
  // }

  async openMasterTable(taxonomyKey: string) {
    this.loadingMasterTable = true
    let entity = this.taxonomyInfo.entityByKey(taxonomyKey)
    let dataGridService = new MasterTableDataGridIntergationService(entity, this.stateService, this.languageService)
    await dataGridService.loadMainTablePage()
    let attachment = new AttachmentFe(entity.key, dataGridService, null, null)
    attachment.isTable = true
    this.displayService.openAttachment(attachment)
    this.loadingMasterTable = false
  }

  //New version changes

  addDataSeries() {
    let ds = new DataSeriesFe()
    ds.addDataPoint()
    ds.label = this.locale("locale_key.kpi_library.data_series", { number: this.dataSeries.length + 1 })
    this.dataSeries.push(ds)
    this.buildChart()
  }

  deleteDataSeries() {
    this.dataSeries.splice(this.selectedDataSeriesIndex, 1)
    this.selectedDataSeriesIndex = null
    this.selectedDataSeries = null
    this.buildChart()
    this.closeModal()
  }

  findEntityColumnsByType(types: string[], key: string, getAllColumns = false): TaxonomyAttributeFe[] {
    if (this.taxonomyInfo) {
      let table = this.taxonomyInfo.entities.find((t) => t.key == key)
      if (getAllColumns) {
        return table.columns
      }
      if (table) {
        return table.columns.filter((c) => types.includes(c.datatype))
      } else {
        return []
      }
    } else {
      return []
    }
  }

  defineDS(index: number) {
    this.define = "ds"
    this.selectedDataSeriesIndex = index
    this.selectedDataSeries = this.dataSeries[index]
  }

  defineUnit() {
    this.setUniqueEntities()
    this.define = "unit"
  }

  defineFilter() {
    this.define = "filter"
  }

  defineGrouping(index: number) {
    this.define = "grouping"
    this.selectedGroupingIndex = index
  }

  defineTime() {
    this.define = "timeGrouping"
  }

  addGrouping() {
    let grouping = new GroupingFe()
    grouping.label = this.locale("locale_key.kpi_library.grouping", { number: this.groupings.length + 1 })
    this.uniqueEntities.forEach((entity, key) => {
      let dp = new DataPointFe()
      dp.setTaxonomy(key)
      let stringCols = this.findEntityColumnsByType(["STRING", "BOOLEAN"], key)
      dp.taxonomyKey = key
      dp.attributeKey = stringCols[0]?.key
      grouping.dataPoints.push(dp)
    })
    this.groupings.push(grouping)
  }

  deleteGrouping() {
    this.groupings.splice(this.selectedGroupingIndex, 1)
    this.selectedGroupingIndex = null
    this.buildChart()
  }

  setUniqueEntities() {
    this.uniqueEntities = new Map()
    this.dataSeries.forEach((ds) => {
      ds.dataPoints.forEach((dataPoint) => {
        if (!this.uniqueEntities.has(dataPoint.taxonomyKey)) {
          let entity = this.taxonomyInfo.entityByKey(dataPoint.taxonomyKey)
          this.uniqueEntities.set(dataPoint.taxonomyKey, entity)
        }
      })
    })
  }

  getUniqueEntitiesArray(): EntityFe[] {
    let entities = []
    this.uniqueEntities.forEach((e) => entities.push(e))
    return entities
  }

  getTaxonomyTitle(taxonomyKey: string) {
    let entity = this.taxonomyInfo.entityByKey(taxonomyKey)
    return entity?.getTaxonomyTitle(this.taxonomyInfo)
  }

  isDisabled() {
    if (this.viewNo == 1) {
      if (this.dataSeries.length > 0) {
        for (let ds of this.dataSeries) {
          let noAttr = ds.dataPoints.find((dp) => !dp.attributeKey)
          if (noAttr) {
            return true
          }
        }
        const measurementType = this.getMeasurementType()
        if (measurementType.size == 0 || measurementType.has(UnitMeasurementTypes.NO_UNIT)) {
          return false
        }
        return !this.unitSymbol || this.unitSymbol == ""
      }
    } else if (this.viewNo == 2) {
      if (this.groupings.length > 0 || this.timeGrouping) {
        if (this.groupings.length > 0) {
          for (let grouping of this.groupings) {
            let noAttr = grouping.dataPoints.find((dp) => !dp.attributeKey)
            if (noAttr) {
              return true
            }
          }
        }
        return false
      }
    }
    return true
  }

  setTimeGrouping() {
    this.timeGrouping = new TimeGroupingFe()
    this.uniqueEntities.forEach((entity, key) => {
      let dp = new DataPointFe()
      dp.setTaxonomy(key)
      let dateCols = this.findEntityColumnsByType(["DATE"], key)
      dp.taxonomyKey = key
      dp.attributeKey = dateCols[0]?.key
      this.timeGrouping.dataPoints.push(dp)
    })
    this.timeGrouping.timeScales.push("year")
  }

  getTimeScaleText() {
    let text = this.timeGrouping.timeScales.join("/")
    return text
  }

  setFilter() {
    this.filters = []
    this.uniqueEntities.forEach((entity, key) => {
      const calculation: Calculation = { formula: [], isFallback: false, then: CalculationThenOptions.FORMULA }
      const newCondition = new CalculationCondition({
        comparator: ConditionComparators.AND_COMPARATOR,
        criteria: [],
      })
      calculation.condition = newCondition
      this.filters.push(new KpiFilterFe2(key, "include", calculation))
    })
  }

  deleteDataPoint() {
    this.selectedDataSeries.deleteSelectedDataPoint()
    this.closeModal()
  }

  getDatatype(datatype: string) {
    switch (datatype) {
      case DataGridColumnType.BOOLEAN:
        return this.locale("locale_key.general.data_type.boolean")
      case DataGridColumnType.NUMERIC:
        return this.locale("locale_key.general.data_type.number")
      case DataGridColumnType.STRING:
        return this.locale("locale_key.general.data_type.text")
      case DataGridColumnType.DATE:
        return this.locale("locale_key.general.data_type.date")
    }
    return datatype
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits
  }

  getMeasurementType() {
    let measurementType = new Set()
    const noUnit = this.unitService.getNoUnit()
    if (this.measurementType && this.measurementType != noUnit.measurementType) {
      measurementType.add(this.measurementType)
    }
    return measurementType
  }

  setMeasurementType(measurementType: string | null) {
    this.measurementType = measurementType
  }

  isDataPointDisabled(c: TaxonomyAttributeFe) {
    if (this.dataSeries.length == 1 && this.dataSeries[0].dataPoints.length == 1) {
      return false
    }
    const noUnit = this.unitService.getNoUnit()
    if (this.measurementType == noUnit.measurementType) {
      return false
    }
    return c.unit?.measurementType != this.measurementType
  }

  setDatapointKey(dataPoint: DataPointFe, c: TaxonomyAttributeFe) {
    if (!this.isDataPointDisabled(c)) {
      dataPoint.attributeKey = c.key
      if (c.unit?.measurementType) {
        this.measurementType = c.unit?.measurementType
      }
    }
  }
}
