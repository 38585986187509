<div class="card m-0">
  <div class="main-modal-header modal-header">
    <h4 class="modal-title white">Extracting & mapping data</h4>
    <button type="button" class="ml-auto close" data-dismiss="modal" aria-label="Close">
       <span aria-hidden="false" class="list-inline white" ><a (click)='closeModal()'><i class="las la-times"></i></a></span>
    </button>
</div>
  <div class="modal-body">
    <div class="content clearfix">
      <div class="form-group">
        <div>
          <div class="row">
            <div style="width: 9rem;padding-left: 15px;">
              <label>
                From file
              </label>
            </div>
            <div class="col-8">
              <a *ngIf="fileType == 'excel'"> <i class="la la-file-excel-o" style="color: #6DC49B;"></i> {{fileName}} </a>
              <a *ngIf="fileType == 'pdf'"> <i class="la la-file-pdf-o" style="color: rgb(198, 92, 92);"></i> {{fileName}} </a>
              <a *ngIf="fileType == 'img'"> <i class="la la-file-image-o" style="color: rgb(242, 146, 29);"></i> {{fileName}} </a>
            </div>
          </div>
          <div class="row" *ngIf="taxonomyEntities.length>0">
            <div style="width: 9rem;padding-left: 15px;">
              <label>To data category</label>
            </div>
            <div class="col-8">
              <select class="form-control" (change)='selectEntity($event.target)' *ngIf='taxonomyEntities.length>1; else selectedEntity' style="width: inherit;">
                <option></option>
                <option [value]='i' *ngFor='let entity of taxonomyEntities;let i=index'>{{entity.label}}</option>
              </select>
              
            <ng-template #selectedEntity>
              <input type="text" value="{{taxonomyEntities[0].label}}" readonly style="width: inherit;">
            </ng-template>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              <div *ngIf='mappedToCols.length>0'>
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="col-6 ml-1">Data in File</th>
                        <th class="col-6 ml-1">Mapped To</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor='let y of mappedToCols;let x=index;'>
                        <td >
                          <select class="form-control float-start " (change)='extractedLabelSelected($event.target , x)'>
                            <option value='null'>---</option>
                            <ng-container *ngFor='let col of extractedColNames;let i=index;'>
                              <option [value]='i' [selected]='x==i'>
                                {{col.label}}
                              </option>
                            </ng-container>
                          </select>
                        </td>
                        <td class="text-right">
                          <input type="text" value="{{y.col.label}}" readonly class="col-12 float-start ">
                        </td>
                      </tr>
                      <tr>
                        <td colspan=2 *ngIf='!areUniformValues'>The datatypes of all cells do not match with mapped column type.</td>
                      </tr>
                    </tbody>
                  </table>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
     <button type="submit" class="btn secondary-button custom-button"
      (click)='closeModal()'>Cancel</button>
    <button type="submit" class="btn btn-add custom-button"
      (click)='extractData()' [disabled]='mappedToCols.length==0' >
      {{isEntityChanged ? "Check" : "Extract"}}
    </button>
  </div>
</div>  