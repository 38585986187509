
export class RequestFolder_AddFe {
  id: string;
  name: string;
  creatorAffId: string;
  requestGroups: string | null;
  creatorCompanyId: string;

  constructor(
    id: string,
    name: string,
    creatorAffId: string,
    requestGroups: string | null,
    creatorCompanyId: string
  ) {
    this.id = id;
    this.name = name;
    this.creatorAffId = creatorAffId;
    this.requestGroups = requestGroups;
    this.creatorCompanyId = creatorCompanyId
  }
}