import { DatabaseInfoFe } from "../emission/DatabaseInfoFe";
import { EmissionFactorFe } from "../emission/EmissionFactorFe";
import { ValueStatementFe } from "./ValueStatementFe";

export class EmissionFactorStatementFe extends ValueStatementFe {
  public static TYPE_KEY = 'EMISSION';
  public type = EmissionFactorStatementFe.TYPE_KEY;

  public databaseInfo: DatabaseInfoFe
  public emissionData: any

  constructor(data: EmissionFactorFe) {
    super(data.emissionData[data.databaseInfo.unitColumn], data.emissionData[data.databaseInfo.valueColumn])
    this.databaseInfo = data.databaseInfo
    this.emissionData = data.emissionData 
  }

  public tooltip(): string {
    let rows = this.databaseInfo.columns.map(col => `<div class='row'><div class="col-12 text-left"><a style="color: green"><b>${col.label}:</b></a> <a class='ml-1'>${this.emissionData[col.name]}</a></div></div>`)
    let info = rows.join('').split('null').join('')
    return info
  }

}