import { TextUtils } from "src/app/utils/text.utils";
import { KpiFe2 } from "./KpiFe2";
import { InsightDefFe } from "./InsightDefFe";
import { InsightProcessingPipelineDefFe } from "./InsightProcessingPipelineDefFe";
import { EntityFe } from "../taxonomy/EntityFe";
import { KpiDataGridIntergationService } from "src/app/components/kpi/KpiDataGridIntergationService";

export class InsightFe extends InsightDefFe {

  public errorCode: string
  public entities: EntityFe[]
  kpiDataGridService: KpiDataGridIntergationService

  constructor(
    public id: string,
    public title: string,
    public createdAt: Date,
    public definition: InsightProcessingPipelineDefFe,
    public pie: boolean,
    public kpis: KpiFe2[]
  ){
    super(id, title, createdAt, definition)
    if (!title) {
      this.title = "Missing Title"
    } else if (!definition || !kpis || kpis.length == 0) {
      this.errorCode = "INCOMPELETE"
    }  
  }

  public static fromTransfer(transfer: any): InsightFe {
    if (transfer.kpis) {
      let kpiData = new InsightFe(
        transfer.id, 
        transfer.title, 
        new Date(transfer.createdAt), 
        InsightProcessingPipelineDefFe.fromTransfer(transfer.definition) ,
        transfer.pie,
        transfer.kpis.map(kpi => new KpiFe2(kpi))
      )
      return kpiData
    } 
  }

  public isTimed() {
    let timeScales = this.kpis.map(k => k.timeScale)
    timeScales = timeScales.filter(ts => ts != undefined && ts != null)
    return timeScales && timeScales.length > 0
  }

  public makesPie() {
    return this.pie ? true : false
  }

  public valueFieldNames(): string[] {
    return this.definition.dataSeries ? this.definition.dataSeries.map(vf => vf.id) : []
  }

  public valueFieldLabels() {
    return this.definition.dataSeries ? (this.definition.dataSeries.map(vf => (vf.label) ? TextUtils.convert(vf.label) : vf.label)) : []
  }

  public valueFieldUnits(): string {
    return this.definition.getUnit();
  }

  public uniqueTimeValuesOrdered(timescaleIndex) {
    return this.kpis[timescaleIndex].uniqueTimeValuesOrdered()
  }

  public uniqueCategoryValuesOrdered(categoryIndex: number): string[] {
    const cat: string = this.definition.groupings[categoryIndex].id
    let catVals: string[] = []
    this.kpis.forEach(kpi => {
      let vals: string[] = kpi.rows.map(d => d.rowValues[cat])
      catVals = catVals.concat(vals)
    })
    let uniqeCatVals: string[] = [...new Set(catVals)]
    let orderedCatValues: string[] = uniqeCatVals.sort()
    return orderedCatValues
  }
}
