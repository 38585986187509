import { QUESTION_TYPE } from "../../../request/questionnaire/QuestionTypeFe";
import { Answer_AddFe } from "./Answer_AddFe";

export class AnswerAttachment_AddFe extends Answer_AddFe{
  managementCompanyId: string
  attachmentStorageId: string;
  attachmentLabel: string;

  file: any
  isLoading = false;
  
  constructor(
    id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    managementCompanyId: string,
    attachmentStorageId: string,
    attachmentLabel: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.ATTACHMENT,
      comments
    )
    this.managementCompanyId = managementCompanyId
    this.attachmentStorageId = attachmentStorageId
    this.attachmentLabel = attachmentLabel
  }

  public isInvalid (isRequired: boolean): boolean {
    if (isRequired)
      return !this.hasAnyValue();
    return false
  }

  public hasAnyValue(): boolean {
    return this.attachmentStorageId!=null
  }

  public reset (): void {
    this.attachmentStorageId = null
    this.attachmentLabel = null
  }

  public toTransfer() {
    let ans = new AnswerAttachment_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.managementCompanyId,
      this.attachmentStorageId,
      this.attachmentLabel
    )
    return ans
  }

}
