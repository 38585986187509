<div class="vh-100" style="overflow: hidden" *ngIf="!initCacheInProgress">
  <div class="wrapper mt-2 scroll-y mb-4" style="max-height: 1200px" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'mw-100' : 'mw-90'">
    <div class="row ms-0 mt-1 px-3">
      <!-- Left Begin -->
      <div class="row" data-test="summary-title-text">
        <h3 class="">{{ locale('locale_key.pages.landing.sm.activities.title') }}</h3>
      </div>
      <div class="col-xl-9 col-lg-12 col-md-12 col-12 ps-0 pe-3">
        <div class="row mb-3 mx-auto ms-1 text-center w-100">
          <div
            class="col-xl-3 col-md-12 col-sm-12 col-12 card d-flex justify-content-center"
            [ngClass]="{ 'py-3 mb-2 me-5': screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isLargeSize() }"
          >
            <div class="col-xl-12 d-flex justify-content-between justify-content-center align-items-center">
              <span
                ><h5 class="text-center my-2 ms-1">{{ locale('locale_key.pages.landing.total_new_activities') }}</h5></span
              >
              <span class="ms-auto"
                ><h3 class="text-center mt-1 ms-auto mx-2" [ngClass]="{ 'text-underline': noOfUnreadActivities > 0 }">{{ noOfUnreadActivities }}</h3></span
              >
              <span class="mt-50"></span>
            </div>
          </div>
          <div
            class="col-xl-9 col-lg-12 col-md-12 col-12 me-0"
            [ngClass]="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isLargeSize() ? 'm-0 w-100 ps-0 pe-0' : 'pe-2'"
          >
            <div class="card px-3 py-4 h-100">
              <div class="row my-auto">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 row my-auto justify-content-between" [ngClass]="{ 'thin-border-r': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
                  <div class="w-70 my-auto text-wrap">
                    <h4 class="text-start mt-2 pt-2 text-truncate fs-5 text-wrap">{{ locale('locale_key.pages.landing.total_to_do') }}</h4>
                  </div>
                  <div class="icon-circle-lg bg-selected border my-auto" style="border: 1px solid #19bfd3 !important">
                    <span class="bold fs-4 dark-text" *ngIf="!isDO()">{{ noOfToReviewRequests }}</span>
                    <span class="bold fs-4 dark-text" *ngIf="isDO()">{{ tasks.pending.length }}</span>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 d-flex flex-row my-auto justify-content-between">
                  <div class="w my-auto">
                    <h5 class="text-start my-auto text-truncate fs-5" [ngClass]="{ 'ps-2': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
                      {{ locale('locale_key.pages.landing.upcoming_deadlines.title') }}
                    </h5>
                  </div>
                  <div class="icon-circle-lg bg-deadline border my-auto ms-auto me-2" style="border: 1px solid #c65c5c !important">
                    <span class="bold fs-4 dark-text">{{ noOfUpcomingDeadlines }}</span>
                  </div>
                  <div class="my-auto ms-auto">
                    <a class="btn tertiary-button ms-auto" *ngIf="isDO()" routerLink="/ws/tasks/inbox">{{ locale('locale_key.pages.landing.button.go_to_inbox') }}</a>
                    <a class="btn tertiary-button ms-auto" *ngIf="!isDO()" routerLink="/ws/inbox">{{ locale('locale_key.pages.landing.button.go_to_inbox') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->

        <div class="mx-1 mb-3 pe-0" style="max-height: 85vh">
          <div class="card sm-scroll bg-light-main me-0 pe-0" style="overflow-x: auto; overflow-x: hidden; background-color: #f4f5fa !important">
            <div class="row">
              <div class="card-content mx-0 px-0 bg-light-main">
                <div class="col-12 ps-0 mt-0 pt-0">
                  <div class="w-100 m-0 p-0" style="position: sticky; top: 0; z-index: 1000 !important">
                    <div class="row m-auto bg-complementary px-0 py-3 mb-2 mt-0">
                      <div class="col-lg-12 col-xl-12 col-12 mx-0 px-0 d-flex flex-column justify-content-center ps-3">
                        <span class="text-start text-white ps-2 bold fs-5 req-title">{{ locale('locale_key.pages.landing.title.request_activity') | uppercase }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="row" [ngClass]="{ hide: screenSize.isSmallerSize() || screenSize.isXSmallSize() }">
                      <span class="ms-3 me-auto dark-text">{{ locale('locale_key.pages.landing.today') }}</span>
                    </div>
                    <div class="timeline w-100">
                      <div *ngFor="let activity of recentActivities; let i = index">
                        <div class="cont w-100" [ngClass]="{ right: i % 2 == 0 || i % 2 !== 0, unreadActivity: activity.readStatus == 'UNREAD' }">
                          <div class="w-100 bg-white mb-1 py-2 mx-0 activity" [ngClass]="{ selected: selectedActivity == activity }" (click)="selectedActivity = activity">
                            <div class="d-flex flex-row">
                              <div class="col-lg-9 col-xl-9 col-11 text-truncate mx-0 px-0 ps-3 feed-item">
                                <h6 class="text-start bold text-truncate me-2 m-0">{{ getRequestGroupTitle(activity) }}</h6>
                                <h6 class="text-start mb-0 pb-0 mt-3" *ngIf="!isDO()">
                                  {{ activity.submitterAffId == 'SYSTEM_AFF' ? 'System' : activity.getSubmitterName() }}
                                  <small *ngIf="activity.submitterAffId != 'SYSTEM_AFF'" class="text-start text-muted mt-0 pt-0">({{ activity.submitterCompanyName }})</small>
                                </h6>
                                <ng-container *ngIf="requestService.isCreatedRequest(activity)">
                                  <h6
                                    class="text-start bold collapsed text-truncate mb-0 pb-0 mt-2"
                                    id="activityHeading{{ i }}"
                                    [ngClass]="{ 'accordion-icon-rotate': !isDO() }"
                                    data-bs-toggle="collapse"
                                    attr.data-bs-target="#activity{{ i }}"
                                    aria-expanded="true"
                                    attr.aria-controls="activity{{ i }}"
                                  >
                                    <i class="me-1 font-weight-bolder font-size-large {{ activity.icon }}"></i>
                                    {{ isDO() ? activity.homePageTextDO() : activity.homePageText() }}
                                    <i class="icon"></i>
                                  </h6>
                                  <h6 class="text-start mb-0 pb-0 mt-1" *ngIf="!isDO()">
                                    {{ locale('locale_key.pages.landing.sm.activity.for_reporters', { noOfDO: getRequestGroup(activity.requestGroupId)?.requestCount }) }}
                                  </h6>
                                  <h6 class="text-start mb-0 pb-0 mt-1" *ngIf="isDO()">
                                    {{
                                      locale('locale_key.pages.landing.data_reporter.activity.from_name', { name: activity.submitterAffId == 'SYSTEM_AFF' ? 'System' : activity.getSubmitterName() })
                                    }}
                                    <small class="text-start text-muted mt-0 pt-0">({{ activity.submitterCompanyName }})</small>
                                  </h6>
                                </ng-container>
                                <ng-container *ngIf="!requestService.isCreatedRequest(activity)">
                                  <h6 class="text-start bold text-truncate mb-0 pb-0 mt-2">
                                    <i
                                      class="{{ activity.icon }} me-1"
                                      *ngIf="requestService.isAccept(activity) || requestService.isCloseRequest(activity)"
                                      [ngClass]="{ 'grad-green': requestService.isAccept(activity) }"
                                    ></i>
                                    <i class="mt-2 me-1 {{ activity.icon }}" style="color: var(--main-gradient-start-color)" *ngIf="requestService.isConnectedData(activity)"></i>
                                    <i class="mt-2 me-1 {{ activity.icon }}" style="color: var(--orange)" *ngIf="requestService.isReject(activity) || requestService.isDisconnectedData(activity)"></i>
                                    <i class="me-1 font-weight-bolder font-size-large {{ activity.icon }}" *ngIf="requestService.isSubmitAnswer(activity) || requestService.isMessage(activity)"></i>
                                    {{ isDO() ? activity.homePageTextDO() : activity.homePageText() }}
                                  </h6>
                                  <h6 class="text-start mb-0 pb-0 mt-1">
                                    <ng-container *ngIf="!isDO()">
                                      <ng-container *ngIf="requestService.isSubmitAnswer(activity) || requestService.isMessage(activity)">
                                        {{ locale('locale_key.pages.landing.sm.activity.to_name', { name: getCreatorName(activity.requestGroupId) }) }}
                                        <small class="text-start text-muted mt-0 pt-0">({{ getCompanyName(activity.requestGroupId) }})</small>
                                      </ng-container>
                                      <ng-container
                                        *ngIf="
                                          requestService.isAccept(activity) ||
                                          requestService.isConnectedData(activity) ||
                                          requestService.isReject(activity) ||
                                          requestService.isDisconnectedData(activity)
                                        "
                                      >
                                        {{ locale('locale_key.pages.landing.sm.activity.from_name', { name: getAssigneeName(activity.requestId) }) }}
                                        <small class="text-start text-muted mt-0 pt-0">({{ activity.submitterCompanyName }})</small>
                                      </ng-container>
                                      <ng-container *ngIf="requestService.isCloseRequest(activity)">
                                        {{ locale('locale_key.pages.landing.sm.activity.for_name', { name: getAssigneeName(activity.requestId) }) }}
                                        <small class="text-start text-muted mt-0 pt-0">({{ activity.submitterCompanyName }})</small>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="isDO()">
                                      {{ locale('locale_key.pages.landing.data_reporter.activity.by_name', { name: activity.getSubmitterName() }) }}
                                      <small class="text-start text-muted mt-0 pt-0">({{ activity.submitterCompanyName }})</small>
                                    </ng-container>
                                  </h6>
                                </ng-container>
                              </div>
                              <div
                                class="col-lg-3 col-xl-3 col-1 text-truncate d-flex flex-row justify-content-between my-auto"
                                [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'text-end' : 'text-start'"
                              >
                                <small class="text-start text-muted my-auto remove-col me-1">{{ activity.submissionDate | date : 'dd MMM, yyyy (HH:mm)' }}</small>
                                <ng-container *ngIf="!isDO()">
                                  <button class="btn primary-button review-btn my-auto ms-auto me-2" *ngIf="isSMActionRequired(activity)" (click)="openActivityPortal(activity)">
                                    {{ locale('locale_key.general.buttons.review') }}
                                  </button>
                                  <span (click)="openActivityPortal(activity)" *ngIf="!isSMActionRequired(activity)" class="ms-auto">
                                    <i class="la la-arrow-right fs-3 cursor mx-2 bold thin-border ms-auto" style="border-radius: 5%"></i>
                                  </span>
                                </ng-container>
                                <ng-container *ngIf="isDO()">
                                  <button class="btn primary-button review-btn my-auto ms-auto me-2" *ngIf="isActionRequired(activity)" (click)="openActivityPortal(activity)">
                                    {{ locale('locale_key.general.buttons.start') }}
                                  </button>
                                  <span (click)="openActivityPortal(activity)" *ngIf="!isActionRequired(activity)" class="ms-auto">
                                    <i class="la la-arrow-right fs-3 cursor mx-2 bold thin-border ms-auto" style="border-radius: 5%"></i>
                                  </span>
                                </ng-container>
                              </div>
                            </div>
                            <div
                              id="activity{{ i }}"
                              class="accordion-collapse activityAcc m-3 collapse"
                              attr.aria-labelledby="activityHeading{{ i }}"
                              *ngIf="requestService.isCreatedRequest(activity) && !isDO()"
                            >
                              <div class="mb-1 py-2 mx-0 bg-light-gray">
                                <div class="d-flex" *ngFor="let request of getRequests(activity.requestGroupId)">
                                  <div class="col-lg-9 col-xl-9 col-11 accordion-body m-1 p-2">
                                    {{ activity.submitterAffId == 'SYSTEM_AFF' ? 'System' : activity.getSubmitterName() }}
                                    <b>
                                      {{ activity.homePageText() | lowercase }}
                                    </b>
                                    {{ locale('locale_key.pages.landing.sm.activity.for_name_company', { name: request.getAssigneeName(), company: getAssigneeCompanyName(request) }) }}
                                  </div>
                                  <div
                                    class="col-lg-3 col-xl-3 col-1 text-truncate d-flex flex-row justify-content-between my-auto"
                                    [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'text-end' : 'text-start'"
                                  >
                                    <small class="text-start text-muted my-auto remove-col me-1">{{ activity.submissionDate | date : 'dd MMM, yyyy (HH:mm)' }}</small>
                                    <span (click)="openActivityPortal(activity)" class="ms-auto">
                                      <i class="la la-arrow-right fs-3 cursor mx-2 bold thin-border ms-auto" style="border-radius: 5%"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Left End -->
      <!-- Right Begin -->
      <div class="col-xl-3 col-md-12 col-12 col-12 ps-1 pe-3 h-100" style="height: 89vh !important; max-height: 89vh !important">
        <div class="d-flex flex-column justify-content-start mx-auto h-100 custom-flex-div">
          <div class="mb-3 col-12" style="max-height: 500px !important">
            <div class="row mx-0">
              <div class="card px-0">
                <div class="card-header bg-mainGradient my-auto">
                  <h3 class="text-start text-white my-auto">{{ locale('locale_key.pages.landing.sm.statistics.title') }}</h3>
                </div>
                <div class="card-body">
                  <div class="row d-flex flex-row justify-content-between pb-3 pt-2 thin-border-b px-3">
                    <span class="d-inline w-75 my-auto"
                      ><h4 class="text-truncate fs-4 d-inline"><i class="las la-table me-2"></i>{{ locale('locale_key.pages.landing.sm.data_sources.title') }}</h4></span
                    >
                    <span class="bold fs-4 w-25 my-auto text-right ms-auto"
                      ><span>{{ noOfDataSources }}</span></span
                    >
                  </div>
                  <div class="row d-flex flex-row justify-content-between py-4 thin-border-b px-3">
                    <span class="d-inline w-75 my-auto"
                      ><h4 class="text-truncate fs-4 d-inline"><i class="las la-tachometer-alt me-2"></i>{{ locale('locale_key.pages.landing.sm.dashboards.title') }}</h4></span
                    >
                    <span class="bold fs-4 w-25 my-auto text-right ms-auto"
                      ><span>{{ noOfDashboards }}</span></span
                    >
                  </div>
                  <div class="row d-flex flex-row justify-content-between pt-3 pb-2 px-3">
                    <span class="d-inline w-75 my-auto"
                      ><h4 class="text-truncate fs-4 d-inline"><i class="las la-bolt me-2"></i>{{ locale('locale_key.pages.landing.sm.kpis.title') }}</h4></span
                    >
                    <span class="bold fs-4 w-25 my-auto text-right ms-auto"
                      ><span>{{ noOfKpis }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" style="max-height: 500px !important">
            <div class="card px-0">
              <div class="card-header bg-complementary my-auto">
                <h3 class="text-start text-white my-auto">{{ locale('locale_key.pages.landing.data_reporter.news.title') }}</h3>
              </div>
              <div class="card-body">
                <div class="col-xl-12 col-lg-12 col-12">
                  <div class="row">
                    <!-- Carousel -->
                    <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
                      <!-- Indicators -->
                      <div class="carousel-indicators">
                        <button data-bs-target="#myCarousel" data-bs-slide-to="0" class="active"></button>
                        <button data-bs-target="#myCarousel" data-bs-slide-to="1"></button>
                        <button data-bs-target="#myCarousel" data-bs-slide-to="2"></button>
                        <button data-bs-target="#myCarousel" data-bs-slide-to="3"></button>
                        <button data-bs-target="#myCarousel" data-bs-slide-to="4"></button>
                      </div>
                      <!-- Slides -->
                      <div class="carousel-inner">
                        <!-- First slide -->
                        <div class="carousel-item active">
                          <img src="https://framerusercontent.com/images/2GdiZMQumu3m9nehsWaaIbn5wM.jpeg" class="d-block w-100" alt="Image 1" />
                          <div class="carousel-text bg-white p-3">
                            <a
                              class="mt-3"
                              href="https://sustainlab.co/blog/4-things-you-need-to-know-about-the-ESRS"
                              style="color: var(--main-dark-color); font-size: 1.1rem; font-weight: 300; cursor: pointer; line-height: 1em"
                              target="_blank"
                              ><h5 class="text-truncate">4 things you need to know about the ESRS</h5>
                            </a>
                            <a href="https://sustainlab.co/blog/4-things-you-need-to-know-about-the-ESRS">
                              <p class="text-truncate">Are you a sustainability manager or professional who's unsure about the ESRS (EU Sustainability Reporting Standards)?</p>
                            </a>
                          </div>
                        </div>
                        <!-- Second slide -->
                        <div class="carousel-item">
                          <img src="https://framerusercontent.com/images/18TsXfpP5EuhXm5ZB3EZoxAFnZQ.jpeg" class="d-block w-100 img-fluid" alt="Image 2" />
                          <div class="carousel-text bg-white p-3">
                            <a
                              class="mt-3"
                              href="https://sustainlab.co/blog/5-key-elements-to-make-your-sustainability-report-a-good-one"
                              style="color: var(--main-dark-color); font-size: 1.1rem; font-weight: 300; cursor: pointer; line-height: 1em"
                              target="_blank"
                              ><h5 class="text-truncate">5 key elements to make your sustainability report a good one</h5>
                            </a>
                            <a href="https://sustainlab.co/blog/5-key-elements-to-make-your-sustainability-report-a-good-one">
                              <p class="text-truncate">
                                Sustainability reporting is crucial for businesses to enhance their awareness of sustainability impact and ensure its integration into their business model.
                              </p>
                            </a>
                          </div>
                        </div>
                        <!-- Third slide -->
                        <div class="carousel-item">
                          <img src="https://framerusercontent.com/images/Ib64wFz1084Smk1XA1EfYTMU.jpeg" class="d-block w-100 img-fluid" alt="Image 3" />
                          <div class="carousel-text bg-white p-3">
                            <a
                              class="mt-1"
                              href="https://sustainlab.co/blog/6-ways-to-ensure-your-sustainability-efforts-are-data-driven"
                              style="color: var(--main-dark-color); font-size: 1.1rem; font-weight: 400; cursor: pointer; line-height: 1em"
                              target="_blank"
                              ><h5 class="text-truncate">6 ways to ensure your sustainability efforts are data-driven</h5>
                            </a>
                            <a href="https://sustainlab.co/blog/6-ways-to-ensure-your-sustainability-efforts-are-data-driven">
                              <p class="text-truncate">
                                You might’ve already heard of the term ‘data-driven’, and how many companies have been increasingly striving towards becoming more data-driven.
                              </p>
                            </a>
                          </div>
                        </div>
                        <!-- Fourth slide -->
                        <div class="carousel-item">
                          <img
                            src="https://framerusercontent.com/images/Q8jyVCNdBeRDQ9fnEn1AqRcincM.jpg"
                            class="d-block w-100 img-fluid"
                            alt="Image 3"
                          />
                          <div class="carousel-text bg-white p-3">
                            <a
                              class="mt-3"
                              href="https://sustainlab.co/blog/how-to-use-sustainability-data-to-influence-other-business-decisions"
                              style="color: var(--main-dark-color); font-size: 1.1rem; font-weight: 400; cursor: pointer; line-height: 1em"
                              target="_blank"
                            >
                              <h5 class="text-truncate">How to use sustainability data to influence other business decisions?</h5>
                            </a>
                            <a href="https://sustainlab.co/blog/how-to-use-sustainability-data-to-influence-other-business-decisions">
                              <p class="text-truncate">
                                By now we all know - sustainability is important. More importantly, sustainability shouldn't just be for your corporate image, brand or conscience
                              </p>
                            </a>
                          </div>
                        </div>
                        <!-- Fifth slide -->
                        <div class="carousel-item">
                          <img src="https://framerusercontent.com/images/ts6IUyXCSesbm5KyOIYarvpC4.png" class="d-block w-100 img-fluid" alt="Image 3" />
                          <div class="carousel-text bg-white p-3">
                            <a
                              class="mt-3"
                              href="https://sustainlab.co/blog/5-organizational-challenges-you-might-face-as-a-sustainability-manager-and-how-to-tackle-them"
                              style="color: var(--main-dark-color); font-size: 1.1rem; font-weight: 300; cursor: pointer; line-height: 1em"
                              target="_blank"
                            >
                              <h5 class="text-truncate">5 organizational challenges you might face as a sustainability manager, and how to tackle them</h5>
                            </a>
                            <a href="https://sustainlab.co/blog/5-organizational-challenges-you-might-face-as-a-sustainability-manager-and-how-to-tackle-them">
                              <p class="text-truncate">The world is changing. Sustainability is becoming more and more important. It's not just a buzzword anymore — it's a real way of life.</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <!-- Controls -->
                      <a class="carousel-control-prev" href="#myCarousel" role="button" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </a>
                      <a class="carousel-control-next" href="#myCarousel" role="button" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #selectLanguage (keydown)="onKeyDown($event)">
    <div class="modal-header main-modal-header">
      <h4 class="modal-title mb-0 pb-0 ml-50 mb-0 white" id="myModalLabel12">{{ locale('locale_key.pages.profile.language_settings_section.title') }}</h4>
    </div>
    <div class="modal-body">
      <div>
        <h5>{{ locale('locale_key.pages.profile.language_settings.language.instruction.message') }}</h5>
        <h6 class="pt-1">{{ locale('locale_key.pages.profile.language_settings.language.instruction') }}</h6>
      </div>
      <div class="card-content">
        <div class="card-body px-25">
          <div class="pt-2">
            <p class="m-0">{{ locale('locale_key.pages.profile.language_settings.platform.language') }}</p>
            <div class="row">
                <div class="col-4">
                  <div id="lang-dropdown" class="btn-group mr-1 mb-1">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span *ngIf="selectedLang"><i class="{{ selectedLang.icon }} mr-50 m-1"></i>{{ selectedLang.label }}</span>
                    <span *ngIf="!selectedLang">{{ locale('locale_key.pages.profile.language_settings.email.language.please_select') }}</span>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let language of languages">
                      <a class="dropdown-item m-0 px-1" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedLang?.code != language.code" (click)="selectedLang = language"
                        ><i class="{{ language.icon }} mr-50"></i> {{ language.label }}
                      </a>
                    </ng-container>
                  </div>
                </div>
                </div>
              <div class="col">
                <p>{{ locale('locale_key.pages.profile.language_settings.platform.language.info') }}</p>
              </div>
            </div>
          </div>
          <div class="pt-2">
            <p class="m-0">{{ locale('locale_key.pages.profile.language_settings.email.language') }}</p>
            <div class="row">
              <div class="col-4">
                <div id="lang-dropdown" class="btn-group mr-1 mb-1">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span *ngIf="selectedEmailLang"><i class="{{ selectedEmailLang.icon }} mr-50 m-1"></i>{{ selectedEmailLang.label }}</span>
                    <span *ngIf="!selectedEmailLang">{{ locale('locale_key.pages.profile.language_settings.email.language.please_select') }}</span>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let language of languages">
                      <a class="dropdown-item m-0 px-1" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedEmailLang?.code != language.code" (click)="selectedEmailLang = language"
                        ><i class="{{ language.icon }} mr-50"></i> {{ language.label }}
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col">
                <p>{{ locale('locale_key.pages.profile.language_settings.email.language.info') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="border-top: none">
      <button *ngIf="!inProgress" type="button" class="btn btn-add white custom-button" (click)="changeLanguage()">
        {{ locale('locale_key.general.buttons.confirm') }}
      </button>
      <div class="ball-pulse loader-primary" *ngIf="inProgress">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #selectEmailLanguage (keydown)="onKeyDown($event)">
    <div class="modal-header main-modal-header">
      <h4 class="modal-title mb-0 pb-0 ml-50 mb-0 white" id="myModalLabel12">{{ locale('locale_key.pages.profile.language_settings.email.instruction.title') }}</h4>
    </div>
    <div class="modal-body" *ngIf="true">
      <div>
        <h5>{{ locale('locale_key.pages.profile.language_settings.email.instruction.message') }}</h5>
        <h6 class="pt-1">{{ locale('locale_key.pages.profile.language_settings.email.instruction') }}</h6>
      </div>
      <div class="card-content">
        <div class="card-body px-25">
          <div class="pt-2">
            <p class="m-0">{{ locale('locale_key.pages.profile.language_settings.platform.language') }}</p>
            <div class="row">
                <div class="col-4">
                  <div id="lang-dropdown" class="btn-group mr-1 mb-1">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span *ngIf="selectedLang"><i class="{{ selectedLang.icon }} mr-50 m-1"></i>{{ selectedLang.label }}</span>
                    <span *ngIf="!selectedLang">{{ locale('locale_key.pages.profile.language_settings.email.language.please_select') }}</span>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let language of languages">
                      <a class="dropdown-item m-0 px-1" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedLang?.code != language.code" (click)="selectedLang = language"
                        ><i class="{{ language.icon }} mr-50"></i> {{ language.label }}
                      </a>
                    </ng-container>
                  </div>
                </div>
                </div>
              <div class="col">
                <p>{{ locale('locale_key.pages.profile.language_settings.platform.language.info') }}</p>
              </div>
            </div>
          </div>
          <div class="pt-2">
            <p class="m-0">{{ locale('locale_key.pages.profile.language_settings.email.language') }}</p>
            <div class="row">
              <div class="col-4">
                <div id="lang-dropdown" class="btn-group mr-1 mb-1">
                  <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span *ngIf="selectedEmailLang"><i class="{{ selectedEmailLang.icon }} mr-50 m-1"></i>{{ selectedEmailLang.label }}</span>
                    <span *ngIf="!selectedEmailLang">{{ locale('locale_key.pages.profile.language_settings.email.language.please_select') }}</span>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let language of languages">
                      <a class="dropdown-item m-0 px-1" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedEmailLang?.code != language.code" (click)="selectedEmailLang = language"
                        ><i class="{{ language.icon }} mr-50 p-1"></i> {{ language.label }}
                      </a>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col">
                <p>{{ locale('locale_key.pages.profile.language_settings.email.language.info') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="border-top: none">
      <button *ngIf="!inProgress" type="button" class="btn btn-add white custom-button inital_lang-button" (click)="changeLanguage()">
        {{ locale('locale_key.pages.profile.language_settings.apply_language.button') }}
      </button>
      <div class="ball-pulse loader-primary" *ngIf="inProgress">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </div>
  </ng-template>
</div>
