<h4 class="text-muted ps-4">{{ locale("locale_key.pages.data_request.create_wizard.submission_details.title") }}</h4>
<div *ngIf="isQuestionnaireInvalid" class="invalid-feedback d-inline ps-4">
  {{ questionnaireMessage }}
</div>
<div class="h-100 d-flex flex-column justify-content-between">
  <div class="col-10 ms-4 p-3 mb-auto" [ngClass]="{ 'bg-light': editDOTable }">
    <div class="row ps-3">
      <div class="col-7 d-flex flex-column justify-content-end">
        <h4 class="mb-1 pb-0">{{ locale("locale_key.pages.data_request.create_wizard.data_reporters.title") }}</h4>
      </div>
      <div class="col-4 text-end me-0 pe-0">
        <input class="form-control me-0 mb-2" *ngIf="editDOTable" [(ngModel)]="filterText" (input)="filterRows()" type="search" placeholder="{{ locale('locale_key.pages.data_request.create_wizard.data_reporters.search.title') }}" />
      </div>
      <div class="col-11 mx-0 px-0" style="max-height: 200px; height: 200px; overflow-y: auto">
        <div class="d-flex flex-row justify-content-center btn-add text-white align-items-center mb-1 pt-2">
          <div class="col-1">
            <label *ngIf="editDOTable"><input class="mt-2 ms-4" type="checkbox" (change)="selectAllDataOwners($event)" /></label>
          </div>
          <div class="col-4">
            <h5 class="text-white">{{ locale("locale_key.general.user_information.name") }}</h5>
          </div>
          <div class="col-3">
            <h5 class="text-white">{{ locale("locale_key.general.email") }}</h5>
          </div>
          <div class="col-3">
            <h5 class="text-white">{{ locale("locale_key.general.user_information.company") }}</h5>
          </div>
        </div>
        <ng-container *ngFor="let dataOwner of filteredDataOwners; let i = index">
          <div class="d-flex flex-row justify-content-center border-shade mb-1 align-items-center" *ngIf="editDOTable ? true : dataOwner.isSelected" [ngClass]="{ selected: dataOwner == selectedDataOwner }">
            <div class="col-1">
              <label *ngIf="editDOTable"><input class="mt-2 ms-4" type="checkbox" [(ngModel)]="dataOwner.isSelected" /></label>
            </div>
            <div class="col-4">
              <p class="text-truncate pb-0 mb-0">
                <small>{{ dataOwner.userFirstName }} {{ dataOwner.userLastName }}</small>
              </p>
            </div>
            <div class="col-3">
              <p class="text-truncate pb-0 mb-0">
                <small>{{ dataOwner.affiliationEmail }}</small>
              </p>
            </div>
            <div class="col-3">
              <p class="text-truncate pb-0 mb-0">
                <small>{{ dataOwner.supplierCompanyname ? dataOwner.supplierCompanyname : stateService.activeWorkspace.companyName }}</small>
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <button type="button" class="btn primary-button mt-4" (click)="toggleEditDOTable()">
      <span *ngIf="editDOTable">{{ locale("locale_key.pages.data_request.create_wizard.button.show_selected") }}</span>
      <span *ngIf="!editDOTable">{{ locale("locale_key.pages.data_request.create_wizard.button.show_all") }}</span>
    </button>
    <div class="invalid-feedback ml-3 d-block" *ngIf="!anyDOSelected() && form3.touched">
      {{ dataOwnerMessage }}
    </div>
  </div>
  <div class="border-t border-b py-1 mt-1">
    <div class="d-flex flex-row ms-3 mt-2 ps-3">
      <div class="d-flex flex-row">
        <h5 class="pe-2 ms-0 ps-1">{{ locale("locale_key.pages.data_request.create_wizard.title.deadline_frequency") }}</h5>
        <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ locale("locale_key.pages.data_request.create_wizard.title.deadline_frequency.info") }}</p>
        </div>
      </div>
    </div>
    <!-- pl-75 to ps-3 -->
    <div class="d-flex flex-row ms-4 mt-1 ps-3">
      <input type="radio" name="mode" id="oneTime" [(ngModel)]="recurringMode" value="oneTime" (change)="recurringTypeChanged('onetime')" />
      <label for="mode1" class="ms-2 me-5">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.recurrence_option.one_time_request") }}</label>
      <input type="radio" name="mode" id="recurring" [(ngModel)]="recurringMode" value="recurring" class="ms-2" (change)="recurringTypeChanged('recurring')" />
      <label for="mode1" class="ms-2">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.recurrence_option.recurring_request") }}</label>
    </div>
    <div *ngIf="showRecurrenceTypeError" class="row ms-4 mt-1 ps-3" style="color: #ff4961; font-size: 80%">
      {{ recurrenceTypeErrorMsg }}
    </div>
    <div class="d-flex flex-row ms-4 mt-1 ps-3" *ngIf="recurrenceType == 'ONETIME'">
      <div class="me-">
        <label for="mode1" class="ms-0">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.title.sent_out_date") }}</label>
        <br />
        <input type="date" [formControl]="sendOutDate" (change)="sendOutDateChanged()" style="position: relative; bottom: 3px" class="form-control ms-0" />
      </div>
      <div class="">
        <label for="mode1" class="ms-5">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.title.submission_deadline") }}</label>
        <br />
        <input type="date" [(ngModel)]="nextDeadline" (change)="nextDeadlineChanged()" style="position: relative; bottom: 3px" class="form-control ms-5" />
        <span *ngIf="showDeadlineError" class="ms-3" style="color: #ff4961; font-size: 80%"> {{ deadlineErrorMsg }} </span>
      </div>
    </div>
  </div>
  <div class="ms-3 ps-3" *ngIf="recurringRequest">
    <!-- <div class="d-flex mt-1 flex-row justify-content-between ms-3" style="max-width: 60%">
    <span class="ms-3" *ngIf="intervalsView">
      <a class="underline dark-text" (click)="showIntervals()"> {{ morePeriods ? "See less" : "See more" }}</a></span>
    <i class="la la-edit ms-auto fs-4 pt-1 me-2" *ngIf="intervalsView"></i>
  </div> -->
    <div class="border mx-0 px-1 ms-3 mt-3" style="max-width: 70%">
      <div class="row pt-1 pb-2">
        <div class="col-2 me-0 pe-0">
          <p class="pt-1">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.title.next_deadline") }}</p>
        </div>
        <div class="col-10 ms-0 ps-0">
          <input type="date" [(ngModel)]="nextDeadline" class="form-control w-100" (change)="nextDeadlineChanged()" />
          <span *ngIf="showDeadlineError" class="ms-3" style="color: #ff4961; font-size: 80%"> {{ deadlineErrorMsg }} </span>
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <p class="pt-1">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.title.send_request") }}</p>
        </div>
        <div class="col-4">
          <div class="row">
            <input type="number" [min]="1" [(ngModel)]="sendOutIntervalValue" (change)="sendOutChanged()" class="form-control me-2" style="display: inline-block; width: 28%" />
            <select [(ngModel)]="sendOutIntervalUnit" (change)="sendOutChanged()" class="form-select form-control d-inline-block ms-0" style="width: 70%">
              <option value="DAY">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.days_before_the_deadline") }}</option>
              <option value="WEEK">{{ locale("locale_key.pages.data_request.details.reminder.type.weeks_before_the_deadline") }}</option>
              <option value="MONTH">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.months_before_the_deadline") }}</option>
              <option value="YEAR">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.years_before_the_deadline") }}</option>
            </select>
            <span *ngIf="showSendOutDayError" style="color: #ff4961; font-size: 80%"> {{ sendOutDayErrorMsg }} </span>
          </div>
        </div>
        <div class="col-2">
          <p class="pt-1 text-end">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.title.repeat") }}</p>
        </div>
        <div class="col-4">
          <div class="row me-2">
            <select class="form-select form-control" [(ngModel)]="selectedRepeatPreset" (change)="repeatTypeChanged()">
              <option [value]="repeatPreset.value" *ngFor="let repeatPreset of repeatPresets">{{ repeatPreset.human }}</option>
              <option value="CUSTOM">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.custom") }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="selectedRepeatPreset == 'CUSTOM'">
        <div class="col-2"></div>
        <div class="col-4"></div>
        <div class="col-2"></div>
        <div class="col-4">
          <div class="d-flex flex-row flex-nowrap me-2">
            <div class="me-3 my-auto">{{ locale("locale_key.pages.data_request.after_every") }}</div>
            <input type="number" [min]="1" [(ngModel)]="selectedCustomRepeatValue" (change)="repeatValueChanged()" class="form-control" style="flex: 1" />
            <select [(ngModel)]="selectedCustomRepeatUnit" (change)="repeatValueChanged()" class="form-control d-inline-block ms-3 me-45" style="flex: 4">
              <option value="DAILY">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.days") }}</option>
              <option value="WEEKLY">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.weeks") }}</option>
              <option value="MONTHLY">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.months") }}</option>
              <option value="YEARLY">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.years") }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="selectedRepeatPreset == 'CUSTOM' && selectedCustomRepeatUnit == 'MONTHLY'">
        <div class="col-2"></div>
        <div class="col-4"></div>
        <div class="col-2"></div>
        <div class="col-4">
          <div class="row me-2">
            <select [(ngModel)]="selectedCustomRepeatMonthType" class="form-control d-inline-block me-2" (change)="repeatValueChanged()">
              <option [value]="monthlyRepeatPreset.value" *ngFor="let monthlyRepeatPreset of customRepeatMonthlyPresets">{{ monthlyRepeatPreset.human }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="selectedRepeatPreset == 'CUSTOM' && selectedCustomRepeatUnit == 'WEEKLY'">
        <div class="col-2"></div>
        <div class="col-4"></div>
        <div class="col-2"></div>
        <div class="col-4">
          <div class="row">
            <div class="me-3">{{ locale("locale_key.pages.data_request.on") }}</div>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('MONDAY') }"
              (click)="setCustomRepeatDayOfWeek('MONDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              M
            </button>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('TUESDAY') }"
              (click)="setCustomRepeatDayOfWeek('TUESDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              T
            </button>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('WEDNESDAY') }"
              (click)="setCustomRepeatDayOfWeek('WEDNESDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              W
            </button>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('THURSDAY') }"
              (click)="setCustomRepeatDayOfWeek('THURSDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              T
            </button>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('FRIDAY') }"
              (click)="setCustomRepeatDayOfWeek('FRIDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              F
            </button>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('SATURDAY') }"
              (click)="setCustomRepeatDayOfWeek('SATURDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              S
            </button>
            <button
              class="col-1 d-flex align-items-center justify-content-center me-45"
              [ngClass]="{ dayButton: isSelectedCustomRepeatDayOfWeek('SUNDAY') }"
              (click)="setCustomRepeatDayOfWeek('SUNDAY')"
              style="border: 1px solid lightgray; height: 30px; width: 30px"
            >
              S
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row pb-2">
        <div class="col-2">
          <p class="pt-1">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.title.end_repeat") }}</p>
        </div>
        <div class=" ">
          <div class="d-flex flex-row px-0 mx-0">
            <input type="radio" name="never" [(ngModel)]="selectedRecurrenceEnds" value="NEVER" />
            <label for="never" class="px-2 my-auto">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.option.never") }}</label>
            <input type="radio" name="date" [(ngModel)]="selectedRecurrenceEnds" value="ON_DATE" />
            <label class="px-2 my-auto" for="date">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.option.on_date") }}</label>
            <br />
            <div class="d-flex flex-row ps-3">
              <input type="radio" name="x" [(ngModel)]="selectedRecurrenceEnds" value="AFTER_MAX_OCCURENCES" style="margin-left: 1px" />
              <label for="x" class="px-2 my-auto">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.option.after_x_occurrences") }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-1">
        <div class="col-2"></div>
        <div class="col-10 ms-0 ps-0">
          <div class="" style="max-width: 75%">
            <div class="gray-bg p-3 w-100 ms-3" *ngIf="selectedRecurrenceEnds == 'NEVER'">
              <div class="col occurence_list_never">
                <ng-container *ngFor="let occurence of neverOccurrences; let idx = index; let last = last">
                  <div>
                    <label for="date-input" class="mb-0 pb-0">{{ occurence.SEND_OUT_DATE_UI }} - {{ occurence.NEXT_DEADLINE_UI }}</label>
                    <div class="d-flex">
                      <input type="text" [(ngModel)]="occurence.NAME" (ngModelChange)="neverOccurrenceUpdated(occurence)" class="form-control col-11 mb-1" id="requesting-period-name" placeholder="Name of requesting period" data-test="occurrences-input"/>
                      <i *ngIf="last" class="pt-2 la la-trash-o cursor" (click)="removeLastNeverOccurrence()"></i>
                    </div>
                  </div>
                </ng-container>
                <p class="mt-0 pt-0 mb-0">
                  <a (click)="addNeverOccurence()"><i class="la la-plus"></i> {{ locale("locale_key.pages.data_request.button.add_name") }}</a>
                </p>
              </div>
            </div>
            <div class="text-start" *ngIf="selectedRecurrenceEnds == 'ON_DATE'">
              <div class="mx-auto">
                <input type="date" [min]="nextDeadline" [(ngModel)]="maxDateUI" (change)="maxDateChanged()" id="date-input" class="me-auto form-control" />
                <span *ngIf="showMaxDateError" style="color: #ff4961; font-size: 80%"> {{ maxDateErrorMsg }} </span>
              </div>
              <div class="gray-bg p-1 w-100 ml-25" *ngIf="onDateOccurrences.length > 0">
                <div class="col occurence_list_on_date">
                  <ng-container *ngFor="let occurence of onDateOccurrences; let idx = index">
                    <div>
                      <label for="date-input" class="mb-0 pb-0">{{ occurence.SEND_OUT_DATE_UI }} - {{ occurence.NEXT_DEADLINE_UI }}</label>
                      <div class="d-flex">
                        <input type="text" [(ngModel)]="occurence.NAME" (ngModelChange)="onDateOccurrenceUpdated(occurence)" class="form-control mb-1" id="requesting-period-name" placeholder="Name of requesting period" />
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="text-start me-auto" *ngIf="selectedRecurrenceEnds == 'AFTER_MAX_OCCURENCES'">
              <div class="row ps-3">
                <input type="number" [min]="1" id="occurrences-input" class="form-control" [(ngModel)]="maxOccurrences" (change)="maxOccurrencesChanged()" style="max-width: 15%" />
                <label for="occurrences-input" class="ms-2 pt-1">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.option.recurrences") }}</label>
              </div>
            </div>
            <div class="gray-bg p-2 w-100 mt-1 mx-0" *ngIf="selectedRecurrenceEnds == 'AFTER_MAX_OCCURENCES'">
              <div class="col text-start occurence_list_after_max_ocurrences" *ngFor="let occurence of occurrences">
                <label for="date-input" class="mb-0 pb-0">{{ occurence.SEND_OUT_DATE_UI }} - {{ occurence.NEXT_DEADLINE_UI }}</label>
                <input type="text" [(ngModel)]="occurence.NAME" (ngModelChange)="occurenceUpdated(occurence)" class="form-control mb-1" id="requesting-period-name" placeholder="Name of requesting period" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col ms-2 py-1 my-1 border-b">
    <div class="d-flex ms-2">
      <h5 class="">{{ locale("locale_key.pages.data_request.create_wizard.title.reminder") }}</h5>
    </div>
    <div class="row ps-4">
      <div class="form-group">
        <br />
        <label class="switch">
          <input type="checkbox" [(ngModel)]="reminder" />
          <span class="slider round scheduleReminderSlider"></span>
        </label>
        <label>{{ locale("locale_key.pages.data_request.create_wizard.button.send_reminders") }}</label>
      </div>
    </div>
    <div class="mt-1 p-3 border ms-3" style="max-width: 60%" *ngIf="reminder">
      <ng-container *ngFor="let reminder of reminders; let i = index">
        <div class="d-flex flex-row ms-3 pb-3">
          <i class="la la-bell me-2 pt-2"></i>
          <select [(ngModel)]="reminder.preset" class="form-select form-control" style="width: 220px">
            <ng-container *ngIf="reminder.type === 'BEFORE_DEADLINE'">
              <option value="WEEK_4">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.4_weeks_before_deadline") }}</option>
              <option value="WEEK_2">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.2_weeks_before_deadline") }}</option>
              <option value="WEEK_1">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.1_week_before_deadline") }}</option>
              <option value="DAY_3">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.3_days_before_deadline") }}</option>
              <option value="DAY_1">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.1_day_before_deadline") }}</option>
              <option value="CUSTOM">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.custom") }}</option>
            </ng-container>
            <ng-container *ngIf="reminder.type === 'AFTER_DEADLINE'">
              <option value="WEEK_4">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.4_weeks_after_deadline") }}</option>
              <option value="WEEK_2">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.2_weeks_after_deadline") }}</option>
              <option value="WEEK_1">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.1_weeks_after_deadline") }}</option>
              <option value="DAY_3">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.3_days_after_deadline") }}</option>
              <option value="DAY_1">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.1_day_after_deadline") }}</option>
              <option value="CUSTOM">{{ locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.custom") }}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="reminder.preset === 'CUSTOM'">
            <input [(ngModel)]="reminder.value" (change)="reminderValueChanged({ idx: i, value: reminder.value })" type="number" [min]="1" class="form-control ms-2" style="display: inline-block; max-width: 100px" />
            <select [(ngModel)]="reminder.unit" class="form-select form-control d-inline-block ms-3" style="width: auto">
              <ng-container *ngIf="reminder.type === 'BEFORE_DEADLINE'">
                <option value="DAY">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.days_before_the_deadline") }}</option>
                <option value="WEEK">{{ locale("locale_key.pages.data_request.details.reminder.type.weeks_before_the_deadline") }}</option>
              </ng-container>
              <ng-container *ngIf="reminder.type === 'AFTER_DEADLINE'">
                <option value="DAY">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.days_after_the_deadline") }}</option>
                <option value="WEEK">{{ locale("locale_key.pages.data_request.create_wizard.reminder.type.weeks_after_the_deadline") }}</option>
              </ng-container>
            </select>
          </ng-container>
          <i class="la la-trash-o cursor ms-2 pt-2" (click)="removeReminder(i)"></i>
        </div>
      </ng-container>
      <div class="d-flex flex-row ms-3 ps-4">
        <a class="me-3" (click)="addReminder('BEFORE_DEADLINE')"
          ><b><i class="la la-plus me-2"></i></b>{{ locale("locale_key.pages.data_request.create_wizard.button.add_reminder") }}</a
        >
        <a class="me-3" (click)="addReminder('AFTER_DEADLINE')"
          ><b><i class="la la-plus me-2"></i></b>{{ locale("locale_key.pages.data_request.create_wizard.button.add_reminder_for_overdue_request") }}</a
        >
      </div>
      <div class="f-flex flex-row ms-3 mt-5">
        <input [(ngModel)]="sendReminderToCreator" type="checkbox" />
        <label class="ms-2 pt-2">{{ locale("locale_key.pages.data_request.create_wizard.option.send_a_copy_of_reminder_email_to_me") }}</label>
      </div>
    </div>
  </div>

  <div id="deadline" [formGroup]="form3" class="mt-3 mb-0" style="border: none">
    <div class="row ms-2 ps-4">
      <div class="d-flex mt-1">
        <h5 class="pe-2 ms-0 ps-0">{{ locale("locale_key.pages.data_request.create_wizard.data_approval.title") }}</h5>
        <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ locale("locale_key.pages.data_request.create_wizard.data_approval.desc") }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row ps-4">
      <div class="m-1 mb-1">
        <div class="d-flex flex-row ms-4">
          <div class="custom-radio custom-control">
            <input class="custom-control-input" type="radio" name="dataConnection" value="autoConnect" id="autoConnect" formControlName="dataConnection" />
            <label class="custom-control-label" for="autoConnect">{{ locale("locale_key.pages.data_request.create_wizard.data_approval.options.auto_approve") }}</label>
          </div>
        </div>
        <div class="d-flex flex-row ms-4">
          <div class="custom-radio custom-control">
            <input class="custom-control-input" type="radio" name="dataConnection" value="manualConnect" id="manualConnect" formControlName="dataConnection" />
            <label class="custom-control-label" for="manualConnect">{{ locale("locale_key.pages.data_request.create_wizard.data_approval.options.approve_manually") }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>