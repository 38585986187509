import { AbstractActivityFe } from "./AbstractActivityFe"
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ActivityTypeFe } from "./ActivityTypeFe";

export class ConnectSectionsActivityFe extends AbstractActivityFe {
  
  icon = "la la-chain grad-green"

  constructor(
    id: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    public submittedAnswerActivityId: string,
    public connectedSectionIds: string[],
    languageService: LanguageService
  ) {
    super(
      id,
      ActivityTypeFe.CONNECT_SECTIONS,
      submitterAffId,
      submitterFirstName,
      submitterLastName,
      submitterCompanyId,
      submitterCompanyName,
      submissionDate,
      message,
      draft,
      readStatus,
      requestTaskId,
      requestGroupId,
      requestGroupLabel,
      languageService
    )
    this.homePageLocaleKey = `locale_key.pages.data_request.activity.data_added`
    this.activityHistoryLocaleKey = `locale_key.pages.data_request.activity.data_added_to_master_table`
  }

  public static fromTransfer(transfer: any, languageService: LanguageService): ConnectSectionsActivityFe {
    let activity = new ConnectSectionsActivityFe(
      transfer.id,
      transfer.submitterAffId,
      transfer.submitterFirstName,
      transfer.submitterLastName,
      transfer.submitterCompanyId,
      transfer.submitterCompanyName,
      transfer.submissionDate,
      transfer.message,
      transfer.draft,
      transfer.readStatus,
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      transfer.submittedAnswerActivityId,
      transfer.connectedSectionIds,
      languageService
    )
    return activity;
  }     
}
