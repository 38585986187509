import { Component, HostListener, TemplateRef, ViewChild, AfterViewInit, AfterViewChecked, Renderer2, ElementRef } from '@angular/core'
import { AffiliationRoleFe } from 'src/app/model/data-suppliers/company/AffiliationRoleFe'
import { READ_STATUS, RequestFe, REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe'
import { REQUEST_GROUP__STATUS, RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe'
import { TaskFe, TASK_STATUS } from 'src/app/model/data-suppliers/task/TaskFe'
import { AbstractActivityFe } from 'src/app/model/data-suppliers/timeline/AbstractActivityFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { DateUtil } from 'src/app/utils/DateUtil'
import { CloseRequestActivityFe } from 'src/app/model/data-suppliers/timeline/CloseRequestActivityFe'
import { SubmitMessageActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitMessageActivityFe'
import { RejectSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe'
import { AcceptSubmissionActivityFe } from 'src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe'
import { CompanyTypeFe } from 'src/app/model/data-suppliers/company/CompanyFe'
import { CreateRequestActivityFe } from 'src/app/model/data-suppliers/timeline/CreateRequestActivityFe'
import { Router } from '@angular/router'
import { RoutesFe } from 'src/app/route/RoutesFe'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { ActivityTypeFe } from 'src/app/model/data-suppliers/timeline/ActivityTypeFe'
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe'
import { ActivityStatusFe } from 'src/app/model/data-suppliers/timeline/ActivityStatusFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Language } from 'src/app/utils/language/Language'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { RequestServiceFe } from 'src/app/services/RequestServiceFe'

export class Articles {
  posts: any[] = [
    {
      id: '5-organizational-challenges-you-might-face-as-a-sustainability-manager-and-how-to-tackle-them',
      title: '5 organizational challenges you might face as a sustainability manager, and how to tackle them',
      imagePath: 'https://sustainlab.co/assets/img/blog-image/5-organizational-challenges-you-might-face-as-a-sustainability-manager-and-how-to-tackle-them/18.png',
      url: 'https://sustainlab.co/blog/5-organizational-challenges-you-might-face-as-a-sustainability-manager-and-how-to-tackle-them',
    },

    {
      id: 'why-should-private-equity-venture-capital-industries-care-about-sustainability-reporting',
      title: 'Why should Private Equity & Venture Capital industries care about sustainability reporting? ',
      imagePath: 'https://sustainlab.co/assets/img/blog-image/why-should-private-equity-venture-capital-industries-care-about-sustainability-reporting/1.jpg',
      url: 'https://sustainlab.co/blog/why-should-private-equity-venture-capital-industries-care-about-sustainability-reporting',
    },
    {
      id: 'how-to-get-the-most-out-of-your-sustainability-data',
      title: 'How to get the most out of your sustainability data?',
      imagePath: 'https://sustainlab.co/assets/img/blog-image/how-to-get-the-most-out-of-your-sustainability-data/1.jpg',
      url: 'https://sustainlab.co/blog/how-to-get-the-most-out-of-your-sustainability-data',
    },
    {
      id: '5-steps-to-write-an-impactful-sustainability-reporting',
      title: '5 steps for impactful Sustainability Reporting',
      imagePath: 'https://sustainlab.co/assets/img/blog-image/5-steps-to-write-an-impactful-sustainability-reporting/sustainability-reporting-puzzle-large.jpg',
      url: 'https://sustainlab.co/blog/5-steps-to-write-an-impactful-sustainability-reporting',
    },
    {
      id: 'why-data-transparency-and-traceability-matter-in-sustainability',
      title: 'Why data transparency and traceability matter in sustainability? ',
      imagePath: 'https://sustainlab.co/assets/img/blog-image/why-data-transparency-and-traceability-matter-in-sustainability/9.jpg',
      url: 'https://sustainlab.co/blog/why-data-transparency-and-traceability-matter-in-sustainability',
    },
  ]
}

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AbstractLanguageComponent {
  allTasks: TaskFe[]
  requests: RequestFe[]
  requestGroups: RequestGroupFe[]
  tasks: { pending: TaskFe[]; submitted: TaskFe[]; approved: TaskFe[] } = { pending: [], submitted: [], approved: [] }
  posts: any[] = new Articles().posts
  recentActivities: AbstractActivityFe[] = []
  noOfUnreadActivities = 0
  noOfToReviewRequests = 0
  noOfUpcomingDeadlines = 0
  noOfDashboards = 0
  noOfDataSources = 0
  noOfKpis = 0
  unreadTasks: TaskFe[] = []
  inProgress = false
  changeDeadlineOnHover: boolean
  initCacheInProgress: boolean
  stingLenght: string
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  languages = this.languageService.availableLanguages
  selectedLang: Language
  selectedEmailLang: Language

  isInitialized: boolean = false;

  @ViewChild('selectLanguage') selectLanguageModal: TemplateRef<any>
  @ViewChild('selectEmailLanguage') selectEmailLanguageModal: TemplateRef<any>
  modalRef: BsModalRef

  public getScreenWidth: any
  selectedActivity: AbstractActivityFe

  constructor(
    public stateService: StateServiceFe,
    private router: Router,
    private displayService: DisplayServiceFe,
    languageService: LanguageService,
    private loginService: LoginServiceFe,
    protected modalService: BsModalService,
    private responsive: ResponsiveService,
    public requestService: RequestServiceFe,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    super(languageService)
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    await this.renderNewData()
    await this.initLanguages()
    await this.openInitialModalIfFirstTime()
    this.isInitialized = true;
  }

  async initLanguages(){
    const platformLang = this.loginService.loginUser.language
    if (platformLang) {
      this.selectedLang = this.languageService.getLanguageObject(platformLang)
    } else {
      this.selectedLang = this.languageService.getLanguageObject('en')
    }

    if (!this.selectedEmailLang) {
      this.selectedEmailLang = this.languageService.getLanguageObject('en')
    }
  }

  async renderNewData() {
    this.inProgress = true
    this.recentActivities = []
    this.noOfUnreadActivities = 0
    this.noOfUpcomingDeadlines = 0
    this.noOfDashboards = 0
    this.noOfDataSources = 0
    this.noOfKpis = 0
    this.noOfToReviewRequests = 0

    this.requestGroups = await this.stateService.getRequestGroups()

    if (this.isDO()) {
      this.allTasks = await this.stateService.getTasks()
      this.tasks = { pending: [], submitted: [], approved: [] }
      this.allTasks.forEach((task) => {
        if (task.status === TASK_STATUS.APPROVED || task.status === TASK_STATUS.CLOSED) {
          this.tasks.approved.push(task)
        } else if (task.status === TASK_STATUS.CREATED) {
          this.tasks.pending.push(task)
        } else if (task.status === TASK_STATUS.IN_PROGRESS) {
          let activity = task.timelineItems
            .slice()
            .reverse()
            .find((act) => act instanceof RejectSubmissionActivityFe || act instanceof SubmitAnswersActivityFe)
          if (activity instanceof RejectSubmissionActivityFe) {
            this.tasks.pending.push(task)
          } else if (activity instanceof SubmitAnswersActivityFe) {
            this.tasks.submitted.push(task)
          }
        }

        if (task.readStatus == READ_STATUS.UNREAD) {
          this.noOfUnreadActivities += task.timelineItems.filter((act) => act.readStatus == READ_STATUS.UNREAD).length
        }
      })
      this.recentActivities = (await this.stateService.getAllTaskActivities()).slice(0, 20)
      this.noOfUpcomingDeadlines = this.allTasks.filter(
        (task) => task.status != REQUEST_TASK_STATUS.APPROVED && task.status != REQUEST_TASK_STATUS.CLOSED && DateUtil.getTimeDifference(new Date(task.dueDate), new Date()) <= 30,
      ).length
    } else if (this.stateService.activeWorkspace.companyType !== CompanyTypeFe.SUPPLIER) {
      let allReqActivities = await this.stateService.getAllRequestActivities()
      let remainingActivities = []
      allReqActivities.forEach((activity) => {
        if (activity instanceof CreateRequestActivityFe) {
          let alreadyContains = remainingActivities.some((a) => a.type == ActivityTypeFe.CREATE_REQUEST && a.requestGroupId == activity.requestGroupId)
          if (!alreadyContains) {
            let noOfRequestsInRG = allReqActivities.filter((a) => a.type == ActivityTypeFe.CREATE_REQUEST && a.requestGroupId == activity.requestGroupId)
            activity.noOfTotalRequests = noOfRequestsInRG.length
            remainingActivities.push(activity)
          }
        } else {
          remainingActivities.push(activity)
        }
      })
      this.recentActivities = remainingActivities.slice(0, 20)
      let dashboards = await this.stateService.getDashboards()
      this.noOfDashboards = dashboards.length

      let stageTables = await this.stateService.getStageTableInfos()
      this.noOfDataSources = stageTables.length

      let backednkpiDatas = await this.stateService.getInsights()
      this.noOfKpis = backednkpiDatas.length
      this.noOfToReviewRequests = this.stateService.activeWorkspace.noOfToReviewRequests

      let toDoActivitiesGroupedByReq: { [key: string]: { activities: AbstractActivityFe[]; isRead: boolean } } = {}
      let unreadActivities = []
      this.requests = await this.stateService.getAllRequests()
      allReqActivities.forEach((a) => {
        if (a.readStatus == READ_STATUS.UNREAD && a.submitterAffId != this.stateService.activeWorkspace.affiliationId) {
          unreadActivities.push(a)
        }
      })
      let toReviewActivities: AbstractActivityFe[] = []

      this.requests.forEach((r) => {
        if (r.status == REQUEST_TASK_STATUS.IN_PROGRESS) {
          let latestSubmission = r.timelineItems
            .slice()
            .reverse()
            .find((a) => this.requestService.isSubmitAnswer(a) || this.requestService.isReject(a) || this.requestService.isAccept(a))
          if (latestSubmission instanceof SubmitAnswersActivityFe && latestSubmission.readStatus == READ_STATUS.READ) {
            toReviewActivities.push(latestSubmission)
          }
        }
      })
      let toDoActivities = [...unreadActivities, ...toReviewActivities]
      toDoActivities.sort((a, b) => new Date(b.submissionDate).getTime() - new Date(a.submissionDate).getTime())
      toDoActivities.forEach((a) => {
        if (toDoActivitiesGroupedByReq[a.requestId]) {
          toDoActivitiesGroupedByReq[a.requestId].activities.push(a)
        } else {
          toDoActivitiesGroupedByReq[a.requestId] = { activities: [a], isRead: a.readStatus == READ_STATUS.READ }
        }
      })
      this.noOfToReviewRequests = Object.keys(toDoActivitiesGroupedByReq).length
      this.noOfUnreadActivities = allReqActivities.filter((a) => a.readStatus == READ_STATUS.UNREAD).length
      this.noOfUpcomingDeadlines = this.requestGroups.filter((rg) => !rg.draft && rg.status != REQUEST_GROUP__STATUS.APPROVED && DateUtil.getTimeDifference(new Date(rg.dueDate), new Date()) <= 30).length
    }

    this.inProgress = false
  }

  @HostListener('document:keydown', ['$event'])
  handleInitalModalKeyboardEvent(event: KeyboardEvent) {
    // update language if use press escape
    if (event.key === 'Escape') {
      this.changeLanguage();
    }
  }

  openInitialModalIfFirstTime() {
    if (!this.isInitialized) {
      if (!this.loginService.loginUser.language) {
        this.openModal(this.selectLanguageModal)
      }

      if (this.isFirstTime()) {
        this.openModal(this.selectEmailLanguageModal)
      }
    }
  }

  openActivityPortal(activity: AbstractActivityFe) {
    if (this.stateService.activeWorkspace.affiliationRole != AffiliationRoleFe.DATA_OWNER) {
      this.displayService.setRequestFromHome(activity)
      this.router.navigate([RoutesFe.REQUESTS.fullPath()])
    } else {
      this.displayService.setRequestFromHome(activity)
      this.router.navigate([RoutesFe.DO_REQUESTS.fullPath()])
    }
  }

  isActionRequired(activity: AbstractActivityFe) {
    let task = this.allTasks.find((t) => t.id == activity.requestId)
    if (activity instanceof CreateRequestActivityFe) {
      return task.status == REQUEST_TASK_STATUS.CREATED
    } else if (activity instanceof RejectSubmissionActivityFe) {
      let act = task.timelineItems
        .slice()
        .reverse()
        .find((a) => a.id == activity.id || a instanceof SubmitAnswersActivityFe || a instanceof CloseRequestActivityFe)
      if (act instanceof SubmitAnswersActivityFe || act instanceof CloseRequestActivityFe) return false
      else if (act instanceof RejectSubmissionActivityFe) return true
    }
  }

  isSMActionRequired(activity: AbstractActivityFe) {
    if (this.requests) {
      let request = this.requests.find(r => r.id == activity.requestId)
      if (activity instanceof SubmitAnswersActivityFe && activity.status == ActivityStatusFe.SUBMITTED) {
        let act = request.timelineItems.slice().reverse().find(a => a instanceof SubmitAnswersActivityFe)
        if (act)
          return act.id == activity.id
      }
    }
    return false
  }

  async changeLanguage() {
    this.inProgress = true
    try {
      if (!this.selectedLang) {
        this.selectedLang = this.languageService.getLanguageObject('en')
      }

      if(!this.selectedEmailLang) {
        this.selectedEmailLang = this.languageService.getLanguageObject('en')
      }
      
      await this.stateService.changeProfileEmailLanguage(this.selectedLang.code, this.selectedEmailLang.code)
      this.languageService.changeLanguage(this.selectedLang.code)
      this.loginService.changeLangauage(this.selectedLang.code)
      this.loginService.loginUser.emailLanguage = this.selectedEmailLang.code
    } catch (err) {}
    this.inProgress = false
    this.closeModal()
  }

  async getCompanyEmailLanguage(): Promise<any> {
    try {
      let langCode = await this.stateService.getCompanyEmailLanguage()
      return langCode 
    } catch (err) { }
  }

  openModal(modalTemplateRef: TemplateRef<any>, className: string = 'modal-md') {
    let config = {
      ignoreBackdropClick: true,
      class: className,
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
  }

  getCreatorName(rgId: string): string {
    let rg = this.getRequestGroup(rgId)
    return `${rg?.requesterFirstName} ${rg?.requesterLastName}`
  }

  getAssigneeName(requestId: string): string {
    let req = this.requests.find((group) => group.id == requestId)
    return req?.getAssigneeName()
  }

  getRequesterName(requestId: string): string {
    let task = this.allTasks.find((task) => task.id == requestId)
    return task?.getRequesterName()
  }

  getCompanyName(rgId: string): string {
    let rg = this.getRequestGroup(rgId)
    return `${rg?.requesterCompanyName}`
  }

  getAssigneeCompanyName(request: RequestFe): string {
    return request.supplierCompanyName ? request.supplierCompanyName : this.stateService.activeWorkspace.companyName
  }

  getRequestGroup(rgId: string): RequestGroupFe {
    let rg = this.requestGroups.find((group) => group.id == rgId)
    return rg
  }

  getRequests(rgId: string): RequestFe[] {
    return this.requests?.filter((r) => r.requestGroupId == rgId)
  }

  isDO() {
    return this.stateService.activeWorkspace.affiliationRole == AffiliationRoleFe.DATA_OWNER
  }

  isFirstTime(): boolean {
    return this.loginService.loginUser.language && !this.loginService.loginUser.emailLanguage;
  }

  getRequestGroupTitle(activity: AbstractActivityFe): string {
    const requestGroup = this.requestGroups.find(rg => rg.id === activity.requestGroupId)
    if (requestGroup) {
      return requestGroup.getTitle()
    }
    return activity.requestGroupLabel
  }
}
