import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { ManagerInvitationFe } from 'src/app/model/invitation/ManagerInvitationFe'
import { AccessibleCompanyFe } from 'src/app/model/org/AccessibleCompanyFe'
import { ManagerInvitation_AddFe } from 'src/app/model/invitation/ManagerInvitation_AddFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { InvitationLinkFe } from 'src/app/model/email/InvitationLinkFe'

import { CompanyFe } from 'src/app/model/data-suppliers/company/CompanyFe'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { Admin_AddFe } from 'src/app/model/user/Admin_AddFe'
import { AffiliationRoleFe } from 'src/app/model/user/AffiliationRoleFe'
import { CustomerSuccess_AddFe } from 'src/app/model/user/CustomerSuccess_AddFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'

@Component({
  selector: 'app-customer-success-invite',
  templateUrl: './customer-success-invite.component.html',
  styleUrls: ['./customer-success-invite.component.scss'],
})
export class CustomerSuccessInviteComponent extends AbstractLanguageComponent implements OnInit {
  form1 = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.NonWhitespaceRegExp), Validators.minLength(1)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.NonWhitespaceRegExp), Validators.minLength(1)]),
    email: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)]),
  })

  form3 = new FormGroup({
    emailSubject: new FormControl('', [Validators.required]),
    emailContent: new FormControl('', [Validators.required]),
  })
  activeFormNo = 1
  inviterCompany: string = 'SL'
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  companyNameMessage = this.locale('locale_key.general.validation_message.company_name_required')
  companyWebFromatMessage = this.locale('locale_key.general.validation_message.invalid_web_address')
  firstNameMessage = this.locale('locale_key.general.validation_message.first_name_required')
  firstNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_first_name')
  lastNameMessage = this.locale('locale_key.general.validation_message.last_name_required')
  lastNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_last_name')
  emailMessage = this.locale('locale_key.general.validation_message.email_required')
  lowerCaseEmail = this.locale('locale_key.general.validation_message.invalid_email')
  industryGroupChangeVal: boolean = false
  industryChangeVal: boolean = false
  isLoading = false

  @Output() supplierInfo = new Subject<ManagerInvitationFe>()
  @Input() selectedSupplier!: ManagerInvitationFe | CompanyFe
  @Input() mode = 'admin'
  invitationLink: InvitationLinkFe
  invitationLinkGenerated = false
  newManager: ManagerInvitation_AddFe
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  mgmtCompanies: CompanyFe[] = []
  assignedCompanies: CompanyFe[] = []

  steps = [
    { title: this.locale('locale_key.pages.admins.add_customer_success_modal.header.customer_success'), completed: false },
    { title: this.locale('locale_key.pages.admins.add_customer_success_modal.header.assign_companies'), completed: false },
  ]

  constructor(private modalRef: BsModalRef, private modalService: BsModalService, @Inject(DOCUMENT) private _document: Document, private stateService: StateServiceFe, languageService: LanguageService, private responsive: ResponsiveService) {
    super(languageService)
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    this.mgmtCompanies = await this.stateService.getAllManagementCompanies()
  }

  async addUser() {
    try {
      this.isLoading = true
      if (this.mode == 'admin') {
        let admin = new Admin_AddFe(this.form1.controls.firstName.value, this.form1.controls.lastName.value, this.form1.controls.email.value, AffiliationRoleFe.CUSTOMER_SUCCESS_ADMIN)
        await this.stateService.addAdmin(admin)
      } else {
        let cs = new CustomerSuccess_AddFe(this.form1.controls.firstName.value, this.form1.controls.lastName.value, this.form1.controls.email.value, this.assignedCompanies)
        await this.stateService.addCS(cs)
      }
      this.isLoading = false
      this.closeModal()
    } catch (err) {
      this.isLoading = false
      this.closeModal()
    }
  }

  toggleCompanies(event: Event, company: CompanyFe) {
    if (event.target['checked']) {
      this.assignedCompanies.push(company)
    } else {
      this.assignedCompanies = this.assignedCompanies.filter((c) => c != company)
    }
  }

  closeModal(): void {
    this.modalService.hide()
    document.body.classList.remove('modal-open')
  }

  next(): void {
    this.steps[this.currentActive - 1].completed = true
    const circles = this._document.querySelectorAll('.circle1')
    const steps = this._document.querySelectorAll('.stepper')
    this.currentActive++
    if (this.currentActive > circles.length) {
      if (this.currentActive > steps.length) this.currentActive = circles.length
    }
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.steps[this.currentActive - 1].completed = false
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  update() {
    this.progress = this._document.getElementById('progress1') as HTMLElement
    this.circles = this._document.querySelectorAll('.circle1')
    this.prevButton = this._document.getElementById('prev') as HTMLButtonElement
    this.nextButton = this._document.getElementById('next') as HTMLButtonElement

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add('active-circle1')
      } else {
        circle.classList.remove('active-circle1')
      }
    })

    const actives = this._document.querySelectorAll('.active-circle1')

    this.progress.style.width = ((actives.length - 1) / (this.circles.length - 1)) * 62 + '%'
  }
}
