<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <nav>
          <ul class="nav">
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ slocale("PROJECTS") }}</h3>
              </a>
            </li>
            <li class="navbar-item">
              <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
            </li>
            <li class="navbar-item">
              <a>
                <h3 class="mt-2"> {{ activeCsrdProject.name }} </h3>
              </a>
            </li>
            <li class="navbar-item">
              <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
            </li>
            <li class="navbar-item">
              <a>
                <h3 class="mt-2"> {{ activeEsrs.code }} {{ activeEsrs.name }} </h3>
              </a>
            </li>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ml-4 mt-" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button *ngIf="toolbarButton.visible()" type="button" class="btn page-toolbar-button" (click)="handleToolbarAction(toolbarButton.actionName)" data-bs-toggle="tooltip" data-placement="top" [title]="toolbarButton.tooltip"
                show-delay="3000">
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div class="card me-2" style="height: 84vh; max-width: 100vw !important" [ngStyle]="!cardHeader ? { border: 'none' } : {}">
            <div class="pb-0" *ngIf="cardHeader">
              <div class="card-header bg-white">
                <div class="d-flex flex-row justify-content-between">
                  <h5 class="card-title mb-0 pb-0">{{ activeDr.code }} - {{ activeDr.name }}</h5>
                </div>
              </div>
              <div class=" p-sm-0 m-sm-0">
                <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                  <div class="row text-center pb-3 pt-4">

                    <!-- TO DO : BEGIN To be removed in phase 2 -->
                    <div class="text-start mx-0 px-0 col-4">
                    </div>
                    <!-- TO DO : END To be removed in phase 2 -->

                    <div class="mt-0 pt-0 mx-0 ps-1 pe-0 col-4">
                      <div class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active-circle1 mx-0 px-0">1</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Select data for reporting") }}</h6>
                        </ng-container>
                        <!-- TO DO : To be enabled in phase 2 -->
                        <!-- <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span> -->
                      </div>
                    </div>

                    <!-- TO DO : BEGIN To be removed in phase 2 -->
                    <div class="text-start mx-0 px-0 col-4">
                    </div>
                    <!-- TO DO : END To be removed in phase 2 -->


                    <!-- TO DO : To be enabled in phase 2 -->
                    <!-- <div class="text-start mx-0 px-0 col-4">
                      <div class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">2</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Assign data collection") }}</h6>
                        </ng-container>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div> -->

                    <!-- TO DO : To be enabled in phase 2 -->
                    <!-- <div class="text-start mx-0 px-0 col-4">
                      <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">3</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Define submission details") }}</h6>
                        </ng-container>
                      </div>
                    </div> -->

                  </div>
                </ng-container>
                <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                  <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                    <div class="d-block text-center">
                      <!-- TO DO : BEGIN To be removed in phase 2 -->
                      <div class="col stepper text-center active-stepper"></div>
                      <!-- TO DO : END To be removed in phase 2 -->

                      <!-- TO DO : To be enabled in phase 2 -->
                      <!-- <div class="col stepper text-center" *ngFor="let step of stepsDR; let i = index" [class.active-stepper]="currentActive === i + 1 || step.completed"></div> -->
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center mb-1">
                    <!-- TO DO : BEGIN To be removed in phase 2 -->
                    {{ (stepsDR)[currentActive - 1].title }}
                    <!-- TO DO : END To be removed in phase 2 -->

                    <!-- TO DO : To be enabled in phase 2 -->
                    <!-- <h6 class="text-center m-0">
                      {{ (stepsDR)[currentActive - 1].title }} ({{ currentActive }}/{{ ( stepsDR).length }})
                    </h6> -->
                  </div>
                </ng-container>
              </div>
            </div>
            <hr class="m-0 pt-0 px-0" [ngClass]="!cardHeader ? 'hide' : 'show'" />
            <!-- NEEDED FOR PHASE 3 -->
            <!-- <div class="row  p-3  mb-2 unreadActivity mx-0" *ngIf="E34WaterConsumption">
              <div class="d-flex flex-column flex-sm-row justify-content-between">
                <div class="flex-grow-1 mb-2 mb-md-0">
                  <p class="mb-0 pb-0">
                    {{
                    slocale(
                    "The updated assess- and assignments require closing affected data requests and superuser tasks, and re-sending them with new content and/or reporters. Click 'Edit' to modify submission details or proceed to re-send with
                    the current details."
                    )
                    }}
                  </p>
                  <p class="mb-0 pb-0">
                    {{ slocale("Your changes don't require re-sending data requests or superuser tasks. Simply save them.To modify submission details, select 'Edit'. Note: Changes will prompt the re-sending of requests and tasks.") }}
                  </p>
                </div>
                <div class="w-auto text-nowrap">
                  <button class="btn tertiary-button w-auto text-nowrap" (click)="openModal(waterManagementModal, 'modal-lg')"><i class="la la-edit me-2"></i>{{ slocale("Edit details") }}</button>
                </div>
              </div>
            </div> -->

            <div class="card-body mt-1 pt-0 px-0" style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important">
              <!-- CSRD -->
              <div *ngIf="activeFormNo == 1" class="px-3">
                <!--Review the details of the Disclosure Requirement starts-->
                <div class="d-flex flex-column justify-content-around aling-items-center" style="height: 100% !important">
                  <div class="mt-3 row px-3">
                    <p class="mb-0 pb-0">{{ slocale("Review the details of the Disclosure Requirement and each data point.") }}</p>
                    <p class="mt-0 pt-0" *ngIf="activeEsrs.code != 'ESRS 2'">{{ slocale("Assess whether a data point is material, and if there's an option to skip it, decide whether to omit it.") }}</p>
                  </div>
                  <div class=" p-sm-0 m-sm-0 vh-auto d-flex flex-column justify-content-center align-items-center">
                    <div class="bg-comp-grey  p-3  w-100">
                      <h6 class="mb-0 pb-0 bold">{{ activeDr.code }} - {{ activeDr.name }}</h6>
                      <p>
                        <span class="bold">{{ slocale("Description:") }}</span>{{ activeDr.description }}
                      </p>
                      <p>
                        <span class="bold">{{ slocale("Objective:") }}</span> {{ activeDr.objective }}
                      </p>
                      <!-- <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small> -->
                    </div>
                    <!-- NEEDED IN PHASE 3 -->
                    <!-- <div class="my-2 border  p-3  w-100">
                      <p>
                        <span class="text-blue bold">{{ slocale("General phase in information: ") }}</span>{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation.") }}
                      </p>
                      <div class="d-flex align-items-center flex-wrap">
                        <label class="switch mt-2 me-2">
                          <input type="checkbox" (change)="openModal(omitDataModal, 'modal-md')" />
                          <span class="slider round"></span>
                        </label>
                        <span class="pt-2">{{ slocale("Omit data point in reporting") }} </span><span class="ms-2 text-pink pt-2">{{ slocale("(Customized)") }}</span>
                      </div>
                    </div> -->
                    <div class="d-flex flex-row flex-wrap py-2 w-100">
                      <p class="d-inline mb-0 pb-0 me-2 bold">{{ slocale("Legend:") }}</p>
                      <span class="text-blue me-3"><i class="la la-clock me-1"></i>{{ slocale("Phase-in or effective date") }}</span>
                      <span class="me-3 may_disclose_help_text">
                        <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>{{ slocale("May disclose") }}
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>
                            {{
                            slocale(
                            "Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected."
                            )
                            }}
                          </p>
                        </div>
                      </span>
                      <!-- <span class="me-3"><i class="la la-eye me-1 text-orange"></i>{{ slocale("Show data collection questions") }}</span> -->
                      <!-- <span class="me-3"><i class="la la-eye me-1"></i>{{ slocale("Questions are edited") }}</span> -->
                      <span class="me-3"><i class="la la-info-circle me-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>{{ slocale("Show more information") }}
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale("Text to be added!") }}</p>
                        </div>
                      </span>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100">
                      <div class="col-7 thin-border-r">
                        <div class="bg-light-gray py-2 d-flex flex-row justify-content-center align-content-center w-100 border h-100">
                          <h5 class="mb-0 pb-0">{{ slocale("ESRS data point") }}</h5>
                        </div>
                      </div>
                      <div class="col-5 thin-border-r">
                        <div class="bg-light-gray py-2 d-flex flex-row justify-content-center align-content-center w-100 border h-100">
                          <h5 class="mb-0 pb-0">{{ slocale("Settings") }}</h5>
                        </div>
                      </div>
                    </div>

                    <!-- CSRD DATAPOINT LIST -->
                    <ng-container *ngFor="let datapoint of csrdDatapoints">
                      <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                        <div class="col-7 thin-border-r d-flex flex-row justify-content-between p-2">
                          <div class="p-2 pb-5 d-flex flex-column w-100">
                            <p class="m-0 p-0 bold">
                              <i *ngIf="datapoint.phaseIn" class="la la-clock me-1 text-blue"></i>
                              <ng-container *ngIf="datapoint.isVoluntary">
                                <i class="la la-question me-1 text-orange info-popover-arrow" aria-haspopup="true" aria-expanded="false"></i>
                                <span>
                                  <div class="dropdown-menu arrow p-2 info-popover">
                                    <p>
                                      <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                    </p>
                                  </div>
                                </span>
                              </ng-container>
                              {{ datapoint.id }} {{ datapoint.name }}
                            </p>
                            <ng-container *ngIf="datapoint.showMoreInfo">
                              <p class="mt-3 mb-0 p-0 bold">{{ slocale("Description from the standards") }}</p>
                              <small>{{ datapoint.description }}</small>
                              <p class="mt-3 mb-0 p-0 bold">{{ slocale("Reference to standard") }}</p>
                              <small>{{ datapoint.LegislationLocation }}</small>
                              <p class="mt-3 mb-0 p-0 bold">{{ slocale("Application footnotes") }}</p>
                              <small>{{ datapoint.applicationFootnotes }}</small>
                              <!-- <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small> -->
                            </ng-container>
                          </div>
                          <div class="p-2 d-flex flex-column">
                            <!-- <i class="la la-eye ms-auto cursor-pointer"></i> -->
                            <i class="la la-info-circle ms-auto info-popover-arrow cursor-pointer" *ngIf="!datapoint.showMoreInfo" (click)="toggleShowMoreInfo(datapoint)"></i>
                            <i class="la la-info-circle ms-auto info-popover-arrow cursor-pointer text-pink" *ngIf="datapoint.showMoreInfo" (click)="toggleShowMoreInfo(datapoint)"></i>
                          </div>
                        </div>
                        <div class="col-5 bg-comp-grey p-2 thin-border-r">
                          <span class="badge-mandatory ms-2"
                            *ngIf="activeEsrs.code == 'ESRS 2' || datapoint.id.toUpperCase().includes('IRO-1') || ( this.activeEsrs.code.toUpperCase().includes('E1') && datapoint.id.toUpperCase().includes('IRO-2'))">{{ slocale("Mandatory")
                            }}</span>
                          <div class="dropdown" *ngIf="activeEsrs.code != 'ESRS 2' && !datapoint.id.toUpperCase().includes('IRO-1') && !( this.activeEsrs.code.toUpperCase().includes('E1') && datapoint.id.toUpperCase().includes('IRO-2'))">
                            <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <span *ngIf="datapoint.isMaterial" class="badge-material ms-2">{{ slocale("Material") }}</span>
                              <span *ngIf="!datapoint.isMaterial" class="badge-not-material ms-2">{{ slocale("Not material") }}</span>
                            </a>
                            <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                              <a class="dropdown-item" (click)="toggleMateriality(datapoint)">
                                <span *ngIf="!datapoint.isMaterial" class="badge-material ms-2">{{ slocale("Material") }}</span>
                                <span *ngIf="datapoint.isMaterial" class="badge-not-material ms-2">{{ slocale("Not material") }}</span>
                              </a>
                            </div>
                          </div>
                          <p *ngIf="datapoint.isMaterial && datapoint.canOmit" class="m-0 p-0">{{ slocale("This data point can potentially be omitted for the reporting. Read the available information carefully before making a decision.")
                            }}</p>
                          <div *ngIf="datapoint.isMaterial && datapoint.canOmit" class="d-flex align-items-center flex-wrap">
                            <label class="switch mt-2 me-2">
                              <input type="checkbox" (click)="startOmitDatapoint(datapoint)" [checked]="datapoint.isOmitted" />
                              <span class="slider round"></span>
                            </label>
                            <span class="pt-2">{{ slocale("Omit data point in reporting") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  {{ slocale("Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected.") }}
                                </p>
                              </div>
                            </span>
                          </div>
                          <div *ngIf="!datapoint.isMaterial" class="d-flex align-items-center flex-wrap">
                            <label class="switch mt-2 me-2">
                              <input type="checkbox" (click)="toggleCollectVoluntarily(datapoint)" [checked]="datapoint.collectVoluntarily" />
                              <span class="slider round"></span>
                            </label>
                            <span class="pt-2">{{ slocale("Collect data voluntarily") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                  steps.") }}</p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <!-- <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-7 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-column w-100">
                          <p class="m-0 p-0">{{ slocale("Total water consumption") }}</p>
                          <p class="m-0 p-0">{{ slocale("Description from legislation") }}</p>
                          <small>{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.") }}</small>
                          <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto" (click)="openModal(waterManagementModal, 'modal-lg')"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow text-pink" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-7 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-row justify-content-start align-items-start w-100">
                          <p class="m-0 p-0"><i class="la la-clock me-1 text-blue"></i>{{ slocale("Total water consumption") }}</p>
                        </div>
                        <div class="p-2 d-flex flex-column w-100">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                          <p class="m-0 p-0">{{ slocale("This data point can potentially be omitted for the reporting. Read the available information carefully before making a decision.") }}</p>
                          <div class="d-flex align-items-center flex-wrap">
                            <label class="switch mt-2 me-2">
                              <input type="checkbox" (change)="openModal(omitDataModal, 'modal-md')" />
                              <span class="slider round"></span>
                            </label>
                            <span class="pt-2">{{ slocale("Omit data point in reporting") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  {{ slocale("Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected.") }}
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-7 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-column w-100">
                          <p class="m-0 p-0">
                            <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <span>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                </p>
                              </div>
                            </span>{{ slocale("Additional water intensity ratio") }}
                          </p>
                          <div class="p-2 d-flex flex-column w-100">
                            <p class="m-0 p-0">{{ slocale("Total water consumption") }}</p>
                            <p class="m-0 p-0">{{ slocale("Description from legislation") }}</p>
                            <small>{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.") }}</small>
                            <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small>
                          </div>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 bg-comp-grey p-2 d-flex flex-column thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" (change)="openModal(omitDataModal, 'modal-md')" />
                            <span class="slider round"></span>
                          </label>
                          <span class="pt-2">{{ slocale("Omit data point in reporting") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>
                                {{ slocale("Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected.") }}
                              </p>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row pink-border">
                      <div class="col-7 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-row justify-content-start align-items-start w-100">
                          <p class="m-0 p-0">
                            <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <span>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                </p>
                              </div>
                            </span>{{ slocale("Total water withdrawals") }}
                          </p>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <span class="pt-2">{{ slocale("Collect data voluntarily") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-7 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-row justify-content-start align-items-start w-100">
                          <p class="m-0 p-0 disabled">
                            <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <span>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p class="">
                                  <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                </p>
                              </div>
                            </span>{{ slocale("Total water withdrawals") }}
                          </p>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <span class="pt-2">{{ slocale("Collect data voluntarily") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!--Review the details of the Disclosure Requirement ends-->

              </div>
              <!--NAME THE PROJECT PAGE-->
              <div *ngIf="activeFormNo == 2" class="px-3">
                <!--Review the details of the Disclosure Requirement starts-->
                <div class="d-flex flex-column justify-content-around aling-items-center" style="height: 100% !important">
                  <div class="row px-3">
                    <p class="mb-0 pb-0">{{ slocale("Review the details of the Disclosure Requirement and each data point.") }}</p>
                    <p class="mt-0 pt-0">{{ slocale("Assess whether a data point is material, and if there's an option to skip it, decide whether to omit it.") }}</p>
                  </div>
                  <div class=" p-sm-0 m-sm-0 vh-auto d-flex flex-column justify-content-center align-items-center">
                    <div class="bg-comp-grey  p-3  w-100">
                      <h6 class="mb-0 pb-0 bold">{{ slocale("E3-4 – Water consumption") }}</h6>
                      <p>
                        <span class="bold">{{ slocale("Description:") }}</span>{{ slocale("The undertaking shall disclose information on its water consumption performance related to its material impacts, risks and opportunities. ") }}
                      </p>
                      <p>
                        <span class="bold">{{ slocale("Objective:") }}</span>{{ slocale("The objective of this Disclosure Requirement is to provide an understanding of the undertaking’s water consumption and any progress by the
                        undertaking in relation to its targets.") }}
                      </p>
                      <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small>
                    </div>
                    <div class="my-2 border  p-3  w-100">
                      <p>
                        <span class="text-blue bold">{{ slocale("General phase in information: ") }}</span>{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation.") }}
                      </p>
                      <div class="d-flex align-items-center flex-wrap">
                        <label class="switch mt-2 me-2">
                          <input type="checkbox" (change)="openModal(omitDataModal, 'modal-md')" />
                          <span class="slider round"></span>
                        </label>
                        <span class="pt-2">{{ slocale("Omit data point in reporting") }} </span><span class="ms-2 text-pink pt-2">{{ slocale("(Customized)") }}</span>
                      </div>
                    </div>
                    <div class="d-flex flex-row flex-wrap py-2 w-100">
                      <p class="d-inline mb-0 pb-0 me-2 bold">{{ slocale("Legend:") }}</p>
                      <span class="text-blue me-3"><i class="la la-clock me-1"></i>{{ slocale("Phase-in or effective date") }}</span>
                      <span class="me-3"><i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>{{ slocale("May disclose") }}
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>
                            {{
                            slocale(
                            "Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected."
                            )
                            }}
                          </p>
                        </div>
                      </span>
                      <!-- <span class="me-3"><i class="la la-eye me-1 text-orange"></i>{{ slocale("Show data collection questions") }}</span> -->
                      <!-- <span class="me-3"><i class="la la-eye me-1"></i>{{ slocale("Questions are edited") }}</span> -->
                      <span class="me-3"><i class="la la-info-circle me-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>{{ slocale("Show more information") }}
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale("Text to be added!") }}</p>
                        </div>
                      </span>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100">
                      <div class="col-4 thin-border-r">
                        <div class="bg-light-gray py-2 d-flex flex-row justify-content-center align-content-center w-100 border h-100">
                          <h5 class="mb-0 pb-0">{{ slocale("ESRS data point") }}</h5>
                        </div>
                      </div>
                      <div class="col-3 thin-border-r">
                        <div class="bg-light-gray py-2 d-flex flex-row justify-content-center align-content-center w-100 border h-100">
                          <h5 class="mb-0 pb-0">{{ slocale("Settings") }}</h5>
                        </div>
                      </div>
                      <div class="col-3 thin-border-r">
                        <div class="bg-light-gray py-2 d-flex flex-row justify-content-center align-content-center w-100 border h-100">
                          <h5 class="mb-0 pb-0">{{ slocale("Data collection") }}</h5>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="bg-light-gray py-2 d-flex flex-row justify-content-center align-content-center w-100 border h-100">
                          <h5 class="mb-0 pb-0">{{ slocale("Final answer") }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-4 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-column w-100">
                          <p class="m-0 p-0">{{ slocale("Total water consumption") }}</p>
                          <p class="m-0 p-0">{{ slocale("Description from legislation") }}</p>
                          <small>{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.") }}</small>
                          <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto" (click)="openModal(waterManagementModal, 'modal-lg')"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow text-pink" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r">
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none">
                          <button class="btn green-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</button>
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(superuserModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectSuperuserModal, 'modal-lg')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign reporters") }}</button>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <small class="pt-2">{{ slocale("Require proof document") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </small>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px" (click)="openModal(addDescModal, 'modal-md')"></i>
                          <small class="pt-2">{{ slocale("Add additional description") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover p-2 w-100">
                              <div class="d-flex flex-row justify-content-between">
                                <div>
                                  <small class="text-wrap">{{
                                    slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                    steps.")
                                    }}</small>
                                </div>
                                <div class="d-flex flex-column justify-content-between">
                                  <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px"></i>
                                  <i class="la la-trash me-2 fs-5 danger" style="position: relative; top: 2px" (click)="openModal(removeDescModal, 'modal-md')"></i>
                                </div>
                              </div>
                            </div>
                          </small>
                        </div>
                      </div>
                      <div class="col-2 bg-comp-grey p-2 scroll-x">
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center d-none d-xl-block d-lg-none mx-auto">
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectListModal, 'modal-xl')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign superusers") }}</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-4 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-row justify-content-start align-items-start w-100">
                          <p class="m-0 p-0"><i class="la la-clock me-1 text-blue"></i>{{ slocale("Total water consumption") }}</p>
                        </div>
                        <div class="p-2 d-flex flex-column w-100">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                          <p class="m-0 p-0">{{ slocale("This data point can potentially be omitted for the reporting. Read the available information carefully before making a decision.") }}</p>
                          <div class="d-flex align-items-center flex-wrap">
                            <label class="switch mt-2 me-2">
                              <input type="checkbox" (change)="openModal(omitDataModal, 'modal-md')" />
                              <span class="slider round"></span>
                            </label>
                            <span class="pt-2">{{ slocale("Omit data point in reporting") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  {{ slocale("Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected.") }}
                                </p>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r text-center">
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none">
                          <button class="btn green-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</button>
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(superuserModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectSuperuserModal, 'modal-lg')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign reporters") }}</button>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <small class="pt-2">{{ slocale("Require proof document") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </small>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px" (click)="openModal(addDescModal, 'modal-md')"></i>
                          <small class="pt-2">{{ slocale("Add additional description") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover p-2 w-100">
                              <div class="d-flex flex-row justify-content-between">
                                <div>
                                  <small class="text-wrap">{{
                                    slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                    steps.")
                                    }}</small>
                                </div>
                                <div class="d-flex flex-column justify-content-between">
                                  <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px"></i>
                                  <i class="la la-trash me-2 fs-5 danger" style="position: relative; top: 2px" (click)="openModal(removeDescModal, 'modal-md')"></i>
                                </div>
                              </div>
                            </div>
                          </small>
                        </div>
                      </div>
                      <div class="col-2 bg-comp-grey p-2 scroll-x">
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none mx-auto">
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectListModal, 'modal-xl')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign superusers") }}</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-4 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-column w-100">
                          <p class="m-0 p-0">
                            <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <span>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                </p>
                              </div>
                            </span>{{ slocale("Additional water intensity ratio") }}
                          </p>
                          <div class="p-2 d-flex flex-column w-100">
                            <p class="m-0 p-0">{{ slocale("Total water consumption") }}</p>
                            <p class="m-0 p-0">{{ slocale("Description from legislation") }}</p>
                            <small>{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.") }}</small>
                            <small>{{ slocale("See guidance") }} <i class="la la-arrows-alt"></i></small>
                          </div>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 d-flex flex-column thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" (change)="openModal(omitDataModal, 'modal-md')" />
                            <span class="slider round"></span>
                          </label>
                          <span class="pt-2">{{ slocale("Omit data point in reporting") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>
                                {{ slocale("Data points formulated using “may disclose” in the legislation indicate a voluntary disclosure to encourage good practice. Selecting the “omit” option means the data for this ESRS point will not be collected.") }}
                              </p>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r text-center">
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none">
                          <button class="btn green-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</button>
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(superuserModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectSuperuserModal, 'modal-lg')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign reporters") }}</button>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <small class="pt-2">{{ slocale("Require proof document") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </small>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px" (click)="openModal(addDescModal, 'modal-md')"></i>
                          <small class="pt-2">{{ slocale("Add additional description") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover p-2 w-100">
                              <div class="d-flex flex-row justify-content-between">
                                <div>
                                  <small class="text-wrap">{{
                                    slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                    steps.")
                                    }}</small>
                                </div>
                                <div class="d-flex flex-column justify-content-between">
                                  <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px"></i>
                                  <i class="la la-trash me-2 fs-5 danger" style="position: relative; top: 2px" (click)="openModal(removeDescModal, 'modal-md')"></i>
                                </div>
                              </div>
                            </div>
                          </small>
                        </div>
                      </div>
                      <div class="col-2 bg-comp-grey p-2 scroll-x">
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none mx-auto">
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectListModal, 'modal-xl')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign superusers") }}</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row pink-border">
                      <div class="col-4 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-row justify-content-start align-items-start w-100">
                          <p class="m-0 p-0">
                            <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <span>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p>
                                  <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                </p>
                              </div>
                            </span>{{ slocale("Total water withdrawals") }}
                          </p>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <span class="pt-2">{{ slocale("Collect data voluntarily") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r text-center">
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none">
                          <button class="btn green-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</button>
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(superuserModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectSuperuserModal, 'modal-lg')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign reporters") }}</button>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <small class="pt-2">{{ slocale("Require proof document") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </small>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px" (click)="openModal(addDescModal, 'modal-md')"></i>
                          <small class="pt-2">{{ slocale("Add additional description") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover p-2 w-100">
                              <div class="d-flex flex-row justify-content-between">
                                <div>
                                  <small class="text-wrap">{{
                                    slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                    steps.")
                                    }}</small>
                                </div>
                                <div class="d-flex flex-column justify-content-between">
                                  <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px"></i>
                                  <i class="la la-trash me-2 fs-5 danger" style="position: relative; top: 2px" (click)="openModal(removeDescModal, 'modal-md')"></i>
                                </div>
                              </div>
                            </div>
                          </small>
                        </div>
                      </div>
                      <div class="col-2 bg-comp-grey p-2 scroll-x">
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none mx-auto">
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectListModal, 'modal-xl')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign superusers") }}</button>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row m-0 p-0 w-100 border esrs-row">
                      <div class="col-4 thin-border-r d-flex flex-row justify-content-between p-2">
                        <div class="p-2 d-flex flex-row justify-content-start align-items-start w-100">
                          <p class="m-0 p-0 disabled">
                            <i class="la la-question me-1 text-orange info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <span>
                              <div class="dropdown-menu arrow p-2 info-popover">
                                <p class="">
                                  <span class="text-orange bold">{{ slocale("May disclose information:") }}</span>
                                </p>
                              </div>
                            </span>{{ slocale("Total water withdrawals") }}
                          </p>
                        </div>
                        <div class="p-2 d-flex flex-column w-auto">
                          <i class="la la-eye ms-auto"></i>
                          <i class="la la-info-circle ms-auto info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Text to be added!") }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r">
                        <div class="dropdown">
                          <a class="dropdown-toggle form-select info-popover-arrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="badge-material ms-2">{{ slocale("Material") }}</span>
                          </a>
                          <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <a class="dropdown-item">
                              <span class="badge-not-material ms-2">{{ slocale("Not material") }}</span></a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center flex-wrap">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <span class="pt-2">{{ slocale("Collect data voluntarily") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="col-3 bg-comp-grey p-2 thin-border-r text-center">
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none">
                          <button class="btn green-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</button>
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(superuserModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectSuperuserModal, 'modal-lg')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign reporters") }}</button>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <label class="switch mt-2 me-2">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                          <small class="pt-2">{{ slocale("Require proof document") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                steps.") }}</p>
                            </div>
                          </small>
                        </div>
                        <div class="flex-wrap w-100 text-center">
                          <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px" (click)="openModal(addDescModal, 'modal-md')"></i>
                          <small class="pt-2">{{ slocale("Add additional description") }}<i class="la la-info-circle mx-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover p-2 w-100">
                              <div class="d-flex flex-row justify-content-between">
                                <div>
                                  <small class="text-wrap">{{
                                    slocale("Data points labeled 'not material' are not collected by default, but you can opt to collect them voluntarily. If you do so, they will be marked as voluntarily collected data in subsequent
                                    steps.")
                                    }}</small>
                                </div>
                                <div class="d-flex flex-column justify-content-between">
                                  <i class="la la-edit me-2 fs-5" style="position: relative; top: 2px"></i>
                                  <i class="la la-trash me-2 fs-5 danger" style="position: relative; top: 2px" (click)="openModal(removeDescModal, 'modal-md')"></i>
                                </div>
                              </div>
                            </div>
                          </small>
                        </div>
                      </div>
                      <div class="col-2 bg-comp-grey p-2 scroll-x">
                        <div class="d-flex flex-row justify-content-end align-items-end">
                          <div class="dropdown ms-auto no-border d-lg-block d-md-block d-sm-block d-xl-none">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</a>
                              <a class="cursor d-block underline" (click)="openModal(waterManagementModal, 'modal-lg')"> <i class="la la-user-plus mx-1"></i>{{ slocale("Assign reporters") }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center m-0 p-0 d-none d-xl-block d-lg-none mx-auto">
                          <button class="btn secondary-button mx-auto m-1 button-lg d-block" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("Unasigned") }}</button>
                          <button class="btn tertiary-button mx-auto m-1 button-lg d-block" (click)="openModal(selectListModal, 'modal-xl')"><i class="la la-user-plus me-1"></i>{{ slocale("Assign superusers") }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Review the details of the Disclosure Requirement ends-->
              </div>
              <!--SELECT EMISSIONS PAGE-->
              <div *ngIf="activeFormNo == 3" style="overflow-x: hidden; height: 100% !important" class="px-3">
                <!--E3-4 – Water consumption water and marine resources start-->
                <div class=" p-sm-0 m-sm-0 p-2">
                  <div class="row">
                    <h4>{{ slocale("Request title and description") }}</h4>
                  </div>
                  <div class="d-flex flex-column">
                    <p class="pt-3 bold mb-0 pb-0">{{ slocale("Request title") }}</p>
                    <input type="text" class="form-control" placeholder="{{ slocale('Water consumption water and marine resources 2024') }}" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'w-50' : 'w-auto'" />
                    <p class="pt-3 bold mb-0 pb-0">{{ slocale("Description") }}</p>
                    <textarea class="form-control" rows="3" placeholder="{{ slocale('Enter description') }}" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'w-50' : 'w-auto'"></textarea>
                  </div>
                  <hr />
                  <p class="pt-3 bold mb-0 pb-0">{{ slocale("Deadline") }}</p>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                      <small class="text-muted">{{ slocale("Send out date") }}</small>
                      <input type="date" class="form-control" placeholder="{{ slocale('Enter deadline') }}" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'w-50' : 'w-auto'" />
                    </div>
                    <div class="m-2"></div>
                    <div class="d-flex flex-column">
                      <small class="text-muted">{{ slocale("Submission deadline") }}</small>
                      <input type="date" class="form-control" placeholder="{{ slocale('Enter deadline') }}" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'w-50' : 'w-auto'" />
                    </div>
                  </div>
                  <hr />
                  <p class="pt-1 bold mb-0 pb-0">{{ slocale("Reminder") }}</p>
                  <div class="d-flex flex-row">
                    <div class="flex-wrap w-100 text-start">
                      <label class="switch mt-2 me-2">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                      <span class="pt-2">{{ slocale("Send scheduled reminders") }}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <p class="pt-1 bold mb-0 pb-0">{{ slocale("Data approval") }}</p>
                  <div class="d-flex flex-row ">
                    <label class="">
                      <input type="radio" class="me-2" />
                      <span class="pt-2">{{ slocale("Received data is automatically approved by the system") }}
                      </span>
                    </label>
                  </div>
                  <div class="d-flex flex-row ">
                    <label class="">
                      <input type="radio" class="me-2" />
                      <span class="pt-2">{{ slocale("Received data has to be approved by me") }}
                      </span>
                    </label>
                  </div>
                </div>
                <!--E3-4 – Water consumption water and marine resources ends-->
              </div>
            </div>

            <div id="card-footer" class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0" style="max-width: 100vw; background-color: transparent" *ngIf="cardFooter">
              <div *ngIf="loadingInProgress" class="ball-pulse loader-primary">
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
                <div style="background-color: var(--main-dark-color1)"></div>
              </div>

              <ng-container *ngIf="!loadingInProgress">

                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" (click)="complete()">{{ slocale("Complete") }}</button>

                <!-- TO DO : Enable in phase 2 -->
                <!-- <button type="button" class="btn m-1 tertiary-button custom-button" *ngIf="activeFormNo > 1" (click)="prev()">{{ locale("locale_key.general.buttons.previous") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" *ngIf="activeFormNo < 3" (click)="next()">{{ locale("locale_key.general.buttons.next") }}</button>
                <button type="button" class="btn mx-1 primary-button custom-button" *ngIf="activeFormNo == 3">{{ locale("locale_key.pages.data_request.create_wizard.button.send_request") }}</button> -->
              </ng-container>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Show Calculations Modal -->
<ng-template #showCalcModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0 bold" id="myModalLabel14">
      {{ slocale("GHG emissions ") }} <span class="text-muted" style="font-weight: 300">{{ slocale("in the unit") }}</span> {{ slocale("Kg CO2e") }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-row justify-content-between align-items-center blue-border w-100 p-2 bg-selected">
      <span class="mb-0 pb-0">{{ slocale("Activity data * Emission factor waste") }}</span>
      <i class="la la-edit fs-5 me-2 bold" (click)="openModal(editCalcModal, 'modal-lg')"></i>
    </div>
  </div>
</ng-template>

<!-- Edit calculation modal-->
<ng-template #editCalcModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Edit calculation formula") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 bold">{{ slocale("Edit calculation formula for this entry") }}</p>
    </div>
    <div class="row px-2">
      <p class="">
        <span class="bold">{{ slocale("GHG emissions ") }}</span>{{ slocale("will be calculated as ") }}
      </p>
    </div>
    <div class="row px-1">
      <div class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="d-flex flex-row justify-content-start align-items-center w-100 flex-grow-1">
          <span class="px-2 py-1 dotted-square mx-2"><i class="la la-plus-circle fs-5 m-auto bold" (click)="editCalculation('id')" style="position: relative; top: 2px"></i></span>
          <span class="teal-border-round p-2 w-auto">
            <span class="me-auto">{{ slocale("Electricity energy ") }}<span class="ms-auto text-mutted">{{ slocale("Kwh") }}</span>
            </span>
          </span>
          <span class="teal-sqaure bold fs-5 mx-2">+</span>
          <span class="px-2 py-1 dotted-square"><i class="la la-plus-circle fs-5 m-auto bold" (click)="editCalculation('id')" style="position: relative; top: 2px"></i></span>
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center p-2" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'text-nowrap' : 'text-wrap'">
          <i class="la la-close fs-5 ms-auto my-auto bold mx-1" (click)="editCalculation('id')"></i>
          <span *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">{{ slocale("Clear calculation") }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save_changes") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>


<!-- Remove from Project Modal -->
<ng-template #removeProjectModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove from project") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Are you sure you want to remove this entry? The data will be removed from this carbon lab project and this action cannot be undone. Removing this entry will not affect the data categories in the platform.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale("Return to page") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Data sucessfully added to project Modal -->
<ng-template #dataSuccessfullyAddedModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Data successfully added to project") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You have successfully added data into the CarbonLab project. The emissions will now be calculated based on your selected calculation method.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("OK") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Missing emissions factors Modal -->
<ng-template #missingEmissionsModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Missing emission factors") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You have not filled in all necessary emission factors to calculated the emissions. Do you want to fill them in now or at a later time?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale("Fill in now") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Fill in later") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Update Data Categories modal-->
<ng-template #updateCategoriesModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Update data categories") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class=" p-sm-0 m-sm-0">
      <div class="row">
        <p class="py-3 bold">{{ slocale("To be able to calculate the GHG emissions new necessary data points need to be added to this data category. The new data points will be added to the data categories once you save the changes.") }}
        </p>
      </div>
      <div class="accordion w-100 px-2 mt-3" id="accordionExample1">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-comp-grey" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo">
              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <i class="la la-table fs-5 me-2"></i>
                <h6 class="mb-0 pb-0">{{ slocale("Data points to be added") }}</h6>
              </div>
            </a>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body p-0">
              <div class="d-flex flex-row justify-content-start align-items-center  p-3  flex-wrap">
                <span class="badge badge-green bg-green text-wrap w-auto m-1">Environmental > Waste generate > Waste generated in operations</span>
                <span class="badge badge-pink bg-light-pink text-wrap m-1 w-auto">Emission factor - scope 3</span> <span class="badge badge-pink bg-light-pink w-auto text-wrap m-1"> GHG emissions</span><span><i
                    class="la la-edit fs-5 ms-1 mt-1"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button">{{ slocale("Save changes") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Export Table modal-->
<ng-template #exportTableModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Export table") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class=" p-sm-0 m-sm-0">
      <div class="row">
        <p class="pt-3 pb-1">{{ slocale("Select in what format to export the summary table") }}</p>
      </div>
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1  p-3  flex-wrap">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i class="la la-file-pdf my-auto mx-2" style="font-size: 3.5rem; color: red !important"></i>
          <label class="card-title mx-auto">
            <input type="checkbox" class="form-check-input me-1" id="exampleCheck1" style="position: relative; top: 2px" />
            {{ slocale("Export to PDF") }}
          </label>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i class="la la-file-excel my-auto mx-2 bg-gradient" style="font-size: 3.5rem; color: var(--main-gradient-start-color) !important"></i>
          <label class="card-title mx-auto">
            <input type="checkbox" class="form-check-input me-1" id="exampleCheck1" style="position: relative; top: 2px" />
            {{ slocale("Export to in CSV") }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button">{{ locale("locale_key.general.toolbar.button.export") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Emission Categories Modal -->
<ng-template #emissionCategoriesModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Edit project setup") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <div class="d-flex flex-row flex-wrap">
        <h5>{{ slocale("Project Name*") }}</h5>
        <i class="la la-info-circle info-popover-arrow ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: 3px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("Choose a name for your carbon project to specify eg. the time period or organization sections included in the calculations.") }}</p>
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center mt-2">
        <span class="w-75"> {{ slocale("Choose the emission categories to include in your project scope. If you require additional categories beyond those provided, you can add custom ones by editing the custom categories.") }}</span>
        <span>
          <i class="la la-edit fs-5 ms-1" style="position: relative; top: 2px" (click)="openModal(customEmissionModal, 'modal-md')"> </i>
          Edit custom categories
        </span>
      </div>
      <p>You can add custom section or custom emission category if the requirement is out of scope.</p>
      !!!Dropdown content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Custom Emission Categories Modal -->
<ng-template #customEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Add custom emission category to Scope 1 (Direct)") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <label class="form-label">{{ slocale("Category name*") }}</label>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <label class="form-label">{{ slocale("Description") }}</label>
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="openModal(removeEmissionModal, 'modal-lg')">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove Emission Categories Modal -->
<ng-template #removeEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Remove Emission Category") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <p>
        {{
        slocale(
        "Are you sure you want to remove this emission category? The data will be removed from this CarbonLab project and this action cannot be undone. This action will not affect the Data Categories in the platform and your data will still
        be available in the platform."
        )
        }}
      </p>
      !!!Content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="openModal(removeEmissionsConfModal, 'modal-sm')">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove emissions Confirmation Modal -->
<ng-template #removeEmissionsConfModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove emission category") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("The category is currently empty and it will not affect your project.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Logout due to inactivity Modal -->
<ng-template #logoutModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Logout due to inactivity") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You are about to be logged out due to being inactive for a long time. Your work will be automatically saved and you can continue from where you left of after logging in again.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ slocale("OK") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Create sub-topic modal-->
<ng-template #createSubTopicModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Create a sub-topic") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Sub-topic name") }}</p>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Enter sub-topic name') }}" />
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Sub-topic description") }}</p>
      <textarea class="form-control" rows="3" placeholder="{{ slocale('Enter description') }}"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Confirm Not Material Modal -->
<ng-template #confirmNotMaterialModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Confirm 'Not Material' status for ESRS E3 (no data collected yet)") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Data collection for 'ESRS E3 Water and Marine resources' has already started, but no data has been submitted yet. If you change the materiality to 'not material,' all ongoing data requests and superuser tasks will be closed,
      preventing further data submission.Would you like to proceed?"
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button custom-button text-white" (click)="closeModal()">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Water management modal-->
<ng-template #waterManagementModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Preview: Disclosure of whether and how policy addresses water management ") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <div class="d-flex flex-lg-row flex-xl-row flex-column-reverse justify-content-between">
      <div class="d-flex flex-column w-75">
        <p class="pt-3 mb-0 pb-0">
          {{ slocale("Do your organisation's policies address water management including the use and sourcing of water and marine resources?") }}<span class="text-orange bold ms-1">{{ slocale("(Edited)") }}</span>
        </p>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item w-auto" style="border: none !important">
            <h2 class="accordion-header w-auto">
              <a class="accordion-button w-auto bg-white small-accordion ms-0 ps-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {{ slocale("Description") }}
              </a>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body w-100">
                <p>
                  <input type="radio" name="waterManagement" value="yes" /> <span class="ms-2">{{ slocale("Yes") }}</span>
                </p>
                <p>
                  <input type="radio" name="waterManagement" value="no" /> <span class="ms-2">{{ slocale("No") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="pt-3 mb-0 pb-0">
          {{
          slocale(
          "Please describe how these policies address water management including: i) the use and sourcing of water and marine resources in your organisation's own operations; ii) water treatment as a step towards more sustainable sourcing
          of water; iii) the prevention and abatement of water pollution resulting from your organisation's activities."
          )
          }}
        </p>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" style="border: none !important">
            <h2 class="accordion-header">
              <a class="accordion-button collapsed small-accordion bg-white ms-0 ps-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                {{ slocale("Description") }}
              </a>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <textarea class="form-control bg-comp-grey" rows="3" placeholder="{{ slocale('Enter description') }}"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <button class="btn tertiary-button mb-auto w-auto me-auto" (click)="openModal(editQuestionnaireModal, 'modal-md')"><i class="la la-edit fs-5 me-2 bold"></i>{{ slocale("Edit questions") }}</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button" (click)="openModal(editQuestionnaireModal, 'modal-md')">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Edit the questionnaire Modal -->
<ng-template #editQuestionnaireModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Editing the questionnaire") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Editing the questionnaire requires a deep understanding of its structure. Incorrect modifications may result in data inaccuracies and survey errors.If you are unsure, please contact your customer success representative.")
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button custom-button text-white" (click)="closeModal()">{{ slocale("Edit") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Superuser Modal -->
<ng-template #superuserModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h6 class="mb-0">
      {{ slocale("Superuser") }}
    </h6>
    <div class="px-2">
      <div class="row bg-light-gray">
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Name/ (Number of units)") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Company") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Silvia (1) (You)") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Silvia (1) (You)") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
    </div>
    <h6 class="mt-3 mb-0">
      {{ slocale("Data reporter list") }}
    </h6>
    <div class="px-2">
      <div class="row bg-light-gray">
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Name/ (Number of units)") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Company") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Silvia (1) (You)") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">
            {{ slocale("Silvia (1)") }} <span class="badge badge-turquoise mx-2">{{ slocale("Invited") }}</span>
          </p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <!-- <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="closeModal()">{{ slocale("Edit") }}</button> -->
    </ng-container>
  </div>
</ng-template>

<!-- Select superuser modal-->
<ng-template #selectSuperuserModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Select superuser to create final answer") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <h6>
      {{ slocale("Select a superuser to create the final answer of this ESRS data point for reporting.") }}
    </h6>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mb-2">
      <h6 class="mt-auto pb-0">
        {{ slocale("Superuser") }}
      </h6>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Search') }}" />
    </div>
    <div class="px-2 mb-3">
      <div class="row btn-add">
        <div class="col-4 py-2">
          <p class="my-auto text-white">{{ slocale("Name") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto text-white">{{ slocale("Email") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto text-white">{{ slocale("Company") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-4 py-2">
          <input type="radio" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row thin-border-l thin-border-b thin-border-r">
        <div class="col-4 py-2">
          <input type="radio" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-4 py-2">
          <input type="radio" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Confirm") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Remove additional description Modal -->
<ng-template #removeDescModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove additional description") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Are you sure you want to remove the additional description?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-danger custom-button text-white" (click)="closeModal()">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Add additional description modal-->
<ng-template #addDescModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Add additional description") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Provide additional information about this ESRS data point for the reporters.") }}</p>
      <textarea class="form-control" rows="5" placeholder="{{ slocale('Enter description') }}"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Select from the list modal-->
<ng-template #selectListModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Select from the list to assign") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <h6>
      {{ slocale("Select which people should provide data on this ESRS data point. Specify the organizational unit(s) they will report for.") }}
    </h6>
    <div class="d-flex flex-row m-0 p-0">
      <button class="btn green-button me-auto my-2 button-lg" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assignments") }}</button>
    </div>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mb-2">
      <h6 class="mt-auto pb-0 bold">
        {{ slocale("Superusers") }}
      </h6>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Search') }}" />
    </div>
    <div class="px-2 mb-3">
      <div class="row btn-add">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /><span class="my-auto text-white ms-2">{{ slocale("Name") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Email") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Company") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Number of organizational units") }}</p>
        </div>
        <div class="col-3 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
    </div>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mt-4">
      <h6 class="mb-0 pb-0 bold">
        {{ slocale("Data reporters") }}
      </h6>
    </div>
    <div class="px-2 mb-3">
      <div class="row btn-add">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /><span class="my-auto text-white ms-2">{{ slocale("Name") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Email") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Company") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Number of organizational units") }}</p>
        </div>
        <div class="col-3 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
          </div>
          <i class="la la-edit fs-5 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
    </div>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mb-2">
      <button class="btn tertiary-button me-auto button-lg" (click)="openModal(waterManagementModal, 'modal-lg')"><i class="la la-user-plus"></i> {{ slocale("Add data reporter") }}</button>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Confirm") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Edit name of org unit modal-->
<ng-template #editUnitName let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Edit name of organizational unit") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 mb-0 pb-0">{{ slocale("Edit the name of the organizational unit that Silvia should report data for.") }}</p>
      <input class="form-control" type="text" placeholder="{{ slocale('Unit A') }}" />
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Remove additional description Modal -->
<ng-template #removeDescModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove additional description") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Are you sure you want to remove the additional description?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-danger custom-button text-white" (click)="closeModal()">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Omit data point in reporting Modal -->
<ng-template #omitDataModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Omit data point in reporting") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>

  <div class="modal-body">
    <!-- <p class="ps-2">
      {{
      slocale(
      "You have switched on the General phase in information toggle for all phase in data points. Turning off for this data point will keep the state of other currently omitted data points and show “Customized” text next to the General
      phase in information toggle."
      )
      }}
    </p> -->
    <p class="ps-2">
      {{ slocale("Are you sure you want to turn this toggle on?") }}
    </p>
  </div>

  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="cancelOmitDatapoint()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white" (click)="confirmOmitDatapoint()">{{ slocale("Omit data point") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Edit ESRS data point Modal -->
<ng-template #editESRSModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Edit ESRS data point materiality and assignment") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Reporters have already started filling in data, preventing changes to the materiality of ESRS data points to avoid data loss. For any assessment changes, reach out to your customer success representative.Assigning of ESRS data points
      for reporting is limited to adding new reporters. If you wish to cancel the data submission of a reporter you can close it from the data request page."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white" (click)="closeModal()">{{ slocale("Add reporters") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Show data point Modal -->
<ng-template #showDataPointModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0 d-inline" id="myModalLabel14">{{ slocale("These data points appear in multiple ESRS data points.") }} <span class="ms-1">
        <i class="la la-info-circle info-popover-arrow fs-5" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: 1px"></i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("The data from this data point is utilized in multiple ESRS data points. As a result, the storage settings for these data points must be consistent, and the data should be stored in the same data category.") }}</p>
        </div>
      </span></h5>

    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="green-border-l m-0 p-0">
      <div class="d-flex flex-column border green-border-l  p-3  mb-2">
        <div class="row mb-2">
          <div class="col-4 fw-bold">{{ slocale("Description") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Short Name") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Data Type") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-4">{{ slocale("Organizational unit this policy applies to") }}</div>
          <div class="col-4">{{ slocale("Organizational unit") }}</div>
          <div class="col-4"><span class="bold">A</span>{{ slocale("Text - Calculated") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses water management") }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses the use and sourcing of water and marine resources in own operations") }}
          </div>
        </div>
      </div>
    </div>
    <div class="green-border-l m-0 p-0">
      <div class="d-flex flex-column border green-border-l  p-3 ">
        <div class="row mb-2">
          <div class="col-4 fw-bold">{{ slocale("Description") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Short Name") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Data Type") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-4">{{ slocale("Whether or not the company has a policy for water management in place") }}</div>
          <div class="col-4">{{ slocale("Existing water management policy") }}</div>
          <div class="col-4"><i class="las la-adjust"></i>{{ slocale("True / False") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses water management") }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses the use and sourcing of water and marine resources in own operations") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white" (click)="closeModal()">{{ slocale("Add reporters") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Edit data storage for ESRS data point Modal -->
<ng-template #editDataStorageModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Edit data storage for ESRS data point") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "This ESRS data point shares data with at least one other data point. If you change the storage location, you must also update the other ESRS data point(s) to match, to ensure data integrity."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white custom-button" (click)="closeModal()">{{ slocale("Edit") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Match shared data point Modal -->
<ng-template #matchDataPointModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Match shared data point") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "If you change the storage location of this shared data point, remember to update the location for the other linked ESRS data point(s) to maintain data integrity."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white custom-button" (click)="closeModal()">{{ slocale("Continue") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Testing mapping modal-->
<ng-template #testingMappingModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Testing mapping [Blank]") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body  p-3 " style="border-top: none">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            {{slocale('Section 1')}} <span class="badge badge-pink my-auto bg-light-pink mx-1 mb-1 ">{{ slocale("Existing water management policy") }}</span>
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
          <div class="accordion-body scroll-y">
            <div class="d-flex flex-row justify-content-between align-items-start">
              <div class="d-flex flex-column">
                <p class="my-auto">{{ slocale("1. Existing water management policy?") }}</p>
                <label class="">
                  <input type="radio" class="me-2" />
                  <span class="pt-2">{{ slocale("Yes") }}
                  </span>
                </label>
                <label class="">
                  <input type="radio" class="me-2" />
                  <span class="pt-2">{{ slocale("No") }}
                  </span>
                </label>
              </div>
              <div class="d-flex flex-row text-end">
                <span class="my-auto">{{ slocale("not added to data in data categories") }} <i class="la la-edit"></i></span>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-start">
              <div class="d-flex flex-column">
                <p class="my-auto">{{ slocale("1. Existing water management policy?") }}</p>
                <label class="">
                  <input type="radio" class="me-2" />
                  <span class="pt-2">{{ slocale("Yes") }}
                  </span>
                </label>
                <label class="">
                  <input type="radio" class="me-2" />
                  <span class="pt-2">{{ slocale("No") }}
                  </span>
                </label>
              </div>
              <div class="d-flex flex-row text-end">
                <span class="my-auto">{{ slocale("not added to data in data categories") }} <i class="la la-edit"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            {{slocale('Section 2')}} <span class="badge badge-pink my-auto bg-light-pink mx-1 mb-1 ">{{ slocale("Existing water management policy") }}</span>
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well
            as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>,
            though the transition does limit overflow.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            {{slocale('Section 3')}} <span class="badge badge-pink my-auto bg-light-pink mx-1 mb-1 ">{{ slocale("Existing water management policy") }}</span>
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well
            as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>,
            though the transition does limit overflow.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Save mapping") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>


<!-- Apply data storage settings modal-->
<ng-template #updateDataStorageModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Update data categories") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class=" p-sm-0 m-sm-0">
      <div class="row">
        <p class="py-3">{{ slocale("You are about to apply the data storage settings for the Disclosure Requirement ") }}<span class="bold">{{slocale('“E3-1 – Policies related to water and marine resources”')}}</span></p>
        <p>{{ slocale("Based on the defined settings these data categories and data points will be added to the platform once you confirm:") }}</p>
      </div>
      <div class="accordion w-100 px-2 mt-3" id="accordionExample1">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-comp-grey" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo">
              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <i class="la la-table fs-5 me-2"></i>
                <h6 class="mb-0 pb-0">{{ slocale("Data categories to be added") }}</h6>
              </div>
            </a>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body p-0">
              <div class="d-flex flex-row justify-content-start align-items-center  p-3  flex-wrap">
                <span class="badge badge-green bg-green text-wrap w-auto m-1">Environmental > Waste generate > Waste generated in operations</span>
                <span class="badge badge-pink bg-light-pink text-wrap m-1 w-auto">Emission factor - scope 3</span> <span class="badge badge-pink bg-light-pink w-auto text-wrap m-1"> GHG emissions</span><span><i
                    class="la la-edit fs-5 ms-1 mt-1"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-comp-grey" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo">
              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <i class="la la-table fs-5 me-2"></i>
                <h6 class="mb-0 pb-0">{{ slocale("Data points to be added") }}</h6>
              </div>
            </a>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body p-0">
              <div class="d-flex flex-row justify-content-start align-items-center  p-3  flex-wrap">
                <span class="badge badge-blue my-auto  mx-1 mb-1 text-dark">Environmental > Waste generate > Waste generated in operations</span>
                <span class="badge badge-pink bg-light-pink text-wrap m-1 w-auto">Emission factor - scope 3</span> <span class="badge badge-pink bg-light-pink w-auto text-wrap m-1"> GHG emissions</span><span><i
                    class="la la-edit fs-5 ms-1 mt-1"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button">{{ slocale("Apply") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Skip reporting for ESRS data point Modal -->
<ng-template #skipReportingModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Skip reporting for ESRS data point") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "This action will remove the final answer you provided for the reported data and prompt you to explain why you're not using it. The individual answers will remain stored in the platform and project."
      )
      }}
    </p>
    <p class="ps-2">
      {{
      slocale(
      "Are you sure you want to skip reporting the data for "
      )
      }}
      <span class="bold">{{ slocale(" 'Policies to manage its material impacts, risks, and opportunities related to water and marine resources?'") }}</span>

    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white custom-button" (click)="closeModal()">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>



<!-- Remove final answer Modal -->
<ng-template #removeFinalAnswerModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove final answer") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "This action will remove the final answer you provided for the reported data. The individual answers will remain stored in the platform and project.Are you sure you want to remove the final answer for "
      )
      }}
      <span class="bold">{{ slocale(" 'Policies to manage its material impacts, risks, and opportunities related to water and marine resources?'") }}</span>

    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-danger text-white custom-button" (click)="closeModal()">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Report this data Modal -->
<ng-template #reportDataModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Report this data") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "This action will remove the explanation you provided for not reporting the data point and will prompt you to provide the final answer for the reported data. Are you sure you want to report this data?"
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-danger text-white custom-button" (click)="closeModal()">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Data collection is not complete yet Modal -->
<ng-template #dataCollectionCompleteModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Data collection is not complete yet") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Some answers are still pending for this ESRS data point. You can begin drafting the final answer now and make edits later."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn tertiary-button  text-white custom-button" (click)="closeModal()">{{ slocale("Continue") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- I won’t report this data Modal -->
<ng-template #wontReportDataModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("I won't report this data") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "This action will remove the final answer you provided for the reported data nd will prompt you to provide the explanation for not to use the reported data."
      )
      }}
    </p>
    <p class="ps-2">
      {{
      slocale(
      "Are you sure you do not want to report this data. "
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white custom-button" (click)="closeModal()">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Revert to calculated result Modal -->
<ng-template #revertModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Revert to calculated result") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Are you sure you want to revert? This action will remove the data you directly input and return to the calculated result."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white custom-button" (click)="closeModal()">{{ slocale("Yes") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Write final answer modal-->
<ng-template #writeAnswertModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Write final answer") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3" style="border-top: none">
    <div class="px-2 mb-3 d-flex flex-row justify-content-between align-items-start">
      <div class="m-0 p-0 w-75">
        <div class="row bg-gray text-dark">
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center py-3">
            <span class="my-auto ms-2 text-dark">{{ slocale("Reporter") }}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center py-3">
            <p class="my-auto ">{{ slocale("Company") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-lg-flex thin-border-r d-flex flex-row align-items-center py-3">
            <p class="my-auto ">{{ slocale("Organizational unit name") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 col-4 d-flex flex-row align-items-center py-3">
            <p class="my-auto ">{{ slocale("CapEx shadow price") }}</p>
          </div>
        </div>
        <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <span class="ms-2 my-auto">{{ slocale("Silvia Smith") }}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-lg-flex thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 col-4 d-flex flex-row align-items-center">
            <small class="my-auto">{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ") }}</small>
          </div>
        </div>
        <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <span class="ms-2 my-auto">{{ slocale("Silvia Smith") }}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-lg-flex thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 col-4 d-flex flex-row align-items-center">
            <small class="my-auto">{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ") }}</small>
          </div>
        </div>
      </div>
      <div class="w-25 ps-3 h-100 d-flex">
        <textarea rows="10" class="form-control h-100 w-100 flex-fill" placeholder="Type your final answer here."></textarea>
      </div>
    </div>
    <div class="px-2 mb-3 d-flex flex-row justify-content-between align-items-start">
      <div class="m-0 p-0 w-75">
        <div class="row bg-gray text-dark">
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center py-3">
            <span class="my-auto ms-2 text-dark">{{ slocale("Reporter") }}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center py-3">
            <p class="my-auto ">{{ slocale("Company") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-lg-flex thin-border-r d-flex flex-row align-items-center py-3">
            <p class="my-auto ">{{ slocale("Organizational unit name") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 col-4 d-flex flex-row align-items-center py-3">
            <p class="my-auto ">{{ slocale("CapEx shadow price") }}</p>
          </div>
        </div>
        <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <span class="ms-2 my-auto">{{ slocale("Silvia Smith") }}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-lg-flex thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 col-4 d-flex flex-row align-items-center">
            <small class="my-auto">{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ") }}</small>
          </div>
        </div>
        <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <span class="ms-2 my-auto">{{ slocale("Silvia Smith") }}</span>
          </div>
          <div class="col-lg-3 col-md-4 col-4 thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-lg-flex thin-border-r d-flex flex-row align-items-center">
            <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          </div>
          <div class="col-lg-3 col-md-4 col-4 d-flex flex-row align-items-center">
            <small class="my-auto">{{ slocale("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ") }}</small>
          </div>
        </div>
      </div>
      <div class="w-25 ps-3 h-100 d-flex">
        <textarea rows="10" class="form-control h-100 w-100 flex-fill" placeholder="Type your final answer here."></textarea>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Add") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- I won’t report this data modal-->
<ng-template #wontReportExplanationModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("I won’t report this data") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 mb-0 pb-0">{{ slocale("Explain why this data is not reported.") }}</p>
    </div>
    <div class="row mx-2">
      <textarea rows="7" placeholder="Type here" class="form-control"></textarea>
    </div>


  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save_changes") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>



<!--  Add final answer modal-->
<ng-template #addFinalAnswerModal let-modal>
  <div class="modal-header alert-modal-header" data-test="request-window-header">
    <h5 class="modal-title">{{ slocale("Add final answer") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 mb-0 pb-0">{{ slocale("This action will take you to the page for ") }}
        <span class="bold">{{slocale('"E3-1 Policies related to water and marine resources"')}}</span>
        {{ slocale(" where you can write the final answer.") }}
      </p>
      <span class="bold">{{slocale('"E3-1 Policies related to water and marine resources"')}}</span>
      <p class="pt-3 mb-0 pb-0">{{ slocale("You will have to manually come back to this page after editing. Would you like to proceed?") }}</p>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn secondary-button " (click)="closeModal()" data-test="request-cancel-button">{{ locale("Stay in summary") }}</button>
      <button class="btn primary-button " data-test="request-create-button">{{ slocale("Write answer") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<ng-template #exportSummaryModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="export-summary-header">
    <h5 class="modal-title text-white">{{ slocale("Export summary") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3" style="border-top: none">
    <div class="d-flex flex-row align-items-center justify-content-between my-3">
      <span class="bold">{{slocale('Select options to export.')}}</span>
      <div class="d-flex flex-row justify-content-between bg-comp-grey p-2 border">
        <div class="d-flex flex-column me-4">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exportOptions" id="exportFinalAnswers" checked>
            <label class="form-check-label" for="exportFinalAnswers">
              {{ slocale("Export final answers only") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exportOptions" id="exportFinalAndCollected">
            <label class="form-check-label" for="exportFinalAndCollected">
              {{ slocale("Export both final answers and individual collected data.") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exportOptions" id="exportByOrganizationalUnit">
            <label class="form-check-label" for="exportByOrganizationalUnit">
              {{ slocale("Export by organizational unit") }}
            </label>
          </div>

        </div>
        <div class="d-flex flex-column">
          <div class="form-check">
            <label class="form-check-label" for="exportFinalAnswers">
              {{ slocale("Select export file type.") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exportOptions" id="exportFinalAndCollected">
            <label class="form-check-label" for="exportFinalAndCollected">
              <i class="las la-file-pdf warning fs-5"></i>
              {{ slocale(".PDF") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exportOptions" id="exportByOrganizationalUnit">
            <label class="form-check-label" for="exportByOrganizationalUnit">
              <i class="las la-file-csv success fs-5"></i>
              {{ slocale(".CSV") }}
            </label>
          </div>

        </div>
      </div>
    </div>
    <div class="my-3">
      <div class="d-flex flex-row justify-content-between align-items-center w-100 flex-wrap">
        <div class="d-flex flex-row align-items-center w-auto">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="selectAll">
            <a class="underline" for="selectAll">{{ slocale("Select all") }}</a>
          </div>
          <div class="form-check">
            <a class="underline" for="deselectAll">{{ slocale("Deselect all") }}</a>
          </div>
          <span class="ms-3 d-none d-lg-block me-2">{{slocale('Organizational unit')}}</span>
          <div class="ms-3 d-none d-lg-block me-2">

            <div class="btn-group arrow" dropdown container="body">
              <button type="button" class="form-control w-auto dark-text mx-1 dropdown-toggle" id="group-button" dropdownToggle aria-controls="group-dropdown-menu" style="text-align: left">
                {{ selectedOption || slocale("Select option") }}
              </button>
              <div class="dropdown-menu p-2 scroll-y" id="group-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="group-button">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                  <label class="form-check-label" for="exportFinalAnswers">
                    {{ slocale("Organizational unit name 1") }}
                  </label>
                </div>
                <div class="dropdown-divider"></div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                  <label class="form-check-label" for="exportFinalAnswers">
                    {{ slocale("Organizational unit name 1") }}
                  </label>
                </div>
                <div class="dropdown-divider"></div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                  <label class="form-check-label" for="exportFinalAnswers">
                    {{ slocale("Organizational unit name 1") }}
                  </label>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center w-auto">
          <input type="text" class="form-control w-auto me-1" placeholder="{{ slocale('Search') }}">
          <a class="underline mx-1">{{ slocale("Expand All") }}</a>
          <a class="underline mx-1">{{ slocale("Collapse All") }}</a>
        </div>
      </div>
    </div>

    <div class="accordion mt-3 ps-3" id="accordionExample">
      <div class="d-flex flex-row align-items-center w-100">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAll">
        </div>
        <div class="accordion-item w-100 mb-2 no-radius">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{ slocale("Cross-cutting topics") }}
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="accordion w-100 px-2 mb-3" id="accordionExample1">
                <div class="d-flex flex-row align-items-center w-100">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="selectAll">
                  </div>
                  <div class="accordion-item w-100 no-radius">
                    <h2 class="accordion-header">
                      <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                          <h6 class="mb-0 pb-0">{{ slocale("ESRS E1 - Climate Change") }}</h6>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-center">
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                      </a>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                      <div class="accordion-body p-0 unreadActivity p-3">
                        <div class="d-flex flex-row p-2 justify-content-between align-items-center thin-border m-2 bg-comp-grey">
                          <div class="form-check w-100">
                            <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                            <label class="form-check-label" for="exportFinalAnswers">
                              {{ slocale("Policies to manage its material impacts, risks and opportunities related to water and marine resources.") }}
                            </label>
                          </div>
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                        <div class="d-flex flex-row p-2 justify-content-between align-items-center thin-border m-2 bg-comp-grey">
                          <div class="form-check w-100">
                            <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                            <label class="form-check-label" for="exportFinalAnswers">
                              {{ slocale("Policies to manage its material impacts, risks and opportunities related to water and marine resources.") }}
                            </label>
                          </div>
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                        <div class="d-flex flex-row p-2 justify-content-between align-items-center thin-border m-2 bg-comp-grey">
                          <div class="form-check w-100">
                            <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                            <label class="form-check-label" for="exportFinalAnswers">
                              {{ slocale(" Policies to manage its material impacts, risks and opportunities related to water and marine resources. ") }}
                            </label>
                          </div>
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                        <div class="d-flex flex-row p-2 justify-content-between align-items-center thin-border m-2 bg-comp-grey">
                          <div class="form-check w-100">
                            <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                            <label class="form-check-label" for="exportFinalAnswers">
                              {{ slocale(" Policies to manage its material impacts, risks and opportunities related to water and marine resources. ") }}
                            </label>
                          </div>
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                        <div class="d-flex flex-row p-2 justify-content-between align-items-center thin-border m-2 bg-comp-grey">
                          <div class="form-check w-100">
                            <input class="form-check-input" type="checkbox" name="exportOptions" id="exportFinalAnswers" checked>
                            <label class="form-check-label" for="exportFinalAnswers">
                              {{ slocale(" Policies to manage its material impacts, risks and opportunities related to water and marine resources. ") }}
                            </label>
                          </div>
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row align-items-center w-100">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="selectAll">
                  </div>
                  <div class="accordion-item w-100 no-radius">
                    <h2 class="accordion-header">
                      <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                        aria-controls="collapseThree">
                        <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                          <h6 class="mb-0 pb-0">{{ slocale("ESRS E1 - Climate Change") }}</h6>
                        </div>
                        <div class="d-flex flex-row justify-content-end align-items-center">
                          <span class="badge badge-green ms-auto me-2">{{slocale('Completed')}}</span>
                        </div>
                      </a>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                      <div class="accordion-body p-0 bg-comp-grey">
                        <div class="d-flex flex-column justify-content-center align-items-center py-3">
                          <p>Add data to start calculating GHG emissions for this emission category.</p>
                          <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 fs-5 bold"></i>Add data</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center w-100">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAll">
        </div>
        <div class="accordion-item w-100 mb-2 no-radius">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button bg-gray collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {{ slocale("Environmental topics") }}
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Content here -->
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center w-100">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAll">
        </div>
        <div class="accordion-item w-100 mb-2 no-radius">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button bg-gray collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{ slocale("Social topics") }}
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Content here -->
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center w-100">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="selectAll">
        </div>
        <div class="accordion-item w-100 mb-2 no-radius">
          <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button bg-gray collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              {{ slocale("Governance topics") }}
            </button>
          </h2>
          <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <!-- Content here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="export-cancel-button">{{ slocale("Cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="export-button">{{ slocale("Export") }}</button>
    </ng-container>
  </div>
</ng-template>

<!--  Summary not complete yet modal-->
<ng-template #summaryNotCompleteModal let-modal>
  <div class="modal-header alert-modal-header" data-test="request-window-header">
    <h5 class="modal-title">{{ slocale("Summary not complete yet") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 mb-0 pb-0">{{ slocale("Some ESRS data points are still missing final answers or individual collected data. You can export the available data, but please note that it is not yet complete.") }}
      </p>
      <span class="bold">{{slocale('"E3-1 Policies related to water and marine resources"')}}</span>
      <p class="pt-3 mb-0 pb-0">{{ slocale("You will have to manually come back to this page after editing. Would you like to proceed?") }}</p>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ slocale("Cancel") }}</button>
      <button class="btn primary-button " data-test="request-create-button">{{ slocale("Export anyway") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>