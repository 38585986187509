import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex';
import { RouterFe } from 'src/app/route/RouterFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
@Component({
    selector: 'forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent extends AbstractLanguageComponent implements OnInit {

    form: FormGroup;
    loading = false;
    submitted = false;
    sucessText = "Submit";
    Message: string;
    EMessage: string;
    recievedError: boolean;
    invalidRegistration: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private backendService: RouterFe,
        private ErrorsFe: ErrorsFe,
        languageService: LanguageService
    ) {
      super(languageService)
     }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)]]
        });
    }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    async onSubmit() {
        this.submitted = true;
        this.loading = false;
        this.sucessText = "Submitting....";
        this.Message = null;

        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        try {
          let resetInitialized = await this.backendService.initResetPassword(this.f.email.value)
          this.loading = false;
          this.invalidRegistration = false;
          this.Message = "Please check your email to reset password!"  
        } catch(err) {
          this.loading = false;
          this.recievedError = true
          let knowError = this.ErrorsFe.matchError(err)
          if (knowError == OpenErrorsFe.USER_NOT_FOUND_ERROR) {
            this.EMessage = OpenErrorsFe.USER_NOT_FOUND_ERROR.message                      
          } else {
            this.EMessage = "Server coudn't process your request, please contact our adminisrator"         
          }
        }
    }

}
