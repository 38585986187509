import { Component, OnInit, Input, Output, HostListener, ChangeDetectorRef } from "@angular/core"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DateUtil } from "src/app/utils/DateUtil"
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe"
import { Subject } from "rxjs"
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe"
import { RequestFe } from "src/app/model/data-suppliers/request/RequestFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { RequestServiceFe } from "src/app/services/RequestServiceFe"
import { SectionFe } from "src/app/model/data-suppliers/request/questionnaire/SectionFe"
import _ from "lodash"
import { RequestGroup_UpdateFe } from "src/app/model/data-suppliers/request/RequestGroup_UpdateFe"
import { QuestionnaireFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe"
import { QuestionDateFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDateFe"

@Component({
  selector: "questionnaire-preview",
  templateUrl: "./questionnaire-preview.component.html",
  styleUrls: ["./data-supplier-questionaire.component.scss", "../data-suppliers.component.scss"],
})
export class QuestionnairePreviewComponent extends AbstractLanguageComponent implements OnInit {
  loadingInfo = false
  loadingInProgress = false

  @Input() requestTitle: string
  @Input() request: RequestFe
  @Input() questionnaire: QuestionnaireFe
  @Output() closeEvent: Subject<boolean> = new Subject()
  @Input() showMapping: boolean = true
  @Input() showHeader = true
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  editMapping: boolean = false
  mappingEdited = false
  selectedQuestion: QuestionFe
  taxonomyInfo: TaxonomyInfoFe
  toConfirmUpdate = false
  sections: SectionFe[]
  questionStack: string[] = []
  selectedSection: SectionFe | null = null
  expanded: { [key: string]: boolean } = {}
  currentlyExpanded: string | null = null

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private responsive: ResponsiveService,
    public requestService: RequestServiceFe,
    languageService: LanguageService,
    private cd: ChangeDetectorRef
  ) {
    super(languageService)
    stateService.workspaceSubject.subscribe((activeCompany) => {
      this.loadCompaniesTaxonomy()
    })
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    ;(window as any).toggleFollowUpQuestions = (id: string, event: Event) => {
      const followUp = this.findQuestionById(id)
      if (followUp) {
        this.toggleFollowUpQuestions(followUp, event)
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.renderNewData()
    this.loadCompaniesTaxonomy()
  }

  async renderNewData() {
    this.loadingInfo = true
    let questionnaire = this.questionnaire ? this.questionnaire : this.request.questionnaire
    this.sections = _.cloneDeep(questionnaire.sections)
    this.sections.forEach((section) => {
      section.questions.forEach((q) => {
        if (q instanceof QuestionDataTableFe) {
          q.setRequestDataGridService()
        }
        if (q.mappedToColKeys && section.entity) {
          q.setMappedToCols(section.entity)
        }
        if (q instanceof QuestionDateFe && q.mappedToColKeysRange) {
          q.setMappedToColsRange(section.entity)
        }
        q.confirmMapping()
      })
      section.editTaxonomy = false
    })
    this.loadingInfo = false
  }

  async loadCompaniesTaxonomy() {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
  }

  close() {
    this.closeEvent.next(true)
  }

  // Questionnaire functionality
  showMenu(event: any) {
    let id = event.currentTarget.id
    let idNumber = id.split("-")[1]
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    let chevron = menuBtn.children[2]
    menu.classList.toggle("show")
    if (chevron.classList.contains("la-angle-down")) {
      chevron.classList.remove("la-angle-down")
      chevron.classList.add("la-angle-up")
    } else {
      chevron.classList.remove("la-angle-up")
      chevron.classList.add("la-angle-down")
    }
  }

  dateToString(date: any) {
    date = new Date(date)
    return DateUtil.toString3(date)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove("modal-open")
  }

  async updateMapping() {
    let questionnaire = new QuestionnaireFe(this.sections, this.request.questionnaire.autoConnect, this.request.id)
    let update = new RequestGroup_UpdateFe(this.request.requestGroupId, questionnaire)
    await this.stateService.updateRequestGroupQuestionnaire(update)
  }

  selectQuestion(section: SectionFe, question: QuestionFe) {
    this.selectedSection = section
    this.selectedQuestion = question
    this.questionStack = [question.id]

    // Expand the selected question to show its children
    this.expanded[question.id] = true

    // Show immediate children
    const children = this.getDirectChildren(question)
    children.forEach((child) => {
      this.expanded[child.id] = false // Ensure children start collapsed
    })

    // Hide siblings of the selected question
    const siblings = this.getSiblings(question)
    siblings.forEach((sibling) => {
      if (sibling.id !== question.id) {
        this.expanded[sibling.id] = false
        this.hideAllDescendants(sibling)
      }
    })

    this.cd.detectChanges()
  }

  backToPreviousQuestion() {
    if (this.questionStack.length > 1) {
      this.questionStack.pop()
      const previousQuestionId = this.questionStack[this.questionStack.length - 1]
      this.selectedQuestion = this.findQuestionById(previousQuestionId)
    } else {
      this.backToMainQuestions()
    }
  }

  backToMainQuestions() {
    this.selectedSection = null
    this.selectedQuestion = null
    this.questionStack = []
  }

  findQuestionById(id: string): QuestionFe | null {
    for (const section of this.sections) {
      for (const question of section.questions) {
        if (question.id === id) {
          return question
        }
        const followUp = this.findInFollowUps(question, id)
        if (followUp) {
          return followUp
        }
      }
    }
    return null
  }

  findInFollowUps(question: QuestionFe, id: string): QuestionFe | null {
    if (question.id === id) {
      return question
    }
    const children = this.selectedSection.getChildrenOfParent(question.id)
    for (const followUp of children) {
      const result = this.findInFollowUps(followUp, id)
      if (result) {
        return result
      }
    }
    return null
  }

  getDirectChildren(question: QuestionFe): QuestionFe[] {
    return this.selectedSection.getChildrenOfParent(question.id)
  }

  toggleFollowUpQuestions(followUp: QuestionFe, event: Event) {
    event.stopPropagation()
    const followUpId = followUp.id

    this.expanded[followUpId] = !this.expanded[followUpId]

    if (this.expanded[followUpId]) {
      // Show immediate children
      const children = this.getDirectChildren(followUp)
      children.forEach((child) => {
        this.expanded[child.id] = false
      })

      // Hide siblings
      const siblings = this.getSiblings(followUp)
      siblings.forEach((sibling) => {
        if (sibling.id !== followUpId) {
          this.expanded[sibling.id] = false
          this.hideAllDescendants(sibling)
        }
      })

      this.currentlyExpanded = followUpId
    } else {
      // If collapsing, hide all descendants
      this.hideAllDescendants(followUp)
      this.currentlyExpanded = null
    }

    this.cd.detectChanges()
  }

  getSiblings(question: QuestionFe): QuestionFe[] {
    const parent = this.findParentQuestion(question)
    return parent ? this.selectedSection.getChildrenOfParent(parent.id) : this.selectedSection.questions
  }

  getAllFollowUpQuestions(question: QuestionFe): QuestionFe[] {
    let allQuestions: QuestionFe[] = []
    const directChildren = this.getDirectChildren(question)

    directChildren.forEach((child) => {
      allQuestions.push(child)
      allQuestions = allQuestions.concat(this.getAllFollowUpQuestions(child))
    })

    return allQuestions
  }

  isVisible(question: QuestionFe): boolean {
    if (question.parentQuestionId === this.selectedQuestion.id) {
      return true
    }
    const parent = this.findQuestionById(question.parentQuestionId)
    return this.expanded[parent.id] && this.isVisible(parent)
  }

  getFollowUpQuestions(question: QuestionFe): QuestionFe[] {
    return this.selectedSection.questions.filter((q) => q.parentQuestionId === question.id)
  }

  findParentQuestion(question: QuestionFe): QuestionFe | null {
    for (const q of this.selectedSection.questions) {
      if (this.isParent(q, question)) {
        return q
      }
    }
    return null
  }

  isParent(potentialParent: QuestionFe, child: QuestionFe): boolean {
    const children = this.selectedSection.getChildrenOfParent(potentialParent.id)
    if (children.some((c) => c.id === child.id)) {
      return true
    }
    for (const c of children) {
      if (this.isParent(c, child)) {
        return true
      }
    }
    return false
  }

  showAllDescendants(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question.id)
    children.forEach((child) => {
      this.expanded[child.id] = true
      this.showAllDescendants(child)
    })
  }

  hideAllDescendants(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question.id)
    children.forEach((child) => {
      this.expanded[child.id] = false
      this.hideAllDescendants(child)
    })
  }

  getFirstLevelFollowUps(): QuestionFe[] {
    return this.selectedQuestion ? this.selectedSection.getChildrenOfParent(this.selectedQuestion.id) : []
  }

  hasFollowUpQuestions(question: QuestionFe): boolean {
    return this.selectedSection.getChildrenOfParent(question.id).length > 0
  }

  @HostListener("window:showFollowUpQuestions", ["$event"])
  showFollowUpQuestions(event: CustomEvent) {
    const followUpId = event.detail
    const followUp = this.findQuestionById(followUpId)
    if (followUp) {
      this.selectedQuestion = followUp
      this.selectQuestion(this.selectedSection, followUp)
    }
  }

  getQuestionLevel(question: QuestionFe): number {
    let level = 0
    let currentQuestion = question

    while (currentQuestion.parentQuestionId) {
      level++
      currentQuestion = this.findQuestionById(currentQuestion.parentQuestionId)
    }

    return level
  }

  getIndentation(level: number): string {
    const baseIndent = 20
    const maxLevel = 3
    return `${Math.min(level, maxLevel) * baseIndent}px`
  }

  renderQuestionOptions(question: QuestionFe): string {
    let html = ""

    if (this.requestService.isTextQues(question)) {
      html += `
        <div class="col mt-1 mx-0 px-0">
          <textarea class="form-control w-50" rows="3" placeholder="Write answer" disabled></textarea>
        </div>
      `
    } else if (this.requestService.isEmissionFactorQues(question)) {
      html += `
        <div class="row w-50">
          <div class="w-50 ms-0 me-4">
            <emission-factor isDisabled="true"></emission-factor>
          </div>
        </div>
      `
    } else if (this.requestService.isDateQues(question)) {
      html += `
        <div class="row w-50">
          <div class="col-12">
            <input type="date" class="form-control" placeholder="12/12/2022" disabled />
          </div>
          ${
            question["range"]
              ? `
            <div class="col-12 ml-1">
              <p class="mb-2">${this.locale("locale_key.pages.task_portal.answer_wizard.date.options.range.to")}</p>
            </div>
            <div class="col-12">
              <input type="date" class="form-control" placeholder="12/12/2022" disabled />
            </div>
          `
              : ""
          }
        </div>
      `
    } else if (this.requestService.isNumberQues(question)) {
      html += `
        <div class="row w-50">
          <div class="col-6 ms-0 ps-3">
            <input type="text" class="form-control" placeholder="value" disabled />
          </div>
          <div class="col-6 ms-0 ps-3">
            <p>${question.answer}</p>
          </div>
        </div>
      `
    } else if (this.requestService.isAttachmentQues(question)) {
      html += `
        <div class="row w-50">
          <div class="col-12 m-1 ps-3">
            <button class="btn primary-button" disabled>${this.locale("locale_key.pages.data_request.review_answer_wizard.file.button.select_file")}</button>
          </div>
        </div>
      `
    } else if (this.requestService.isDataTableQues(question)) {
      html += `
        <div class="row">
          <div class="col-12 ms-0 ps-3">
            <datagrid-table-editor [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
          </div>
          <div class="col-12 m-1 ps-3">
            <button class="btn primary-button" disabled>${this.locale("locale_key.general.buttons.extract_from_file")}</button>
          </div>
        </div>
      `
    } else if (this.requestService.isMultipleChoiceQues(question) || this.requestService.isSingleChoiceQues(question)) {
      const inputType = this.requestService.isMultipleChoiceQues(question) ? "checkbox" : "radio"
      html += `
        <div>
          ${question["options"]
            .map(
              (option, index) => `
            <div class="row me-auto mx-1">
              <a id="btnMenu-${index}" class="dropdown" (click)="this.querySelector('.dropdown-menu').classList.toggle('show')">
                <input type="${inputType}" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" ${inputType === "radio" ? `value="${option.id}"` : ""} disabled />
                <p class="d-inline">${option.value}</p>
                ${
                  option.desc
                    ? `
                  <i class="ms-2 la la-angle-down d-inline" id="chev-${index}"></i>
                  <div class="dropdown-menu position-relative dropdown-demo px-1">
                    ${option.desc}
                  </div>
                `
                    : ""
                }
              </a>
            </div>
          `
            )
            .join("")}
        </div>
      `
    }

    if (question.comments) {
      html += `
        <div class="col mt-1 ms-0 ps-0">
          <label class="mt-1">${this.locale("locale_key.modal.questionnaire_preview.comments")}</label>
          <textarea class="form-control w-50" rows="1" disabled></textarea>
        </div>
      `
    }

    return html
  }
}
