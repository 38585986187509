import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

enum ModalNames {
  categorySelectorModal = "categorySelectorModal",
}

@Component({
  selector: "category-switcher",
  templateUrl: "./category-switcher.component.html",
  styleUrls: ["./category-switcher.component.scss"],
})
export class CategorySwitcherComponent extends AbstractLanguageComponent implements OnInit {
  dcIcons = {
    environmental: "las la-globe-europe",
    social: "las la-user",
    governance: "las la-gavel",
  };

  @Input() dataCategories = {
    next: { level_1: [], level_2: {}, level_3: {} },
    deployed: { level_1: [], level_2: {}, level_3: {} },
  };

  @Input() selectedDataCategory = {
    level_1: { label: undefined, key: "" },
    level_2: { label: undefined, key: "" },
    level_3: { label: undefined, key: "", columns: [] },
  };

  @Input() selectedDatapoint;
  @Input() showDatapoint = false;
  @Input() isDisabled = false;
  @Output() categorySwitched = new EventEmitter<any>();

  @ViewChild(`${ModalNames.categorySelectorModal}`, { static: true })
  categorySelectorModal: TemplateRef<any>;

  modals;

  constructor(private modalService: BsModalService, public languageService: LanguageService) {
    super(languageService)
  }


  ngOnInit(): void {
    this.setupModals();
  }

  resolveLabel(label: any): string {
    return label[this.activeLanguage.code] || label['en'] || ''
  }

  private setupModals() {
    this.modals = {
      [`${ModalNames.categorySelectorModal}`]: {
        template: this.categorySelectorModal,
        class: `modal-lg ${ModalNames.categorySelectorModal}`,
      },
    };
  }

  private openModal(options: {
    modal: ModalNames;
    class?: string;
    ignoreBackdropClick?: boolean;
  }) {
    const modal = options.modal;
    const customClass = options.class ?? this.modals[modal].class;
    const template = this.modals[modal].template;
    const ignoreBackdropClick = options.ignoreBackdropClick ?? true;
    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass,
    });
  }

  private closeModal({ modal }) {
    const modalRef = this.modals[modal].ref;
    this.modalService.hide(modalRef.id);
  }

  startChangeCategory() {
    this.openModal({
      modal: ModalNames.categorySelectorModal,
      ignoreBackdropClick: false,
    });
  }

  changeCategory(opts: {
    level_1: EntityFe;
    level_2: EntityFe;
    level_3: EntityFe;
  }) {
    if (!opts.level_3 || opts.level_3.isRemoved) {
      return;
    }
    this.selectedDataCategory.level_1 = opts.level_1;
    this.selectedDataCategory.level_2 = opts.level_2;
    this.selectedDataCategory.level_3 = opts.level_3;
    this.closeModal({
      modal: ModalNames.categorySelectorModal,
    });
    this.categorySwitched.emit(this.selectedDataCategory);
  }
}
