<div class="modal-content">
  <div class="modal-header alert-modal-header p-2">
    <div class="modal-title black" >
      <h3 *ngIf="mode == 'info'" class="m-0 mt-2" [ngClass]="{'modal-header-fs': screenSize.isXSmallSize()}">{{locale('locale_key.pages.revise_organization.title')}}</h3>
      <h3 *ngIf="mode == 'upgrade'" class="m-0 mt-2" [ngClass]="{'modal-header-fs': screenSize.isXSmallSize()}"> {{ locale("locale_key.pages.organization.upgrade_modal.title") }} </h3>
    </div>
    <button type="button" class="btn close my-auto" data-dismiss="modal" aria-label="Close" (click)="cancel()">
      <h3><i class="las la-times"></i></h3>
    </button>
  </div>
  <div class="modal-body p-3" *ngIf="mode == 'info'">
    <h4><i class="la la-lightbulb-o"></i>Practical considererations</h4>
      <ol>
        <li>{{ locale("locale_key.pages.organization.organization_chart_is_linked") }}</li>
        <li>{{ locale("locale_key.pages.organization.you_can_work_on_draft") }}</li>
        <li>{{ locale("locale_key.pages.organization.always_possible_revise") }}</li>
        <li>{{ locale("locale_key.pages.organization.when_you_are_happy") }}</li>
      </ol>
  </div>
  <div class="modal-body p-3" *ngIf="mode == 'upgrade' && orgInfo.modified">
    <h4>{{ locale("locale_key.pages.organization.upgrade_modal.sub_title") }}</h4>
  </div>
  <div class="modal-body p-3" *ngIf="mode == 'upgrade' && orgInfo.modified && entieisWithNewTypeAttribues().length == 0">
    <ng-container *ngIf="orgInfo.deletedEntities.length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.description") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of orgInfo.deletedEntities">
          <li>{{entity.label}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="entitiesWithDeletedAttributes().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.columns_to_be_removed") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of entitiesWithDeletedAttributes()">
          <li>{{entity.label}}: {{deletedAttributeListOf(entity)}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="entitiesWithDeletedParents().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.connections_to_be_deleted") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of entitiesWithDeletedParents()">
          <li>{{entity.label}}: {{entity.deletedParents}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="entieisWithAddedAttribues().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.columns_to_be_added") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of entieisWithAddedAttribues()">
          <li>{{entity.label}}: {{newAttributeListOf(entity)}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="entitiesWithAddedParents().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.connections_to_be_added") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of entitiesWithAddedParents()">
          <li>{{entity.label}}: {{entity.addedParents}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="newEntities().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.create_data_group") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of newEntities()">
          <li>{{entity.label}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="renamedEntities().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.rename_data_group") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of renamedEntities()">
          <li>{{entity.oldLabel}} to {{entity.label}}</li>
        </ng-container>
      </ul>  
    </ng-container>
    <ng-container *ngIf="entieisWithRenamedAttribues().length > 0">
      <h5>{{ locale("locale_key.pages.organization.upgrade_modal.rename_column") }}</h5>
      <ul>
        <ng-container *ngFor="let entity of entieisWithRenamedAttribues()">
          <li>{{entity.label}}: {{renamedAttributeListOf(entity)}}</li>
        </ng-container>
      </ul>  
    </ng-container>
  </div>
  <div class="modal-body p-75" *ngIf="mode == 'upgrade' && orgInfo.modified && entieisWithNewTypeAttribues().length > 0">
    <h5>{{ locale("locale_key.pages.organization.upgrade_modal.data_format") }}</h5>
    <ul>
      <ng-container *ngFor="let entity of entieisWithNewTypeAttribues()">
        <li>{{entity.label}}: {{reTypedAttributeListOf(entity)}}</li>
      </ng-container>
    </ul>  
  </div>
  <div class="modal-body p-75" *ngIf="mode == 'upgrade' && !orgInfo.modified">
    <h4>{{ locale("locale_key.pages.organization.upgrade_modal.no_data_changed") }}</h4>
  </div>
  <div class="modal-footer p-75" style="border-top: none">
      <button type="submit" class="btn secondary-button custom-button mt-0 ml-1"
        (click)="cancel()">{{ locale("locale_key.pages.organization.upgrade_modal.button.cancel") }}</button>
      <button type="submit" class="btn primary-button custom-button mt-0" 
        (click)="infoConfirmed()" *ngIf="mode == 'info'">{{ locale("locale_key.pages.organization.upgrade_modal.button.confirm") }}</button>
      <button type="submit" class="btn primary-button btn-block custom-button mt-0" 
        (click)="upgradeConfirmed()" *ngIf="mode == 'upgrade' && orgInfo.modified && entieisWithNewTypeAttribues().length == 0">{{ locale("locale_key.pages.organization.upgrade_modal.button.upgrade") }}</button>
      <button type="submit" class="btn primary-button btn-block custom-button mt-0" 
      (click)="cancel()" *ngIf="mode == 'upgrade' && orgInfo.modified && entieisWithNewTypeAttribues().length > 0">{{ locale('locale_key.pages.organization.upgrade_modal.button.ok') }}</button>
      <button type="submit" class="btn primary-button btn-block custom-button mt-0" 
      (click)="cancel()" *ngIf="mode == 'upgrade' && !orgInfo.modified">{{ locale('locale_key.pages.organization.upgrade_modal.button.ok') }}
      </button>
  </div>
</div>