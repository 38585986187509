import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @Input() selectedIndex: number = 0; // Default selectedIndex to 0
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  ngAfterContentInit() {
    this.tabs.changes.subscribe(() => {
      this.updateSelectedIndex();
    });
  }

  updateSelectedIndex() {
    if (this.selectedIndex < 0 || this.selectedIndex >= this.tabs.length) {
      this.selectedIndex = 0;
    }
    this.selectTab(this.selectedIndex);
  }

  selectTab(index: number) {
    if (!this.tabs || this.tabs.length === 0) {
      return;
    }
    this.tabs.toArray().forEach((tab, i) => {
      tab.isActive = i === index;
    });

    this.selectedIndex = index;
  }
}
