import {
  Component,
  Inject,
  Input,
  Output,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as UUID from "uuid";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { ValidationMessages } from "src/app/model/form-validation/FormValidationMessages";
import { ContactFe } from "src/app/model/user/ContactFe";
import { Request_AddFe } from "src/app/model/data-suppliers/request/add/Request_AddFe";
import { RequestGroup_AddFe } from "src/app/model/data-suppliers/request/add/RequestGroup_AddFe";
import { DateUtil } from "src/app/utils/DateUtil";
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { RequestFe } from "src/app/model/data-suppliers/request/RequestFe";
import { DOCUMENT } from "@angular/common";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe";
import { QuestionAttachmentFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionAttachmentFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { QuestionSingleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe";
import { QuestionMultipleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe";
import { QuestionDateFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDateFe";
import { QuestionTextFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionTextFe";
import { QuestionNumberFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe";
import { QuestionChoiceOptionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionChoiceOptionFe";
import { DataTableRequestFieldFe } from "src/app/model/data-suppliers/request/questionnaire/DataTableRequestFieldFe";
import { BooleanStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/BooleanStatementFe";
import { TwoOperandStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandStatementFe";
import { VariableStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/VariableStatementFe";
import { TwoOperandOperatorFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandOperatorFe";
import { BooleanValueStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/BooleanValueStatementFe";
import { QuestionnaireFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe";
import { QUESTION_TYPE } from "src/app/model/data-suppliers/request/questionnaire/QuestionTypeFe";
import { QuestionTypeConversion } from "src/app/model/data-suppliers/request/questionnaire/QuestionTypeConversionFe";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import moment from "moment";
import parser from "cron-parser";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import {
  SchedulingOccurenceGeneratorFe,
  SchedulingOccurenceGeneratorFromTypes
} from "src/app/model/scheduling/SchedulingOccurenceGeneratorFe";
import { RecurrenceFe } from "src/app/model/data-suppliers/request/recurrence/RecurrenceFe";
import { IntervalFe } from "src/app/model/data-suppliers/request/recurrence/IntervalFe";
import { RepeatScheduleFe } from "src/app/model/data-suppliers/request/recurrence/RepeatScheduleFe";
import { CustomRepeatScheduleFe } from "src/app/model/data-suppliers/request/recurrence/CustomRepeatScheduleFe";
import { ReminderScheduleFe } from "src/app/model/data-suppliers/request/recurrence/ReminderScheduleFe";
import { NamedOccurenceFe } from "src/app/model/data-suppliers/request/recurrence/NamedOccurenceFe";
import { SectionFe } from "src/app/model/data-suppliers/request/questionnaire/SectionFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { DataCategoryServiceFe } from "src/app/services/DataCategoryServiceFe";
import { RequestServiceFe } from "src/app/services/RequestServiceFe";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { groupUnitsByMeasurementTypeAndSystem } from "../../unit-systems/model/utils";
import { UnitFe } from "../../unit-systems/model/UnitFe";
import { QuestionEmissionFactorFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionEmissionFactorFe";
interface Period {
  label: string;
  name: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: "submission-details-questionnaire",
  templateUrl: "./submission-details-questionnaire.component.html",
  styleUrls: [
    "./data-supplier-questionaire.component.scss",
    "../data-suppliers.component.scss"
  ],
  providers: [{ provide: BsDropdownDirective }]
})
export class SubmissionDetailsRequestComponent extends AbstractLanguageComponent implements OnInit {
  
  @Input() form3 = new FormGroup({
    // deadline: new FormControl(null, [Validators.required]),
    dataConnection: new FormControl("autoConnect")
  });

  @Input() allDataOwners: ContactFe[] = [];
  @Output() deadlineChanged = new EventEmitter<String>();
  @Input() recurringRequest: boolean = false;
  @Output() recurringRequestChanged = new EventEmitter<boolean>();
  filteredDataOwners: ContactFe[] = [];
  filterText: string = "";

  dataScopeMessage = this.locale(
    "locale_key.general.validation_message.data_category_required"
  );
  mappingMessage = this.locale(
    "locale_key.general.validation_message.mapping_required"
  );
  dataOwnerMessage = this.locale(
    "locale_key.general.validation_message.dataowner_required"
  );
  requestDeadlineMessage = this.locale(
    "locale_key.general.validation_message.request_deadline_required"
  );
  questionnaireMessage = this.locale(
    "locale_key.general.validation_message.incorrect_questionnaire"
  );
  isDueDatePassedConfirmed = false;
  inProgress = false;
  loadingInProgress = false;
  loadingInfo: any;
  todaysDate = moment().utc().startOf("day").toISOString().split("T")[0];
  selectedDataOwner: ContactFe;
  editDOTable = true;
  @Input() isQuestionnaireInvalid;
  requestForPreview: RequestFe;
  allRequestGroups: RequestGroupFe[] = [];

  selectedValues: string[] = [];
  selectedOption: any;
  showAdditionalInputs = false;
  recurrences: number = 0;
  periods: Period[] = [
    { label: "1st request period", name: "", startDate: "", endDate: "" },
    { label: "2nd request period", name: "", startDate: "", endDate: "" }
  ];
  intervals: boolean = false;
  intervalsView: boolean = false;
  morePeriods: boolean = true;
  oneTimeRequest: boolean = true;
  reminder: boolean = false;
  customRepeat: boolean = false;

  initCacheInProgress: boolean;
  menuCollapsed: boolean;
  url: string = "";
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE;
  inviteMode;
  recurringMode = "oneTime";
  recurrenceType = "ONETIME";
  sendOutDate = new FormControl(moment().format("YYYY-MM-DD"));
  repeatPresets = [];
  repeatPresetsValueMap = { CUSTOM: "" };
  selectedRepeatPreset = "WEEKLY";
  customRepeatMonthlyPresets = [];
  customRepeatMonthlyPresetsValueMap = {};
  selectedCustomRepeatUnit = "DAILY";
  selectedCustomRepeatValue = 1;
  selectedCustomRepeatDayOfWeek = "";
  selectedCustomRepeatMonthType = "";
  selectedRecurrenceEnds = "NEVER";
  @Input() nextDeadline = moment()
    .add(1, "month")
    .startOf("day")
    .toISOString()
    .split("T")[0];
  maxOccurrences = 0;
  sendOutType = "DATE";
  sendOutIntervalType = "BEFORE_DEADLINE";
  sendOutIntervalUnit = "DAY";
  sendOutIntervalValue = 1;
  repeatType = "PRESET";
  occurrences = [];
  occurrencesMap = {};
  occurrencesNameCache = {};
  neverOccurrences = [];
  neverOccurrencesMap = {};
  onDateOccurrences = [];
  onDateOccurrencesMap = {};
  onDateOccurrencesNameCache = {};
  maxDateUI = "";
  maxDate = "";
  reminders: ReminderScheduleFe[] = [
    new ReminderScheduleFe("BEFORE_DEADLINE", "WEEK", 1, "WEEK_1")
  ];
  sendReminderToCreator: boolean = false;
  showDeadlineError: boolean = false;
  deadlineErrorMsg = this.locale(
    "locale_key.general.validation_message.deadline_required"
  );
  showRecurrenceTypeError: boolean = false;
  recurrenceTypeErrorMsg = this.locale(
    "locale_key.general.validation_message.request_frequency_required"
  );
  sendOutDayErrorMsg = this.locale(
    "locale_key.general.validation_message.past_send_out_dates"
  );
  showSendOutDayError: boolean;
  maxDateErrorMsg = this.locale(
    "locale_key.general.validation_message.max_date_required"
  );
  showMaxDateError: boolean = false;
  mapped: boolean = false;
  mergeSubmission: boolean = false;
  selectedEntityKey: EntityFe;

  units = [];
  customUnits = [];
  unitsByMeasurementType = [];
  selectedQuestionIndex: number;
  selectedSection: SectionFe;

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe,
    public categoryService: DataCategoryServiceFe,
    public requestService: RequestServiceFe
  ) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress;
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress;
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed;
    });

    this.responsive.screenWidthSizeSubject.subscribe(
      (screenSize: ScreenWidthSizeFe) => {
        this.screenSize = screenSize;
      }
    );

    this.screenSize = responsive.currentScreenWidthSize;

    this.updateRepeatPresets();
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits();
    });
  }

  ngOnInit(){
    this.inProgress = true;
    this.filteredDataOwners = this.allDataOwners;
    this.filteredDataOwners.forEach((d) => (d.isSelected = false));
    this.inProgress = false;
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(
      this.units
    );
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.customUnits = this.units.filter(
      (unit) => unit.isCustom && !unit.isStandard
    );
  }

  filterRows() {
    let newRegEx = new RegExp(this.filterText, "i");
    this.filteredDataOwners = this.allDataOwners.filter(
      (dataOwner) =>
        dataOwner.userFirstName.match(newRegEx) ||
        dataOwner.userLastName.match(newRegEx) ||
        `${dataOwner.userFirstName} ${dataOwner.userLastName}`.match(newRegEx) ||
        dataOwner.affiliationEmail.match(newRegEx) ||
        (dataOwner.supplierCompanyname
          ? dataOwner.supplierCompanyname.match(newRegEx)
          : this.stateService.activeWorkspace.companyName.match(newRegEx))
    );
  }

  openModal(template: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(template, {
      class: size,
      backdrop: "static",
      keyboard: true
    });
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  anyDOSelected() {
    return this.filteredDataOwners.some((d) => d.isSelected);
  }

  isDueDatePassed(): boolean {
    let today = new Date();
    let selectedDate = new Date(this.form3.get("deadline").value);
    let diff = DateUtil.getTimeDifference(selectedDate, today);
    if (diff < 0) {
      return true;
    }
    return false;
  }

  compareFn(item, selected) {
    return item.value === selected.value;
  }

  //NEW CODE

  selectAllDataOwners(event: Event) {
    if ((event.target as HTMLInputElement).checked)
      this.filteredDataOwners.forEach((d) => (d.isSelected = true));
    else this.filteredDataOwners.forEach((d) => (d.isSelected = false));
  }

  addReminder(type) {
    const reminder = new ReminderScheduleFe(type, "WEEK", 1, "WEEK_1");
    this.reminders.push(reminder);
  }

  removeReminder(index: number) {
    // Remove the reminder from the reminders array
    this.reminders.splice(index, 1);
  }

  onSelectChange(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedValues[index] = selectElement.value;
  }

  onDateChange() {
    this.showAdditionalInputs = true;
  }

  getArray(n: number): number[] {
    this.showAdditionalInputs = false;
    return Array(n)
      .fill(0)
      .map((_, i) => i);
  }

  addPeriod() {
    this.showAdditionalInputs = false;
    const label = `${this.periods.length + 1}th request period`;
    const newPeriod: Period = { label, name: "", startDate: "", endDate: "" };
    this.periods.push(newPeriod);
  }

  showIntervals() {
    this.morePeriods == false
      ? (this.morePeriods = true)
      : (this.morePeriods = false);
  }

  setCustomRepeatDayOfWeek(dayOfWeek) {
    this.selectedCustomRepeatDayOfWeek = dayOfWeek;
    this.repeatValueChanged();
  }

  isSelectedCustomRepeatDayOfWeek(dayOfWeek) {
    return this.selectedCustomRepeatDayOfWeek == dayOfWeek;
  }
  getRepeatPresets(nextDeadline = moment().toISOString()) {
    const momentObj = moment(nextDeadline);

    const isoWeekDays = {
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday",
      "7": "Sunday"
    };
    const weekly_day = momentObj.isoWeekday();
    const weekly = this.locale(
      `locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.weekly_on`,
      { weekly_day: isoWeekDays[weekly_day] }
    );
    const weekly_cron = `5 0 * * ${weekly_day}`;

    const monthly_date = momentObj.date();
    const monthly = this.locale(
      `locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.monthly_on`,
      { monthly_date: monthly_date }
    );
    const monthly_cron = `5 0 ${monthly_date} * *`;

    const quaterly_month_pos = {
      January: { quarter: 1, monthPos: 1 },
      February: { quarter: 1, monthPos: 2 },
      March: { quarter: 1, monthPos: 3 },
      April: { quarter: 2, monthPos: 1 },
      May: { quarter: 2, monthPos: 2 },
      June: { quarter: 2, monthPos: 3 },
      July: { quarter: 3, monthPos: 1 },
      August: { quarter: 3, monthPos: 2 },
      September: { quarter: 3, monthPos: 3 },
      October: { quarter: 4, monthPos: 1 },
      November: { quarter: 4, monthPos: 2 },
      December: { quarter: 4, monthPos: 3 }
    };
    const months_in_a_year = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const month_pos_sufx = { "1": "st", "2": "nd", "3": "rd" };

    // Maps which months are the 1st, 2nd or 3rd in a quater
    const month_pos_to_cron = {
      "1": "1,4,7,10",
      "2": "2,5,8,11",
      "3": "3,6,9,12"
    };

    const yearly_month = momentObj.month();
    // cron months start from 1, while moment months start from 0

    const yearly_cron_month = yearly_month + 1;
    const monthPos =
      quaterly_month_pos[months_in_a_year[yearly_month]].monthPos;
    const quarterly_month = `${monthPos}${month_pos_sufx[monthPos]}`;

    const quarterly = this.locale(
      "locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.quarterly_on",
      { monthly_date: monthly_date, quarterly_month: quarterly_month }
    );
    const quarterly_cron = `5 0 ${monthly_date} ${month_pos_to_cron[monthPos]} *`;

    const yearly = this.locale(
      "locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.yearly_on",
      {
        monthly_date: monthly_date,
        yearly_month: months_in_a_year[yearly_month]
      }
    );
    const yearly_cron = `5 0 ${monthly_date} ${yearly_cron_month} *`;

    return [
      {
        human: weekly,
        cron: weekly_cron,
        value: "WEEKLY"
      },
      {
        human: monthly,
        cron: monthly_cron,
        value: "MONTHLY"
      },
      {
        human: quarterly,
        cron: quarterly_cron,
        value: "QUATERLY"
      },
      {
        human: yearly,
        cron: yearly_cron,
        value: "YEARLY"
      }
    ];
  }

  getCustomRepeatMonthlyPresets(nextDeadline = moment().toISOString()) {
    const get_date_of_month = (isodate) => {
      const date = moment(isodate).date();
      const human = this.locale(
        "locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.on_date_of_month",
        { date }
      );
      const cron = `5 0 ${date} * *`;
      return {
        human,
        cron
      };
    };

    const get_day_occurence_in_month = (isodate) => {
      const days_in_week = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ];
      const day = moment(isodate).day();
      const day_str = days_in_week[day];

      const start_month = moment(isodate);
      start_month.set("date", 1);
      const startIsoDate = start_month.toISOString();

      const interval = parser.parseExpression(`0 3 * * ${day}`, {
        currentDate: startIsoDate
      });
      const occurences = {};
      const occurencesArray = [];
      if (start_month.day() == day) {
        occurencesArray.push(startIsoDate.split("T")[0]);
      }
      for (let i = 0; i < 5; i++) {
        const date = interval.next().toISOString();
        const monthStr = date.split("T")[0];
        occurencesArray.push(monthStr);
      }
      occurencesArray.forEach((occurence, idx) => {
        occurences[occurence] = idx + 1;
      });

      const month_str = isodate.split("T")[0];
      const day_occurence_pfix = occurences[month_str];
      const day_occurence_sfix = { "1": "st", "2": "nd", "3": "rd", "4": "th" };
      const day_occurence = `${day_occurence_pfix}${
        day_occurence_sfix[day_occurence_pfix] || "th"
      }`;

      const human = this.locale(
        "locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.on_day_of_month",
        { day_occurence, day_str }
      );

      const cron = `5 0 0 ? * ${day}#${day_occurence_pfix}`;

      return {
        human,
        cron
      };
    };

    const get_last_day_occurence_in_month = (isodate) => {
      const days_in_week = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ];
      const day = moment(isodate).day();
      const day_str = days_in_week[day];

      const human = this.locale(
        "locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.last_day_of_month",
        { day_str }
      );
      const cron = `5 0 0 * * ${day}L`;
      return {
        human,
        cron
      };
    };

    const isodate = moment(nextDeadline).toISOString();
    const dom = get_date_of_month(isodate);
    const doim = get_day_occurence_in_month(isodate);
    const ldoim = get_last_day_occurence_in_month(isodate);

    return [
      {
        ...dom,
        value: "DATE_OF_MONTH"
      },
      {
        ...doim,
        value: "DAY_OCCURENCE_IN_MONTH"
      },
      {
        ...ldoim,
        value: "LAST_DAY_OCCURENCE_IN_MONTH"
      }
    ];
  }

  sendOutDateChanged() {
    let sendOutDate = new Date(this.sendOutDate.value);
    let deadline = new Date(this.nextDeadline);

    if (DateUtil.getTimeDifference(deadline, sendOutDate) < 0) {
      this.nextDeadline = null;
    }
  }

  nextDeadlineChanged() {
    this.showDeadlineError = false;
    if (_.isEmpty(this.nextDeadline)) {
      this.showDeadlineError = true;
    }
    this.maxDateUI = "";
    this.maxDate = "";
    this.updateRepeatPresets();
    this.clearOccurences();
    this.validateSendOut();
    this.deadlineChanged.emit(this.nextDeadline);
  }

  sendOutChanged() {
    this.updateRepeatPresets();
    this.clearOccurences();
    this.validateSendOut();
  }

  validateSendOut() {
    const occurences = this.getNextOccurrences({
      numOccurrences: this.maxOccurrences
    });

    let firstOccurenceDate: any = Object.keys(occurences)[0];
    firstOccurenceDate = new Date(firstOccurenceDate);
    let todaysDate: any = new Date().toDateString();
    todaysDate = new Date(todaysDate);

    if (DateUtil.getTimeDifference(firstOccurenceDate, todaysDate) < 0) {
      this.showSendOutDayError = true;
    } else {
      this.showSendOutDayError = false;
    }
  }

  recurringTypeChanged(type) {
    
    this.showRecurrenceTypeError = false;
    switch (type) {
      case "onetime":
        this.oneTimeRequest = true;
        this.recurringRequest = false;
        this.sendOutType = "DATE";
        this.recurrenceType = "ONETIME";
        break;
      case "recurring":
        this.oneTimeRequest = false;
        this.recurringRequest = true;
        this.sendOutType = "INTERVAL";
        this.recurrenceType = "FIXED";
        break;
    }
    this.recurringRequestChanged.emit(this.recurringRequest)
  }

  reRenderNamedOccurrences() {
    if (this.maxDateUI) {
      this.maxDateChanged();
    }
    if (this.maxOccurrences) {
      this.maxOccurrencesChanged();
    }
  }

  repeatTypeChanged() {
    this.repeatType = "PRESET";
    if (this.selectedRepeatPreset == "CUSTOM") {
      this.repeatType = "CUSTOM";
    }
    this.clearMaxDate();
    this.clearOccurences();
    this.reRenderNamedOccurrences();
  }

  repeatValueChanged() {
    this.clearMaxDate();
    this.clearOccurences();
    this.reRenderNamedOccurrences();
  }

  clearMaxDate() {
    this.maxDateUI = "";
    this.maxDate = "";
  }

  updateRepeatPresets() {
    //timezone issues
    const moment_str = moment(this.nextDeadline).utc(true).toISOString();
    this.repeatPresets = this.getRepeatPresets(moment_str);
    this.repeatPresets.forEach((preset) => {
      this.repeatPresetsValueMap[`${preset.value}`] = preset;
    });
    this.customRepeatMonthlyPresets =
      this.getCustomRepeatMonthlyPresets(moment_str);
    this.customRepeatMonthlyPresets.forEach((preset) => {
      this.customRepeatMonthlyPresetsValueMap[`${preset.value}`] = preset;
    });
  }

  formatUIOccurrence(occurence) {
    const date_format = "DD MMM, YYYY";
    occurence.SEND_OUT_DATE_UI = moment(occurence.SEND_OUT_DATE).format(
      date_format
    );
    occurence.NEXT_DEADLINE_UI = moment(occurence.NEXT_DEADLINE).format(
      date_format
    );
    return occurence;
  }

  genRecurrence(): RecurrenceFe {
    let namedOccurences = {};
    let namedOccurenceFe: NamedOccurenceFe[] = [];
    if (this.selectedRecurrenceEnds == "NEVER") {
      namedOccurences = this.neverOccurrencesMap;
    }
    if (this.selectedRecurrenceEnds == "ON_DATE") {
      namedOccurences = this.onDateOccurrencesMap;
    }
    if (this.selectedRecurrenceEnds == "AFTER_MAX_OCCURENCES") {
      namedOccurences = this.occurrencesMap;
    }

    Object.keys(namedOccurences).forEach((key) => {
      const occurence_raw = namedOccurences[key];
      const occurence = new NamedOccurenceFe(
        occurence_raw.SEND_OUT_DATE,
        occurence_raw.NEXT_DEADLINE,
        occurence_raw.NAME
      );
      namedOccurenceFe.push(occurence);
    });

    const nextDeadline = moment(this.nextDeadline)
      .utc(true)
      .startOf("day")
      .toISOString();
    let sendOutDate = moment(this.sendOutDate.value)
      .utc(true)
      .startOf("day")
      .toISOString();
    if (this.sendOutType == "INTERVAL") {
      const unitToMomentMap: any = {
        DAY: "d",
        WEEK: "w",
        MONTH: "M",
        YEAR: "y",
        weekly: "w",
        monthly: "M",
        quaterly: "Q",
        yearly: "y",
        daily: "d"
      };
      const sendOutIntervalUnit = this.sendOutIntervalUnit;
      const sendOutIntervalValue = this.sendOutIntervalValue;
      const sendOutMomentDuration = unitToMomentMap[sendOutIntervalUnit];
      sendOutDate = moment(nextDeadline)
        .subtract(sendOutIntervalValue, sendOutMomentDuration)
        .utc(true)
        .startOf("day")
        .toISOString();
    }

    const deduplicatedRemindersMap: { [key: string]: ReminderScheduleFe } = {};
    const deduplicatedReminders: ReminderScheduleFe[] = [];
    this.reminders.forEach((reminder) => {
      const key = `${reminder.type}_${reminder.unit}_${reminder.value}_${reminder.preset}`;
      deduplicatedRemindersMap[key] = reminder;
    });
    Object.keys(deduplicatedRemindersMap).forEach((key) => {
      deduplicatedReminders.push(deduplicatedRemindersMap[key]);
    });

    const recurrence = new RecurrenceFe(
      this.recurrenceType,
      this.sendOutType,
      sendOutDate,
      new IntervalFe(
        this.sendOutIntervalType,
        this.sendOutIntervalUnit,
        this.sendOutIntervalValue
      ),
      nextDeadline,
      namedOccurenceFe,
      new RepeatScheduleFe(
        nextDeadline,
        this.repeatType,
        this.selectedRepeatPreset,
        "",
        new CustomRepeatScheduleFe(
          this.selectedCustomRepeatUnit,
          this.selectedCustomRepeatDayOfWeek,
          this.selectedCustomRepeatMonthType,
          this.selectedCustomRepeatValue,
          ""
        )
      ),
      this.selectedRecurrenceEnds,
      this.maxDate,
      this.maxOccurrences,
      deduplicatedReminders,
      this.sendReminderToCreator,
      this.reminder,
      false
    );
    return recurrence;
  }

  maxOccurrencesChanged() {
    if (this.maxOccurrences < 1) {
      this.maxOccurrences = 1;
    }
    const occurences = this.getNextOccurrences({
      numOccurrences: this.maxOccurrences
    });
    const consolidated = {};
    Object.keys(occurences).forEach((SEND_OUT_DATE) => {
      consolidated[SEND_OUT_DATE] =
        this.occurrencesMap[SEND_OUT_DATE] || occurences[SEND_OUT_DATE];
    });
    this.occurrencesMap = consolidated;
    this.occurrences = Object.keys(consolidated)
      .sort()
      .map((date, idx) => {
        const occurence = consolidated[date];

        const occurenceNo = idx + 1;
        const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" };
        const occurenceNoSuffix = occurenceNoSuffixes[occurenceNo] || "th";
        const defaultName = `${occurenceNo}${occurenceNoSuffix} requesting period`;
        occurence.NAME = defaultName;
        this.occurrencesMap[date].NAME = defaultName;

        const cachedName =
          this.occurrencesNameCache[
            `${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`
          ];
        if (!_.isEmpty(cachedName)) {
          occurence.NAME = cachedName;
          this.occurrencesMap[date].NAME = cachedName;
        }

        return this.formatUIOccurrence(occurence);
      });
  }

  generateCustomRepeatCron({ unit, value }) {
    let cron = "";
    switch (unit) {
      case "DAILY":
        cron = `5 0 */${value} * *`;
        break;
      case "WEEKLY":
        break;
      case "MONTHLY":
        break;
      case "YEARLY":
        break;
    }
    return cron;
  }

  getNextOccurrences({
    numOccurrences,
    nextDeadline
  }: {
    numOccurrences: number;
    nextDeadline?: string;
  }) {
    const recurrence = this.genRecurrence();

    if (nextDeadline) {
      recurrence.nextDeadline = nextDeadline;
    }

    const occurenceGenerator = new SchedulingOccurenceGeneratorFe();

    const occurences = {};
    occurenceGenerator
      .generateOccurences({
        fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
        recurrence,
        numOccurrences
      })
      .forEach((occurence) => {
        const sendOutDate = occurence.sendOutDay;
        occurences[sendOutDate] = {
          SEND_OUT_DATE: sendOutDate,
          NEXT_DEADLINE: occurence.nextDeadline,
          NAME: ""
        };
      });

    return occurences;
  }

  occurenceUpdated(occurence) {
    this.occurrencesNameCache[
      `${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`
    ] = occurence.NAME;
    this.occurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME;
  }

  neverOccurrenceUpdated(occurence) {
    this.neverOccurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME;
  }

  addNeverOccurence() {
    let nextDeadline = "";
    let SEND_OUT_DATE = "";

    if (this.neverOccurrences.length == 0) {
      //timezone issues
      nextDeadline = moment(this.nextDeadline).utc(true).toISOString();
    } else {
      nextDeadline =
        this.neverOccurrences[this.neverOccurrences.length - 1].NEXT_DEADLINE;
    }

    const occurences = this.getNextOccurrences({
      numOccurrences: 2,
      nextDeadline
    });

    if (this.neverOccurrences.length == 0) {
      SEND_OUT_DATE = Object.keys(occurences)[0];
    } else {
      SEND_OUT_DATE = Object.keys(occurences).pop();
    }

    const occurence = occurences[SEND_OUT_DATE];

    const occurence_no = this.neverOccurrences.length + 1;
    const occurence_no_suffixes = { "1": "st", "2": "nd", "3": "rd" };
    const occurence_no_suffix = occurence_no_suffixes[occurence_no] || "th";
    const default_occurence_name = `${occurence_no}${occurence_no_suffix} requesting period`;
    occurence.NAME = default_occurence_name;

    this.neverOccurrencesMap[SEND_OUT_DATE] =
      this.formatUIOccurrence(occurence);
    this.neverOccurrences.push(occurence);
  }

  removeLastNeverOccurrence() {
    this.neverOccurrences.pop();
  }

  maxDateChanged() {
    //System crashes if we let empty maxDateUI past this point
    if (_.isEmpty(this.maxDateUI)) {
      this.clearOccurences();
      this.showMaxDateError = true;
      return;
    }

    this.showMaxDateError = false;
    //timezone issues
    this.maxDate = moment(this.maxDateUI).utc(true).toISOString();

    const occurenceGenerator = new SchedulingOccurenceGeneratorFe();
    const recurrence = this.genRecurrence();
    const recurrenceBuffer = { ...recurrence };
    const rawOccurrences = [];
    let occurenceBuffer = occurenceGenerator.generateOccurences({
      recurrence: recurrenceBuffer,
      fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
      numOccurrences: 2
    });
    let shouldContinue = true;
    do {
      rawOccurrences.push(occurenceBuffer[0]);
      if (moment(occurenceBuffer[1].nextDeadline).isAfter(recurrence.maxDate)) {
        shouldContinue = false;
      } else {
        recurrenceBuffer.nextDeadline = occurenceBuffer[1].nextDeadline;
        occurenceBuffer = occurenceGenerator.generateOccurences({
          recurrence: recurrenceBuffer,
          fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
          numOccurrences: 2
        });
      }
    } while (shouldContinue);
    const occurrences = {};
    rawOccurrences.forEach((occurrence) => {
      const sendOutDate = occurrence.sendOutDay;
      occurrences[sendOutDate] = {
        SEND_OUT_DATE: sendOutDate,
        NEXT_DEADLINE: occurrence.nextDeadline,
        NAME: ""
      };
    });

    const consolidated = {};
    Object.keys(occurrences).forEach((SEND_OUT_DATE) => {
      consolidated[SEND_OUT_DATE] =
        this.onDateOccurrencesMap[SEND_OUT_DATE] || occurrences[SEND_OUT_DATE];
    });
    this.onDateOccurrencesMap = consolidated;
    this.onDateOccurrences = Object.keys(consolidated)
      .sort()
      .map((date, idx) => {
        const occurence = consolidated[date];
        const occurenceNo = idx + 1;
        const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" };
        const occurenceNoSuffix = occurenceNoSuffixes[occurenceNo] || "th";
        const defaultName = `${occurenceNo}${occurenceNoSuffix} requesting period`;
        occurence.NAME = defaultName;
        this.onDateOccurrencesMap[date].NAME = defaultName;

        const cachedName =
          this.onDateOccurrencesNameCache[
            `${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`
          ];
        if (!_.isEmpty(cachedName)) {
          occurence.NAME = cachedName;
          this.onDateOccurrencesMap[date].NAME = cachedName;
        }

        return this.formatUIOccurrence(occurence);
      });
  }

  onDateOccurrenceUpdated(occurence) {
    this.onDateOccurrencesNameCache[
      `${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`
    ] = occurence.NAME;
    this.onDateOccurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME;
  }

  clearOccurences() {
    this.occurrences = [];
    this.occurrencesMap = {};
    this.occurrencesNameCache = {};
    this.neverOccurrences = [];
    this.neverOccurrencesMap = {};
    this.onDateOccurrences = [];
    this.onDateOccurrencesMap = {};
    this.onDateOccurrencesNameCache = {};
  }

  toggleEditDOTable() {
    this.editDOTable = !this.editDOTable;
  }

  reminderValueChanged({ idx, value }) {
    if (value <= 0) {
      this.reminders[idx].value = 1;
    }
  }

  stopImmediatePropagation(event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  }
}
