import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrganizationInfoFe } from 'src/app/model/organization/OrganizationInfoFe';
import { OrganizationTableInfoFe } from 'src/app/model/organization/OrganizationTableInfoFe';
import { TableDataFe } from 'src/app/model/schema/TableDataFe';
import { OrgInfoSubjectFe } from 'src/app/model/subject/OrgInfoSubjectFe';
import { AlertServiceFe } from 'src/app/services/AlertServiceFe';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { ErrorsFe } from 'src/app/utils/KNOWN_ERRORS';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { OpenOrgTableCallback } from './OpenOrgTableCallback';
import { OrganizationChartFLowChartIntegration } from './OrganizationChartFLowChartIntegration';
import { OrganizationTableDataGridIntergationService } from './OrganizationTableDataGridIntergationService';
import { ReviseOrganizationModal } from './ReviseOrganizationModal';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';

@Component({
  selector: 'organization-component',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent extends AbstractLanguageComponent implements OnInit, OpenOrgTableCallback {
  @ViewChild('reviseOrganizaionInfoModal', { static: true }) reviseOrganizaionInfoModal: TemplateRef<any>;
  @ViewChild('upgradeOrganizaionInfoModal', { static: true }) upgradeOrganizaionInfoModal: TemplateRef<any>;
  isChartActive = true
  isNewVersionActive = false
  depOrg: OrganizationInfoFe
  newOrg: OrganizationInfoFe
  depOrgIntegrationService: OrganizationChartFLowChartIntegration
  newOrgIntegrationService: OrganizationChartFLowChartIntegration
  orgTableInfos: OrganizationTableInfoFe[] = []
  loadInProgress: boolean = false
  loadTableInProgress: boolean = false
  savingChartInProgress: boolean = false
  savingTablesInProgress: boolean = false
  upgradingInProgress: boolean = false
  isLocked = true
  organization = true;
  selectedOrgTableIndex: number = -1
  public modalRef: BsModalRef;
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  url:string =''
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode;
  
  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.edit'),
        longLabel: this.locale('locale_key.pages.organization.toolbar.edit_organization'),
        tooltip: this.locale('locale_key.pages.organization.toolbar.edit_organization'),
        icon: "la la-edit",
        actionName: "edit_organisation",
        visible: () => this.isChartActive && !this.isNewVersionActive,
        disabled: false,
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: "la la-arrow-left",
        actionName: "back",
        visible: () => this.isChartActive && this.isNewVersionActive && !this.newOrgIntegrationService.modified && !this.upgradingInProgress,
        disabled: false,
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.discard'),
        longLabel: this.locale('locale_key.general.toolbar.button.discard'),
        tooltip: this.locale('locale_key.general.toolbar.button.discard'),
        icon: "la la-trash-o",
        actionName: "discard",
        visible: () => this.isChartActive && this.isNewVersionActive && this.newOrgIntegrationService.modified,
        disabled: false,
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.save'),
        longLabel: this.locale('locale_key.general.toolbar.button.save'),
        tooltip: this.locale('locale_key.general.toolbar.button.save'),
        icon: "la la-save",
        actionName: "save",
        visible: () => this.isChartActive && this.isNewVersionActive && this.newOrgIntegrationService.modified && !this.savingChartInProgress && !this.upgradingInProgress,
        disabled: false,
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.publish'),
        longLabel: this.locale('locale_key.general.toolbar.button.publish'),
        tooltip: this.locale('locale_key.general.toolbar.button.publish'),
        icon: "las la-upload",
        actionName: "publish",
        visible: () => (this.isChartActive && this.isNewVersionActive && (this.newOrgIntegrationService.modified || this.newOrg.modified)) && !this.savingChartInProgress && !this.upgradingInProgress,
        disabled: false,
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.save'),
        longLabel: this.locale('locale_key.general.toolbar.button.save'),
        tooltip: this.locale('locale_key.general.toolbar.button.save'),
        icon: "la la-save",
        actionName: "save_table",
        visible: () => !this.isChartActive && this.orgTableInfos[this.selectedOrgTableIndex]?.dataGridService.modified && !this.orgTableInfos[this.selectedOrgTableIndex]?.dataGridService.saveInProgress,
        disabled: false,
      },
    ],
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info", actionName:"toggle_quick_tips" , visible:()=> true}
    ],  
  ];
  


  constructor(private stateService: StateServiceFe, private modalService: BsModalService, private displayService: DisplayServiceFe, private alertService: AlertServiceFe, languageService: LanguageService,private responsive: ResponsiveService,
    private ErrorsFe: ErrorsFe) { 
    super(languageService)
    if (stateService.activeWorkspace) {
      this.loadCompanyOrganization()
    }
    
    stateService.workspaceSubject.subscribe((activeCompany) => {
      this.loadCompanyOrganization()
    })

    stateService.depOrgInfoSubject.subscribe((subject: OrgInfoSubjectFe) => {
      if (this.depOrgIntegrationService) {
        this.depOrg = subject.orgInfo
        this.depOrgIntegrationService.updateOrganizationInfo(this.depOrg)
      }
    })

    stateService.newOrgInfoSubject.subscribe((subject: OrgInfoSubjectFe) => {
      if (this.newOrgIntegrationService) {
        this.newOrg = subject.orgInfo
        this.newOrgIntegrationService.updateOrganizationInfo(this.newOrg)
      }
    })

    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });

    this.screenSize = responsive.currentScreenWidthSize;
  }

  ngOnInit(): void {
    if(this.depOrg.entities.length == 0){
      this.displayService.openTips();
      this.displayService.updateQuickTipsData({viewMode: 'view_mode', page:'organization'})
    }
  }



  public async loadCompanyOrganization() {
    this.loadInProgress = true
    this.depOrg = null
    this.newOrg = null
    this.isChartActive = true
    this.isNewVersionActive = false
    this.orgTableInfos = []
    let {depOrg, newOrg} = await this.stateService.getOrgInfos()
    this.depOrg = depOrg
    this.newOrg = newOrg
    this.newOrgIntegrationService = new OrganizationChartFLowChartIntegration(this.stateService.activeWorkspace?.companyName, this.newOrg, 'edit', this)
    this.depOrgIntegrationService = new OrganizationChartFLowChartIntegration(this.stateService.activeWorkspace?.companyName, this.depOrg, 'view', this)
    this.loadInProgress = false
  }

  async openOrgTable(orgEntityKey: string) {
    let orgTableInfo
    let orgTableInfoIndex
    this.orgTableInfos.forEach((orgTable, i) => {
      if (orgTable.entity.key == orgEntityKey) {
        orgTableInfo = orgTable
        orgTableInfoIndex = i
      }
    })
    if (orgTableInfo) {
      this.viewTable(orgTableInfoIndex)
    } else {
      this.loadTableInProgress = true
      let orgTables: TableDataFe[] = await this.stateService.getOrganizationTableDatas()
      let orgTable = orgTables.find(table => table.key == orgEntityKey) 
      let entity = this.depOrg.entities.find(e => e.key == orgTable.key)
      let service = new OrganizationTableDataGridIntergationService(entity, this.depOrg, orgTables, this.stateService)
      let orgTableInfo = new OrganizationTableInfoFe(entity, service)
      this.orgTableInfos.push(orgTableInfo)
      this.viewTable(this.orgTableInfos.length - 1)
      this.loadTableInProgress = false
    }
  }

  closeTableTab(tableInfo: OrganizationTableInfoFe) {
    this.orgTableInfos = this.orgTableInfos.filter(ot => ot.entity.key != tableInfo.entity.key)
    this.viewChart()
  }
  
  public openModal(modalTemplateRef: TemplateRef<any> | string, size: string = '') {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: 'modal-md',
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
    this.displayService.updateQuickTipsData({viewMode: 'edit_mode', page:'organization'})
  }


  onReviseOrganizationInfoConfirmed(confirmed: boolean) {
    this.modalService.hide(this.modalRef.id )
    document.body.classList.remove('modal-open');
    this.isNewVersionActive = confirmed
  }

  async onUpgradeOrganizationConfirmed(confirmed: boolean) {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove('modal-open');
    if (confirmed) {
      await this.upgradeOrganizaton()
    }
    this.upgradingInProgress = false;
  }

  viewChart() {
    this.isChartActive = true
    this.orgTableInfos.forEach(st => st.isActive = false)
  }

  async viewTable(index) {
    this.selectedOrgTableIndex = index
    let orgTableInfo = this.orgTableInfos[index]
    this.orgTableInfos.forEach(f => f.isActive = false)
    orgTableInfo.isActive = true
    this.isChartActive = false
  }

  setNewVersionActive(active: boolean) {
    this.isNewVersionActive = active
    this.displayService.updateQuickTipsData({viewMode: 'view', page:'organization'})
  }

  async saveNewOrgChanges() {
    try{
      this.savingChartInProgress = true
      let newOrgInfo = await this.newOrgIntegrationService.getUpdatedOrganizationalInfo()
      newOrgInfo.diffDeployment(this.depOrg)
      let updated = await this.stateService.updateNewOrgInfo(newOrgInfo)
      this.savingChartInProgress = false
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error)
      if (knownError == this.ErrorsFe.ORG_NOT_SAVED_ERROR) {
        this.alertService.showError(knownError.message)
      }
      this.savingChartInProgress = false
    }
  }

  discardNewOrgChanges() {
    this.newOrgIntegrationService.updateOrganizationInfo(this.newOrg)
  }

  async upgradeOrganizaton() {
    try{
      this.upgradingInProgress = true
      let replaced = this.replaceTempEntityKeys()
      if (replaced) {
        let updated = await this.stateService.updateNewOrgInfo(this.newOrg)  
      } 
      let deployed = await this.stateService.upgradeOrganizationalInfo()
      this.orgTableInfos = []
      this.isChartActive = true
      this.isNewVersionActive = false
      this.upgradingInProgress = false
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error)
      if (knownError == this.ErrorsFe.ORG_NOT_DEPLOYED_ERROR) {
        this.alertService.showError(knownError.message)
      }
      this.upgradingInProgress = false
    }
  }

  replaceTempEntityKeys(): boolean {
    let tempEntities = this.newOrg.entities.filter(e => e.key.startsWith("<TEMP_KEY_"))
    if (tempEntities && tempEntities.length > 0) {
      let keyPairs = tempEntities.map(e => {
        let tempKey = e.key
        let availableKeys = this.newOrg.entities.map(e => e.key)
        e.adjustKey(availableKeys)
        return {tempKey: tempKey, newKey: e.key} 
      })
      this.newOrg.entities.forEach(e => {
        let parentKeys = e.parentKeys.map(pk => {
          let keyPair = keyPairs.find(kp => kp.tempKey == pk)
          return (keyPair) ? keyPair.newKey : pk
        })
        e.parentKeys = parentKeys
      })
      return true
    } else {
      return false
    }
  }

  async saveAndUpgradeChanges(modalTemplateRef: TemplateRef<any>) {
    this.upgradingInProgress = true;
    if(this.newOrgIntegrationService.modified){
      await this.saveNewOrgChanges(); 
    }
    this.openModal(modalTemplateRef);
  }
  changeId(event: any) {
    this.organization = event.target.value
  }
  toggleTips(){
    this.displayService.toggleTips();
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "edit_organisation":
        this.openModal(this.reviseOrganizaionInfoModal);
        this.onReviseOrganizationInfoConfirmed(true);
        break;
      case "back":
        this.setNewVersionActive(false);
        break;
      case "discard":
        this.discardNewOrgChanges();
        break;
      case "save":
        this.saveNewOrgChanges();
        break;
      case "publish":
        this.saveAndUpgradeChanges(this.upgradeOrganizaionInfoModal);
        break;
      case "save_table":
        this.orgTableInfos[this.selectedOrgTableIndex]?.dataGridService.saveChanges();
        break;
        case "toggle_quick_tips":
        this.toggleTips()
        break;
    }
    }


  showToolbarButtons(){
    return this.pageToolbar.some((e:any) => {e.visible == true}) ? true : false;
  }
}
