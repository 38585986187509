<div class="body vw-100 vh-100" style="overflow:hidden;">
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper pt-0 pe-1">
      <div class="content-body text-center align-center">
        <div style="height: 10rem;">
            <div class="loader-container">
              <img class="brand-logo img-responsive img-fluid white my-auto" src="/assets/img/logos/Logo-Sustainlab.png" alt="logo"/>            
              <div class="ball-pulse loader-primary my-3">
                <div style="background-color:var( --main-gradient-start-color)"></div>
                <div style="background-color:var( --main-gradient-start-color)"></div>
                <div style="background-color:var( --main-gradient-start-color)"></div>
              </div>
            <h2 class="">{{locale("locale_key.cache_loading_component.loading_message")}} </h2>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>