import { CompanyFe } from "./CompanyFe";
import { PartnerInvitationFe } from "./PartnerInvitationFe";

export class PartnerInvitationExtendedFe {
  invitation: PartnerInvitationFe;
  primaryContactEmailExist: boolean;

  constructor(primaryContactEmailExist: boolean, invitation: PartnerInvitationFe) {
    this.invitation = invitation;
    this.primaryContactEmailExist = primaryContactEmailExist;
  }

  public static fromTransfer(transfer: any): PartnerInvitationExtendedFe {
    let invitation = PartnerInvitationFe.fromTransfer(transfer.invitation);
    let invitationExtended = new PartnerInvitationExtendedFe(transfer.primaryContactEmailExist, invitation);

    return invitationExtended;
  }
}
