import { Component, OnInit, Input, Output } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe"
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe"
import { QuestionMultipleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe"
import { QuestionSingleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe"
import { TaskFe } from "src/app/model/data-suppliers/task/TaskFe"
import { AnswerAttachment_AddFe } from "src/app/model/data-suppliers/timeline/add/answer/AnswerAttachment_AddFe"
import { AnswerDataTable_AddFe } from "src/app/model/data-suppliers/timeline/add/answer/AnswerDataTable_AddFe"
import { AnswerAttachmentFe } from "src/app/model/data-suppliers/timeline/answer/AnswerAttachmentFe"
import { AnswerDataTableFe } from "src/app/model/data-suppliers/timeline/answer/AnswerDataTableFe"
import { AnswerFe } from "src/app/model/data-suppliers/timeline/answer/AnswerFe"
import { SubmitAnswersActivityFe } from "src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe"
import { AttachmentFe } from "src/app/model/details/AttachmentFe"
import { FileDataFe } from "src/app/model/file/FileDataFe"
import { FileTypeFe } from "src/app/model/file/FileTypeFe"
import { TableDataFe } from "src/app/model/schema/TableDataFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { TableStorageServiceFe } from "src/app/services/TableStorageServiceFe"
import { DateUtil } from "src/app/utils/DateUtil"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { RequestEntryDataGridIntegrationService } from "../data-supplier-request/RequestEntryDataGridIntegrationService"
import { TaskDataGridIntegrationService } from "./TaskDataGridIntegrationService"
import { RequestServiceFe } from "src/app/services/RequestServiceFe"
import { DataGridServiceInterface } from "../../projects/data-grid-ui/service/DataGridServiceInterface"
import { NoUnit } from "../../unit-systems/unit-selector/unit-selector.component"
import { UnitUtilFe } from "src/app/utils/UnitUtilFe"

@Component({
  selector: "data-supplier-view-answer",
  templateUrl: "./data-supplier-view-answer.component.html",
  styleUrls: ["../data-suppliers.component.scss", "../../file/file-portal.component.scss"],
})
export class DataSupplierViewAnswerComponent extends AbstractLanguageComponent implements OnInit {
  
  loadingInfo = false
  loadingInProgress = false
  draftLoadingInProgress = false
  @Input() task: TaskFe
  @Input() answerActivity: SubmitAnswersActivityFe = null
  questions: QuestionFe[]

  constructor(
    public stateService: StateServiceFe,
    public tableStorageService: TableStorageServiceFe,
    private domSanitizer: DomSanitizer, 
    private displayService: DisplayServiceFe,
    languageService: LanguageService,
    public requestService: RequestServiceFe
  ) {
    super(languageService);
  }

  async ngOnInit(): Promise<void> {
    this.renderNewData()
  }

  async renderNewData() {
    this.questions = this.task.questionnaire.getQuestions()
    this.questions.forEach(q => {
      if (q instanceof QuestionDataTableFe) {
        if (!q.newAnswer.dataGridService)
          q.newAnswer.dataGridService = new TaskDataGridIntegrationService(new TableDataFe());
        q.setAnswerTaskDataGridService();
      }
    });
  }

  async setTableDraft(question: QuestionDataTableFe) {
    let answer = question.newAnswer
    answer.isOpenedOnce = true;
    if (!answer.draftTable) {
      this.draftLoadingInProgress = true
      answer.isLoading = true
      let content = await this.stateService.downloadDataTableFile(this.task.managementCompanyId, answer.dataStorageId)
      await this.tableStorageService.convertFileIntoTable(content, async (table: TableDataFe) => {
        answer.draftTable = table;
        answer.dataGridService.table.rows = table.rows;
        answer.dataGridService.table.rowCount = table.rows.length;
        answer.dataGridService.observer?.renderNewDataTable();
        answer.isLoading = false
      })
    } else {
      answer.dataGridService.table.rows = answer.draftTable.rows;
      answer.dataGridService.table.rowCount = answer.draftTable.rows.length;
      answer.dataGridService.observer?.renderNewDataTable();
      answer.isLoading = false
    }
  }

  openAttachment(id: string, dataGridService: DataGridServiceInterface | null, file: FileDataFe | null, submissionDate: Date) {
    let attachment = new AttachmentFe(id, dataGridService, file, submissionDate)
    if (dataGridService) {
      attachment.isTable = true
      attachment.isFile = false
    } else {
      attachment.isFile = true
      attachment.isTable = false
    }
    this.displayService.openAttachment(attachment)
  }

  // Questionnaire functionality
  showMenu(event: any) {
    let id = event.currentTarget.id
    let idNumber = id.split("-")[1]
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    let chevron = menuBtn.children[2]
    menu.classList.toggle("show")
    if (chevron.classList.contains("la-angle-down")) {
      chevron.classList.remove("la-angle-down")
      chevron.classList.add("la-angle-up")
    } else {
      chevron.classList.remove("la-angle-up")
      chevron.classList.add("la-angle-down")
    }
  }

  saveCurrentTableData (ques: QuestionDataTableFe) {
    if (ques.newAnswer.dataGridService.observer) {
      ques.newAnswer.dataGridService.updateTableData()
    }
  }
  
  dateToString(date: any) {
    date = new Date(date)
    return DateUtil.toString3(date)
  }
  
  getAnswerValues (): Map<string, any> {
    let answerValues: Map<string, any> = new Map()
    this.questions.forEach( q => {
      if (q instanceof QuestionMultipleChoiceFe && q.answer && q.answer.selectedOptionIds) {
        q.answer.selectedOptionIds.forEach(optionId => {
          let ref = `${q.id}.${optionId}`
          answerValues.set(ref, true);
        })
      } else if (q instanceof QuestionSingleChoiceFe && q.answer && q.answer.selectedOptionId) {
        let ref = `${q.id}.${q.answer.selectedOptionId}`
        answerValues.set(ref, true);
      }
    })

    return answerValues
  }

  isQuesVisible (ques: QuestionFe) {
    if (ques.followUpCondition) {
      let values = this.getAnswerValues();
      return ques.followUpCondition.evaluate(values)
    } 
    return true
  }

  
  async openTableFile(answer: AnswerDataTable_AddFe | AnswerDataTableFe) {
    if(answer instanceof AnswerDataTableFe){
      answer.isLoading = true;
      let service = new RequestEntryDataGridIntegrationService(new TableDataFe());
      if (!answer.dataTable) {
        let content = await this.stateService.downloadDataTableFile(this.task.managementCompanyId, answer.dataStorageId);
        service.mode = "view";
        await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
          service.table.key = answer.id;
          service.table.rows = table.rows;
          service.table.rowCount = table.rows.length;
          service.table.dataSchema = table.dataSchema;
          answer["dataTable"] = table;
          answer["dataGridService"] = service;
          service.observer?.renderNewDataTable();
          this.openAttachment(answer.id, service, null, this.answerActivity.submissionDate);
        });
      } else {
        this.openAttachment(answer.id, answer.dataGridService, null, this.answerActivity.submissionDate);
      }
      answer.isLoading = false;
    } else if(answer instanceof AnswerDataTable_AddFe){
      answer.isLoading = true;
      this.openAttachment(answer.id, answer.dataGridService, null, this.answerActivity.submissionDate);
      answer.isLoading = false;
    }
  }
  
  async openFile (answer: AnswerFe) {
    if (answer instanceof AnswerAttachmentFe || answer instanceof AnswerAttachment_AddFe) {
      answer.isLoading = true;
      if (!answer.file) {
        let name = answer.attachmentLabel
        let file = await this.downloadFile(answer.attachmentStorageId, name)
        answer.file = file
      }
      let date = this.answerActivity ? this.answerActivity.submissionDate : new Date();
      answer.isLoading = false;
      this.openAttachment(answer.id, null, answer.file, date)
    }
  }

  async downloadFile(fileId: string, fileName: string): Promise<FileDataFe> {    
    let file = FileDataFe.fromBlob(fileName)
    file.loadingFileInProgress = true
    file.id = fileId
    let content = await this.stateService.downloadAttachmentFile(this.task.managementCompanyId, file.id)
    file.addBlobContent(content, this.domSanitizer)
    file.type = FileTypeFe.fromName(fileId)
    file.loadingFileInProgress = false
    return file
  }

  getUnitString(unit) {
    return UnitUtilFe.getUnitString(unit)
  }
}