import { AbstractSignInMethodFe } from "../signInMethod/AbstractSignInMethodFe";
import { SignInMethodConversionFe } from "../signInMethod/SignInMethodConversionFe";
import { AbstractAffiliationFe } from "./AbstractAffiliationFe";

export class AdminFe extends AbstractAffiliationFe {

  constructor(
    affiliationCompanyId: string,
    userId: string,
    userFirstName: string,
    userLastName: string,
    affiliationId: string,
    affiliationRole: string,
    affiliationEmail: string,
    affiliationStatus: string,
    activationDate: Date | null,
    deactivationDate: Date | null,
    lastLoggedAt: Date | null,
    signInMethods: AbstractSignInMethodFe[],
    mfaEnabled: boolean
  ) {
    super (
      affiliationCompanyId,
      userId,
      userFirstName,
      userLastName,
      affiliationId,
      affiliationRole,
      affiliationEmail,
      affiliationStatus,
      activationDate,
      deactivationDate,
      lastLoggedAt,
      signInMethods,
      mfaEnabled
    )
  }

  public static fromTransfer(transfer: any): AdminFe{
    let signInMethods = transfer['signInMethods']?.map(signInMethod => SignInMethodConversionFe.fromTransfer(signInMethod))
    let admin = new AdminFe(
      transfer['affiliationCompanyId'],
      transfer['userId'],
      transfer['userFirstName'],
      transfer['userLastName'],
      transfer['affiliationId'],
      transfer['affiliationRole'],
      transfer['affiliationEmail'],
      transfer['affiliationStatus'],
      transfer['activationDate'],
      transfer['deactivationDate'],
      transfer['lastLoggedAt'],
      signInMethods,
      transfer['mfaEnabled']
    );
    return admin;
  }
}
