export class TextUtils {
  public static capitalizeFirstLetterAllWords(s: string) {
    let words = s.split('_')
    let cWords = words.map(w => this.capitalizeFirstLetterFirstWord(w))
    return cWords.join(' ')
  }

  public static capitalizeFirstLetterFirstWord(s: string) {
    s = s.toLowerCase()
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  public static convert(sentence: string) {
    let sen = sentence + '';
    let words = sen.split(' ')
    let newWords = words.map(word => this.convertWord(word))
    let newSentence = newWords.join(' ')
    return newSentence
  }

  private static convertWord(label: string) {
    if (label.includes('{_}')) {
      let splits = label.split('{_}')
      if (splits.length == 2)
        return splits[0] + this.subUnicode(splits[1])
    } else if (label.includes('{^}')) {
      let splits = label.split('{^}')
      if (splits.length == 2)
        return splits[0] + this.supUnicode(splits[1])
    }
    return label;
  }

  private static subUnicode(label: string) {
    if (label === '0') {
      return '\u2080'
    } else if (label === '1') {
      return '\u2081'
    } else if (label === '2') {
      return '\u2082'
    } else if (label === '3')
      return '\u2083';
    else if (label === '4')
      return '\u2084'
    else if (label === '5')
      return '\u2085'
    else if (label === '6')
      return '\u2086'
    else if (label === '7')
      return '\u2087'
    else if (label === '8')
      return '\u2088'
    else if (label === '9')
      return '\u2089'
    else
      return label
  }

  private static supUnicode(label: string) {
    if (label === '0') {
      return '\u2070'
    } else if (label === '1') {
      return '\u2071'
    } else if (label === '2') {
      return '\u00B2'
    } else if (label === '3')
      return '\u00B3';
    else if (label === '4')
      return '\u2074'
    else if (label === '5')
      return '\u2075'
    else if (label === '6')
      return '\u2076'
    else if (label === '7')
      return '\u2077'
    else if (label === '8')
      return '\u2078'
    else if (label === '9')
      return '\u2079'
    else
      return label
  }

}