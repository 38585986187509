<div class="d-flex flex-column h-100" *ngIf="loadingCompanyData === false">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <!-- <div class="d-flex flex-row justify-content-between"> -->
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <h3>{{ isUserASM ? locale('locale_key.general.analytics.dashboard.upper_case') : (locale('locale_key.pages.dashboard.shared.title') | uppercase) }}</h3>
        <div class="btn-group ms-4 ms-auto" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
      <!-- dropdown menu instead of toolbar for smaller pages-->
      <!-- </div> -->
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group" *ngIf="!inProgress">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper" *ngIf="inProgress">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column h-100 ps-1 mb-5 pb-5 me-1" style="height: 100vh !important; overflow-y: auto !important; overflow-x: auto !important; min-width: 100%" id="chartsContainer" #chartsContainer>
          <div class="" *ngIf="sharedDashboards.length > 0 || dashboards.length > 0">
            <div class="mb-2">
              <a
                class="btn-block border py-1 d-flex flex-row justify-content-betwen dark-text dd-dash text-truncate px-1 bg-white"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="{{ selectedDashboard['recipients'] && selectedDashboard['recipients'].length > 0 ? selectedDashboard.name + ' is shared with ' + selectedDashboard['recipients'].length + ' users' : selectedDashboard.name }}"
                (click)="slideOpen = !slideOpen"
                data-bs-toggle="collapse"
                href="#dashboardCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="dashboardCollapse"
              >
                <span class="ms-1 mt-2" style="width: 50%" *ngIf="(selectedDashboard['recipients'] && selectedDashboard['recipients'].length > 0) || isSelectedDashboardSharedDashboard()"
                  ><h4 class="text-truncate fs-6">{{ selectedDashboard.name }}</h4></span
                >
                <span class="ms-1 mt-2 me-auto" style="max-width: 100%" *ngIf="!isSelectedDashboardSharedDashboard() && selectedDashboard['recipients'].length == 0"
                  ><h4 class="text-truncate">{{ selectedDashboard.name }}</h4></span
                >
                <ng-container *ngIf="isSelectedDashboardSharedDashboard()">
                  <span class="border-r my-1 mx-1"></span>
                  <div class="d-flex flex-row justify-content-center ms-1 align-items-center" style="width: 43%">
                    <div class="text-start ps-0 col-1 px-0">
                      <div class="icon-circle m-0"><i class="las la-user text-white"></i></div>
                    </div>
                    <span class="px-1 col-5 ms-2">
                      <h6 class="m-0 text-truncate">{{ selectedDashboard["sharingPersonFirstName"] }} {{ selectedDashboard["sharingPersonLastName"] }}</h6>
                      <small class="m-0 text-muted">{{ selectedDashboard["sharingPersonCompanyName"] }}</small>
                    </span>
                    <p class="text-start m-0 p-0">
                      <span class="badge badge-info text-white ms-0">
                        {{ selectedDashboard["accessType"] == "VIEW_ONLY" ? "View only" : "View & Export" }}
                      </span>
                    </p>
                  </div>
                </ng-container>
                <ng-container *ngIf="!isSelectedDashboardSharedDashboard()">
                  <span class="border-r my-3" *ngIf="selectedDashboard['recipients'].length > 0"></span>
                  <div class="me-auto me-2 d-flex flex-row justify-content-center align-content-center my-auto" *ngIf="selectedDashboard['recipients'].length > 0">
                    <h5>
                      <i class="la la-users font-large-1 ml-1" style="position: relative; top: 2px"></i>
                      {{ locale('locale_key.pages.dashboard.shared_with_users.title', { no: selectedDashboard["recipients"].length }) }}
                    </h5>
                  </div>
                </ng-container>
                <i class="la la-angle-down pe-0 my-auto" [ngClass]="slideOpen ? 'la-angle-up' : 'la-angle-down'"></i>
              </a>
              <div class="row" style="position: fixed; z-index: 999">
                <div class="col">
                  <div class="collapse multi-collapse dd-dash" id="dashboardCollapse">
                    <div class="card card-body m-0 p-1">
                      <div class="nav nav-underline nav-tabs px-1">
                        <ul class="nav nav-underline nav-tabs no-hover-bg nav-justified" style="width: 100%; border-bottom: none !important">
                          <li class="nav-item" *ngIf="dashboards.length > 0">
                            <a class="nav-link active tab1" id="baseIcon-tab" data-bs-toggle="tab" aria-controls="tabIcon-1" href="#tabIcon-1" aria-expanded="true"
                              ><h5>{{ locale('locale_key.pages.dashboard.my_dashboards.title') }}</h5>
                            </a>
                          </li>
                          <li class="nav-item" *ngIf="sharedDashboards.length > 0">
                            <a class="nav-link tab2" [ngClass]="{ active: isSelectedDashboardSharedDashboard() }" id="baseIcon-tab-2" data-bs-toggle="tab" aria-controls="tabIcon-2" href="#tabIcon-2" aria-expanded="false">
                              <h5>{{ locale('locale_key.pages.dashboard.shared_with_you.title') }}</h5></a
                            >
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content px-0 h-100 vertical-scroll mh-300">
                        <div role="tabpanel" class="tab-pane active" id="tabIcon-1" aria-expanded="true" aria-labelledby="baseIcon-tab">
                          <ul>
                            <ng-container *ngFor="let dashboard of dashboards">
                              <li class="nav-item thin-border-b" *ngIf="dashboard != selectedDashboard">
                                <a
                                  class="nav-link btn-block d-flex flex-row justify-content-betwen dark-text px-1"
                                  (click)="selectDashboard(dashboard); slideOpen = !slideOpen"
                                  data-bs-toggle="collapse"
                                  href="#dashboardCollapse"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="dashboardCollapse"
                                >
                                  <span class="me-auto ms-1 mt-2" [ngStyle]="{ 'max-width': dashboard.recipients.length > 0 ? '50%' : '100%' }" style="min-width: 50%"
                                    ><h5>{{ dashboard.name }}</h5></span
                                  >
                                  <span class="border-r my-1" *ngIf="dashboard.recipients.length > 0"></span>
                                  <div class="me-auto ms-3 d-flex flex-row justify-content-center align-content-center my-auto" *ngIf="dashboard.recipients.length > 0">
                                    <h5>
                                      <i class="la la-users dark-text font-large-1 me-2" style="position: relative; top: 2px"></i>
                                      {{ locale('locale_key.pages.dashboard.shared_with_users.title', { no: dashboard["recipients"].length }) }}
                                    </h5>
                                  </div>
                                </a>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                        <div class="tab-pane" id="tabIcon-2" [ngClass]="{ active: isSelectedDashboardSharedDashboard() }" aria-labelledby="baseIcon-tab-2">
                          <ul>
                            <ng-container *ngFor="let sharedDashboard of sharedDashboards">
                              <li class="nav-item thin-border-b" *ngIf="sharedDashboard != selectedDashboard">
                                <a
                                  class="nav-link btn-block d-flex flex-row justify-content-betwen px-1 dark-text"
                                  (click)="selectSharedDashboard(sharedDashboard); slideOpen = !slideOpen"
                                  data-bs-toggle="collapse"
                                  href="#dashboardCollapse"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="dashboardCollapse"
                                >
                                  <div class="row mx-0 px-0 border-r" style="width: 50%; max-width: 50%">
                                    <span class="me-auto ms-1 mt-3">{{ sharedDashboard.name }}</span>
                                  </div>
                                  <div class="d-flex flex-row justify-content-center align-items-center me-auto ms-2">
                                    <div class="text-start ps-0">
                                      <div class="icon-circle me-auto"><i class="las la-user text-white"></i></div>
                                    </div>
                                    <span class="px-2">
                                      <h6 class="m-0">{{ sharedDashboard.sharingPersonFirstName }} {{ sharedDashboard.sharingPersonLastName }}</h6>
                                      <small class="m-0 text-muted">{{ sharedDashboard.sharingPersonCompanyName }}</small>
                                    </span>
                                    <p class="text-start m-0">
                                      <span class="badge badge-info text-white ms-0">
                                        {{ sharedDashboard.accessType == "VIEW_ONLY" ? "View only" : "View & Export" }}
                                      </span>
                                    </p>
                                  </div>
                                </a>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section #dashboardEl class="d-flex flex-row mb-5 pb-5 ps-0" dragula="CHARTS" [(dragulaModel)]="chartWrappers" id="custom-box">
            <ng-container *ngFor="let wrapper of chartWrappers; let i = index">
              <div class="flex-grow-150 px-0 pe-2" id="item-{{ i }}" *ngIf="wrapper.hasError">
                <div class="isResizable d-flex flex-row">
                  <div class="card hoverable p-0 m-0 mb-2">
                    <div class="card-header p-0">
                      <div class="cart-title">{{ locale('locale_key.pages.dashboard.error.issues_in_loading_chart') }}</div>
                    </div>
                    <div class="card-body">
                      <h5>{{ locale('locale_key.pages.dashboard.error.messages.title') }}</h5>
                      <ul>
                        <li *ngFor="let error of wrapper.errors">{{ error }}</li>
                      </ul>
                      <div id="parent-canvas{{ i }}" class="card-body width-90-per p-0" #canvascontainer>
                        <canvas id="canvas{{ i }}" width="0" height="0" #canvas></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-grow-{{ wrapper.setting.width }} px-0 pe-2" id="item-{{ i }}" *ngIf="!wrapper.hasError">
                <div id="accordionWrapa{{ i }}" role="tablist" aria-multiselectable="true">
                  <div class="card hoverable p-0 m-0 mb-2">
                    <div class="card-body d-flex flex-column p-1" style="background-color: #ffffff">
                      <div class="desc text-truncate p-2" [ngStyle]="{ width: wrapper.setting.newWidth + 'px' }">
                        <div>
                          <b class="font-medium-1">{{ wrapper.setting.titleOption == "kpi" ? wrapper.insight.title : wrapper.setting.selectTitle() }}</b>
                        </div>
                        <div>
                          <a class="font-small-3">{{ locale('locale_key.pages.dashboard.chart_settings.compare_by') }}</a>
                          <div class="badge px-2 badge-gray">{{ wrapper.setting.principalComparisionText() }}</div>
                          <span class="ms-2" *ngIf="wrapper.insight.isTimed()">
                            <span class="font-small-3">{{ locale('locale_key.insight-detail.time_options.' + wrapper.setting.timeOption()?.timeScaleToAdverb().toLowerCase()) }}</span>
                            <div class="badge px-2 badge-gray" *ngIf="wrapper?.setting.principalCategoryFieldIndex > -1">{{ wrapper?.setting.timeValue }}</div>
                          </span>

                          <span *ngFor="let categoryOption of wrapper.setting.nonPricipalCategoryOptions()">
                            <a class="ms-3 font-small-3">{{ categoryOption.getLabel() }}</a>
                            <div class="badge px-2 badge-gray">{{ wrapper.setting.categoryValue(categoryOption.field) }}</div>
                          </span>
                        </div>
                      </div>
                      <div class="isResizable d-flex flex-row" (resized)="chartResized($event, wrapper)" [style.max-width.px]="containerWidth">
                        <div class="card-body p-0" #canvascontainer [style.width.px]="getChartWidth(wrapper)" [style.max-width.px]="containerWidth - 20">
                          <canvas id="canvas{{ i }}" width="{{ wrapper.setting.width }}" [hidden]="wrapper.setting.chartType.id.startsWith('table')" height="{{ wrapper.setting.height }}" #canvas></canvas>
                          <datagrid-table-editor [dataGrid]="wrapper.insight.kpiDataGridService" *ngIf="wrapper.setting.chartType.id.startsWith('table')"></datagrid-table-editor>
                        </div>
                        <div class="list-inline-item d-flex flex-column px-0 me-0">
                          <ul class="list-inline m-0 p-0">
                            <button type="button" class="btn btn-icon p-0" (click)="updateDetailsData(i, wrapper)" data-bs-toggle="modal" data-target="#slideInRight1">
                              <i id="cog{{ i }}" class="las la-cog fs-4"></i>
                            </button>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </section>
          <div class="row" style="height: 1rem"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #duplicateDashboardModal>
  <dashboard-detail [originalDashboard]="selectedDashboard" (refreshView)="refreshDashboard($event)" (closeModalEvent)="closeModal($event)"> </dashboard-detail>
</ng-template>

<ng-template #createDashboardModal>
  <dashboard-detail (closeModalEvent)="closeModal($event)"></dashboard-detail>
</ng-template>

<ng-template #editDashboardModal>
  <dashboard-detail [edit]="true" [dashboard]="selectedDashboard" (closeModalEvent)="closeModal($event)"> </dashboard-detail>
</ng-template>

<ng-template #addKpiDashboardModal>
  <dashboard-detail [kpi]="true" [dashboard]="selectedDashboard" (refreshView)="refreshDashboard($event)" (closeModalEvent)="closeModal($event)"> </dashboard-detail>
</ng-template>

<ng-template #exportDashboard>
  <div class="main-modal-header modal-header py-0 ps-3 pe-0">
    <h4 class="modal-title text-white text-truncate">{{ locale('locale_key.general.toolbar.button.export') }} {{ selectedDashboard.name }}</h4>
    <button type="button" class="btn close" (click)="closeModal(true)" *ngIf="!inProgress">
      <span aria-hidden="true" style="font-size: 2rem; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="accordionMessage" class="border-shade">
      <h5>{{ locale('locale_key.pages.dashboard.export_modal.desc') }}</h5>
      <div class="row mt-4">
        <div class="custom-radio col-lg-6 col-md-6 col-sm-6 col-12 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-pdf-o"></i>
            <i class="las la-download"></i>
            <i class="ft-grid"></i>
          </div>
          <span>
            <input class="form-check-input" type="radio" name="typeOfExport" id="layoutPDF" value="layoutPDF" [(ngModel)]="typeOfExport" />
            <label class="form-check-label" for="layoutPDF">{{ locale('locale_key.pages.dashboard.export_modal.options.pdf') }}</label>
          </span>
        </div>
        <div class="custom-radio col-lg-6 col-md-6 col-sm-6 col-12 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-pdf-o"></i>
            <i class="las la-download"></i>
            <i class="ft-server"></i>
          </div>
          <span>
            <input class="form-check-input" type="radio" name="typeOfExport" id="nonLayoutPDF" value="nonLayoutPDF" [(ngModel)]="typeOfExport" />
            <label class="form-check-label" for="nonLayoutPDF">{{ locale('locale_key.pages.dashboard.export_modal.options.pdf_with_kpi') }}</label>
          </span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="custom-radio col-12 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-image-o"></i>
            <i class="las la-download"></i>
            <i class="ft-grid"></i>
          </div>
          <span>
            <input class="form-check-input" type="radio" name="typeOfExport" id="img" value="img" [(ngModel)]="typeOfExport" />
            <label class="form-check-label" for="img">{{ locale('locale_key.pages.dashboard.export_modal.options.img') }}</label>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="ball-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!inProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal(true)">{{ locale('locale_key.pages.dashboard.export_modal.button.cancel') }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="exportDashobard()">{{ locale('locale_key.pages.dashboard.export_modal.button.export') }}</button>
    </ng-container>
  </div>
</ng-template>

<ng-template #deleteDashboardModal>
  <div class="modal-header justify-content-start" style="border-bottom: none">
    <div class="icon-background d-inline" style="height: 18px; width: 18px; background-color: #c65c5c; border-radius: 50%; margin-top: 0.43rem">
      <!-- <i class="las la-times"></i> -->
      <i class="las la-times fs-5 text-white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 0px; top: -1px"></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 my-auto">{{ locale('locale_key.delete_confirmation_modal.header', { object_name: selectedDashboard?.name }) }}</h4>
    <button type="button" class="btn close ms-auto" (click)="closeModal(true)">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale('locale_key.delete_confirmation_modal.message', { object_name: selectedDashboard?.name }) }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!inProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal(true)">{{ locale('locale_key.delete_confirmation_modal.button.cancel') }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="deleteDashboard()">{{ locale('locale_key.confirmation_modal.button.confirm') }}</button>
    </ng-container>
  </div>
</ng-template>

<ng-template #shareDashboardModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h4 class="modal-title text-white" id="myModalLabel9">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.title', { name: selectedDashboard?.name }) }}</h4>
    <button type="button" class="btn close" (click)="closeModal(true)" *ngIf="!inProgress">
      <span aria-hidden="true" class="text-white"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body overflow-y pe-0">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <h4>{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.people_with_access.title') }}</h4>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 text-end">
        <div class="d-flex px-3" id="accordion">
          <h4 class="pe-2" [ngClass]="screenSize.isSmallerSize() ? 'text-start me-auto' : 'text-end ms-auto'" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapseOne">
            {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.share_view_access.title') }}
            <i class="la la-info-circle dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
            <div class="dropdown-menu arrow p-2 info-popover">
              <p>{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.share_view_access.info') }}</p>
            </div>
          </h4>
        </div>
      </div>
    </div>
    <div class="row overflow-y pt-2">
      <div class="mx-0 pb-3 col-12">
        <div class="row mx-0 px-0" [formGroup]="recipientsForm">
          <div class="invalid-feedback d-block" *ngIf="recipientsForm.errors && recipientsForm.errors.noRecipients">
            {{ recipientsMessage }}
          </div>
          <div class="invalid-feedback d-block" *ngIf="recipientsForm.errors && recipientsForm.errors.sameEmail">
            {{ sameEmailMessage }}
          </div>
          <div class="col-12 d-none d-md-block" *ngIf="!screenSize.isMediumSize() || !screenSize.isSmallerSize()">
            <div class="col-12 row px-0" *ngIf="!(recipientsForm.errors && recipientsForm.errors.noRecipients)">
              <div class="col-2 mx-0 px-1">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name_column.title') }}</div>
              <div class="col-2 mx-0 px-1">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name_column.title') }}</div>
              <div class="col-2 mx-0 px-1">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name_column.title') }}</div>
              <div class="col-2 mx-0 px-1">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email_column.title') }}</div>
              <div class="col-2 mx-0 px-1">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.permission_column.title') }}</div>
              <div class="col-2 mx-0 px-1">
                <div class="d-flex" id="accordion">
                  <div class="pe-2 text-start me-auto" data-bs-toggle="collapse" data-bs-parent="#accordion" href="#collapseOne">
                    {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.email_language.column') }}
                    <i class="la la-info-circle dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                    <div class="dropdown-menu arrow p-2 info-popover">
                      <p>{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.share_view_access.info') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 mx-0 px-1 d-flex flex-row justify-content-start align-items-center me-auto" *ngIf="selectedDashboard['sharedId']"></div>
            </div>
          </div>
          <div formArrayName="existing" class="col-12 d-none d-md-block" *ngIf="!screenSize.isMediumSize() || !screenSize.isMediumSize() || !screenSize.isSmallerSize()">
            <div class="col-12 row px-0 pb-1 pt-2" *ngFor="let recipient of selectedDashboard['recipients']; let i = index" [formGroupName]="i">
              <div class="col-2 mx-0 px-1">
                <input type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name.placeholder') }}" formControlName="firstName" />
              </div>
              <div class="col-2 mx-0 px-1">
                <input type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name.placeholder') }}" formControlName="lastName" />
              </div>
              <div class="col-2 mx-0 px-1">
                <input type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name.placeholder') }}" formControlName="companyName" />
              </div>
              <div class="col-2 mx-0 px-1">
                <input type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email.placeholder') }}" formControlName="email" />
              </div>
              <div class="col-2 mx-0 px-1">
                <select class="form-control" formControlName="type">
                  <option value="VIEW_ONLY">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_only') }}</option>
                  <option value="VIEW_AND_EXPORT">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_with_export_data') }}</option>
                </select>
              </div>
              <div class="col-2 mx-0 px-1">
                <div class="d-flex flex-row align-items-center">
                  <div class="col-10">
                    <div id="lang-dropdown" class="btn-group me-3 mb-1 w-100 border">
                      <button class="btn bg-white p-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" disabled="true">
                        <span *ngIf="this.existingEmailLangs[i]"><i class="{{ this.existingEmailLangs[i].icon }} me-2"></i>{{ this.existingEmailLangs[i].label }}</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-2 text-center">
                    <span (click)="recipient.confirmRemoval = true" *ngIf="!recipient.confirmRemoval"><i class="la la-trash"></i></span>
                  </div>
                </div>
              </div>
              <div *ngIf="recipient.confirmRemoval" class="mx-0 px-2 pt-2 col-12 d-flex flex-row justify-content-end align-items-center">
                <span class="px-2 red">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.confirm_remove.title') }}</span>
                <div class="ball-pulse loader-primary" *ngIf="inProgress">
                  <div style="background-color: #405369"></div>
                  <div style="background-color: #405369"></div>
                  <div style="background-color: #405369"></div>
                </div>
                <ng-container *ngIf="!inProgress">
                  <button class="btn secondary-button custom-button" (click)="recipient.confirmRemoval = false">{{ locale('locale_key.general.buttons.cancel') }}</button>
                  <button class="btn primary-button custom-button ms-2 me-0" (click)="removeRecipient(recipient, i)">{{ locale('locale_key.general.buttons.remove') }}</button>
                </ng-container>
              </div>
            </div>
          </div>
          <ng-container *ngIf="screenSize.isSmallerSize()">
            <div formArrayName="existing" class="col-12">
              <div class="col-12 d-flex flex-column px-0 pb-1 pt-2" *ngFor="let recipient of selectedDashboard['recipients']; let i = index" [formGroupName]="i">
                <div class="col-12 mx-0 px-1">
                  <label for="firstname">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name_column.title') }}</label>
                  <input id="firstname" type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name.placeholder') }}" formControlName="firstName" />
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="lastname">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name_column.title') }}</label>
                  <input id="lastname" type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name.placeholder') }}" formControlName="lastName" />
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="companyname">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name_column.title') }}</label>
                  <input id="companyname" type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name.placeholder') }}" formControlName="companyName" />
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="email">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email_column.title') }}</label>
                  <input id="email" type="text" class="form-control" placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email.placeholder') }}" formControlName="email" />
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="permission">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.permission_column.title') }}</label>
                  <select class="form-control" formControlName="type">
                    <option value="VIEW_ONLY">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_only') }}</option>
                    <option value="VIEW_AND_EXPORT">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_with_export_data') }}</option>
                  </select>
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="language">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.email_language.label') }}</label>
                </div>
                <div class="col-12 mx-0 px-1">
                  <div id="lang-dropdown" class="btn-group me-3 mb-1">
                    <button class="btn bg-white p-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" disabled="true">
                      <span *ngIf="this.existingEmailLangs[i]"><i class="{{ this.existingEmailLangs[i].icon }} me-2"></i>{{ this.existingEmailLangs[i].label }}</span>
                    </button>
                    <div class="col-12 mx-0 p-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" (change)="onIncludeEnChange(i, true, $event)" [disabled]="getDisabledEnglish(i)" [checked]="getIncludeEnglish(i)" />
                        <label class="form-check-label ml-2" style="font-size: 12px"> {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.include_en_translation') }} </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1 mx-0 px-1 d-flex flex-row justify-content-start align-items-center">
                  <span (click)="recipient.confirmRemoval = true" *ngIf="!recipient.confirmRemoval"><i class="la la-trash"></i></span>
                </div>
                <div *ngIf="recipient.confirmRemoval" class="mx-0 px-2 pt-2 col-12 d-flex flex-row justify-content-end align-items-center">
                  <span class="px-2 red">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.confirm_remove.title') }}</span>
                  <div class="ball-pulse loader-primary" *ngIf="inProgress">
                    <div style="background-color: #405369"></div>
                    <div style="background-color: #405369"></div>
                    <div style="background-color: #405369"></div>
                  </div>
                  <ng-container *ngIf="!inProgress">
                    <button class="btn secondary-button custom-button" (click)="recipient.confirmRemoval = false">{{ locale('locale_key.general.buttons.cancel') }}</button>
                    <button class="btn primary-button custom-button ms-2 me-0" (click)="removeRecipient(recipient, i)">{{ locale('locale_key.general.buttons.remove') }}</button>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <div formArrayName="users" class="col-12 d-none d-md-block" *ngIf="!screenSize.isMediumSize() || !screenSize.isMediumSize() || !screenSize.isSmallerSize()">
            <div class="col-12 row px-0 pb-1 pt-2 bg-light" *ngFor="let newRecipient of recipientsForm.get('users')['controls']; let i = index" [formGroupName]="i">
              <div class="col-2 mx-0 px-1">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name.placeholder') }}"
                  formControlName="firstName"
                  [ngClass]="{ 'is-invalid': newRecipient.get('firstName').errors && newRecipient.get('firstName').touched }"
                />
                <div *ngIf="newRecipient.get('firstName').errors && newRecipient.get('firstName').touched" class="invalid-feedback">
                  <div *ngIf="newRecipient.get('firstName').errors.required">
                    {{ firstNameMessage }}
                  </div>
                  <div *ngIf="newRecipient.get('firstName').hasError('pattern')">
                    {{ firstNamePatternMessage }}
                  </div>
                </div>
              </div>
              <div class="col-2 mx-0 px-1">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name.placeholder') }}"
                  formControlName="lastName"
                  [ngClass]="{ 'is-invalid': newRecipient.get('lastName').errors && newRecipient.get('lastName').touched }"
                />
                <div *ngIf="newRecipient.get('lastName').errors && newRecipient.get('lastName').touched" class="invalid-feedback">
                  <div *ngIf="newRecipient.get('lastName').errors.required">
                    {{ lastNameMessage }}
                  </div>
                  <div *ngIf="newRecipient.get('lastName').hasError('pattern')">
                    {{ lastNamePatternMessage }}
                  </div>
                </div>
              </div>
              <div class="col-2 mx-0 px-1">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name.placeholder') }}"
                  formControlName="companyName"
                  [ngClass]="{ 'is-invalid': newRecipient.get('companyName').errors && newRecipient.get('companyName').touched }"
                />
                <div *ngIf="newRecipient.get('companyName').errors && newRecipient.get('companyName').touched" class="invalid-feedback">
                  <div *ngIf="newRecipient.get('companyName').errors.required">
                    {{ companyNameMessage }}
                  </div>
                </div>
              </div>
              <div class="col-2 mx-0 px-1">
                <input
                  type="text"
                  class="form-control"
                  placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email.placeholder') }}"
                  formControlName="email"
                  [ngClass]="{ 'is-invalid': newRecipient.get('email').errors && newRecipient.get('email').touched }"
                />
                <div *ngIf="newRecipient.get('email').errors && newRecipient.get('email').touched" class="invalid-feedback">
                  <div *ngIf="newRecipient.get('email').errors.required">
                    {{ emailMessage }}
                  </div>
                  <div *ngIf="newRecipient.get('email').hasError('pattern')">
                    {{ lowerCaseEmail }}
                  </div>
                </div>
              </div>
              <div class="col-2 mx-0 px-1">
                <select class="form-control" formControlName="type">
                  <option value="VIEW_ONLY">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_only') }}</option>
                  <option value="VIEW_AND_EXPORT">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_with_export_data') }}</option>
                </select>
              </div>
              <div class="col-2 mx-0 px-1">
                <div class="d-flex flex-row align-items-center">
                  <div class="col-10">
                    <div id="lang-dropdown" class="btn-group me-3 mb-1 w-100 border">
                      <button class="btn dropdown-toggle form-select bg-white p-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        <span *ngIf="getEmailLanguage(i)"><i class="{{ getEmailLanguage(i).icon }} me-2"></i>{{ getEmailLanguage(i).label }}</span>
                      </button>
                      <div class="dropdown-menu">
                        <ng-container *ngFor="let language of languages" class="form-control">
                          <a class="dropdown-item m-0 px-3" style="color: var(--main-dark-color); cursor: pointer" *ngIf="getEmailLanguage(i)?.code != language.code" (click)="updateEmailLanguage(i, language)"
                            ><i class="{{ language.icon }} me-2"></i> {{ language.label }}
                          </a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-center">
                    <span (click)="removeUserRecipient(i)"><i class="la la-trash cursor mt-1 ms-auto"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-12 mx-0 p-2">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" (change)="onIncludeEnChange(i, false, $event)" [disabled]="getDisabledEnglish(i)" [checked]="getIncludeEnglish(i)" />
                  <label class="form-check-label ml-2" style="font-size: 12px"> {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.include_en_translation') }} </label>
                </div>
              </div>
              <!-- <div class="col-1 mx-0 px-1"></div> -->
            </div>
          </div>
          <ng-container *ngIf="screenSize.isSmallerSize()">
            <div formArrayName="users" class="col-12">
              <div class="col-12 d-flex flex-column px-0 pb-1 pt-2 bg-light" *ngFor="let newRecipient of recipientsForm.get('users')['controls']; let i = index" [formGroupName]="i">
                <div class="col-12 mx-0 px-1">
                  <label for="firstname">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name_column.title') }}</label>
                  <input
                    id="firstname"
                    type="text"
                    class="form-control w-100"
                    placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_first_name.placeholder') }}"
                    formControlName="firstName"
                    [ngClass]="{ 'is-invalid': newRecipient.get('firstName').errors && newRecipient.get('firstName').touched }"
                  />
                  <div *ngIf="newRecipient.get('firstName').errors && newRecipient.get('firstName').touched" class="invalid-feedback">
                    <div *ngIf="newRecipient.get('firstName').errors.required">
                      {{ firstNameMessage }}
                    </div>
                    <div *ngIf="newRecipient.get('firstName').hasError('pattern')">
                      {{ firstNamePatternMessage }}
                    </div>
                  </div>
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="lastname">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name_column.title') }}</label>
                  <input
                    id="lastname"
                    type="text"
                    class="form-control w-100"
                    placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_last_name.placeholder') }}"
                    formControlName="lastName"
                    [ngClass]="{ 'is-invalid': newRecipient.get('lastName').errors && newRecipient.get('lastName').touched }"
                  />
                  <div *ngIf="newRecipient.get('lastName').errors && newRecipient.get('lastName').touched" class="invalid-feedback">
                    <div *ngIf="newRecipient.get('lastName').errors.required">
                      {{ lastNameMessage }}
                    </div>
                    <div *ngIf="newRecipient.get('lastName').hasError('pattern')">
                      {{ lastNamePatternMessage }}
                    </div>
                  </div>
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="companyname">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name_column.title') }}</label>
                  <input
                    id="companyname"
                    type="text"
                    class="form-control w-100"
                    placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_company_name.placeholder') }}"
                    formControlName="companyName"
                    [ngClass]="{ 'is-invalid': newRecipient.get('companyName').errors && newRecipient.get('companyName').touched }"
                  />
                  <div *ngIf="newRecipient.get('companyName').errors && newRecipient.get('companyName').touched" class="invalid-feedback">
                    <div *ngIf="newRecipient.get('companyName').errors.required">
                      {{ companyNameMessage }}
                    </div>
                  </div>
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="email">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email_column.title') }}</label>
                  <input
                    id="email"
                    type="text"
                    class="form-control w-100"
                    placeholder="{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.recipient_email.placeholder') }}"
                    formControlName="email"
                    [ngClass]="{ 'is-invalid': newRecipient.get('email').errors && newRecipient.get('email').touched }"
                  />
                  <div *ngIf="newRecipient.get('email').errors && newRecipient.get('email').touched" class="invalid-feedback">
                    <div *ngIf="newRecipient.get('email').errors.required">
                      {{ emailMessage }}
                    </div>
                    <div *ngIf="newRecipient.get('email').hasError('pattern')">
                      {{ lowerCaseEmail }}
                    </div>
                  </div>
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="permission">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.permission_column.title') }}</label>
                  <select id="permission" class="form-control w-100" formControlName="type">
                    <option value="VIEW_ONLY">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_only') }}</option>
                    <option value="VIEW_AND_EXPORT">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.perimission_type.view_with_export_data') }}</option>
                  </select>
                </div>
                <div class="col-12 mx-0 px-1">
                  <label for="language">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.email_language.label') }}</label>
                </div>
                <div class="col-12 mx-0 px-1">
                  <div id="lang-dropdown" class="btn-group me-3 mb-1 w-100">
                    <button class="btn dropdown-toggle form-select bg-white p-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span *ngIf="getEmailLanguage(i)"><i class="{{ getEmailLanguage(i).icon }} me-2"></i>{{ getEmailLanguage(i).label }}</span>
                    </button>
                    <div class="dropdown-menu">
                      <ng-container *ngFor="let language of languages">
                        <a class="dropdown-item m-0 px-3" style="color: var(--main-dark-color); cursor: pointer" *ngIf="getEmailLanguage(i)?.code != language.code" (click)="updateEmailLanguage(i, language)"
                          ><i class="{{ language.icon }} me-2"></i> {{ language.label }}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-12 mx-0 p-1">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" (change)="onIncludeEnChange(i, false, $event)" [disabled]="getDisabledEnglish(i)" [checked]="getIncludeEnglish(i)" />
                    <label class="form-check-label ml-2" style="font-size: 12px"> {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.include_en_translation') }} </label>
                  </div>
                </div>
                <div class="col-12 mx-0 px-1">
                  <span (click)="removeUserRecipient(i)"><i class="la la-trash cursor mt-1 ms-auto"></i></span>
                </div>
                <!-- <div class="col-1 mx-0 px-1"></div> -->
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div id="select" class="ps-0 ms-0 w-100 d-flex justify-content-between" [ngClass]="screenSize.isSmallerSize() ? 'flex-column' : 'flex-row'">
        <div class="">
          <button id="btn-menu" class="btn btn-light my-3 dropdown black" [ngClass]="screenSize.isSmallerSize() ? 'ms-1 w-100' : 'ms-3'" (click)="addNewUserRecipient()">
            <i class="las la-plus me-2"></i>{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.button.add_additional_person') }}
          </button>
          <button id="btn-menu" class="btn btn-light my-3 dropdown black" [ngClass]="screenSize.isSmallerSize() ? 'ms-1 w-100' : 'ms-3'" (click)="showUserList()">
            <i class="la la-user-plus me-2"></i>{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.button.add_from_contact_list') }}
          </button>
        </div>
        <div class="">
          <div class="me-4" *ngIf="selectedDashboard['sharedId']">
            <button
              class="btn btn-light my-3 dropdown black"
              [ngClass]="screenSize.isSmallerSize() ? 'ms-1 w-100' : 'ms-3'"
              (click)="selectedDashboard['confirmRemoval'] = true"
              (click)="selectedDashboard['confirmRemoval'] = true"
              *ngIf="!selectedDashboard['confirmRemoval']"
            >
              {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.button.remove_all_access') }}
            </button>
          </div>
          <div *ngIf="selectedDashboard['confirmRemoval']" class="d-flex flex-row justify-content-end me-2 mt-3">
            <span class="px-2 red">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.remove_everyone.title') }}</span>
            <div class="ball-pulse loader-primary" *ngIf="inProgress">
              <div style="background-color: #405369"></div>
              <div style="background-color: #405369"></div>
              <div style="background-color: #405369"></div>
            </div>
            <ng-container *ngIf="!inProgress">
              <button class="btn secondary-button custom-button" (click)="selectedDashboard['confirmRemoval'] = false">{{ locale('locale_key.general.buttons.cancel') }}</button>
              <button class="btn primary-button custom-button mx-2" (click)="removeSharedDashboard()">{{ locale('locale_key.general.buttons.remove') }}</button>
            </ng-container>
          </div>
        </div>
      </div>
      <div id="user-list" class="row ms-2 w-100 hide">
        <div class="p-1 mb-auto" [ngClass]="screenSize.isSmallerSize() ? 'col-12' : 'col-8'">
          <div class="row ps-3">
            <div class="d-flex flex-column justify-content-end ms-0 ps-0" [ngClass]="screenSize.isXSmallSize() ? 'col-12' : 'col-7'">
              <h4 class="mb-1 pb-0 text-start ms-0 ps-0">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.select_from_contact_list.title') }}</h4>
            </div>
            <div class="text-end me-0 px-0" [ngClass]="screenSize.isXSmallSize() ? 'col-12' : 'col-4'">
              <input class="form-control me-0 mb-2" [ngClass]="{ 'w-75': screenSize.isXSmallSize() }" [(ngModel)]="filterText" (input)="filterRows()" type="search" placeholder="Search for data reporters" />
            </div>
            <div class="col-11 mx-0 px-0" style="max-height: 200px; overflow-y: auto">
              <div class="d-flex flex-row justify-content-center btn-add text-white align-items-center mb-25 pt-2">
                <div class="col-1">
                  <label><input class="mt-50 ml-1" type="checkbox" (change)="selectAllDataOwners($event)" /></label>
                </div>
                <div class="col-4">
                  <h5 class="text-white">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.name_column.title') }}</h5>
                </div>
                <div class="col-3">
                  <h5 class="text-white">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.email_column.title') }}</h5>
                </div>
                <div class="col-3">
                  <h5 class="text-white">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.company_column.title') }}</h5>
                </div>
              </div>
              <ng-container *ngFor="let contact of filteredContacts; let i = index">
                <div class="d-flex flex-row justify-content-center border-shade mb-1 align-items-center thin-border-b">
                  <div class="col-1">
                    <label><input class="mt-2 ms-3" type="checkbox" [(ngModel)]="contact.isSelected" /></label>
                  </div>
                  <div class="col-4">
                    <p class="text-truncate pb-0 mb-0">
                      <small>{{ contact.userFirstName }} {{ contact.userLastName }}</small>
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="text-truncate pb-0 mb-0">
                      <small>{{ contact.affiliationEmail }}</small>
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="text-truncate pb-0 mb-0">
                      <small>{{ contact.supplierCompanyname ? contact.supplierCompanyname : stateService.activeWorkspace.companyName }}</small>
                    </p>
                  </div>
                </div>
              </ng-container>
              <div class="col-11 text-end me-0 pe-3">
                <button type="button" class="btn secondary-button custom-button mt-2" (click)="disselectContacts()">{{ locale('locale_key.general.buttons.cancel') }}</button>
                <button type="button" class="btn primary-button custom-button mt-2" (click)="selectContacts()">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.button.select_contact') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="ball-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
    <ng-container *ngIf="!inProgress">
      <p *ngIf="confirmShare">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.confirm_share.title') }}</p>
      <button class="btn secondary-button custom-button" (click)="closeModal(true)">{{ locale('locale_key.general.buttons.close') }}</button>
      <button *ngIf="!confirmShare" class="btn primary-button custom-button" (click)="confirmShare = true">{{ locale('locale_key.pages.dashboard.shared_dashboard_modal.button.share') }}</button>
      <button *ngIf="confirmShare" class="btn primary-button custom-button" (click)="shareDashboard()">{{ locale('locale_key.general.buttons.confirm') }}</button>
    </ng-container>
  </div>
</ng-template>
