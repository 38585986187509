export class KpiRowFe {
  public rowId: string;
  public rowValues: {};

  constructor(
    rowId: string,
    rowValues: {}
  ) {
    this.rowId = rowId;
    this.rowValues = rowValues
  }

  public static fromTransfer(transfer: any): KpiRowFe {
    let row = new KpiRowFe(transfer.rowId, transfer.rowValues)
    return row;
  }
}