export class DataPointFe {

  public dataSeriesId: string | null
  public groupingId: string | null
  public taxonomyKey: string
  public attributeKey: string

  constructor(){}

  public static fromTransfer(transfer: any): DataPointFe {
    let dataPoint = new DataPointFe()
    dataPoint.dataSeriesId = transfer.dataSeriesId
    dataPoint.groupingId = transfer.groupingId
    dataPoint.taxonomyKey = transfer.taxonomyKey
    dataPoint.attributeKey = transfer.attributeKey
    return dataPoint;
  }

  setTaxonomy(key: string) {
    this.taxonomyKey = key;
  }
}
