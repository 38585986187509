export class NamedOccurenceFe {
  sendOutDate: string
  nextDeadline: string
  name: string

  constructor(
    sendOutDate: string,
    nextDeadline: string,
    name: string
  ) {
    this.sendOutDate = sendOutDate
    this.nextDeadline = nextDeadline
    this.name = name
  }

  public static fromTransfer(transfer: any): NamedOccurenceFe {
    let occurence = new NamedOccurenceFe (
      transfer.sendOutDate,
      transfer.nextDeadline,
      transfer.name
    )
    return occurence
  }
}