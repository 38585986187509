export class ServerErrorFe {
  status: number 
  name: string
  message: string
  error: string
  hasHandler: boolean
  constructor(status: number, name: string, message: string, hasHandler: boolean = false) {
    this.name = name
    this.error = name
    this.status = status
    this.message = message
    this.hasHandler = hasHandler
  }
}