import { Component, ElementRef, OnInit, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Chart } from 'chart.js';
import jsPDF from 'jspdf';
import _ from 'lodash';
import Big from 'big.js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, from } from 'rxjs';
import { ChartWrapperFeNew } from 'src/app/model/chart/ChartWrapperFeNew';
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe';
import { SupplierCompanyFe } from 'src/app/model/data-suppliers/company/SupplierCompanyFe';
import { REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe';
import { InsightFe } from 'src/app/model/insight/InsightFe';
import { LoginUserFe } from 'src/app/model/org/LoginUserFe';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { ActionFe } from 'src/app/model/subject/ActionFe';
import { EntityFe } from 'src/app/model/taxonomy/EntityFe';
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe';
import { ContactFe } from 'src/app/model/user/ContactFe';
import { ChartBuilderServiceFeNew } from 'src/app/services/ChartBuilderServiceFeNew';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { LoginServiceFe } from 'src/app/services/LoginServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import * as XLSX from 'xlsx';
import { InsightDefFe } from 'src/app/model/insight/InsightDefFe';
import { InsightSubjectFe } from 'src/app/model/subject/InsightSubjectFe';
import { KpiDataGridIntergationService } from '../kpi/KpiDataGridIntergationService';
import { ChartTypeFe } from 'src/app/model/chart/ChartTypeFe';
import { DataGridTableMode } from '../projects/data-grid-ui/model/DataGridTableMode';
import { UnitFe } from '../unit-systems/model/UnitFe';
import { groupUnitsByMeasurementTypeAndSystem } from '../unit-systems/model/utils';

@Component({
  selector: 'app-insights-hub',
  templateUrl: './insights-hub.component.html',
  styleUrls: ['./insights-hub.component.scss']
})
export class InsightsHubComponent extends AbstractLanguageComponent implements OnInit {

  @ViewChild("insightModal", { static: true }) insightModal: TemplateRef<any>
  @ViewChild("traceData", { static: true }) traceData: TemplateRef<any>
  
  userInfo: LoginUserFe
  public taxonomyInfo: TaxonomyInfoFe;
  public chartWrappers: ChartWrapperFeNew[] = [];
  public isCollapsed: boolean[] = [];
  public canvases: any;
  public filteredRootDataEntities: EntityFe[] = [];
  public filteredParentDataEntities: EntityFe[] = [];
  private firstTimeDraw = true
  public insightModalRef: BsModalRef;
  public selectedKpiEntity: EntityFe
  public insightToDelete: InsightFe
  public inProgress = false;
  public isLoadingData = false
  public dashboardsIncludingInsight: DashboardFe[]  = []
  private canvasSubscription: Subscription; 
  filteringInsightS = false;
  selectedChartWrapper: ChartWrapperFeNew;
  newlyAddedInsights: InsightFe[] = [];
  insightAction = null;
  url:string =''
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  
  subscripition = new Subscription();

  pageToolbar = [
    [
      { shortLabel: this.locale('locale_key.kpi_library.tooltip.insight'), longLabel: this.locale('locale_key.kpi_library.add_new_insight'), tooltip: this.locale('locale_key.kpi_library.add_new_insight'), icon: "la la-plus", actionName: "add_insight",  visible: () =>  true  , disabled: false }
    ],
    ,
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info", actionName:"toggle_quick_tips" , visible:()=> true, disabled: false}, 
    ], 
  ]

  pageToolbarModal = [
    [{ shortLabel: this.locale('locale_key.general.toolbar.button.export'), longLabel: this.locale('locale_key.general.toolbar.button.export'), tooltip: this.locale('locale_key.general.toolbar.button.export'), icon: "la la-download", actionName: "add_insight",  visible: () =>  true  , disabled: false }]
  ]

  
  searchKeyword: string = '';

  groupedBy = null
  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]

  selectedStatus = null;
  customMultipleFilters: {name: string, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number}[] = []
  filteredCompanies: SupplierCompanyFe[] = []
  filteredDataReporters: ContactFe[] = []
  filteredEntities: EntityFe[] = []
  
  filteredChartWrappers: ChartWrapperFeNew[] = []
  filteredGroupedChartWrappers: {name:string | EntityFe, wrappers: ChartWrapperFeNew[]}[] = []

  units: UnitFe[] = []
  unitsByMeasurementType;
  customUnits = [];

  constructor(private chartBuilderService: ChartBuilderServiceFeNew, public stateService: StateServiceFe,
    private modalService: BsModalService, private loginService: LoginServiceFe, private displayService: DisplayServiceFe, private modalRef: BsModalRef, 
    languageService : LanguageService, private responsive: ResponsiveService, private el: ElementRef, private renderer: Renderer2,
    ) {
    super(languageService);

    let sub = stateService.insightSubject.subscribe((subject: InsightSubjectFe) => {
      if (subject.action == ActionFe.CREATED) {
        this.firstTimeDraw = true
        this.newlyAddedInsights.push(subject.obj)
        this.refreshData()
      } else if (subject.action == ActionFe.UPDATED) {
        this.firstTimeDraw = true
        let wrapper = ChartWrapperFeNew.build(subject.obj, undefined, this.stateService)
        let index = this.chartWrappers.findIndex(w => w.insight.id == wrapper.insight.id)
        this.selectedChartWrapper = wrapper
        this.chartWrappers[index]= wrapper
        this.refreshData()
      } else if (subject.action == ActionFe.DELETED) {
        this.firstTimeDraw = true
        this.chartWrappers = this.chartWrappers.filter(w => w.insight.id != subject.obj)
        this.refreshData()        
      } else if (subject.action == ActionFe.SELECT) {
        let wrapper = this.chartWrappers.filter(w => w.insight.id != subject.obj.id)
        this.selectChart(wrapper)
      }
    })
    this.subscripition.add(sub)
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
    this.screenSize = responsive.currentScreenWidthSize;

    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits();
    });

    this.stateService.insightCacheInvalidated.subscribe( async (insights) => {
      const openedInsight = `${this.selectedChartWrapper.insight.id}`;
      await this.refreshData();
      await this.refreshView();
      if(!_.isEmpty(openedInsight)){
        const wrapper = this.chartWrappers.find(w => w.insight.id == openedInsight);
        setTimeout(() => {this.selectChart(wrapper)})
      }
    })
  }

  @ViewChildren('canvas', { read: ElementRef }) htmlCanvases: QueryList<ElementRef>;


  async ngOnInit() {
    Chart.defaults.color = "#405369";
    Chart.defaults.font.family = "'Open Sans', sans-serif";
    Chart.defaults.font.size = 14;
    Chart.defaults.font.weight = "400";
    await this.loadCompanyData()
    if(this.chartWrappers.length == 0){
      this.displayService.openTips();
    }
    this.setInsightEntities();
    this.filterEntities();
    await this.loadUnits();
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units);
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard && unit.shouldDisplay);
  }

  setInsightEntities(): void{
    this.chartWrappers.forEach(wrapper => {
      let entityKeysSet = new Set<string>();
      if (wrapper.hasError) {
        return undefined
      } else {
        wrapper.insight.definition.dataSeries.forEach(ds => {
          ds.dataPoints.forEach(dp => entityKeysSet.add(dp.taxonomyKey))
        })
      }
      let entityKeys = Array.from(entityKeysSet)
      let entities = entityKeys.map(entityKey => this.taxonomyInfo.entityByKey(entityKey));
      entities = entities.filter(entity => !!entity)
      wrapper.insight.entities = entities;
    })
  }

  downloadPDF(index){

    this.canvases.map((canvas, indx) => {
      if (indx == index) {
        var pdf = new jsPDF("p", "mm", "a4");
        const a4Size= {height:300,width:210};
        let canvasElem = document.getElementById(canvas.nativeElement.id) as HTMLCanvasElement;
        let leftMargin = 10, topMargin = 20, rightMargin = 10;
        let height = canvasElem.clientHeight;
        let width = canvasElem.clientWidth;
        let ratio = height / width;
        if(height > width){
          height=a4Size.height - topMargin;
          width=height*(1/ratio);
        }else if(width > height){
          width=a4Size.width - leftMargin - rightMargin;
          height=width*ratio;
        }
        var url_base64jp = canvasElem.toDataURL();      
        pdf.addImage(url_base64jp, leftMargin, topMargin,width,height);   
        var insight = this.selectedChartWrapper.insight
        pdf.save(`${insight.title}.pdf`);
      }
    })
  }


  downloadImage(index) {
    this.canvases.map((canvas, indx) => {
      if (indx == index) {
        let canvasElem = document.getElementById(canvas.nativeElement.id) as HTMLCanvasElement;
        var url_base64jp = canvasElem.toDataURL();
        let link = document.createElement('a');
        link.href = url_base64jp;
        var insight = this.selectedChartWrapper.insight
        link.download = insight.title;
        link.click();
      }
    })
  }

  downloadExcel (exportType: string) {
    this.selectedChartWrapper.insight.kpiDataGridService.setTable(this.selectedChartWrapper.setting)
    this.selectedChartWrapper.insight.kpiDataGridService.downloadCurrentPage(exportType)
  }

  selectChart (wrapper) {
    this.selectedChartWrapper = this.selectedChartWrapper == wrapper ? null : wrapper;
  }

  deselectChart() {
    this.selectedChartWrapper = null;
    setTimeout(() =>this.drawAllCharts())
  }

  onChangedSetting(): void {
    if (this.selectedChartWrapper.setting.chartType.id.startsWith('table')) {
      this.selectedChartWrapper.insight.kpiDataGridService.setTable(this.selectedChartWrapper.setting)
    } else {
      let hiddenDatasetLabels = this.getHiddenDatasetLabels()
      this.selectedChartWrapper.chart.destroy();
      let insight = this.selectedChartWrapper.insight
      let setting = this.selectedChartWrapper.setting
      if (this.selectedChartWrapper.setting.chartType != ChartTypeFe.TABLE) {
        var chartData = this.chartBuilderService.buildChart(insight, setting);
        let canvasEl = document.getElementsByClassName("selectedChartWrapper")[0] as HTMLCanvasElement;
        var chart = new Chart(canvasEl.getContext('2d'), chartData)
        this.selectedChartWrapper.chart = chart
      }
      this.hideDatasets(hiddenDatasetLabels)
    }
  }

  setKpiTable(): void {
    this.selectedChartWrapper.insight.kpiDataGridService = new KpiDataGridIntergationService(this.selectedChartWrapper.insight, this.stateService); 
    this.selectedChartWrapper.insight.kpiDataGridService.mode = DataGridTableMode.VIEW
    this.selectedChartWrapper.insight.kpiDataGridService.setTable(this.selectedChartWrapper.setting)
  }

  getHiddenDatasetLabels() {
    let hiddenDatasetLabels = []
    let chart = this.selectedChartWrapper.chart
    chart.data.datasets.forEach((d, i) => {
      if (chart.getDatasetMeta(i).hidden) {
        hiddenDatasetLabels.push(d.label)
      }
    });
    return hiddenDatasetLabels
  }

  hideDatasets(datasetLabels: string[]) {
    let chart = this.selectedChartWrapper.chart
    chart.data.datasets.forEach((d, i) => {
      if (datasetLabels.includes(d.label)) {
        chart.getDatasetMeta(i).hidden = true
        chart.update()
      }
    });
  }

  refreshView() {
    let entityKeysSet = new Set<string>();
    this.filteredChartWrappers.forEach(wrapper => {
      if (wrapper.hasError) {
        return undefined
      } else {
        wrapper.insight.definition.dataSeries.forEach(ds => {
          ds.dataPoints.forEach(dp => entityKeysSet.add(dp.taxonomyKey))
        })
      }
    })
    let entityKeys = Array.from(entityKeysSet)
    entityKeys = entityKeys.filter(e => e != undefined)
    entityKeys.sort()
    this.filteredRootDataEntities = this.taxonomyInfo.rootParentEntities(entityKeys)
    this.filteredParentDataEntities = this.taxonomyInfo.parentEntities(entityKeys)
    this.isCollapsed = this.filteredParentDataEntities.map(e => false);
    if (this.canvasSubscription) {
      this.canvasSubscription.unsubscribe()
      this.firstTimeDraw = true
    }
    this.canvasSubscription = this.htmlCanvases.changes.subscribe((canvases) => {
      if (this.firstTimeDraw) {
        this.firstTimeDraw = false
        this.canvases = canvases
        this.drawAllCharts()  
      }
    })
  }

  drawAllCharts() {
    this.canvases.forEach((canvas, indx) => {
      this.drawChart(canvas, indx)
    });
  }

  drawChart(canvas, index) {
    let wrappers = this.filteredChartWrappers;
    if (this.groupedBy) {
      wrappers = this.filteredGroupedChartWrappers.reduce((result, group) => result.concat(group.wrappers), []);
    }
    let wr = wrappers[index];
    if (wr.chart) {
      wr.chart.destroy();
    }
    if (!wr.hasError && wr.setting.chartType != ChartTypeFe.TABLE) {
      let chartData = this.chartBuilderService.buildChart(wr.insight, wr.setting)
      let chart = new Chart(canvas.nativeElement.getContext('2d'), chartData)
      wr.chart = chart
    }
  }

  public async loadCompanyData() {
    this.isLoadingData = true
    this.userInfo = this.loginService.getUserInfo()
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
    let insights = await this.stateService.getInsights()
    this.chartWrappers = insights.map(insight => ChartWrapperFeNew.build(insight, undefined, this.stateService))
    await this.refreshData()
    this.isLoadingData = false
    if (this.stateService.selectedInsight) {
      let wrapper = this.chartWrappers.find(w => w.insight.id == this.stateService.selectedInsight.id)
      setTimeout(() => {this.selectChart(wrapper)})
    }
  }

  async refreshData() {
    let insights = await this.stateService.getInsights()
    this.chartWrappers = insights.map(insight => ChartWrapperFeNew.build(insight, undefined, this.stateService))
    this.applyFilters()
  }
  
  public editInsight(wrapper: ChartWrapperFeNew) {
    this.selectedChartWrapper = wrapper
    this.insightAction = 'edit'
  }

  public duplicateInsight(wrapper: ChartWrapperFeNew) {
    this.selectedChartWrapper = wrapper
    this.insightAction = 'duplicate'
  }

  async delete(modalTemplateRef: TemplateRef<any>, wrapper: ChartWrapperFeNew) {
    this.inProgress = true
    this.insightToDelete = wrapper.insight
    await this.checkDashboardForInsight(wrapper.insight.id);
    this.openModal(modalTemplateRef, 'modal-md')
    this.inProgress = false
  }

  async checkDashboardForInsight(insightId: string){
    let dashboards = await this.stateService.getDashboards()
    this.dashboardsIncludingInsight = dashboards.filter(d => d.charts.some(chart => chart.kpiId == insightId));
  }

  async deleteInsight(insight: InsightDefFe) {
   this.inProgress = true
   await this.deleteInsightFromDashboard(insight.id); 
   await this.stateService.deleteInsightDef(insight.id);
   this.closeModal(true);
   this.inProgress = false
  }

  async deleteInsightFromDashboard(insightId: string){
    for await (let d of this.dashboardsIncludingInsight){
      d.charts = d.charts.filter((c) => c?.wrapper?.insight.id != insightId);
      await this.stateService.modifyDashboard(d);
    }
  }

  closeKpiModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.insightModalRef.id)
    }
  }

  someKpisInError() {
    let w = this.filteredChartWrappers.find(w => w.hasError)
    return (w) ? true : false 
  }

  toggleTips(){
    this.displayService.toggleTips()
  }

  openModal (modalTemplateRef: TemplateRef<any> | string, className: string = 'modal-xl') {
    let config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: className
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove('modal-open');
    }
  }

  expandAll () {
    this.isCollapsed = this.isCollapsed.map(c => c = false)
  }

  collapseAll () {
    this.isCollapsed = this.isCollapsed.map(c => c = true)
  }

  isNewInsight (wrapper: ChartWrapperFeNew) {
    return this.newlyAddedInsights.find(i => wrapper.insight.id == i.id);
  }

  closeInsight () {
    this.insightAction = null
    this.refreshData()
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "add_insight":
        this.insightAction = 'add'
        break
      case "toggle_quick_tips":
        this.toggleTips()
        break
    }
  }

  addActiveClass(event: any){
    const elements = document.querySelectorAll('[id^="acc"]');
    elements.forEach((element:any) => {
      element.classList.remove('active-accordion')
    });
    let elementId = event.currentTarget.id
    let element = document.getElementById(elementId)
    element.classList.toggle('active-accordion')
  }

  showInsightData(chartWrapper: ChartWrapperFeNew, event: MouseEvent) {
    this.selectedChartWrapper = chartWrapper;
    event.stopPropagation();
    this.openModal(this.traceData, 'modal-xl');
  }

  applyFilters() {
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedStatus = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof SupplierCompanyFe) {
        this.customMultipleFilters.push({name: value.supplierCompanyname, value})
      } else if (value instanceof ContactFe) {
        this.customMultipleFilters.push({name: value.getName(), value})
      } else if (value instanceof EntityFe) {
        this.customMultipleFilters.push({name: value.getTaxonomyTitle(this.taxonomyInfo), value, level})
      } 
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  
  group(option?) {
    if (option) {
      this.groupedBy = option;
    }
    this.filteredGroupedChartWrappers = [];
    if (this.groupedBy) {
      if (this.groupedBy.value == 'entities') {
        let entities = new Map<EntityFe, ChartWrapperFeNew[]>();
        this.filteredChartWrappers.forEach(wrapper => {
          wrapper.insight.entities.forEach(entity => {
            if (!entities.has(entity)) {
              entities.set(entity, []);
            }
            entities.get(entity).push(wrapper);
          })
        })
        entities.forEach((wrappers, entity) => this.filteredGroupedChartWrappers.push({ name: entity.getTaxonomyTitle(this.taxonomyInfo), wrappers}) )
  
      }
    }
    this.sort();
  }

  async sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let sortFun;
    if (this.sortedBy) {
      if (this.sortedBy.value == 'name') {
        sortFun = (a: ChartWrapperFeNew, b: ChartWrapperFeNew) => {
          if (a.insight.title.toLowerCase() < b.insight.title.toLowerCase()) {
            return -1 
          } else if (a.insight.title.toLowerCase() > b.insight.title.toLowerCase()) {
            return 1
          } else {
            return 0
          }
        }
      } else if (this.sortedBy.value == 'nameDes') {
        sortFun = (a: ChartWrapperFeNew, b: ChartWrapperFeNew) => {
          if (a.insight.title.toLowerCase() < b.insight.title.toLowerCase()) {
            return 1 
          } else if (a.insight.title.toLowerCase() > b.insight.title.toLowerCase()) {
            return -1
          } else {
            return 0
          }
        }
      }
    }
    if (sortFun) {
      if (this.groupedBy) {
        this.filteredGroupedChartWrappers.forEach(group => group.wrappers = group.wrappers.sort(sortFun))
      } else {
        this.filteredChartWrappers = this.filteredChartWrappers.sort(sortFun);
      }
    }
    this.refreshView()
  }

  async applyCustomFilters() {
    let insights = await this.stateService.getInsights()
    this.chartWrappers = insights.map(insight => ChartWrapperFeNew.build(insight, undefined, this.stateService))
    
    if (this.customMultipleFilters.length > 0) {
      this.chartWrappers = this.chartWrappers.filter(wrapper => {
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof EntityFe) {
            let taxonomyKeys = new Set<string>();
            if (f.level == 0) {
              let parent = this.taxonomyInfo.childrenSortedByOrdinal(value.key)
              let child = []
              parent.forEach(item => child.push(...this.taxonomyInfo.childrenSortedByOrdinal(item.key)))
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else if (f.level == 1) {
              let child = this.taxonomyInfo.childrenSortedByOrdinal(value.key)
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else {
              taxonomyKeys.add(value.key)
            }
            let entityKeysSet = new Set<string>();
            wrapper.insight.definition.dataSeries.forEach(ds => {
              ds.dataPoints.forEach(dp => entityKeysSet.add(dp.taxonomyKey))
            })
            let entityKeys = Array.from(entityKeysSet)
            return entityKeys.find(key => taxonomyKeys.has(key))
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword;
    }
    this.filteredChartWrappers = this.chartWrappers.filter(wrapper => wrapper.insight.title.match(new RegExp(this.searchKeyword, "i")))
    this.group();    
  }

  async filterEntities(keyword: string = '') {
    let allRootEntities = this.taxonomyInfo.childrenSortedByOrdinal()
    this.filteredEntities = allRootEntities.filter(root => {
      if (root.getLabel().match(new RegExp(keyword, "i"))) {
        return true
      }
      let parents = this.taxonomyInfo.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.taxonomyInfo.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != company)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != contact)
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
      })
    }
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        if (!this.customMultipleFilters.find(f => f.value == company)) {
          this.customMultipleFilters.push({name: company.supplierCompanyname, value: company})
        } 
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        if (!this.customMultipleFilters.find(f => f.value == contact)) {
          this.customMultipleFilters.push({name: contact.getName(), value: contact})
        } 
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        if (!this.customMultipleFilters.find(f => f.value == enitity)) {
          this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.taxonomyInfo), value: enitity})
        } 
      })
    }
    this.applyFilters();
  }

  getCustomFilterText() {
    let text = '';
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }

  insightUnitChanged({ newUnit, wrapper } : { newUnit:UnitFe, wrapper:ChartWrapperFeNew }){
    console.log({ msg : "insightUnit changed...", newUnit, wrapper });

    // We use string interpolation `` to avoid passby ref logical errors
    const fromUnit = `${wrapper.insight.definition.unitSymbol}`;

    if(!wrapper.insight.definition.unitSymbolChanged){
      wrapper.insight.definition.unitSymbolChanged = true;
      wrapper.insight.definition.originalUnitSymbol = `${wrapper.insight.definition.unitSymbol}`;
    }

    if(_.isEmpty(newUnit.symbol)){
      wrapper.insight.definition.unitSymbol = `${wrapper.insight.definition.originalUnitSymbol}`;
    }else{
      wrapper.insight.definition.unitSymbol = `${newUnit.symbol}`;
    }

    const toUnit = `${wrapper.insight.definition.unitSymbol}`

    // convert
    const unitEvaluator = this.stateService.getUnitEvaluator();
    wrapper.insight.kpis = wrapper.insight.kpis.map( kpi => {
      kpi.rows = kpi.rows.map(row => {
        try{
          const values:any = row.rowValues
          Object.keys(values).forEach(key => {
            if(key.startsWith("_dataSeries_")){
              const conversionExp = `${values[key]} ${fromUnit} to  ${toUnit}`;
              const convertedValue = new Big(unitEvaluator.evaluate(conversionExp).toString().split(" ")[0]).toString();
              values[key] = convertedValue;
            }
          })
          row.rowValues = { ...values } 
        }catch(err){
          console.log(err)
        }
        return row
      })
      return kpi
    })

    this.onChangedSetting();
  }

  getInsightMeasurementTypeLimits({ wrapper } : { wrapper:ChartWrapperFeNew }){
    const limits = new Set();
    this.units.forEach(unit => {
      if(unit.symbol == wrapper.insight.definition.unitSymbol){
        limits.add(unit.measurementType);
      }
    })
    return limits;
  }
}
