import { BooleanStatementFe } from "./BooleanStatementFe";
import { ValueStatementFe } from "./ValueStatementFe";

export class NumericalValueStatementFe extends ValueStatementFe {
  public static TYPE_KEY = 'NUMERICAL' 
  public type = NumericalValueStatementFe.TYPE_KEY 
  public static START_KEYWORD = '#'
  
  constructor(value: number) {
    super(value)
  }

  public toFormulaString(): string {
    let str = [`${NumericalValueStatementFe.START_KEYWORD}`,
               `${this.value}`].join('');
    return str
  }

  public static fromFormulaString(formula: string): NumericalValueStatementFe {
    let nextSpace = formula.indexOf(BooleanStatementFe.SPACE);
    let str = formula.substring(NumericalValueStatementFe.START_KEYWORD.length, nextSpace);
    let value = parseFloat(str)
    return new NumericalValueStatementFe(value)
  }
  
  public evaluate(values: Map<string, any>): number {
    return this.value
  }
  
  public static fromTransfer (transfer: any): NumericalValueStatementFe {
    let value = transfer['value']
    let statement = new NumericalValueStatementFe(value)
    return statement
  }
}
