import { DOCUMENT } from "@angular/common"
import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { View } from "src/app/model/datahub/View"
import { CCProjectInfoFe } from "src/app/model/project/CCProjectInfoFe"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { LoginServiceFe } from "src/app/services/LoginServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"

@Component({
  selector: "carbonlab-overview",
  templateUrl: "./carbonlab-overview.component.html",
  styleUrls: ["./carbonlab-overview.component.scss"],
})
export class CarbonlabOverviewComponent extends AbstractLanguageComponent implements OnInit {
  filter(arg0: any) {
    throw new Error("Method not implemented.")
  }
  collapseAll() {
    throw new Error("Method not implemented.")
  }
  expandAll() {
    throw new Error("Method not implemented.")
  }
  @ViewChild("addProjectModal") addProjectModal: TemplateRef<any>
  @ViewChild("saveDraft") saveDraft: TemplateRef<any>
  @ViewChild("showCalcModal") showCalcModal: TemplateRef<any>
  @ViewChild("editCalcModal") editCalcModal: TemplateRef<any>
  @ViewChild("selectEmissionsModal") selectEmissionsModal: TemplateRef<any>
  @ViewChild("emissionsPortalModal") emissionsPortalModal: TemplateRef<any>
  @ViewChild("removeProjectModal") removeProjectModal: TemplateRef<any>
  @ViewChild("dataSuccessfullyAddedModal") dataSuccessfullyAddedModal: TemplateRef<any>
  @ViewChild("missingEmissionsModal") missingEmissionsModal: TemplateRef<any>
  @ViewChild("updateCategoriesModal") updateCategoriesModal: TemplateRef<any>
  @ViewChild("exportTableModal") exportTableModal: TemplateRef<any>
  @ViewChild("emissionCategoriesModal") emissionCategoriesModal: TemplateRef<any>
  @ViewChild("customEmissionModal") customEmissionModal: TemplateRef<any>
  @ViewChild("removeEmissionModal") removeEmissionModal: TemplateRef<any>
  @ViewChild("removeEmissionsConfModal") removeEmissionsConfModal: TemplateRef<any>

  loadingInProgress: boolean

  url: string = ""
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingData: boolean = false

  activeFormNo = 1
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  inProgress = true
  projectName = ""
  saveBeforeLeave = false
  bizTravel: boolean = false
  emissionsEmpty: boolean = true
  allProjects: boolean = false
  activeTab: string = !this.allProjects ? "tab31" : "tab35"
  pullDataFromPlatform: boolean = false
  emissionFormSustainLab: boolean = false
  carbonEmissions2023: boolean = true
  licencedMaterial: boolean = true
  cardHeader:boolean = false
  cardFooter:boolean = false
  selectedOption: string = '';
  tabNames = {
    tab31: "All data entries",
    tab32: "Per emission source",
    tab33: "Per organizational unit",
    tab34: "Request overview",
  }

  @Input() project: CCProjectInfoFe

  views: {[key:string]:View}= {}
  activeView: View
  selectedEmissionCategory: EntityFe
  
  pageToolbar = [
    [
      {
        shortLabel: this.slocale("All projects"),
        longLabel: this.slocale("All projects"),
        tooltip: this.slocale("All projects"),
        icon: "la la-project-diagram",
        visible: () => true,
        actionName: "all_projects",
      },
    ],
    [
      {
        shortLabel: this.slocale("Home"),
        longLabel: this.slocale("Home"),
        tooltip: this.slocale("Home"),
        icon: "la la-home",
        actionName: "toggle_quick_tips",
        visible: () => true,
      },
    ],
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_table",
        visible: () => true,
        disabled: false,
      },
    ],
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.info"),
        longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
        tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
        icon: "la la-info",
        actionName: "toggle_quick_tips",
        visible: () => true,
      },
    ],
  ]

  steps = [
    { title: this.slocale("Learn about CarbonLab"), completed: false },
    { title: this.slocale("Pick project name"), completed: false },
    { title: this.slocale("Select emissions categories"), completed: false },
    { title: this.slocale("Define data storage"), completed: false },
  ]

  stepsPullData = [
    { title: this.slocale("Select calculation method"), completed: false },
    { title: this.slocale("Select data category"), completed: false },
    { title: this.slocale("Filter and select data"), completed: false },
    { title: this.slocale("Add missing data"), completed: false },
  ]

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {
    this.renderViews();
    this.activeView = this.views['overview']
  }

  renderViews() {
   this.views = { 
    'overview' : new View('overview', ['PROJECTS']),
    'addData' : new View('addData', ['PROJECTS', this.project.name]),
    'requestData' : new View('requestData', ['PROJECTS', this.project.name, this.selectedEmissionCategory?.resolveLabel(this.activeLanguage)]),
   };
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  openModal(templateRef: TemplateRef<any>, size: string = "") {
    this.modalService.show(templateRef, { class: size })
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "all_projects":
        this.openModal(this.addProjectModal, "modal-lg")
        break
      case "export_table":
        this.openModal(this.exportTableModal, "modal-md")
        break
      case "toggle_quick_tips":
        this.toggleTips()
        break
    }
  }

  update() {
    this.circles = this._document.querySelectorAll(".circle1")

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add("active-circle1")
      } else {
        circle.classList.remove("active-circle1")
      }
    })

    const actives = this._document.querySelectorAll(".active-circle1")
  }

  next() {
    // if (this.activeFormNo == 1) {
    //   this.reqDetailsForm.markAllAsTouched();
    //   if (this.reqDetailsForm.invalid) {
    //     return;
    //   }
    // } else {
    //   this.validateQuestionnaire();
    //   if (this.isQuestionnaireInvalid) {
    //     return;
    //   }
    // }
    this.currentActive++
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  closeModal() {
    this.modalService.hide()
  }

  saveDraftModal(saveDraft: boolean) {
    this.saveBeforeLeave = saveDraft
    this.modalService.show(this.saveDraft, { class: "modal-lg" })
  }

  saveProject() {
    this.modalService.hide()
    this.saveBeforeLeave = false
  }

  selectTab(tabId: string) {
    this.activeTab = tabId
  }

  getActiveTabName(): string {
    return this.slocale(this.tabNames[this.activeTab])
  }

  editCalculation(id: string) {}

  setDateOption(option: string) {
    this.selectedOption = option;
  }

  addData() {
    this.activeView = this.views['addData']
  }

  setView(viewName: string) {
    this.activeView = this.views[viewName];
  }
}


