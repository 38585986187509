import { Component, Input, OnInit } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { DirectEntryInfoFe } from "src/app/model/directEntry/DirectEntryInfoFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { StageTableDataGridIntergationService } from "../data/StageTableDataGridIntergationService"
import { TableDataFe } from "src/app/model/schema/TableDataFe"
import { RowStatusFe } from "src/app/model/schema/RowStatusFe"
import { RouterFe } from "src/app/route/RouterFe"
import { DatahubService } from "./DatahubService"
import { Subscription } from "rxjs"
import { AlertServiceFe } from "src/app/services/AlertServiceFe"
import { ErrorsFe } from "src/app/utils/KNOWN_ERRORS"
import { DataGridTableMode } from "../projects/data-grid-ui/model/DataGridTableMode"

@Component({
  selector: "data-entry",
  templateUrl: "./dataEntry.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class DataEntryComponent extends AbstractLanguageComponent implements OnInit {
  
  stageTable: DirectEntryInfoFe;
  menuCollapsed: boolean

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInfo: boolean = false
  loadingInProgress: boolean = false
  isDataExtractor = false
  toCopyStageTableInfo: DirectEntryInfoFe; 
  toCopyStageTableService: StageTableDataGridIntergationService; 
  taxonomyInfo: TaxonomyInfoFe;

  subscription = new Subscription();

  constructor(languageService: LanguageService, private responsive: ResponsiveService, private stateService: StateServiceFe, 
    public datahubService: DatahubService, private ErrorsFe: ErrorsFe, private alertService: AlertServiceFe,
    private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService, private backendService: RouterFe) {
    super(languageService)
    
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.screenSize = this.responsive.currentScreenWidthSize
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize;
  }

  async ngOnInit() {
    this.stageTable = this.datahubService.selectedDataEntry
    this.stageTable.loadInProgress = true      
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy;
    let entity = this.taxonomyInfo.entityByKey(this.stageTable.taxonomyKey)
    let dataGridService = new StageTableDataGridIntergationService(this.stageTable, entity, this.backendService, this.languageService)
    if (!this.stageTable.hasContent()) {
      await dataGridService.loadMainTablePage()
      this.stageTable.dataGridService = dataGridService
    } else if (!this.stageTable.dataGridService) {
      this.stageTable.dataGridService = dataGridService
      this.stageTable.dataGridService.currentPageData = this.stageTable.tableData
    }
    this.displayService.updateQuickTipsData({viewMode:'table_viewer', page:'data'})
    this.stageTable.loadInProgress = false

    let connectSub = this.datahubService.connecToPipeline.subscribe(async obs => await this.stageTable.connecToPipeline(this.stateService, this.ErrorsFe, this.alertService))
    let disconnectSub = this.datahubService.disconnectFromPipeline.subscribe(async obs => await this.stageTable.disconnectFromPipeline(this.stateService, this.ErrorsFe, this.alertService))
    let fileExtrSub = this.datahubService.openFileExtractor.subscribe(async obs => this.openFileExtractor())
    let downloadSub = this.datahubService.exportDirectEntry.subscribe(async obs => await this.stageTable.dataGridService.downloadDataEntry(this.datahubService.exportFileType))
    this.subscription.add(connectSub);
    this.subscription.add(disconnectSub);
    this.subscription.add(fileExtrSub);
    this.subscription.add(downloadSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async dataExtracted (newTableData: TableDataFe) {
    let updatedTable = this.stageTable.dataGridService.observer.getModifiedTableData()
    newTableData.rows.forEach((row, index) => {
      row.status = RowStatusFe.ADDED
      this.stageTable.dataGridService.observer.addRow(row, index + updatedTable.rowCount, true)
    })
    let isValid = this.stageTable.dataGridService.observer.checkDataValidity()
    this.stageTable.dataGridService.markDataModified(newTableData.rows.length>0, isValid)
    this.closeFileExtractor(this.stageTable);
  } 

  closeFileExtractor (stageTable: DirectEntryInfoFe) {
    this.isDataExtractor = false;
    stageTable.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
  }

  openFileExtractor () {
    this.toCopyStageTableInfo = this.stageTable; 
    let entity = this.taxonomyInfo.entityByKey(this.stageTable.taxonomyKey)
    this.toCopyStageTableService = new StageTableDataGridIntergationService(this.stageTable, entity, this.backendService, this.languageService)
    this.toCopyStageTableService.hasTablePagination = false
    this.toCopyStageTableService.mode = DataGridTableMode.VIEW
    this.toCopyStageTableService.currentPageData = this.stageTable.dataGridService.deepCopy()
    this.isDataExtractor = true;
  }
  
  openMasterTable() {
    this.datahubService.showEntityMasterTable(this.stageTable.taxonomyEntity)
  }

}
