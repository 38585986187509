export class FileTypeFe {
  static readonly PDF = new FileTypeFe('pdf', 'la-file-pdf-o', '#c65c5c');
  static readonly CSV = new FileTypeFe('csv', 'la-file-excel-o', '#6dc49b');
  static readonly XLS = new FileTypeFe('xls', 'la-file-excel-o', '#6dc49b');
  static readonly XLSX = new FileTypeFe('xlsx', 'la-file-excel-o', '#6dc49b');
  static readonly DOC = new FileTypeFe('doc', 'la-file-word-o', '#19bfd3');
  static readonly DOCX = new FileTypeFe('docx', 'la-file-word-o', '#19bfd3');
  static readonly PNG = new FileTypeFe('png', 'la-file-image-o', '#F2921D');
  static readonly JPG = new FileTypeFe('jpg', 'la-file-image-o', '#F2921D');
  static readonly JPEG = new FileTypeFe('jpeg', 'la-file-image-o', '#F2921D');
  static readonly ZIP = new FileTypeFe('zip', 'la-folder-open-o', '#F2921D');
  static readonly ZIPX = new FileTypeFe('zipx', 'la-folder-open-o', '#F2921D');
  static readonly UNRECOGNIZED = new FileTypeFe('unrecognized', '', '');
  constructor(readonly extension: string, readonly icon: string, readonly color: string) {
  }

  public static fromName(name: string): FileTypeFe {
    if (!name) {
      return FileTypeFe.UNRECOGNIZED;
    } else {
      let extension = name.split('.').pop().toLowerCase()
      switch (extension) {
        case FileTypeFe.PDF.extension:
          return FileTypeFe.PDF
        case FileTypeFe.CSV.extension:
          return FileTypeFe.CSV
        case FileTypeFe.XLS.extension:
          return FileTypeFe.XLS
        case FileTypeFe.XLSX.extension:
          return FileTypeFe.XLSX
        case FileTypeFe.DOC.extension:
          return FileTypeFe.DOC
        case FileTypeFe.DOCX.extension:
          return FileTypeFe.DOCX
        case FileTypeFe.PNG.extension:
          return FileTypeFe.PNG
        case FileTypeFe.JPG.extension:
          return FileTypeFe.JPG
        case FileTypeFe.JPEG.extension:
          return FileTypeFe.JPEG
        case FileTypeFe.ZIP.extension:
          return FileTypeFe.ZIP
        case FileTypeFe.ZIPX.extension:
          return FileTypeFe.ZIPX
        default:
          return FileTypeFe.UNRECOGNIZED;
      }
  
    }
  }
}