import { BooleanStatementFe } from "./condition/BooleanStatementFe";
import { QuestionFe } from "./QuestionFe";
import { QUESTION_TYPE } from "./QuestionTypeFe"
import { QuestionChoiceOptionFe } from "./QuestionChoiceOptionFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { AnswerSingleChoice_AddFe } from "../../timeline/add/answer/AnswerSingleChoice_AddFe";
import { AnswerSingleChoiceFe } from "../../timeline/answer/AnswerSingleChoiceFe";
import { BooleanStatementBuilderFe } from "./condition/BooleanStatementBuilderFe";
import { FormControl, Validators } from "@angular/forms";

export class QuestionSingleChoiceFe  extends QuestionFe {
  options: QuestionChoiceOptionFe[]

  followUpControl = new FormControl(null, Validators.required)
  newAnswer: AnswerSingleChoice_AddFe = new AnswerSingleChoice_AddFe(IdUtil.next(), this.sectionId, this.id, null, null)
  answer: AnswerSingleChoiceFe
  chartWrapperIndex = -1

  constructor(
      id: string, 
      sectionId: string,
      ordinal: number | null,
      followUpCondition: BooleanStatementFe | null,
      question: string,
      desc: string | null,
      comments: boolean,
      required: boolean,
      mappedToColKeys: string[] | null,
      options: QuestionChoiceOptionFe[]
    ) {
    super(
      id, 
      sectionId,
      QUESTION_TYPE.SINGLE_CHOICE,
      ordinal, 
      followUpCondition,
      question, 
      desc, 
      comments, 
      required,
      mappedToColKeys
    );
    this.options = options;
  }

  public static fromTransfer(transfer: any, sectionId: string): QuestionSingleChoiceFe  {
    transfer.followUpCondition = transfer.followUpCondition ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition) : null
    let item = this.setQuestion(transfer, sectionId);
    return item
  }

  public static setQuestion(transfer: any, sectionId?): QuestionSingleChoiceFe {
    let item = new QuestionSingleChoiceFe (
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.mappedToColKeys,
      transfer.options.map(op => QuestionChoiceOptionFe.fromTransfer(op))
    )
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }

  adjustNewQuestion () {
    this.options = []
    for (let i=1; i<=3; i++) {
      let option = new QuestionChoiceOptionFe(IdUtil.next(), null, null);
      this.options.push(option)
    }
  }

  getOption (optionId: string) {
    let option = this.options.find(o => o.id == optionId)
    return option
  }
}