import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AlertServiceFe } from "src/app/services/AlertServiceFe";
import { RoutesFe } from "src/app/route/RoutesFe";
import { PartnerInvitationFe } from "src/app/model/data-suppliers/company/PartnerInvitationFe";
import { CompanyFe } from "src/app/model/data-suppliers/company/CompanyFe";
import { ManagerInvitationFe } from "src/app/model/invitation/ManagerInvitationFe";
import { ContactInvitationFe } from "src/app/model/data-suppliers/company/ContactInvitationFe";
import { RouteServiceFe } from "src/app/route/RouteServiceFe";
import { RouteFe } from "src/app/route/RouteFe";
import { ManagerInvitationExtendedFe } from "src/app/model/invitation/ManagerInvitationExtended";
import { Manager_RegisterFe } from "src/app/model/invitation/Manager_RegisterFe";
import { ContactInvitationExtendedFe } from "src/app/model/data-suppliers/company/ContactInvitationExtendedFe";
import { Contact_RegisterFe } from "src/app/model/data-suppliers/company/Contact_RegisterFe";
import { PartnerInvitationExtendedFe } from "src/app/model/data-suppliers/company/PartnerInvitationnExtendedFe";
import { ManagerInvitation_Reject } from "src/app/model/invitation/ManagerInvitation_Reject";
import { PartnerInvitation_CheckExistingUserCompaniesFe } from "src/app/model/data-suppliers/company/PartnerInvitation_CheckExistingUserCompaniesFe";
import INDUSTRIES_DATA from "../../model/INDUSTRIES.json";
import COUNTRIES_DATA from "../../model/COUNTRIES.json";
import { Partner_RegisterReuseExistedUserCompanyIdFe } from "src/app/model/data-suppliers/company/Partner_RegisterReuseExistedUserCompanyIdFe";
import { PartnerInvitation_CheckIdClashFe } from "src/app/model/data-suppliers/company/PartnerInvitation_CheckIdClashFe";
import { Partner_RegisterNonExistedCompanyFe } from "src/app/model/data-suppliers/company/Partner_RegisterNonExistedCompanyFe";
import { Partner_RegisterWithIdClashResolutionFe } from "src/app/model/data-suppliers/company/Partner_RegisterWithIdClashResolutionFe";
import { ValidationMessages } from "src/app/model/form-validation/FormValidationMessages";
import { ValidationRegex } from "src/app/model/form-validation/ValidationRegex";
import { ErrorsFe, OpenErrorsFe } from "src/app/utils/KNOWN_ERRORS";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { UserCredentialWithCustomClaims } from "src/app/services/GoogleIdentityPlatformService";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { Language } from "src/app/utils/language/Language";
import { Subscription } from "rxjs";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent
  extends AbstractLanguageComponent
  implements OnInit
{
  loadingInfo = false;
  loadInProgress: boolean = false;
  userInfo: FormGroup;
  rejectionReason = new FormControl(null, Validators.required);
  rejectionExplanation = new FormControl(null);
  invalidRegistration: boolean;
  password = "";
  cpassword = "";
  isSubmitted = false;

  uuid: string;
  invitationAccepted = false;
  invitationRejected = false;
  isManagerInvitation = false;
  isContactInvitation = false;
  isPartnerInvitation = false;
  invitationInfo:
    | ManagerInvitationFe
    | PartnerInvitationFe
    | ContactInvitationFe;
  managerInvitationExtended: ManagerInvitationExtendedFe;
  contactInvitationExtended: ContactInvitationExtendedFe;
  partnerInvitationExtended: PartnerInvitationExtendedFe;
  activeRoute: RouteFe;
  viewNo = 1;

  isNewUser = new FormControl("-1", Validators.required);
  isCompanyConfirmed = false;
  sameAsExisitingCompany = new FormControl("-1", Validators.required);
  existingCompanies: CompanyFe[] = [];
  confirmCompanyDetailsForm: FormGroup;
  industries = INDUSTRIES_DATA;
  countries = Object.entries(COUNTRIES_DATA);
  clashedCompany: CompanyFe;
  whichCompanyInformationIsCorrect = new FormControl(null, Validators.required);
  passwordMessage = ValidationMessages.password[0].message;
  validationMinlengthMessage = ValidationMessages.password[1].message;
  confirmPasswordMessage = ValidationMessages.password[2].message;
  redirectingToLogin: boolean = false;
  isManagerOrContact: boolean = false;
  invitationType = "";

  selectedLang: Language;
  private languageSubscription: Subscription;
  activeLanguage: Language;
  lang: string;

  constructor(
    private routeService: RouteServiceFe,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateServiceFe,
    private alertService: AlertServiceFe,
    private loginService: LoginServiceFe,
    private ErrorsFe: ErrorsFe,
    languageService: LanguageService
  ) {
    super(languageService);
  }

  async ngOnInit() {
    this.loadingInfo = true;
    if (this.router.url.includes(RoutesFe.MANAGER_REGISTRATION.rootPath)) {
      this.isManagerInvitation = true;
      this.invitationType = "MANAGER";
    } else if (
      this.router.url.includes(RoutesFe.PARTNER_REGISTRATION.rootPath)
    ) {
      this.isPartnerInvitation = true;
      this.invitationType = "PARTNER";
    } else if (
      this.router.url.includes(RoutesFe.CONTACT_REGISTRATION.rootPath)
    ) {
      this.isContactInvitation = true;
      this.invitationType = "CONTACT";
    }

    this.languageSubscription = this.languageService.languageSubject.subscribe(
      (language: Language) => {
        this.activeLanguage = language;
        this.lang = language.toString();
      }
    );

    this.route.params.subscribe((params) => {
      this.uuid = params.uuid;
    });
    await this.loadSignupInfo();
    this.isNewUser.valueChanges.subscribe((changed) =>
      this.isNewUserSelected()
    );
    const registerAction = this.route.snapshot.queryParams["registerAction"];
    switch (registerAction) {
      case "register":
        this.invitationAccepted = true;
        if (this.invitationType == "PARTNER") {
          await this.checkExistingUserCompanies();
        } else {
          this.register();
        }
        break;
    }
  }

  resolveLabel(label: any): string {
    if (!label) {
      return "";
    }

    return label[this.activeLanguage?.code] || label["en"] || label;
  }

  async loadSignupInfo() {
    try {
      if (this.isManagerInvitation) {
        let invitationExtended =
          await this.stateService.getManagerInvitationExtendedByUUID(this.uuid);
        this.managerInvitationExtended = invitationExtended;
        this.invitationInfo = invitationExtended.invitation;
        this.setInfo(
          this.invitationInfo.managerFirstName,
          this.invitationInfo.managerLastName,
          this.invitationInfo.clientCompanyName,
          this.invitationInfo.managerEmail
        );
      } else if (this.isPartnerInvitation) {
        let invitationExtended =
          await this.stateService.checkExistedContactEmailForPartnerInvitation(
            this.uuid
          );
        this.partnerInvitationExtended = invitationExtended;
        this.invitationInfo = invitationExtended.invitation;
        this.setInfo(
          this.invitationInfo.primaryContactFirstName,
          this.invitationInfo.primaryContactLastName,
          this.invitationInfo.partnerName,
          this.invitationInfo.primaryContactEmail
        );
      } else if (this.isContactInvitation) {
        let invitationExtended =
          await this.stateService.getContactInvitationExtendedByUUID(this.uuid);
        this.contactInvitationExtended = invitationExtended;
        this.invitationInfo = invitationExtended.invitation;
        this.setInfo(
          this.invitationInfo.contactFirstName,
          this.invitationInfo.contactLastName,
          this.invitationInfo.contactCompanyName,
          this.invitationInfo.contactEmail
        );
      }
      this.loadingInfo = false;
    } catch (err) {
      let knownError = this.ErrorsFe.matchError(err.error);
      if (knownError == OpenErrorsFe.INVITATION_LINK_EXPIRED_ERROR) {
        this.alertService.showError(knownError.message, knownError);
      }
      this.loadingInfo = true;
    }
  }

  setInfo(
    firstName: string,
    lastName: string,
    companyName: string,
    email: string
  ) {
    this.userInfo = new FormGroup(
      {
        firstName: new FormControl(firstName, Validators.required),
        lastName: new FormControl(lastName, Validators.required),
        companyName: new FormControl(companyName, Validators.required),
        email: new FormControl(email, [
          Validators.required,
          Validators.pattern(ValidationRegex.EmailRegex),
        ]),
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(ValidationRegex.NewPasswordRegex),
        ]),
        cpassword: new FormControl(null, [Validators.required]),
      },
      { validators: this.passwordMatchValidator }
    );
  }

  isNewUserSelected() {
    if (this.isNewUser.value == "true") {
      if (this.isManagerInvitation) {
        this.userInfo.controls.email.setValue(
          this.invitationInfo["managerEmail"]
        );
      } else if (this.isPartnerInvitation) {
        this.userInfo.controls.email.setValue(
          this.invitationInfo["primaryContactEmail"]
        );
      } else if (this.isContactInvitation) {
        this.userInfo.controls.email.setValue(
          this.invitationInfo["contactEmail"]
        );
      }
    } else {
      this.userInfo.controls.email.setValue("");
    }
  }

  async register() {
    this.isSubmitted = true;
    this.loadInProgress = true;
    this.invitationAccepted = true;
    this.isNewUser.setValue("false");

    const googleUserCreds: UserCredentialWithCustomClaims =
    this.loginService.getGoogleUserCreds();
    if (!googleUserCreds) {
      this.router.navigate([RoutesFe.LOGIN.fullPath()], {
        queryParams: {
          loginAction: "register",
          loginNextUrl: this.router.url,
        },
      });
    }
    this.userInfo.controls["email"].setValue(googleUserCreds.user.email);
    try {
      if (this.isManagerInvitation) {
        this.loadInProgress = true;
        let register = new Manager_RegisterFe(
          this.invitationInfo.uuid,
          this.userInfo.controls.firstName.value,
          this.userInfo.controls.lastName.value,
          null,
          null,
          null,
          null
        );
        if (this.managerInvitationExtended.managerEmailExist) {
          register.existedUserEmail =
            this.managerInvitationExtended.invitation.managerEmail;
          register.existedUserPassword = this.userInfo.controls.password.value;
        } else if (this.isNewUser.value != "true") {
          register.existedUserEmail = this.userInfo.controls.email.value;
          register.existedUserPassword = this.userInfo.controls.password.value;
        }
        register.googleUserCreds = googleUserCreds;
        await this.stateService.registerManager(register);
        await this.stateService.acceptManagerInvitation(
          this.invitationInfo.uuid
        );
      } else if (this.isContactInvitation) {
        this.loadInProgress = true;
        let register = new Contact_RegisterFe(
          this.invitationInfo.uuid,
          null,
          null,
          null,
          null,
          null,
          null
        );
        if (this.contactInvitationExtended.contactEmailExists) {
          register.existedUserEmail =
            this.contactInvitationExtended.invitation.contactEmail;
          register.existedUserPassword = this.userInfo.controls.password.value;
        } else if (this.isNewUser.value != "true") {
          register.existedUserEmail = this.userInfo.controls.email.value;
          register.existedUserPassword = this.userInfo.controls.password.value;
        }
        register.googleUserCreds = googleUserCreds;
        await this.stateService.registerContact(register);
        await this.stateService.acceptContactInvitation(
          this.invitationInfo.uuid
        );
      }
      this.loadInProgress = false;
      this.alertService.showSuccess("Successfully Registered!");
      this.router.navigate([RoutesFe.LOGIN.fullPath()]);
      this.isSubmitted = false;
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error);
      if (
        knownError == OpenErrorsFe.AFFILIATION_ALREADY_EXISED ||
        knownError == OpenErrorsFe.UNMATCHED_PASSES_ERROR ||
        knownError == OpenErrorsFe.NO_PROPER_CREDENTIALS_ERROR ||
        knownError == OpenErrorsFe.INVITATION_LINK_EXPIRED_ERROR ||
        knownError ==
          OpenErrorsFe.CREDENTIAL_NOT_PROVIDED_ERROR_USER_ID_ALREADY_EXISED
      ) {
        this.alertService.showError(knownError.message);
      }
      this.loadInProgress = false;
      this.router.navigate([RoutesFe.LOGIN.fullPath()]);
      this.isSubmitted = false;
    }
  }

  async registerNewUser() {
    this.isSubmitted = true;
    if (this.userInfo.invalid) {
      return;
    }
    if (
      this.userInfo.controls.password.value !==
      this.userInfo.controls.cpassword.value
    ) {
      this.userInfo.controls.cpassword.setErrors({ notMatch: true });
      return;
    }
    if (this.isManagerInvitation) {
      this.loadInProgress = true;
      let register = new Manager_RegisterFe(
        this.invitationInfo.uuid,
        this.userInfo.controls.firstName.value,
        this.userInfo.controls.lastName.value,
        null,
        null,
        this.userInfo.controls.password.value,
        this.userInfo.controls.cpassword.value
      );
      await this.stateService.registerManager(register);
      await this.stateService.acceptManagerInvitation(this.invitationInfo.uuid);
    } else if (this.isContactInvitation) {
      this.loadInProgress = true;
      let register = new Contact_RegisterFe(
        this.invitationInfo.uuid,
        this.userInfo.controls.firstName.value,
        this.userInfo.controls.lastName.value,
        null,
        null,
        this.userInfo.controls.password.value,
        this.userInfo.controls.cpassword.value
      );
      await this.stateService.registerContact(register);
      await this.stateService.acceptContactInvitation(this.invitationInfo.uuid);
    }
    this.loadInProgress = false;
    this.alertService.showSuccess("Successfully Registered!");
    this.router.navigate([RoutesFe.LOGIN.fullPath()]);
    this.isSubmitted = false;
  }

  async acceptInvitation() {
    let emailExists = false;
    switch (this.invitationType) {
      case "MANAGER":
        emailExists = this.managerInvitationExtended.managerEmailExist;
        break;
      case "PARTNER":
        emailExists = this.partnerInvitationExtended.primaryContactEmailExist;
        break;
      case "CONTACT":
        emailExists = this.contactInvitationExtended.contactEmailExists;
        break;
    }

    if (emailExists) {
      this.register();
    } else {
      this.invitationAccepted = true;
      this.viewNo++;
    }
  }

  async rejectInvitation() {
    this.invitationRejected = true;
    this.viewNo++;
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get("password").value;
    const confirmPassword = formGroup.get("cpassword").value;

    if (newPassword !== confirmPassword) {
      formGroup.get("cpassword").setErrors({ notMatch: true });
    } else {
      return null;
    }
  }

  setCompanyDetailsForm(checkUserInfoForm = false) {
    this.isSubmitted = true;
    if (checkUserInfoForm) {
      if (this.userInfo.invalid) {
        return;
      } else if (
        checkUserInfoForm &&
        this.userInfo.controls.password.value !==
          this.userInfo.controls.cpassword.value
      ) {
        this.userInfo.controls.cpassword.setErrors({ notMatch: true });
        return;
      } else {
        this.viewNo = 5;
      }
    }

    this.confirmCompanyDetailsForm = new FormGroup({
      companyName: new FormControl(
        this.partnerInvitationExtended.invitation.partnerName,
        Validators.required
      ),
      industryGroup: new FormControl(
        this.partnerInvitationExtended.invitation.partnerIndustryGroup,
        Validators.required
      ),
      industry: new FormControl(
        this.partnerInvitationExtended.invitation.partnerIndustry,
        Validators.required
      ),
      country: new FormControl(
        this.partnerInvitationExtended.invitation.partnerCountryCode,
        Validators.required
      ),
      website: new FormControl(
        this.partnerInvitationExtended.invitation.partnerWebsite,
        Validators.required
      ),
    });
    this.isSubmitted = false;
  }

  async checkExistingUserCompanies() {
    this.isSubmitted = true;
    this.loadingInfo = true;
    const googleUserCreds: UserCredentialWithCustomClaims =
      this.loginService.getGoogleUserCreds();
    let invitation = new PartnerInvitation_CheckExistingUserCompaniesFe(
      this.partnerInvitationExtended.invitation.uuid,
      googleUserCreds.user.email,
      googleUserCreds
    );
    let result = await this.stateService.checkExistingUserCompanies(invitation);
    this.existingCompanies = result.existingUserCompanies;
    this.loadingInfo = false;
    if (
      this.partnerInvitationExtended.primaryContactEmailExist ||
      googleUserCreds
    ) {
      this.viewNo = 4;
    } else {
      this.viewNo = 3;
    }
    this.isSubmitted = false;
  }

  resetUserInfo() {
    this.setInfo(
      "",
      "",
      this.userInfo.controls.companyName.value,
      this.userInfo.controls.email.value
    );
  }

  async confirmRejectInvitation() {
    this.loadInProgress = true;
    let invitation = new ManagerInvitation_Reject(
      this.invitationInfo.uuid,
      this.rejectionReason.value,
      this.rejectionExplanation.value
    );
    if (this.isManagerInvitation) {
      let isRejected = await this.stateService.rejectManagerInvitation(
        invitation
      );
    } else if (this.isPartnerInvitation) {
      let isRejected = await this.stateService.rejectPartnerInvitation(
        this.invitationInfo as PartnerInvitationFe,
        this.rejectionReason.value,
        this.rejectionExplanation.value
      );
    } else if (this.isContactInvitation) {
      let isRejected = await this.stateService.rejectContactInvitation(
        invitation
      );
    }
    this.loadInProgress = false;
    this.viewNo = 3;
  }

  async continueExistingUserCompanyForm() {
    if (this.sameAsExisitingCompany.value == "-1") {
      this.setCompanyDetailsForm();
      this.viewNo++;
    } else {
      this.loadInProgress = true;
      const googleUserCreds: UserCredentialWithCustomClaims =
        this.loginService.getGoogleUserCreds();
      let details = new Partner_RegisterReuseExistedUserCompanyIdFe(
        this.partnerInvitationExtended.invitation.uuid,
        this.sameAsExisitingCompany.value,
        googleUserCreds.user.email,
        googleUserCreds
      );
      await this.stateService.registerExistingCompany(details);
      await this.stateService.acceptPartnerInvitation(
        this.partnerInvitationExtended.invitation.uuid
      );
      this.loadInProgress = false;
      this.alertService.showSuccess("Successfully Registered!");
      this.router.navigate([RoutesFe.LOGIN.fullPath()]);
    }
  }

  async continueClashedCompanyForm() {
    if (this.whichCompanyInformationIsCorrect.value == "none") {
      this.viewNo++;
    } else if (this.whichCompanyInformationIsCorrect.value !== "none") {
      this.loadInProgress = true;
      const googleUserCreds: UserCredentialWithCustomClaims =
        this.loginService.getGoogleUserCreds();
      let register = new Partner_RegisterWithIdClashResolutionFe(
        this.partnerInvitationExtended.invitation.uuid,
        this.clashedCompany.id,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        googleUserCreds,
        null,
        null
      );
      if (this.whichCompanyInformationIsCorrect.value == "partnerInvitation") {
        let responseGroup, responseIndustry;
        this.industries.forEach((data, index) => {
          if (index != 0 && !responseGroup && !responseIndustry) {
            const langCode = 'en'
            if (data.industryGroup[langCode] === this.confirmCompanyDetailsForm.controls.industryGroup.value) {
              responseGroup = data.industryGroup[langCode]

              data.industry.forEach(ind => {
                if (ind[langCode] === this.confirmCompanyDetailsForm.controls.industry.value) {
                  responseIndustry = ind[langCode]
                }
              });
            }
          }
        });

        register.overwriteIdClashingCompanyInformation = true;
        register.correctedPartnerName =
          this.confirmCompanyDetailsForm.controls.companyName.value;
        register.correctedPartnerIndustryGroup = responseGroup;
        register.correctedPartnerIndustry = responseIndustry;
        register.correctedPartnerCountryCode =
          this.confirmCompanyDetailsForm.controls.country.value;
        register.correctetdPartnerWebsite =
          this.confirmCompanyDetailsForm.controls.website.value;
      }
      if (this.isNewUser.value == "true") {
        register.correcedPrimaryContactFirstName =
          this.userInfo.controls.firstName.value;
        register.correcedPrimaryContactLastName =
          this.userInfo.controls.lastName.value;
        register.newPrimaryContactUserPassword =
          this.userInfo.controls.password.value;
        register.newPrimaryContactUserConfirmedPassword =
          this.userInfo.controls.cpassword.value;
      } else {
        register.existedPrimaryContactUserEmail = googleUserCreds.user.email;
      }
      await this.stateService.registerAfterClashResolution(register);
      await this.stateService.acceptPartnerInvitation(
        this.partnerInvitationExtended.invitation.uuid
      );
      this.loadInProgress = false;
      this.alertService.showSuccess("Successfully Registered!");
      this.router.navigate([RoutesFe.LOGIN.fullPath()]);
    }
  }

  async checkIdClash() {
    if (this.confirmCompanyDetailsForm.invalid) {
      return;
    } else {
      this.loadInProgress = true;
      const googleUserCreds: UserCredentialWithCustomClaims =
        this.loginService.getGoogleUserCreds();
      let details = new PartnerInvitation_CheckIdClashFe(
        this.partnerInvitationExtended.invitation.uuid,
        this.confirmCompanyDetailsForm.controls.companyName.value,
        this.confirmCompanyDetailsForm.controls.country.value
      );
      let result = await this.stateService.checkIdClash(details);
      if (!result.idClashCompany) {
        let nonExistingCompany = new Partner_RegisterNonExistedCompanyFe(
          this.partnerInvitationExtended.invitation.uuid,
          this.confirmCompanyDetailsForm.controls.companyName.value,
          this.confirmCompanyDetailsForm.controls.country.value,
          this.confirmCompanyDetailsForm.controls.industryGroup.value,
          this.confirmCompanyDetailsForm.controls.industry.value,
          this.confirmCompanyDetailsForm.controls.website.value,
          null,
          null,
          null,
          null,
          null,
          null
        );
        if (this.isNewUser.value == "true") {
          nonExistingCompany.correcedPrimaryContactFirstName =
            this.userInfo.controls.firstName.value;
          nonExistingCompany.correcedPrimaryContactLastName =
            this.userInfo.controls.lastName.value;
          nonExistingCompany.newPrimaryContactUserPassword =
            this.userInfo.controls.password.value;
          nonExistingCompany.newPrimaryContactUserConfirmedPassword =
            this.userInfo.controls.cpassword.value;
        } else {
          nonExistingCompany.existedPrimaryContactUserEmail =
            googleUserCreds.user.email;
          nonExistingCompany.googleUserCreds = googleUserCreds;
        }
        await this.stateService.registerNonExistingCompany(nonExistingCompany);
        await this.stateService.acceptPartnerInvitation(
          this.partnerInvitationExtended.invitation.uuid
        );
        this.loadInProgress = false;
        this.alertService.showSuccess("Successfully Registered!");
        this.router.navigate([RoutesFe.LOGIN.fullPath()]);
      } else {
        this.loadInProgress = false;
        this.viewNo++;
        this.clashedCompany = result.idClashCompany;
      }
    }
  }

  getIndustriesForSelectedGroup(): string[] {
    const langCode = 'en'
    const selectedIndustryGroupValue = this.confirmCompanyDetailsForm.controls.industryGroup.value;
    let industryGroup = this.industries.find((obj) => obj.industryGroup[langCode] === selectedIndustryGroupValue);
    if (industryGroup) {
      return industryGroup.industry.map(ind => ind[langCode]) as string[]
    }
    return [];
  }

  getAllIndustryGroups(): string[] {
    const industryGroups = this.industries.map(ind => ind.industryGroup['en']) as string[];
    if (industryGroups) {
      return industryGroups;
    }
    return [];
  }

  getCountryByKey(countryKey: string): string {
    let country = Object.entries(COUNTRIES_DATA).find((data) => data[0] === countryKey);
    if (country && country[1] && country[1].en) {
      return country[1].en
    }
    return '';
  }

  onIsNewUserChange() {
    this.loadInProgress = true;
    if (this.isNewUser.value === "false") {
      this.loadInProgress = false;
      this.router.navigate([RoutesFe.LOGIN.fullPath()], {
        queryParams: {
          loginAction: "register",
          loginNextUrl: this.router.url,
        },
      });
    } else {
      this.loadInProgress = false;
    }
  }
}
