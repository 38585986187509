import { DOCUMENT } from "@angular/common"
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { View } from "src/app/model/datahub/View"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { LoginServiceFe } from "src/app/services/LoginServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { PROJECTS_PAGES } from "../../projects.component"
import { CSRDProjectDefinition, CSRDProjectInfoFe } from "src/app/model/project/CSRDProjectInfoFe"
import { CSRDProjectInfoAddFe } from "src/app/model/project/add/CSRDProjectInfoAddFe"
import COUNTRIES_DATA from "src/app/model/COUNTRIES.json"
import _ from "lodash"
import moment from "moment"

const enum CSRD_SETUP_FORMS {
  LEARN = "LEARN",
  DEFINE = "DEFINE",
  COMPANY_INFO = "COMPANY_INFO",
}

@Component({
  selector: "create-new-csrd-project",
  templateUrl: "./create-new-csrd-project.component.html",
  styleUrls: ["./create-new-csrd-project.component.scss"],
})
export class CreateNewCSRDProjectComponent extends AbstractLanguageComponent implements OnInit {
  @Input() activeProjectsPage: string
  @Output() switchProjectsPage = new EventEmitter<PROJECTS_PAGES>()
  @Output() viewNewCsrdProject = new EventEmitter<CSRDProjectInfoFe>()
  @ViewChild("addProjectModal") addProjectModal: TemplateRef<any>
  @ViewChild("cancelNewProjectModal") cancelNewProjectModal: TemplateRef<any>
  @ViewChild("saveDraft") saveDraft: TemplateRef<any>
  @ViewChild("saveAsDraft") saveAsDraft: TemplateRef<any>
  @ViewChild("exportTableModal") exportTableModal: TemplateRef<any>
  @ViewChild("removeLevel") removeLevel: TemplateRef<any>

  loadingInProgress: boolean

  url: string = ""
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingData: boolean = false

  activeFormNo = 1
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  inProgress = true
  cardFooter: boolean = true
  cardHeader: boolean = true
  projCSRD: boolean = true
  savingAsDraft: boolean = false

  pageToolbar = [
    [
      {
        shortLabel: this.slocale("All projects"),
        longLabel: this.slocale("All projects"),
        tooltip: this.slocale("All projects"),
        icon: "la la-project-diagram",
        visible: () => true,
        actionName: "all_projects",
      },
    ],
    // [
    //   {
    //     shortLabel: this.slocale("Home"),
    //     longLabel: this.slocale("Home"),
    //     tooltip: this.slocale("Home"),
    //     icon: "la la-home",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.export"),
    //     icon: "las la-download",
    //     actionName: "export_table",
    //     visible: () => true,
    //     disabled: false
    //   }
    // ],
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.info"),
        longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
        tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
        icon: "la la-info",
        actionName: "toggle_quick_tips",
        visible: () => true,
      },
    ],
  ]

  stepsCSRD = [
    { title: this.slocale("Learn about CSRD module"), completed: false },
    { title: this.slocale("Define project"), completed: false },
    { title: this.slocale("Add company info"), completed: false },
    { title: this.slocale("Define disaggregation levels"), completed: false },
  ]

  activeCSRDSetupForm = CSRD_SETUP_FORMS.LEARN
  reportingYearInput: string
  nameInput: string
  projectNameUpdated: boolean = false
  firstReportingYearInput: string
  companyNameInput: string
  companyHQCountryInput: string = ""
  companyHQTownInput: string
  companyNoEmployeesInput: string = ""
  countries = Object.entries(COUNTRIES_DATA)
  _activeCsrdProject: CSRDProjectInfoFe

  hasError = {
    reportingYear: false,
    name: false,
    firstReportingYear: false,
    companyName: false,
    companyHQCountry: false,
    companyHQTown: false,
    companyNoEmployees: false,
  }

  errorMsg = {
    reportingYear: "",
    name: "",
    firstReportingYear: "",
    companyName: "",
    companyHQCountry: "",
    companyHQTown: "",
    companyNoEmployees: "",
  }

  showErrors: boolean = false

  @Input()
  set activeCsrdProject(project: CSRDProjectInfoFe) {
    this._activeCsrdProject = project
    this.reportingYearInput = project?.definition?.reportingYear
    this.nameInput = project?.name
    this.firstReportingYearInput = project?.definition?.firstReportingYear
    this.companyNameInput = project?.definition?.company.name
    this.companyHQCountryInput = project?.definition?.company?.hq?.country
    this.companyHQTownInput = project?.definition?.company?.hq?.town
    this.companyNoEmployeesInput = project?.definition?.company?.noEmployees
  }

  @Input() csrdProjects: CSRDProjectInfoFe[]

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {}

  toggleTips() {
    this.displayService.toggleTips()
  }

  openModal(templateRef: TemplateRef<any>, size: string = "") {
    this.modalService.show(templateRef, { class: size })
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "all_projects":
        this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
        break
      case "export_table":
        this.openModal(this.exportTableModal, "modal-md")
        break
      case "toggle_quick_tips":
        this.toggleTips()
        break
    }
  }

  update() {
    this.circles = this._document.querySelectorAll(".circle1")

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add("active-circle1")
      } else {
        circle.classList.remove("active-circle1")
      }
    })

    const actives = this._document.querySelectorAll(".active-circle1")
  }
  next() {
    // if (this.activeFormNo == 1) {
    //   this.reqDetailsForm.markAllAsTouched();
    //   if (this.reqDetailsForm.invalid) {
    //     return;
    //   }
    // } else {
    //   this.validateQuestionnaire();
    //   if (this.isQuestionnaireInvalid) {
    //     return;
    //   }
    // }

    let hasErrors = false

    if (this.activeFormNo > 1) {
      hasErrors = this.checkInputs()
    }

    if (hasErrors) {
      this.showErrors = true
      return
    }

    this.currentActive++
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  closeModal() {
    this.modalService.hide()
  }

  saveDraftModal(saveDraft: boolean) {
    this.modalService.show(this.saveDraft, { class: "modal-md" })
  }

  saveProject() {
    this.modalService.hide()
  }

  startCancelNewProject() {
    this.modalService.show(this.cancelNewProjectModal, { class: "modal-md" })
  }

  confirmCancelNewProject() {
    this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
    this.modalService.hide()
  }

  goToPrevCSRDSetupForm() {
    this.prev()
  }

  goToNextCSRDSetupForm() {
    console.log({ activeFormNo: this.activeFormNo })
    this.next()
  }

  async finishCSRDSetup(draft = false) {
    const hasErrors = this.checkInputs()
    if (hasErrors) {
      this.showErrors = true
      return
    }

    this.loadingInProgress = true
    const name = this.nameInput
    const definition: CSRDProjectDefinition = {
      isStarted: false,
      reportingYear: this.reportingYearInput,
      firstReportingYear: this.firstReportingYearInput,
      company: {
        name: this.companyNameInput,
        noEmployees: this.companyNoEmployeesInput,
        hq: {
          country: this.companyHQCountryInput,
          town: this.companyHQTownInput,
        },
      },
      csrdSettings: this._activeCsrdProject?.definition.csrdSettings || [],
    }

    let csrdProject
    const createdAt = moment().toISOString()
    if (_.isEmpty(this._activeCsrdProject?.id)) {
      csrdProject = new CSRDProjectInfoAddFe(name, draft, definition, createdAt)
      csrdProject = await this.stateService.createNewProject(csrdProject)
    } else {
      csrdProject = new CSRDProjectInfoFe({
        id: this._activeCsrdProject.id,
        name,
        draft,
        definition,
        createdAt,
      })
      csrdProject = await this.stateService.updateCsrdProject(csrdProject)
    }

    this.loadingInProgress = false
    if (!draft) {
      this.viewNewCsrdProject.emit(csrdProject)
    } else {
      this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS)
    }

    if (draft) {
      this.closeModal()
    }
  }

  resolveCountryName(locales) {
    if (!locales) {
      return ""
    }

    return locales[this.activeLanguage?.code] || locales["en"] || locales
  }

  startSaveAsDraft() {
    this.modalService.show(this.saveAsDraft, { class: "modal-md" })
  }

  reportingYearChanged() {
    if (!this.projectNameUpdated) {
      this.nameInput = `CSRD ${this.reportingYearInput}`
    }
    this.checkInputs()
  }

  projectNameChanged() {
    this.projectNameUpdated = true
    this.checkInputs()
  }

  resetErrorObject() {
    this.hasError = {
      reportingYear: false,
      name: false,
      firstReportingYear: false,
      companyName: false,
      companyHQCountry: false,
      companyHQTown: false,
      companyNoEmployees: false,
    }

    this.errorMsg = {
      reportingYear: "",
      name: "",
      firstReportingYear: "",
      companyName: "",
      companyHQCountry: "",
      companyHQTown: "",
      companyNoEmployees: "",
    }
  }

  checkInputs() {
    this.resetErrorObject()

    let hasErrors = false

    if (_.isEmpty(this.reportingYearInput)) {
      hasErrors = true
      this.hasError["reportingYear"] = true
      this.errorMsg["reportingYear"] = this.slocale("Reporting year is required")
    }

    if (_.isEmpty(this.nameInput)) {
      hasErrors = true
      this.hasError["name"] = true
      this.errorMsg["name"] = this.slocale("Project name is required")
    }

    //Check if project with same name exists
    if (!_.isEmpty(this.nameInput)) {
      for (let i = 0; i < this.csrdProjects.length; i++) {
        const project = this.csrdProjects[i]
        let currentId = this._activeCsrdProject?.id
        if (_.eq(this.nameInput.toLowerCase().trim(), project.name.toLowerCase().trim()) && project.id != currentId) {
          hasErrors = true
          this.hasError["name"] = true
          this.errorMsg["name"] = this.slocale("A project with that name already exists. Please choose a different name")
          break
        }
      }
    }

    if (!this.firstReportingYearInput) {
      hasErrors = true
      this.hasError["firstReportingYear"] = true
      this.errorMsg["firstReportingYear"] = this.slocale("First reporting year is required")
    }

    let firstReportingYear
    try {
      firstReportingYear = parseInt(this.firstReportingYearInput)
      if (firstReportingYear < 2000 || firstReportingYear >= 2100) {
        hasErrors = true
        this.hasError["firstReportingYear"] = true
        this.errorMsg["firstReportingYear"] = this.slocale("First reporting year should be 4 numbers and start with 20XX")
      }
    } catch (err) {
      hasErrors = true
      this.hasError["firstReportingYear"] = true
      this.errorMsg["firstReportingYear"] = this.slocale("First reporting year should be 4 numbers and start with 20XX")
    }

    // If we are on the second form return, otherwise continue
    if (this.activeFormNo == 2) {
      return hasErrors
    }

    if (_.isEmpty(this.companyNameInput)) {
      hasErrors = true
      this.hasError["companyName"] = true
      this.errorMsg["companyName"] = this.slocale("Company name is required")
    }

    if (_.isEmpty(this.companyHQCountryInput)) {
      hasErrors = true
      this.hasError["companyHQCountry"] = true
      this.errorMsg["companyHQCountry"] = this.slocale("Country is required")
    }

    if (_.isEmpty(this.companyHQTownInput)) {
      hasErrors = true
      this.hasError["companyHQTown"] = true
      this.errorMsg["companyHQTown"] = this.slocale("Town is required")
    }

    if (_.isEmpty(this.companyNoEmployeesInput)) {
      hasErrors = true
      this.hasError["companyNoEmployees"] = true
      this.errorMsg["companyNoEmployees"] = this.slocale("Number of employees is required")
    }

    return hasErrors
  }

  firstReportingYearInputChanged(evt) {
    if (evt.key.toLowerCase() == "e") {
      evt.preventDefault()
    }
    this.checkInputs()
  }
}
