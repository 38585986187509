import { DataGridObserverInterface } from './DataGridObserverInterface';
// import * as 
import { DataGridTableData } from '../model/DataGridTableData';
import { DataGridServiceInterface } from './DataGridServiceInterface';
import { DataGridRow } from '../model/DataGridRow';
import { DataGridTableMode } from '../model/DataGridTableMode';

export class MultiEntityDataGridIntegrationService implements DataGridServiceInterface {
  
  database: DataGridTableData= new DataGridTableData();
  referencedTables : DataGridTableData[] = [];
  currentPage =new DataGridTableData();
  observer: DataGridObserverInterface | undefined
  modified: boolean = false;
  hasPagination = false;
  rowRefIds : string[][]=[];

  mainDataGrid!:DataGridServiceInterface;
 
  registerObserver(observer: DataGridObserverInterface): void{
    this.observer = observer;
  }

  getMode(): string{
    return this.hasDataModifiedElseWhere() ? DataGridTableMode.EDIT_REFERENCE_TABLE_DATA : DataGridTableMode.SELECT_REFERENCE_TABLE_DATA;
  }

  getMainTableData(): DataGridTableData{
    return this.hasPagination ?this.currentPage : this.database;
  }

  getRowRefIds() : string[][]{
    return this.rowRefIds;
  }

  hasMainTablePagination(): boolean{
    return this.hasPagination;
  }

  loadMainTablePage(pageSize: number, pageNumber: number): Promise<DataGridTableData>{
    pageSize= Number(pageSize);  
    let previousRows = (pageNumber - 1) * pageSize;
    this.currentPage.rows = this.database.rows.slice(previousRows, previousRows + pageSize);
 
    this.database.pageSize=pageSize;
    this.database.pageNumber=pageNumber;
    this.database.rowCount=this.database.rows.length;

    this.currentPage.pageSize=pageSize;
    this.currentPage.pageNumber=pageNumber;
    this.currentPage.rowCount=this.database.rows.length;

    return  new Promise<DataGridTableData>((resolve)=>{
        resolve(this.currentPage)
    });
  }

  markDataModified(modified: boolean, isDataValid: boolean): void{
    this.modified=modified;
  }

  hasDataModifiedElseWhere(): boolean{
    return this.modified;
  }

  saveReferenceTable(table: DataGridTableData) : void{  
    
  }

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
  }

  getReferenceTables(){
    return this.referencedTables
  }

  getNoOfMinRows(): number {
    return 10;
  }

}