export class PartnerInvitation_CheckIdClashFe {
  invitationUuid: string
  correctedPartnerName: string
  correctedPartnerCountryCode: string

  constructor(
    invitationUuid: string,
    correctedPartnerName: string,
    correctedPartnerCountryCode: string
  ) {
    this.invitationUuid = invitationUuid
    this.correctedPartnerName = correctedPartnerName
    this.correctedPartnerCountryCode = correctedPartnerCountryCode
  }

  public static fromTransfer(transfer: any): PartnerInvitation_CheckIdClashFe {
    let inviation = new PartnerInvitation_CheckIdClashFe(
      transfer.invitationUuid,
      transfer.correctedPartnerName,
      transfer.correctedPartnerCountryCode
    ) 
    return inviation
  }

}