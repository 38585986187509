<div *ngIf="isLoadingData">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="{ hidden: isLoadingData }" class="card d-flex flex-column main-container" style="min-height: 81vh" [style.height.px]="containerHeight">
  <div class="card-header divider pb-0">
    <div class="row">
      <div class="col-lg-12 col-md-12 mt-0 pt-0 m-auto d-flex justify-content-center" [ngClass]="!screenSize.isXSmallSize() ? 'flex-row' : 'flex-column'">
        <ng-container *ngIf="!screenSize.isXSmallSize()">
          <div class="mt-0 pt-0 mx-0 px-0">
            <div class="mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <div class="circle1 mx-0 px-0" [ngClass]="{ 'active-circle1': viewNo >= 1 }">1</div>
              <h6 class="mt-3 ms-0 ps-3 pb-1">{{ locale("locale_key.pages.insight_detail.define_data_series") }}</h6>
              <span class="ms-2 mt-1 d-none d-sm-block" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
            </div>
          </div>
          <div class="text-start mx-0 px-0">
            <div class="mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <div class="circle1 mx-0 px-0" [ngClass]="{ 'active-circle1': viewNo >= 2 }">2</div>
              <h6 class="mt-3 ms-0 ps-3 pb-1">{{ locale("locale_key.pages.insight_detail.define_display") }}</h6>
              <span class="ms-2 mt-1 d-none d-sm-block" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
            </div>
          </div>
          <div class="text-start mx-0 px-0">
            <div class="mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
              <div class="circle1 mx-0 px-0" [ngClass]="{ 'active-circle1': viewNo >= 3 }">3</div>
              <h6 class="ms-0 mt-3 ps-3 pb-1">{{ locale("locale_key.pages.insight_detail.name_insight") }}</h6>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="screenSize.isXSmallSize()">
          <div class="d-flex flex-row justify-content-center mb-1">
            <div class="text-center" [ngClass]="screenSize.isXSmallSize() ? 'd-block' : 'd-flex'">
              <div class="col stepper text-center" *ngFor="let step of steps; let i = index" [class.active-stepper]="viewNo === i + 1 || step.completed"></div>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-around mb-1">
            <h6 class="text-muted my-auto">{{ steps[viewNo - 1].title }} ({{ viewNo }}/{{ steps.length }})</h6>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row scroll-y card-body flex-grow-1" style="max-width: 100vw; box-sizing: border-box; overflow-y: auto !important; max-height: 73vh">
    <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 mb-3 pe-0">
      <div class="card-body px-2 kpiBody scroll-y">
        <div class="step1" *ngIf="viewNo == 1">
          <div class="col-12 kpiSection">
            <p class="font-small-5">{{ locale("locale_key.pages.insight_detail.choose_data_to_show") }}</p>
            <div class="card mt-2 p-2">
              <p class="bold">{{ locale("locale_key.pages.insight_detail.data_series") }}</p>
              <div class="dataSeries">
                <div class="border br-5 ms-0 m-1 d-flex align-items-center justify-content-between" *ngFor="let ds of dataSeries; let index = index">
                  <span class="ms-2">{{ ds.label }}</span>
                  <button class="btn bold" (click)="defineDS(index)">{{ locale("locale_key.pages.insight_detail.click_to_define") }}</button>
                </div>
              </div>
              <button class="btn my-2 text-start p-0 d-inline-flex align-items-center justify-content-end" style="border: none" (click)="addDataSeries()">
                <span class="circle">
                  <i class="la la-plus"></i>
                </span>
                <span class="ms-2">{{ locale("locale_key.pages.insight_detail.add_data_series") }}</span>
              </button>
            </div>
            <div class="card mt-2 p-2">
              <p class="bold">{{ locale("locale_key.pages.insight_detail.unit") }}</p>
              <div class="border br-5 ms-0 m-1 d-flex align-items-center justify-content-between">
                <span class="ms-2" *ngIf="unitSymbol || unitLabel">{{ unitSymbol || unitLabel }}</span>
                <i class="ms-2" *ngIf="!(unitSymbol || unitLabel)">{{ locale("locale_key.pages.insight_detail.unit_not_defined") }}</i>
                <button class="btn bold" (click)="defineUnit()">{{ locale("locale_key.pages.insight_detail.click_to_define") }}</button>
              </div>
            </div>
            <div class="col-12 px-3 errorCard" *ngIf="error.taxonomy">
              <div class="icon-background d-inline mt-2 mx-2" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidwhite">
                <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 2px; top: 1px"></i>
              </div>
              <span class="font-small-2">
                {{ locale("locale_key.pages.kpi_library.kpi_wizard.setup_kpi.errors.data_category_do_not_exist") }}
              </span>
            </div>
          </div>
        </div>
        <div class="step2" *ngIf="viewNo == 2">
          <div class="col-12 kpiSection">
            <p class="font-small-5">{{ locale("locale_key.pages.insight_detail.customize_display") }}</p>
            <div class="card mt-2 p-2">
              <p class="bold">
                {{ locale("locale_key.general.data_type.time") }} <small class="text-muted ms-2">{{ locale("locale_key.pages.insight_detail.optional") }}</small>
              </p>
              <div class="border br-5 ms-0 m-1 d-flex align-items-center justify-content-between">
                <span class="ms-2" *ngIf="timeGrouping">{{ locale("locale_key.pages.insight_detail.time_per_scales", { scales: getTimeScaleText() }) }} </span>
                <i class="ms-2" *ngIf="!timeGrouping">{{ locale("locale_key.pages.insight_detail.no_grouping_defined") }}</i>
                <button class="btn bold" (click)="defineTime()">{{ locale("locale_key.pages.insight_detail.click_to_define") }}</button>
              </div>
            </div>
            <div class="card mt-2 p-2">
              <p class="bold">
                {{ locale("locale_key.insight-detail.other_groupings") }} <small class="text-muted ms-2">{{ locale("locale_key.pages.insight_detail.optional") }}</small>
              </p>
              <div class="groupings">
                <div class="border br-5 ms-0 m-1 d-flex align-items-center justify-content-between" *ngFor="let grouping of groupings; let index = index">
                  <span class="ms-2">{{ grouping.label }}</span>
                  <button class="btn bold" (click)="defineGrouping(index)">{{ locale("locale_key.pages.insight_detail.click_to_define") }}</button>
                </div>
              </div>
              <button class="btn mt-2 text-start p-0 d-inline-flex align-items-center justify-content-end" style="border: none" (click)="addGrouping()">
                <span class="circle">
                  <i class="la la-plus"></i>
                </span>
                <span class="ms-2">{{ locale("locale_key.insight-detail.add_grouping") }}</span>
              </button>
            </div>
            <div class="card mt-2 p-2">
              <p class="bold">
                {{ locale("locale_key.pages.insight_detail.data_set_specifications") }}<small class="text-muted ms-2">{{ locale("locale_key.pages.insight_detail.optional") }}</small>
              </p>
              <div class="border br-5 ms-0 m-1 d-flex align-items-center justify-content-between">
                <span class="ms-2" *ngIf="filters.length != 0">{{ locale("locale_key.pages.insight_detail.customized_data") }}</span>
                <i class="ms-2" *ngIf="filters.length == 0">{{ locale("locale_key.pages.insight_detail.no_filters_applied") }}</i>
                <button class="btn bold" (click)="defineFilter()">{{ locale("locale_key.pages.insight_detail.click_to_define") }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="step3" *ngIf="viewNo == 3">
          <div class="col-12">
            <div class="font-small-5">
              {{ locale("locale_key.pages.kpi_library.kpi_wizard.name.desc") }}
              <i class="la la-info-circle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
              <div class="dropdown-menu font-small-3 arrow p-2 info-popover">
                <p>{{ locale("locale_key.pages.kpi_library.kpi_wizard.name.info") }}</p>
              </div>
            </div>
            <input class="col-10 ms-3" [placeholder]="title" [(ngModel)]="title" onblur="this.focus()" autofocus />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12" style="max-width: 100vw; box-sizing: border-box">
      <div class="m-0 d-flex align-items-start h-100">
        <div class="card-body pt-0">
          <div class="mx-3 mt-3">
            <div class="btn-toolbar d-flex justify-content-between" role="toolbar">
              <div class="d-flex flex-row justify-content-start p-0 flex-grow-1 m-0" *ngIf="dataSeries.length > 0">
                <ul class="list-inline m-0">
                  <a class="me-3">{{ locale("locale_key.pages.insight_hub.select_view") }}</a>
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-icon action-icon" placement="top" title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.bar') }}" delay="300" (click)="chartTypeChanged('bar')">
                      <i class="las la-chart-bar"></i>
                    </button>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-icon action-icon" placement="top" title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.stack') }}" delay="300" (click)="chartTypeChanged('stack')">
                      <i class="las la-layer-group"></i>
                    </button>
                  </li>
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-icon action-icon" placement="top" title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.line') }}" delay="300" (click)="chartTypeChanged('line')">
                      <i class="las la-chart-line"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mb-0">
            <div class="p-0 border-0">
              <div class="btn-toolbar d-flex justify-content-between" role="toolbar">
                <div class="d-flex flex-row p-0 flex-grow-1 m-0" *ngIf="groupings.length > 0 && dataSeries.length > 0">
                  <ul class="list-inline m-0">
                    <a class="me-3">{{ locale("locale_key.pages.kpi_library.compare_on.title") }}</a>
                    <li class="list-inline-item">
                      <button data-bs-toggle="dropdown" type="button" class="btn btn-icon action-icon d-flex justify-content-between px-0">
                        <span class="btn-min-width-5">{{ compareOn?.label }}</span>
                        <i class="las la-angle-down my-auto me-1"></i>
                      </button>
                      <ul class="dropdown-menu btn-min-width p-0" id="menuChoices" role="menu">
                        <ng-container *ngFor="let grouping of groupings">
                          <a class="dropdown-item" *ngIf="grouping.id != compareOn?.id" (click)="onChangedSetting(grouping)">{{ grouping.label }}</a>
                        </ng-container>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div id="parent-canvas" class="card-body p-0" style="max-width: 100vw; box-sizing: border-box">
                  <canvas id="canvas" #canvas style="max-width: 100vw; box-sizing: border-box; max-height: 310px" class="w-100 h-100 p-2"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step1 scroll-y" *ngIf="viewNo == 1">
      <div class="col-12" *ngIf="define == 'ds' && selectedDataSeries">
        <div class="card mx-2">
          <div class="row-design p-3">
            <span class="bold">{{ selectedDataSeries.label }}</span>
            <button class="btn float-inline-end"><i class="la la-trash" (click)="openModal(confirmDeleteDataSeries)"></i></button>
          </div>
          <div class="card-body bg-light">
            <div class="mb-3">
              <p class="mb-0">
                {{ locale("locale_key.pages.insight_detail.result_to_show_question") }}
                <i class="la la-info-circle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
              </p>
              <div class="dropdown-menu font-small-3 arrow p-2 info-popover">
                <p>{{ locale("locale_key.pages.kpi_library.kpi_wizard.setup_kpi.select_calculation.info") }}</p>
              </div>
              <div class="mb-2">
                <div class="operation cursor" *ngFor="let function of pipelineFunctions" [ngClass]="{ selected: selectedDataSeries.calculationPipeline == function }" (click)="selectedDataSeries.calculationPipeline = function">
                  {{ locale(function.locale) }}
                </div>
              </div>
            </div>
            <ng-container *ngFor="let dataPoint of selectedDataSeries.dataPoints; let index = index; let last = last">
              <div class="mb-3 row">
                <div class="bg-light-gray ms-2 white numberLine" *ngIf="selectedDataSeries.dataPoints.length > 1">
                  <span class="m-auto">{{ index + 1 }}</span>
                </div>
                <div class="col">
                  <p class="mb-0">
                    {{ locale("locale_key.pages.insight_detail.data_selection_question") }}
                    <button class="btn float-inline-end" *ngIf="index > 0"><i class="la la-trash" (click)="selectedDataSeries.selectedDataPointIndex = index; openModal(confirmDeleteDataPoint)"></i></button>
                  </p>
                  <span>{{ locale("locale_key.pages.insight_detail.data_category_selection") }}</span>
                  <div class="row">
                    <div class="col-11">
                      <category-selection [entityKey]="dataPoint.taxonomyKey" [editedTaxonomy]="true" (entitySelected)="dataPoint.setTaxonomy($event.key)"></category-selection>
                    </div>
                    <div class="col-1 my-auto d-flex justify-content-end">
                      <div class="btn-group">
                        <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="la la-ellipsis-v ms-auto my-auto cursor size-15"></i>
                        </div>
                        <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                          <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="openModal(addDataCategory, 'modal-md')">
                            <i class="las la-plus px-1"></i>{{ locale("locale_key.pages.insight_detail.add_data_category") }}
                          </button>
                          <button class="dropdown-item underline ms-0 ps-1 text-dark" type="button" *ngIf="dataPoint.taxonomyKey && !loadingMasterTable" (click)="openMasterTable(dataPoint.taxonomyKey)">
                            <i class="la la-map"></i>{{ locale("locale_key.pages.insight_detail.show_master_table") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="dataPoint.taxonomyKey">
                    <div class="row">
                      <span class="mt-2 col-11">{{ locale("locale_key.pages.insight_detail.select_relevant_data_point") }}</span>
                      <div class="col-1 my-auto d-flex justify-content-end">
                        <div class="btn-group">
                          <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="la la-ellipsis-v ms-auto my-auto cursor size-15"></i>
                          </div>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="selectedEntityKey = dataPoint.taxonomyKey; openModal(addDataPoint, 'modal-lg')">
                              <i class="las la-plus px-1"></i>{{ locale("locale_key.pages.insight_detail.add_data_point") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2" *ngIf="dataPoint.taxonomyKey">
                      <div
                        class="card columnCard me-3 mb-2"
                        *ngFor="let c of findEntityColumnsByType(['NUMERIC'], dataPoint.taxonomyKey, selectedDataSeries.calculationPipeline?.key == 'COUNT')"
                        [ngClass]="{ selected: dataPoint.attributeKey == c.key, disabled: isDataPointDisabled(c) }"
                        (click)="setDatapointKey(dataPoint, c)"
                      >
                        <div class="row px-3">
                          <span class="p-0 col-10 label" title="{{ c.getLabel() }}">{{ c.getLabel() }}</span>
                          <span class="col-2 p-0">
                            <i class="la la-dot-circle fieldSelected" *ngIf="dataPoint.attributeKey == c.key"></i>
                            <i class="la la-circle fieldSelected" *ngIf="dataPoint.attributeKey != c.key"></i>
                          </span>
                        </div>
                        <div class="desc" title="{{ c.getDescription() }}">{{ c.getDescription() }}</div>
                        <span
                          >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ getDatatype(c.datatype) | titlecase }}</b></span
                        >
                        <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.wizard.create_questionnaire.new") }}</div>
                      </div>
                      <ng-container *ngIf="findEntityColumnsByType(['NUMERIC'], dataPoint.taxonomyKey, selectedDataSeries.calculationPipeline?.key == 'COUNT').length == 0">
                        <div class="col-11 px-3 errorCard">
                          <div class="icon-background d-inline mt-2 mx-2" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidwhite">
                            <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 2px; top: 1px"></i>
                          </div>
                          <span class="font-small-2">
                            {{ locale("locale_key.pages.insight_detail.no_data_point_found") }}
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="w-100 m-2 mb-3 additionLine" *ngIf="!last">
                <div class="line"></div>
                <span class="square"><i class="la la-plus"></i></span>
              </div>
            </ng-container>
            <button class="btn mt-2 text-start p-0 d-inline-flex align-items-center" style="border: none" (click)="selectedDataSeries.addDataPoint()">
              <span class="circle">
                <i class="la la-plus"></i>
              </span>
              <span class="ms-2">{{ locale("locale_key.pages.insight_detail.include_data_from_another_category") }}</span>
            </button>
            <div class="mt-3">
              <p class="mb-0">{{ locale("locale_key.pages.insight_detail.displayed_name_question") }}</p>
              <input class="form-control mb-0 w-50" placeholder="{{ locale('locale_key.pages.insight_detail.data_series') }}" (change)="buildChart()" [(ngModel)]="selectedDataSeries.label" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="define == 'unit'">
        <div class="card mx-2">
          <div class="row-design p-3">
            <span class="bold">{{ locale("locale_key.pages.insight_detail.unit") }}</span>
          </div>
          <div class="card-body bg-light">
            <div class="mb-3">
              <p class="mb-0">{{ locale("locale_key.pages.insight_detail.display_unit_question") }}</p>
              <span>{{ locale("locale_key.pages.insight_detail.unit_conversion_warning") }}</span>
              <unit-selector
                [mode]="'select'"
                [selectBy]="'symbol'"
                (selectedUnitChanged)="unitSymbol = $event?.symbol"
                [source]="'add_datapoint_modal'"
                [selectedUnitSymbol]="unitSymbol"
                [includeMeasurementTypes]="getMeasurementType()"
                [customUnits]="getCustomUnits()"
                [unitsByMeasurementType]="getUnitsByMeasurementType()"
              >
              </unit-selector>
            </div>
            <div class="mt-3" *ngIf="!unitSymbol || unitSymbol == ''">
              <p class="mb-0">{{ locale("locale_key.pages.emission.if_your_datapoint_doesn't_assigned_unit") }}</p>
              <input class="form-control mb-0" placeholder="{{ locale('locale_key.add_data_point.unit.title') }}" [(ngModel)]="unitLabel" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step2 scroll-y" *ngIf="viewNo == 2">
      <div class="col-12" *ngIf="define == 'grouping' && groupings[selectedGroupingIndex]">
        <div class="card mx-2">
          <div class="row-design p-3">
            <span class="bold">{{ groupings[selectedGroupingIndex].label }}</span>
            <button class="btn float-inline-end"><i class="la la-trash" (click)="deleteGrouping()"></i></button>
          </div>
          <div class="card-body bg-light">
            <div class="mb-3">
              <p class="mb-0">{{ locale("locale_key.pages.insight_detail.grouping_indicator_question") }}</p>
              <span>{{ locale("locale_key.pages.insight_detail.select_data_point_for_indicator") }}</span>
            </div>
            <ng-container *ngFor="let dataPoint of groupings[selectedGroupingIndex].dataPoints">
              <div class="mb-3 row">
                <div class="col">
                  <ng-container *ngIf="dataPoint.taxonomyKey">
                    <span> {{ getTaxonomyTitle(dataPoint.taxonomyKey) }}</span>
                    <div class="mt-2" *ngIf="dataPoint.taxonomyKey">
                      <div class="card columnCard me-3 mb-2" *ngFor="let c of findEntityColumnsByType(['STRING', 'BOOLEAN'], dataPoint.taxonomyKey)" [ngClass]="{ selected: dataPoint.attributeKey == c.key }" (click)="dataPoint.attributeKey = c.key">
                        <div class="row px-3">
                          <span class="p-0 col-10 label" title="{{ c.getLabel() }}">{{ c.getLabel() }}</span>
                          <span class="col-2 p-0">
                            <i class="la la-dot-circle fieldSelected" *ngIf="dataPoint.attributeKey == c.key"></i>
                            <i class="la la-circle fieldSelected" *ngIf="dataPoint.attributeKey != c.key"></i>
                          </span>
                        </div>
                        <div class="desc" title="{{ c.getDescription() }}">{{ c.getDescription() }}</div>
                        <span
                          >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ getDatatype(c.datatype) | titlecase }}</b></span
                        >
                        <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.wizard.create_questionnaire.new") }}</div>
                      </div>
                      <ng-container *ngIf="findEntityColumnsByType(['STRING'], dataPoint.taxonomyKey).length == 0">
                        <div class="col-11 px-3 errorCard">
                          <div class="icon-background d-inline mt-2 mx-2" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidwhite">
                            <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 2px; top: 1px"></i>
                          </div>
                          <span class="font-small-2">
                            {{ locale("locale_key.pages.insight_detail.no_data_point_found") }}
                          </span>
                        </div>
                        <div class="col-1 my-auto d-flex justify-content-end">
                          <div class="btn-group">
                            <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v ms-auto my-auto cursor size-15"></i>
                            </div>
                            <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                              <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="selectedEntityKey = dataPoint.taxonomyKey; openModal(addDataPoint, 'modal-lg')">
                                <i class="las la-plus px-1"></i>{{ locale("locale_key.pages.insight_detail.add_data_point") }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <div class="mb-3">
              <p class="mb-0">{{ locale("locale_key.pages.insight_detail.grouping_name_question") }}</p>
              <input class="form-control mb-0 w-50" placeholder="{{ locale('locale_key.pages.insight_detail.grouping_term') }}" (change)="buildChart()" [(ngModel)]="groupings[selectedGroupingIndex].label" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="define == 'timeGrouping'">
        <div class="card mx-2">
          <div class="row-design p-3">
            <span class="bold">{{ locale("locale_key.pages.insight_detail.time_term") }}</span>
          </div>
          <div class="card-body bg-light">
            <div class="mb-3">
              <p class="mb-0">{{ locale("locale_key.pages.insight_detail.time_parameters_question") }}</p>
            </div>
            <div class="timeOption cursor" [ngClass]="{ selected: !timeGrouping }" (click)="timeGrouping = null">
              {{ locale("locale_key.pages.insight_detail.no_time_parameters") }}
            </div>
            <div class="timeOption cursor" [ngClass]="{ selected: timeGrouping }" (click)="setTimeGrouping()">
              {{ locale("locale_key.pages.insight_detail.group_and_limit_by_time") }}
            </div>
            <div *ngIf="timeGrouping">
              <div class="my-3">
                <p class="mb-0">{{ locale("locale_key.pages.insight_detail.time_information_data_point_question") }}</p>
                <span>{{ locale("locale_key.pages.insight_detail.select_data_point_each_category") }}</span>
              </div>
              <ng-container *ngFor="let dataPoint of timeGrouping.dataPoints">
                <div class="mb-3 row">
                  <div class="col">
                    <ng-container *ngIf="dataPoint.taxonomyKey">
                      <span> {{ getTaxonomyTitle(dataPoint.taxonomyKey) }}</span>
                      <div class="mt-2" *ngIf="dataPoint.taxonomyKey">
                        <div class="card columnCard me-3 mb-2" *ngFor="let c of findEntityColumnsByType(['DATE'], dataPoint.taxonomyKey)" [ngClass]="{ selected: dataPoint.attributeKey == c.key }" (click)="dataPoint.attributeKey = c.key">
                          <div class="row px-3">
                            <span class="p-0 col-10 label" title="{{ c.getLabel() }}">{{ c.getLabel() }}</span>
                            <span class="col-2 p-0">
                              <i class="la la-dot-circle fieldSelected" *ngIf="dataPoint.attributeKey == c.key"></i>
                              <i class="la la-circle fieldSelected" *ngIf="dataPoint.attributeKey != c.key"></i>
                            </span>
                          </div>
                          <div class="desc" title="{{ c.getDescription() }}">{{ c.getDescription() }}</div>
                          <span
                            >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ getDatatype(c.datatype) | titlecase }}</b></span
                          >
                          <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.wizard.create_questionnaire.new") }}</div>
                        </div>
                        <ng-container *ngIf="findEntityColumnsByType(['DATE'], dataPoint.taxonomyKey).length == 0">
                          <div class="col-11 px-3 errorCard">
                            <div class="icon-background d-inline mt-2 mx-2" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidwhite">
                              <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 2px; top: 1px"></i>
                            </div>
                            <span class="font-small-2">
                              {{ locale("locale_key.pages.insight_detail.no_data_point_found") }}
                            </span>
                          </div>
                          <div class="col-1 my-auto d-flex justify-content-end">
                            <div class="btn-group">
                              <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-v ms-auto my-auto cursor size-15"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="selectedEntityKey = dataPoint.taxonomyKey; openModal(addDataPoint, 'modal-lg')">
                                  <i class="las la-plus px-1"></i>{{ locale("locale_key.pages.insight_detail.add_data_point") }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <div class="mb-3">
                <p class="mb-0">{{ locale("locale_key.pages.insight_detail.split_time_periods_question") }}</p>
                <div class="ms-3">
                  <span class="pe-2">
                    <input type="checkbox" id="week" class="me-2" [checked]="isTimeScaleSelected('week')" value="week" (click)="toggleTimeScale($event)" />
                    <label for="week">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.split_time_periods.option.week") }}</label>
                  </span>
                  <span class="pe-2">
                    <input type="checkbox" id="month" class="me-2" [checked]="isTimeScaleSelected('month')" value="month" (click)="toggleTimeScale($event)" />
                    <label for="month">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.split_time_periods.option.month") }}</label>
                  </span>
                  <span class="pe-2">
                    <input type="checkbox" id="quarter" class="me-2" [checked]="isTimeScaleSelected('quarter')" value="quarter" (click)="toggleTimeScale($event)" />
                    <label for="quarter">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.split_time_periods.option.quarter") }}</label>
                  </span>
                  <span class="pe-2">
                    <input type="checkbox" id="year" class="me-2" [checked]="isTimeScaleSelected('year')" value="year" (click)="toggleTimeScale($event)" />
                    <label for="year">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.split_time_periods.option.year") }}</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="define == 'filter'">
        <div class="card mx-2">
          <div class="row-design p-3">
            <span class="bold">{{ locale("locale_key.pages.insight_detail.data_set_specifications") }}</span>
          </div>
          <div class="card-body bg-light">
            <div class="mb-3">
              <p class="mb-0">{{ locale("locale_key.pages.insight_detail.specify_data_set_question") }}</p>
              <div class="timeOption cursor" [ngClass]="{ selected: filters.length == 0 }" (click)="filters = []">
                {{ locale("locale_key.pages.insight_detail.use_all_data_option") }}
              </div>
              <div class="timeOption cursor" [ngClass]="{ selected: filters.length != 0 }" (click)="setFilter()">
                {{ locale("locale_key.pages.insight_detail.use_customised_data_option") }}
              </div>
            </div>
            <div class="mb-3" *ngIf="filters.length != 0">
              <ng-container *ngFor="let entity of getUniqueEntitiesArray(); let index = index">
                <span class="mt-3"> {{ getTaxonomyTitle(entity.key) }}</span>
                <div class="row ms-1 mt-1">
                  <div class="col-lg-3 col-md-12">
                    <div class="row d-flex align-items-center">
                      <select class="filterCondition mb-0 ms-1 form-control col-6" [(ngModel)]="filters[index].filterCondition">
                        <option value="include">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.type.only_include") }}</option>
                        <option value="exclude">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.type.exclude") }}</option>
                      </select>
                      <span class="col-lg-5 px-auto col-md-12">{{ locale("locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.data_where.title") }}</span>
                    </div>
                  </div>
                  <div class="col-lg-8 ps-1 col-md-12">
                    <conditions-builder [showEditMode]="true" [calculation]="filters[index].calculation" [level3DataCategory]="entity"></conditions-builder>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-2 card-footer float-end my-auto">
    <span>
      <div class="connectedActionBtn p-1 d-flex flex-row justify-content-start align-items-center" *ngIf="!displayService.isConnectedActionsTabOpen && categoryService.totalChanges() > 0">
        <div class="icon-circle-dark d-flex align-items-center" style="max-width: 40px !important">
          <span class="text-white">{{ categoryService.totalChanges() }} </span>
        </div>
        <span>
          {{ locale("locale_key.pages.insight_detail.connected_actions") }}
        </span>
        <i class="las la-external-link-square-alt" (click)="displayService.openConnectedActionsTab()"></i>
      </div>
    </span>
    <span class="float-end">
      <ng-container *ngIf="!inProgress">
        <button type="submit" class="btn tertiary-button custom-button m-1" (click)="openModal(confirmExit)">{{ locale("locale_key.pages.kpi_library.kpi_wizard.button.cancel") }}</button>
        <button type="submit" class="btn secondary-button custom-button m-1" *ngIf="viewNo > 1" (click)="previous()">{{ locale("locale_key.pages.kpi_library.kpi_wizard.button.previous") }}</button>
        <button type="submit" class="btn primary-button custom-button m-1 me-2" *ngIf="viewNo == 1" (click)="next()" [disabled]="isDisabled()">{{ locale("locale_key.pages.kpi_library.kpi_wizard.button.next") }}</button>
        <button type="submit" class="btn primary-button custom-button m-1 me-2" *ngIf="viewNo == 2" (click)="next()" [disabled]="isDisabled()">{{ locale("locale_key.pages.kpi_library.kpi_wizard.button.next") }}</button>
        <button type="submit" class="btn primary-button custom-button m-1 me-2" *ngIf="viewNo == 3" (click)="openConnectedActionsModal(confirmDeploy)">
          {{ edit ? locale("locale_key.pages.kpi_library.kpi_wizard.button.save_changes") : locale("locale_key.general.buttons.create") }}
        </button>
      </ng-container>
      <div class="ball-grid-pulse loader-primary" *ngIf="inProgress">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </span>
  </div>
</div>

<!-- <ng-template #dataCategoryChangedModal>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel10">
      <i class="ft-alert-circle white p-0 " style="font-size: 1.5rem; background-color:var(--orange);border-radius: 50%;"></i> {{ locale('locale_key.pages.kpi_library.kpi_wizard.data_category_changed_modal.title') }}
    </h4>
    <button type="button" class="btn close" (click)="cancelTaxonomyChange()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="categoryChangedTo.itemNo == 0">{{ locale('locale_key.pages.kpi_library.kpi_wizard.data_category_changed_modal.main_topic_changed.desc', {dataCategoryLabel: categoryChangedTo.label}) }}</p>
    <p *ngIf="categoryChangedTo.itemNo == 1">{{ locale('locale_key.pages.kpi_library.kpi_wizard.data_category_changed_modal.sub_topic_changed.desc', {dataCategoryLabel: categoryChangedTo.label}) }}</p>
    <p *ngIf="categoryChangedTo.itemNo == 2">{{ locale('locale_key.pages.kpi_library.kpi_wizard.data_category_changed_modal.data_category_changed.desc', {dataCategoryLabel: categoryChangedTo.label}) }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn secondary-button custom-button" (click)="cancelTaxonomyChange()">{{ locale('locale_key.pages.kpi_library.kpi_wizard.data_category_changed_modal.button.cancel') }}</button>
    <button type="button" class="btn primary-button custom-button" (click)="confirmTaxonomyChange()">{{ locale('locale_key.pages.kpi_library.kpi_wizard.data_category_changed_modal.button.yes') }}</button>
  </div>
</ng-template> -->

<ng-template #confirmExit>
  <div class="modal-header main-modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel10">
      <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i> {{ locale("locale_key.pages.insight_detail.exit_insight_creator") }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale("locale_key.pages.insight_detail.leave_without_finalizing_warning") }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn secondary-button custom-button" (click)="cancel()">{{ locale("locale_key.pages.insight_detail.leave_page") }}</button>
    <button type="button" class="btn primary-button custom-button" (click)="closeModal()">{{ locale("locale_key.pages.insight_detail.return_to_page") }}</button>
  </div>
</ng-template>

<ng-template #confirmDeleteDataPoint>
  <div class="modal-header main-modal-header" style="border-bottom: none" *ngIf="selectedDataSeries">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0"><i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i> {{ locale("locale_key.pages.insight_detail.remove_data_point_in_series") }}</h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale("locale_key.pages.insight_detail.confirm_remove_data_category") }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
    <button type="button" class="btn primary-button custom-button" (click)="deleteDataPoint()">{{ locale("locale_key.general.buttons.remove") }}</button>
  </div>
</ng-template>

<ng-template #confirmDeleteDataSeries>
  <div class="modal-header main-modal-header" style="border-bottom: none" *ngIf="selectedDataSeries">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i> {{ locale("locale_key.pages.insight_detail.remove_data_series", { title: selectedDataSeries.label }) }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale("locale_key.pages.insight_detail.confirm_remove_data_series") }}</p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
    <button type="button" class="btn primary-button custom-button" (click)="deleteDataSeries()">{{ locale("locale_key.general.buttons.remove") }}</button>
  </div>
</ng-template>

<ng-template #addDataCategory>
  <add-data-category (closeEvent)="closeModal()"></add-data-category>
</ng-template>

<ng-template #addDataPoint>
  <add-data-point (closeEvent)="closeModal()" [entityKey]="selectedEntityKey"></add-data-point>
</ng-template>

<ng-template #confirmDeploy>
  <div class="modal-header main-modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      {{ locale("locale_key.pages.insight_detail.create_insight") }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!inProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ locale("locale_key.pages.insight_detail.create_insight_warning") }}</p>
    <p>{{ locale("locale_key.pages.insight_detail.changes_to_be_applied") }}</p>
    <div class="row mb-2" *ngIf="categoryService.newEntities.length > 0">
      <div class="accordion" id="entitesAccordion">
        <h2 class="accordion-header" id="entityHeading">
          <div class="accordion-button bg-light-gray" type="button" data-bs-toggle="collapse" data-bs-target="#entityCollapse" aria-expanded="true" aria-controls="entityCollapse">
            <i class="las la-table fs-3 pe-2"></i>
            {{ locale("locale_key.pages.insight_detail.data_categories_to_be_added") }}
          </div>
        </h2>
        <div id="entityCollapse" class="accordion-collapse collapse show" aria-labelledby="entityHeading" data-bs-parent="#entitesAccordion">
          <span class="badge badge-pink w-auto py-auto m-2" *ngFor="let entity of categoryService.newEntities">
            {{ entity.getTaxonomyTitle(taxonomyInfo) }}
          </span>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="categoryService.dataPointsLength() > 0">
      <div class="accordion" id="dataPointAcc">
        <h2 class="accordion-header" id="dpHeading">
          <div class="accordion-button bg-light-gray" type="button" data-bs-toggle="collapse" data-bs-target="#dpCollapse" aria-expanded="true" aria-controls="dpCollapse">
            <i class="las la-table fs-3 pe-2"></i>
            {{ locale("locale_key.pages.insight_detail.data_points_to_be_added") }}
          </div>
        </h2>
        <div id="dpCollapse" class="accordion-collapse collapse show" aria-labelledby="dpHeading" data-bs-parent="#dataPointAcc">
          <ng-container *ngFor="let entity of categoryService.getNewDataPointEntites()">
            <span class="badge badge-info w-auto py-auto m-2 mb-0">
              {{ entity.getTaxonomyTitle(taxonomyInfo) }}
            </span>
            <ng-container *ngFor="let dp of categoryService.getNewDataPoints(entity); let last = last">
              <span class="badge badge-pink w-auto py-auto mx-2">
                {{ dp.getLabel() }}
              </span>
              <ng-container *ngIf="!last">/</ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!inProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="deployTaxonomy()">{{ locale("locale_key.pages.insight_detail.create_and_apply") }}</button>
    </ng-container>
    <div class="ball-grid-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>
