import { OrganizationEntityFe } from "src/app/model/organization/OrganizationEntityFe";
import { OrganizationInfoFe } from "src/app/model/organization/OrganizationInfoFe";
import { OrganizationTableInfoFe } from "src/app/model/organization/OrganizationTableInfoFe";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { DataGridRow } from "../projects/data-grid-ui/model/DataGridRow";
import { DataGridStatus } from "../projects/data-grid-ui/model/DataGridStatus";
import { DataGridTableData } from "../projects/data-grid-ui/model/DataGridTableData";
import { DataGridTableMode } from "../projects/data-grid-ui/model/DataGridTableMode";
import { DataGridObserverInterface } from "../projects/data-grid-ui/service/DataGridObserverInterface";
import { DataGridServiceInterface } from "../projects/data-grid-ui/service/DataGridServiceInterface";

export class OrganizationTableDataGridIntergationService implements DataGridServiceInterface {
  entity: OrganizationEntityFe
  orgInfo: OrganizationInfoFe
  stateService: StateServiceFe

  allTableDatas: TableDataFe[]
  mainTableData: TableDataFe
  dataGridMainTableData: DataGridTableData
  dataGridAncestorTableData: DataGridTableData[]
    
  observer: DataGridObserverInterface | undefined
  mode: string = DataGridTableMode.EDIT_REFERENCE_TABLE_DATA
  public modified: boolean = false

  loadInProgress: boolean = false;
  saveInProgress = false

  
  constructor(entity: OrganizationEntityFe, orgInfo: OrganizationInfoFe, allTableDatas: TableDataFe[], stateService: StateServiceFe) {
    this.entity = entity
    this.orgInfo = orgInfo
    this.stateService = stateService
    this.refreshTables(allTableDatas)
    this.stateService.orgTablesUpdatedSubject.subscribe(async(tableDatas: TableDataFe[]) => {
      this.refreshTables(tableDatas)
      await this.observer.renderNewDataTable()
    })
  }

  refreshTables(allTableData: TableDataFe[]) {
    this.allTableDatas = allTableData
    this.mainTableData = allTableData.find(td => td.key == this.entity.key)
    this.dataGridMainTableData = this.mainTableData.toDataGridTableData()
    let ancestors = this.entity.getAllAncestors(this.orgInfo)
    let ancestorKeys = ancestors.map(a => a.key)
    let references = this.allTableDatas.filter(t => ancestorKeys.includes(t.key))
    this.dataGridAncestorTableData = references.map(rtd => rtd.toDataGridTableData())
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMode(): string {
    return this.mode
  }

  getMainTableData(): DataGridTableData {
    let tableData = this.mainTableData.toDataGridTableData() 
    return tableData
  }

  hasMainTablePagination(): boolean {
    return false
  }

  async loadMainTablePage(pageSize: number = 10, pageNumber: number = 1): Promise<DataGridTableData> {
    return this.dataGridMainTableData
  }

  async saveChanges() {
    this.saveInProgress = true
    let modifedTable: DataGridTableData = this.observer.getModifiedTableData()
    modifedTable.rows = modifedTable.rows.filter(row => row.status == DataGridStatus.MODIFIED || row.status == DataGridStatus.ADDED || row.status == DataGridStatus.DELETED)
    let modifedTableFe = TableDataFe.fromDataGridTableData(modifedTable)

    let modifiedRefTables: DataGridTableData[] = this.observer.getModifiedReferenceTables()
    modifiedRefTables.forEach(mrt=>{
      mrt.rows = mrt.rows.filter(row => row.status == DataGridStatus.MODIFIED || row.status == DataGridStatus.ADDED || row.status == DataGridStatus.DELETED)
    })
    let modifedRefTablesFe: TableDataFe[] = []
    modifiedRefTables.forEach(mrt=>modifedRefTablesFe.push(TableDataFe.fromDataGridTableData(mrt)))

    let updated = await this.stateService.updateOrganizationTableData([modifedTableFe, ...modifedRefTablesFe])
    this.saveInProgress = false
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {
    this.modified = modified
  }

  //if a reference table is modified in anothr tab
  hasDataModifiedElseWhere(): boolean {
    return false
  }

  //save reference table 
  saveReferenceTable(table: DataGridTableData): void {

  }

  getReferenceTables(): DataGridTableData[] {
    return this.dataGridAncestorTableData
  }

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
  }

  getNoOfMinRows(): number {
    return 10;
  }
}