<div class="card m-0">
  <div class="card-header d-flex justify-content-between pb-2">
    <div class="card-title">{{locale('locale_key.pages.data_categories.emissions_portal_modal.title')}}</div>
    <div class="heading-elements">
      <ul class="list-inline d-inline">
        <li><a (click)='cancel()'><i class="las la-times"></i></a></li>
      </ul>
    </div>
  </div>

  <div class="card-body">
    <div class="row make-column">
      <div class="col-xl-7 col-12 mb-3 pe-0">
        <!-- adds border-0 to make the div borderless -->
        <div class="card h-60 border-0">
          <div class="search-wrapper text-center py-3">
            <div class="row">
              <div class="col-12">
                <div class="bar mx-3">
                  <input type="text" class="searchbar" [(ngModel)]="searchSentence" placeholder="{{locale('locale_key.pages.data_categories.emissions_portal_modal.placeholder.search')}}"
                    (keyup.enter)="searchInDB(searchSentence)" (input)="onSearchSentenceChange($event.target.value)">
                </div>  
              </div>
              <div class="col-12">
                <div class="dropdown-menu show scrollable" *ngIf="searchSentenceSuggestions.length > 0 ">
                  <ng-container *ngFor="let suggestion of searchSentenceSuggestions">
                    <button class="dropdown-item" type="button" (click)="selectSearchSuggestion(suggestion)">
                      {{suggestion}}
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
            <div class="content-wrapper p-0 ps-3">
              <div class="d-flex">
                <label class="me-3">{{locale('locale_key.pages.data_categories.emissions_portal_modal.databases')}}:</label>
              <ng-container *ngFor="let db of databases">
                <div class="me-4">
                  <label class="switch">
                    <input type="checkbox" [(ngModel)]="db.selected">
                    <span class="slider round"></span>
                  </label>
                  <label>{{db.name}}</label>
                </div>  
              </ng-container>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="wrap ps-3" style=" overflow-x: auto;">
              <ul id="tabs" class="nav nav-tabs" role="tablist">
                <li class="nav-item" *ngFor="let result of searchResults; let i = index">
                  <a (click)="selectResultPage(i)" class="nav-link active" data-bs-toggle="tab" role="tab"><span>{{result.metadata.name}}</span> </a>
                </li>
              </ul>
              <div id="content" class="tab-content" role="tablist">
                <div *ngFor="let result of searchResults; let i = index">
                  <div *ngIf="pageIndex == i" class="card tab-pane fade show active" role="tabpanel">
                    <div class="row">
                      <div class="col-12">
                        <div class="table-responsive" style="height: 40vh;">
                          <table class="table table-striped" style="font-size: 0.8rem; font-weight: 400;">
                            <thead>
                              <tr>
                                <ng-container *ngFor="let column of result.metadata.columns">
                                  <th class="p-1"style="font-weight: 500;" *ngIf="column.summary">{{ column.label }}</th>
                                </ng-container>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let record of result.data; let j = index">
                                <tr class="clickable-row" (click)="selectRecord(j, result.metadata.columns, record)"  [ngClass]="{'unread': selectedRowIndex == j}"> 
                                  <ng-container *ngFor="let column of result.metadata.columns">
                                    <td class="p-1" style="font-weight: 400;" *ngIf="column.summary">{{ record[column.name] }}</td>
                                  </ng-container>
                                </tr>
                              </ng-container>    
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- adds border-0 to make the div borderless -->
      <div class="col-xl-5 col-12 ps-3">
        <div class="card h-60 border-0">
          <div class="card-header" *ngIf="selectedRecord">
            <div class="card-title">{{locale('locale_key.pages.data_categories.emissions_portal_modal.emission_factor_details')}}</div>
          </div>
          <div class="card-body" *ngIf="selectedRecord">
            <ng-container *ngFor="let column of selectedRecordColumns">
              <div class="form-group row mb-0">
                <div class="col-4">
                  <label class="label-control">{{ column.label }}</label>
                </div>
                <div class="col-8">
                  <input type="text" class="form-control" style="height: 1.5rem;" value="{{ selectedRecord[column.name] }}" readonly>  
                </div>
              </div>
            </ng-container>
          </div>
        </div>
    </div>
  </div>  
  <div class="card-footer">
    <div class="d-flex float-end">
        <button type="reset" class="btn btn-outline-danger btn-block custom-button me-3 mt-3" (click)="cancel()">{{locale('locale_key.general.buttons.cancel')}}</button>  
        <button type="submit" class="btn btn-outline-info btn-block mt-0 mt-3 me-3" (click)="notifySelection(selectedRecord)" *ngIf="selectedRecord">{{locale('locale_key.pages.data_categories.emissions_portal_modal.button.select_emission')}}</button>
    </div>
  </div>
</div>
