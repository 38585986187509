<div class="card main-card m-0" style="overflow: auto; max-height: inherit;">
  <ng-container *ngIf="mode == 'extract'">
    <div class="main-modal-header modal-header pb-50">
      <h4 class="modal-title white">{{locale('locale_key.file_viewer.extract_data_from.title')}} {{ inputFile.label }}</h4>
      <button type="button" class="ml-auto close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="false" class="list-inline white"
          ><a (click)="cancel()"><i class="las la-times" ></i></a
        ></span>
      </button>
    </div>
  </ng-container>
  <div style="  height: 50rem; overflow-x: hidden">
    <div *ngIf="inputFile.inProgress()">
      <div class="loader-wrapper mt-5">
        <div class="loader-container">
          <div class="ball-grid-pulse loader-primary">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="inputFile.isMainViewer() && !inputFile.inProgress()">
      <div *ngIf="inputFile.isPdf() || inputFile.isImage()">
        <div class="row">
          <div class="col-10 main-section p-0">
            <div class="card-body p-0" style="height: 100%">
              <data-extractor [imgExtractor]="this"></data-extractor>
            </div>
          </div>
          <div class="col-2 tool-bar-col" *ngIf="mode == 'extract'">
            <button type="button" class="btn btn-icon action-icon crop-button mb-1 mr-1" container="body" containerClass="tooltipClass" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.file_viewer.extract_data.button.crop')}}" (click)="convertToImg(inputFile)" *ngIf="!inputFile.isConvertedToImg"><i class="ft-crop"></i></button>
            <button type="button" class="btn btn-icon action-icon back-button mb-1 mr-2" container="body" containerClass="tooltipClass" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.file_viewer.extract_data.button.back_to_original_file')}}" (click)="setMainViewer()" *ngIf="inputFile.isConvertedToImg && !inputFile.isCropped"><i class="ft-rewind"></i></button>
            <button type="button" class="btn btn-icon action-icon crop-button mr-1" container="body" containerClass="tooltipClass" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.file_viewer.extract_data.button.convert_to_text')}}" (click)="convertCroppedToText(inputFile)" *ngIf="inputFile.isConvertedToImg && !inputFile.isCropped">
              <i class="las la-file-alt"></i>
            </button>
            <button type="button" class="btn btn-icon action-icon back-button mb-1 mr-2" container="body" containerClass="tooltipClass" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.file_viewer.extract_data.button.back_to_cropping')}}" (click)="setCropperViewer()" *ngIf="inputFile.isConvertedToImg && inputFile.isCropped"><i class="ft-rewind"></i></button>
          </div>
        </div>
      </div>
      <div class="card-body p-0" *ngIf="inputFile.isDoc()">
        <ngx-doc-viewer [url]="inputFile.publicUrl" viewer="office"> </ngx-doc-viewer>
      </div>
      <div class="card-body p-0" *ngIf="inputFile.isExcel()">
        <datagrid-excel-viewer [excelViewerService]="this"></datagrid-excel-viewer>
      </div>
      <div class="card-body p-0" *ngIf="inputFile.isTable()">
        <datagrid-table-editor [dataGrid]="inputFile.dataGridService"></datagrid-table-editor>
      </div>
    </div>
    <div *ngIf="inputFile.isTextViewer() && !inputFile.inProgress()">
      <div class="row">
        <div class="col-11 main-section p-0">
          <div class="card-body p-0">
            <pre style="font-family: monospace">{{ inputFile.txtContent }}</pre>
          </div>
        </div>
        <div class="col-1 tool-bar-col" *ngIf="mode == 'extract'">
          <button type="button" class="btn btn-icon action-icon back-button mb-1 mr-2" container="body" containerClass="tooltipClass" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.file_viewer.extract_data.button.back_to_original_file')}}" (click)="inputFile.setMainViewer()"><i class="ft-rewind"></i></button>
          <button type="button" class="btn btn-icon action-icon crop-button mb-1 mr-1" container="body" containerClass="tooltipClass" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.file_viewer.extract_data.button.back_to_cropping')}}" (click)="inputFile.setCropperViewer()"><i class="ft-crop"></i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="!inputFile.inProgress() && mode != 'view'">
    <div class="modal-footer mb-0">
      <button type="submit" class="btn secondary-button custom-button mb-0" (click)="cancel()">{{locale('locale_key.general.buttons.cancel')}}</button>
      <button type="submit" class="btn btn-add custom-button mb-0" [disabled]="!(isExcelDataMarked && observer) && !isExtractorViewer()" (click)="observer.mapToTaxonomy()">{{locale('locale_key.file_viewer.extract_data.button.extract')}}</button>
    </div>
  </div>
</div>
