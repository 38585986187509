import { RecipientInfoFe } from "./RecipientInfoFe";
import { DashboardChartFe } from "./DashboardChartFe";
import { Dashboard_AddFe } from "./add/Dashboard_AddFe";

export class DashboardFe {
  public id: string; 
  public name: string;
  public description: string;
  public charts: DashboardChartFe[] = []
  public sharedId: string | null
  public isDirty = false
  public recipients: RecipientInfoFe[] = []

  confirmRemoval = false

  constructor (
    id: string,
    name: string,
    description: string,
    charts: DashboardChartFe[],
    sharedId: string | null
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.charts = charts
    this.sharedId = sharedId
  }

  sortChartsByOrdinal() {
    this.charts = this.charts.sort((c1, c2) => {
      if (c1.ordinal < c2.ordinal) return -1 
      else return 1 
    })
  }

  updateChartsOrdinal(oldIndex, newIndex) {
    this.charts.splice(newIndex, 0, this.charts.splice(oldIndex, 1)[0])
    this.charts.forEach((chart, index) => chart.ordinal = index)
  }

  public static fromTransfer(transfer: any): DashboardFe {
    let d = new DashboardFe(
      transfer['id'],
      transfer['name'],
      transfer['description'],
      transfer.charts.map(c => DashboardChartFe.fromTransfer(c)),
      transfer.sharedId
    )
    d.sortChartsByOrdinal()
    return d
  }

  toTransfer(): DashboardFe {
    let transfer = new DashboardFe(
      this.id,
      this.name,
      this.description,
      this.charts.map(c => c.toTransfer()),
      this.sharedId
    ) 
    return transfer
  }

  public static fromDashboardAdd (added: Dashboard_AddFe): DashboardFe {
    let dashboard = new DashboardFe (
      added.id,
      added.name,
      added.description,
      added.charts,
      null
    )
    return dashboard
  }
}