export class Partner_RegisterWithIdClashResolutionFe {
  invitationUuid: string
  idClashingCompanyId: string
  overwriteIdClashingCompanyInformation: boolean | undefined
  correctedPartnerName: string
  correctedPartnerCountryCode: string
  correctedPartnerIndustryGroup: string
  correctedPartnerIndustry: string
  correctetdPartnerWebsite: string
  correcedPrimaryContactFirstName: string
  correcedPrimaryContactLastName: string
  existedPrimaryContactUserEmail: string | undefined
  googleUserCreds: any
  newPrimaryContactUserPassword: string | undefined
  newPrimaryContactUserConfirmedPassword: string | undefined

  constructor(
    invitationUuid: string,
    idClashingCompanyId: string,
    overwriteIdClashingCompanyInformation: boolean | undefined,
    correctedPartnerName: string,
    correctedPartnerCountryCode: string,
    correctedPartnerIndustryGroup: string,
    correctedPartnerIndustry: string,
    correctetdPartnerWebsite: string,
    correcedPrimaryContactFirstName: string,
    correcedPrimaryContactLastName: string,
    existedPrimaryContactUserEmail: string | undefined,
    googleUserCreds: any,
    newPrimaryContactUserPassword: string | undefined,
    newPrimaryContactUserConfirmedPassword: string | undefined
  ) {
    this.invitationUuid = invitationUuid
    this.idClashingCompanyId = idClashingCompanyId
    this.overwriteIdClashingCompanyInformation = overwriteIdClashingCompanyInformation
    this.correctedPartnerName = correctedPartnerName
    this.correctedPartnerCountryCode = correctedPartnerCountryCode
    this.correctedPartnerIndustryGroup = correctedPartnerIndustryGroup
    this.correctedPartnerIndustry = correctedPartnerIndustry
    this.correctetdPartnerWebsite = correctetdPartnerWebsite
    this.correcedPrimaryContactFirstName = correcedPrimaryContactFirstName
    this.correcedPrimaryContactLastName = correcedPrimaryContactLastName
    this.existedPrimaryContactUserEmail = existedPrimaryContactUserEmail
    this.googleUserCreds = googleUserCreds
    this.newPrimaryContactUserPassword = newPrimaryContactUserPassword
    this.newPrimaryContactUserConfirmedPassword = newPrimaryContactUserConfirmedPassword
  }

  public static fromTransfer(transfer: any): Partner_RegisterWithIdClashResolutionFe {
    let register = new Partner_RegisterWithIdClashResolutionFe(
      transfer.invitationUuid,
      transfer.idClashingCompanyId,
      transfer.overwriteIdClashingCompanyInformation,
      transfer.correctedPartnerName,
      transfer.correctedPartnerCountryCode,
      transfer.correctedPartnerIndustryGroup,
      transfer.correctedPartnerIndustry,
      transfer.correctetdPartnerWebsite,
      transfer.correcedPrimaryContactFirstName,
      transfer.correcedPrimaryContactLastName,
      transfer.existedPrimaryContactUserEmail,
      transfer.googleUserCreds,
      transfer.newPrimaryContactUserPassword,
      transfer.newPrimaryContactUserConfirmedPassword
    )
    return register
  }
}