export class TenantFe {

  id: string
  description: string
  isPrimary: boolean

  constructor (
    id: string,
    description: string,
    isPrimary: boolean
  ) {
    this.id = id
    this.description = description
    this.isPrimary = isPrimary
  }
  
  public static fromTransfer (transfer: any): TenantFe {
    let tenant = new TenantFe(
      transfer.id,
      transfer.description,
      transfer.isPrimary
    )
    return tenant
  }
}