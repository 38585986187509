import { Injectable } from "@angular/core";
import { RequestFe } from "../model/data-suppliers/request/RequestFe";
import { QuestionFe } from "../model/data-suppliers/request/questionnaire/QuestionFe";
import { AnswerFe } from "../model/data-suppliers/timeline/answer/AnswerFe";
import { IdUtil } from "../utils/IdUtil";
import { StateServiceFe } from "./StateServiceFe";
import { QUESTION_TYPE } from "../model/data-suppliers/request/questionnaire/QuestionTypeFe";
import { AbstractActivityFe } from "../model/data-suppliers/timeline/AbstractActivityFe";
import { AcceptSubmissionActivityFe } from "../model/data-suppliers/timeline/AcceptSubmissionActivityFe";
import { CloseRequestActivityFe } from "../model/data-suppliers/timeline/CloseRequestActivityFe";
import { CreateRequestActivityFe } from "../model/data-suppliers/timeline/CreateRequestActivityFe";
import { RejectSubmissionActivityFe } from "../model/data-suppliers/timeline/RejectSubmissionActivityFe";
import { SubmitAnswersActivityFe } from "../model/data-suppliers/timeline/SubmitAnswersActivityFe";
import { SubmitMessageActivityFe } from "../model/data-suppliers/timeline/SubmitMessageActivityFe";
import { SectionFe } from "../model/data-suppliers/request/questionnaire/SectionFe";
import { TaxonomyAttributeFe } from "../model/taxonomy/TaxonomyAttributeFe";
import { DataCategoryServiceFe } from "./DataCategoryServiceFe";
import { DataTableRequestFieldFe } from "../model/data-suppliers/request/questionnaire/DataTableRequestFieldFe";
import { QuestionDataTableFe } from "../model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { ConnectSectionsActivityFe } from "../model/data-suppliers/timeline/ConnectSectionsActivityFe";
import { DisconnectSectionsActivityFe } from "../model/data-suppliers/timeline/DisconnectSectionsActivityFe";
import { DisconnectSectionsActivity_AddFe } from "../model/data-suppliers/timeline/add/DisconnectSectionsActivity_AddFe";
import { ConnectSectionsActivity_AddFe } from "../model/data-suppliers/timeline/add/ConnectSectionsActivity_AddFe";
import { DataGridColumnType } from "../components/projects/data-grid-ui/model/DataGridColumnType";
import { AnswerDataTableFe } from "../model/data-suppliers/timeline/answer/AnswerDataTableFe";
import { QuestionDateFe } from "../model/data-suppliers/request/questionnaire/QuestionDateFe";
import { LanguageService } from "./LanguageServiceFe";

@Injectable({
  providedIn: 'root'
})

export class RequestServiceFe {

  constructor(private stateService: StateServiceFe, private categoryService: DataCategoryServiceFe, private languageService: LanguageService){}
  
  async connectSection(request: RequestFe, section: SectionFe){
    let answerActivity = request.questionnaire.relatedActivity
    let toConnectAnswers = section.questions.map(q => q.answer).filter(a => !!a)  //connect only answered questions
    await this.addConnectActivity(request, toConnectAnswers, [section.id])
    section.isConnected = true
  }

  async connectAllSections(request: RequestFe){
    let sections = request.questionnaire.sections
    let toConnectAnswers = []
    sections.forEach(section => {
      if (section.isConnected == false) { //connect only disconnected sections
        let answers = section.questions.map(q => q.answer).filter(a => !!a)  //connect only answered questions
        toConnectAnswers.push(...answers);
      }
    })
    sections.forEach(section => section.isConnected = false);
    let ids = sections.map(s => s.id)
    await this.addConnectActivity(request, toConnectAnswers, ids)
    sections.forEach(section => section.isConnected = true)
  }

  async addConnectActivity(request: RequestFe, toConnectAnswers: AnswerFe[], sectionIds: string[]) {
    let answerActivity = request.questionnaire.relatedActivity
    toConnectAnswers.forEach(answer => {
      if (answer instanceof AnswerDataTableFe) {
        answer.dataGridService = null
      }
    })
    let activity = new ConnectSectionsActivity_AddFe(IdUtil.next(), this.stateService.activeWorkspace.companyId, answerActivity.id, toConnectAnswers, null, answerActivity.requestId, answerActivity.requestGroupId, answerActivity.requestGroupLabel);
    await this.stateService.addRequestTimelineItem(activity, activity.requestGroupId, activity.requestTaskId);
    await this.updateKpis(request, sectionIds);
  }

  async disconnectSections(request: RequestFe, sections: SectionFe[]){
    let answerActivity = request.questionnaire.relatedActivity
    let sectionsToDisconnect = sections.map(s => SectionFe.fromTransfer(s))
    let activity = new DisconnectSectionsActivity_AddFe(IdUtil.next(), answerActivity.id, sectionsToDisconnect, null, request.id, answerActivity.requestGroupId, answerActivity.requestGroupLabel);
    await this.stateService.addRequestTimelineItem(activity, activity.requestGroupId, activity.requestTaskId);
    sections.forEach(section => section.isConnected = false);
    let ids = sections.map(s => s.id)
    await this.updateKpis(request, ids);
    sections.forEach(section => section.isConnected = false)
  }

  async updateKpis(request:RequestFe, sectionIds: string[]) {
    let updatedSections = new Set(sectionIds)
    let entityKeys = new Set<string>;
    request.questionnaire.sections.forEach(section => {
      if(updatedSections.has(section.id)) {
        entityKeys.add(section.taxonomyKey);
      }
    })
    await this.stateService.updateAffectedUserInsights([...entityKeys]);
  }
  
  isMessage(activity: AbstractActivityFe) {
    return activity instanceof SubmitMessageActivityFe;
  }

  isAccept(activity: AbstractActivityFe) {
    return activity instanceof AcceptSubmissionActivityFe;
  }

  isReject(activity: AbstractActivityFe) {
    return activity instanceof RejectSubmissionActivityFe;
  }

  isCloseRequest(activity: AbstractActivityFe) {
    return activity instanceof CloseRequestActivityFe;
  }

  isCreatedRequest(activity: AbstractActivityFe) {
    return activity instanceof CreateRequestActivityFe;
  }

  isSubmitAnswer(activity: AbstractActivityFe) {
    return activity instanceof SubmitAnswersActivityFe
  }
  
  isConnectedData(activity: AbstractActivityFe) {
    return activity instanceof ConnectSectionsActivityFe;
  }

  isDisconnectedData(activity: AbstractActivityFe) {
    return activity instanceof DisconnectSectionsActivityFe;
  }

  isSingleChoiceQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.SINGLE_CHOICE;
  }

  isMultipleChoiceQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.MULTIPLE_CHOICE;
  }

  isDataTableQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.DATA_TABLE;
  }

  isDateQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.DATE;
  }

  isAttachmentQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.ATTACHMENT;
  }

  isNumberQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.NUMBER;
  }

  isTextQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.TEXT;
  }

  isEmissionFactorQues(question: QuestionFe) {
    return question.type == QUESTION_TYPE.EMISSION_FACTOR
  }

  getQuestions(request: RequestFe): QuestionFe[]{
    let questions = request.questionnaire.sections.reduce((result: QuestionFe[], section) => result.concat(section.questions), []);
    return questions
  }
  
  isSectionConnected (section: SectionFe) {
    return section.taxonomyKey && section.isConnected;
  }

  findColumnsByType (question: QuestionFe, section: SectionFe, skipCalculatedCols = true): TaxonomyAttributeFe[] {
    let types = new Set<string>();
    switch (question.type) {
      case QUESTION_TYPE.DATE: types.add(DataGridColumnType.DATE); break;
      case QUESTION_TYPE.MULTIPLE_CHOICE: types.add(DataGridColumnType.STRING); break;
      case QUESTION_TYPE.SINGLE_CHOICE: types.add(DataGridColumnType.STRING); types.add(DataGridColumnType.BOOLEAN); break;
      case QUESTION_TYPE.NUMBER: types.add(DataGridColumnType.NUMERIC); break;
      case QUESTION_TYPE.TEXT: types.add(DataGridColumnType.STRING); break;
      case QUESTION_TYPE.ATTACHMENT: types.add(DataGridColumnType.STRING); break;
      case QUESTION_TYPE.EMISSION_FACTOR: types.add(DataGridColumnType.EMISSION_FACTOR); break;
      default: types.add(DataGridColumnType.DATE);  types.add(DataGridColumnType.STRING); types.add(DataGridColumnType.BOOLEAN); types.add(DataGridColumnType.NUMERIC); 
        types.add(DataGridColumnType.EMISSION_FACTOR); break;
    }

    if (section.taxonomyKey && section.entity && this.categoryService.getTaxonomy()) {
      if (section.entity) {
        let usedColumnKeys = new Set<string>()
        section.questions.forEach(q => {
          if (q.id != question.id) {
            q.mappedToColKeys?.forEach(key => usedColumnKeys.add(key)) 
            if (q instanceof QuestionDateFe) {
              q.mappedToColKeysRange?.forEach(key => usedColumnKeys.add(key))
            }
          } 
      })
        
        let matchedColumns = section.entity.columns.filter(c => types.has(c.datatype) && (skipCalculatedCols ? c.providedBy == 'user' : true))
        return matchedColumns.filter(c => !usedColumnKeys.has(c.key))
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  addDataTableField(question: QuestionDataTableFe) {
    let field = new DataTableRequestFieldFe(IdUtil.next(), null, null, "STRING", true, [], [], true, null, null, null)
    question.customFields.push(field)
    question.addEntityColumn(field)
  }

  toggleQuesMapping(event: Event, ques: QuestionFe) {
    if ((event.target as HTMLInputElement).checked) {
      ques.isMapped = false;
      ques.mappedToColKeys = null;
      if (ques instanceof QuestionDateFe && ques.range) {
        ques.mappedToColKeysRange = null;
        ques.mappedToColsRange= []
      }
    } else {
      ques.isMapped = true;
      ques.mappedToColKeys = [];
      if (ques instanceof QuestionDateFe && ques.range) {
        ques.mappedToColKeysRange = []
        ques.mappedToColsRange= []
      }
    }
    ques.mappedToCols = [];
    if (ques instanceof QuestionDataTableFe) {
      ques.fields = [];
      ques.customFields = []
      if (!ques.isMapped) {
        this.addDataTableField(ques)
      } else {
        ques.setRequestDataGridService();
      }
    }
  }

}
