import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Component({
  selector: "app-drafts",
  templateUrl: "./drafts.component.html",
  styleUrls: ["../../common/data-suppliers.component.scss"],
})
export class RequestDraftComponent extends AbstractLanguageComponent implements OnInit {
  
  requestGroups: RequestGroupFe[] = [];
  draftRgs: RequestGroupFe[] = [];
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  
  selectedRequestGroup: RequestGroupFe
  startRequestCreation = null;
  loadingInfo = false;
  loadingInProgress = false;
  
  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.pages.data_request.toolbar.add'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.new_request'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.new_request'),
        icon: "la la-plus fs-4",
        actionName: "open_add_questionaire_modal",
        visible: () => true,
        disabled: false
      }
    ],
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info fs-4", actionName: "toggle_quick_tips", visible: () => true, disabled: false }
    ],
  ];

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    public loginService: LoginServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService);
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
    
    this.screenSize = responsive.currentScreenWidthSize;
  }

  async ngOnInit(): Promise<void> {
    this.loadingInfo = true;
    await this.renderNewData();
    this.displayService.closeTips();
    this.loadingInfo = false;
  }

  async renderNewData(): Promise<void> {
    this.requestGroups = await this.stateService.getRequestGroups();
    this.draftRgs = this.requestGroups.filter(rg => rg.draft)
  }
 
  onSelectRequestGroup(requestGroup) {
    this.selectedRequestGroup = requestGroup;
    this.startRequestCreation = true;
  }

  onCloseRequestGroup() {
    this.startRequestCreation = false; 
    this.renderNewData();
  }

  onCreateRequestGroup() {
    this.selectedRequestGroup = undefined
    this.startRequestCreation = true;
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      
      case "open_add_questionaire_modal":
        this.onCreateRequestGroup()
        break;
      case "toggle_quick_tips":
        this.toggleTips()
        break;
    }
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  openModal(template: TemplateRef<any> | string, clasz = "modal-xl") {
    this.modalRef = this.modalService.show(template, { class: clasz, backdrop: "static", keyboard: true });
  }

  async deleteDraft() {
    this.loadingInProgress = true
    await this.stateService.deleteDraftRequestGroup(this.selectedRequestGroup.id);
    this.requestGroups = await this.stateService.getRequestGroups();
    this.closeModal();
    this.loadingInProgress = false;
  }

}