import { FormControl, Validators } from "@angular/forms";
import { LanguageService } from "src/app/services/LanguageServiceFe";

export abstract class AbstractAttributeFe {
  public description: { [key: string]: string } = {};
  public key: string;
  public label: { [key: string]: string } = {};
  public datatype: string;
  public descriptionControl = new FormControl(null, Validators.required);
  public labelControl = new FormControl(null, Validators.required);

  public languageService: LanguageService;

  constructor(attr, languageService: LanguageService) {
    this.description = attr["description"];
    this.key = attr["key"];
    this.label = attr["label"];
    this.datatype = attr["datatype"];
    this.languageService = languageService
  }
}
