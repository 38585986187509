import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AttachmentFe } from "src/app/model/details/AttachmentFe";
import { FileDataFe } from "src/app/model/file/FileDataFe";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { RouteFe } from "src/app/route/RouteFe";
import { RouteServiceFe } from "src/app/route/RouteServiceFe";
import { RoutesFe } from "src/app/route/RoutesFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { TaskDataGridIntegrationService } from "../data-suppliers/data-supplier-task/TaskDataGridIntegrationService";

@Component({
  selector: "app-attachment-details",
  templateUrl: "./attachment-details.component.html",
  styleUrls: ["./attachment-details.component.scss"],
})
export class AttachmentDetailsComponent extends AbstractLanguageComponent {
  activeRoute: RouteFe | undefined;
  tabTitle: string;
  @Input() selectedFile: FileDataFe;
  modalRef: BsModalRef;
  dataGridService = new TaskDataGridIntegrationService(new TableDataFe());
  attachments: AttachmentFe[] = []

  constructor(private modalService: BsModalService, private routeService: RouteServiceFe, private displayService: DisplayServiceFe,
    languageService: LanguageService) {
    super(languageService);
    routeService.activeRouteSubject.subscribe((activeRoute: RouteFe | undefined) => {
      this.closeAttachments();
    })

    displayService.openAttachmentSubject.subscribe((attachment: AttachmentFe) => {
      let existingAttachmentIndex = this.attachments.findIndex((a) => a.id == attachment.id);
      this.attachments.forEach((a) => a.id == attachment.id ? a.isActive = true : a.isActive = false);
      if(existingAttachmentIndex == -1){
        this.attachments.push(attachment);
        attachment.isActive = true;
      }
    });

    displayService.closeAttachmentSubject.subscribe((close: boolean) => {
      if(close){
        this.closeAttachments();
      }
    })
  }

  closeAttachment(index: number) {
    if(this.attachments.length > 1 && this.attachments[index].isActive){
      this.attachments[index-1] ? this.attachments[index-1].isActive = true : this.attachments[index+1].isActive = true;
    }
    this.attachments.splice(index, 1);
  }

  viewAttachment(index: number){
    this.attachments.forEach((a, i) => i == index ? a.isActive = true : a.isActive = false);
  }

  closeAttachments(){
    this.attachments = []
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide()
      document.body.classList.remove('modal-open');
    }
  }

}
