import { DataGridColumnSchema } from "src/app/components/projects/data-grid-ui/model/DataGridColumnSchema";
import { DataGridReferenceColumnSchema } from "src/app/components/projects/data-grid-ui/model/DataGridReferenceColumnSchema";

export class FlowChartOrganizationEntity {
  public ordinal: number
  public key: string
  public label: string;
  public deployed: boolean;
  public modified: boolean;
  public dataSchema: DataGridColumnSchema [];
  public referenceSchema: DataGridReferenceColumnSchema [];
  public parents: FlowChartOrganizationEntity[]

  constructor(ordinal: number, key: string, label: string, deployed: boolean, modified: boolean, dataSchema: DataGridColumnSchema[], 
    referenceSchema: DataGridReferenceColumnSchema[], parents: FlowChartOrganizationEntity[]) {
    this.ordinal = ordinal
    this.key = key
    this.label = label
    this.deployed = deployed
    this.parents = parents
    this.modified = modified
    this.dataSchema = dataSchema;
    this.referenceSchema = referenceSchema;
  }
}