export class PipelineFunctionFe {
  public static SUM = new PipelineFunctionFe('SUM', 'Sum', 'locale_key.pages.insight_detail.operation.sum') ;
  public static AVG = new PipelineFunctionFe('AVG', 'Average', 'locale_key.pages.insight_detail.operation.average');
  public static MIN = new PipelineFunctionFe('MIN', 'Minimum', 'locale_key.pages.insight_detail.operation.minimum');
  public static MAX = new PipelineFunctionFe('MAX', 'Maximum', 'locale_key.pages.insight_detail.operation.maximum');
  public static COUNT = new PipelineFunctionFe('COUNT', 'Number of occurences', 'locale_key.pages.insight_detail.operation.number_of_occurences');

  constructor(public key: string, public label: string, public locale?: string) {}

  public static getAllFunctions(): PipelineFunctionFe[] {
    return [PipelineFunctionFe.SUM, PipelineFunctionFe.AVG, PipelineFunctionFe.MIN, PipelineFunctionFe.MAX, PipelineFunctionFe.COUNT]
  }

  public static fromTransfer(transfer: any): PipelineFunctionFe{
    switch (transfer.key) {
      case PipelineFunctionFe.SUM.key: 
        return PipelineFunctionFe.SUM; 
      case PipelineFunctionFe.AVG.key: 
        return PipelineFunctionFe.AVG; 
      case PipelineFunctionFe.MIN.key: 
        return PipelineFunctionFe.MIN; 
      case PipelineFunctionFe.MAX.key: 
        return PipelineFunctionFe.MAX; 
      case PipelineFunctionFe.COUNT.key: 
        return PipelineFunctionFe.COUNT; 
    }
  }

}