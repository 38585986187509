import { UnitFe } from "src/app/components/unit-systems/model/UnitFe";
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
export class SelectDataInputOperand implements Operand {
  type: "operand" = "operand";
  operandType: OperandType = "selectDataInput";
  value: String = "Select data input";
  constructor() {}
}

export class FixedNumberOperand implements Operand {
  type: "operand" = "operand";
  operandType: OperandType = "fixedNumber";
  value: String;
  unit;
  constructor({ value, unit }) {
    this.value = value;
    this.unit = unit;
  }
}

export class DataPointOperand implements Operand {
  type: "operand" = "operand";
  operandType: OperandType = "dataPoint";
  datapoint: TaxonomyAttributeFe;
  constructor(datapoint: TaxonomyAttributeFe) {
    this.datapoint = datapoint;
  }
}

export class EmissionFactorOperand implements Operand {
  type: "operand" = "operand";
  operandType: OperandType = "emissionFactor";
  emissionFactor: AbstractEmissionFactorFe;
  constructor({ emissionFactor }) {
    this.emissionFactor = emissionFactor;
  }
}

export enum OperandTypes {
  "dataPoint" = "dataPoint",
  "fixedNumber" = "fixedNumber",
  "selectDataInput" = "selectDataInput",
  "emissionFactor" = "emissionFactor"
}
export type OperandType = "dataPoint" | "fixedNumber" | "selectDataInput" | "emissionFactor";

export interface Operand {
  type: "operand";
  operandType: OperandType;
  datapoint?: TaxonomyAttributeFe;
  emissionFactor?: AbstractEmissionFactorFe;
  value?: String;
  unit?: UnitFe;
  highlightError?: boolean;
}

export type OperatorType = "openBracket" | "closeBracket" | "plus" | "minus" | "times" | "divide" | "control";

export class OpenBracketOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "openBracket";
  constructor() {}
}

export class CloseBracketOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "closeBracket";
  constructor() {}
}

export class PlusOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "plus";
  constructor() {}
}

export class MinusOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "minus";
  constructor() {}
}

export class TimesOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "times";
  constructor() {}
}

export class DivideOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "divide";
  constructor() {}
}

export class ControlOperator implements Operator {
  type: "operator" = "operator";
  operatorType: OperatorType = "control";
  constructor() {}
}

export type Operator = {
  type: "operator";
  operatorType: OperatorType;
  highlightError?: boolean;
};

export enum TokenTypes {
  "operator" = "operator",
  "operand" = "operand"
}
export type Formula = Array<Operand | Operator>;

export type OR_COMPARATOR = "or";
export type AND_COMPARATOR = "and";
export type NONE_COMPARATOR = "none";
export type Comparator = OR_COMPARATOR | AND_COMPARATOR | NONE_COMPARATOR;

export enum ConditionComparators {
  OR_COMPARATOR = "or",
  AND_COMPARATOR = "and",
  NONE_COMPARATOR = "none"
}

export enum CriterionBooleanCondition {
  TRUE = "true",
  FALSE = "false"
}

export enum CriterionDateCondition {
  IS_EXACTLY = "is_exactly",
  IS_BEFORE = "is_before",
  IS_AFTER = "is_after",
  IS_IN_BETWEEN = "is_in_between",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty"
}

export enum CriterionNumbersCondition {
  IS_EXACTLY = "is_exactly",
  IS_LESS_THAN = "is_less_than",
  IS_MORE_THAN = "is_more_than",
  IS_IN_BETWEEN = "is_in_between",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty"
}

export enum CriterionTextCondition {
  IS_EXACTLY = "is_exactly",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
  CONTAINS = "contains"
}

export type CriterionCondition = CriterionTextCondition | CriterionNumbersCondition | CriterionDateCondition | CriterionBooleanCondition;

export type Criterion = {
  type: "criterion";
  datapoint: TaxonomyAttributeFe;
  condition: CriterionCondition;
  value: string;
};
export type ConditionCriteria = [] | [Condition | Criterion] | [Condition | Criterion, Condition | Criterion];
export type Condition = {
  type: "condition";
  comparator: Comparator;
  criteria: ConditionCriteria;
  hasError: boolean;
};

export enum CalculationThenOptions {
  "FORMULA" = "FORMULA",
  "FIXED" = "FIXED",
  "NOTHING" = "NOTHING"
}

export type Calculation = {
  isFallback: Boolean;
  condition?: Condition;
  formula: Formula;
  harmonizedFormula?: Formula;
  useHarmonizedFormula?: Boolean;
  value?: string;
  then: CalculationThenOptions;
  hasError?: Boolean;
};

export class CalculationCriterion implements Criterion {
  type: "criterion" = "criterion";
  datapoint: TaxonomyAttributeFe;
  condition: CriterionCondition;
  value: string;
  constructor(opts?) {
    this.datapoint = opts?.datapoint;
    this.condition = opts?.condition;
    this.value = opts?.value;
  }
}

export class CalculationCondition implements Condition {
  type: "condition" = "condition";
  comparator: Comparator;
  criteria: ConditionCriteria;
  hasError: boolean = false;
  constructor({ comparator, criteria }: { comparator: Comparator; criteria: ConditionCriteria }) {
    this.comparator = comparator;
    this.criteria = criteria;
  }
}

export function cloneCalculation(calculation: Calculation, clonedAttrs: TaxonomyAttributeFe[]): Calculation {
  return {
    ...calculation,
    formula: deepCloneFormula(calculation.formula, clonedAttrs),
    harmonizedFormula: calculation.harmonizedFormula ? deepCloneFormula(calculation.harmonizedFormula, clonedAttrs) : undefined,
    condition: calculation.condition ? deepCloneCondition(calculation.condition, clonedAttrs) : undefined
  };
}

export function deepCloneFormula(formula: Formula, clonedAttrs: TaxonomyAttributeFe[]): Formula {
  return formula.map(token => {
    if (token.type === "operand") {
      return cloneOperand(token as Operand, clonedAttrs);
    } else {
      // Since operators are immutable and stateless, shallow copies are fine
      return Object.assign({}, token);
    }
  });
}

export function deepCloneCondition(condition: Condition, clonedAttrs: TaxonomyAttributeFe[]): Condition {
  return new CalculationCondition({
    comparator: condition.comparator,
    criteria: condition.criteria.map(crit => {
      if (crit instanceof CalculationCriterion) {
        const clonedDataPoint = clonedAttrs.find(attr => attr.key == crit.datapoint.key);
        return new CalculationCriterion({
          datapoint: clonedDataPoint,
          condition: crit.condition,
          value: crit.value
        });
      }
      return crit; // Assuming deep clone for other types if necessary
    }) as ConditionCriteria  // Explicit type assertion here
  });
}

export function cloneOperand(operand: Operand, clonedAttrs: TaxonomyAttributeFe[]): Operand {
  if (operand.operandType == OperandTypes.fixedNumber) {
    return new FixedNumberOperand({ value: operand.value, unit: operand.unit });
  } else if (operand.operandType == OperandTypes.dataPoint) {
    // Assuming TaxonomyAttributeFe is a complex object that also needs deep cloning
    const clonedDataPoint = clonedAttrs.find(attr => attr.key == operand.datapoint.key);
    return new DataPointOperand(clonedDataPoint);
  } else if (operand.operandType == OperandTypes.emissionFactor) {
    // Assuming AbstractEmissionFactorFe needs deep cloning
    const clonedEmissionFactor = operand.emissionFactor;
    return new EmissionFactorOperand({ emissionFactor: clonedEmissionFactor });
  }
  throw new Error("Unknown operand type");
}


