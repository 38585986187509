<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <h3 class="">{{ locale("locale_key.data_owner_portal.main_navbar.inbox") }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-5" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- content column-->
      <div class="flex-fill scroll" style="height: 90%;">
        <div class="d-flex h-100 flex-row me-1">
          <div class="tab-content h-100 w-100">
            <div class="w-100 pt-1 h-100">
              <div class="card mb-1 pb-0 h-100">
                <div class="card-body px-0 h-100 pt-0">
                  <div class="row mt-3 mx-3" *ngIf="!isExpanded">
                    <div class="col-md-6 mb-3 col-lg-3">
                      <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}" #search (input)="applySearchFilter(search.value)" />
                    </div>
                    <div class="col-md-12 mb-3 btn-group col-lg-9" dropdown container="body" [insideClick]="true">
                      <button type="button" class="form-control filter-button" id="filter-button" dropdownToggle aria-controls="filter-dropdown-menu" style="text-align: left;">
                        <p class="d-inline m-0"><i class="la la-filter"></i> {{ locale("locale_key.pages.datahub.filter_dropdown") }}</p>
                        <p class="text-muted text-truncate col-7  m-0" [title]="getCustomFilterText()"
                        *ngIf="customMultipleFilters.length > 0 || selectedStatus"> :{{getCustomFilterText()}} </p>
                      </button>
                      <div class="ms-3 dropdown-menu" id="filter-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="filter-button" [ngClass]="{'width-dropdown': screenSize.isXSmallSize()}">
                        <div class="accordion-body px-0 pt-0">
                          <div class="bg-light-gray col pt-3">
                            <div class="d-flex flex-row justify-content-between px-2">
                              <span class="bold">{{ locale("locale_key.pages.datahub.applied_filters") }}</span>
                              <a class=" underline" (click)="clearAllFilters()">{{ locale("locale_key.pages.datahub.button.clear_all_filters") }}</a>
                            </div>
                            <div class="row px-2">
                              <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">{{ locale('locale_key.pages.datahub.filter_none') }}</i>
                              <p class="text-muted text-truncate" *ngIf="customMultipleFilters.length > 0 || selectedStatus">
                                {{getCustomFilterText()}}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
                            <div class="d-flex " [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="CREATED" id="sent" />
                                <label class="form-check-label" for="sent">
                                  {{ locale("locale_key.pages.datahub.do_submissions.filter.show_requests_sent") }}
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="APPROVED" id="completed" />
                                <label class="form-check-label" for="completed">
                                  {{ locale("locale_key.pages.datahub.do_submissions.filter.show_completed_requests") }}
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="IN_PROGRESS" id="inProgress" />
                                <label class="form-check-label" for="inProgress">
                                  {{ locale("locale_key.pages.datahub.do_submissions.filter.show_in_progress_requests") }}
                                </label>
                              </div>
                            </div>
                            <div class="d-flex flex-row">
                              <a class=" underline" (click)="selectedStatus = null; applyFilters()">{{ locale('locale_key.pages.datahub.button.clear_filter') }}</a>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="accordion" id="accordionExample1">
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button class="accordion-button collapsed" 
                                  type="button" data-bs-toggle="collapse" data-bs-target="#companyCollapse" aria-expanded="false" aria-controls="companyCollapse">
                                    <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.pages.data_hub.master_table.data_categories.title') }}</span>
                                  </button>
                                </h2>
                                <div id="companyCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body scroll" style="max-height:20rem">
                                    <div class="row">
                                      <div class="col-md-6 col-lg-6 mb-3">
                                        <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterEntities(entitySearch.value)" #entitySearch>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-3 text-end">
                                        <a class=" underline" (click)="selectAll('entityCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                        <a class=" underline" (click)="deselectAll('entityCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                      </div>
                                    </div>
                                    <div *ngFor="let item0 of filteredEntities; let i = index">
                                      <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item0.key}}' [checked]="isCustomFilterSelected(item0)" (change)="changeCustomFilters($event, item0, 0)">
                                      <i class="{{ taxonomy.icon(item0)}} me-2 fs-5" [ngStyle]="{ color: taxonomy.color(item0) }"></i>
                                      <label>{{item0.getLabel()}}</label>
                                      <div class="ms-3">
                                        <div *ngFor="let item1 of taxonomy.childrenSortedByOrdinal(item0.key); let i = index">
                                          <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item1.key}}' [checked]="isCustomFilterSelected(item1)" (change)="changeCustomFilters($event, item1, 1)">
                                          <label>{{item1.getLabel()}}</label>
                                          <div class="ms-3">
                                            <div *ngFor="let item2 of taxonomy.childrenSortedByOrdinal(item1.key); let i = index">
                                              <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item2.key}}' [checked]="isCustomFilterSelected(item2)" (change)="changeCustomFilters($event, item2, 2)">
                                              <label>{{item2.getLabel()}}</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.general.user_role.data_reporters') }}</span>
                                  </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body scroll" style="max-height:20rem">
                                    <div class="row">
                                      <div class="col-md-6 col-lg-6 mb-3">
                                        <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterDataReporters(contactSearch.value)" #contactSearch>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-3 text-end">
                                        <a class=" underline" (click)="selectAll('contactCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                        <a class=" underline" (click)="deselectAll('contactCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                      </div>
                                    </div>
                                    <div *ngFor="let contact of filteredDataReporters; let i = index">
                                      <input type="checkbox" class="form-check-input" class="contactCheckbox" id='company{{i}}' [checked]="isCustomFilterSelected(contact)" (change)="changeCustomFilters($event, contact)">
                                      <label class="col-4">{{contact.getName()}}</label>
                                      <label class="col-4">{{contact.getCompanyName(stateService.activeWorkspace)}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.pages.datahub.do_submissions.button.companies') }}</span>
                                  </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                  <div class="accordion-body scroll" style="max-height:20rem">
                                    <div class="row">
                                      <div class="col-md-6 col-lg-6 mb-3">
                                        <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterCompanies(companySearch.value)" #companySearch>
                                      </div>
                                      <div class="col-md-6 col-lg-6 mb-3 text-end">
                                        <a class=" underline" (click)="selectAll('companyCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                        <a class=" underline" (click)="deselectAll('companyCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                      </div>
                                    </div>
                                    <div *ngFor="let company of filteredCompanies; let i = index">
                                      <input type="checkbox" class="form-check-input" class="companyCheckbox" id='company{{i}}' [checked]="isCustomFilterSelected(company)" (change)="changeCustomFilters($event, company)">
                                      <label>{{company.supplierCompanyname}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--New design for questions-->
                  <div class="d-flex flex-xl-row flex-lg-row flex-column mb-5 scroll-y" style="height: 75vh; max-width: 100% !important; box-sizing: border-box !important">
                    <div class="col-lg-5 col-md-12 bg-light-main ps-3 pe-4" *ngIf="!isExpanded">
                      <div class="d-flex flex-row justify-content-between py-3">
                        <div class="w-50 text-start custom-div">
                          <button class="btn dropdown-toggle form-control left-align-dropdown-button dark-text ms-2" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="max-height: 33.5px !important">
                            <span *ngIf="isToDoListOpen" style="display: inline-block;width: 90%;"><i class="la la-list-alt fs-4 me-auto"></i> <span class="me-auto">{{ locale("locale_key.pages.task_portal.option.to_do") }}</span></span> 
                            <span *ngIf="!isToDoListOpen" style="display: inline-block;width: 90%;"><i class="la la-check-circle-o grad-green fs-4 me-auto" style="position: relative; top: 2px"></i> <span class="me-auto">{{ locale("locale_key.pages.task_portal.option.done_list") }}</span></span> 
                          </button>
                          <ul class="dropdown-menu ms-0 custom-dropdown">
                            <a class="dropdown-item d-flex align-items-center ps-2" *ngIf="isToDoListOpen" (click)="reset(); isToDoListOpen = false"><i class="la la-check-circle-o grad-green fs-4 me-2" style="position: relative; top: 2px"></i> {{ locale('locale_key.pages.task_portal.option.done_list') }}</a>
                            <a class="dropdown-item d-flex align-items-center ps-2" *ngIf="!isToDoListOpen" (click)="reset(); isToDoListOpen = true"><i class="la la-list-alt fs-4 me-2"></i> {{ locale("locale_key.pages.task_portal.option.to_do") }}</a>
                          </ul>
                        </div>
                        <div class="d-flex flex-row ms-auto" *ngIf="isToDoListOpen">
                          <div class="icon-circle-md bg-selected border my-auto" style="border: 1px solid #19bfd3 !important">
                            <span class="bold fs-5 dark-text">{{getToDoActivitiesGroup().length>100 ? '99+' : getToDoActivitiesGroup().length}}</span>
                          </div>
                          <span class="my-auto ps-2 dark-text">{{ locale("locale_key.general.quick_tips.tasks.title") }}</span>
                        </div>
                      </div>
                      <div class="d-flex flex-column mx-auto mb-3 pb-3 scroll-y h-90">
                        <ng-container *ngIf="isToDoListOpen; else doneList">
                          <ng-container *ngFor="let toDoActivitiesGroup of getToDoActivitiesGroup(); let i = index" >
                            <inbox-card (click)="selectNewActivity(i)" [isSelected]="selectedActivity == toDoActivitiesGroup.activities[0]"
                            [requestGroup]="getRequestGroup(toDoActivitiesGroup.activities[0].requestGroupId)" [activity]="toDoActivitiesGroup.activities[0]"></inbox-card>
                          </ng-container>
                        </ng-container>
                        <ng-template #doneList>
                          <ng-container *ngFor="let request of getDoneRequestList(); let i = index" >
                            <inbox-card (click)="selectDoneRequest(request)"  [isSelected]="selectedActivity == getLastActivity(request)"
                            [requestGroup]="getRequestGroup(request.requestGroupId)" [activity]="getLastActivity(request)"></inbox-card>
                          </ng-container>
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-12 h-100 px-2" [ngClass]="isExpanded ? 'col-lg-12' : 'col-lg-7'" *ngIf="selectedRequest">
                      <div class="scroll-y m-0 p-0 card h-100" style="border: none">
                        <div class="d-flex flex-row gray-bg py-3 px-3 justify-content-between thin-border">
                          <span class="fs-5 bold my-auto">{{ selectedRequestGroup.title }}</span>
                          <a class="text-muted my-auto" (click)="isExpanded = !isExpanded"><i class="las fs-4" [ngClass]="isExpanded? 'la-compress-arrows-alt' : 'la-expand-arrows-alt'"></i></a>
                        </div>
                        <div class="d-flex flex-row py-3 px-2 thin-border-b thin-border-l thin-border-r">
                          <div class="col-4 px-2 my-auto"><span class="me-2 dark-text text-wrap">{{ selectedRequest.getAssigneeName() }}</span><span class="text-muted">({{selectedRequest.supplierCompanyName ? selectedRequest.supplierCompanyName : stateService.activeWorkspace.companyName}})</span></div>
                          <div class="border-r px-2 my-auto border-l" [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'col-3': 'col-5'">
                            <span class="me-2 dark-text mx-auto">{{ selectedRequestGroup.getCreated() }} - {{ selectedRequestGroup.getDeadline() }}</span>
                          </div>
                          <div class="px-2 my-auto text-center" [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'col-5': 'col-1'">
                            <ng-container *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()">
                              <a class="btn tertiary-button position-relative mx-auto btn tertiary-button open-message-box-btn" (click)="openModal(questionnairePreview, 'modal-lg')" >
                                <i class="la la-file-alt fs-4" title="{{locale('locale_key.pages.data_request.table.button.show_request')}}" style="position: relative; top: 3px"></i> 
                                <ng-container *ngIf="isExpanded"> {{ locale("locale_key.pages.data_request.table.button.show_request") }} </ng-container> 
                              </a>
                              <a class="btn tertiary-button position-relative mx-auto btn tertiary-button open-message-box-btn" (click)="openMessageModal()">
                                <i class="la la-comment-alt fs-4" title="{{locale('locale_key.general.button.open_conversation')}}" style="position: relative; top: 3px"></i> 
                                <ng-container *ngIf="isExpanded"> {{ locale('locale_key.general.button.open_conversation') }} </ng-container>
                                <span *ngIf="getUnreadActivities(selectedRequest).length > 0" class="position-absolute top-0 start-100 translate-middle rounded-pill unread-dot text-white" style="max-width: fit-content !important; border-radius: 50%">
                                  {{getUnreadActivities(selectedRequest).length > 100 ? '99+' : getUnreadActivities(selectedRequest).length}}
                                </span>
                              </a>
                            </ng-container>
                            <div class="btn-group">
                              <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-v ms-auto my-auto cursor size-15" [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item ms-0 px-1 text-dark" type="button" (click)="openRequestPortal()">
                                  {{ locale('locale_key.pages.data_request_answers.show_in_request_portal')}}
                                </button>
                                <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="openModal(confirmConnect, 'modal-lg')">
                                  {{locale('locale_key.pages.inbox.connect_all_sections')}}
                                </button>
                                <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="openModal(confirmDisconnect, 'modal-md')">
                                  {{locale('locale_key.pages.inbox.disconnect_all_sections')}}
                                </button>
                                <ng-container *ngIf="!screenSize.isXLargeSize() && !screenSize.isLargeSize()">
                                  <button class="dropdown-item ms-0 px-1 text-dark" type="button" (click)="openModal(questionnairePreview, 'modal-lg')" >
                                    {{ locale("locale_key.pages.data_request.table.button.show_request") }}
                                  </button>
                                  <button class="dropdown-item ms-0 px-1 text-dark" type="button" (click)="openMessageModal()">
                                    {{ locale('locale_key.general.button.open_conversation') }}
                                  </button>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <request-answer style ="height: 53vh" [requestGroup]="selectedRequestGroup" [request]="selectedRequest"></request-answer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #questionnairePreview>
  <questionnaire-preview [request]="selectedRequest" [requestTitle]="selectedRequestGroup.title"></questionnaire-preview>
</ng-template>

<!-- CONVERSATION MODAL -->
<app-chat-box *ngIf="isChatOpen" [requestTask]="selectedRequest" [rg]="selectedRequestGroup" (chatClosed)="isChatOpen = false"></app-chat-box>


<ng-template #confirmDisconnect>
  <div class="modal-header btn-add">
    <div class="icon-background d-inline mt-0" style="height:18px;width:18px; background-color:var(--orange);border-radius: 50%; border:2px solidtext-white;">
      <i class="las la-exclamation-circle text-white p-0 " style="font-size: 1.5rem; background-color:transparent;border-radius: 50%;position: relative;left:-2px;top:-1px"></i>
    </div>
    <h5 class="modal-title text-white" id="myModalLabel12">{{locale('locale_key.general.buttons.remove_from_maser_table')}}</h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal();">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      {{locale('locale_key.pages.data_request.review_answer_wizard.disconnect_confirmation_modal.title')}}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="disconnectAllSections() ">{{locale('locale_key.general.buttons.remove_from_maser_table')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmConnect>
  <div class="modal-header btn-add">
    <h5 class="modal-title text-white" id="myModalLabel12">{{locale('locale_key.pages.data_request.review_answer_wizard.add_to_master_table_confirmation_modal.title')}}</h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal();">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{locale('locale_key.pages.data_request.review_answer_wizard.add_to_master_table_confirmation_modal.desc')}}</p>
    <p>{{locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.desc2')}}</p>
    <div class="px-2">
      <div class="row mx-auto pe-3">
        <div class="col-md-3 px-1 mb-2">{{locale('locale_key.pages.data_request.create_question.table.advanced_settings.requested_data.title')}}</div>
        <div class="col-md-9 px-1 mb-2">{{locale('locale_key.pages.data_request.create_question.table.advanced_settings.mapped_to_data_categories.title')}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button"
        (click)="closeModal()">{{locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.button.cancel')}}</button>
      <button type="button" class="btn primary-button custom-button"
        (click)="connectAllSections()">{{locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.button.add_to_master_table')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>