<div class="d-flex flex-column h-100" *ngIf="!startRequestCreation">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <h3 class="">{{ locale("locale_key.pages.data_request.drafts_section.title") | uppercase}}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-5" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-row me-1">
          <div class="tab-content w-100" style="overflow-x: hidden">
            <div class="w-100 pt-1" style="overflow: hidden">
              <div class="card mb-1 pb-0" style="height: 89.5vh; overflow-x: hidden; border-bottom-left-radius: 0%; border-bottom-right-radius: 0%">
                <div class="card-body px-0 pt-0">
                  <div id="accordionWrap1" role="tablist" aria-multiselectable="true" style="max-height: 75vh; overflow-y: auto" class="px-3 pt-3">
                    <ng-container *ngFor="let requestGroup of requestGroups; let i = index">
                      <div class="row mx-auto w-100 row-design my-1 py-3 bg-light mb-2" *ngIf="requestGroup.draft" (click)="selectedRequestGroup = requestGroup">
                        <div [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'col-7' : 'col-8'">
                          <h6>{{ requestGroup.title }}</h6>
                        </div>
                        <div class="col-2" *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                          <div class="d-flex flex-column justify-content-center h-100 align-content-center">
                            <small class="text-muted">{{ locale("locale_key.general.data_type.date") }}</small>
                            <h6>{{ requestGroup?.getDate() }}</h6>
                          </div>
                        </div>
                        <div class="col-3">
                          <button class="btn tertiary-button custom-button me-4" (click)="onSelectRequestGroup(requestGroup)">{{ locale("locale_key.general.buttons.continue") }}</button>
                          <button class="btn tertiary-button custom-button me-4" (click)="openModal(confirmDeletionDraft, '')">{{ locale("locale_key.general.buttons.delete") }}</button>
                        </div>
                      </div>
                    </ng-container>
                    <div class="d-flex align-items-center justify-content-center h-100" *ngIf="draftRgs.length == 0">
                      {{ locale("locale_key.pages.data_request.no_drafts") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addQuestionaireModal>
  <data-supplier-request-creator (selectedReqGroupSubject)="onSelectRequestGroup($event)"></data-supplier-request-creator>
</ng-template>

<!-- <data-supplier-questionaire [reqGroupToBeDuplicated]="selectedRequestGroup" (close)="requestCreated()" *ngIf="startRequestCreation"></data-supplier-questionaire> -->

<ng-template #confirmDeletionDraft>
  <div class="modal-header" style="border-bottom: none">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">
      <i class="las la-times text-white p-0" style="font-size: 1.5rem; background-color: var(--warning-color); border-radius: 50%; position: relative; left: -2px; top: 2px"></i>
      {{ locale("locale_key.pages.data_request.delete_draft_modal.title") }}
    </h4>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!loadingInProgress">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale("locale_key.pages.data_request.delete_draft_modal.desc", { requestLabel: selectedRequestGroup.title }) }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="deleteDraft()">{{ locale("locale_key.general.buttons.delete") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>


<data-supplier-questionaire [reqGroupToBeDuplicated]="selectedRequestGroup" [isFromDraft]="true" (close)="onCloseRequestGroup()" *ngIf="startRequestCreation"></data-supplier-questionaire>