import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ShareDataService } from "../../services/shareData.service";
import { Subject } from "rxjs";
import { FlowChartOrganizationEntity } from "../../model/FlowChartOrganizationEntity";
import * as UUID from "uuid";
import { DataGridIntegrationService } from "./dataGrid.service";
import { FormControl, FormGroup } from "@angular/forms";
import { ValidationMessages } from "src/app/model/form-validation/FormValidationMessages";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { DataGridColumnMode } from "src/app/components/projects/data-grid-ui/model/DataGridColumnMode";
import { DataGridColumnSchema } from "src/app/components/projects/data-grid-ui/model/DataGridColumnSchema";
import { DataGridColumnType } from "src/app/components/projects/data-grid-ui/model/DataGridColumnType";
import { DataGridStatus } from "src/app/components/projects/data-grid-ui/model/DataGridStatus";


@Component({
    selector:'app-editNode-modal',
    templateUrl:'./editNode.modal.html',
    styleUrls: ['./editNode.modal.css']
})

export class EditNodeModalComponent extends AbstractLanguageComponent{

    mode:string ='add';
    node:FlowChartOrganizationEntity={
        deployed : false,
        modified: false,
        ordinal : 0,
        key : `<TEMP_KEY_${UUID.v4()}>`,
        label: '',
        parents : [],
        dataSchema: [],
        referenceSchema: [],
    };

    parentNode!:FlowChartOrganizationEntity;
    optionalNodes:FlowChartOrganizationEntity[] = [];
    allExistingLabels: string[] = [];
    updatedLabel: string = '';
    selectedOptionalNode:string=''; //will store index of selected node

    value$ = new Subject<FlowChartOrganizationEntity>();
    isPresent=false;
    type='selectFrom';
    format = 'form';
    showRef = true;
    isNodeLabelAdded = false;
    selectExistingEntityMessage = this.locale('locale_key.general.validation_message.select_from_existing_data_group')
    defineNewEntityMessage = this.locale('locale_key.general.validation_message.define_data_group_required')
    columnNameMessage = this.locale('locale_key.general.validation_message.field_name_required')
    columnTypeMessage = this.locale('locale_key.general.validation_message.datatype_required')

    //for form
    columns = new FormGroup({});
    totalCols = 0;
    parentNodeLabels : string[] = [];

    dataGrid= new DataGridIntegrationService();

    //for validation
    allColumnsHaveName : boolean = false;

    constructor(private modalRef: BsModalRef | null,
                private shareDataService: ShareDataService, languageService: LanguageService) {
        super(languageService);
    }

  ngOnInit(){
    this.dataGrid.database.name = this.node.label;
    this.dataGrid.database.dataSchema = this.node.dataSchema;
    this.dataGrid.database.referenceSchema = [...this.node.referenceSchema];
    this.updatedLabel = this.node.label;
    if(this.parentNode && this.mode==='add'){
      this.node.parents = [this.parentNode]; 
      const flow = this.shareDataService.newOrgFlow ? this.shareDataService.newOrgFlow : this.shareDataService.orgChartService.getOrganizationalInfo();
      flow.entities?.forEach((x)=>{
        this.allExistingLabels.push(x.label)
        this.isPresent = false;
        this.checkNodes(x,this.parentNode);
        if(!this.isPresent){
          this.optionalNodes.push(x);
        }
      });

      //add parent in ReferenceSchema if it is not parent node
      if(this.parentNode.key != 'PARENT_NODE'){
        this.node.parents.forEach((parent)=>this.node.referenceSchema.push({referenceKey:parent.key}))
      }
    }

    if(this.dataGrid.getMode().includes("multi")){
      this.node.parents.forEach((x)=>{this.addDataIntoRefTable(x);})
    }

    if(this.mode=="edit"){
      this.node.dataSchema.forEach((col,index)=>{
        this.addNewCol(index, col, true);
      })
    }else{
      this.addNewCol(0);
      this.dataGrid.database.dataSchema.push({name:'', label:'', type:DataGridColumnType.STRING, status: DataGridStatus.ADDED, mode: DataGridColumnMode.NULLABLE , width:'10rem' , deployed:false, modified:true, isCalculated: false});
    }
  }

  //update datagrid with new mainTable name
  async refreshTable(){ 
    this.dataGrid.database.name = this.node.label;
    await this.dataGrid.observer?.renderNewDataTable();
  }

  addDataIntoRefTable(node: FlowChartOrganizationEntity){
    this.dataGrid.referencedTables.push({
      name: node.label,
      dataSchema: node.dataSchema,
      referenceSchema: node.referenceSchema,
      rows:[],
      pageNumber: 1,
      pageSize: 10,
      rowCount: 0,
      ordered: true,
      key: node.key
    })

    this.parentNodeLabels.push(node.label);
    
    node.parents.forEach((x)=>{this.addDataIntoRefTable(x);})
    
  }  
    
  checkNodes(node:FlowChartOrganizationEntity,parent:FlowChartOrganizationEntity){    
    if(node.key!==parent.key && !this.isPresent){
      if(parent.parents.length>0){
        const isAParent = parent.parents.find(parent=>parent.key==node.key);
        if(!isAParent){
          parent.parents.forEach((x)=>{
            this.checkNodes(node,x);
          })
        }else{
          this.isPresent = true;
          return;
        }
      }
    }else{
      this.isPresent = true;
    }
  }

  editNode(){
    if(this.type==='selectFrom' && this.mode == "add"){
      this.node = this.optionalNodes[Number(this.selectedOptionalNode)];
      this.node.parents.push(this.parentNode);
      this.node.referenceSchema.push({referenceKey:this.parentNode.key});
    }else{
      this.node.label = this.updatedLabel;
      let schema : DataGridColumnSchema[] = [];
      if(this.format=="table"){
        schema = this.dataGrid.observer?.getModifiedTableData().dataSchema!;
      }else if(this.format=="form"){
        Object.values(this.columns.controls).forEach((x:FormGroup)=>{
          schema.push({
            name: x.controls.name.value,
            label: x.controls.name.value,
            type: x.controls.type.value,
            mode: DataGridColumnMode.NULLABLE,
            status: DataGridStatus.ADDED,
            width: '10rem',
            deployed: false,
            modified: true,
            isCalculated: false
          })
        })
      }
      if(schema){
        this.node.dataSchema = schema;
      }
    }
      this.value$.next(this.node);
      this.closeModal();
  }

  closeModal(){
    if (!this.modalRef) {
      return;
    }
    this.modalRef?.hide();
    this.modalRef = null;
    document.body.classList.remove('modal-open');
  }

  async toggleShowRef(){
    this.showRef = !this.showRef;
    if(!this.showRef){
      this.dataGrid.database.referenceSchema = [];
    }else{
      this.dataGrid.database.referenceSchema = [...this.node.referenceSchema];
    }
    if(this.format == 'table'){
      await this.dataGrid.observer?.renderNewDataTable();
    }
  }

  addNewCol(index:number , data:DataGridColumnSchema = null, isDatatypeDisabled = false){
    const col = new FormGroup({
      'name': new FormControl(''),
      'type': new FormControl('STRING'),
    });
    
    if(data){
      col.setControl('name',new FormControl(data.label));
      col.setControl('type',new FormControl({value:data.type, disabled: isDatatypeDisabled}));
    }

    let colNo:string|number = index;
    //getting the column no of last column in this.columns formGroup and setting colNo as next column no
    if(Object.keys(this.columns.value)[index]){
      colNo = Object.keys(this.columns.value)[index].slice(-1);
      colNo = Number(colNo)+1;
    }
    this.columns.addControl(`col${colNo}`,col);
    this.totalCols++;
  }

  delCol(index:number){
    this.columns.removeControl(Object.keys(this.columns.value)[index]);
    this.totalCols--;
  }

  addNodeLabel(){
    this.isNodeLabelAdded = true;
    this.node.label = this.updatedLabel;
    this.dataGrid.database.name = this.node.label;
  }

  validate(){
    this.allColumnsHaveName = !(Object.values(this.columns.value).some((x:any)=>x.name==""));
  }
  getControl(){
    return Object.values(this.columns.controls) as FormGroup[];
  }

  changeFormat (format: string) {
    if (format == 'form') {
      this.totalCols = 0;
      this.columns = new FormGroup({});
      let updatedTable = this.dataGrid.observer.getModifiedTableData();
      updatedTable.dataSchema.forEach((col, index)=> this.addNewCol(index, col, col.status != DataGridStatus.ADDED))
    } else {
      this.dataGrid.database.dataSchema = [];
      Object.values(this.columns.controls).forEach((x:FormGroup)=>{
        this.dataGrid.database.dataSchema.push({
          name: x.controls.name.value,
          label: x.controls.name.value,
          type: x.controls.type.value,
          mode: DataGridColumnMode.NULLABLE,
          status: x.get('type').disabled ? DataGridStatus.MODIFIED : DataGridStatus.ADDED,
          width: '10rem',
          deployed: false,
          modified: true,
          isCalculated: false
        })
      })  
    }
    this.format = format;
  }
}