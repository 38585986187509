import { Component,  AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DataGridColumnSchema } from '../../model/DataGridColumnSchema';
import { DataGridTaxonomyEntity } from '../../model/DataGridTaxonomyEntity';
import { ImportedDataService } from '../../service/ImportDataService';
import { DataGridColumnType } from '../../model/DataGridColumnType';
import { DataExtractorServiceInterface } from '../../service/DataExtractorServiceInterface';
import { DataGridRow } from '../../model/DataGridRow';
import { DataGridTableData } from '../../model/DataGridTableData';
import * as UUID from 'uuid';
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex';

@Component({
  selector: 'app-modal',
  templateUrl: './mappingModal.component.html',
  styleUrls: ['./mappingModal.component.scss']
})
export class MappingModalComponent implements AfterViewInit{

  extractedColNames : {label:string, mappedColIndex:number}[]  = [];
  mappedToCols:{col:DataGridColumnSchema, mappedColIndex:number}[]  = [];

  fileName = '';
  fileType = 'excel';
  taxonomyEntities : DataGridTaxonomyEntity[] = [];
  areUniformValues = true;
  isEntityChanged = true;

  //for extracting data from image
  isExtractingFromImg = false;
  imgExtractor!: DataExtractorServiceInterface;
  extractedRows!:DataGridRow[];
  table = new DataGridTableData();
  selectedCols :{col:DataGridColumnSchema, mappedColIndex:number}[]  = [];

  public method= new Subject<string>();
  public entity = new Subject<DataGridTaxonomyEntity>();
  public contentAdded = new Subject<boolean>();
  constructor(public modalRef: BsModalRef,
              private importedDataService: ImportedDataService) {
  }

  async ngAfterViewInit(){
    if(this.taxonomyEntities.length<=1){
      await this.selectEntity({value:0});
    }
    if(!this.isExtractingFromImg){
      this.fileName = await this.importedDataService.excelViewerService.getFileName()
    }
  }

  async selectEntity(target:any){
    let tableDef : any;
    if(this.isExtractingFromImg){
      tableDef = await this.imgExtractor.getEntityTableDef(this.taxonomyEntities[target.value]);
      this.table.name = tableDef.name!;
    }else{
      tableDef = await this.importedDataService.excelViewerService.getEntityTableDef(this.taxonomyEntities[target.value]);
      this.importedDataService.name = tableDef.name!;
    }
    this.mappedToCols = [];
    tableDef.dataSchema?.map((col: DataGridColumnSchema, index:number)=>{
      this.mappedToCols.push({
        col:col,
        mappedColIndex : index<this.extractedColNames.length ? index : -1
      })
    })
    this.isEntityChanged = true;
    this.areUniformValues = true;
  }

  extractedLabelSelected(x:any, mappedColIndex:number): void {
    this.mappedToCols[mappedColIndex].mappedColIndex=Number(x.value);
    this.isEntityChanged = true;
    this.areUniformValues = true;
  }

  extractData(): void{
    if(this.isEntityChanged){

      if(this.isExtractingFromImg){
        let data:DataGridRow[]= [];      
        this.extractedRows.map((el,rowNo:number)=>{
          const rowValues : string[] = [];
          this.mappedToCols.forEach((colData)=>{
            rowValues.push(el.values[colData.mappedColIndex])
          })
          data.push({
            rowId: UUID.v4(),
            values: rowValues,
            status: 'ADDED',
            ordinal: rowNo
          });
        })
        this.table.dataSchema = this.mappedToCols.map(x=>x.col);
        this.table.referenceSchema = [];
        this.table.rows = data;
        this.table.rowCount= data.length;
        this.table.ordered= false;
        this.table.pageSize= 25;
        this.table.pageNumber= 1;
      }
      else{
        this.importedDataService.cols = this.mappedToCols;
        this.importedDataService.setSelectedTableSchema();
        this.importedDataService.setSelectedTableRows();
      }

      if(this.areUniformValues){
        for(let row of this.importedDataService.getSelectedDataTable().rows){
          for(let [colNo,val] of row.values.entries()){
            if(val && val!='' &&  this.mappedToCols[colNo].col.type == DataGridColumnType.DATE && !this.isValidDate(val)){
              this.areUniformValues = false;
              this.isEntityChanged = false;
              return;
            }
          }
        }
      }
    }
    this.updateData();  
    if(this.isExtractingFromImg){
      this.imgExtractor.exportTableData(this.table);
    }else{
      this.importedDataService.excelViewerService.exportTableData(this.importedDataService.getSelectedDataTable());
    }

    this.modalRef.hide();
    document.body.classList.remove('modal-open');
  }

  closeModal(){
    if(this.isExtractingFromImg){
      this.imgExtractor.exportTableData(new DataGridTableData);
    }else{
      this.importedDataService.excelViewerService.exportTableData(new DataGridTableData);
    }
    this.modalRef.hide();
    document.body.classList.remove('modal-open');
  }

  updateData(){
    if(!this.areUniformValues){
      const rows = this.importedDataService.getSelectedDataTable().rows;
      rows.forEach((row,rowNo)=>{
        row.values.map((val,colNo)=>{
          if(this.mappedToCols[colNo].col.type == DataGridColumnType.DATE && !this.isValidDate(val)){
            row.values[colNo]= null;
          }
        })
      })
    }
  }

  isValidDate(date:any) {
    return (typeof(date) == 'string' && date.match(ValidationRegex.DateRegex) != null) || date instanceof Date;
  }
}