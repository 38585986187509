import { QuestionFe } from "./QuestionFe";
import { QUESTION_TYPE } from "./QuestionTypeFe"
import { BooleanStatementFe } from "./condition/BooleanStatementFe";
import { AnswerText_AddFe } from "../../timeline/add/answer/AnswerText_AddFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { AnswerTextFe } from "../../timeline/answer/AnswerTextFe";
import { BooleanStatementBuilderFe } from "./condition/BooleanStatementBuilderFe";

export class QuestionTextFe extends QuestionFe {
  
  newAnswer: AnswerText_AddFe = new AnswerText_AddFe(IdUtil.next(), this.sectionId, this.id, null, null)
  answer: AnswerTextFe
  predefinedOptions: string[]

  constructor(
    id: string, 
    sectionId: string,
    ordinal: number | null,
    followUpCondition: BooleanStatementFe | null,
    question: string,
    desc: string | null,
    comments: boolean,
    required: boolean,
    mappedToColKeys: string[] | null,
    predefinedOptions?: string[]
  ) {
    super(
      id, 
      sectionId,
      QUESTION_TYPE.TEXT,
      ordinal, 
      followUpCondition,
      question, 
      desc, 
      comments, 
      required,
      mappedToColKeys
    );
    this.predefinedOptions = predefinedOptions
  }

  public static fromTransfer(transfer: any, sectionId: string): QuestionTextFe {
    transfer.followUpCondition = transfer.followUpCondition ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition) : null
    let item = this.setQuestion(transfer, sectionId);
    return item
  }

  public static setQuestion(transfer: any, sectionId?): QuestionTextFe {
    let item = new QuestionTextFe (
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.mappedToColKeys,
      transfer.predefinedOptions
    )
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }

}