export class NotificationFe {
  id: string
  type: string
  status: string
  title: string
  summaryLabel: string
  summaryValue: string
  timeStamp: string

  constructor(id: string, type: string, status: string, title: string, summaryLabel: string, summaryValue: string, timeStamp: string) {
    this.id = id
    this.type = type 
    this.status = status
    this.title = title 
    this.summaryLabel = summaryLabel
    this.summaryValue = summaryValue
    this.timeStamp = timeStamp
  }

  public static fromTransfer(transfer: any): NotificationFe {
    let id = transfer.id
    let type = transfer.type
    let status = transfer.status
    let title = transfer.title
    let summaryLabel = transfer.summary.label
    let summaryValue = transfer.summary.value
    let utcDate = transfer.time_stamp.value;
    let localDate = new Date(utcDate);
    let timestamp = localDate.toLocaleString()
    let notification = new NotificationFe(id, type, status, title, summaryLabel, summaryValue, timestamp)
    return notification
  }
}