export class PredefinedTextOptionFe {
    text: string
  
    constructor(text: string = "") {
      this.text = text
    }
  
    public static fromJsonObj(obj: any): PredefinedTextOptionFe {
      return new PredefinedTextOptionFe(
        obj.text,
      )
    }
  }