import { DataPointFe } from "./DataPointFe";
import { PipelineFunctionFe } from "./PipelineFunctionFe";
export class DataSeriesFe {

  public id: string
  public label: string
  public calculationPipeline: PipelineFunctionFe = PipelineFunctionFe.SUM
  public dataPoints: DataPointFe[] = []
  
  public selectedDataPointIndex: number

  constructor(){
  }

  public static fromTransfer(transfer: any): DataSeriesFe {
    let ds = new DataSeriesFe();
    ds.id = transfer.id;
    ds.label = transfer.label, 
    ds.calculationPipeline = PipelineFunctionFe.fromTransfer(transfer.calculationPipeline),
    ds.dataPoints = transfer.dataPoints.map(dataPoint => DataPointFe.fromTransfer(dataPoint))
    return ds
  }
  
  addDataPoint() {
    this.dataPoints.push(new DataPointFe())
  }

  deleteSelectedDataPoint() {
    this.dataPoints.splice(this.selectedDataPointIndex, 1)
  }

  setId (index) {
    this.id = '_dataSeries_'+index;
  }
}
