import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { VisitorUserFe } from "../model/org/VisitorUserFe";
import { RouterFe } from "../route/RouterFe";
import { RoutesFe } from "../route/RoutesFe";
import { UserServiceFe} from "./UserServiceFe";

@Injectable({
  providedIn: 'root'
})

export class VisitorServiceFe {
  private static VISITOR_SESSION_TOEKN_KEY = 'sustainlab.studio.visitor.token'
  public user: VisitorUserFe | null
  public isValid: boolean = false
  public openRoute: boolean = false
  public verifiedSubject: Subject<boolean> = new Subject<boolean>()
  
  constructor(httpBackend: HttpBackend, private router: Router, private backendService: RouterFe, private userService: UserServiceFe) {
  }

  private getTokenHeader(token: string): HttpHeaders {
    let headers = new HttpHeaders({'Authorization': 'Bearer ' + token})
    return headers
  }

  public async checkValidity() {
    if (!this.isValid) {
      let token = this.loadToken()
      if (token) {
        try {
          let tokenHeader: HttpHeaders = this.getTokenHeader(token)
          let isValid = await this.backendService.isVisitorValid(tokenHeader); 
          let user = await this.backendService.getVisitorInfo(tokenHeader);
          this.user = VisitorUserFe.fromTransfer(user)
          if (isValid) {
            this.setAsValid()
          } else {
            this.setAsInvalid()
          }  
        } catch (err) {
          this.setAsInvalid()
        } 
      } else {
        this.setAsInvalid()
      }

    }
  }

  private setAsValid() {
    this.userService.setUserAsVisitor();
    this.isValid = true
    this.verifiedSubject.next(true)
  }

  async verify (email: string, enteredCode: string): Promise<boolean> {
    try {
      let user = await this.backendService.verifySharedDashboardVerificationCode(email, enteredCode);
      this.user = VisitorUserFe.fromTransfer(user);
      sessionStorage.setItem(VisitorServiceFe.VISITOR_SESSION_TOEKN_KEY, user.token)
      this.setAsValid()
      return true;
    } catch (err) {
      this.user = null;
      throw err
    }
  }

  private setAsInvalid() {
    this.user = null
    this.isValid = false
    this.verifiedSubject.next(false)
    this.router.navigate([RoutesFe.LOGIN.fullPath()])
  }
  
  async logout() {
    try {
      await this.backendService.logoutVisitor();
    } catch (err) {
    }
    this.setAsInvalid()
  }
  
  loadToken(): string | undefined {
    let token = sessionStorage.getItem(VisitorServiceFe.VISITOR_SESSION_TOEKN_KEY)
    return token
  }

  public handleSessionError() {
    this.setAsInvalid()
  }
  
  getUserInfo(): VisitorUserFe | undefined {
    return this.user
  }

}