import { Component, AfterViewChecked } from "@angular/core";

@Component({
  selector: "open-layout",
  templateUrl: "./open-layout.component.html",
  styleUrls: ["./open-layout.component.scss"],
})
export class OpenLayoutComponent implements AfterViewChecked {

  constructor() {
  }

  async ngOnInit() {
  }

  ngAfterViewChecked(): void {
  }
}
