<div class="supplier-modal">
  <div class="main-modal-header modal-header py-0 px-3">
    <h6 class="modal-title text-white text-truncate bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.title') }}</h6>
    <button type="button" class="btn close" (click)="closeModal()" *ngIf="!isLoading">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="white">&times;</span>
    </button>
  </div>
  <div class="modal-body scroll-y modal-min-h" style="border-top: none">
    <div class="col-lg-12">
      <div class="row mt-3">
        <div class="container">
          <div class="progress-container mb-3 mx-5" [ngClass]="screenSize.isXSmallSize() ? 'd-flex flex-column' : ''">
            <ng-container *ngIf="!screenSize.isXSmallSize()">
              <div class="col-4 text-center d-flex flox-row align-items-center">
                <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(1) }">
                  <span *ngIf="!isCompleted(1)">1</span>
                  <span *ngIf="isCompleted(1)" class="fs-4 la la-check text-white"></span>
                </div>
                <h6 class="m-0">{{ locale('locale_key.pages.network.add_business_partner_wizard.company_information') }}</h6>
              </div>
              <div class="col-1 align-self-center">
                <div>
                  <span class="fs-4 la la-angle-right pt-1"></span>
                </div>
              </div>
              <div class="col-3 text-center d-flex flox-row align-items-center">
                <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(2) }">
                  <span *ngIf="!isCompleted(2)">2</span>
                  <span *ngIf="isCompleted(2)" class="fs-4 la la-check text-white"></span>
                </div>
                <h6 class="m-0">{{ locale('locale_key.pages.network.add_business_partner_wizard.contact_person') }}</h6>
              </div>
              <div class="col-1 align-self-center">
                <div>
                  <span class="fs-4 la la-angle-right pt-1"></span>
                </div>
              </div>
              <div class="col-3 text-center d-flex flox-row align-items-center">
                <div class="m-2 circle1 active-circle1" [ngClass]="{ 'fill-circle': isCompleted(3) }">
                  <span *ngIf="!isCompleted(3)">3</span>
                  <span *ngIf="isCompleted(3)" class="fs-4 la la-check text-white"></span>
                </div>
                <h6 class="m-0">{{ locale('locale_key.pages.network.add_business_partner_wizard.invitation_email') }}</h6>
              </div>
            </ng-container>
            <ng-container *ngIf="screenSize.isXSmallSize()">
              <div class="d-flex flex-row justify-content-center mb-1">
                <div class="d-block text-center">
                  <div class="col stepper text-center" *ngFor="let step of steps; let i = index" [class.active-stepper]="currentActive === i + 1 || step.completed"></div>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-center mb-1">
                <h6 class="text-center m-0">{{ steps[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})</h6>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <hr class="mt-0 pt-0 mx-3" />
    </div>
    <form [formGroup]="form1" *ngIf="activeFormNo == 1">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <div class="row px-1">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <i class="la la-building me-2 fs-5 my-auto"></i>
                  <h6 class="me-2 my-auto text-truncate bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.business_partner_title') }}</h6>
                  <i class="fs-4 la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ locale('locale_key.pages.network.add_business_partner_wizard.how_to_identify_company_desc') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-1 mt-3">
              <div class="col-12 mt-1">
                <div class="form-group">
                  <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.company_name') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.network.add_business_partner_wizard.company_name.placeholder') }}"
                    required
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': form1.get('name').errors && (form1.get('name').touched || form1.get('name').dirty)
                    }"
                  />
                  <div *ngIf="form1.get('name').errors && (form1.get('name').touched || form1.get('name').dirty)" class="invalid-feedback">
                    <div *ngIf="form1.get('name').errors.required">
                      {{ companyNameMessage }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-1">
                <div class="form-group">
                  <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.country') }}*</label>
                  <select
                    formControlName="country"
                    class="form-select form-control"
                    [ngClass]="{
                      'is-invalid': form1.get('country').errors && form1.get('country').touched
                    }"
                  >
                    <option *ngFor="let countryData of countries" [value]="countryData[0]" style="font-family: 'Open Sans'; color: #405369">{{ resolveLabel(countryData[1]) }}</option>
                  </select>
                  <div *ngIf="form1.get('country').errors && form1.get('country').touched" class="invalid-feedback">
                    <div *ngIf="form1.get('country').errors.required">
                      {{ companyCountry }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-1">
                <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.industry') }}*</label>
                <div class="row">
                  <div class="" [ngClass]="screenSize.isSmallerSize() ? 'col-12 p-balance' : 'col-6'">
                    <div class="form-group">
                      <select
                        class="form-select form-control"
                        formControlName="industryGroup"
                        [ngClass]="{
                          'is-invalid': form1.get('industryGroup').errors && form1.get('industryGroup').touched
                        }"
                        required
                        (change)="industryGroupChanged()"
                      >
                        <option *ngFor="let industryData of industries" [value]="industryData.industryGroup[this.activeLanguage.code]">
                          {{ industryData.industryGroup[this.activeLanguage.code] }}
                        </option>
                      </select>
                      <div *ngIf="form1.get('industryGroup').touched || form1.get('industryGroup').value == industries[0].industryGroup[activeLanguage.code]" class="invalid-feedback">
                        <div>
                          {{ companyIndustryGroup }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="" [ngClass]="screenSize.isSmallerSize() ? 'col-12 p-balance' : 'col-6'">
                    <div class="form-group">
                      <select
                        class="form-select form-control"
                        formControlName="industry"
                        [ngClass]="{
                          'is-invalid': form1.get('industry').errors && form1.get('industry').touched
                        }"
                        required
                      >
                        <option *ngFor="let industry of industriesList" [value]="industry">
                          {{ industry }}
                        </option>
                      </select>
                      <div *ngIf="form1.get('industry').touched && form1.get('industry').value == industries[0].industry[0][activeLanguage.code]" class="invalid-feedback">
                        <div *ngIf="form1.get('industry').value == industries[0].industry[0][activeLanguage.code]">
                          {{ companyIndustry }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-1">
                <div class="form-group">
                  <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.web_address') }}*</label>
                  <input
                    type="url"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.network.add_business_partner_wizard.web_address.placeholder') }}"
                    formControlName="website"
                    [ngClass]="{
                      'is-invalid': form1.get('website').errors && (form1.get('website').touched || form1.get('website').dirty)
                    }"
                  />
                  <div *ngIf="form1.get('website').errors && (form1.get('website').touched || form1.get('website').dirty)" class="invalid-feedback">
                    <div *ngIf="form1.get('website').errors.required">
                      {{ companyWebAddress }}
                    </div>
                    <div *ngIf="form1.get('website').errors?.pattern">
                      {{ companyWebFromatMessage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="form2" *ngIf="activeFormNo == 2">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <div class="row px-1">
              <div class="col-12">
                <div class="d-flex align-items-center">
                  <i class="la la-user me-2 fs-5 my-auto"></i>
                  <h6 class="me-2 my-auto text-truncate bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.primary_contact_person') }}</h6>
                  <!-- <i class="fs-4 la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i> -->
                  <!-- <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ locale('locale_key.pages.network.add_business_partner_wizard.primary_contact_person.desc') }}</p>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="row px-1 mt-3">
              <div class="col-12 mt-1">
                <div class="form-group">
                  <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.primary_contact_person_first_name') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.network.add_business_partner_wizard.primary_contact_person_first_name.placeholder') }}"
                    required
                    formControlName="primaryContactFirstName"
                    [ngClass]="{
                      'is-invalid': form2.get('primaryContactFirstName').errors && (form2.get('primaryContactFirstName').touched || form2.get('primaryContactFirstName').dirty)
                    }"
                  />
                  <div *ngIf="form2.get('primaryContactFirstName').errors && (form2.get('primaryContactFirstName').touched || form2.get('primaryContactFirstName').dirty)" class="invalid-feedback">
                    <div *ngIf="form2.get('primaryContactFirstName').errors.required">
                      {{ firstNameMessage }}
                    </div>
                    <div *ngIf="form2.get('primaryContactFirstName').hasError('pattern')">
                      {{ firstNameMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group">
                  <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.primary_contact_person_last_name') }}*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.network.add_business_partner_wizard.primary_contact_person_last_name.placeholder') }}"
                    required
                    formControlName="primaryContactLastName"
                    [ngClass]="{
                      'is-invalid': form2.get('primaryContactLastName').errors && (form2.get('primaryContactLastName').touched || form2.get('primaryContactLastName').dirty)
                    }"
                  />
                  <div *ngIf="form2.get('primaryContactLastName').errors && (form2.get('primaryContactLastName').touched || form2.get('primaryContactLastName').dirty)" class="invalid-feedback">
                    <div *ngIf="form2.get('primaryContactLastName').errors.required">
                      {{ lastNameMessage }}
                    </div>
                    <div *ngIf="form2.get('primaryContactLastName').hasError('pattern')">
                      {{ lastNameMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group">
                  <label class="bold">{{ locale('locale_key.pages.network.add_business_partner_wizard.primary-contact.email') }}*</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="{{ locale('locale_key.pages.network.add_business_partner_wizard.primary-contact.email') }}"
                    required
                    formControlName="primaryContactEmail"
                    [ngClass]="{
                      'is-invalid': form2.get('primaryContactEmail').errors && (form2.get('primaryContactEmail').touched || form2.get('primaryContactEmail').dirty)
                    }"
                  />
                  <div *ngIf="form2.get('primaryContactEmail').errors && (form2.get('primaryContactEmail').touched || form2.get('primaryContactEmail').dirty)" class="invalid-feedback">
                    <div *ngIf="form2.get('primaryContactEmail').errors.required">
                      {{ emailMessage }}
                    </div>
                    <div *ngIf="form2.get('primaryContactEmail').hasError('pattern')">
                      {{ lowerCaseEmail }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="form3" *ngIf="activeFormNo == 3">
      <div class="modal-body">
        <div class="content clearfix">
          <div class="form-group">
            <div class="row px-1">
              <div class="col-12">
                <div class="d-flex flex-row align-items-center">
                  <span class="fs-3 la la-envelope-o me-1"></span>
                  <h6 class="m-0">{{ locale('locale_key.pages.clients.invite_manager_wizard.invitation_email.title') }}</h6>
                  <i class="fs-5 la la-info-circle m-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ locale('locale_key.pages.data.supplier.invite_partner.message') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-1 mt-3">
              <div class="col-12 mt-1 mb-5">
                <div class="form-group d-flex flex-row align-items-center">
                  <div id="lang-dropdown" class="btn-group me-3 mb-1" style="width: 150px">
                    <button class="form-select border border-secondary" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span *ngIf="selectedLang"><i class="{{ selectedLang.icon }} me-2"></i>{{ selectedLang.label }}</span>
                    </button>
                    <div class="dropdown-menu">
                      <ng-container *ngFor="let language of languages">
                        <a class="dropdown-item m-0 px-3" style="color: var(--main-dark-color); cursor: pointer" *ngIf="selectedLang?.code != language.code" (click)="updateLanguage(language)"
                          ><i class="{{ language.icon }} me-2"></i> {{ language.label }}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="includeEnglish" (change)="onIncludeEnglishChange($event)" [disabled]="disableEnglish" [checked]="includeEnglish" />
                    <label class="form-check-label ml-2" style="font-size: 12px"> {{ locale('locale_key.pages.dashboard.shared_dashboard_modal.include_en_translation') }} </label>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 p-3">
                <div class="form-group row">
                  <div class="col-12">
                    <p>{{ locale('locale_key.pages.data.supplier.invite_partner.email_template.message') }}</p>
                    <button (click)="openModal()" class="text-decoration-underline bg-white border-0 p-0">{{ locale('locale_key.pages.dashboard.email_template.edit.link') }}</button>
                  </div>
                  <confirm-cancel-modal
                    (actionClicked)="navigateToEditTemplate()"
                    (cancelClicked)="onCancel()"
                    [isVisible]="showNavigateModal"
                    [headerText]="modalHeaderText"
                    [bodyText]="modalBodyText"
                    [cancelButtonText]="cancelButtonText"
                    [actionButtonText]="actionButtonText"
                  ></confirm-cancel-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn border border-secondary custom-button" (click)="closeModal()">{{ locale('locale_key.general.buttons.cancel') }}</button>
      <button class="btn secondary-button custom-button" (click)="prev()" [hidden]="activeFormNo == 1">{{ locale('locale_key.general.buttons.previous') }}</button>
      <button class="btn btn-add white custom-button" [disabled]="form1.invalid || form1.get('industryGroup').value == industries[0].industryGroup[activeLanguage.code] || form1.get('industry').value == industries[0].industry[0][activeLanguage.code]" [hidden]="activeFormNo != 1" (click)="next()">
        {{ activeFormNo == 3 ? locale('locale_key.pages.network.add_business_partner_wizard.button.add_network') : locale('locale_key.general.buttons.next') }}
      </button>
      <button class="btn btn-add white custom-button" [disabled]="form2.invalid" [hidden]="activeFormNo == 1" (click)="activeFormNo == 2 && form2.valid ? getLink() : activeFormNo == 3 ? addNewPartner() : next()">
        {{ activeFormNo == 3 ? locale('locale_key.pages.network.add_business_partner_wizard.button.add_network') : locale('locale_key.general.buttons.next') }}
      </button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</div>
