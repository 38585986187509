export class RouteFe {
  private layoutpath: string
  rootPath: string
  private parameterType: string | null
  private parameterValue: string | null
  static CUSTOMER_SUCCESS: any

  constructor(layoutpath: string, rootPath: string, parameterType: string | null) {
    this.layoutpath = layoutpath
    this.rootPath = rootPath
    this.parameterType = parameterType
  }

  public fullPath(): string {
    if (this.parameterType) {
      return `${this.layoutpath}/${this.rootPath}/:${this.parameterType}`
    } else {
      return `${this.layoutpath}/${this.rootPath}`
    }
  }

  public childPath(): string {
    if (this.parameterType) {
      return `${this.rootPath}/:${this.parameterType}`
    } else {
      return this.rootPath
    }
  }

  public doesRouteMatch(path: string): boolean {
    return path.startsWith(`/${this.layoutpath}/${this.rootPath}`)
  }

  public isRouteSame(path: string): boolean {
    if(this.parameterType) {
      return path == `/${this.layoutpath}/${this.rootPath}/${this.parameterType}`
    }else{
      return path == `/${this.layoutpath}/${this.rootPath}`
    }
  }

  public getPath(parameter: string = null): string {
    if (this.parameterType) {
      return `${this.layoutpath}/${this.rootPath}/${parameter}`
    } else {
      return `${this.layoutpath}/${this.rootPath}`
    }
  }

  public setParameter (parameter: string) {
    this.parameterValue = parameter;
  }
}