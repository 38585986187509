<div class="unit_systems_page">
  <div class="header">
    <div class="spacer"></div>
    <div class="title">{{ locale("locale_key.pages.units.title") }}</div>
  </div>
  <div class="body">
    <div class="controls">
      <div *ngFor="let control of controls; let isFirst = first" [class.first-control]="isFirst" class="control d-flex flex-column justify-content-center align-items-center" [id]="control.id" (click)="controlClicked(control.id)"
        [title]="control.title">
        <i class="fs-4" [class]="control.icon"></i>
        <div>{{ control.name }}</div>
      </div>
    </div>
    <div class="main_content">
      <div class="controls">
        <unit-selector [mode]="'filter'" [filterBy]="'symbol'" [filter]="nameOrSymbolFilter" [unitsByMeasurementType]="filteredUnitsByMeasurementType" [customUnits]="filteredCustomUnits"
          (filterChanged)="filterUnits($event)"></unit-selector>
        <unit-selector [mode]="'filter'" [filterBy]="'system'" [filter]="unitSystemsFilter" (filterChanged)="filterUnits($event)"></unit-selector>
        <unit-selector [mode]="'filter'" [filterBy]="'measurementType'" [filter]="measurementTypeFilter" [unitsByMeasurementType]="filteredUnitsByMeasurementType" [customUnits]="filteredCustomUnits"
          (filterChanged)="filterUnits($event)"></unit-selector>
      </div>
      <div class="accordion accordion-flush unit_systems">
        <!-- CUSTOM UNITS -->
        <div class="accordion-item measurement_type" *ngIf="filteredCustomUnits.length > 0">
          <div class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#CUSTOM'" aria-expanded="false" aria-controls="collapseTwo">
              {{ locale("locale_key.pages.units.custom") }}
            </button>
          </div>
          <div [id]="'CUSTOM'" class="accordion-collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="system">
                <div class="system_name"></div>
                <div class="units">
                  <div class="unit" [class.collapsed]="unit.collapsed || unit?.prefixList?.length == 0" *ngFor="let unit of filteredCustomUnits">
                    <div class="unitHeader" (click)="toggleUnitCollapse(unit)">
                      <div class="name">
                        {{ unit.name }}
                        <span class="symbol">({{ getSymbol(unit) }})</span>
                      </div>
                      <div class="edit_unit control" (click)="editUnit(unit)">
                        <i class="las la-edit"></i>
                      </div>
                    </div>
                    <div class="unitBody">
                      <div class="description">
                        {{ unit.description }}
                      </div>
                      <div class="prefixes" *ngIf="unit?.prefixList?.length > 0">
                        <ng-container *ngFor="let prefix of unit?.prefixList">
                          <div class="prefix">
                            <div class="name">
                              {{ prefix.name }}
                              <span class="symbol">({{ getSymbol(prefix) }})</span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- OTHER UNITS -->
        <div class="accordion-item measurement_type" *ngFor="let measurementType of filteredUnitsByMeasurementType">
          <div class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#' + measurementType.key" aria-expanded="false" aria-controls="collapseTwo">
              {{ measurementType.name }}
            </button>
          </div>
          <div [id]="measurementType.key" class="accordion-collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="system" *ngFor="let system of measurementType.systems">
                <div class="system_name">
                  {{ system.name }}
                </div>
                <div class="units">
                  <div class="unit" [class.collapsed]="unit.collapsed || unit?.prefixList?.length == 0" *ngFor="let unit of system.units">
                    <div class="unitHeader" (click)="toggleUnitCollapse(unit)">
                      <div class="name">
                        {{ unit.name }}
                        <span class="symbol">({{ getSymbol(unit) }})</span>
                      </div>
                    </div>
                    <div class="unitBody">
                      <div class="description">
                        {{ unit.description }}
                      </div>
                      <div class="prefixes" *ngIf="unit?.prefixList?.length > 0">
                        <ng-container *ngFor="let prefix of unit?.prefixList">
                          <div class="prefix">
                            <div class="name">
                              {{ prefix.name }}
                              <span class="symbol">({{ getSymbol(prefix) }})</span>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <unit-editor [units]="units" #unitEdtiorModal></unit-editor>
</div>