import { QUESTION_TYPE } from "../../../request/questionnaire/QuestionTypeFe";
import { Answer_AddFe } from "./Answer_AddFe";

export class AnswerText_AddFe extends Answer_AddFe{
  text: string

 constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    text: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.TEXT,
      comments
    )
    this.text = text
  }

  public isInvalid (isRequired: boolean): boolean {
    if (isRequired) {
      return !this.hasAnyValue()
    }
    return false
  }

  public hasAnyValue(): boolean {
    return this.text?.length>0
  }

  public reset (): void {
    this.text = null
  }

  public toTransfer() {
    let ans = new AnswerText_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.text
    )
    return ans
  }

}
