import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractActivityFe } from "./AbstractActivityFe";
import { ActivityTypeFe } from "./ActivityTypeFe";

export class DisconnectSectionsActivityFe extends AbstractActivityFe {
  icon = "la la-chain-broken color-orange"

  constructor(
    id: string,
    submitterAffId: string,
    submitterFirstName: string,
    submitterLastName: string,
    submitterCompanyId: string,
    submitterCompanyName: string,
    submissionDate: Date | null,
    message: string | null,
    draft: boolean | null,
    readStatus: string,    
    requestId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    public submittedAnswerActivityId: string,
    public disconnectedSectionIds: string[],
    languageService: LanguageService
  ) {
    super(
      id,
      ActivityTypeFe.DISCONNECT_SECTIONS,
      submitterAffId,
      submitterFirstName,
      submitterLastName,
      submitterCompanyId,
      submitterCompanyName,
      submissionDate,
      message,
      draft,
      readStatus,      
      requestId,
      requestGroupId,
      requestGroupLabel,
      languageService
    )
    this.homePageLocaleKey = `locale_key.pages.data_request.activity.data_removed`
    this.homePageLocaleKeyDO = `locale_key.pages.data_request.activity.data_removed`
    this.activityHistoryLocaleKey = `locale_key.pages.data_request.activity.data_removed_from_master_table`  
  }

  public static fromTransfer(transfer: any, languageService: LanguageService): DisconnectSectionsActivityFe {
    let activity = new DisconnectSectionsActivityFe(
      transfer.id,
      transfer.submitterAffId,
      transfer.submitterFirstName,
      transfer.submitterLastName,
      transfer.submitterCompanyId,
      transfer.submitterCompanyName,
      transfer.submissionDate,
      transfer.message,
      transfer.draft,
      transfer.readStatus,      
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      transfer.submittedAnswerActivityId,
      transfer.disconnectedSectionIds,
      languageService
    )
    return activity;
  }  

}
