import { MathStatementFe } from "./MathStatementFe"

export class ValueStatementFe extends MathStatementFe {
  public static TYPE_KEY = 'VALUE'
  public type = ValueStatementFe.TYPE_KEY

  constructor(public readonly unit: string, public readonly value: number) {
    super()
  }
  public toString(): string {
    return this.value + ' ' + this.unit 
  }

  public flatten(): any[] {
    return [this]
  }

  isPlaceholder() {
    return false
  }

  public tooltip(): string {
    return this.value + ''
  }

}