import { Component, OnInit, Output, Input, TemplateRef, ElementRef, QueryList, ViewChildren, ViewChild, SimpleChange } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuestionAttachmentFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionAttachmentFe';
import { QuestionDataTableFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe';
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe';
import { RequestFe, REQUEST_TASK_STATUS, READ_STATUS } from 'src/app/model/data-suppliers/request/RequestFe';
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe';
import { ActivityTypeFe } from 'src/app/model/data-suppliers/timeline/ActivityTypeFe';
import { SubmitAnswersActivityFe } from 'src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe';
import { AttachmentFe } from 'src/app/model/details/AttachmentFe';
import { FileDataFe } from 'src/app/model/file/FileDataFe';
import { FileTypeFe } from 'src/app/model/file/FileTypeFe';
import { TableDataFe } from 'src/app/model/schema/TableDataFe';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { TableStorageServiceFe } from 'src/app/services/TableStorageServiceFe';
import { RequestEntryDataGridIntegrationService } from '../data-suppliers/data-supplier-request/RequestEntryDataGridIntegrationService';
import { jsPDF } from 'jspdf';
import { Subject, Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import { DashboardFe } from 'src/app/model/dashboard/DashboardFe';
import { KpiDefFe } from 'src/app/model/kpi/KpiDefFe';
import { LoginUserFe } from 'src/app/model/org/LoginUserFe';
import { EntityFe } from 'src/app/model/taxonomy/EntityFe';
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe';
import { ChartBuilderServiceFeNew } from 'src/app/services/ChartBuilderServiceFeNew';
import { ChartSettingFe } from 'src/app/model/chart/ChartSettingFe';
import { KpiDataFe } from 'src/app/model/kpi/KpiDataFe';
import { ValueFieldFe } from 'src/app/model/pipeline/ValueFiledFe';
import { KpiFe } from 'src/app/model/kpi/KpiFe';
import { PipelineFe } from '../../model/pipeline/PipelineFe'
import { QuestionSingleChoiceFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe';
import { QuestionMultipleChoiceFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe';
import { AnswerSingleChoiceFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerSingleChoiceFe';
import { AnswerMultipleChoiceFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerMultipleChoiceFe';
import { NgxCaptureService } from 'ngx-capture';
import { PipelineDefFe } from 'src/app/model/pipeline/PipelineDefFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { DatahubService } from '../data-hub/DatahubService';
import { QUESTIONNAIRE_STATUS } from 'src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe';
import { RequestServiceFe } from 'src/app/services/RequestServiceFe';
import { AnswerFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerFe';
import { ChartWrapperFeNew } from 'src/app/model/chart/ChartWrapperFeNew';
import { InsightDefFe } from 'src/app/model/insight/InsightDefFe';
import { KpiFe2 } from 'src/app/model/insight/KpiFe2';
import { InsightProcessingPipelineDefFe } from 'src/app/model/insight/InsightProcessingPipelineDefFe';
import { InsightFe } from 'src/app/model/insight/InsightFe';
import { GroupingFe } from 'src/app/model/insight/GroupingFe';
import { DataSeriesFe } from 'src/app/model/insight/DataSeriesFe';
import { PipelineFunctionFe } from 'src/app/model/insight/PipelineFunctionFe';
import { DataGridServiceInterface } from '../projects/data-grid-ui/service/DataGridServiceInterface';
import { CloseRequestActivity_AddFe } from 'src/app/model/data-suppliers/timeline/add/CloseRequestActivity_AddFe';
import { IdUtil } from 'src/app/utils/IdUtil';
import { RequestPeriodFe } from 'src/app/model/data-suppliers/request/recurrence/RequestPeriodFe';
import { SectionFe } from 'src/app/model/data-suppliers/request/questionnaire/SectionFe';
import { RoutesFe } from 'src/app/route/RoutesFe';
import { Router } from '@angular/router';
import { AnswerAttachmentFe } from 'src/app/model/data-suppliers/timeline/answer/AnswerAttachmentFe';
import { NoUnit } from '../unit-systems/unit-selector/unit-selector.component';
import { UnitUtilFe } from 'src/app/utils/UnitUtilFe';

@Component({
  selector: 'data-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss', '../common/data-suppliers.component.scss']
})
export class InventoryComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('export', { static: true }) export: TemplateRef<any>;
  viewNo = 2;
  viewType = 'dataReporter';
  requestGroups: RequestGroupFe[] = []
  @Input() requestGroup: RequestGroupFe
  @Input() requestPeriod: RequestPeriodFe
  @Output() closeInventory = new Subject<boolean>()
  @Input() selectedRequest: RequestFe
  @Input() exportResult = new Subject<boolean>();
  approvedRequests = 0;

  // Export functionality
  typeOfExport = 'pdf';
  loading = false;

  @Input() data = new Subject<boolean>();
  submittedAnswers = 0;
  isChatOpen = false
  reviewMessage = null;
  userInfo: LoginUserFe
  public taxonomyInfo: TaxonomyInfoFe;
  public chartWrappers: ChartWrapperFeNew[] = [];
  public isCollapsed: boolean[] = [];
  public canvases: any;
  public dataEntities: EntityFe[] = [];
  private firstTimeDraw = true
  public inProgress = false;
  public isLoadingData = false
  public dashboardsIncludingKPI: DashboardFe[]  = []
  private canvasSubscription: Subscription; 
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode;
  url: string ='';

  @Input() showHeader: boolean = false
  @Input() showToolbar: boolean = true
  @Input() showRequestPortalButton: boolean = false

  loadingInProgress = false;
  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: "la la-arrow-left",
        actionName: "back_from_view2",
        visible: () => true,
        disabled: () => this.loading 
      },
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.export'),
        longLabel: this.locale('locale_key.general.toolbar.button.export'),
        tooltip: this.locale('locale_key.general.toolbar.button.export'),
        icon: "la la-download",
        actionName: "export",
        visible: () => true,
        disabled:  this.loading
      },
    ],
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info", actionName:"toggle_quick_tips" , visible:()=> true, disabled: false}
    ], 
  ];

  @ViewChildren('canvas', { read: ElementRef }) htmlCanvases: QueryList<ElementRef>;
  @ViewChild("tableView") tableView!: ElementRef;
  @ViewChild("questionView") questionView!: ElementRef;

  subscriptions = new Subscription();
  selectedAnswer: AnswerFe;

  constructor(
    private stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    private tableStorageService: TableStorageServiceFe, 
    private domSanitizer: DomSanitizer,
    private modalRef: BsModalRef,private datahubService: DatahubService,
    private modalService: BsModalService,
    private chartBuilderService: ChartBuilderServiceFeNew,
    public requestService: RequestServiceFe,
    private router: Router,
    private captureService: NgxCaptureService, languageService: LanguageService, private responsive: ResponsiveService) { 
      super(languageService)
      this.url = window.location.href;
      this.initCacheInProgress = this.stateService.initCacheInProgress
      this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
        this.initCacheInProgress = initCacheInProgress
      });
  
      this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
        this.menuCollapsed = collapsed
      });
  
      this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
        this.screenSize = screenSize
      });
      this.screenSize = responsive.currentScreenWidthSize;
    }

  async ngOnInit(): Promise<void> {
    this.requestGroups = await this.stateService.getRequestGroups();
    this.taxonomyInfo = (await this.stateService.getTaxonomyInfos()).depTaxonomy;
    this.setData(); 
    this.subscriptions.add(
      this.datahubService.exportRgResult.subscribe(download => this.viewType=='dataReporter'? this.exportDashobard() : this.openModal(this.export, 'modal-md'))
    );
    this.subscriptions.add(
      this.exportResult.subscribe(res => this.viewType=='dataReporter'? this.exportDashobard() : this.openModal(this.export, 'modal-md'))
    )
  }

  ngOnChanges() {
    this.setData();
  }

  setData() {
    if (this.requestPeriod) {
      const id = this.requestPeriod.requestGroupId
      this.requestGroup = this.requestGroups.find(r => r.id == id)
    }
    if (!this.selectedRequest) {
      this.selectedRequest = this.requestGroup.requests[0]
    }
    this.buildKPIs();
    this.submittedAnswers = this.requestGroup.requests.filter(req => req.questionnaire.status != QUESTIONNAIRE_STATUS.CREATED).length;
    this.requestGroup.requests.forEach(req => {
      if (req.questionnaire.status != QUESTIONNAIRE_STATUS.CREATED) {
        let answers = req.timelineItems.slice().reverse().find(a => a.type == ActivityTypeFe.SUBMIT_ANSWERS);
        if (answers instanceof SubmitAnswersActivityFe) {
          req.questionnaire.setAnswers(answers);
        }
      }
    })
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async openFile (question: QuestionAttachmentFe) {
    let answer = question.answer
    answer.isLoading = true;
    if (!answer.file) {
      let name = this.requestGroup.title
      let file = await this.downloadFileFromServer(answer.attachmentStorageId, name);
      file.label = this.getAttachmentTitle(question)
      answer.file = file;
    }
    answer.isLoading = false;
    this.openAttachment(answer.id, null, answer.file, this.selectedRequest.questionnaire.relatedActivity.submissionDate);
  }

  
  async downloadFileFromServer(fileId: string, fileName: string): Promise<FileDataFe> {
    const fileType = this.getFileExtension(fileId);
    let file = FileDataFe.fromBlob(`${fileName}.${fileType.extension}`);
    file.loadingFileInProgress = true;
    file.id = fileId;
    let content = await this.stateService.downloadAttachmentFile(this.stateService.activeWorkspace.companyId, file.id);
    file.addBlobContent(content, this.domSanitizer);
    file.type = fileType;
    file.loadingFileInProgress = false;
    return file;
  }

  getFileExtension(fileId: string): FileTypeFe {
    return FileTypeFe.fromName(fileId);
  }

  openAttachment(id: string, dataGridService: DataGridServiceInterface | null, file: FileDataFe | null, submissionDate: Date) {
    let attachment = new AttachmentFe(id, dataGridService, file, submissionDate)
    if (dataGridService) {
      attachment.isTable = true
      attachment.isFile = false
    } else {
      attachment.isFile = true
      attachment.isTable = false
    }
    this.displayService.openAttachment(attachment)
  }

  async openTableFile(question: QuestionDataTableFe) {
    let answer = question.answer
    answer.isLoading = true;
    let service = new RequestEntryDataGridIntegrationService(new TableDataFe());
    if (!answer.dataTable) {
      let content = await this.stateService.downloadDataTableFile(this.stateService.activeWorkspace.companyId, answer.dataStorageId);
      service.mode = "view";
      
      await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
        service.table.key = answer.id;
        service.table.rows = table.rows;
        service.table.rowCount = table.rows.length;
        service.table.dataSchema = table.dataSchema;
        answer["dataTable"] = table;
        answer["dataGridService"] = service;
        service.observer?.renderNewDataTable();
        this.openAttachment(answer.id, service, null, this.selectedRequest.questionnaire.relatedActivity.submissionDate);
      });
    } else {
      this.openAttachment(answer.id, answer.dataGridService, null, this.selectedRequest.questionnaire.relatedActivity.submissionDate);
    }
    answer.isLoading = false;
  }
  
  openModal(template: TemplateRef<any> |string, clasz = "modla-md") {
    this.modalRef = this.modalService.show(template, {class: clasz,backdrop: "static", keyboard: true });
  }

  closeModal (close: boolean = true) {
    if (close) {
      this.modalService.hide(this.modalRef.id);
      document.body.classList.remove("modal-open");
    }
  }

  close () {
    this.closeInventory.next(true);
  }
  
  async exportDashobard () {
    this.loading = true;
    const pdf = new jsPDF("p", "mm", "a4");
    const a4Size= {height:300,width:210};
    let leftMargin = 10, topMargin = 20, rightMargin = 10;
    if (this.viewType == 'table') {
      let imgData = await this.captureService.getImage(this.tableView.nativeElement, true).toPromise();
      if (this.typeOfExport == 'pdf') {
        const imgProps = pdf.getImageProperties(imgData);
        let height = imgProps.height;
        let width = imgProps.width;
        let ratio = height / width;
        if (height > width) {
          height=a4Size.height - topMargin;
          width=height*(1/ratio);
        } else if(width > height){
          width=a4Size.width - leftMargin - rightMargin;
          height=width*ratio;
        }
        pdf.addImage(imgData, leftMargin, topMargin,width,height);   
        pdf.save('tableView.pdf');
      } else {
        let link = document.createElement('a');
        link.href = imgData;
        link.download = 'tableView';
        link.click();
      }
      this.loading = false;
      this.closeModal(true)
    } else if (this.viewType == 'question') {
     
      if (this.typeOfExport == 'pdf') {    
        let questions = this.selectedRequest.questionnaire.getQuestions()
        this.loading = true;
        let addingImagesSub = new Subject<boolean>();
        let noOfQuestionsAdded = 0;
        let data : {index: number, img: any, height: any, width: any}[] = []
        let subscription = addingImagesSub.subscribe(done => {
          noOfQuestionsAdded++;
          if (noOfQuestionsAdded == questions.length){
            data.sort((a, b) => a.index - b.index);
            data.forEach((d, i) => {
              if(i!=0){
                pdf.addPage();
              }
              // const imgProps = pdf.getImageProperties(d.img);
              let height = d.height;
              let width = d.width;
              let ratio = height / width;
              if (height > width) {
                height=a4Size.height - topMargin;
                width=height*(1/ratio);
              } else if(width > height){
                width=a4Size.width - leftMargin - rightMargin;
                height=width*ratio;
              }
              pdf.addImage(d.img, leftMargin, topMargin,width,height);   
            })
            pdf.save(`perQuestionView.pdf`); 
            this.loading = false;
            this.closeModal(true)
            subscription.unsubscribe();
          }
        })
        questions.forEach(async (question, indx) =>{
          const source = document.getElementById(`question${indx}`);
          let imgData = await this.captureService.getImage(source, true).toPromise();
          data.push({'index':indx, 'img': imgData,'height': source.clientHeight, 'width' : source.clientWidth});          
          addingImagesSub.next(true);
        })
      } else {
        let imgData = await this.captureService.getImage(this.questionView.nativeElement, true).toPromise();
        let link = document.createElement('a');
        link.href = imgData;
        link.download = 'questionView';
        link.click();
        this.loading = false;
        this.closeModal(true)
      }
    } else {
      this.downloadCSV()
      this.loading = false;
    }    
  }

  downloadCSV() {
    let questions = this.selectedRequest.questionnaire.getQuestions()
    let fileName = 'View_Per_DO.csv';
    let columnNames = [" DO Name"];
    for (let q of questions) {
      columnNames.push(q.question)
    }
    let header = columnNames.join(',');
    let csv = header;

    this.requestGroup.requests.forEach(r => {
      if (r.status == REQUEST_TASK_STATUS.APPROVED) {
        csv += '\r\n';
        questions = r.questionnaire.getQuestions()
        let answer =[r.assigneeFirstName + ' ' + r.assigneeLastName]
        questions.map((c) => {
          if (c.type == "DATA TABLE") {
            answer.push(c["fields"][1].key)
          } else if (c.type == "SINGLE CHOICE") {
            let selectedOptionId = c["answer"]?.["selectedOptionId"]
            let optionAnswer = c['options'].find(option => option.id == selectedOptionId)?.value;
            answer.push(optionAnswer)
          } else if (c.type == "MULTIPLE CHOICE") {
            let selectedOptionIds = new Set(c["answer"]?.["selectedOptionIds"])
            let options = c['options'].filter(option => selectedOptionIds.has(option.id));
            let optionAnswer = options.map(option => option.value).join(', ');
            answer.push(optionAnswer)
          } else if (c.type == "TEXT") {
            answer.push(c["answer"]?.["text"])
          } else if (c.type == "NUMBER") {
            answer.push(c["answer"]?.["value"])
          } else if (c.type == "DATE") {
            answer.push(`${c["answer"]?.["dateFrom"]} ${c["answer"]?.["dateTo"] ? "-" + c["answer"]?.["dateTo"] : ''}`)
          }
        })
        csv += answer.join(',')
      }
    })
    var blob = new Blob([csv], { type: "text/csv" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  downloadGraphImage(index) {
    this.canvases.map((canvas, indx) => {
      if (indx == index) {
        let canvasElem = document.getElementById(canvas.nativeElement.id) as HTMLCanvasElement;
        var url_base64jp = canvasElem.toDataURL();
        let link = document.createElement('a');
        link.href = url_base64jp;
        var insight = this.chartWrappers[indx].insight
        link.download = insight.title;
        link.click();
      }
    })
  }

  downloadGraphCSV(index) {
    var data, filename, link;
    var datasets = this.chartWrappers[index].chart.data.datasets
    var labels = this.chartWrappers[index].chart.data.labels

    var csv = '';
    datasets.forEach(dataset => {
      csv = csv + ',' + ((dataset.label) ? (dataset.label) : '')
    });

    labels.forEach((label, i) => {
      csv = csv + '\n'
      csv = csv + label
      datasets.forEach(dataset => {
        csv = csv + ',' + dataset.data[i]
      });
    });
    csv = csv + '\n'

    if (csv == null) return;
    var kpiData = this.chartWrappers[index].insight
    filename = kpiData.title + '.csv' || 'chart-data.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }

    data = encodeURI(csv);
    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  downloadGraphPDF(index){

    this.canvases.map((canvas, indx) => {
      if (indx == index) {
        var pdf = new jsPDF("p", "mm", "a4");
        const a4Size= {height:300,width:210};
        let canvasElem = document.getElementById(canvas.nativeElement.id) as HTMLCanvasElement;
        let leftMargin = 10, topMargin = 20, rightMargin = 10;
        let height = canvasElem.clientHeight;
        let width = canvasElem.clientWidth;
        let ratio = height / width;
        if(height > width){
          height=a4Size.height - topMargin;
          width=height*(1/ratio);
        }else if(width > height){
          width=a4Size.width - leftMargin - rightMargin;
          height=width*ratio;
        }
        var url_base64jp = canvasElem.toDataURL();      
        pdf.addImage(url_base64jp, leftMargin, topMargin,width,height);   
        var kpiData = this.chartWrappers[indx].insight
        pdf.save(`${kpiData.title}.pdf`);
      }
    })
  }

  async buildKPIs () {
    let questions = this.requestService.getQuestions(this.selectedRequest) 
    questions.forEach((question, i)=>{
      if (question instanceof QuestionSingleChoiceFe || question instanceof QuestionMultipleChoiceFe) {
        question.options.forEach(op => op.noOfDataOwners = 0);
        this.requestGroup.requests.forEach(req=>{
          let questions = req.questionnaire.getQuestions()
          if (req.status == REQUEST_TASK_STATUS.APPROVED && questions[i].answer) {
            if (questions[i] instanceof QuestionSingleChoiceFe) {
              let optionId = (questions[i].answer as AnswerSingleChoiceFe).selectedOptionId
              let option = question.options.find(op => op.id == optionId)
              option.noOfDataOwners++;
            } else if (questions[i] instanceof QuestionMultipleChoiceFe) {
              (questions[i].answer as AnswerMultipleChoiceFe).selectedOptionIds.forEach(optionId => {
                let option = question.options.find(op => op.id == optionId)
                option.noOfDataOwners++;
              })
            }
          }
        })

        // let kpiDef: KpiDefFe = new KpiDefFe(question.id, 1, ' ', null, null);
        // let valueFields: ValueFieldFe[] = question.options.map(op => new ValueFieldFe(op.id, op.value));
        // let cf1 = ['metric']
        // let rowData = {'metric': 'No Of Data Owners'};
        // question.options.forEach(op => rowData[op.id] = op.noOfDataOwners)
        // let data = [{rowValues: rowData}];
        // let kpi = new KpiFe({data})
        // let pipeline = PipelineFe.fromTransfer({'categoryFields': cf1});
        // pipeline.definition = new PipelineDefFe()
        // pipeline.definition.units = [`#`]
        // let newKpiFe = new KpiDataFe(kpiDef.id, kpiDef.ordinal, kpiDef.title, null, pipeline, null, null, valueFields , cf1, true, [kpi])
        // this.firstTimeDraw = true
        // let wrapper = ChartWrapperFe.build(newKpiFe)

        // let valueFields: ValueFieldFe[] = question.options.map(op => new ValueFieldFe(op.id, op.value));
        let rowData = {'metric': 'No Of Data Owners'};
        question.options.forEach(op => rowData[op.id] = op.noOfDataOwners)
        let data = [{rowValues: rowData}];
        let kpi = new KpiFe2({data})
        let dataSeries = question.options.map(op => DataSeriesFe.fromTransfer({id: op.id, label: op.value, calculationPipeline: PipelineFunctionFe.SUM, dataPoints:[]}));
        let grouping = [GroupingFe.fromTransfer({id: 'metric', label: 'Metric', dataPoints: []})]
        let insightPipelineDef = new InsightProcessingPipelineDefFe(dataSeries, '#', '#', null, grouping, null )
        let insight = new InsightFe(question.id, '', new Date(), insightPipelineDef, true, [kpi]);
        this.firstTimeDraw = true
        let wrapper = ChartWrapperFeNew.build(insight)

        question.chartWrapperIndex = this.chartWrappers.length;
        wrapper.setting.legendPosition = 'right';
        this.chartWrappers.push(wrapper)
      }
    })
    this.refreshView()
  }

  refreshView() {
    if (this.canvasSubscription) {
      this.canvasSubscription.unsubscribe()
      this.firstTimeDraw = true
    }
    this.canvasSubscription = this.htmlCanvases?.changes.subscribe((canvases) => {
      if (this.firstTimeDraw) {
        this.firstTimeDraw = false
        this.canvases = canvases
        this.drawAllCharts()  
      }
    })

  }

  drawAllCharts() {
    this.canvases.forEach((canvas, indx) => {
      this.drawChart(canvas, indx)
    });
  }

  drawChart(canvas, index) {
    let wr = this.chartWrappers[index]
    if (wr.chart) {
      wr.chart.destroy();
    }
    let chartData = this.chartBuilderService.buildChart(wr.insight, wr.setting)
    let chart = new Chart(canvas.nativeElement.getContext('2d'), chartData)
    wr.chart = chart
  }

  onChangedSetting(index): void {
    let hiddenDatasetLabels = this.getHiddenDatasetLabels(index)
    this.chartWrappers[index].chart.destroy();
    let kpiData = this.chartWrappers[index].insight
    let setting = this.chartWrappers[index].setting
    var chartData = this.chartBuilderService.buildChart(kpiData, setting);
    var chart = new Chart(this.canvases._results[index].nativeElement.getContext('2d'), chartData)
    this.chartWrappers[index].chart = chart
    this.hideDatasets(index, hiddenDatasetLabels)
  }

  getHiddenDatasetLabels(index) {
    let hiddenDatasetLabels = []
    let chart = this.chartWrappers[index].chart
    chart.data.datasets.forEach((d, i) => {
      if (chart.getDatasetMeta(i).hidden) {
        hiddenDatasetLabels.push(d.label)
      }
    });
    return hiddenDatasetLabels
  }

  hideDatasets(index, datasetLabels: string[]) {
    let chart = this.chartWrappers[index].chart
    chart.data.datasets.forEach((d, i) => {
      if (datasetLabels.includes(d.label)) {
        chart.getDatasetMeta(i).hidden = true
        chart.update()
      }
    });
  }

  async exportFile (question: QuestionAttachmentFe | QuestionDataTableFe, request: RequestFe, exportType: string) {
    question.answer.isLoading = true;
    let name = `${this.requestGroup.title}_${request.assigneeFirstName}`;
    if (question instanceof QuestionAttachmentFe) {
      name = `${name}_${question.question}`;
      if (!question.answer.file) {
        let file = await this.downloadFileFromServer(question.answer.attachmentStorageId, name);
        question.answer.file = file;
      }
      question.answer.file.setExportType(exportType)
      question.answer.file.saveFile();
    } else {
      if (!question.answer.dataTable) {
        let service = new RequestEntryDataGridIntegrationService(new TableDataFe());
        let content = await this.stateService.downloadDataTableFile(this.stateService.activeWorkspace.companyId, question.answer.dataStorageId);
        service.mode = "view";
        await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
          service.table.key = question.answer.id;
          service.table.rows = table.rows;
          service.table.rowCount = table.rows.length;
          service.table.dataSchema = table.dataSchema;
          question.answer["dataTable"] = table;
          question.answer["dataGridService"] = service;
          service.downloadSheet(name, exportType);
        });
      } else {
        (question.answer.dataGridService as RequestEntryDataGridIntegrationService).downloadSheet(name, exportType);
      }
    }
    question.answer.isLoading = false;
  }

  toggleTips(){
    this.displayService.toggleTips()
  }

  getUnreadActivities() {
    let acts = this.selectedRequest.timelineItems.filter(item => item.readStatus == READ_STATUS.UNREAD);
    return acts;
  }

  openRequest() {
    this.displayService.viewRequestGroup(this.requestGroup)
  }
  
  async disconnectAllSections() {
    try {
      this.loadingInProgress = true;
      await this.requestService.disconnectSections(this.selectedRequest, this.selectedRequest.questionnaire.sections);
      this.closeModal();
      this.loadingInProgress = false;
    } catch (err) {
      this.closeModal();
      this.loadingInProgress = false;
    }
  }

  async connectAllSections() {
    try {
      this.loadingInProgress = true;
      await this.requestService.connectAllSections(this.selectedRequest)
      this.closeModal();
      this.loadingInProgress = false;
    } catch (err) {
      this.closeModal();
      this.loadingInProgress = false;
    }
  }

  async markAsRead() {
    await this.stateService.markAllRequestActivitiesRead(this.requestGroup, this.selectedRequest);
  }

  async closeRequest() {
    this.loadingInProgress = true;
    let activity = new CloseRequestActivity_AddFe(IdUtil.next(), this.reviewMessage, this.selectedRequest.id, this.requestGroup.id, this.requestGroup.title, this.requestGroup.requesterFirstName, this.requestGroup.requesterLastName, this.requestGroup.requesterCompanyName);
    await this.stateService.addRequestTimelineItem(activity, this.requestGroup.id, this.selectedRequest.id);
    this.closeModal();
    this.reviewMessage = null;
    this.loadingInProgress = false;
  }

  
  openMasterTable(section: SectionFe) {
    if (section.taxonomyKey) {
      this.datahubService.showEntityMasterTable(section.entity)
      this.router.navigate([RoutesFe.DATA_HUB.fullPath()])
    }
  }

 getAttachmentTitle(question: QuestionAttachmentFe): string {
    let title = AnswerAttachmentFe.getTitle(this.requestGroup.title, question.question, this.selectedRequest.getAssigneeName(), this.selectedRequest.questionnaire.relatedActivity.submissionDate, question.answer.attachmentLabel)
    return title
  }

  getUnitString(unit) {
    return UnitUtilFe.getUnitString(unit)
  }
}