import { Component, OnInit } from "@angular/core";
import { AffiliationRoleFe } from "src/app/model/data-suppliers/company/AffiliationRoleFe";
import { AccessibleCompanyFe, ADMIN_WORKSPACE } from "src/app/model/org/AccessibleCompanyFe";
import { LoginUserFe } from "src/app/model/org/LoginUserFe";
import { RouterFe } from "src/app/route/RouterFe";
import { RoutesFe } from "src/app/route/RoutesFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { ActivatedRoute, Router } from "@angular/router";
import { SignInMethodProviderFe } from "src/app/model/signInMethod/SignInMethodProviderFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";

@Component({
  selector: "app-workspaces",
  templateUrl: "./workspaces.component.html",
  styleUrls: ["./workspaces.component.scss"],
})
export class WorkspacesComponent extends AbstractLanguageComponent implements OnInit {
  loginUser: LoginUserFe;
  filteredCompanies: AccessibleCompanyFe[] = []
  searchKeyword: string = '';

  constructor (public stateService: StateServiceFe, private loginService: LoginServiceFe, private router: Router, private backendService: RouterFe, 
    private activatedRoute: ActivatedRoute, public languageService: LanguageService) {
    super(languageService);
    const workspaceAction = this.activatedRoute.snapshot.queryParams['workspaceAction'];
    if (workspaceAction == 'changeWorkspace') {
      const workspace = JSON.parse(atob(this.activatedRoute.snapshot.queryParams['workspace'])) as AccessibleCompanyFe
      if (workspace) {
        this.changeWorkspace(workspace);
      } else {
        //bug of some sort
        console.log('Cannot switch to empty workspace. bug of some sort');
      }
    }
  }

  async ngOnInit (): Promise<void> {
    this.loginService.loggedInSubject.subscribe((loggedIn: boolean) => {
      this.loginUser = this.loginService.getUserInfo();
    });
    this.loginUser = this.loginService.getUserInfo();
    if (this.loginUser.isAdmin) {
      this.loginUser.accessibleCompanies.forEach(c => {
        c.affiliationRole = AffiliationRoleFe.SUSTAINABILITY_MANAGER
      })
    }
    this.filteredCompanies = this.loginUser.accessibleCompanies
  }

  async changeWorkspace (company: AccessibleCompanyFe) {
    this.router.navigate([RoutesFe.LOADING.fullPath()]);
    await this.stateService.loadWorkspace(company, true);
    await this.updateActivities();
    this.router.navigate([RoutesFe.HOME.fullPath()]);
  }

  async logout() {
    await this.loginService.logout();
  }

  async updateActivities(): Promise<void> {
    this.router.navigate([RoutesFe.LOADING.fullPath()]);
    let companies = await this.backendService.updateReadActivities();
    companies = companies.map(c => AccessibleCompanyFe.fromTransfer(c));
    this.loginUser.accessibleCompanies = companies;
    this.router.navigate([RoutesFe.WORKSPACES.fullPath()]);
  }

  async changeToAdmin () {
    this.router.navigate([RoutesFe.LOADING.fullPath()]);
    await this.stateService.loadWorkspaceData(ADMIN_WORKSPACE, true);
    this.router.navigate([RoutesFe.CLIENTS.fullPath()]);
  }

  loginToWorkspace ({ type, workspace }) {
    const workspaceStr = btoa(JSON.stringify(workspace));
    console.log({ 'tag': 'login to workspace', type, workspace, workspaceStr })
    this.router.navigate([RoutesFe.LOGIN.fullPath()], {
      queryParams: {
        'loginAction': 'switchWorkspace',
        'loginNextUrl': this.router.url,
        'loginType': type,
        workspace: workspaceStr
      }
    });
  }

  isGoogleLoginRequired (workspace: AccessibleCompanyFe) {
    const isGoogleAllowed = workspace.signInMethods.find(method => method.provider == SignInMethodProviderFe.GOOGLE && method.isEnabled)
    return isGoogleAllowed
  }

  isMicrosoftLoginRequired (workspace: AccessibleCompanyFe) {
    const isMicrosoftAllowed = workspace.signInMethods.find(method => method.provider == SignInMethodProviderFe.MICROSOFT && method.isEnabled)
    return isMicrosoftAllowed
  }

  isPasswordLoginRequired (workspace: AccessibleCompanyFe) {
    const isPasswordAllowed = workspace.signInMethods.find(method => method.provider == SignInMethodProviderFe.PASSWORD && method.isEnabled)
    return isPasswordAllowed
  }

  requireLogin (workspace: AccessibleCompanyFe) {
    if (!workspace.signInMethods || workspace.signInMethods.length == 0) {
      return false;
    }
    for (let i = 0; i < workspace.signInMethods.length; i++) {
      if (workspace.signInMethods[i].provider == this.loginUser.signedInMethod.provider && workspace.signInMethods[i].isEnabled) {
        return false;
      }
    }
    return true;
  }

  filter () {
    this.filteredCompanies = this.loginUser.accessibleCompanies.filter(c => c.companyName.toLowerCase().includes(this.searchKeyword.toLowerCase()))
  }
}
