import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { DR_DATAPOINTS_PAGES } from "../dr-datapoints.component";
import {
  CSRDDataPointSettings,
  CSRDDisclosureSettings,
  CSRDDisclosureSettingsStatus,
  CSRDEsrsSettings,
  CSRDProjectInfoFe,
  CSRDTopicSettings
} from "src/app/model/project/CSRDProjectInfoFe";
import _ from "lodash";
import { BsModalService } from "ngx-bootstrap/modal";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { DR_TABS } from "../../dr.component";

enum LEAVE_PAGE_SOURCES {
  "BACK" = "BACK",
  "ALL_PROJECTS" = "ALL_PROJECTS"
}

@Component({
  selector: "csrd-dr-datapoints-edit",
  templateUrl: "./dr-datapoints-edit.component.html",
  styleUrls: ["./dr-datapoints-edit.component.scss"]
})
export class DrDatapointsEditComponent extends AbstractLanguageComponent implements OnInit {
  showAllInfo: boolean = false;
  showAllComments: boolean = false;
  savingAssessment: boolean = false;
  changesMade = false;
  addingComment: {
    [datapointId: string]: boolean;
  } = {};

  @ViewChild("omitDataModal") omitDataModal: TemplateRef<any>;
  @ViewChild("deleteCommentModal") deleteCommentModal: TemplateRef<any>;
  @ViewChild("saveBeforeLeavingModal") saveBeforeLeavingModal: TemplateRef<any>;
  @ViewChild("cancelProcessModal") cancelProcessModal: TemplateRef<any>;
  @ViewChild("saveAsDraftModal") saveAsDraftModal: TemplateRef<any>;

  @Output() switchDrDatapointsPage = new EventEmitter<DR_DATAPOINTS_PAGES>();
  @Output() switchProjectsPage = new EventEmitter<boolean>();
  @Output() switchCsrdProjectPage = new EventEmitter<boolean>();
  @Output() switchActiveDrTab = new EventEmitter<{ tab: DR_TABS }>();

  @Input() activeCsrdProject: CSRDProjectInfoFe;
  @Input() activeTopic: any;
  @Input() activeEsrs: any;
  @Input() activeDr: any;

  activeDatapoint;
  csrdDatapoints;
  leavePageSource: LEAVE_PAGE_SOURCES;

  constructor(
    languageService: LanguageService,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe
  ) {
    super(languageService);
  }

  ngOnInit(): void {
    //'ESRS 2' is mandatory
    if (this.activeEsrs.code == "ESRS 2") {
      this.activeEsrs.isMaterial = true;
    }

    const { newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();
    this.activeDr.hasDraft = newDisclosureSettings.hasDraft;
    this.activeDr.status = newDisclosureSettings.status;

    this.csrdDatapoints = this.activeDr.csrdDatapoints.map((datapoint) => {
      datapoint.showMoreInfo = false;

      datapoint.isConditional = false;
      datapoint.isOmitted = false;
      datapoint.canOmit = false;
      datapoint.hasPhaseIn = false;
      datapoint.collectVoluntarily = false;
      datapoint.showComment = false;

      // Get from Esrs Settings first, then get from csrdProject Settings
      datapoint.isMaterial = newEsrsSettings.isMaterial;

      let newDatapointSettings: CSRDDataPointSettings;
      if (this.activeDr.hasDraft) {
        newDisclosureSettings.draft.csrdDatapoints.forEach((datapointSettings) => {
          if (datapoint.id == datapointSettings.id) {
            newDatapointSettings = _.cloneDeep(datapointSettings);
          }
        });
      } else {
        newDisclosureSettings.csrdDatapoints.forEach((datapointSettings) => {
          if (datapoint.id == datapointSettings.id) {
            newDatapointSettings = _.cloneDeep(datapointSettings);
          }
        });
      }

      if (!_.isEmpty(newDatapointSettings)) {
        datapoint.isOmitted = newDatapointSettings.isOmitted;
        datapoint.isMaterial = newDatapointSettings.isMaterial;
        datapoint.collectVoluntarily = newDatapointSettings.collectVoluntarily;
        datapoint.comment = newDatapointSettings.comment;
      }

      datapoint = this.setDatapointCanOimt(datapoint);

      return datapoint;
    });
  }

  toggleSwitch(switchName) {
    switch (switchName) {
      case "showAllInfo":
        this.showAllInfo = !this.showAllInfo;
        this.csrdDatapoints = this.csrdDatapoints.map((datapoint) => {
          datapoint.showMoreInfo = this.showAllInfo;
          return datapoint;
        });
        break;
      case "showAllComments":
        this.showAllComments = !this.showAllComments;
        break;
    }
  }

  getNewTopicEsrsAndDisclosureSettings(): {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  } {
    let newTopicSettings: CSRDTopicSettings;
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == this.activeTopic.id) {
        newTopicSettings = _.cloneDeep(topicSettings);
      }
    });

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: this.activeTopic.id,
        esrs: []
      };
    }

    let newEsrsSettings: CSRDEsrsSettings;
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == this.activeEsrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings);
      }
    });

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: this.activeEsrs.id,
        isAssessed: false,
        isMaterial: false,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      };
    }

    let newDisclosureSettings: CSRDDisclosureSettings;
    newEsrsSettings.disclosures.forEach((disclosureSettings) => {
      if (disclosureSettings.id == this.activeDr.id) {
        newDisclosureSettings = _.cloneDeep(disclosureSettings);
      }
    });

    if (_.isEmpty(newDisclosureSettings)) {
      newDisclosureSettings = {
        id: this.activeDr.id,
        status: CSRDDisclosureSettingsStatus.NOT_STARTED,
        hasDraft: false,
        draft: {
          csrdDatapoints: []
        },
        questionnaire: [],
        csrdDatapoints: []
      };
    }

    return { newTopicSettings, newEsrsSettings, newDisclosureSettings };
  }

  updateNewTopicEsrsAndDisclosureSettings({
    newTopicSettings,
    newEsrsSettings,
    newDisclosureSettings
  }: {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  }) {
    let existingDisclosureFound = false;
    newEsrsSettings.disclosures = newEsrsSettings.disclosures.map((disclosureSettings) => {
      if (disclosureSettings.id == newDisclosureSettings.id) {
        existingDisclosureFound = true;
        return newDisclosureSettings;
      }
      return disclosureSettings;
    });
    if (!existingDisclosureFound) {
      newEsrsSettings.disclosures.push(newDisclosureSettings);
    }

    let existingEsrsFound = false;
    newTopicSettings.esrs = newTopicSettings.esrs.map((esrsSettings) => {
      if (esrsSettings.id == newEsrsSettings.id) {
        existingEsrsFound = true;
        return newEsrsSettings;
      }
      return esrsSettings;
    });
    if (!existingEsrsFound) {
      newTopicSettings.esrs.push(newEsrsSettings);
    }

    let existingTopicFound = false;
    this.activeCsrdProject.definition.csrdSettings = this.activeCsrdProject.definition.csrdSettings.map((topicSettings) => {
      if (topicSettings.id == newTopicSettings.id) {
        existingTopicFound = true;
        return newTopicSettings;
      }
      return topicSettings;
    });
    if (!existingTopicFound) {
      this.activeCsrdProject.definition.csrdSettings.push(newTopicSettings);
    }
  }

  setDatapointCanOimt = (datapoint) => {
    //handle canOmit logic
    // If ESRS is material and Datapoint is material
    if (this.activeEsrs.isMaterial && datapoint.isMaterial) {
      if (datapoint.isVoluntary) {
        datapoint.canOmit = true;
      }
      if (datapoint.hasPhaseIn) {
        datapoint.canOmit = true;
      }
    }

    // If ESRS is not material and Datapoint is material and ESRS is not E1
    if (!this.activeEsrs.isMaterial && datapoint.isMaterial && !`${this.activeEsrs.code}`.toUpperCase().includes("E1")) {
      if (`${this.activeDr.code}`.toUpperCase().includes("IRO-2")) {
        datapoint.canOmit = true;
      }
      if (datapoint.isVoluntary) {
        datapoint.canOmit = true;
      }
      if (datapoint.hasPhaseIn) {
        datapoint.canOmit = true;
      }
    }

    // IRO-1 datapoints must be mandatory
    if (`${datapoint.id}`.toUpperCase().includes("IRO-1")) {
      datapoint.isMaterial = true;
      datapoint.canOmit = false;
    }

    // If ESRS is E1, IRO-2 datapoints are mandatory as well
    if (`${this.activeEsrs.code}`.toUpperCase().includes("E1") && `${datapoint.id}`.toUpperCase().includes("IRO-2")) {
      datapoint.isMaterial = true;
      datapoint.canOmit = false;
    }

    return datapoint;
  };

  toggleShowMoreInfo(datapoint) {
    datapoint.showMoreInfo = !datapoint.showMoreInfo;
  }

  toggleMateriality(datapoint) {
    this.changesMade = true;
    datapoint.isMaterial = !datapoint.isMaterial;
    datapoint = this.setDatapointCanOimt(datapoint);
  }

  toggleCollectVoluntarily(datapoint) {
    this.changesMade = true;
    datapoint.collectVoluntarily = !datapoint.collectVoluntarily;
  }

  toggleAddingComment(datapoint) {
    this.changesMade = true;
    this.addingComment[datapoint.id] = !this.addingComment[datapoint.id];
  }

  toggleShowComment(datapoint) {
    this.changesMade = true;
    datapoint.showComment = !datapoint.showComment;
  }

  adjustCommentHeight(textarea: HTMLTextAreaElement) {
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 126)}px`;
  }

  startDeleteComment(datapoint) {
    this.changesMade = true;
    this.activeDatapoint = datapoint;
    this.openModal(this.deleteCommentModal, "modal-md");
  }

  cancelDeleteComment() {
    this.closeModal();
  }

  confirmDeleteComment() {
    this.activeDatapoint.comment = "";
    this.closeModal();
  }

  openModal(templateRef: TemplateRef<any>, size: string = "") {
    this.modalService.show(templateRef, { class: size });
  }

  closeModal() {
    this.modalService.hide();
  }

  startOmitDatapoint(datapoint) {
    this.changesMade = true;
    this.activeDatapoint = datapoint;
    if (this.activeDatapoint.isOmitted) {
      this.activeDatapoint.isOmitted = false;
    } else {
      this.openModal(this.omitDataModal, "modal-md");
    }
  }

  cancelOmitDatapoint() {
    this.closeModal();
  }

  confirmOmitDatapoint() {
    this.activeDatapoint.isOmitted = true;
    this.closeModal();
  }

  startCancelProcess() {
    if (this.activeDr.status == CSRDDisclosureSettingsStatus.ASSESSING) {
      //switch to overview page
      this.switchActiveDrTab.emit({
        tab: DR_TABS.OVERVIEW
      });
      return;
    }

    if (this.changesMade == true) {
      this.openModal(this.cancelProcessModal, "modal-md");
    } else {
      //Need to call this, to cause main dr page to reboot and update any statuses
      this.switchActiveDrTab.emit({
        tab: DR_TABS.DATAPOINTS
      });
      this.switchDrDatapointsPage.emit(DR_DATAPOINTS_PAGES.VIEW);
    }
  }

  async saveAssessment(opts = { draft: false }) {
    this.savingAssessment = true;

    const { newTopicSettings, newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();
    if (opts.draft) {
      newDisclosureSettings.status = CSRDDisclosureSettingsStatus.ASSESSING;
      newDisclosureSettings.hasDraft = true;
      newDisclosureSettings.draft = { csrdDatapoints: [] };
    } else {
      newDisclosureSettings.status = CSRDDisclosureSettingsStatus.ASSESSED;
      newDisclosureSettings.hasDraft = false;
      newDisclosureSettings.draft = { csrdDatapoints: [] };
      newDisclosureSettings.csrdDatapoints = [];
    }

    this.csrdDatapoints.forEach((datapoint) => {
      const setting: CSRDDataPointSettings = {
        id: datapoint.id,
        isMaterial: datapoint.isMaterial,
        isOmitted: datapoint.isOmitted,
        collectVoluntarily: datapoint.collectVoluntarily,
        comment: datapoint.comment
      };

      if (opts.draft) {
        newDisclosureSettings.draft.csrdDatapoints.push(setting);
      } else {
        newDisclosureSettings.csrdDatapoints.push(setting);
      }
    });

    this.updateNewTopicEsrsAndDisclosureSettings({ newTopicSettings, newEsrsSettings, newDisclosureSettings });
    await this.stateService.updateCsrdProject(this.activeCsrdProject);

    this.savingAssessment = false;
    this.changesMade = false;

    if (opts.draft) {
      //switch to overview page
      this.switchActiveDrTab.emit({
        tab: DR_TABS.OVERVIEW
      });
    } else {
      //Need to call this, to cause main dr page to reboot and update any statuses
      this.switchActiveDrTab.emit({
        tab: DR_TABS.DATAPOINTS
      });
      this.switchDrDatapointsPage.emit(DR_DATAPOINTS_PAGES.VIEW);
    }
  }

  openCSRDInfoTab(datapoint) {
    this.displayService.openCSRDInfoTab(datapoint);
  }

  canSwitchCsrdProjectPage() {
    console.log(`Checking if changes were made :: ${this.changesMade}`);
    let canSwitch = true;
    if (this.changesMade == true) {
      canSwitch = false;
      /* Means back button was clicked */
      this.leavePageSource = LEAVE_PAGE_SOURCES.BACK;
      this.openModal(this.saveBeforeLeavingModal, "modal-md");
    }
    return canSwitch;
  }

  canSwitchProjectsPage() {
    let canSwitch = true;
    if (this.changesMade == true) {
      canSwitch = false;
      /* Means "all projects" button was clicked */
      this.leavePageSource = LEAVE_PAGE_SOURCES.ALL_PROJECTS;
      this.openModal(this.saveBeforeLeavingModal, "modal-md");
    }
    return canSwitch;
  }

  dontLeavePage() {
    this.closeModal();
  }

  leavePage() {
    switch (this.leavePageSource) {
      case LEAVE_PAGE_SOURCES.BACK:
        this.switchCsrdProjectPage.emit(true);
        this.closeModal();
        break;
      case LEAVE_PAGE_SOURCES.ALL_PROJECTS:
        this.switchProjectsPage.emit(true);
        this.closeModal();
        break;
    }
  }

  dontCancelProcess() {
    this.closeModal();
  }

  cancelProcess() {
    //Need to call this, to cause main dr page to reboot and update any statuses
    this.switchActiveDrTab.emit({
      tab: DR_TABS.DATAPOINTS
    });
    this.switchDrDatapointsPage.emit(DR_DATAPOINTS_PAGES.VIEW);
    this.closeModal();
  }

  startSaveAsDraft() {
    this.openModal(this.saveAsDraftModal, "modal-md");
  }

  cancelSaveAsDraft() {
    this.closeModal();
  }

  async saveAsDraft() {
    await this.saveAssessment({ draft: true });
    this.closeModal();
  }
}
