
import { Injectable } from '@angular/core';
import {TextUtils} from 'src/app/utils/text.utils'
import { InsightFe } from '../model/insight/InsightFe';
import { KpiFe2 } from '../model/insight/KpiFe2';
import { ValueColumnFe } from '../model/pipeline/ValueColumnFe';
import { ValueRowFeNew } from '../model/pipeline/ValueRowFeNew';
import { RGB } from '../model/chart/RGB';
import { ChartTypeFe } from '../model/chart/ChartTypeFe';
import { Chart, ChartConfiguration, ChartData, ChartType, DefaultDataPoint, TooltipItem, TooltipModel, DoughnutControllerChartOptions, registerables, ChartDataset } from 'chart.js';
import {Context as DataLabelsContext} from 'chartjs-plugin-datalabels';
import { ChartSettingFeNew } from '../model/chart/ChartSettingFeNew';
import _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class ChartBuilderServiceFeNew {
 
  registered: any = Chart.register(...registerables);

  chartColors = ['#4E5BA6', '#6dc49b', '#F2E416', '#F2921D', '#985FF5', '#CEF2DB', '#19bfd3',
   '#3366cc','#dc3912','#ff9900','#109618','#990099','#0099c6','#dd4477','#66aa00','#b82e2e','#316395','#3366cc','#994499','#22aa99','#aaaa11','#6633cc','#e67300','#8b0707','#651067','#329262','#5574a6','#3b3eac','#b77322','#16d620','#b91383','#f4359e','#9c5935','#a9c413','#2a778d','#668d1c','#bea413','#0c5922','#743411']
  
   borderColors = this.chartColors.map(c => RGB.hexToRgb(c).rgba(1.0))
   backgroundColors = this.chartColors.map(c => RGB.hexToRgb(c).rgba(0.7))

  buildChart(insight: InsightFe, chartSetting: ChartSettingFeNew): ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    if (insight.isTimed() && chartSetting.principalComparisonTime) {
      var kpi: KpiFe2 = insight.kpis[chartSetting.timeScaleIndex]
      if ([ChartTypeFe.BAR, ChartTypeFe.LINE, ChartTypeFe.STACK].includes(chartSetting.chartType)) {
        return this.buildColumnBasedTimeChart(chartSetting, kpi, insight)
      } else if ([ChartTypeFe.DOUGHNUT, ChartTypeFe.PIE].includes(chartSetting.chartType)) {
        return this.buildRowBasedTimeChart(chartSetting, kpi, insight)
      }
    } else if (!insight.isTimed() && chartSetting.principalCategoryFieldIndex == -1) {
      var kpi: KpiFe2 = insight.kpis[0]
      if ([ChartTypeFe.BAR, ChartTypeFe.STACK].includes(chartSetting.chartType)) {
        return this.buildValueBar(chartSetting, kpi, insight)
      } else if ([ChartTypeFe.DOUGHNUT, ChartTypeFe.PIE].includes(chartSetting.chartType)) {
        return this.buildValuePie(chartSetting, kpi, insight)
      }
    } else if (chartSetting.principalCategoryFieldIndex > -1) {
      var kpi: KpiFe2
      if (insight.isTimed()) {
       kpi = insight.kpis[chartSetting.timeScaleIndex]
      } else {
        kpi = insight.kpis[0]
      }
      if ([ChartTypeFe.BAR, ChartTypeFe.LINE, ChartTypeFe.STACK].includes(chartSetting.chartType)) {
        return this.buildColumnBasedCategoryChart(chartSetting, kpi, insight)
      } else if ([ChartTypeFe.DOUGHNUT, ChartTypeFe.PIE].includes(chartSetting.chartType)) {
        return this.buildRowBasedCategoryChart(chartSetting, kpi, insight)
      }
    } else {
      throw Error('No chart implementation found for chart-type: ' + chartSetting.chartType.id)
    }
  }

  private buildColumnBasedTimeChart(chartSetting: any, kpi: KpiFe2, insight: InsightFe):
  ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let chartTitle = chartSetting.selectTitle(insight)
    let units = insight.valueFieldUnits()
    let orderedTimeLabels: string[] = kpi.uniqueTimeValuesOrdered()
    let valueProjections: ValueColumnFe[] = kpi.projectValuesFilteredCategoriesOrderedByTime(chartSetting, insight)

    let chartDatasets = valueProjections.map((valueProjection: ValueColumnFe, i) => {
      return {
        label: TextUtils.convert(valueProjection.valueLabel),
        data: valueProjection.valueVector,
        percentage: valueProjection.percentageVector, 
        unit: valueProjection.unitVector, 
        fill: true,
        borderWidth: 2,
        borderColor: this.borderColors[i],
        backgroundColor: this.backgroundColors[i],
        hoverBackgroundColor : this.backgroundColors[i],
        xAxisID: 'xAxis',
        yAxisID: 'yAxis'
      }
    })
    var chartData = this.chartDataTemplateWithScales(chartSetting, orderedTimeLabels, chartDatasets, chartTitle, units)
    return chartData
  }

  private buildValueBar(chartSetting: any, kpi: KpiFe2, insight: InsightFe):
  ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let chartTitle = chartSetting.selectTitle(insight)
    var vals = kpi.valuesOfFirstElementToArray(insight)
    var valueFiledLabels = insight.valueFieldLabels()
    var units = insight.valueFieldUnits()
    let datasets = [{
      data: vals, 
      fill: true, 
      borderWidth: 2,
      borderColor: this.borderColors.slice(0, vals.length),
      backgroundColor: this.backgroundColors.slice(0, vals.length), 
      hoverBackgroundColor : this.backgroundColors.slice(0, vals.length),
      xAxisID: 'xAxis',
      yAxisID: 'yAxis'
    }]
    var chartData = this.chartDataTemplateWithScales(chartSetting, valueFiledLabels, datasets, chartTitle, units)
    return chartData
  }

  private buildColumnBasedCategoryChart(chartSetting: any, kpi: KpiFe2, insight: InsightFe):
  ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let chartTitle = chartSetting.selectTitle(insight)
    let units = insight.valueFieldUnits()
    let categoryIndex = chartSetting.principalCategoryFieldIndex
    let orderedCategoryValues: string[] = chartSetting.options.categoryOptions[categoryIndex].values
    let valueProjections: ValueColumnFe[] = kpi.projectValuesFilteredCategoriesOrderedByCategory(chartSetting, insight)

    let chartDatasets = valueProjections.map((valueProjection: ValueColumnFe, i) => {
      return {
        label: TextUtils.convert(valueProjection.valueLabel),
        data: valueProjection.valueVector,
        percentage: valueProjection.percentageVector, 
        unit: valueProjection.unitVector, 
        fill: true,
        borderWidth: 2,
        borderColor: this.borderColors[i],
        backgroundColor: this.backgroundColors[i],
        hoverBackgroundColor : this.backgroundColors[i],
        xAxisID: 'xAxis',
        yAxisID: 'yAxis'
      }
    })
    var chartData = this.chartDataTemplateWithScales(chartSetting, orderedCategoryValues, chartDatasets, chartTitle, units)
    return chartData
  }

  private buildRowBasedTimeChart(chartSetting: ChartSettingFeNew, kpi: KpiFe2, insight: InsightFe): 
  ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let chartTitle = chartSetting.selectTitle(insight)
    let orderedTimeLabels = kpi.uniqueTimeValuesOrdered()
    orderedTimeLabels = orderedTimeLabels.filter(label => label != null)
    let valueRows: ValueRowFeNew[] = kpi.selectValuesFilterCategoriesOrderByTime(chartSetting, insight)
    let valLabels = insight.valueFieldLabels()
    let datasets = valueRows.map((valueRow, i) => {
      return {
        data: valueRow.valueVector, 
        label: TextUtils.convert(orderedTimeLabels[i]), 
        percentage: valueRow.percentageVector, 
        unit: new Array(orderedTimeLabels.length).fill(insight.definition.getUnit()),
        borderWidth: 2,
        borderColor: this.borderColors.slice(0, valLabels.length),
        backgroundColor: this.backgroundColors.slice(0, valLabels.length), 
        hoverBackgroundColor : this.backgroundColors.slice(0, valLabels.length),
      }
    })     
    
    var chartData = this.chartDataTemplate(chartSetting, valLabels, datasets, chartTitle)
    return chartData
  }

  private buildValuePie(chartSetting: ChartSettingFeNew, kpi: KpiFe2, insight: InsightFe): 
  ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let chartTitle = chartSetting.selectTitle(insight)
    var vals = kpi.valuesOfFirstElementToArray(insight)
    var labels = insight.valueFieldLabels()
    let datasets = [{
      data: vals,
      borderWidth: 2, 
      borderColor: this.borderColors.slice(0, vals.length),
      backgroundColor: this.backgroundColors.slice(0, vals.length), 
      hoverBackgroundColor : this.backgroundColors.slice(0, vals.length),
      xAxisID: 'xAxis',
      yAxisID: 'yAxis'
    }]
    var chartData = this.chartDataTemplate(chartSetting, labels, datasets, chartTitle)
    return chartData
  }
  
  private buildRowBasedCategoryChart(chartSetting: ChartSettingFeNew, kpi: KpiFe2, insight: InsightFe): 
  ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let chartTitle = chartSetting.selectTitle(insight)
    let categoryIndex = chartSetting.principalCategoryFieldIndex
    let orderedCategoryValues: string[] = chartSetting.options.categoryOptions[categoryIndex].values
    let valueRows: ValueRowFeNew[] = kpi.selectValuesFilterCategoriesOrderByCategory(chartSetting, insight)
    let valLabels = insight.valueFieldLabels()
    let datasets = valueRows.map((valueRow, i) => {
      return {
        data: valueRow.valueVector, 
        label: TextUtils.convert(orderedCategoryValues[i]), 
        percentage: valueRow.percentageVector, 
        unit: new Array(orderedCategoryValues.length).fill(insight.definition.getUnit()),
        borderWidth: 2,
        borderColor: this.borderColors.slice(0, valLabels.length),
        backgroundColor: this.backgroundColors.slice(0, valLabels.length), 
        hoverBackgroundColor : this.backgroundColors.slice(0, valLabels.length),
      }
    })     
    
    var chartData = this.chartDataTemplate(chartSetting, valLabels, datasets, chartTitle)
    return chartData
  }

  private chartDataTemplateWithScales(chartSetting: ChartSettingFeNew, lables: string[], datasets, title: string, unit: string):
    ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    var chartData = this.chartDataTemplate(chartSetting, lables, datasets, title)
    chartData.options.scales = {
      yAxis: {
        title: {
          display: true,
          text: TextUtils.convert(unit),
        },
        ticks: {
          maxTicksLimit: 10,
          callback: function (value: number, index, ticks) {
            if (Math.floor(value) === value) {
              return Intl.NumberFormat().format(value);
            }
          },
        },
        beginAtZero: true,
        stacked: chartSetting.chartType.stacked,
        reverse: false
      },
      xAxis: {
        type: 'category',
        ticks: {
          maxTicksLimit: 150
        },
        grid: {
          display: false
        },
        stacked: chartSetting.chartType.stacked,
      }

    }
    return chartData
  }

  private chartDataTemplate(chartSetting: ChartSettingFeNew, labels: string[], datasets: ChartDataset[], title: string):
    ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> {
    let convertedLabels = labels.map(l => TextUtils.convert(l))
    var chartData: ChartConfiguration<ChartType, DefaultDataPoint<ChartType>, string> = {
      type: chartSetting.chartType.chartjsKey as ChartType,
      data: {
        labels: convertedLabels,
        datasets: datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.5,
        scales: null,
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: function (value, context: DataLabelsContext) {
              return _.isString(value) ? Number.parseFloat(value).toFixed(2) : ''
            }
          },
          title: {
            display: (title) ? true : false,
            text: (title) ? TextUtils.convert(title) : '',
            font: {
              size: 15,
              style: 'normal'
            },
            padding: 5,
          },
          tooltip: {
            callbacks: {
              label: function (this: TooltipModel<ChartType>, tooltipItem: TooltipItem<ChartType>): string | void | string[] {
                let label = tooltipItem.dataset.label;
                let perc = tooltipItem.dataset;
                let value = tooltipItem.parsed.y ? tooltipItem.parsed.y : tooltipItem.parsed;
                let units = tooltipItem.dataset['unit'] //to be defined in the extended ChartDataset to not get type error
                let unit = units[0];
                let percentages = tooltipItem.dataset['percentage'] //to be defined in the extended ChartDataset to not get type error
                let valueStr = value?.toLocaleString(undefined, { minimumFractionDigits: 2 });
                if (label) {
                  if (percentages) {
                    let percentage = percentages[tooltipItem.dataIndex];
                    return `${label}: ${percentage}, ${valueStr} ${unit}`
                  } else {
                    return `${label}: ${valueStr} ${unit}`
                  }
                } else {
                  return valueStr
                }
              }
            }
          },
          legend: {
            display: true,
            position: chartSetting.legendPosition,
            labels: {
              boxWidth: 5,
              boxHeight: 5,
              padding: 5
            }
          }
        },
      }
    }
    return chartData;
  }
}