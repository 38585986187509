import { Calculation } from "src/app/components/data-categories/calculation-builder/model/CalculationBuilderModels";
import { MathStatementFe } from "../calculation/MathStatementFe";
import { StatementBuilderFe } from "../calculation/StatementBuilderFe";
import { ColumnSchemaFe } from "../schema/ColumnSchemaFe";
import { RowStatusFe } from "../schema/RowStatusFe";
import { AbstractAttributeFe } from "./AbstractAttributeFe";
import { UnitFe } from "src/app/components/unit-systems/model/UnitFe";
import { TaxonomyEmissionFactorFe } from "./TaxonomyEmissionFactorFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { DatapointProvidedBy } from "./DatapointProvidedByFe";

export class TaxonomyAttributeFe extends AbstractAttributeFe {
  public deployed: boolean = true;
  public calculation: string | MathStatementFe | undefined | null;

  public oldLabel: { [key: string]: string } = {};
  public oldDatatype: string;
  public labelMatch: boolean = true;
  public datatypeMatch: boolean = true;
  public modified: boolean = false;

  public isSelected = false;
  public distinctValues: any[];

  public isNew = false;
  public isEdited = false;
  public isRemoved = false;
  public providedBy: DatapointProvidedBy = DatapointProvidedBy["user"];
  public ordinal: number = 0;
  public calculations: Calculation[];
  public emissionFactors: TaxonomyEmissionFactorFe[];
  public emissionFactor: TaxonomyEmissionFactorFe; // not in use anymore for old examples
  public unit: UnitFe;
  public prevUnit: UnitFe;

  constructor(column: any, languageService: LanguageService) {
    super(column, languageService);
    this.deployed = column["deployed"];
    this.isNew = column["isNew"];
    this.isEdited = column["isEdited"];
    this.isRemoved = column["isRemoved"];
    this.unit = column["unit"];
    this.prevUnit = column["prevUnit"];
    this.providedBy = column["providedBy"] ?? DatapointProvidedBy["user"];
    this.ordinal = column["ordinal"] ?? 0;
    this.calculations = column["calculations"] ?? [];
    this.emissionFactors = column["emissionFactors"] ?? [];
    this.emissionFactor = column["emissionFactor"];
    if (column["calculation"] == null) {
      this.calculation = null;
    } else {
      this.calculation = StatementBuilderFe.build(column["calculation"]);
    }
  }

  public dbColName() {
    return this.key.toUpperCase().replace(/[^a-zA-Z0-9_]/gi, "_");
  }

  columnSchema(): ColumnSchemaFe {
    let schema = new ColumnSchemaFe();
    schema.label = this.getLabel();
    schema.name = this.dbColName();
    schema.type = this.datatype.replace("NUMERIC", "FLOAT");
    schema.isCalculated = this.providedBy == "calculation";
    schema.status = RowStatusFe.ADDED;
    schema.unitSymbol = this.unit?.symbol;
    schema.measurementKey = this.unit?.measurementType;
    schema.showUnitCol = true
    return schema;
  }

  diffDeployment(da: TaxonomyAttributeFe | undefined) {
    if (da) {
      this.deployed = true;
      if (this.label != da.label) {
        this.labelMatch = false;
        this.oldLabel = da.label;
      } else {
        this.labelMatch = true;
      }

      if (this.datatype != da.datatype) {
        this.datatypeMatch = false;
        this.oldDatatype = da.datatype;
      } else {
        this.datatypeMatch = true;
      }

      this.modified = this.labelMatch && this.datatypeMatch ? false : true;
    } else {
      this.deployed = false;
    }
  }

  getDatatypeText() {
    switch (this.datatype) {
      case "STRING":
        return "Text";
      case "NUMERIC":
        return "Number";
      case "DATE":
        return "Time";
      case "BOOLEAN":
        return "Yes/No";
    }
  }

  public getLabel(withUnit = true): string {
    const langCode = this.languageService.getDisplayActiveLanguage();
    const unit = this.unit && this.unit.symbol ? ` (${this.unit.symbol})` : '';
    const label = this.label[langCode] || this.label['en'];
    return withUnit ? `${label} ${unit}` : label;
  }

  public getDescription(): string {
    const langCode = this.languageService.getDisplayActiveLanguage();
    return this.description[langCode] || this.description['en'];
  }

  toJSON(): any {
    const { languageService, getLabel, getDescription, ...data } = this;
    return data;
  }
}
