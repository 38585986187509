import { CompanyFe } from "../data-suppliers/company/CompanyFe"

export class CustomerSuccess_UpdateFe {

  userId: string
  email: string
  removedCompanyIds: string[]
  newAssignedCompanyIds: CompanyFe[]
  
  constructor(
    userId: string,
    email: string,
    removedCompanyIds: string[],
    newAssignedCompanyIds: CompanyFe[]
  ) {
    this.userId = userId
    this.email = email
    this.removedCompanyIds = removedCompanyIds
    this.newAssignedCompanyIds = newAssignedCompanyIds
  }

}
