import { Component, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { DatahubService } from "./DatahubService"
import { Subject } from "rxjs"
import { AlertServiceFe } from "src/app/services/AlertServiceFe"
import { FileDataFe } from "src/app/model/file/FileDataFe"
import { FileTypeFe } from "src/app/model/file/FileTypeFe"
import { RecentlyOpenedItemTypeFe } from "src/app/model/datahub/RecentlyOpenedItemFe"



@Component({
  selector: "app-data-hub",
  templateUrl: "./data-hub.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class DataHubComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild("dataTable", { static: true }) dataTable: TemplateRef<any>
  @ViewChild("initStageTableModal", { static: true }) initStageTableModal: TemplateRef<any>
  @ViewChild('upload', { static: true }) uploadFileModal: TemplateRef<any>;
  componentNameForChild = 'Datahub';

  detailsData: any
  depTaxonomy: TaxonomyInfoFe
  initCacheInProgress: boolean
  menuCollapsed: boolean
  activeFormNo: number = 3
  url: string = ""
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  
  selectedRowIndex: number
  isCollapsed: boolean[] = []
  showDefinition: string = "Show"
  showDefinitionIcon: string = "la la-arrows"
  showMore: string = "Show more"
  showMore1: string = "Show more"
  showMore2: string = "Show more"
  loadingInfo: boolean = false
  loadingInProgress: boolean = false
  isActiveUser: any
  searchText: any
  isLoadingData: any
  
  public filesToUpload: any[] = [];
  public uploadProgress: number[] = [];
  public fileUploadMessages: string[] = [];
  public isDisabled: boolean = true;

  pageToolbar = [
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.back"),
        longLabel: this.locale("locale_key.general.toolbar.button.back"),
        tooltip: this.locale("locale_key.general.toolbar.button.back"),
        icon: "la la-arrow-left",
        actionName: "back",
        visible: () => !this.datahubService.isHomeView(),
        disabled: () => this.loadingInfo || this.loadingInProgress,
        additionalClasses: "nav-link",
      },
      {
        shortLabel: this.locale("locale_key.pages.datahub.toolbar.button.dh_home"),
        longLabel: this.locale("locale_key.pages.datahub.toolbar.button.dh_home"),
        tooltip: this.locale("locale_key.pages.datahub.toolbar.button.dh_home"),
        icon: "la la-table",
        actionName: "data_hub_view",
        visible: () => !this.datahubService.isHomeView() || !this.datahubService.isAllDataFilter(),
        disabled: () => this.loadingInfo || this.loadingInProgress,
      },
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "add_kpi",
        visible: () => this.activeFormNo == 2,
        disabled: false,
      },
    ],
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_result",
        visible: () => this.datahubService.isRgResultView(),
        disabled: false,
      },
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_master_table",
        visible: () => this.datahubService.isMasterTableView() && !this.datahubService.selectedEntity?.dataGridService?.downloading,
        disabled: false,
      },
      { 
        shortLabel: this.locale('locale_key.general.toolbar.button.data'), 
        longLabel: this.locale('locale_key.pages.direct_data_entries.tooltip.add_data_entry'), 
        tooltip: this.locale('locale_key.pages.direct_data_entries.tooltip.add_data_entry'), 
        icon: "la la-plus ", 
        actionName: "add_data_entry",  
        visible: () =>  this.datahubService.isDataEntriesPortalView(), 
        disabled: false 
      },
      { 
        shortLabel: this.locale('locale_key.general.toolbar.button.add'), 
        longLabel: this.locale('locale_key.general.buttons.add_to_master_table'), 
        tooltip: this.locale('locale_key.general.buttons.add_to_master_table'), 
        icon: "las la-link ", 
        actionName: "connect_to_pipeline", 
        visible: () => this.datahubService.isDataEntryView() && !this.datahubService.selectedDataEntry.isLoading() && this.datahubService.selectedDataEntry.dataGridService && !this.datahubService.selectedDataEntry.dataGridService?.modified && !this.datahubService.selectedDataEntry.transformed && this.datahubService.selectedDataEntry.tableData?.rows.length > 0,
        disabled: false
      },
      { 
        shortLabel: this.locale('locale_key.general.toolbar.button.remove'), 
        longLabel: this.locale('locale_key.general.buttons.remove_from_maser_table'), 
        tooltip: this.locale('locale_key.general.buttons.remove_from_maser_table'), 
        icon: "las la-unlink ", 
        actionName: "disconnect_from_pipeline", 
        visible: () => this.datahubService.isDataEntryView() && !this.datahubService.selectedDataEntry.isLoading() && this.datahubService.selectedDataEntry.dataGridService && !this.datahubService.selectedDataEntry.dataGridService?.modified && this.datahubService.selectedDataEntry.transformed && this.datahubService.selectedDataEntry.tableData?.rows.length > 0,
        disabled: false
      },
      { 
        shortLabel: this.locale('locale_key.general.toolbar.button.extract'), 
        longLabel: this.locale('locale_key.general.buttons.extract_from_file'), 
        tooltip: this.locale('locale_key.general.buttons.extract_from_file'), 
        icon: "la la-file-medical ", 
        actionName: "open_file_extractor", 
        visible: () => this.datahubService.isDataEntryView() && !this.datahubService.selectedDataEntry.isLoading(),
        disabled: () => this.datahubService.isDataEntryView() && this.datahubService.selectedDataEntry.dataGridService.modified
      },
      { 
        shortLabel: this.locale('locale_key.general.toolbar.button.save'), 
        longLabel: this.locale('locale_key.general.buttons.save_changes'), 
        tooltip: this.locale('locale_key.general.buttons.save_changes'), 
        icon: "la la-save ", 
        actionName: "save_changes", 
        visible: () => this.datahubService.isDataEntryView() && !this.datahubService.selectedDataEntry.isLoading(),
        disabled: () => this.datahubService.isDataEntryView() && !(this.datahubService.selectedDataEntry.dataGridService.modified && this.datahubService.selectedDataEntry.dataGridService.isDataValid)
      },
      { 
        shortLabel: this.locale('locale_key.general.buttons.export'), 
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.export_data_source'), 
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.export_data_source'), 
        icon: "la la-file-export ", 
        actionName: "export_data_entry", 
        visible: () => this.datahubService.isDataEntryView() && !this.datahubService.selectedDataEntry.isLoading(), 
        disabled: () => this.datahubService.isDataEntryView() && this.datahubService.selectedDataEntry.dataGridService.modified
      },
      {
        shortLabel: this.locale('locale_key.pages.file_explorer.toolbar.upload'),
        longLabel: this.locale('locale_key.pages.file_explorer.toolbar.upload'),
        tooltip: this.locale('locale_key.pages.file_explorer.toolbar.upload'),
        icon: "la la-upload ",
        actionName: "file_upload",
        visible: () => this.datahubService.isFilesPortalView(),
        disabled: false
      },
      { 
        shortLabel: this.locale('locale_key.general.buttons.export'), 
        longLabel: this.locale('locale_key.general.toolbar.button.export'), 
        tooltip: this.locale('locale_key.general.toolbar.button.export'), 
        icon: "la la-download ", 
        actionName: "export_file", 
        visible: () => this.datahubService.isFilesView() && !this.datahubService.selectedFile.loadingFileInProgress, 
        disabled: false 
      }
    ]
    ,
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.info"),
        longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
        tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
        icon: "la la-info ",
        actionName: "toggle_quick_tips",
        visible: () => true,
      },
    ],
  ]

  userOptions = [
    { label: this.locale("locale_key.pages.data_hub.master_table.data_categories.title"), value: "DATA_CATEGORIES" },
    { label: this.locale("locale_key.pages.data_hub.master_table.sub_categories.title"), value: "SUB_CATEGORIES" },
  ]

  constructor(languageService: LanguageService, private responsive: ResponsiveService, private stateService: StateServiceFe, 
    public datahubService: DatahubService, private alertService: AlertServiceFe,
    private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService) {
    super(languageService)
    
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.screenSize = this.responsive.currentScreenWidthSize
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize;

  }
  
  async ngOnInit() {
    if (!this.datahubService.activeView) {
      this.datahubService.setView('homeView')
    }
    this.loadingInProgress = true;
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
    this.depTaxonomy = depTaxonomy
    await this.setRecentlyOpenedItems();
    this.loadingInProgress = false;
  }
  
  async setRecentlyOpenedItems(){
    let systems = await this.stateService.getAutomatedFeedSystems();
    let feeds = [];
    systems.forEach((system) => feeds.push(...system.automatedFeeds));
    let files = await this.stateService.getAllFiles();
    let rgs = await this.stateService.getRequestGroups();
    let directEntries = await this.stateService.getStageTableInfos();
    let items = this.stateService.activeWorkspace.recentlyOpenedItems
    items.forEach( item => {
      if (!item.item) {
        switch (item.type) {
          case RecentlyOpenedItemTypeFe.REQUEST: item.item = rgs.find(rg => rg.id == item.id); break;
          case RecentlyOpenedItemTypeFe.FILE: item.item = files.find(file => file.id == item.id); break;
          case RecentlyOpenedItemTypeFe.DIRECT_ENTRY: item.item = directEntries.find(entry => entry.id == item.id); break;
          case RecentlyOpenedItemTypeFe.AUTOMATED_FEED: item.item = feeds.find(feed => feed.id == item.id); break;
          case RecentlyOpenedItemTypeFe.MASTER_TABLE: item.item = this.depTaxonomy.entityByKey(item.id); break;
        }
      }
    })
    this.stateService.activeWorkspace.recentlyOpenedItems = items.filter(item => item.item)
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "back":
        this.datahubService.goToPrevious()
        break;
      case "data_hub_view":
        this.datahubService.setAsDataHubView()
        break
      case "export_result":
        this.datahubService.exportRgResult.next(true);
        break  
      case "export_master_table":
        this.datahubService.exportMasterTable.next(true);
        break  
      case "add_data_entry":
        this.openModal(this.initStageTableModal, "modal-md")
        break  
      case "connect_to_pipeline":
        this.datahubService.connecToPipeline.next(true)
        break
      case "disconnect_from_pipeline":
        this.datahubService.disconnectFromPipeline.next(true)
        break
      case "open_file_extractor":
        this.datahubService.openFileExtractor.next(true)
        break
      case "save_changes":
        this.datahubService.selectedDataEntry.dataGridService.saveChages()
        break
      case "export_data_entry":
        this.datahubService.exportDirectEntry.next(true)
        break
      case "file_upload":
        this.uploadPopup(this.uploadFileModal, 'modal-md');
        break;
      case "export_file":
        this.datahubService.exportFile.next(true)
        break;
      case "toggle_quick_tips":
        this.toggleTips()
        break
    }
  }

  isLoaderVisibleInToolbar() {
    if (this.datahubService.isDataEntryView()) {
      return this.datahubService.selectedDataEntry.isLoading()
    } else if (this.datahubService.isMasterTableView()) {
      return this.datahubService.selectedEntity?.dataGridService?.downloading
    }
    return false;
  }

  search($event: any) {
    throw new Error("Method not implemented.")
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = "modal-xl") {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: className,
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }
 
  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove("modal-open")
  }

  taxonomyName(): string {
    return this.datahubService.getSelectdTaxonomyName(this.depTaxonomy)
  }
  
  public uploadPopup(template: TemplateRef<any> | string, size: string): void {
    this.filesToUpload = [];
    this.uploadProgress = [];
    this.fileUploadMessages = [];
      this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'gray modal-md mt-3 pt-3' }));
  }

  
  public selectFilesForUpload(event): void {
    Array.from(event.target.files).forEach(file => {
      this.filesToUpload.push(file);
      this.uploadProgress.push(0)
    });
    (document.getElementById('uploadCaptureInputFile') as HTMLInputElement).value = '';
    this.enableButton();
  } 

  public deleteFileFromUpload(index: number): void {
    this.filesToUpload.splice(index, 1);
  }

  public async uploadFiles() {
    for (let index = 0; index < this.filesToUpload.length; index++) {
      const file = this.filesToUpload[index];
      const formData = new FormData();

      formData.append('files', file);

      let newFile = FileDataFe.fromBlob(file.name);
      newFile.type = FileTypeFe.fromName(newFile.label);
      newFile.timeCreated = new Date();

      formData.append('metadata', JSON.stringify({ label: newFile.label }));

      await this.upload(formData, newFile, index);
    }

    setTimeout(() => {
      this.modalRef.hide();
      document.body.classList.remove('modal-open');
    }, 500);
  }

  public async upload(formData, newFile, index) {
    this.uploadProgress[index] = 30;
    try {
      let data = await this.stateService.uploadExplorerFile(formData, newFile)
      this.uploadProgress[index] += data.progress
      this.uploadProgress[index] += 20  
      this.fileUploadMessages[index] = data.info
    } catch (error) {
      this.alertService.showError(`File ${newFile.name} could not be uploaded!`)
    }   
   }

  public formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed()) + ' ' + sizes[i];
  }

  public getItemString(item: any) {
    return (typeof item === 'object') ? item.getLabel() : item
  }

  public enableButton() {
    if (this.filesToUpload.length > 0) {
      this.isDisabled = false
    }
    else {
      this.isDisabled = true;
    }
  }

  handleExportType(actionName: string, exportType: string) {
    this.datahubService.setExportFileType(exportType)
    this.handleToolbarAction(actionName)
  }
}
