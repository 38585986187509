import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import _ from "lodash";
import {
  CalculationCondition,
  CalculationCriterion,
  Comparator,
} from "../../model/CalculationBuilderModels";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import * as UUID from "uuid";
import { DatapointDatatype } from "src/app/model/taxonomy/DatapointDatatypeFe";

@Component({
  selector: "condition-container",
  templateUrl: "./condition-container.component.html",
  styleUrls: ["./condition-container.component.scss"],
})
export class ConditionContainerComponent extends AbstractLanguageComponent implements OnInit {
  @Input() isRoot: boolean = false;
  @Input() showEditMode: boolean = true;
  @Input() level3DataCategory: EntityFe;
  @Input() selectedDatapoint: TaxonomyAttributeFe = null;
  @Input() parentCondition: CalculationCondition;
  @Input() conditionIdx;
  @Input() condition: CalculationCondition;
  @Input() selectedDataCategory;
  componentID;
  taxonomies: {
    depTaxonomy: TaxonomyInfoFe;
    newTaxonomy: TaxonomyInfoFe;
  };

  constructor(private stateService: StateServiceFe, public languageService: LanguageService) {
    super(languageService)
  }

  async ngOnInit() {
    this.componentID = UUID.v4();
    if (!this.parentCondition) this.isRoot = true;
    this.taxonomies = await this.stateService.getTaxonomyInfos();
  }

  get conditionComparator() {
    return this.condition.comparator.toUpperCase();
  }

  addCriterion(opts: { comparator: Comparator; index }) {
    this.markSelectedDatapointEdited()

    if (this.condition.criteria.length == 1) {
      const criterion = new CalculationCriterion();
      this.condition.criteria.push(criterion);
      this.condition.comparator = opts.comparator;
      return;
    }

    if (this.condition.criteria.length == 2) {
      const currentCriterion = _.cloneDeep(this.condition.criteria[opts.index]);
      const secondCriterion = new CalculationCriterion();
      const newCondition = new CalculationCondition({
        comparator: opts.comparator,
        criteria: [currentCriterion, secondCriterion],
      });
      this.condition.criteria[opts.index] = newCondition;
    }
  }

  removeCriterion({ index }) {
    if (this.isRoot) {
      this.condition.criteria.splice(index, 1);
      this.markSelectedDatapointEdited()
      return;
    }

    if (!this.isRoot) {
      this.markSelectedDatapointEdited()
      const replacementIndex = index == 0 ? 1 : 0;
      const replacementCriterion = _.cloneDeep(
        this.condition.criteria[replacementIndex]
      );
      this.parentCondition.criteria[this.conditionIdx] = replacementCriterion;
      return;
    }
  }

  getOtherDatapoints() {
    const includeDatatypes = [ DatapointDatatype.NUMERIC, DatapointDatatype.EMISSION_FACTOR, DatapointDatatype.STRING, DatapointDatatype.DATE ]
    const otherDatapoints = this.level3DataCategory.columns.filter(
      (column) => column.key != this.selectedDatapoint?.key && includeDatatypes.includes(DatapointDatatype[column.datatype])
    );
    return otherDatapoints;
  }

  datapointCompareWith(
    d1: TaxonomyAttributeFe,
    d2: TaxonomyAttributeFe
  ): boolean {
    return d1 && d2 ? d1.key === d2.key : d1 === d2;
  }

  markSelectedDatapointEdited() {
    if (this.selectedDatapoint) {
      this.selectedDatapoint.isEdited = true;
    }
  }

  resolveLabel({ token, deployed }) {

    let datapoint = token.datapoint;

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column;
            }
          });
        }
      });
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column;
            }
          });
        }
      });
    }

    let result = "";

    if (datapoint && datapoint.label && datapoint.label.hasOwnProperty(this.languageService.getDisplayActiveLanguage())) {
      result = datapoint.label[this.languageService.getDisplayActiveLanguage()];
    }

    if (result) {
      return result;
    }

    return datapoint?.label[this.activeLanguage.code] ||  datapoint?.label['en'];
  }

  getConditionLocale(condition: string) {
    const key = condition.replaceAll(' ', '_').toLowerCase()
    return this.locale('locale_key.pages.kpi_library.kpi_wizard.define_kpi.customise_data.option.' + key) || condition
  }

  toggleCriterionDropdown({ criterionIdx, componentID, evt }){    
    const criterionControl = evt.currentTarget;
    const criterionDropdown = criterionControl.nextElementSibling;

    const criterionControlIsActive = criterionControl?.classList.contains("active");
    
    if(criterionControlIsActive){
      criterionControl?.classList.remove("active");
      criterionDropdown?.classList.remove("active");
    }else{
      criterionControl?.classList.add("active");
      criterionDropdown?.classList.add("active");
    }
  }
}
