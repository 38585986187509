export class Admin_AddFe {

  firstName: string
  lastName: string
  email: string
  affiliationRole: string

  constructor(
    firstName: string,
    lastName: string,
    email: string,
    affiliationRole: string
  ) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.affiliationRole = affiliationRole
  }

}
