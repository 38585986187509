import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'csrd-dr-collected-data',
  templateUrl: './dr-collected-data.component.html',
  styleUrls: ['./dr-collected-data.component.scss']
})
export class DrCollectedDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
