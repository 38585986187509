import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AbstractActivityFe } from "../model/data-suppliers/timeline/AbstractActivityFe";
import { AttachmentFe } from "../model/details/AttachmentFe";
import { ChartSettingSubjectFe } from "../model/subject/ChartSettingSubjectFe";
import { RequestGroupFe } from "../model/data-suppliers/request/RequestGroupFe";
import { Router } from "@angular/router";
import { RoutesFe } from "../route/RoutesFe";
import { AbstractEmissionFactorFe } from "../model/emissions/AbstractEmissionFactorFe";

@Injectable({
  providedIn: "root",
})
export class DisplayServiceFe {
  public openTipsSubject: Subject<boolean> = new Subject<boolean>();
  public openAttachmentSubject: Subject<AttachmentFe> = new Subject<AttachmentFe>();
  public closeAttachmentSubject: Subject<boolean> = new Subject<boolean>();
  public quickTipsData: any;
  public quickTipsDataUpdatedSubject: Subject<boolean> = new Subject<boolean>();
  public detailsData: any;
  public detailsDataUpdatedSubject: Subject<boolean> = new Subject<boolean>();
  public dataFromDetails: any;
  public dataFromDetailsSubject: Subject<any> = new Subject<boolean>();
  public chartSettingsChanged: Subject<ChartSettingSubjectFe> = new Subject<ChartSettingSubjectFe>();
  public dataFromHomePage: AbstractActivityFe | null
  public selectedRequestGroup: RequestGroupFe
  public closeDetailsSubject: Subject<boolean> = new Subject<boolean>();
  public isConnectedActionsTabOpenSubject: Subject<boolean> = new Subject<boolean>();

  public openTab: 'EF_INFO' | 'DATABASE_INFO' | 'DETAILS' | 'QUICK_TIPS' | 'CONNECTED_ACTIONS' | 'CSRD_INFO' | null = null;
  public isEFDetailsTabOpenSubject: Subject<AbstractEmissionFactorFe | null> = new Subject();
  public isCSRDInfoTabOpenSubject: Subject<any | null> = new Subject();

  constructor(private router: Router){}

  updateDetailsData(data: any) {
    this.detailsData = data;
    if (data) {
      this.detailsDataUpdatedSubject.next(true);
    } else {
      this.detailsDataUpdatedSubject.next(false);
    }
  }

  updateQuickTipsData(data: any) {
    this.quickTipsData = data;
    if (data) {
      this.quickTipsDataUpdatedSubject.next(true);
    } else {
      this.quickTipsDataUpdatedSubject.next(false);
    }
  }

  toggleTips() {
    if (this.openTab == 'QUICK_TIPS') {
      this.closeTips()
    } else {
      this.openTips()
    }
  }

  updateDataFromDetails(data: any) {
    this.dataFromDetails = data;
    if (data) {
      this.dataFromDetailsSubject.next(true);
    } else {
      this.dataFromDetailsSubject.next(false);
    }
  }

  openTips() {
    this.openTipsSubject.next(true);
  }

  closeTips() {
    this.openTipsSubject.next(false);
  }

  openConnectedActionsTab() {
    this.isConnectedActionsTabOpenSubject.next(true);
  }

  closeConnectedActionsTab() {
    this.isConnectedActionsTabOpenSubject.next(false);
  }

  openEfDetailsTab(ef: AbstractEmissionFactorFe) {
    this.isEFDetailsTabOpenSubject.next(ef);
  }

  openAttachment(attachment: AttachmentFe) {
    this.openAttachmentSubject.next(attachment);
  }

  closeAttachments(){
    this.closeAttachmentSubject.next(true);
  }

  setRequestFromHome (activity: AbstractActivityFe) {
    this.dataFromHomePage = activity;
  }

  viewRequestGroup(rg: RequestGroupFe) {
    this.selectedRequestGroup = rg
    this.router.navigate([RoutesFe.REQUESTS.fullPath()]);
  }

  closeDetails () {
    this.closeDetailsSubject.next(true);
  }

  isQuickTipsOpen() {
    return this.openTab == "QUICK_TIPS"
  }
  
  isDetailsTabOpen() {
    return this.openTab == "DETAILS"
  }
  
  isConnectedActionsTabOpen() {
    return this.openTab == "CONNECTED_ACTIONS"
  }

  isEFTabOpen() {
    return this.openTab == "EF_INFO"
  }

  isDatabaseTabOpen() {
    return this.openTab == "DATABASE_INFO"
  }

  isCSRDInfoTabOpen() {
    return this.openTab == "CSRD_INFO"
  }

  openCSRDInfoTab(datapoint: any) {
    this.isCSRDInfoTabOpenSubject.next(datapoint);
  }

}
