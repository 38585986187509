import { Component, OnInit} from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { Subscription } from 'rxjs'
import { Language } from 'src/app/utils/language/Language'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input-gg'
import { EmailTemplateFe } from 'src/app/model/email/EmailTemplateFe'
import { EmailResponseFe } from 'src/app/model/email/EmailResponseFe'
import { EmailCategoryFe } from 'src/app/model/email/EmailCategoryFe'
import emailKeysFe from '../../../utils/language/locale.email.keysfe.json'

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss', '../user-settings/user-settings.component.scss'],
})
export class CompanySettingsComponent extends AbstractLanguageComponent implements OnInit {
  currentPass = new FormControl(null, [Validators.required])
  isTemplateOpen = false
  isSettingsOpen = false
  inProgress = false
  passChange = false
  flagClass: string = 'flag-icon-se'
  languages = this.languageService.availableLanguages
  private languageSubscription: Subscription
  private emailSubscription: Subscription
  activeLanguage: Language
  lang: string
  emailLang: string
  showDetailedView = false
  selectedAllLang: Language
  oldAllLang: Language
  selectedShowAllLang: Language
  selectedCategory: EmailCategoryFe
  emailResponseFe: EmailResponseFe
  showModal = false
  allOption: EmailCategoryFe 
  selectedTemplate: EmailTemplateFe
  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode
  searchQuery: string = ''
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  filteredCategories: any[]
  newLang: boolean = false
  loadingInProgress: boolean
  extraCategories: EmailCategoryFe[] = [new EmailCategoryFe('to_data_reporters', []), new EmailCategoryFe('to_sustainability_managers', []), new EmailCategoryFe('customized', [])]

  isSaveButtonDisabled: boolean = true

  constructor(languageService: LanguageService, private stateService: StateServiceFe, private displayService: DisplayServiceFe, private responsive: ResponsiveService) {
    super(languageService)

    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize

    this.allOption = new EmailCategoryFe('All types', [])
  }

  async ngOnInit(): Promise<void> {
    this.loadingInProgress = true
    this.emailResponseFe = await this.getAllEmailTemplates()
    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
      if (this.emailResponseFe) {
        this.updateCategoriesLocales()
      }
    })

    this.emailSubscription = this.languageService.emailLanguageSubject.subscribe((language: Language) => {
      this.selectedAllLang = language
      this.selectedShowAllLang = language
      this.emailLang = language.toString()
    })

    this.oldAllLang = this.selectedShowAllLang

    const langCode = await this.getEmailLanguage()
    this.languageService.changeCompanyEmailLanguage(langCode)

    this.selectShowAllLanguage(this.selectedAllLang)
    this.loadingInProgress = false
  }

  async getEmailLanguage() {
    try {
      let langCode = await this.stateService.getCompanyEmailLanguage()
      return langCode || 'en'
    } catch (err) {}
  }

  async changeAllEmailLanguage() {
    this.inProgress = true
    try {
      await this.stateService.changeAllEmailLanguage(this.selectedAllLang.code)
      this.languageService.changeCompanyEmailLanguage(this.selectedAllLang.code)
      this.selectShowAllLanguage(this.selectedAllLang)
      this.oldAllLang = this.selectedAllLang
      this.isSaveButtonDisabled = true
    } catch (err) {}
    this.inProgress = false
  }

  getSendTo(template: EmailTemplateFe) {
    const key = `locale.email.${template.fileName}.sendto`
    return this.locale(key)
  }

  getUserFriendly(template: EmailTemplateFe) {
    const userFKey = `locale.email.${template.fileName}.user.friendly`
    return this.locale(userFKey)
  }

  async getAllEmailTemplates(): Promise<EmailResponseFe> {
    try {
      return await this.stateService.getAllEmailTemplates()
    } catch (err) {}
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  filterTemplates(event: Event): void {
    if (!event || !this.searchQuery) {
      this.filteredCategories = this.getAllCategories(true)  
    } else {
      const searchLowerCase = this.searchQuery.toLowerCase();
      this.filteredCategories = this.getCategories()
        .map((cat) => ({
          ...cat,
          emails: cat.emails.filter(t => this.matchesSearchQuery(t, searchLowerCase))
        }))
        .filter(cat => cat.emails.length > 0);
    }
  }

  matchesSearchQuery(email: any, searchQuery: string): boolean {
    const key = `locale.email.${email.fileName}.sendto`;
    const sendTo = this.locale(key) || '';

    const userFKey = `locale.email.${email.fileName}.fileName.user.friendly`;
    const userFriendly = this.locale(userFKey) || '';

    return (sendTo && sendTo.toLowerCase().includes(searchQuery)) ||
      (userFriendly && userFriendly.toLowerCase().includes(searchQuery));
  }

  handleBackspace(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      event.preventDefault()

      if (this.searchQuery.length > 1) {
        this.searchQuery = this.searchQuery.slice(0, -1)
      } else if (this.searchQuery.length === 1) {
        this.searchQuery = ''
        this.filterTemplates(null)
      }
    }
  }

  resetFilter(): void {
    this.searchQuery = ''
    this.filteredCategories = this.getAllCategories(false)
  }

  getActiveLanguageLabel(): string {
    const language = this.languageService.availableLanguages.find((lang) => lang.code === this.lang)
    return language ? language.label : 'English (Default)'
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  async updateCategoriesLocales() {
    if (this.selectedCategory && this.selectedCategory.category) {
      this.selectedCategory.category = this.getCategoryLocaleByKey(this.selectedCategory.key)
    }

    this.allOption.category = this.getCategoryLocaleByKey(this.allOption.key)

    if (this.filteredCategories) {
      this.filteredCategories.forEach((fc) => (fc.category = this.getCategoryLocaleByKey(fc.key)))
    }

    this.getAllCategories(false).forEach((c) => {
      c.category = this.getCategoryLocaleByKey(c.key)
    })
  }

  getAllCategories(skipExtraCategories: boolean): EmailCategoryFe[] {
    if (skipExtraCategories) {
      return this.emailResponseFe?.categories || [];
    }

    return this.emailResponseFe?.categories
      ? [...this.emailResponseFe.categories, ...this.extraCategories]
      : [];
  }

  getCategories(): any[] {
    if (!this.selectedCategory || !this.extraCategories.some(cat => this.getCategoryLocaleByKey(cat.key) === this.selectedCategory.category)) {
      if (this.selectedCategory && this.selectedCategory.category !== this.allOption.category) {
        return [this.selectedCategory];
      }

      return this.filteredCategories || this.getAllCategories(true);
    }

    const filter = this.getFilter();
    return this.getAllCategories(false)
      .map((cat) => ({
        ...cat,
        emails: cat.emails.filter(e => this.matchesFilter(e, filter))
      }))
      .filter(cat => cat.emails.length > 0);
  }

  matchesFilter(email: EmailTemplateFe, filter: string): boolean {
    const key = `locale.email.${email.fileName}.sendto`;
    const sendTo = this.locale(key);

    const userFKey = `locale.email.${email.fileName}.user.friendly`;
    const userFriendly = this.locale(userFKey) || '';

    if (filter === 'customized') {
      return email.companyCustomEmailTemplates?.length > 0;
    }

    if (filter == 'data reporter') {
      return sendTo && sendTo.toLowerCase().includes(filter) || userFriendly && userFriendly.toLowerCase().includes('external');
    }

    return sendTo && sendTo.toLowerCase().includes(filter);
  }

  categoryEmails(index: number): EmailTemplateFe[] {
    return this.emailResponseFe.categories[index].emails
  }

  selectTemplate(template: EmailTemplateFe) {
    this.selectedTemplate = template
    this.showDetailedView = true
  }

  closeDetailedView() {
    this.showDetailedView = false
  }

  getCategoryLocaleByKey(key: string) {
    return this.locale(`locale.email.${key}`)
  }

  selectCategory(category: EmailCategoryFe) {
    this.selectedCategory = category
  }

  selectLanguage(emailTemplate: EmailTemplateFe, lang: Language) {
    emailTemplate.transitLanguage = lang
  }

  selectShowAllLanguage(lang: Language) {
    this.selectedShowAllLang = lang
    this.emailResponseFe.categories.forEach((c) => {
      c.emails.forEach((e) => (e.transitLanguage = lang))
    })
  }

  getLanguage(emailTemplate: EmailTemplateFe): Language {
    return emailTemplate?.transitLanguage || this.selectedAllLang
  }

  openModal(): void {
    this.showModal = true
  }

  async resetAllTemplates(): Promise<void> {
    this.inProgress = true
    await this.stateService.resetAllTemplates()
    this.emailResponseFe.categories.forEach((cat) => {
      cat.emails.forEach((e) => (e.companyCustomEmailTemplates = []))
    })
    this.inProgress = false
    this.onCancel()
  }

  onCancel() {
    this.showModal = false
  }

  changeCompanyLanguage(language: Language) {
    if (!this.oldAllLang) {
      this.oldAllLang = this.selectedAllLang
    }

    if (this.oldAllLang.code != language.code) {
      this.isSaveButtonDisabled = false
    } else {
      this.isSaveButtonDisabled = true
    }
    this.selectedAllLang = language
  }


  getFilter(): string {
    if (this.selectedCategory.category === this.getCategoryLocaleByKey('to_data_reporters')) {
      return 'data reporter';
    } else if (this.selectedCategory.category === this.getCategoryLocaleByKey('to_sustainability_managers')) {
      return 'sustainability manager';
    } else if (this.selectedCategory.category === this.getCategoryLocaleByKey('customized')) {
      return 'customized';
    }
  }

  getAllCategoriesLocale() {
    const keys = emailKeysFe.find((k) => k.name == 'categories')
    let result = []
    keys.keys.forEach((k) => {
      result.push(this.locale(k))
    })
    return result
  }

  resolveEmailLocales(html: string, template: string) {
    const keys = emailKeysFe.find((l) => l.name == template).keys || []
    keys.forEach((key) => {
        html = html.replaceAll(key, this.locale(key) || '')
    })

    return html
  }

  isTemplateEdited(emailTemplate: EmailTemplateFe) {
    if (emailTemplate.companyCustomEmailTemplates) {
      return !!emailTemplate.companyCustomEmailTemplates.some(b => b.langCode === emailTemplate.transitLanguage.code)
    }
    return false
  }
}
