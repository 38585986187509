import { Component } from '@angular/core';
import { NgFlowchartStepComponent } from '../ng-flowchart-step/ng-flowchart-step.component';
import { NgFlowchart } from '../model/flow.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditNodeModalComponent } from '../modal/editNode/editNode.modal';
import { DeleteNodeModalComponent } from '../modal/deleteNode/deleteNode.modal';
import { FlowChartOrganizationEntity } from '../model/FlowChartOrganizationEntity';
import { ShareDataService } from '../services/shareData.service';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { LanguageService } from 'src/app/services/LanguageServiceFe';

@Component({
  selector: 'app-custom-step',
  templateUrl: './custom-step.component.html',
  styleUrls: ['./custom-step.component.scss']
})
export class CustomStepComponent extends NgFlowchartStepComponent  {

  routes: FlowChartOrganizationEntity[] = []; //contains children Info
  optionalLabels:string[] = [];
  
  constructor(private modalRef: BsModalRef,
              private modalService: BsModalService, 
              shareDataService: ShareDataService, private languageService: LanguageService){
            super(shareDataService);
  }

  canDrop(dropEvent: NgFlowchart.DropTarget): boolean {
    return true;
  }

  canDeleteStep(): boolean {
    return true;
  }

  getDropPositionsForStep(pendingStep: NgFlowchart.PendingStep): NgFlowchart.DropPosition[] {
    return ['ABOVE', 'LEFT', 'RIGHT']
  }

  addNewNode() {
    this.modalRef = this.modalService.show(EditNodeModalComponent, {initialState:{mode:"add", parentNode: this.data}, class: 'ngDraggable modal-lg'});

    this.modalRef.content.value$.subscribe(async(node:FlowChartOrganizationEntity)=>{ 
      let index = this.routes.push(node);
      node.ordinal = index;
      node.deployed = false;
      node.modified = true;
      //create child in chart
      await this.addChild({
        template: CustomStepComponent,
        type: 'router',
        data: node
      },{
        sibling: true
      });
      
      this.shareDataService.updateOrgInfo(node);
      this.shareDataService.chartUpdated$.next(null);
      this.shareDataService.orgChartService.markChartAsModified();
    })
  }

  editDetails(){
    this.modalRef = this.modalService.show(EditNodeModalComponent , {initialState: { mode : "edit", node: {...this.data} }, class: 'ngDraggable modal-lg'});
    this.modalRef.content.value$.subscribe((node:FlowChartOrganizationEntity)=>{
      //update details of node
      this.data.label = node.label;
      this.data.dataSchema = node.dataSchema;
      this.data.modified = true;
      this.shareDataService.updateOrgInfo(this.data);
      this.shareDataService.chartUpdated$.next(null);
      this.shareDataService.orgChartService.markChartAsModified();
    })
  }

  sendDetails(){
    this.shareDataService.orgChartService.openOrgTable(this.data);
  }

  async deleteNode() {
    //to check if there is similar nodes anywhere in chart except this one
    const similarNodeExists = this.shareDataService.canvasFlow.steps.filter((step)=>step.data.key==this.data.key).length>1;
    const hasChildren = this.hasChildren(); //to check if it has children
    
    //recursively delete
    this.modalRef = this.modalService.show(DeleteNodeModalComponent, {initialState: {node: this.data , similarNodeExists , hasChildren}});;
    
    this.modalRef.content.delete$.subscribe((method:string)=>{
      let flow = [...this.shareDataService.newOrgFlow.entities];
      if(method=="1"){
        this.destroy(true);
      }else if(method=="2"){
        this.shareDataService.canvasFlow.steps.filter((step: NgFlowchartStepComponent)=>step.data.key==this.data.key)
        .forEach(x=>x.destroy(true));
      }else if(method=="3"){
        this.destroy(false);

        // //remove this unit
        // const index  = flow.findIndex(x=>x.key==this.data.key);
        // if(index!=-1){
        //   //remove its parent only if it has multiple parents
        //   if(flow[index].parents.length>1){
        //     flow[index].parents = flow[index].parents.filter(parent=>parent.key!=this.parent?.data.key);
        //   }else{
        //   //else remove this node itself    
        //     flow.splice(index,1);
        //   }
        // }


        // const childrenKeys = this.children.map(x=>x.data.key);
        
        // //remove unit if its someone's parent
        // flow.forEach((x)=>{        
        //   if(x.parents.length>0 && childrenKeys.includes(x.key)){
        //     const parents:FlowChartOrganizationEntity[]=[];
        //     for(let Xparent of x.parents){
        //       //Xparent's parent
        //       const y=this.deleteParent(Xparent);
        //       if(y){
        //         parents.push(y);
        //       }
        //     };
        //     x.parents=parents;
        //   }
        // })
        // this.shareDataService.refreshChart$.next(flow);
      }
      this.shareDataService.chartUpdated$.next(null);
      this.shareDataService.orgChartService.markChartAsModified();
      
    })
  }

  deleteParent(parent:FlowChartOrganizationEntity){
    if(parent.key!=this.data.key){
      const isParentSameAsNode = parent.parents.findIndex(parent=>parent.key==this.data.key);
      if(isParentSameAsNode==-1){
        if(parent.parents.length>0){
          const parents:FlowChartOrganizationEntity[]=[];
          parent.parents.forEach((y)=>{
            const z=this.deleteParent(parent);
            if(z){
              parents.push(y);
            }
          });
          parent.parents=parents;
          return;
        }else{
          return parent;
        }
      }else{
        parent.parents.splice(isParentSameAsNode,1);
        return parent;
      }
    }
    return false;
  }

  public locale(str: string, kv: any =  undefined):string{
    return this.languageService.getLocale(str, kv);
  }
  
}
