<div class=" m-0">
  <div class=" modal-header" style="border:none;">
    <div class="icon-background d-inline" style="height:18px;width:18px; background-color:#c65c5c;border-radius: 50%;">
      <i class="las la-times text-white p-0 " style="font-size: 1rem; background-color:transparent;border-radius: 50%;position: relative;left:2px;top:-3px"></i>
    </div>
    <h4 class="modal-title pb-0 ms-2 mb-0"> {{locale('locale_key.delete_confirmation_modal.header')}} </h4>
    <button type="button" class="btn ms-auto close">
       <span aria-hidden="false" class="list-inline" ><a (click)='closeModal()'><i class="las la-times"></i></a></span>
    </button>
  </div>
    <div class="modal-body">
      <div class="content clearfix">
        <div class="form-group">
          <div>
            <div class="row mb-3 ps-2">
              <div class="custom-control custom-radio col-12 d-flex flex-row">
                <input class="form-check-input px-2 me-1 my-auto" type="radio" name="method" id="1" value="1" [(ngModel)]="method"> 
                <label class="form-check-label" for="1">{{locale("locale_key.pages.organization.edit_mode.delete_from_here", {data_group_name: node.label})}}</label>
              </div>
            </div>
            <div class="row mb-3 ps-2" *ngIf='similarNodeExists'>
              <div class="custom-control custom-radio col-12 d-flex flex-row">
                <input class="form-check-input px-2 me-1 my-auto" type="radio" name="method" id="2" value="2" [(ngModel)]="method">
                <label class="form-check-label" for="2">{{locale("locale_key.pages.organization.edit_mode.delete_from_everywhere", {data_group_name: node.label})}}</label>
              </div>
            </div>
            <div class="row mb-3 ps-2" *ngIf='hasChildren'>
              <div class="custom-control custom-radio col-12 d-flex flex-row">
                <input class="form-check-input px-2 me-1" type="radio" name="method" id="3" value="3" [(ngModel)]="method">
                <label class="form-check-label" for="3">{{locale("locale_key.pages.organization.edit_mode.delete_from_here_and_keep_sub_groups", {data_group_name: node.label})}}</label>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
    <div class="modal-footer" style="border:none;">
      <button type="submit" class="btn secondary-button custom-button"
        (click)='closeModal()'>{{locale("locale_key.general.buttons.cancel")}}</button>
      <button type="submit" class="btn primary-button custom-button"
        (click)='deleteNode()'>
        {{locale("locale_key.pages.organization.edit_mode.button.delete")}}
      </button>
    </div>
  </div>  