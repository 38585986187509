<div class="animated slideInRight text-start" style="border-radius: 5px;">
  <div [ngClass]="showOnlyInfo ? '' :'modal-content custom-content thin-border'">
    <div class="modal-header" style="padding: 0rem;margin-top:1px;" *ngIf="!showOnlyInfo">
      <ul class="nav nav-tabs nav-underline no-hover-bg nav-justified w-100">
        <li class="nav-item w-50" style="font-size: 12px;" *ngIf="!showOnlyDatabaseInfo">
          <a class="nav-link w-100 border-0" [ngClass]="{ 'active bold': isEFDetailOpen }" (click)="isEFDetailOpen = true">
            <p class="text-underline d-inline cursor-arrow">{{ locale("locale_key.pages.emission.emission_factor_details") }}</p>          
          </a>
        </li>
        <li class="nav-item w-50" style="font-size: 12px;" *ngIf="showDatabaseInfo && emissionFactor['relatedDatabase'] || showOnlyDatabaseInfo">
          <a class="nav-link w-100 border-0" [ngClass]="{ 'active bold': !isEFDetailOpen }" (click)="isEFDetailOpen = false">
            <p class="text-underline d-inline cursor-arrow">{{ locale("locale_key.pages.emission.database_info") }}</p>    
            <i class="la la-close ms-2 cursor-pointer" (click)="close()" *ngIf="!isEFDetailOpen && emissionFactor['relatedDatabase'] || showOnlyDatabaseInfo"></i>      
          </a>
        </li>
      </ul>
    </div>
    <div [ngStyle]="showOnlyInfo ? {} : {'height': '50vh', 'min-height': '50vh'}" class="scroll">
      <ng-container *ngIf="isEFDetailOpen && !showOnlyDatabaseInfo">
        <div class="row mx-1 border-b">
          <div class="col-6 p-2">{{locale('locale_key.pages.emission.emission_source')}}</div>
          <div class="col-6 bold p-2">{{emissionFactor.sourceName}}</div>
        </div>
        <div class="row mx-1 border-b">
          <div class="col-6 p-2">{{locale('locale_key.pages.emission.emission_source_unit')}}</div>
          <div class="col-6 bold p-2">{{emissionFactor.sourceUnit}}</div>
        </div>
        <div class="row mx-1 border-b">
          <div class="col-6 p-2">{{locale('locale_key.pages.emission.conversion_unit')}}</div>
          <div class="col-6 bold p-2">{{emissionFactor.conversionUnit}}</div>
        </div>
        <div class="row mx-1 border-b">
          <div class="col-6 p-2">{{locale('locale_key.pages.emission.conversion_factor')}}</div>
          <div class="col-6 bold p-2">{{emissionFactor.conversionFactor}}</div>
        </div>
        <div class="row mx-1 border-b" *ngFor="let detail of emissionFactor.getAdditionDetails()">
          <div class="col-6 p-2">{{detail[0] | titlecase}}</div>
          <div class="col-6 bold p-2">{{detail[1] | titlecase}}</div>
        </div>
        <div class="mx-1 border-b" *ngIf="emissionFactor.origin">
          <div class="col word-break-all pb-0 p-2">{{locale('locale_key.pages.emission.conversion_factor_origin')}}</div>
          <div class="col word-break-all pt-0 p-2" style="font-size: 1rem;">{{emissionFactor.origin}}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isEFDetailOpen && emissionFactor['relatedDatabase'] || showOnlyDatabaseInfo">
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.database_name')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor['relatedDatabase'].name}}</div>
        </div>
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.original_release_date')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor.getDateString(emissionFactor['relatedDatabase'].releaseDate)}}</div>
        </div>
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.upload_date_in_platform')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor.getDateString(emissionFactor['relatedDatabase'].uploadedInPlatformDate)}}</div>
        </div>
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.publisher_organization')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor['relatedDatabase'].publisher}}</div>
        </div>
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.applied_geography')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor['relatedDatabase'].appliedGeography}}</div>
        </div>
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.applied_time_period')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor.getDateString(emissionFactor['relatedDatabase'].appliedTimeFrom)}} - {{emissionFactor.getDateString(emissionFactor['relatedDatabase'].appliedTimeTo)}}</div>
        </div>
        <div class="mx-1 border-b">
          <div class="col pb-0 p-2">{{locale('locale_key.pages.emission.license_information')}}</div>
          <div class="col pt-0 bold p-2">{{emissionFactor['relatedDatabase'].license}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>