import { StageTableDataGridIntergationService } from "src/app/components/data/StageTableDataGridIntergationService";
import { OrganizationTableDataGridIntergationService } from "src/app/components/organization/OrganizationTableDataGridIntergationService";
import { TableDataFe } from "../schema/TableDataFe";
import { OrganizationEntityFe } from "./OrganizationEntityFe";

export class OrganizationTableInfoFe {
  public entity: OrganizationEntityFe
  public dataGridService: OrganizationTableDataGridIntergationService

  public isActive: boolean = false;

  constructor(entity: OrganizationEntityFe, dataGridService: OrganizationTableDataGridIntergationService) {
    this.entity = entity
    this.dataGridService = dataGridService
  }

}