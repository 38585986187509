import { Language } from "./Language";
import { LanguageService } from "../../services/LanguageServiceFe";
import { DataGridColumnType } from 'src/app/components/projects/data-grid-ui/model/DataGridColumnType'

export abstract class AbstractLanguageComponent {
  activeLanguage: Language
  languageService: LanguageService

  constructor(languageService: LanguageService) {
    this.activeLanguage = languageService.getActiveLanguage()
    this.languageService = languageService
    languageService.languageSubject.subscribe((languageCode) => {
      this.activeLanguage = languageService.getActiveLanguage()
    })
  }

  public locale(key: string, kv: any = undefined, languageCode?: string): string {
    return this.languageService.getLocale(key, kv, languageCode)
  }

  public slocale(label: string, kv: any = undefined): string {
    if (kv) {
      const variableKeys = Object.keys(kv)

      variableKeys.forEach((key) => {
        const regex = new RegExp('\\{\\{' + key + '\\}\\}', 'g')
        label = label.replace(regex, kv[key])
      })
    }
    return label
  }

  public getDatatype(datatype: string) {
    switch (datatype) {
      case DataGridColumnType.BOOLEAN : return this.locale('locale_key.general.data_type.boolean')
      case DataGridColumnType.NUMERIC : return this.locale('locale_key.general.data_type.number')
      case DataGridColumnType.STRING : return this.locale('locale_key.general.data_type.text')
      case DataGridColumnType.DATE : return this.locale('locale_key.general.data_type.date')
      case DataGridColumnType.EMISSION_FACTOR : return this.locale('locale_key.pages.emission.emission_factor')
    }
    return datatype;
  }
}