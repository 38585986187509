import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { QuestionAttachmentFe } from "./QuestionAttachmentFe"
import { QuestionDataTableFe } from "./QuestionDataTableFe"
import { QuestionDateFe } from "./QuestionDateFe"
import { QuestionFe } from "./QuestionFe"
import { QuestionMultipleChoiceFe } from "./QuestionMultipleChoiceFe"
import { QuestionNumberFe } from "./QuestionNumberFe"
import { QuestionSingleChoiceFe } from "./QuestionSingleChoiceFe"
import { QuestionTextFe } from "./QuestionTextFe"
import { QUESTION_TYPE } from "./QuestionTypeFe"
import { QuestionEmissionFactorFe } from "./QuestionEmissionFactorFe"
   
export class QuestionTypeConversion{
  
    public static fromTransfer(transfer: any, sectionId: string): QuestionFe{
      switch (transfer.type) {
        case QUESTION_TYPE.DATA_TABLE :
          return QuestionDataTableFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.DATE :
          return QuestionDateFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.MULTIPLE_CHOICE :
          return QuestionMultipleChoiceFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.SINGLE_CHOICE :
          return QuestionSingleChoiceFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.NUMBER :
          return QuestionNumberFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.TEXT :
          return QuestionTextFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.ATTACHMENT :
          return QuestionAttachmentFe.fromTransfer(transfer, sectionId);
        case QUESTION_TYPE.EMISSION_FACTOR:  
          return QuestionEmissionFactorFe.fromTransfer(transfer, sectionId);
      }
    }

    public static setQuestion(transfer: QuestionFe, entity?: EntityFe): QuestionFe{
      let question;
      switch (transfer.type) {
        case QUESTION_TYPE.DATA_TABLE :
          question = QuestionDataTableFe.setQuestion(transfer); break;
        case QUESTION_TYPE.DATE :
          question = QuestionDateFe.setQuestion(transfer); break;
        case QUESTION_TYPE.MULTIPLE_CHOICE :
          question = QuestionMultipleChoiceFe.setQuestion(transfer); break;
        case QUESTION_TYPE.SINGLE_CHOICE :
          question = QuestionSingleChoiceFe.setQuestion(transfer); break;
        case QUESTION_TYPE.NUMBER :
          question = QuestionNumberFe.setQuestion(transfer); break;
        case QUESTION_TYPE.TEXT :
          question = QuestionTextFe.setQuestion(transfer); break;
        case QUESTION_TYPE.ATTACHMENT :
          question = QuestionAttachmentFe.setQuestion(transfer); break;          
        case QUESTION_TYPE.EMISSION_FACTOR:  
          return QuestionEmissionFactorFe.setQuestion(transfer); break;
      }
      if (entity) {
        question.setMappedToCols(entity)
        if (question instanceof QuestionDateFe && question.mappedToColKeysRange) {
          question.setMappedToColsRange(entity)
        }
      }
      return question;
    }
}