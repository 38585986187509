import { BooleanStatementFe } from "./BooleanStatementFe";

export class VariableStatementFe extends BooleanStatementFe {
  public static TYPE_KEY = 'VARIABLE' 
  public type = VariableStatementFe.TYPE_KEY 
  public static START_KEYWORD = '$'

  public reference: string

  constructor(reference: string) {
    super()
    this.reference = reference
  }

  public toFormulaString(): string {
    let str = [`${VariableStatementFe.START_KEYWORD}`,
               `${this.reference}`].join('');
    return str
  }

  public static fromFormulaString(formula: string): VariableStatementFe {
    let nextSpace = formula.indexOf(BooleanStatementFe.SPACE);
    let reference = formula.substring(VariableStatementFe.START_KEYWORD.length, nextSpace);
    return new VariableStatementFe(reference)
  }

  public evaluate(values: Map<string, any>): any {
    return values.get(this.reference)
  }

  public static fromTransfer (transfer: any): VariableStatementFe {
    let reference = transfer['reference']
    let statement = new VariableStatementFe(reference)
    return statement
  }
}
