import { Component, ElementRef, OnInit, Renderer2, SimpleChange, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { CompanyFe } from "src/app/model/data-suppliers/company/CompanyFe";
import { PartnerInvitationFe } from "src/app/model/data-suppliers/company/PartnerInvitationFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import COUNTRIES from "src/app/model/COUNTRIES.json"
import { ActionFe } from "src/app/model/subject/ActionFe";
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { ContactInvitationFe } from "src/app/model/data-suppliers/company/ContactInvitationFe";
import { ContactFe } from "src/app/model/user/ContactFe";
import { ManagerInvitationFe } from "src/app/model/invitation/ManagerInvitationFe";
import { ManagerFe } from "src/app/model/user/ManagerFe";
import { AffiliationStatus } from "src/app/model/user/AffiliationStatusFe";
import { InvitationStatusFe } from "src/app/model/invitation/InvitationStatusFe";
import { Subscription } from "rxjs";

@Component({
  selector: "network",
  templateUrl: "./network.component.html",
  styleUrls: ["./network.component.scss", "../data-suppliers/data-suppliers.component.scss"],
})
export class NetworkPortalComponent extends AbstractLanguageComponent implements OnInit {

  @ViewChild('invitePartnerModal', { static: true }) invitePartnerModal: TemplateRef<any>;
  @ViewChild('inviteReporterModal', { static: true }) inviteReporterModal: TemplateRef<any>;

  detailsData: any;
  isLoading = false;
  companies: SupplierCompanyFe[] = [];
  companyInvitations: PartnerInvitationFe[] = [];
  selectedInvitation: PartnerInvitationFe | ContactInvitationFe
  filteredData: (SupplierCompanyFe | CompanyFe | PartnerInvitationFe)[] = [];
  currentCompany: CompanyFe
  selectedIndex = -1;
  isCollapsed: boolean[] = [];
  selectedUser: ContactFe
  selectedUserIdx = -1;
  selectedCompany: any;
  initCacheInProgress: boolean
  menuCollapsed: boolean 
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  loadingData: boolean = false;
  searchKeyword: string
  url: string = '';
  sub = new Subscription();

  userOptions = [
    { label: this.locale('locale_key.pages.network.business_partners_table.active_column'), value: 'ACTIVE_USERS' },
    { label: this.locale('locale_key.general.state.deactivated'), value: 'DEACTIVATED_USERS' },
    { label: this.locale('locale_key.general.state.invited'), value: 'INVITATIONS' },
  ];

  pageToolbar = [
    [
      { shortLabel: this.locale('locale_key.pages.network.toolbar.add_business_partner'), longLabel: this.locale('locale_key.pages.network.add_business_partner_wizard.button.add_network'), tooltip: this.locale('locale_key.pages.network.add_business_partner_wizard.button.add_network'),icon: "la la-plus",  visible: () => true, actionName:"add_partner" },
      { shortLabel: this.locale('locale_key.pages.data_reporters.add_data_reporter_wizard.button.add_reporter'), longLabel: this.locale('locale_key.pages.data_reporters.add_data_reporter_wizard.button.add_reporter'), tooltip: this.locale('locale_key.pages.network.add_business_partner_wizard.button.add_reporter_tooltion'),icon: "la la-plus", visible: () => true,actionName: "add_reporter" }
    ], 
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info", actionName:"toggle_quick_tips" , visible:()=> true}
    ],     
   
  ]

  constructor(private modalRef: BsModalRef, private modalService: BsModalService, public stateService: StateServiceFe, private displayService: DisplayServiceFe, languageService: LanguageService, private responsive: ResponsiveService, private renderer: Renderer2, private el: ElementRef) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });

    this.screenSize = responsive.currentScreenWidthSize;

    let contactInvitationUpdatedSubject = this.stateService.contactInvitationUpdatedSubject.subscribe((obj) => {
      if (obj.action == ActionFe.CREATED) {
        let company = this.companies.find(c => c.supplierCompanyId == obj.invitation.contactCompanyId) 
        if (company) {
          company.doInvitations.push(obj.invitation)
        } else {
          this.currentCompany.doInvitations.push(obj.invitation)
        }
      }
    })

    let partnerInvitationUpdatedSubject = this.stateService.partnerInvitationUpdatedSubject.subscribe((obj) => {
      if (obj.action == ActionFe.CREATED) {
        this.companyInvitations.push(obj.invitation);
        this.filteredData.push(obj.invitation)
      }
    })

    this.sub.add(contactInvitationUpdatedSubject)
  }

  async ngOnInit(): Promise<void> {
    this.renderNewData();
  }

  ngOnDestroy() {
   this.sub.unsubscribe(); 
  }

  async renderNewData(){
    this.loadingData = true;
    
    this.companies = await this.stateService.getPartners()
    let currentCompanyId = this.stateService.activeWorkspace.companyId;
    this.currentCompany = await this.stateService.getManagementCompanyById(currentCompanyId)
    let allcompanyInvitations = await this.stateService.getPartnerInvitations()
    this.companyInvitations = allcompanyInvitations.filter(invitation => invitation.invitationStatus != InvitationStatusFe.ACCEPTED)
    
    this.filteredData = [this.currentCompany]
    this.filteredData.push(...this.companies.filter(c => c.supplierCompanyId != this.stateService.activeWorkspace.companyId))
    this.filteredData.push(...this.companyInvitations)
    this.isCollapsed = this.filteredData.map(e => true);

    let allDataOwners = await this.stateService.getContacts();
    let allRequests = await this.stateService.getRequestGroups
    let allDOInvitations = await this.stateService.getContactInvitations();
    this.setCompanyNetwork(this.currentCompany, allDataOwners, allDOInvitations);
    this.filteredData.forEach(company => {
      if (!(company instanceof PartnerInvitationFe)) {
        this.setCompanyNetwork(company, allDataOwners, allDOInvitations)
      }
    });
    this.url = window.location.href;
    this.loadingData = false;
  }

  openModal(templateRef: TemplateRef<any> | string, size:string) {
    this.modalRef = this.modalService.show(templateRef, { class: size });
  }
  
  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  setSelectedCompany(company: any) {
    this.selectedCompany = company;
  }

  setSelectedInvitation(inv: PartnerInvitationFe | ContactInvitationFe, index: number) {
    this.selectedInvitation = inv;
    this.selectedIndex = index;
    
  }

  updateDetailsData(data: ContactFe) {
    this.detailsData = {data};
    this.displayService.updateDetailsData(this.detailsData);
  }

  setSelectedUser({user,idx}){
    this.selectedUser = user;
    this.selectedUserIdx = idx;
  }

  async cancelInvitation() {
    this.isLoading = true;
    if (this.selectedInvitation instanceof ContactInvitationFe) {
      await this.stateService.cancelContactInvitation(this.selectedInvitation.uuid);
    } else {
      await this.stateService.cancelPartnerInvitation(this.selectedInvitation.uuid);
    }
    this.isLoading = false;
    this.closeModal();
  }

  resolveLabel(label: any): string {
    if (!label) {
      return ''
    }

    return label[this.activeLanguage?.code] || label['en'] || label
  }

  getCountry(countryKey:string): string{
    let country = Object.entries(COUNTRIES).find(data=> data[0] == countryKey);
    return this.resolveLabel(country[1]);
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  handleToolbarAction(actionName: string) {
    switch(actionName) {
      case "add_partner":
        this.selectedInvitation = null
        this.openModal(this.invitePartnerModal, 'modal-lg')
        break;
      case "add_reporter":
        this.selectedUser = null
        this.openModal(this.inviteReporterModal, 'modal-lg')
        break;
      case "toggle_quick_tips":
        this.toggleTips()
        break;
    }
  }

  setCompanyNetwork (company: CompanyFe | SupplierCompanyFe, allDataOwners: ContactFe[], allDOInvitations: ContactInvitationFe[]) {
    if (company instanceof SupplierCompanyFe) {
      company.dataOwners = allDataOwners.filter(c => c.affiliationCompanyId == company.supplierCompanyId && c.affiliationStatus == AffiliationStatus.ACTIVE)
      company.deactivatedDOs = allDataOwners.filter(c => c.affiliationCompanyId == company.supplierCompanyId && c.affiliationStatus != AffiliationStatus.ACTIVE)
      company.doInvitations = allDOInvitations.filter(i => i.contactCompanyId == company.supplierCompanyId && i.invitationStatus != InvitationStatusFe.ACCEPTED)
    } else {
      company.dataOwners = allDataOwners.filter(c => c.affiliationCompanyId == company.id && c.affiliationStatus == AffiliationStatus.ACTIVE)
      company.deactivatedDOs = allDataOwners.filter(c => c.affiliationCompanyId == company.id && c.affiliationStatus != AffiliationStatus.ACTIVE)
      company.doInvitations = allDOInvitations.filter(i => i.contactCompanyId == company.id && i.invitationStatus != InvitationStatusFe.ACCEPTED)
    }
  }

  filterCompanies () {
    let data = []
    const filter = this.searchKeyword.toLowerCase()
    if (this.currentCompany.name.toLowerCase().includes(filter) || this.currentCompany.dataOwners.some(o => o.getName().toLowerCase().includes(filter))) {
      data.push(this.currentCompany)
    }
    data.push(...this.companies.filter(c =>
      c.supplierCompanyname.toLowerCase().includes(filter) ||
      c.dataOwners.some(o => o.getName().toLowerCase().includes(filter))
    ))
    
    data.push(...this.companyInvitations.filter(c => c.partnerName.toLowerCase().includes(filter)))


    this.filteredData = data
    this.isCollapsed = this.filteredData.map(e => true);
  }

  inviteAgain(object: PartnerInvitationFe | ContactFe){
    if (object instanceof ContactFe) {
      this.openModal(this.inviteReporterModal, 'modal-md')
    } else {
      this.openModal(this.invitePartnerModal, 'modal-md')
    }
  }

  async deactivateContact() {
    this.isLoading = true;
    const deactivated = await this.stateService.deactivateContact(this.selectedUser);
    this.selectedUser.deactivationDate = new Date()
    this.selectedCompany.dataOwners.splice(this.selectedUserIdx,1);
    this.selectedCompany.deactivatedDOs.push(this.selectedUser);
    this.isLoading = false;
    this.closeModal();
  }

  toggleCollapse(index: number) {
    this.selectedCompany = this.filteredData[index]
    this.isCollapsed[index] = !this.isCollapsed[index];
    let acc = document.getElementById('acc'+index)
    acc.classList.toggle('collapse')
    if (this.isCollapsed[index]) {
      this.selectedCompany.selectedOption = null
    } else {
      if (this.selectedCompany.dataOwners.length > 0) {
        this.selectedCompany.selectedOption = this.userOptions[0].value
      } else if (this.selectedCompany.deactivatedDOs.length > 0) {
        this.selectedCompany.selectedOption = this.userOptions[1].value
      } else if (this.selectedCompany.doInvitations.length > 0) {
        this.selectedCompany.selectedOption = this.userOptions[2].value
      } else {
        this.selectedCompany.selectedOption = null
      }
    }
  }

  collapseAll() {
    this.toggleCollapse(0)
    this.isCollapsed =  this.isCollapsed.map(c => c = true)
  }

  expandAll() {
    this.toggleCollapse(0)
    this.isCollapsed = this.isCollapsed.map(c => c = false)
  }

  isAllExpanded() {
    return this.isCollapsed.every(c => !c)
  }

  isAllCollapsed() {
    return this.isCollapsed.every(c => c)
  }

  isAInvitation(obj: any): boolean {
    return obj instanceof PartnerInvitationFe
  }
}
