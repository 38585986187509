import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { FirebaseError, initializeApp } from "firebase/app";
import { Auth, getAuth, signInWithEmailAndPassword, signInWithRedirect, GoogleAuthProvider, getRedirectResult, getIdTokenResult, UserCredential, RecaptchaVerifier, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, MultiFactorResolver, getMultiFactorResolver, OAuthProvider } from "firebase/auth";
import { OpenErrorsFe } from "../utils/KNOWN_ERRORS";


const handleFirebaseError = ({err,errormap}) => {
    
}

export interface UserCredentialWithCustomClaims extends UserCredential {
    customClaims?:any
}
@Injectable({
    providedIn: 'root'
})
export class GoogleIdentityPlatformService {

  
    private sustainlab_auth:Auth;
    private multiFactorResolver:MultiFactorResolver;
    private user_phones = [];
    private googleCreds:UserCredentialWithCustomClaims;
    private errorMap = {
        "auth/user-not-found":OpenErrorsFe.USER_NOT_FOUND_ERROR,
        "auth/admin-restricted-operation":OpenErrorsFe.PROVIDER_ACCOUNT_NOT_LINKED,
        "auth/wrong-password":OpenErrorsFe.INCORRECT_PASS_ERROR,
        "auth/too-many-requests":OpenErrorsFe.TOO_MANY_FAILED_ATTEMPTS,
        "auth/multi-factor-auth-required":OpenErrorsFe.MFA_AUTH_REQUIRED,
        "auth/web-storage-unsupported":OpenErrorsFe.WEB_STORAGE_NOT_SUPPORTED
    }
    constructor() {
        const firebaseConfig = {
            apiKey: environment.googleIdentityPlatform.firebaseApiKey,
            authDomain: environment.googleIdentityPlatform.firebaseAuthDomain,
        };
        const firebaseApp = initializeApp(firebaseConfig);
        this.sustainlab_auth = getAuth(firebaseApp);

        const tenantId = environment.googleIdentityPlatform.masterTenantId;
        this.sustainlab_auth.tenantId = tenantId;
    }

    handleFirebaseError({err}) {
        const fireError:FirebaseError = err;      
        if(fireError.code == 'auth/multi-factor-auth-required'){
            this.multiFactorResolver = getMultiFactorResolver(this.sustainlab_auth,err);
            const phones = [];
            this.multiFactorResolver.hints.forEach((hint,idx) => {
                if(hint.factorId == PhoneMultiFactorGenerator.FACTOR_ID){
                    const phone:any = hint
                    phone.idx = idx;
                    phones.push(phone);
                }
            });
            this.user_phones = phones;
        }
        
        if(!this.errorMap[fireError.code]) throw err;
        throw this.errorMap[fireError.code];
    }

    async signInWithGoogle() {
        const googleProvider = new GoogleAuthProvider();
        // So that on re-direct we know to signin with google
        sessionStorage.setItem('signInWithGoogle','true');
        await signInWithRedirect(this.sustainlab_auth,googleProvider);
    }

    async signInWithMicrosoft(){
        const microsoftProvider = new OAuthProvider('microsoft.com');
        // So that on re-direct we know to signin with google
        sessionStorage.setItem('signInWithMicrosoft','true');
        await signInWithRedirect(this.sustainlab_auth,microsoftProvider);
    }

    async getRedirectResults(){
        try{
            const creds:UserCredentialWithCustomClaims = await getRedirectResult(this.sustainlab_auth);
            const idTokenResult = await getIdTokenResult(creds.user);
            creds.customClaims = idTokenResult.claims;
            return creds;
        }catch(err){
            this.handleFirebaseError({err});
        }
    }


    async signInWithEmailAndPassword({email,password}) {
        try{
            const creds:UserCredentialWithCustomClaims = await signInWithEmailAndPassword(this.sustainlab_auth,email,password);
            const idTokenResult = await getIdTokenResult(creds.user);
            creds.customClaims = idTokenResult.claims;
            return creds;
        }catch(err){
            this.handleFirebaseError({err})
        }
    }

    // user is enrolling new phone
    async sendEnrollVerificationCode({phonenumber,mfaBtnID}){
        const user = this.sustainlab_auth.currentUser;
        const recaptchaVerifier = new RecaptchaVerifier(mfaBtnID, {"size": "invisible"}, this.sustainlab_auth);
        const multiFactorSession = await multiFactor(user).getSession();
        const phoneInfoOptions = { phoneNumber: phonenumber, session: multiFactorSession };
        const phoneAuthProvider = new PhoneAuthProvider(this.sustainlab_auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        return verificationId; 
    }

    // user is verifying using existing phone
    async sendAuthVerificationCode({mfaBtnID,mfaPhoneIdx}){
       if(this.multiFactorResolver.hints[mfaPhoneIdx].factorId === PhoneMultiFactorGenerator.FACTOR_ID){
        const recaptchaVerifier = new RecaptchaVerifier(mfaBtnID, {"size": "invisible"}, this.sustainlab_auth);
        const phoneInfoOptions = { multiFactorHint: this.multiFactorResolver.hints[mfaPhoneIdx], session: this.multiFactorResolver.session };
        const phoneAuthProvider = new PhoneAuthProvider(this.sustainlab_auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        return verificationId; 
       }
    }

    async sendVerifcationCode({phonenumber,mfaBtnID,mfaType,mfaPhoneIdx}){
        switch(mfaType){
            case 'enroll':
                return this.sendEnrollVerificationCode({phonenumber,mfaBtnID});
            case 'auth':
                return this.sendAuthVerificationCode({mfaBtnID,mfaPhoneIdx});
        }
    }

    async verifyEnrollMfaCode({verificationID,verificationCode}){
        const user = this.sustainlab_auth.currentUser;
        const cred = PhoneAuthProvider.credential(verificationID, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        return multiFactor(user).enroll(multiFactorAssertion);
    }

    async verifyAuthMfaCode({verificationID,verificationCode}){
        const cred = PhoneAuthProvider.credential(verificationID, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        const googleCreds:UserCredentialWithCustomClaims = await this.multiFactorResolver.resolveSignIn(multiFactorAssertion);
        const idTokenResult = await getIdTokenResult(googleCreds.user);
        googleCreds.customClaims = idTokenResult.claims;
        this.googleCreds = googleCreds;
    }

    async verifyMfaCode({verificationID,verificationCode,mfaType}){
        switch(mfaType){
            case 'enroll':
                return this.verifyEnrollMfaCode({verificationID,verificationCode});
            case 'auth':
                return this.verifyAuthMfaCode({verificationID,verificationCode});
        }
    }

    getUserPhones(){
        return this.user_phones;
    }

    getGoogleCreds(){
        return this.googleCreds;
    }
}