<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill py-2">
      <div class="d-flex flex-row justify-content-between">
        <h3 class="text-uppercase ml-25">{{ locale('locale_key.pages.settings.title') }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 pt-0 mt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h font-large-1 pt-0 mt-0" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-row me-2 w-100">
          <section class="row w-100 ms-0" style="overflow: hidden">
            <div class="col-12 card px-0 mx-0" style="overflow: hidden; min-height: 85vh">
              <div>
                <app-tabs [selectedIndex]="activeTabIndex">
                  <app-tab tabTitle="{{ locale('locale_key.pages.settings.user_setting') }}" [isActive]="true">
                    <div>
                      <app-user-settings></app-user-settings>
                    </div>
                  </app-tab>
                  <app-tab *ngIf="!isDO()" tabTitle="{{ locale('locale_key.pages.settings.company_setting') }}">
                      <app-company-settings></app-company-settings>
                  </app-tab>
                </app-tabs>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
