import { Component, OnInit, Output, EventEmitter, Input, TemplateRef, ViewChild, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";
import { CustomEmissionFactorFe } from "src/app/model/emissions/CustomEmissionFactorFe";
import { GlobalEmissionFactorFe } from "src/app/model/emissions/GlobalEmissionFactorFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { DataCategoryServiceFe } from "src/app/services/DataCategoryServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { groupUnitsByMeasurementTypeAndSystem } from "../../unit-systems/model/utils";
import { IdUtil } from "src/app/utils/IdUtil";
import { ValidationRegex } from "src/app/model/form-validation/ValidationRegex";
import { BsModalService } from "ngx-bootstrap/modal";
import _ from "lodash";
import { GlobalDatabaseFe } from "src/app/model/emissions/GlobalDatabaseFe";
import moment from "moment"
import { UnitFe } from "../../unit-systems/model/UnitFe";

enum ModalNames {
  backToMenuModal = "backToMenuModal",
}

@Component({
  selector: "emission-factor-detail",
  templateUrl: "./emission-factor-detail.component.html",
  styleUrls: ["./emission-factor-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmissionFactorDetailComponent extends AbstractLanguageComponent implements OnInit {
  @Input() mgmtCompanyId = null;
  @Input() datapointEFUnit: { sourceUnit: string; conversionUnit: string } = null;
  sourceMeasurementType: string;
  conversionMeasurementType: string;

  isLoading = false;
  loadingInProgress = false;
  viewNo = 1;
  action: string = null;
  subAction: string = null;

  globalEfs: GlobalEmissionFactorFe[] = [];
  filteredGlobalEfs: GlobalEmissionFactorFe[] = [];
  customEfs: CustomEmissionFactorFe[] = [];
  filteredCustomEfs: CustomEmissionFactorFe[] = [];
  selectedEf: AbstractEmissionFactorFe;
  appliedFilters = new Map<string, Set<string>>();
  searchableProperties = new Set(["sourceName", "sourceUnit", "conversionUnit", "databaseName"]);
  globalDBs: GlobalDatabaseFe[] = [];
  globalDBScopes = {};
  filteredGlobalDBs: GlobalDatabaseFe[] = [];
  selectedGlobalDBs = new Set();
  selectedGlobalDBsSliceSize = 5;
  globalDBsFilterQuery = "";
  globalDBToView: GlobalDatabaseFe;
  fakeEmissionFactorToViewGlobalDb: GlobalEmissionFactorFe;
  seeingMoreGlobalDBs = false;
  seeingLessGlobalDBs = true;

  conversionUnit = null;
  sourceUnit = null;
  newEmissionFactor = new FormGroup({
    sourceName: new FormControl(null, [Validators.required]),
    conversionFactor: new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.NumberRegExp)]),
    origin: new FormControl(null)
  });
  showNewEmissionFactorErrors: boolean = false;

  units:UnitFe[] = [];
  customUnits = [];
  unitsByMeasurementType = [];
  searchKeyword = "";
  @Output() closeEvent = new EventEmitter<boolean>();
  @Output() emissionFactorSelected = new EventEmitter<AbstractEmissionFactorFe>();

  @ViewChild(`${ModalNames.backToMenuModal}`, { static: true })
  backToMenuModal: TemplateRef<any>;

  modals;
  unitsWithIssues = []

  constructor(
    public languageService: LanguageService,
    public stateService: StateServiceFe,
    public categoryService: DataCategoryServiceFe,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef
  ) { 
    super(languageService);
  }

  async ngOnInit(): Promise<void> {
      this.isLoading = true;

      [this.globalEfs, this.globalDBs] = await Promise.all([
        this.stateService.getGlobalEmissionFactors(),
        this.stateService.getGlobalDatabases()
      ]);

      this.checkEfsForUnitsThatDontExist();

      this.populateGlobalDBScope();

      this.customEfs = await this.stateService.getCustomEmissionFactors(false, this.mgmtCompanyId);

      await this.loadUnits();

      if (this.datapointEFUnit) {
        const sourceUnit = this.getUnit(this.datapointEFUnit.sourceUnit);
        const conversionUnit = this.getUnit(this.datapointEFUnit.conversionUnit);

        if (sourceUnit && conversionUnit) {
          this.sourceMeasurementType = sourceUnit.measurementType;
          this.conversionMeasurementType = conversionUnit.measurementType;

          // Filter global emission factors based on measurement type
          this.globalEfs = this.globalEfs.filter(e => {
            const sourceUnit = this.getUnit(e.sourceUnit);
            const conversionUnit = this.getUnit(e.conversionUnit);
            return sourceUnit?.measurementType === this.sourceMeasurementType &&
                   conversionUnit?.measurementType === this.conversionMeasurementType;
          });

          this.customEfs = this.customEfs.filter(e => {
            const sourceUnit = this.getUnit(e.sourceUnit);
            const conversionUnit = this.getUnit(e.conversionUnit);
            return sourceUnit?.measurementType === this.sourceMeasurementType &&
                   conversionUnit?.measurementType === this.conversionMeasurementType;
          });
        }
      }

      this.isLoading = false;
      this.setupModalsV2();
      this.filterGlobalDBs();
  }

  private checkEfsForUnitsThatDontExist() {
    const unitEvaluator = this.stateService.getUnitEvaluator();

    const unitsWithIssuesBuffer = new Set();

    this.globalEfs.forEach((ef) => {
      try {
        unitEvaluator.evaluate(`1${ef?.sourceUnit} * 2`);
      } catch (err) {
        unitsWithIssuesBuffer.add(`${ef?.sourceUnit} ( ${ef?.databaseName} )`);
      }
      try {
        unitEvaluator.evaluate(`1${ef?.conversionUnit} * 2`);
      } catch (err) {
        unitsWithIssuesBuffer.add(`${ef?.conversionUnit} ( ${ef?.databaseName} )`);
      }
    });

    this.unitsWithIssues = Array.from(unitsWithIssuesBuffer);
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units);
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.customUnits = this.units.filter((unit) => unit.isCustom);
  }

  setAction(action: string) {
    this.viewNo = 2;
    this.action = action;
    this.setFilter();
    this.selectedEf = null;
  }

  goBackToMenu() {
    this.viewNo = 1;
    this.action = "";
    this.selectedEf = null;
  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  getFilterValues(property: string) {
    let difValues = new Set<string>();
    if (this.action == "global") {
      this.globalEfs.forEach((ef) => {
        if (this.selectedGlobalDBs.has(ef.databaseId)) {
          difValues.add(ef[property]);
        }
      });
    } else [this.customEfs.forEach((ef) => difValues.add(ef[property]))];
    return difValues;
  }

  updateFilter(property: string, selectedValues: Set<string>) {
    this.appliedFilters.set(property, selectedValues);
    this.applyFilters();
  }

  applySearchFilterOnAdditionaDetails() {
    this.searchKeyword = this.searchKeyword.toLowerCase();

    let factors: AbstractEmissionFactorFe[] = this.action == "global" ? this.filteredGlobalEfs : this.filteredCustomEfs;
    let filteredFactors = factors.filter((ef) => ef.getAdditionDetails().find((detail) => detail[1].toLowerCase().includes(this.searchKeyword)));

    if (this.action == "global") {
      this.filteredGlobalEfs = filteredFactors as GlobalEmissionFactorFe[];
      this.filteredGlobalEfs = this.filteredGlobalEfs.filter((ef) => this.selectedGlobalDBs.has(ef.databaseId));
    }
  }

  applyFilters() {
    switch (this.action) {
      case "global":
        const globalEfs = _.cloneDeep(this.globalEfs);
        const filteredGlobalEfs = globalEfs.filter((globalEf) => {
          //check if ef db in selected global dbs
          if (!this.selectedGlobalDBs.has(globalEf.databaseId)) {
            return false;
          }

          let shouldAdd = true;

          this.appliedFilters.forEach((filterValues, filterKey) => {
            if (filterValues.size > 0 && !filterValues.has(globalEf[filterKey])) {
              shouldAdd = false;
            }
          });

          return shouldAdd;
        });
        this.filteredGlobalEfs = filteredGlobalEfs;
        break;
      default:
        const customEfs = _.cloneDeep(this.customEfs);
        const filteredCustomEfs = customEfs.filter((customEf) => {
          let shouldAdd = true;
          this.appliedFilters.forEach((filterValues, filterKey) => {
            if (!filterValues.has(customEf[filterKey])) {
              shouldAdd = false;
            }
          });
          return shouldAdd;
        });
        this.filteredCustomEfs = filteredCustomEfs;
        break;
    }
    if (this.action == "global") {
      this.applySearchFilterOnAdditionaDetails();
    }
  }

  setFilter() {
    this.filteredGlobalEfs = this.globalEfs.filter((ef) => this.selectedGlobalDBs.has(ef.databaseId));
    this.filteredCustomEfs = this.customEfs;
  }

  async applyEf() {
    this.loadingInProgress = true;
    if (this.action == "addCustom") {
      this.newEmissionFactor.markAllAsTouched();
      if (this.newEmissionFactor.invalid) {
        this.showNewEmissionFactorErrors = true;
        this.loadingInProgress = false;
        return;
      }
      if (!this.conversionUnit || this.conversionUnit == "") {
        this.showNewEmissionFactorErrors = true;
        this.loadingInProgress = false;
        return;
      }
      if (!this.sourceUnit || this.sourceUnit == "") {
        this.showNewEmissionFactorErrors = true;
        this.loadingInProgress = false;
        return;
      }

      const sourceName = this.newEmissionFactor.get("sourceName").value;
      const conversionFactor = this.newEmissionFactor.get("conversionFactor").value;
      const conversionUnit = this.conversionUnit;
      const sourceUnit = this.sourceUnit;
      const origin = this.newEmissionFactor.get("origin").value;
      let newEf = new CustomEmissionFactorFe(IdUtil.next(), "", sourceName, sourceUnit, conversionFactor, conversionUnit, origin, {});
      await this.stateService.addEmissionFactor(newEf);
      this.emissionFactorSelected.emit(newEf);
    } else {
      this.emissionFactorSelected.emit(this.selectedEf);
    }
    this.closeModal();
    this.loadingInProgress = false;
  }

  getConversionUnitMeasurementType() {
    let measurementType = new Set();
    if (this.conversionMeasurementType) {
      measurementType.add(this.conversionMeasurementType);
    }
    return measurementType;
  }

  getSourceUnitMeasurementType() {
    let measurementType = new Set();
    if (this.sourceMeasurementType) {
      measurementType.add(this.sourceMeasurementType);
    }
    return measurementType;
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType;
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits;
  }

  private setupModalsV2() {
    this.isLoading = false;
    this.cdr.detectChanges();
    this.modals = {
      [`${ModalNames.backToMenuModal}`]: {
        template: this.backToMenuModal,
        class: `modal-md ${ModalNames.backToMenuModal}`
      }
    };
  }

  private openModalV2(options: { modal: ModalNames; class?: string; ignoreBackdropClick?: boolean }) {
    const modal = options.modal;
    const customClass = options.class ?? this.modals[modal].class;
    const template = this.modals[modal].template;
    const ignoreBackdropClick = options.ignoreBackdropClick ?? true;
    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass
    });
  }

  private closeModalV2({ modal }) {
    const modalRef = this.modals[modal].ref;
    if (modalRef) {
      this.modalService.hide(modalRef.id);
    }
  }

  startBackToMenu() {
    this.openModalV2({
      modal: ModalNames.backToMenuModal,
      ignoreBackdropClick: false
    });
  }

  confirmBackToMenu() {
    this.viewNo = 1;
    this.action = "";
    this.closeModalV2({
      modal: ModalNames.backToMenuModal
    });
  }

  cancelBackToMenu() {
    this.closeModalV2({
      modal: ModalNames.backToMenuModal
    });
  }

  isEmpty(obj) {
    const isObjEmpty = _.isEmpty(obj);
    return isObjEmpty;
  }

  goToSelectEmissionFactor() {
    this.subAction = "selectEF";
    this.applyFilters();
  }

  goToSelectGlobalEF() {
    this.setAction("global");
    this.subAction = "selectDB";
  }

  toggleGlobalDBSelected({ globalDB }: { globalDB: GlobalDatabaseFe }) {
    if (this.selectedGlobalDBs.has(globalDB.id)) {
      this.selectedGlobalDBs.delete(globalDB.id);
    } else {
      this.selectedGlobalDBs.add(globalDB.id);
    }
  }

  selectAllGlobalDBs() {
    this.globalDBs.forEach((globalDB) => {
      this.selectedGlobalDBs.add(globalDB.id);
    });
  }

  deselectAllGlobalDBs() {
    this.selectedGlobalDBs.clear();
  }

  isGlobalDBSelected({ globalDB }: { globalDB: GlobalDatabaseFe }) {
    return this.selectedGlobalDBs.has(globalDB.id);
  }

  getApplicableTimePeriod({ globalDB }: { globalDB: GlobalDatabaseFe }) {
    const from = moment(globalDB.appliedTimeFrom).format("MMM YYYY");
    const to = moment(globalDB.appliedTimeTo).format("MMM YYYY");
    const result = `${from} - ${to}`;
    return result;
  }


  filterGlobalDBs(){
    const globalDBs = _.sortBy(_.cloneDeep(this.globalDBs),["appliedTimeFrom","appliedTimeTo"]).reverse();

    if(_.isEmpty(this.globalDBsFilterQuery)){
      this.filteredGlobalDBs = globalDBs;
      return;
    }

    this.filteredGlobalDBs = globalDBs.filter((globalDB) => {
      let shouldAdd = false;

      // Check name
      if (globalDB.name.toLowerCase().includes(this.globalDBsFilterQuery.toLowerCase())) {
        shouldAdd = true;
      }

      // Check applied geography
      if (globalDB.appliedGeography.toLowerCase().includes(this.globalDBsFilterQuery.toLowerCase())) {
        shouldAdd = true;
      }

      // Check Applicable time period
      const applicableTimePeriod = this.getApplicableTimePeriod({ globalDB });
      if (applicableTimePeriod.toLowerCase().includes(this.globalDBsFilterQuery.toLowerCase())) {
        shouldAdd = true;
      }

      return shouldAdd;
    });
  }

  toggleViewGlobalDB({ globalDB }: { globalDB: GlobalDatabaseFe }) {
    if (_.isEmpty(this.globalDBToView)) {
      this.viewGlobalDB({ globalDB });
    } else {
      this.globalDBToView = null;
    }
  }

  closeViewGlobalDB(){
    this.globalDBToView = null;
  }

  viewGlobalDB({ globalDB }: { globalDB: GlobalDatabaseFe }) {
    this.globalDBToView = globalDB;
    this.fakeEmissionFactorToViewGlobalDb = GlobalEmissionFactorFe.fromTransfer({});
    this.fakeEmissionFactorToViewGlobalDb.relatedDatabase = this.globalDBToView;
    this.selectedEf = null;
  }

  toggleViewGlobalDBByID({ id }) {
    if (_.isEmpty(this.globalDBToView)) {
      this.viewGlobalDBByID({ id });
    } else {
      this.globalDBToView = null;
    }
  }

  viewGlobalDBByID({ id }) {
    const globalDB = _.find(this.globalDBs, { id });
    this.viewGlobalDB({ globalDB });
  }

  getSelectedDBName({ id }) {
    const globalDB = _.find(this.globalDBs, { id });
    return globalDB.name;
  }

  getSelectedDBDate({ id }) {
    const globalDB = _.find(this.globalDBs, { id });
    return this.getApplicableTimePeriod({ globalDB });
  }

  getSelectedGlobalDBs() {
    if (this.selectedGlobalDBs.size <= this.selectedGlobalDBsSliceSize || this.seeingMoreGlobalDBs) {
      return this.selectedGlobalDBs;
    }

    if (this.seeingLessGlobalDBs) {
      const slice = Array.from(this.selectedGlobalDBs).sort().slice(0, this.selectedGlobalDBsSliceSize);
      return slice;
    }
  }

  seeMoreGlobalDBs() {
    this.seeingMoreGlobalDBs = true;
    this.seeingLessGlobalDBs = false;
  }

  seeLessGlobalDBs() {
    this.seeingLessGlobalDBs = true;
    this.seeingMoreGlobalDBs = false;
  }

  selectEmissionFactor({ ef }) {
    this.selectedEf = ef;
    this.globalDBToView = null;
  }

  private populateGlobalDBScope() {
    const globalDBToScope = {};
    this.globalEfs.forEach((ef) => {
      globalDBToScope[ef.databaseId] = globalDBToScope[ef.databaseId] || new Set();
      globalDBToScope[ef.databaseId].add(ef.additionalDetails.Scope);
    });

    Object.keys(globalDBToScope).forEach((id) => {
      const sortedScopes = Array.from(globalDBToScope[id]).sort();
      this.globalDBScopes[id] = sortedScopes;
    });
  }

  private getUnit(rawSymbol: string): UnitFe {
    let symbol = rawSymbol;
    const evaluator = this.stateService.getUnitEvaluator();

    try {
        const baseSymbol = evaluator.evaluate(`1 ${symbol}`).units[0].unit.name;
        symbol = baseSymbol;
    } catch (err) {}

    for (const unit of this.stateService.units) {
        if (unit.symbol === symbol || unit.aliases.split(",").map(alias => alias.trim()).includes(symbol)) {
            return unit;
        }

        if (unit.hasPrefixes) {
            for (const prefixedUnit of unit.prefixList) {
                if (prefixedUnit.symbol === symbol || prefixedUnit.aliases.split(",").map(alias => alias.trim()).includes(symbol)) {
                    return prefixedUnit;
                }
            }
        }
    }

    return null;
  }

  getMt(symbol){
    const unit = this.getUnit(symbol);
    let mt = "n/a"
    if(!_.isEmpty(unit)){
      mt = unit.measurementType;
    }
    return mt;
  }
}
