import { DashboardFe } from "../dashboard/DashboardFe"
import { RequestFe, REQUEST_TASK_STATUS } from "../data-suppliers/request/RequestFe"
import { AccessibleCompanyFe } from "../org/AccessibleCompanyFe"
import { SharedDashboardFe } from "../shared/SharedDashboardFe"
import { AbstractSignInMethodFe } from "../signInMethod/AbstractSignInMethodFe"
import { SignInMethodConversionFe } from "../signInMethod/SignInMethodConversionFe"
import { AbstractAffiliationFe } from "./AbstractAffiliationFe"
import { AffiliationRoleFe } from "./AffiliationRoleFe"

export class ContactFe extends AbstractAffiliationFe {

  managementCompanyId: string
  supplierCompanyname: string | undefined
  supplierCountryCode: string | undefined
  supplierIndustryGroup: string | undefined
  supplierIndustry: string | undefined
  supplierWebsite: string | undefined
  partnershipStatus: string | undefined

  isSelected = false
  sharedDashboards: DashboardFe[]= []
  dataRequests: RequestFe[] = []
  
  constructor(
    affiliationCompanyId: string,
    userId: string,
    userFirstName: string,
    userLastName: string,
    affiliationId: string,
    affiliationEmail: string,
    affiliationStatus: string,
    activationDate: Date | null,
    deactivationDate: Date | null,
    lastLoggedAt: Date | null,    
    managementCompanyId: string,
    supplierCompanyname: string,
    supplierCountryCode: string,
    supplierIndustryGroup: string,
    supplierIndustry: string,
    supplierWebsite: string,
    partnershipStatus: string,
    signInMethods: AbstractSignInMethodFe[],
    mfaEnabled: boolean
  ) {
    //In this case affiliationCompanyId is same as supplierCompanyId
    super (
      affiliationCompanyId,
      userId,
      userFirstName,
      userLastName,
      affiliationId,
      AffiliationRoleFe.DATA_OWNER,
      affiliationEmail,
      affiliationStatus,
      activationDate,
      deactivationDate,
      lastLoggedAt,
      signInMethods,
      mfaEnabled
    )
    this.managementCompanyId = managementCompanyId
    this.supplierCompanyname = supplierCompanyname
    this.supplierCountryCode = supplierCountryCode
    this.supplierIndustryGroup = supplierIndustryGroup
    this.supplierIndustry = supplierIndustry
    this.supplierWebsite = supplierWebsite
    this.partnershipStatus = partnershipStatus
  }

  public static fromTransfer(transfer: any): ContactFe{
    let signInMethods = transfer['signInMethods']?.map(signInMethod => SignInMethodConversionFe.fromTransfer(signInMethod))
    let contact = new ContactFe(
      transfer['affiliationCompanyId'],
      transfer['userId'],
      transfer['userFirstName'],
      transfer['userLastName'],
      transfer['affiliationId'],
      transfer['affiliationEmail'],
      transfer['affiliationStatus'],
      transfer['activationDate'],
      transfer['deactivationDate'],
      transfer['lastLoggedAt'],
      transfer['managementCompanyId'] ? transfer['managementCompanyId'] : transfer['affiliationCompanyId'],
      transfer['supplierCompanyname'],
      transfer['supplierCountryCode'],
      transfer['supplierIndustryGroup'],
      transfer['supplierIndustry'],
      transfer['supplierWebsite'],
      transfer['partnershipStatus'],
      signInMethods,
      transfer['mfaEnabled']
    );
    return contact;
  }

  public noOfOngoingRequests(): number {
    let ongoingRequest = this.dataRequests.filter(r => r.status == REQUEST_TASK_STATUS.IN_PROGRESS || r.status == REQUEST_TASK_STATUS.CREATED)
    return ongoingRequest.length
  }

  public getCompanyName(workspace: AccessibleCompanyFe): string {
    return this.supplierCompanyname ? this.supplierCompanyname : workspace.companyName
  }
}