import { ResizedEvent } from "angular-resize-event";
import { CategoryOptionFeNew } from "./CategoryOptionFeNew";
import { ChartTypeFe } from "./ChartTypeFe";
import { SelectedCategoryFe } from "./SelectedCategoryFe";
import { InsightFe } from "../insight/InsightFe";
import { ChartOptionsFeNew } from "./ChartOptionsFeNew";

type LegendPosition = 'center' | 'top' | 'bottom' | 'left' | 'right' | 'chartArea';

export class ChartSettingFeNew {
  public options: ChartOptionsFeNew; 
  public principalComparisonTime: boolean;
  public principalCategoryFieldIndex: number = -1;
  public timeScaleIndex: number; 
  public timeValue: string;
  public categories: SelectedCategoryFe[];
  public chartType: ChartTypeFe;
  public chartTypeId: string;
  public width: number = 200; 
  public height: number = 150;
  public newWidth: number; 
  public newHeight: number;
  public titleOption: string = 'kpi';
  public title: string | undefined = undefined;
  public legendPosition: LegendPosition = 'top'
  public firstTimeDraw = true;

  private constructor() {}

  public toTransfer(): ChartSettingFeNew {
    let transfer = new ChartSettingFeNew()
    transfer.principalComparisonTime = this.principalComparisonTime
    transfer.principalCategoryFieldIndex = this.principalCategoryFieldIndex
    transfer.timeScaleIndex = this.timeScaleIndex
    transfer.timeValue = this.timeValue
    transfer.categories = this.categories?.map(c => new SelectedCategoryFe(c.field, c.value))
    transfer.chartTypeId = this.chartType.toTransfer()
    transfer.width = this.newWidth ? this.newWidth : this.width
    transfer.height = this.newHeight ? this.newHeight : this.height
    transfer.titleOption = this.titleOption
    transfer.title = this.title
    return transfer
  }
  
  public static fromTransfer(transfer: any): ChartSettingFeNew {
    if (transfer) {
      let cs = new ChartSettingFeNew()
      cs.principalComparisonTime = transfer.principalComparisonTime
      cs.principalCategoryFieldIndex = transfer.principalCategoryFieldIndex
      cs.timeScaleIndex = transfer.timeScaleIndex
      cs.timeValue = transfer.timeValue
      cs.categories = transfer.categories?.map(c => new SelectedCategoryFe(c.field, c.value))
      cs.chartType = ChartTypeFe.fromTransfer(transfer.chartTypeId)
      cs.width = transfer.width
      cs.height = transfer.height
      cs.newWidth = transfer.width
      cs.newHeight = transfer.height
      cs.titleOption = (transfer.titleOption) ? transfer.titleOption : 'kpi'
      cs.title = transfer.title
      return cs  
    } else {
      return undefined
    }
  }

  public static buildInstance(insight: InsightFe): ChartSettingFeNew {
    let options = ChartOptionsFeNew.buildInstance(insight)
    
    let setting = new ChartSettingFeNew()
    setting.options = options
    if (insight.isTimed()) {
      setting.principalComparisonTime = true
      setting.principalCategoryFieldIndex = -1
      setting.timeScaleIndex = 0
      setting.timeValue = options.timeOptions[0].values[0]
    } else {
      setting.principalComparisonTime = false
      setting.timeScaleIndex = -1
      setting.timeValue = undefined
      if (options.categoryOptions.length > 0) {
        setting.principalCategoryFieldIndex = 0
      }
    }
    setting.categories = options.categoryOptions?.map(c => new SelectedCategoryFe(c.field, c.values[0]))
    setting.chartType = options.chartTypes[0]
    return setting
  }

  public selectTitle(insight: InsightFe): string | undefined {
    if (this.titleOption == 'custom') {
      return this.title
    } else if (this.titleOption == 'kpi') {
      return insight.title
    } else if (this.titleOption == 'no') {
      return undefined
    }
  }

  public titleOptionText(): string | undefined {
    if (this.titleOption == 'custom') {
      return 'Custom'
    } else if (this.titleOption == 'kpi') {
      return 'KPI'
    } else if (this.titleOption == 'no') {
      return '---'
    }
  }

  public isTitleCustom(): boolean {
    return (this.titleOption == 'custom')
  }

  public isTitleKpi(): boolean {
    return (this.titleOption == 'kpi')
  }

  public isNoKpi(): boolean {
    return (this.titleOption == 'no')
  }

  public setTitleOption(option: string): void {
    this.titleOption = option
  }

  public setTimeScaleIndex(index: number) {
    this.timeScaleIndex = index
    this.timeValue = this.options.timeOptions[this.timeScaleIndex].values[0]
  } 

  public setTimeValue(value: string) {
    this.timeValue = value
  } 

  public timeOption() {
    return this.options.timeOptions[this.timeScaleIndex]
  }

  public timeValues() {
    return this.options.timeOptions[this.timeScaleIndex].values
  }

  public categoryValue(catField: string) {
    let selectedCat = this.categories.find(c => c.field == catField)
    return selectedCat?.value
  }

  public setCategoryValue(categoryFiled: string, value: string ) {
    let cat = this.categories.find(c => c.field == categoryFiled)
    if (cat) {
      cat.value = value
    }
  }

  public setChartType(type: ChartTypeFe) {
    this.chartType = type
  }

  public principalComparisionText(): string {
    if(this.principalComparisonTime) {
      return 'Time'
    } else if (this.principalCategoryFieldIndex > -1) {
      let cat = this.options.categoryOptions[this.principalCategoryFieldIndex]
      return cat?.getLabel()
    }
  }

  public selectPrincipalComparisonTime() {
    this.principalComparisonTime = true
    this.principalCategoryFieldIndex = -1
    if (!this.timeValue && this.timeScaleIndex >= 0) {
      this.timeValue = this.options.timeOptions[this.timeScaleIndex].values[0]
    }
  }

  public selectPrincipalCategoryFieldIndex(index: number) {
    this.principalComparisonTime = false
    this.principalCategoryFieldIndex = index
    if (!this.timeValue && this.timeScaleIndex >= 0) {
      this.timeValue = this.options.timeOptions[this.timeScaleIndex].values[0]
    }
  }

  public nonPricipalCategoryOptions(): CategoryOptionFeNew[] {
    let categoryOptions = this.options.categoryOptions
    if (this.principalCategoryFieldIndex > -1) {
      categoryOptions = categoryOptions.filter((catOption, i) => i != this.principalCategoryFieldIndex )
    }
    return categoryOptions
  }

  public onResized(event: ResizedEvent): void {
    this.newWidth = event.newRect.width ;
    this.newHeight = event.newRect.height;
    if (this.firstTimeDraw) {
      this.firstTimeDraw = false
    }
 }

}