
import { FileDataFe } from "src/app/model/file/FileDataFe";
import { ColumnSchemaFe } from "src/app/model/schema/ColumnSchemaFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { DataGridTableData } from "../projects/data-grid-ui/model/DataGridTableData";
import { DataGridTableDef } from "../projects/data-grid-ui/model/DataGridTableDef";
import { DataGridTaxonomyEntity } from "../projects/data-grid-ui/model/DataGridTaxonomyEntity";
import { ExcelViewerServiceInterface } from "../projects/data-grid-ui/service/ExcelViewerServiceInterface";
import { ExcelViewerObserverInterface } from "../projects/data-grid-ui/service/ExcxelViewerObserverInterface";

export class ExcelViewerIntegrationService implements ExcelViewerServiceInterface {
  stateService: StateServiceFe
  fileData: FileDataFe
  languageService: LanguageService

  constructor(stateService: StateServiceFe, fileData: FileDataFe, languageService: LanguageService) {
    this.stateService = stateService
    this.fileData = fileData
    this.languageService = languageService
  }

  markDataSelected(isSelected: boolean): void {
  }
  registerObserver(observer: ExcelViewerObserverInterface): void {
  }

  async getTaxonomyEntities(): Promise<DataGridTaxonomyEntity[]> {
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
    let taxonomy = depTaxonomy
    let tableEntiteies = taxonomy.entities.filter(e => e.table == true)
    let entities = tableEntiteies.map(e => {
      let entity = new DataGridTaxonomyEntity(e.key, taxonomy.categoryFQN(e.key), e.getLabel(), e.ordinal)
      return entity
    })
    return entities
  }

  async getEntityTableDef(entity: DataGridTaxonomyEntity): Promise<DataGridTableDef> {
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
    let taxonomy = depTaxonomy
    let e = taxonomy.entityByKey(entity.key)
    let tableDef = new DataGridTableDef()
    let schema: ColumnSchemaFe[] = e.columnSchemas()
    tableDef.dataSchema = schema.map(s => s.toDataGridColumnSchema())
    return tableDef
  }

  async exportTableData(tableData: DataGridTableData): Promise<void> {
    return
  }

  async getExcelBlob(): Promise<Blob> {
    return this.fileData.blob
  }

  async getFileName(): Promise<string> {
    return this.fileData.label
  }

}