import { RouterFe } from 'src/app/route/RouterFe';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { FileDataFe } from 'src/app/model/file/FileDataFe';
import { TableDataFe } from 'src/app/model/schema/TableDataFe';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnSchemaFe } from 'src/app/model/schema/ColumnSchemaFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { TableStorageServiceFe } from 'src/app/services/TableStorageServiceFe';
import { DatahubService } from '../data-hub/DatahubService';
import { Subscription } from 'rxjs';
import { DataGridTableData } from '../projects/data-grid-ui/model/DataGridTableData';
import { DataGridTableDef } from '../projects/data-grid-ui/model/DataGridTableDef';
import { DataGridTaxonomyEntity } from '../projects/data-grid-ui/model/DataGridTaxonomyEntity';
import { DataExtractorObserverInterface } from '../projects/data-grid-ui/service/DataExtractorObserverInterface';
import { DataExtractorServiceInterface } from '../projects/data-grid-ui/service/DataExtractorServiceInterface';
import { ExcelViewerServiceInterface } from '../projects/data-grid-ui/service/ExcelViewerServiceInterface';
import { ExcelViewerObserverInterface } from '../projects/data-grid-ui/service/ExcxelViewerObserverInterface';

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent extends AbstractLanguageComponent implements OnInit, ExcelViewerServiceInterface, DataExtractorServiceInterface {
  @Output() closeModalEvent = new EventEmitter<boolean>();
  @Output() dataExtractedEvent = new EventEmitter<TableDataFe>();
  @Input() inputFile: FileDataFe 
  @Input() destTable: TableDataFe
  @Input() mode ='extract' //view, extract
  subscription = new Subscription();

  constructor(private backendService: RouterFe, private stateService: StateServiceFe, private tableStorageService: TableStorageServiceFe,
    private domSanitizer: DomSanitizer, languageService: LanguageService, private datahubService: DatahubService) {
    super(languageService)
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  async ngOnInit(): Promise<void> {
    await this.inputFile.loadFile(this.stateService, this.domSanitizer, this.tableStorageService, this.backendService)
    this.subscription.add(this.datahubService.exportFile.subscribe(download => {
      this.inputFile.setExportType(this.datahubService.exportFileType)
      this.inputFile.saveFile()
    }));
  }
  
  async convertToImg(fileData: FileDataFe) {
    await (this.observer as DataExtractorObserverInterface)?.convertToImg();   
    this.setCropperViewer();
  }
  
  convertCroppedToText(fileData: FileDataFe): void  {
    (this.observer as DataExtractorObserverInterface).convertCroppedToText();
    this.setExtractorViewer();
  }

  addNewColumn(){
    return (this.observer as DataExtractorObserverInterface).addNewColumn()
  }

  cancel() {
    this.closeModalEvent.emit(true)
  }

  /**
   * ExcelViewerServiceInterface methods
   */
  observer: ExcelViewerObserverInterface
  isExcelDataMarked: boolean = false
  @Input() isMappingToTaxonomy = true;

  registerObserver(observer: ExcelViewerObserverInterface | DataExtractorObserverInterface): void {
    this.observer = observer  
  }

  async getTaxonomyEntities(): Promise<DataGridTaxonomyEntity[]> {
    if (this.destTable) {
      if(this.isMappingToTaxonomy){
        let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
        let taxonomy = depTaxonomy
        let taxonomyKey = this.destTable.key
        let e = taxonomy.entityByKey(taxonomyKey)
        let entity = new DataGridTaxonomyEntity(e.key, taxonomy.categoryFQN(e.key), e.getLabel(), e.ordinal)
        return [entity]
      }else{
        return []
      }
    } else {
      let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
      let taxonomy = depTaxonomy
      let tableEntiteies = taxonomy.entities.filter(e => e.table == true)
      let entities = tableEntiteies.map(e => {
        let entity = new DataGridTaxonomyEntity(e.key, taxonomy.categoryFQN(e.key), e.getLabel(), e.ordinal)
        return entity
      })
      return entities
    }
  }

  async getEntityTableDef(entity: DataGridTaxonomyEntity): Promise<DataGridTableDef> {
    let tableDef = new DataGridTableDef()
    if(this.isMappingToTaxonomy){
      let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
      let taxonomy = depTaxonomy
      let e = taxonomy.entityByKey(entity.key)
      let schema: ColumnSchemaFe[] = e.columnSchemas()
      tableDef.dataSchema = schema.map(s => s.toDataGridColumnSchema())
    }else{
      let schema: ColumnSchemaFe[] = this.destTable.dataSchema
      tableDef.dataSchema = schema.map((s: ColumnSchemaFe) => { 
        return s.toDataGridColumnSchema()})
    }
    return tableDef
  }

  async exportTableData(dgtd: DataGridTableData): Promise<void> {
    let tableData = TableDataFe.fromDataGridTableData(dgtd)
    this.dataExtractedEvent.emit(tableData)
  }

  async getExcelBlob(): Promise<Blob> {
    return this.inputFile.blob
  }

  async getFileName(): Promise<string> {
    return this.inputFile.label
  }

  markDataSelected(isSelected:boolean): void {
    this.isExcelDataMarked = isSelected
  }

  /**
   * DataExtractorServiceInterface methods
   */

   tableData : TableDataFe;
   viewer : string;
 
   getViewer(): string {
     return this.viewer
   }
 
   setMainViewer(){
     this.viewer='main';
     this.inputFile.isConvertedToImg = false;
     this.inputFile.isCropped = false;
   }
 
   setCropperViewer(){
     this.viewer='cropper';
     this.inputFile.isConvertedToImg = true;
     this.inputFile.isCropped = false;
   }
 
   isCroppperViewer(): boolean {
     return this.viewer == 'cropper'
   }
 
   setExtractorViewer(){
     this.viewer= 'extractor';
     this.inputFile.isCropped = true;
   }
 
   isExtractorViewer(): boolean {
     return this.viewer == 'extractor'
   }
   
   async getPDFBlob(): Promise<Blob> {
     if(this.inputFile.isPdf()){
       this.setMainViewer();
       return this.inputFile.blob 
     }
      else
       return null 
   }
 
   async getImageBlob(): Promise<Blob> {
     if(this.inputFile.isImage()){
       this.setMainViewer();  
       return this.inputFile.blob
     }
     else
       return null;  
  }


}