import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import {
  Calculation,
  CloseBracketOperator,
  ControlOperator,
  DivideOperator,
  EmissionFactorOperand,
  FixedNumberOperand,
  MinusOperator,
  OpenBracketOperator,
  Operand,
  Operator,
  PlusOperator,
  SelectDataInputOperand,
  TimesOperator
} from "../../model/CalculationBuilderModels";
import _ from "lodash";
import { UnitFe } from "src/app/components/unit-systems/model/UnitFe";
import { UnitsByMeasurementType } from "src/app/components/unit-systems/model/UnitsByMeasurementType";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { groupUnitsByMeasurementTypeAndSystem } from "src/app/components/unit-systems/model/utils";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Component({
  selector: "formula-operator-dropdown",
  templateUrl: "./formula-operator-dropdown.component.html",
  styleUrls: ["./formula-operator-dropdown.component.scss"]
})
export class FormulaOperatorDropdownComponent extends AbstractLanguageComponent implements OnInit {
  @Input() showDatapoints: boolean = true;
  @Input() calculation: Calculation;
  @Input() tokenIdx: String;
  @Input() addFixedNumberValue: String;
  @Input() addFixedNumberUnit: UnitFe;
  @Input() includeMeasurementTypes: Set<any> = new Set();
  showAddFixedNumberInput: Boolean = false;

  unitInput = { selectedUnit: {} };
  units: UnitFe[] = [];
  customUnits: UnitFe[] = [];
  unitsByMeasurementType: UnitsByMeasurementType[] = [];

  constructor(private stateService: StateServiceFe, public languageService: LanguageService, private modalRef: BsModalRef, private modalService: BsModalService) {
    super(languageService);
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits();
    });
  }

  async ngOnInit() {
    await this.loadUnits();
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units.filter((unit) => unit.shouldDisplay));
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.unitInput.selectedUnit = this.addFixedNumberUnit ?? {};
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard && unit.shouldDisplay);
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = "modal-xl") {
    let config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: className
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config);
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  setSelectDataInputOperand({ tokenIdx }) {
    const token: Operand = new SelectDataInputOperand();
    this.calculation.formula[tokenIdx] = token;
    const nextToken = this.calculation.formula[tokenIdx + 1];

    /*
        Order of operations matter here, 
        since arbitrarily inserting elements
        will change expected values of tokenIdx
      */
    if (_.isEmpty(nextToken)) {
      const controlToken = new ControlOperator();
      if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
        this.calculation.formula.push(controlToken);
      }
    }

    if (tokenIdx > 0) {
      const prevToken = this.calculation.formula[tokenIdx - 1];
      if (prevToken.type == "operand") {
        this.addControlBefore({ tokenIdx });
      }
    }
  }

  setOperator({ tokenIdx, operator }) {
    let token: Operator;
    switch (operator) {
      case "plus":
        token = new PlusOperator();
        break;
      case "minus":
        token = new MinusOperator();
        break;
      case "times":
        token = new TimesOperator();
        break;
      case "divide":
        token = new DivideOperator();
        break;
      case "openBracket":
        token = new OpenBracketOperator();
        break;
      case "closeBracket":
        token = new CloseBracketOperator();
        break;
    }
    this.calculation.formula[tokenIdx] = token;
    const nextToken = this.calculation.formula[tokenIdx + 1];
    if (_.isEmpty(nextToken)) {
      const controlToken = new ControlOperator();
      if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
        this.calculation.formula.push(controlToken);
        return;
      }
    }
  }

  addControlBefore({ tokenIdx }) {
    const token: Operator = new ControlOperator();
    if (tokenIdx == 0) {
      this.calculation.formula.unshift(token);
      return;
    }
    this.calculation.formula.splice(tokenIdx, 0, token);
  }

  addControlAfter({ tokenIdx }) {
    const token: Operator = new ControlOperator();
    if (tokenIdx == this.calculation.formula.length - 1) {
      this.calculation.formula.push(token);
      return;
    }
    this.calculation.formula.splice(tokenIdx + 1, 0, token);
  }

  removeToken({ tokenIdx }) {
    this.calculation.formula.splice(tokenIdx, 1);
  }

  addFixedNumber({ tokenIdx }) {
    const value = this.addFixedNumberValue;
    const unit = this.unitInput.selectedUnit;

    if (!_.isUndefined(value) && !_.isNull(value)) {
      const token: Operand = new FixedNumberOperand({ value, unit });
      this.calculation.formula[tokenIdx] = token;

      /*
        Order of operations matter here, 
        since arbitrarily inserting elements
        will change expected values of tokenIdx
      */
      const nextToken = this.calculation.formula[tokenIdx + 1];
      if (_.isEmpty(nextToken)) {
        const controlToken = new ControlOperator();
        if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
          this.calculation.formula.push(controlToken);
        }
      }

      if (tokenIdx > 0) {
        const prevToken = this.calculation.formula[tokenIdx - 1];
        if (prevToken.type == "operand") {
          this.addControlBefore({ tokenIdx });
        }
      }
    }
  }

  startAddFixedNumber() {
    this.showAddFixedNumberInput = true;
  }

  addEmissionFactor({ emissionFactor, tokenIdx }) {

    if (!_.isUndefined(emissionFactor)) {
      const token: Operand = new EmissionFactorOperand({ emissionFactor });
      this.calculation.formula[tokenIdx] = token;

      /*
        Order of operations matter here, 
        since arbitrarily inserting elements
        will change expected values of tokenIdx
      */
      const nextToken = this.calculation.formula[tokenIdx + 1];
      if (_.isEmpty(nextToken)) {
        const controlToken = new ControlOperator();
        if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
          this.calculation.formula.push(controlToken);
        }
      }

      if (tokenIdx > 0) {
        const prevToken = this.calculation.formula[tokenIdx - 1];
        if (prevToken.type == "operand") {
          this.addControlBefore({ tokenIdx });
        }
      }
    }
  }
}
