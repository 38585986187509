import { ContactFe } from "../../user/ContactFe"
import { ContactInvitationFe } from "./ContactInvitationFe"

export class SupplierCompanyFe {
  managementCompanyId: string
  supplierCompanyId: string
  supplierCompanyname: string
  supplierCountryCode: string
  supplierIndustryGroup: string
  supplierIndustry: string
  supplierWebsite: string
  partnershipStatus: string
  partnershipActivation: Date | null
  partnershipInactivationDate: Date | null
  dataOwners: ContactFe[] = []
  doInvitations: ContactInvitationFe[] = []
  deactivatedDOs: ContactFe[] = []

  selectedOption = 'ACTIVE_USERS'

  constructor(
    managementCompanyId: string,
    supplierCompanyId: string,
    supplierCompanyname: string,
    supplierCountryCode: string,
    supplierIndustryGroup: string,
    supplierIndustry: string,
    supplierWebsite: string,
    partnershipStatus: string,
    partnershipActivation: Date | null,
    partnershipInactivationDate: Date | null
  ) {
    this.managementCompanyId = managementCompanyId
    this.supplierCompanyId = supplierCompanyId
    this.supplierCompanyname = supplierCompanyname
    this.supplierCountryCode = supplierCountryCode
    this.supplierIndustryGroup = supplierIndustryGroup
    this.supplierIndustry = supplierIndustry
    this.supplierWebsite = supplierWebsite
    this.partnershipStatus = partnershipStatus
    this.partnershipActivation = partnershipActivation
    this.partnershipInactivationDate = partnershipInactivationDate
  }

  public static fromTransfer(transfer: any): SupplierCompanyFe{
    let supplier = new SupplierCompanyFe(
      transfer.managementCompanyId,
      transfer.supplierCompanyId,
      transfer.supplierCompanyname,
      transfer.supplierCountryCode,
      transfer.supplierIndustryGroup,
      transfer.supplierIndustry,
      transfer.supplierWebsite,
      transfer.partnershipStatus,
      transfer.partnershipActivation,
      transfer.partnershipInactivationDate
    )
    return supplier;
  }
}