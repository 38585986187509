<div id="custom-modal-dialog">
  <div class="modal-header light-purple-bg">
    <h4 class="modal-title my-0 ms-2 py-0 my-auto text-white">{{ locale("locale_key.general.chat_box.title.conversation") }}</h4>
    <button type="button" class="btn close ms-auto" (click)="closeChat()">
      <span aria-hidden="true"><i class="las la-times text-white fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body chatBox scrollContainer scroll-y text-center flex-grow-1 d-flex flex-column">
    <div class="card-content">
      <div class="card-body">
        <ng-container *ngFor="let activity of requestTask.timelineItems">
          <div class="row flex-grow-1 px-2" *ngIf="requestService.isCreatedRequest(activity)">
            <span class="text-center mx-auto px-2 pt-3">------ {{ locale("locale_key.general.chat_box.request_sent_on", {date: activity.getSubmissionTime()}) }} ------ </span>
          </div>
          <div class="chat-right" *ngIf="isSubmitterDO(activity)">
            <div class="text-right w-100 pt-3 ms-auto me-3" style="text-align: right !important">
              <span class="text-muted me-2 ms-auto">{{ activity.getSubmissionTime() }}</span><span class="d-inline">{{ activity.submitterFirstName }}</span>
              <span class="me-2"><div class="round-icon d-inline"><i class="la la-user white fs-4"></i></div></span>
            </div>
            <div class="chat-body ms-auto chat-message-right">
              <div class="m-1" *ngIf="requestService.isSubmitAnswer(activity)">
                <p class="text-white mb-0 border-b p-2" *ngIf="activity.message?.length > 0">{{ activity.message }}</p>
                <p class="text-white m-3 bold"> <i class="la la-envelope-o mt-2 me-1"></i> {{requestTitle}} </p>
              </div>
              <p class="m-1 p-2" *ngIf="requestService.isMessage(activity) && activity.message?.length > 0">{{ activity.message }}</p>
            </div>
          </div>
          <div class="chat-left" *ngIf="!isSubmitterDO(activity) && !requestService.isCreatedRequest(activity)">  
            <div class="text-left w-100 pt-3 me-auto ms-3" style="text-align: left !important">
              <span *ngIf="activity.submitterAffId == 'SYSTEM_AFF'">
                <span><div class="round-icon d-inline"><i class="la la-desktop white fs-4"></i></div></span>
                <span class="d-inline me-2">{{ locale("locale_key.general.user_role.system") }}</span>
                <span class="text-muted me-2 ms-auto">{{ activity.getSubmissionTime() }}</span>
              </span>
              <ng-container *ngIf="activity.submitterAffId != 'SYSTEM_AFF'">
                <span><div class="round-icon d-inline"><i class="la la-user white fs-4"></i></div></span>
                <span class="d-inline me-2">{{ activity.submitterFirstName }}</span>
                <span class="text-muted me-2 ms-auto">{{ activity.getSubmissionTime() }}</span>
              </ng-container>
            </div>
            <span class="fs-4 ps-3 my-auto">
              <i class="{{ activity.icon }}" *ngIf="!requestService.isMessage(activity)"></i>
            </span>
            <div class="chat-body bg-main-light chat-message-left">
              <div class="m-1" *ngIf="!requestService.isMessage(activity)">
                <p class="p-2 m-0 bold"> {{ activity.activityHistoryText() }}</p>
                <p class="border-t mb-0 p-2" *ngIf="activity.message?.length > 0">{{ activity.message }}</p>
              </div>
              <p class="m-1 p-2" *ngIf="requestService.isMessage(activity) && activity.message?.length > 0">{{ activity.message }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row flex-grow-1 px-2">
    </div>
    <div class="row mt-auto mx-3 py-3">
      <textarea class="form-control" rows="5" [(ngModel)]="message" placeholder="Type message"></textarea>
    </div>
  </div>
  <div class="modal-footer mt-auto text-right p-2 bg-white thin-border">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn primary-button my-auto me-2 ms-auto" [disabled]="!message || message?.length == 0" (click)="sendMessage()">{{ locale("locale_key.pages.data_request.button.send_message") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</div>