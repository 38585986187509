import { Component } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { FlowChartOrganizationEntity } from "../../model/FlowChartOrganizationEntity";

@Component({
    selector:'app-deleteNode-modal',
    templateUrl:'./deleteNode.modal.html'
})

export class DeleteNodeModalComponent extends AbstractLanguageComponent{

    delete$= new Subject<string>();
    method="1";
    node!: FlowChartOrganizationEntity;

    //to check if there is similar nodes anywhere in chart
    similarNodeExists = false;
    hasChildren = false;

    constructor(private modalRef: BsModalRef, languageService: LanguageService){
        super(languageService);
    }
    

    deleteNode(){
        this.delete$.next(this.method);
        this.closeModal();
    }

    closeModal(){
        this.modalRef.hide();
        document.body.classList.remove('modal-open');
    }

}