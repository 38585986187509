import { OrganizationAttributeFe } from "./OrganizationAttributeFe";
import { OrganizationInfoFe } from "./OrganizationInfoFe";

export class OrganizationEntityFe {
  public ordinal: number
  public key: string
  public label: string
  public attributes: OrganizationAttributeFe[]
  public parentKeys: string[]
  public deletedAttributes: OrganizationAttributeFe[] = []
  public deletedParents: string[] = []
  public addedParents: string[] = []
  public oldLabel: string
  public deployed = true

  public labelMatch: boolean = true
  public attributesMatch: boolean = true
  public parentsMatch: boolean = true
  public modified = false

  constructor(ordinal: number, key: string, label: string, attributes: OrganizationAttributeFe[], parentKeys: string[]) {
    this.ordinal = ordinal
    this.key = key
    this.label = label
    this.attributes = attributes
    this.parentKeys = parentKeys
  }

  public getParents(orgInfo: OrganizationInfoFe): OrganizationEntityFe[] {
    let parents: OrganizationEntityFe[] = []
    this.parentKeys.forEach(pk => {
      let parentEntitey = orgInfo.entities.find(e => e.key == pk)
      if (parentEntitey) {
        parents.push(parentEntitey)
      }
    })
    return parents
  }

  public getAllAncestors(orgInfo: OrganizationInfoFe): OrganizationEntityFe[] {
    let toExploreEnities: OrganizationEntityFe[] = [this]
    let ancestors: OrganizationEntityFe[] = []
    while (toExploreEnities.length != 0) {
      let entity = toExploreEnities[0]
      toExploreEnities = toExploreEnities.filter(e => e.key != entity.key)
      let isInAncestor = ancestors.find(e => e.key == entity.key) 
      if (!isInAncestor) {
        entity.parentKeys.forEach(pk => {
          let parentEntitey = orgInfo.entities.find(e => e.key == pk)
          if (parentEntitey) {
            toExploreEnities.push(parentEntitey)
          }
        })
        if (entity.key != this.key) {
          ancestors.push(entity)  
        }
      }
    }
    return ancestors
  }

  public static fromTransfer(transfer: any): OrganizationEntityFe {
    let atts = transfer.attributes.map(beAtt => OrganizationAttributeFe.fromTransfer(beAtt))
    let entity = new OrganizationEntityFe(transfer.ordinal, transfer.key, transfer.label, atts, transfer.parentKeys)
    return entity
  }

  public adjustKey(availableKeys: string[]) {
    let i = 1
    let baseKey = 'nokey'
    if (this.label && this.label !== '') {
      baseKey = this.label.replace(/[^a-zA-Z0-9_]/gi, '_').toLowerCase()
    }

    let baseMatch = availableKeys.find(key => key == baseKey)

    let key;
    if (!baseMatch) {
      key = baseKey
    } else {
      let keyMatch = false
      while (!keyMatch) {
        key = baseKey + i
        let akey = availableKeys.find(akey => akey == key)
        if (!akey) {
          keyMatch = true
        } else {
          i++
        }
      }  
    }
    this.key = key
  }

  diffDeployment(myParents: OrganizationEntityFe[], deployedEnity: OrganizationEntityFe | undefined, deployedEntityParents: OrganizationEntityFe[]) {    
    this.deletedAttributes = []
    this.addedParents = []
    this.deletedParents = []
    this.oldLabel = undefined

    if (deployedEnity) {
      this.deployed = true

      if (this.label != deployedEnity.label) {
        this.labelMatch = false
        this.oldLabel = deployedEnity.label
      }
      
      deployedEnity.attributes.forEach(da => {
        let attr = this.attributes.find(a => a.key == da.key)
        if (!attr) {
          this.deletedAttributes.push(da)
        }
      })
  
      this.attributes.forEach(a => {
        let da = deployedEnity.attributes.find(da => da.key == a.key)
        a.diffDeployment(da)
      })
  
      let modifiedAttr = this.attributes.find(a => !a.deployed || a.modified)
  
      this.attributesMatch = (this.deletedAttributes.length == 0) && (modifiedAttr ? false : true) 
  
      deployedEntityParents.forEach(dp => {
        let mp = myParents.find(p => p.key == dp.key)
        if (!mp) {
          this.deletedParents.push(dp.label)
        }
      })

      myParents.forEach(mp => {
        let dp = deployedEntityParents.find(p => p.key == mp.key)
        if (!mp) {
          this.addedParents.push(mp.label)
        }
      })

      this.parentsMatch = (this.deletedParents.length == 0) && (this.addedParents.length == 0)

      this.modified = (this.labelMatch && this.attributesMatch && this.parentsMatch) ? false : true
  
    } else {
      this.deployed = false
    }
  }

}