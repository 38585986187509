import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { RequestGroupFe } from "../../../model/data-suppliers/request/RequestGroupFe";
import { AbstractActivityFe } from "../../../model/data-suppliers/timeline/AbstractActivityFe";
import { RequestEntryDataGridIntegrationService } from "../RequestEntryDataGridIntegrationService";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import * as UUID from "uuid";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { DateUtil } from "src/app/utils/DateUtil";
import { TableStorageServiceFe } from "src/app/services/TableStorageServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { RejectSubmissionActivityFe } from "src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe";
import { AcceptSubmissionActivityFe } from "src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe";
import { CloseRequestActivity_AddFe } from "src/app/model/data-suppliers/timeline/add/CloseRequestActivity_AddFe";
import { SubmitMessageActivity_AddFe } from "src/app/model/data-suppliers/timeline/add/SubmitMessageActivityFe_AddFe";
import { DomSanitizer } from "@angular/platform-browser";
import { FileTypeFe } from "src/app/model/file/FileTypeFe";
import { AttachmentFe } from "src/app/model/details/AttachmentFe";
import { RequestFe, REQUEST_TASK_STATUS } from "src/app/model/data-suppliers/request/RequestFe";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { SubmitAnswersActivityFe } from "src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe";
import { NgxCaptureService } from "ngx-capture";
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { RequestFolder_AddFe } from "src/app/model/data-suppliers/request/add/RequestFolder_AddFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { RequestFolderFe } from "src/app/model/data-suppliers/request/RequestFolderFe";
import { SchedulingOccurenceGeneratorFe, SchedulingOccurenceGeneratorFromTypes } from "src/app/model/scheduling/SchedulingOccurenceGeneratorFe";
import moment from "moment";
import { ViewFe } from "src/app/model/subject/ViewFe";
import { RequestPeriodFe } from "src/app/model/data-suppliers/request/recurrence/RequestPeriodFe";
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { ContactFe } from "src/app/model/user/ContactFe";
import { Subject } from "rxjs";
import { FileDataFe } from "src/app/model/file/FileDataFe";
import { DataGridServiceInterface } from "../../projects/data-grid-ui/service/DataGridServiceInterface";

enum ModalNames {
  listRequestFoldersModal = "listRequestFoldersModal",
  createRequestFolderModal = "createRequestFolderModal",
}

@Component({
  selector: "app-requests",
  templateUrl: "./requests.component.html",
  styleUrls: ["./requests.component.scss","../../common/data-suppliers.component.scss"]
})
export class RequestsComponent extends AbstractLanguageComponent implements OnInit {
  @ViewChild('addQuestionaireModal') addQuestionaireModal: TemplateRef<any>;
  @ViewChild('confirmClose', { static: true }) confirmClose: TemplateRef<any>;
  @ViewChild('addFolder') addFolder: ModalDirective;
  exportResult = new Subject<boolean>()
  viewNo = 1;
  allRequestGroups: RequestGroupFe[] = [];
  requestGroups: RequestGroupFe[] = [];
  selectedRequestGroup: RequestGroupFe;
  selectedRequest: RequestFe;
  selectedRequestPeriod: RequestPeriodFe;
  detailsData: any;
  activityMessage = "";
  reviewMessage = "";
  inProgress = true;
  taxonomyInfo: TaxonomyInfoFe = undefined;
  loadingInfo = false;
  loadingInProgress = false;
  editSettings = false;
  requestGroupToBeDuplicated: RequestGroupFe | null
  // Export functionality
  typeOfExport = 'img';

  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  

  /*
    BEGIN : Request Folder Functionality  
  */

  selectedRequestGroupIDsForAddFolder = [];
  addFolderName = "";
  requestFolders: RequestFolderFe[] = [];
  addingFolder = false;

  /*
    END : Request Folder Functionality  
  */

  requestsFilterType = "allRequests";
  requestsFilterFolder: RequestFolderFe;
  requestsFilterName = this.locale("locale_key.pages.data_request.portal.option.see_all_requests");
  requestsFilterText = "";

  filteredRequestGroups: RequestGroupFe[] = []
  searchKeyword: string = '';
  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date'), value: 'createdDate', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date_desc'), value: 'createdDateDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline'), value: 'deadline', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline_desc'), value: 'deadlineDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]

  selectedStatus = null;
  customMultipleFilters: {name: string, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number}[] = []
  filteredCompanies: SupplierCompanyFe[] = []
  filteredDataReporters: ContactFe[] = []
  filteredEntities: EntityFe[] = []
  

  pageToolbar = [
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.back'),
        longLabel: this.locale('locale_key.general.toolbar.button.back'),
        tooltip: this.locale('locale_key.general.toolbar.button.back'),
        icon: "la la-arrow-left fs-4",
        actionName: "back_from_view2",
        visible: () => this.viewNo === 2,
        disabled: () => this.loadingInfo || this.loadingInProgress
      },
      {
        shortLabel: this.locale('locale_key.pages.data_request.toolbar.add'),
        longLabel: this.locale('locale_key.general.toolbar.button.tooltip.new_request'),
        tooltip: this.locale('locale_key.general.toolbar.button.tooltip.new_request'),
        icon: "la la-plus fs-4",
        actionName: "open_add_questionaire_modal",
        visible: () => this.viewNo === 1,
        disabled: false
      },
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_results",
        visible: () => this.viewNo == 2,
        disabled: false,
      }
    ],
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info fs-4", actionName: "toggle_quick_tips", visible: () => true, disabled: false }
    ],
  ];


  url: string = '';


  mappingForm = new FormGroup({
    taxonomy: new FormGroup({
      item0: new FormControl("", [Validators.required]),
      item1: new FormControl("", [Validators.required]),
      item2: new FormControl("", [Validators.required]),
    }),
    mappings: new FormGroup({}),
  });
  selectedMappedTaxonomy: EntityFe = null;
  startRequestCreation = false;
  isReviewMode = false;
  questions: QuestionFe[] = [];
  //Recurring
  editGroup: boolean = false;
  slideOpen: boolean = false;
  slideOpenMap = new Map<number, boolean>();
  showRequestFolderTitleError: boolean = false;
  showSelectRequestsError: boolean = false;
  deleteRequestFolderOrRequestType = "";
  deleteRequestFolderOrRequestSelectedFolder: RequestFolderFe;
  deleteRequestFolderOrRequestSelectedRequestGroup: RequestGroupFe;
  deletingRequestGroupOrFolder: boolean = false;
  deleteRequestFolderOrRequestTitle = "";
  deleteRequestFolderOrRequestMsgPrimary = "";
  deleteRequestFolderOrRequestMsgSecondary = "";
  
  //navigation
  isRgSection = false

  @ViewChild(`${ModalNames.listRequestFoldersModal}`, { static: true }) listRequestFoldersModal: TemplateRef<any>;
  @ViewChild(`${ModalNames.createRequestFolderModal}`, { static: true }) createRequestFolderModal: TemplateRef<any>;
  modals;
  @ViewChild('questionnairePreview') questionnairePreview: TemplateRef<any>;
  isCustomModalOpen: boolean = false;
  message: any;

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    private tableStorageService: TableStorageServiceFe,
    public loginService: LoginServiceFe,
    private domSanitizer: DomSanitizer, private captureService: NgxCaptureService,
    languageService: LanguageService,
    private responsive: ResponsiveService
  ) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
    
    this.screenSize = responsive.currentScreenWidthSize;

    this.stateService.viewSubject.subscribe((obj) => {
      if (obj.view == ViewFe.CREATE_REQUEST) {
        this.openModal(this.addQuestionaireModal, 'request-modal');
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.loadingInfo = true;
    await this.loadCompaniesTaxonomy();
    await this.renderNewData();
    if (this.displayService.dataFromHomePage) {
      let activity = this.displayService.dataFromHomePage;
      this.viewNo = 2;
      this.selectedRequestGroup = this.allRequestGroups.find((rg) => rg.id == activity.requestGroupId);
      this.selectedRequest = this.selectedRequestGroup.requests.find((r) => r.id == activity.requestId);
      this.selectedRequestPeriod = null;
      this.openActivityPortal();
      this.displayService.dataFromHomePage = null;
    } else {
      this.viewNo = 1;
    }

    if (this.displayService.selectedRequestGroup) {
      this.searchKeyword = this.displayService.selectedRequestGroup.title
      this.displayService.selectedRequestGroup = null;
      this.isRgSection = true;
      this.changeView();
    }
    
    this.displayService.dataFromDetailsSubject.subscribe((isUpdated) => {
      if (isUpdated) {
        const data = this.displayService.dataFromDetails;
        if (data.viewNo == 2) {
          this.selectRg(this.selectedRequestGroup);
          this.viewNo = 2;
        } else if (data.viewNo == 3) {
          this.openActivityPortal();
          this.viewNo = 3;
        }
      }
    });

    this.stateService.requestTimelineItemsUpdatedSubject.subscribe((activity) => {
      let index = this.selectedRequest.timelineItems.findIndex(act => act.id == activity.id);
      this.selectedRequest.timelineItems[index] = activity;
    });
    this.displayService.closeTips();
    this.loadingInfo = false;
    this.setupModals();
  }

  async loadCompaniesTaxonomy() {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos();
    this.taxonomyInfo = depTaxonomy;
  }

  async renderNewData(): Promise<void> {
    this.allRequestGroups = await this.stateService.getRequestGroups();
    this.requestFolders = await this.stateService.getRequestFolders();
    this.adjustRecurringPeriods();
    this.setFilterData();
  }

  setFilterData() {
    this.applyFilters();
    this.filterCompanies();
    this.filterDataReporters();
    this.filterEntities();
  }

  adjustRecurringPeriods() {
    let rgs = this.getOnlyParentRequestGroups();
    rgs.forEach(r => {
      if (r.recurrence) {
        r.previousRequestPeriods = this.getPreviousRequestPeriods(r)
        r.previousRequestPeriods.forEach(period => this.setRequestGroupForRequestPeriod(period, r))
        r.currentRequestPeriods = this.getCurrentRequestPeriod(r)
        r.currentRequestPeriods.forEach(period => this.setRequestGroupForRequestPeriod(period, r))
        if (r.previousRequestPeriods.length == 0 && r.currentRequestPeriods.length == 1) {
          r.currentRequestPeriods[0].requestGroupId = r.id;
        } else if (r.previousRequestPeriods.length>0) {
          r.previousRequestPeriods[0].requestGroupId = r.id;
        }
        r.upcomingRequestPeriods = this.getUpcomingRequestPeriods(r)
        r.upcomingRequestPeriodsMsg = this.getUpCommingRequestMsg(r);
      }
    })
  }

  setRequestGroupForRequestPeriod(period: RequestPeriodFe, requestGroup: RequestGroupFe) {
    let date = new Date(period.start), dueDate = new Date(period.end)
    let requestGroupDate, rgDueDate;
    let rg = requestGroup;
    do {
      rg = this.allRequestGroups.find(r => r.recurringParent == rg.id);
      if (!rg) return;
      requestGroupDate = rg.date.toDateString();
      requestGroupDate = new Date(requestGroupDate)
      rgDueDate = rg.dueDate.toDateString();
      rgDueDate = new Date(rgDueDate)
    } while (DateUtil.getTimeDifference(requestGroupDate , date) != 0 && DateUtil.getTimeDifference(rgDueDate, dueDate) != 0) 
    period.requestGroupId = rg.id
  }

  selectRg(requestGruop: RequestGroupFe) {
    this.selectedRequestGroup = requestGruop;
    this.selectedRequest = requestGruop.requests[0];
    this.selectedRequestPeriod = null;
  }

  async updateDetailsData(requestGroup: RequestGroupFe, request: RequestFe = null) {
    this.detailsData = {
      requestGroup: requestGroup,
      request: request,
    };
    this.displayService.updateDetailsData(this.detailsData);
    this.displayService.updateQuickTipsData({ viewMode: `view_${this.viewNo}`, page: "requests" });
  }

  openActivityPortal(req?) {
    if (req) {
      this.selectedRequest = req;
    }
    this.viewNo = 2;
  }

  isSubmitAnswer(activity: AbstractActivityFe) {
    return activity instanceof SubmitAnswersActivityFe
  }

  isAccept(activity: AbstractActivityFe) {
    return activity instanceof AcceptSubmissionActivityFe;
  }

  isReject(activity: AbstractActivityFe) {
    return activity instanceof RejectSubmissionActivityFe;
  }

  openModal(template: TemplateRef<any> | string, clasz = "modal-xl") {
    this.modalRef = this.modalService.show(template, { class: clasz, backdrop: "static", keyboard: true });
  }

  async sendMessage() {
    this.loadingInProgress = true;
    let activity = new SubmitMessageActivity_AddFe(UUID.v4(), this.stateService.activeWorkspace.companyId, this.activityMessage, false, this.selectedRequest.id, this.selectedRequestGroup.id, this.selectedRequestGroup.title);
    await this.stateService.addRequestTimelineItem(activity, this.selectedRequestGroup.id, this.selectedRequest.id);

    this.closeModal();
    this.activityMessage = "";
    this.loadingInProgress = false;
  }
  
  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  openAttachment(id: string, dataGridService: DataGridServiceInterface | null, file: FileDataFe | null, submissionDate: Date) {
    let attachment = new AttachmentFe(id, dataGridService, file, submissionDate);
    if (dataGridService) {
      attachment.isTable = true;
      attachment.isFile = false;
    } else {
      attachment.isFile = true;
      attachment.isTable = false;
    }
    this.displayService.openAttachment(attachment);
  }

  closeAttachments() {
    this.displayService.closeAttachments();
  }

  back() {
    this.closeAttachments();
    this.viewNo = 1;
    this.changeView();
    this.isReviewMode = false;
  }

  getCreated(requestGroup: RequestGroupFe) {
    const created_raw = requestGroup.date;
    const date_format = "DD MMM, yyyy";
    const created = moment(created_raw).format(date_format);
    return created;
  }

  getDeadline(requestGroup: RequestGroupFe) {
    const deadline_raw = requestGroup.recurrence?.nextDeadline || requestGroup.dueDate;
    const date_format = "DD MMM, yyyy";
    const deadline = moment(deadline_raw).format(date_format);
    return deadline;
  }

  getStart(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const occurences = occurenceGenrator.generateOccurences({ fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP, requestGroup, numOccurrences: 2 })
    const date_format = "DD MMM, yyyy";
    const start_raw = occurences[0].sendOutDay;
    const start = moment(start_raw).format(date_format);
    return start;
  }

  getEnd(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const occurences = occurenceGenrator.generateOccurences({ fromType: SchedulingOccurenceGeneratorFromTypes.REQUEST_GROUP, requestGroup, numOccurrences: 2 })
    const date_format = "DD MMM, yyyy";
    const end_raw = occurences[0].nextDeadline;
    const end = moment(end_raw).format(date_format);
    return end;
  }

  displayDeadlineText(index: number): any {
    let deadline = document.getElementById("due" + index);
    if (deadline.classList.contains("badge-red")) {
      return "Overdue";
    } else if (deadline.classList.contains("badge-orange")) {
      return "Upcoming";
    }
    return "Due";
  }

  displayDeadlineText1(index: number): any {
    let deadline1 = document.getElementById("due1" + index);
    if (deadline1.classList.contains("badge-red")) {
      return "Overdue";
    } else if (deadline1.classList.contains("badge-orange")) {
      return "Upcoming";
    }
    return "Due";
  }

  async downloadFileFromServer(fileId: string, fileName: string): Promise<FileDataFe> {
    let fileType = FileTypeFe.fromName(fileId);
    let file = FileDataFe.fromBlob(`${fileName}.${fileType.extension}`);
    file.loadingFileInProgress = true;
    file.id = fileId;
    let content = await this.stateService.downloadAttachmentFile(this.stateService.activeWorkspace.companyId, file.id);
    file.addBlobContent(content, this.domSanitizer);
    file.type = fileType;
    file.loadingFileInProgress = false;
    return file;
  }

  async openRelatedTableFile(requestItem: any) {
    let activity = requestItem.relatedActivity
    if (activity instanceof AcceptSubmissionActivityFe) {
      let approvedActivity = this.selectedRequest.timelineItems.find((ti) => ti.id == (activity as AcceptSubmissionActivityFe).acceptedActivityId);
      activity = approvedActivity;
    }
  }

  async openTableFile(activity) {
    activity.isLoading = true;
    let service = new RequestEntryDataGridIntegrationService(new TableDataFe());
    if (!activity.dataTable) {
      let content = await this.stateService.downloadDataTableFile(this.stateService.activeWorkspace.companyId, activity.dataStorageId);
      service.mode = "view";
      await this.tableStorageService.convertFileIntoTable(content, (table: TableDataFe) => {
        service.table.key = activity.id;
        service.table.rows = table.rows;
        service.table.rowCount = table.rows.length;
        service.table.dataSchema = table.dataSchema;
        activity["dataTable"] = table;
        activity["dataGridService"] = service;
        service.observer?.renderNewDataTable();
        this.openAttachment(activity.id, service, null, activity.submissionDate);
      });
    } else {
      this.openAttachment(activity.id, activity.dataGridService, null, activity.submissionDate);
    }
    activity.isLoading = false;
  }

  dateToString(date: any) {
    date = new Date(date);
    return DateUtil.toString3(date);
  }

  toggleEditSettings() {
    this.editSettings = !this.editSettings;
    this.toggleMappingControls();
  }

  toggleMappingControls() {
    if (this.editSettings) {
      Object.keys(this.mappingForm.controls.taxonomy["controls"]).forEach((key) => {
        let control = this.mappingForm.controls.taxonomy["controls"][key] as FormControl;
        control.enable();
      });
      Object.keys(this.mappingForm.controls.mappings["controls"]).forEach((key) => {
        let control = this.mappingForm.controls.mappings["controls"][key] as FormControl;
        control.enable();
      });
    } else {
      Object.keys(this.mappingForm.controls.taxonomy["controls"]).forEach((key) => {
        let control = this.mappingForm.controls.taxonomy["controls"][key] as FormControl;
        control.disable();
      });
      Object.keys(this.mappingForm.controls.mappings["controls"]).forEach((key) => {
        let control = this.mappingForm.controls.mappings["controls"][key] as FormControl;
        control.disable();
      });
    }
  }

  onMappedTaoxonmyChange(itemNo: number) {
    if (itemNo != 2) {
      this.mappingForm.controls.taxonomy.value.item2 = "";
    } else {
      this.selectedMappedTaxonomy = this.taxonomyInfo.entityByKey(this.mappingForm.controls.taxonomy.value.item2);
    }
  }

  getTaxonomyChildren(value: string) {
    return this.taxonomyInfo?.childrenSortedByOrdinal(value);
  }

  async changeView() {
    this.selectedRequest = null;
    this.selectedRequestPeriod = null;
    this.selectedRequestGroup = null;
  }

  isLatestRelatedActivity(activity: AbstractActivityFe): boolean {
    let acts = this.selectedRequest.timelineItems.slice().reverse()
    let a = acts.find(a => this.isSubmitAnswer(a) || this.isReject(a) || this.isAccept(a));
    return activity.id == a?.id
  }

  isLatestSubmission(activity: AbstractActivityFe): boolean {
    let acts = this.selectedRequest.timelineItems.slice().reverse()
    let a = acts.find(a => this.isSubmitAnswer(a));
    return activity.id == a?.id
  }

  createNewRequest(requestGroup: RequestGroupFe | null) {
    this.requestGroupToBeDuplicated = requestGroup
    this.startRequestCreation = true;
  }

  requestCreated() {
    this.startRequestCreation = false; 
    this.adjustRecurringPeriods();
    this.toggleTips();
  }

  download() {
    this.questions = this.selectedRequest?.questionnaire?.getQuestions()
    let fileName = 'View_Per_DO.csv';
    let columnNames = [" DO Name"];
    for (let q of this.questions) {
      columnNames.push(q.question)
    }
    let header = columnNames.join(',');
    let csv = header;
    csv += '\r\n';

    console.log(this.questions)

    let answer = [this.selectedRequest.assigneeFirstName + ' ' + this.selectedRequest.assigneeLastName]
    this.questions.map((c) => {
      if (c.type == "DATA TABLE") {
        answer.push(c["fields"][1].key)
      } else if (c.type == "SINGLE CHOICE") {
        answer.push(c['options'][1].value)
      } else if (c.type == "MULTIPLE CHOICE") {
        answer.push(c['options'][1].value)
      } else if (c.type == "TEXT") {
        answer.push(c["answer"]["text"])
      } else if (c.type == "NUMBER") {
        answer.push(c["answer"]["value"])
      } else if (c.type == "DATE") {
        answer.push(c["answer"]["dateFrom"])
      }
      // else if (c.type == "ATTACHMENT"){
      //   answer.push(c["answer"].id)
      // }
    })
    csv += answer.join(',')
    var blob = new Blob([csv], { type: "text/csv" });

    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  
  showRequest(rg: RequestGroupFe) {
    this.selectedRequestGroup = rg;
    this.selectedRequest = this.selectedRequestGroup.requests[0];
    this.selectedRequestPeriod = null;
    this.updateDetailsData(this.selectedRequestGroup);
  }

  anyRequestApproved(rg: RequestGroupFe) {
    return rg.requests.some(r => r.status == REQUEST_TASK_STATUS.APPROVED)
  }

  
  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "back_from_view2":
        this.back();
        break;
      case "open_add_questionaire_modal":
        this.openModal(this.addQuestionaireModal, 'request-modal modal-lg');
        break;
      case "export_results":
        this.exportResult.next(true)
        break;
      case "open_confirm_close_modal":
        this.openModal(this.confirmClose, 'modal-md');
        break;
      case "toggle_quick_tips":
        this.toggleTips()
        break;
    }
  }

  toggleSlideOpen(i: number) {
    const current = this.slideOpenMap.get(i) || false;
    this.slideOpenMap.set(i, !current);
  } 

  editGroups() {
    this.editGroup = !this.editGroup;
  }

  isRecurringRequest(requestGroup: RequestGroupFe) {
    if (requestGroup.recurrence) {
      return requestGroup.recurrence.type == "FIXED" && !requestGroup.recurrence.stopped;
    }
    return false;
  }

  /*
    BEGIN : Request Folder Functionality  
  */

  selectedIdsForAddFolderChanged(requestGroup: RequestGroupFe, event) {
    const id = requestGroup.id;
    if (event.target.checked) {
      this.selectedRequestGroupIDsForAddFolder.push(id);
    } else {
      const idx = this.selectedRequestGroupIDsForAddFolder.indexOf(id);
      if (idx >= 0) {
        this.selectedRequestGroupIDsForAddFolder.splice(idx, 1);
      }
    }
    this.showSelectRequestsError = this.selectedRequestGroupIDsForAddFolder.length == 0
  }

  selectAllRequestGroups(event) {
    let rgs: any = this.getFilteredRequestGroupsAccToText();
    if (event.target.checked) {
      rgs.forEach(rg => rg.isSelected = true);
      this.selectedRequestGroupIDsForAddFolder = rgs.map(r => r.id);
    } else {
      rgs.forEach(rg => rg.isSelected = false);
      rgs = rgs.map(r => r.id);
      this.selectedRequestGroupIDsForAddFolder = this.selectedRequestGroupIDsForAddFolder.filter(id => !rgs.includes(id));
    }
    this.showSelectRequestsError = this.selectedRequestGroupIDsForAddFolder.length == 0
  }

  addFolderNameChanged() {
    this.showRequestFolderTitleError = false;
  }

  async addRequestFolder() {
    this.addingFolder = true;
    const id = IdUtil.next();
    const name = this.addFolderName;

    if (!name) {
      this.showRequestFolderTitleError = true;
      this.addingFolder = false;
      return;
    }

    if (this.selectedRequestGroupIDsForAddFolder.length == 0) {
      this.showSelectRequestsError = true;
      this.addingFolder = false;
      return;
    }

    const creatorAffId = this.stateService.activeWorkspace.affiliationId;
    const requestGroups = JSON.stringify(this.selectedRequestGroupIDsForAddFolder);
    const creatorCompanyId = this.stateService.activeWorkspace.companyId;
    const newFolder = new RequestFolder_AddFe(id, name, creatorAffId, requestGroups, creatorCompanyId);
    await this.stateService.addRequestFolder(newFolder);
    this.requestFolders = await this.stateService.getRequestFolders();
    this.addingFolder = false;
    this.addFolderName = "";
    this.selectedRequestGroupIDsForAddFolder = [];
    this.closeModalV2({ modal: ModalNames.createRequestFolderModal });
    setTimeout(() => {
      this.openModalV2({ modal: ModalNames.listRequestFoldersModal });
    }, 200);
  }

  getRequestGroupsInFolder(requestFolder: RequestFolderFe) {
    const ids = requestFolder.requestGroups;
    const requestGroupsInFolder = this.allRequestGroups.filter(requestGroup => ids.includes(requestGroup.id));
    return requestGroupsInFolder;
  }

  async deleteRequestGroupFromFolder({ requestGroup, requestFolder }: { requestGroup: RequestGroupFe, requestFolder: RequestFolderFe }) {
    this.deleteRequestFolderOrRequestType = "REQUEST";
    this.deleteRequestFolderOrRequestSelectedFolder = requestFolder;
    this.deleteRequestFolderOrRequestSelectedRequestGroup = requestGroup; 
    // this.openModalV2({ modal: ModalNames.deleteRequestFolderOrRequestModal });
  }

  async deleteRequestFolder({ requestFolder }: { requestFolder: RequestFolderFe }) {
    this.deleteRequestFolderOrRequestType = "REQUEST_FOLDER";
    this.deleteRequestFolderOrRequestSelectedFolder = requestFolder;
    // this.openModalV2({ modal: ModalNames.deleteRequestFolderOrRequestModal });
  }

  async confirmDeleteRequestFolderOrRequest() {
    this.deletingRequestGroupOrFolder = true;
    const requestFolder = this.deleteRequestFolderOrRequestSelectedFolder;
    const requestGroup = this.deleteRequestFolderOrRequestSelectedRequestGroup;

    switch (this.deleteRequestFolderOrRequestType) {
      case "REQUEST":
        const ids = requestFolder.requestGroups;
        const idx = ids.indexOf(requestGroup.id);
        const idsStr = JSON.stringify(ids);
        const creatorCompanyId = this.stateService.activeWorkspace.companyId;
        const newFolder = new RequestFolder_AddFe(
          requestFolder.id,
          requestFolder.name,
          requestFolder.creatorAffId,
          idsStr,
          creatorCompanyId
        )
        await this.stateService.updateRequestFolder(newFolder);
        this.requestFolders = await this.stateService.getRequestFolders();
        ids.splice(idx, 1);
        break;

      case "REQUEST_FOLDER":
        await this.stateService.deleteRequestFolder(requestFolder);
        this.requestFolders = await this.stateService.getRequestFolders();
        break;
    }
    this.deletingRequestGroupOrFolder = false;
  }

  cancelDeleteRequestFolderOrRequest() {
    this.deleteRequestFolderOrRequestSelectedRequestGroup = null;
    this.deleteRequestFolderOrRequestSelectedFolder = null;
    // this.closeModalV2({ modal: ModalNames.deleteRequestFolderOrRequestModal });
  }
  /*
    END : Request Folder Functionality  
  */

  setRequestsFilter({ type, folder }: { type: string, folder?: RequestFolderFe }) {
    this.requestsFilterType = type;
    switch (type) {
      case "allRequests":
        this.requestsFilterName = this.locale("locale_key.pages.data_request.portal.option.see_all_requests");
        this.requestsFilterFolder = null;
        break;
      case "folder":
        this.requestsFilterFolder = folder;
        this.requestsFilterName = folder.name;
        break;
    }
    this.applyFilters();
  }

  getOnlyParentRequestGroups() {
    return this.allRequestGroups.filter(rg => !rg.isChildRecurring);
  }

  getFilteredRequestGroupsAccToText() {
    let rgs = this.getOnlyParentRequestGroups();
    let newRegEx = new RegExp(this.requestsFilterText, "i");
    let filtered = rgs.filter(rg => rg.title.match(newRegEx))
    return filtered
  }

  private getPreviousRequestPeriods(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getPreviousRequestPeriods(requestGroup);
    return periods;
  }

  private getCurrentRequestPeriod(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getCurrentRequestPeriod(requestGroup);
    return periods;
  }

  private getUpcomingRequestPeriods(requestGroup: RequestGroupFe) {
    const occurenceGenrator = new SchedulingOccurenceGeneratorFe();
    const periods = occurenceGenrator.getUpcomingRequestPeriods(requestGroup);
    return periods;
  }

  private getUpCommingRequestMsg(requestGroup: RequestGroupFe): string {
    const ends = (requestGroup.recurrence.ends + "").toLowerCase();

    if (ends == "never") {
      return this.locale('locale_key.pages.data_request.title.several_upcoming_requests')
    }

    // This is abit tricky to translate
    if (requestGroup.upcomingRequestPeriods.length == 1) {
      return this.locale('locale_key.pages.data_request.title.no_of_upcoming_requests_singular')
    }
    return this.locale('locale_key.pages.data_request.title.no_of_upcoming_requests_plural', {length: requestGroup.upcomingRequestPeriods.length});
  }

  setupModals() {
    this.modals = {
      [`${ModalNames.listRequestFoldersModal}`]: { template: this.listRequestFoldersModal, class: `modal-lg ${ModalNames.listRequestFoldersModal}` },
      [`${ModalNames.createRequestFolderModal}`]: { template: this.createRequestFolderModal, class: `modal-lg ${ModalNames.createRequestFolderModal}` }
    }
  }

  openModalV2(options: { modal: ModalNames, class?: string }) {
    const modal = options.modal;
    const customClass = options.class || this.modals[modal].class;
    const template = this.modals[modal].template;
    this.modals[modal].ref = this.modalService.show(template, { backdrop: "static", keyboard: true, class: customClass });
  }

  closeModalV2({ modal }) {
    const modalRef = this.modals[modal].ref;
    this.modalService.hide(modalRef.id);
  }

  openRequestFoldersList() {
    this.editGroup = false;
    this.openModalV2({ modal: ModalNames.listRequestFoldersModal });
  }

  closeRequestFoldersList() {
    this.closeModalV2({ modal: ModalNames.listRequestFoldersModal });
  }

  addNewFolder() {
    this.closeModalV2({ modal: ModalNames.listRequestFoldersModal });
    this.addFolderName = "";
    this.selectedRequestGroupIDsForAddFolder = [];
    this.getOnlyParentRequestGroups().forEach(rg => rg.isSelected = false);
    setTimeout(() => {
      this.openModalV2({ modal: ModalNames.createRequestFolderModal });
    }, 200);
  }

  goBackToListRequestFolders() {
    this.closeModalV2({ modal: ModalNames.createRequestFolderModal });
    setTimeout(() => {
      this.openModalV2({ modal: ModalNames.listRequestFoldersModal });
    }, 200);
  }

  closeAddFoldersList() {
    this.closeModalV2({ modal: ModalNames.createRequestFolderModal });
  }

  selectRequestPeriod(period: RequestPeriodFe, requestGroup: RequestGroupFe) {
    let rg = this.getRequestGroup(period.requestGroupId)
    if (this.selectedRequest) {
      this.selectedRequest = rg.requests.find(r => r.assigneeAffId == this.selectedRequest.assigneeAffId)
    }
    this.selectedRequestPeriod = period;
    requestGroup.selectedRequestPeriod = period;
  }

  getRequestGroup(id: string) {
    return this.allRequestGroups.find(r => r.id == id)
  }

  openMessageModal() {
    this.isCustomModalOpen = !this.isCustomModalOpen;
  }

  sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let sortFun;
    if (this.sortedBy.value == 'createdDate') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(a.date , b.date)
      }
    } else if (this.sortedBy.value == 'createdDateDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(b.date , a.date)
      }
    } else if (this.sortedBy.value == 'deadline') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(a.dueDate , b.dueDate)
      }
    } else if (this.sortedBy.value == 'deadlineDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(b.dueDate , a.dueDate)
      }
    } else if (this.sortedBy.value == 'name') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1 
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'nameDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return 1 
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      this.filteredRequestGroups = this.filteredRequestGroups.sort(sortFun);
    }
  }

  applyFilters() {
    this.setFilteredRequestGroups();
  }

  setFilteredRequestGroups() {
    this.requestGroups = this.getOnlyParentRequestGroups();
    if (this.requestsFilterType == "folder") {
      const ids = this.requestsFilterFolder.requestGroups;
      this.requestGroups = this.requestGroups.filter(group => ids.includes(group.id));
    }
    this.applyShowOnlyFilter();
  }

  applyShowOnlyFilter() {
    if (this.selectedStatus) {
      this.requestGroups = this.requestGroups.filter(f => f.status == this.selectedStatus)
    }
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedStatus = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof SupplierCompanyFe) {
        this.customMultipleFilters.push({name: value.supplierCompanyname, value})
      } else if (value instanceof ContactFe) {
        this.customMultipleFilters.push({name: value.getName(), value})
      } else if (value instanceof EntityFe) {
        this.customMultipleFilters.push({name: value.getTaxonomyTitle(this.taxonomyInfo), value, level})
      } 
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.requestGroups = this.requestGroups.filter(requestGroup => {
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof SupplierCompanyFe) {
            for (let request of requestGroup.requests) {
              if (request.supplierCompanyId == value['supplierCompanyId']) {
                return true;
              }
            }
          } else if (value instanceof ContactFe) {
            for (let request of requestGroup.requests) {
              if (request.assigneeAffId == value['affiliationId']) {
                return true;
              }
            }
          } else if (value instanceof EntityFe) {
            let taxonomyKeys = new Set<string>();
            if (f.level == 0) {
              let parent = this.taxonomyInfo.childrenSortedByOrdinal(value.key)
              let child = []
              parent.forEach(item => child.push(...this.taxonomyInfo.childrenSortedByOrdinal(item.key)))
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else if (f.level == 1) {
              let child = this.taxonomyInfo.childrenSortedByOrdinal(value.key)
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else {
              taxonomyKeys.add(value.key)
            }
            if (requestGroup.requests[0]?.questionnaire?.sections) {
              for (let section of requestGroup.requests[0].questionnaire.sections) {
                if (section.taxonomyKey && taxonomyKeys.has(section.taxonomyKey)) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword;
    }
    this.filteredRequestGroups = this.requestGroups.filter(rg => {
      if (rg.title.match(new RegExp(this.searchKeyword, "i"))) {
        return true;
      }
      let requests =rg.requests.filter(req=> req.getAssigneeName().match(new RegExp(this.searchKeyword, "i")))
      return requests.length > 0
    })
    if (this.sortedBy) {
      this.sort();
    }
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Set();
    this.allRequestGroups.forEach(rg => rg.requests.forEach(request => relatedCompanyIds.add(request.supplierCompanyId)));
    let companies = await this.stateService.getPartners()
    let relatedCompanies = companies.filter(company => relatedCompanyIds.has(company.supplierCompanyId));
    this.filteredCompanies = relatedCompanies.filter(f => f.supplierCompanyname.match(new RegExp(keyword, "i")))
  }

  async filterDataReporters(keyword: string = '') {
    let relatedContactAffIds = new Set();
    this.allRequestGroups.forEach(rg => rg.requests.forEach(request => relatedContactAffIds.add(request.assigneeAffId)));
    let contacts = await this.stateService.getActiveContacts()
    let relatedContacts = contacts.filter(contact => relatedContactAffIds.has(contact.affiliationId));
    this.filteredDataReporters = relatedContacts.filter(f => f.getName().match(new RegExp(keyword, "i")))
  }

  async filterEntities(keyword: string = '') {
    let allRootEntities = this.taxonomyInfo.childrenSortedByOrdinal()
    this.filteredEntities = allRootEntities.filter(root => {
      if (root.getLabel().match(new RegExp(keyword, "i"))) {
        return true
      }
      let parents = this.taxonomyInfo.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.taxonomyInfo.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != company)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != contact)
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
      })
    }
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        if (!this.customMultipleFilters.find(f => f.value == company)) {
          this.customMultipleFilters.push({name: company.supplierCompanyname, value: company})
        } 
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        if (!this.customMultipleFilters.find(f => f.value == contact)) {
          this.customMultipleFilters.push({name: contact.getName(), value: contact})
        } 
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        if (!this.customMultipleFilters.find(f => f.value == enitity)) {
          this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.taxonomyInfo), value: enitity})
        } 
      })
    }
    this.applyFilters();
  }

  getCustomFilterText() {
    let text = '';
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }

  getRequests() {
    let requests = this.selectedRequestGroup.requests
    if (this.selectedRequestGroup.selectedRequestPeriod) {
      let id = this.selectedRequestGroup.selectedRequestPeriod.requestGroupId
      let rg = this.getRequestGroup(id)
      requests = rg.requests
    }
    return requests
  }

  addCreatedRequest(data: any) {
    this.filteredRequestGroups.unshift(data);
  }
}