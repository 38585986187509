<body class="blank-page" style="background-image: var(--main-gradient-color)">
  <div class="app-content container center-layout">
    <div class="content-wrapper">
      <div class="content-body">
        <div class="row mb-1">
          <section class="row flexbox-container my-1">
            <div class="col-12 d-flex align-items-center justify-content-center">
              <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                  <div class="card-header pb-0">
                    <div class="card-title text-center">
                      <img class="img-responsive img-fluid logo-invitation" src="/assets/img/logos/SL_Logo_gradient@2x.png" alt="logo" style="height: 70px; width: 200px" />
                    </div>
                  </div>
                  <div class="card-content">
                    <div class="card-body pb-0 pt-0">
                      <form class="form form-horizontal text-center p-1" *ngIf="viewNo==1">
                        <div class="row p-1 text-center">
                          <p class="text-justify m-2">
                           {{locale('locale_key.pages.dashboard.access_shared_dashboard.description')}}
                          </p>
                          <div class="col-12">
                            <div class="form-group text-left">
                              <input type="text" class="form-control" id="email" placeholder="Email" [formControl]="email"
                              [ngClass]="{'is-invalid': email.errors && email.touched }"/>
                              <div *ngIf="email.errors && email.touched" class="invalid-feedback">
                                <div *ngIf="email.errors.required">
                                  {{ emailMessage }}
                                </div>
                                <div *ngIf="email.hasError('pattern')">
                                  {{ lowerCaseEmail }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer float-end" style="border-top: none">
                          <button type="submit" class="btn primary-button custom-button" *ngIf="!inProgress" (click)="sendVerificationCode()">{{locale('locale_key.general.buttons.next')}}</button>
                          <div class="ball-pulse loader-primary" *ngIf="inProgress">
                            <div style="background-color: #405369"></div>
                            <div style="background-color: #405369"></div>
                            <div style="background-color: #405369"></div>
                          </div>
                        </div>
                      </form>
                      <form class="form form-horizontal text-center p-1" *ngIf="viewNo==2">
                        <div class="row p-1 text-center">
                          <h3 class="mx-auto text-center"><i class="la la-check-circle-o success font-large-1" style="position: relative; top: 3px"></i> {{locale('locale_key.pages.dashboard.access_shared_dashboard.verification_code')}}</h3>
                          <p class="text-justify m-2">{{locale('locale_key.pages.dashboard.access_shared_dashboard.verification_code_description')}}</p>
                          <div class="col-12">
                            <div class="form-group text-left">
                              <input type="text" class="form-control" id="email" placeholder="{{locale('locale_key.pages.dashboard.access_shared_dashboard.verification_code_placeholder')}}" [formControl]="enteredCode" 
                              [ngClass]="{'is-invalid': enteredCode.errors && enteredCode.touched }"/>
                              <div *ngIf="enteredCode.errors && enteredCode.touched" class="invalid-feedback">
                                <div *ngIf="enteredCode.errors.required">
                                  {{ verificationCodeMessage }}
                                </div>
                                <div *ngIf="enteredCode.hasError('pattern')">
                                  {{ verificationCodeValidMessage }}
                                </div>
                              </div>
                              <label class="ml-auto float-end">{{locale('locale_key.pages.dashboard.access_shared_dashboard.cannot_verify')}} <a class="card-link" (click)="generateNewCode()">{{locale('locale_key.pages.dashboard.access_shared_dashboard.send_new_code')}}</a></label>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer float-end" style="border-top: none">
                          <button type="submit" class="btn primary-button custom-button" (click)="verifyCode()" *ngIf="!inProgress">{{locale('locale_key.pages.profile.security.multi_factor_authentication.button.verify')}}</button>
                          <div class="ball-pulse loader-primary" *ngIf="inProgress">
                            <div style="background-color: #405369"></div>
                            <div style="background-color: #405369"></div>
                            <div style="background-color: #405369"></div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</body>