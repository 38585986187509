import { AfterViewChecked, Component, OnInit, TemplateRef, ViewChild } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe"
import { REQUEST_TASK_STATUS } from "src/app/model/data-suppliers/request/RequestFe"
import { DatahubService } from "./DatahubService"
import { DateUtil } from "src/app/utils/DateUtil"
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe"
import { ContactFe } from "src/app/model/user/ContactFe"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"

@Component({
  selector: "do-submissions",
  templateUrl: "./doSubmissions.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class DoSubmissionsComponent extends AbstractLanguageComponent implements OnInit, AfterViewChecked {
  @ViewChild("dataTable", { static: true }) dataTable: TemplateRef<any>
  componentNameForChild = 'Datahub';
  menuCollapsed: boolean

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInfo: boolean = false
  loadingInProgress: boolean = false
  selectedRg: RequestGroupFe
  datahubFilteredRgs: RequestGroupFe[] = []
  allRequestGroups: RequestGroupFe[] = []
  filteredRequestGroups: RequestGroupFe[] = []
  filteredGroupedRequestGroups: {name:string, requestGroups: RequestGroupFe[]}[] = []
  isCollapsed: boolean;  
  depTaxonomy: TaxonomyInfoFe
  searchKeyword: string = '';
  scrolledToSelectedRg: boolean = false
  isScrolled: boolean = false

  groupedBy = null
  groupedByOptions = [
    {name: this.locale('locale_key.pages.datahub.group.option.created_year'), value: 'createdYear'}
  ]
  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date'), value: 'createdDate', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date_desc'), value: 'createdDateDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline'), value: 'deadline', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline_desc'), value: 'deadlineDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]

  selectedStatus = null;
  customMultipleFilters: {name: string, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number}[] = []
  filteredCompanies: SupplierCompanyFe[] = []
  filteredDataReporters: ContactFe[] = []
  filteredEntities: EntityFe[] = []
  
  
  constructor(languageService: LanguageService, private responsive: ResponsiveService, public stateService: StateServiceFe,
    public datahubService: DatahubService, private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService) {
    super(languageService)
    
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.screenSize = this.responsive.currentScreenWidthSize
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize;
  }

  async ngOnInit() {
    
    this.loadingInProgress = true
    if (this.datahubService.isHomeView()) {
      this.datahubFilteredRgs = await this.stateService.getRequestGroups();
    } else {
      this.datahubFilteredRgs = await this.datahubService.getFilteredRequestGroups();
    }
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos();
    this.depTaxonomy = depTaxonomy
    this.filterCompanies();
    this.filterDataReporters();
    this.filterEntities();
    this.applyFilters();
    this.loadingInProgress = false
    this.isScrolled = false;
  }

  ngAfterViewChecked() {
    if (this.datahubService.selectedScrollRg && this.datahubService.isRequestFilter && !this.isScrolled) {
      const id = "groupRgDiv_" + this.datahubService.selectedScrollRg.id;
      this.scrollToSelectedRg(id);
      this.isScrolled = true;
    }
  }

  search($event: any) {
    throw new Error("Method not implemented.")
  }

  selectRg(rg: RequestGroupFe) {
    this.selectedRg = rg;
    if (this.datahubService.isHomeView()) {
      this.datahubService.selectItemToFilter(rg)
    }
    if (this.displayService.openTab == 'DETAILS') {
      this.updateDetailsData(rg);
    }

    this.datahubService.selectedScrollRg = rg;
    this.scrolledToSelectedRg = false;
  }

  showRgResult(rg: RequestGroupFe) {
    this.selectedRg = rg;
    this.datahubService.showRgResult(rg)
  }

  anyRequestApproved (rg: RequestGroupFe) {
    return rg.requests.some(r => r.status == REQUEST_TASK_STATUS.APPROVED)
  }
  
  updateDetailsData(rg: RequestGroupFe) {
    this.detailsData = { requestGroup: rg };
    this.displayService.updateDetailsData(this.detailsData);
  }

  group(option?) {
    if (option) {
      this.groupedBy = option;
    }
    this.filteredGroupedRequestGroups = [];
    if (this.groupedBy?.value == 'createdYear') {
      let years = new Map<number, RequestGroupFe[]>();
      this.filteredRequestGroups.forEach(rg => {
        let year = years.get(rg.date.getFullYear())
        if (year) {
          year.push(rg)
        } else if (rg.date.getFullYear()) {
          years.set(rg.date.getFullYear(), [rg])
        }
      })
      years.forEach((requestGroups, year)=> this.filteredGroupedRequestGroups.push({name: `Created year: ${year}`, requestGroups}) )
    }
    if (this.sortedBy) {
      this.sort();
    }
  }

  sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let sortFun;
    if (this.sortedBy.value == 'createdDate') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(a.date , b.date)
      }
    } else if (this.sortedBy.value == 'createdDateDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(b.date , a.date)
      }
    } else if (this.sortedBy.value == 'deadline') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(a.dueDate , b.dueDate)
      }
    } else if (this.sortedBy.value == 'deadlineDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(b.dueDate , a.dueDate)
      }
    } else if (this.sortedBy.value == 'name') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1 
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'nameDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return 1 
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      if (this.groupedBy) {
        this.filteredGroupedRequestGroups.forEach(group => group.requestGroups = group.requestGroups.sort(sortFun))
      } else {
        this.filteredRequestGroups = this.filteredRequestGroups.sort(sortFun);
      }
    }
  }

  applyFilters() {
    this.applyShowOnlyFilter();
  }

  applyShowOnlyFilter() {
    if (this.selectedStatus) {
      this.allRequestGroups = this.datahubFilteredRgs.filter(f => f.status == this.selectedStatus)
    } else {
      this.allRequestGroups = this.datahubFilteredRgs;
    }
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedStatus = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof SupplierCompanyFe) {
        this.customMultipleFilters.push({name: value.supplierCompanyname, value})
      } else if (value instanceof ContactFe) {
        this.customMultipleFilters.push({name: value.getName(), value})
      } else if (value instanceof EntityFe) {
        this.customMultipleFilters.push({ name: value.getTaxonomyTitle(this.depTaxonomy), value, level})
      } 
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.allRequestGroups = this.allRequestGroups.filter(requestGroup => {
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof SupplierCompanyFe) {
            for (let request of requestGroup.requests) {
              if (request.supplierCompanyId == value['supplierCompanyId']) {
                return true;
              }
            }
          } else if (value instanceof ContactFe) {
            for (let request of requestGroup.requests) {
              if (request.assigneeAffId == value['affiliationId']) {
                return true;
              }
            }
          } else if (value instanceof EntityFe) {
            let taxonomyKeys = new Set<string>();
            if (f.level == 0) {
              let parent = this.depTaxonomy.childrenSortedByOrdinal(value.key)
              let child = []
              parent.forEach(item => child.push(...this.depTaxonomy.childrenSortedByOrdinal(item.key)))
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else if (f.level == 1) {
              let child = this.depTaxonomy.childrenSortedByOrdinal(value.key)
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else {
              taxonomyKeys.add(value.key)
            }
            if (requestGroup.requests[0]?.questionnaire?.sections) {
              for (let section of requestGroup.requests[0].questionnaire.sections) {
                if (section.taxonomyKey && taxonomyKeys.has(section.taxonomyKey)) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword;
    }
    this.filteredRequestGroups = this.allRequestGroups.filter(rg => {
      if (rg.title.match(new RegExp(this.searchKeyword, "i"))) {
        return true;
      }
      let requests =rg.requests.filter(req=> req.getAssigneeName().match(new RegExp(this.searchKeyword, "i")))
      return requests.length > 0
    })
    this.group();    
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Set();
    this.datahubFilteredRgs.forEach(rg => rg.requests.forEach(request => relatedCompanyIds.add(request.supplierCompanyId)));
    let companies = await this.stateService.getPartners()
    let relatedCompanies = companies.filter(company => relatedCompanyIds.has(company.supplierCompanyId));
    this.filteredCompanies = relatedCompanies.filter(f => f.supplierCompanyname.match(new RegExp(keyword, "i")))
  }

  async filterDataReporters(keyword: string = '') {
    let relatedContactAffIds = new Set();
    this.datahubFilteredRgs.forEach(rg => rg.requests.forEach(request => relatedContactAffIds.add(request.assigneeAffId)));
    let contacts = await this.stateService.getContacts()
    let relatedContacts = contacts.filter(contact => relatedContactAffIds.has(contact.affiliationId));
    this.filteredDataReporters = relatedContacts.filter(f => f.getName().match(new RegExp(keyword, "i")))
  }

  async filterEntities(keyword: string = '') {
    let allRootEntities = this.depTaxonomy.childrenSortedByOrdinal()
    this.filteredEntities = allRootEntities.filter(root => {
      if (root.getLabel().match(new RegExp(keyword, "i"))) {
        return true
      }
      let parents = this.depTaxonomy.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.depTaxonomy.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != company)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != contact)
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
      })
    }
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        if (!this.customMultipleFilters.find(f => f.value == company)) {
          this.customMultipleFilters.push({name: company.supplierCompanyname, value: company})
        } 
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        if (!this.customMultipleFilters.find(f => f.value == contact)) {
          this.customMultipleFilters.push({name: contact.getName(), value: contact})
        } 
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        if (!this.customMultipleFilters.find(f => f.value == enitity)) {
          this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.depTaxonomy), value: enitity})
        } 
      })
    }
    this.applyFilters();
  }

  getCustomFilterText() {
    let text = '';
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }

  openRequestGroup(rg: RequestGroupFe) {
    this.displayService.viewRequestGroup(rg)
  }

  checkSelectRg(rg: any): boolean {
    if (this.selectedRg) {
      return this.selectedRg == rg
    }
    return this.datahubService.selectedItemToFilter && this.datahubService.selectedItemToFilter == rg
  }

  scrollToSelectedRg(groupId: string) {
    if (!this.scrolledToSelectedRg) {
      let element = document.getElementById(groupId);
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
        this.scrolledToSelectedRg = true;
      }
    }
  }
}
