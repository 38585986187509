import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Component({
  selector: "ef-details",
  templateUrl: "./ef-details.component.html",
  styleUrls: ["./ef-details.component.scss"],
})
export class EmissionFactorDetailsComponent  extends AbstractLanguageComponent{
  
  @Input() isEFDetailOpen = true;
  @Input() emissionFactor: AbstractEmissionFactorFe
  @Input() showOnlyInfo = false
  @Input() showDatabaseInfo = true
  @Input() showOnlyDatabaseInfo = false
  @Output() closeEfDetails = new EventEmitter<boolean>();

  constructor(languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit(): void {
  }

  close(){
    this.closeEfDetails.emit(true);
  }
}
