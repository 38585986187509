import { Component, OnInit, ViewChild } from "@angular/core";
import _ from "lodash";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { UnitFe } from "./model/UnitFe";
import { UnitSystems, getUnitSystemName } from "./model/UnitSystems";
import { groupUnitsByMeasurementTypeAndSystem } from "./model/utils";
import { UnitMeasurementTypes } from "./model/UnitMeasurementType";
import { UNIT_EDITOR_STATE } from "./model/UNIT_EDITOR_STATE";
import { UnitEditorComponent } from "./unit-editor/unit-editor.component";
import { UnitsByMeasurementType } from "./model/UnitsByMeasurementType";

@Component({
  selector: "unit-systems",
  templateUrl: "./unit-systems.component.html",
  styleUrls: ["./unit-systems.component.scss"]
})
export class UnitSystemsComponent extends AbstractLanguageComponent implements OnInit {
  controls = [
    {
      name: this.locale("locale_key.general.state.new"),
      id: "new",
      title: "New",
      icon: "la la-plus"
    },
    {
      name: this.locale("locale_key.general.toolbar.button.info"),
      id: "info",
      title: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
      icon: "la la-info"
    }
  ];

  units: UnitFe[] = [];
  unitsByMeasurementType: UnitsByMeasurementType[] = [];
  filteredUnitsByMeasurementType: UnitsByMeasurementType[] = [];
  customUnits: UnitFe[] = [];
  filteredCustomUnits: UnitFe[] = [];
  unitSystemsFilter: Set<UnitSystems> = new Set();
  measurementTypeFilter = new Set();
  nameOrSymbolFilter = new Set();

  @ViewChild(`unitEdtiorModal`, { static: false })
  unitEdtiorModal: UnitEditorComponent;

  constructor(languageService: LanguageService, private displayService: DisplayServiceFe, private stateService: StateServiceFe) {
    super(languageService);
    stateService.unitsUpdated.subscribe((units) => {
      this.bootstrap();
    });
  }

  async ngOnInit() {
    this.bootstrap();
  }

  private async bootstrap() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units);
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.filterUnits({});

    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard && unit.shouldDisplay);
    this.filteredCustomUnits = this.customUnits;
  }

  private startUnitEditor({ state, unit }: { state: UNIT_EDITOR_STATE; unit?: UnitFe }) {
    this.unitEdtiorModal.open({ state, unit });
  }

  controlClicked(id) {
    switch (id) {
      case "new":
        this.startUnitEditor({ state: UNIT_EDITOR_STATE.ADD_UNIT });
        return;
      case "info":
        return this.displayService.toggleTips();
    }
  }

  getSymbol(unit: UnitFe) {
    if (_.isEmpty(unit)) return "";

    let symbol = unit.symbol;
    if (!_.isEmpty(unit.aliases)) {
      symbol = unit.aliases;
    }

    return symbol;
  }

  filterUnits(evt) {
    const toTitleCase = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    let unitsByMeasurementType = _.cloneDeep(this.unitsByMeasurementType);

    if (this.nameOrSymbolFilter.size > 0) {
      // we are filtering units, so Combine units and their prefixes into one array to search once
      unitsByMeasurementType = unitsByMeasurementType.map((measurementType) => {
        measurementType.systems = measurementType.systems.map((system) => {
          let unitPrefixes = [];
          system.units.forEach((unit) => {
            unitPrefixes = [...unitPrefixes, ...unit.prefixList];
          });
          system.units = [...system.units, ...unitPrefixes];
          return system;
        });
        return measurementType;
      });
    }

    // deep clone so that we don't lose original data;
    this.filteredUnitsByMeasurementType = unitsByMeasurementType.filter((measurementType) => {
      measurementType.systems = measurementType.systems.filter((system) => {
        system.units = system.units.filter((unit) => {
          if (!unit.shouldDisplay) return false;
          //filter units
          if (this.nameOrSymbolFilter.size == 0) {
            return true;
          }
          let match = false;
          this.nameOrSymbolFilter.forEach((term: string) => {
            if (unit.name.toLowerCase().includes(term.toLowerCase()) || unit.symbol.toLowerCase().includes(term.toLowerCase()) || unit.aliases.toLowerCase().includes(term.toLowerCase())) {
              match = true;
            }
          });
          return match;
        });

        // filter systems
        if (system.units.length == 0) {
          return false;
        }
        if (this.unitSystemsFilter.size == 0) {
          return true;
        }
        if (this.unitSystemsFilter.has(system.key)) {
          return true;
        } else {
          return false;
        }
      });

      // filter measurement types
      if (measurementType.systems.length == 0) {
        return false;
      }

      if (this.measurementTypeFilter.size == 0) {
        return true;
      }

      if (this.measurementTypeFilter.has(measurementType.key)) {
        return true;
      } else {
        return false;
      }
    });

    // filter custom units as well
    // deep clone so that we don't lose original data;
    const customUnits = _.cloneDeep(this.customUnits);
    this.filteredCustomUnits = customUnits.filter((unit) => {
      if (!unit.shouldDisplay) return false;

      //filter units
      if (this.nameOrSymbolFilter.size == 0 && this.unitSystemsFilter.size == 0 && this.measurementTypeFilter.size == 0) {
        return true;
      }

      const matches = [];

      let nameMatch = false;
      // filter by name
      this.nameOrSymbolFilter.forEach((term: string) => {
        if (  
            unit.name.toLowerCase().split("~").join("").includes(term.toLowerCase().split("~").join("")) || 
            unit.symbol.toLowerCase().split("~").join("").includes(term.toLowerCase().split("~").join("")) || 
            unit.aliases.toLowerCase().split("~").join("").includes(term.toLowerCase().split("~").join(""))) {
          nameMatch = true;
        }
      });
      if (this.nameOrSymbolFilter.size > 0) {
        matches.push(nameMatch);
      }

      // filter by unit system
      let systemMatch = false;
      this.unitSystemsFilter.forEach((term: string) => {
        const check = `is${toTitleCase(term)}`;
        if (unit[check]) {
          systemMatch = true;
        }
      });
      if (this.unitSystemsFilter.size > 0) {
        matches.push(systemMatch);
      }

      // filter by measurement type
      let measurementTypeMatch = false;
      this.measurementTypeFilter.forEach((term: string) => {
        if (unit.measurementType.toLowerCase().includes(term.toLowerCase())) {
          measurementTypeMatch = true;
        }
      });
      if (this.measurementTypeFilter.size > 0) {
        matches.push(measurementTypeMatch);
      }

      let match = true;
      matches.forEach((check) => {
        if (!check) {
          match = false;
        }
      });
      return match;
    });
  }

  editUnit(unit) {
    this.startUnitEditor({ state: UNIT_EDITOR_STATE.EDIT_UNIT, unit });
  }

  toggleUnitCollapse(unit){
    unit.collapsed = !unit.collapsed;
  }
}
