import { ActivityTypeFe } from "../../timeline/ActivityTypeFe";
import { AbstractActivity_AddFe } from "./AbstractActivity_AddFe";

export class CloseRequestActivity_AddFe extends AbstractActivity_AddFe {

  requesterFirstName: string
  requesterLastName: string
  requesterCompanyName: string

  constructor(
    id: string,
    message: string | null,
    requestTaskId: string,
    requestGroupId: string,
    requestGroupLabel: string,
    requesterFirstName: string,
    requesterLastName: string,
    requesterCompanyName: string
  ) {
    super(
      id,
      ActivityTypeFe.CLOSE_REQUEST,
      message,
      false,
      requestTaskId,
      requestGroupId,
      requestGroupLabel
    ) 
    this.requesterFirstName = requesterFirstName
    this.requesterLastName = requesterLastName
    this.requesterCompanyName = requesterCompanyName
  }

  public static fromTransfer(transfer: any): CloseRequestActivity_AddFe {
    let activity = new CloseRequestActivity_AddFe(
      transfer.id,
      transfer.message,
      transfer.requestTaskId,
      transfer.requestGroupId,
      transfer.requestGroupLabel,
      transfer.requesterFirstName,
      transfer.requesterLastName,
      transfer.requesterCompanyName
    )
    return activity
  }
}