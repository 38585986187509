<body class="ml-5" style="overflow-y: scroll !important;">
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper pt-25 pr-25">
      <div class="content-body">
        <!--MODALS-->
        <div class="w-100 vertical-scroll">
            <!-- Invitation Forms Themes start-->
            <section id="modal-themes">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">{{ slocale("Invitation Forms Modal Demo") }}</h4>
  
                      <a class="heading-elements-toggle"
                        ><i class="la la-ellipsis font-medium-3"></i
                      ></a>
                      <div class="heading-elements">
                        <ul class="list-inline mb-0">
                          <li>
                            <a data-action="collapse"><i class="ft-minus"></i></a>
                          </li>
                          <li>
                            <a data-action="reload"
                              ><i class="ft-rotate-cw"></i
                            ></a>
                          </li>
                          <li>
                            <a data-action="expand"
                              ><i class="ft-maximize"></i
                            ></a>
                          </li>
                          <li>
                            <a data-action="close"><i class="las la-times"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                    <div class="card-content collapse show">
                      <div class="card-body vertical-scroll">
                        <div class="row my-2 vertical-scroll" >
                          <div class="content-wrapper vertical-scroll">
                            <div class="content-body vertical-scroll" style="overflow-y: scroll !important;">
                              <!-- Dear Jane-->
                              <div class="col-lg-6 m-auto">
                                <div class="row" style="background: #6dc49b">
                                  <div class="col-lg-12 btn-add text-center mb-1">
                                    <img
                                      src="../../../../src/assets/img/logos/SL_Logo_gradient@2x.png"
                                    />
                                  </div>
                        
                                  <div class="col-lg-11 bg-white text-justify m-auto p-3">
                                    <p>{{ slocale("Dear Jane Doe,") }}</p>
                        
                                    <p>
                                      {{ slocale("We are happy and excited to welcome you to SustainLab and to become part of our group of gamechangers that want to accelerate the sustainability transition through better data management! You are invited by Simon Iaffa (Regio) to signup on our sustainability studio platform as a data owner in behalf of your company, RVM Mgmt.") }}
                                    </p>
                        
                                    <p>
                                      {{ slocale("To get started we need you to confirm your relationship with Regio and confirm your personal details. Please follow the below link and use your email address, sally@regio.co, as your user-id to complete the form: Link:") }}
                                      <a
                                        href="https://studio.sustainlab.co/signup/57105c95-ecla-4773-89ec-4689613d244a"
                                        >https://studio.sustainlab.co/signup/57105c95-ecla-4773-89ec-4689613d244a</a
                                      >
                                    </p>
                        
                                    <p>
                                      {{ slocale("If you are not the correct person to confirm this, please answer this email providing the correct contact details.") }}
                                    </p>
                        
                                    <p class="mb-0">{{ slocale("Studio Platform,") }}</p>
                                    <p class="mt-0">SustainLab Sweden AB</p>
                                  </div>
                                  <div class="col-lg-12 btn-add text-center mt-1">
                                    <div class="divider"></div>
                        
                                    <div class="container">
                                      <div class="logo pb-2">
                                        <a routerLink="/"
                                          ><img
                                            src="../../src/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo image"
                                        /></a>
                                      </div>
                        
                                      <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                          <div class="social m-auto white text-justify">
                                            <p class="white">
                                              SustainLab is a SaaS Sustainability Management platform
                                              that automates collection, processing and visualisation
                                              of sustainability data, to help companies spend less
                                              time on data-handling and more on accelerating change.
                                            </p>
                                          </div>
                                        </div>
                        
                                        <div class="col-lg-4 col-md-6 col-sm-6 ml-auto">
                                          <div class="row social">
                                            <p>
                                              <i
                                                class="la la-linkedin-square white mr-50"
                                                style="font-size: 1.5rem"
                                              ></i>
                                              <a
                                                href="https://se.linkedin.com/company/sustainlab"
                                                target="_blank"
                                                class="white"
                                                >{{ slocale("Follow us") }}</a
                                              >
                                            </p>
                        
                                            <p>
                                              <i
                                                class="la la-globe white mr-50"
                                                style="font-size: 1.5rem"
                                              ></i>
                                              <a href="www.sustainlab.co" class="white"
                                                >https://www.ustainlab.co</a
                                              >
                                            </p>
                                            <p>
                                              <i
                                                class="la la-envelope white mr-50"
                                                style="font-size: 1.5rem"
                                              ></i>
                                              <a href="mailto:hello@sustainlab.co" class="white"
                                                >hello@sustainlab.co</a
                                              >
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6 col-sm-6">
                                          <div class="single-footer-widget col-lg-12 m-auto">
                                            <div id="email" class=""></div>
                                          </div>
                                        </div>
                                      </div>
                        
                                      <div class="copyright-area white">
                                        <hr />
                                        <p class="text-center white">
                                          Copyright @2020-2022 SustainLab Sweden AB.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <h1 class="my-2">{{ slocale("Welcome to SustainLab") }}</h1>
                                            <div class="row p-1">
                                              <p class="text-justify mb-1">
                                                {{slocale("You have received an invitation from <b>Simon Iaffa Nylén</b> from <b>Brunswick Real Estate</b> to connect with them through our platform as part of your affiliation to the company Fortum. To become connected to them, please confirm the information they provided and accept the invitation.") }}
                                              </p>
                                              <input type="checkbox" class="mb-75 mr-50" />
                                              <label
                                                >{{ slocale("I confirm that I am affiliated with company Fortum.") }}</label
                                              >
                                            </div>
                                            <div class="mt-2 text-right">
                                              <button type="submit" class="btn secondary-button">
                                                {{ slocale("Reject Invitation") }}
                                              </button>
                                              <button type="submit" class="btn primary-button">
                                                {{ slocale("Accept Invitation") }}
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <hr />
                              <hr />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid logo-invitation"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1 text-center">
                                              <h1 class="m-auto">{{ slocale("Welcome to SustainLab") }}</h1>
                                              <p class="text-justify my-2">
                                                Is this the first time you are invited to SustainLab
                                                or do you use SustainLab with another email?
                                              </p>
                                              <select
                                                class="form-control"
                                                id="location1"
                                                name="location"
                                              >
                                                <option selected>Select an answer</option>
                                                <option value="1">
                                                  I am totally new to the SustainLab Platform
                                                </option>
                                                <option value="2">
                                                  I am already a user of SustainLab Platform with
                                                  another email
                                                </option>
                                              </select>
                                            </div>
                                            <div class="mt-2 text-right">
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Continue
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid logo-invitation"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1">
                                              <h1 class="m-auto">Welcome to SustainLab</h1>
                                              <p class="text-justify my-2">
                                                Please provide your current credentials that system
                                                can add your new role and new workspace to the same
                                                user account. Later you can login with either of your
                                                emails to access your workspaces.
                                              </p>
                                            </div>
                                            <div class="row p-1">
                                              <div class="col-12">
                                                <div class="form-group text-left">
                                                  <label for="email" class="text-left"
                                                    >Your Email Address</label
                                                  >
                                                  <input
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    placeholder="Your existing email."
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-12">
                                                <div class="form-group text-left">
                                                  <label for="password" class="text-left"
                                                    >Your Password</label
                                                  >
                                                  <input
                                                    type="password"
                                                    class="form-control"
                                                    id="password"
                                                    placeholder="Your existing password."
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="mt-2 text-right">
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Continue
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid logo-invitation"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1">
                                              <h1 class="m-auto">Welcome to SustainLab</h1>
                                              <p class="text-justify my-2">
                                                Our system recognised that your email address <
                                                primaryContactEmail > is already registered in our
                                                system. Please provide your current credentials to
                                                continue.
                                              </p>
                                            </div>
                                            <div class="row p-1">
                                              <div class="col-12">
                                                <div class="form-group text-left">
                                                  <label for="email" class="text-left"
                                                    >Your Email Address</label
                                                  >
                                                  <input
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    placeholder="manager@email.com"
                                                    readonly
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-12">
                                                <div class="form-group text-left">
                                                  <label for="password" class="text-left"
                                                    >Your Password</label
                                                  >
                                                  <input
                                                    type="password"
                                                    class="form-control"
                                                    id="password"
                                                    placeholder="Your existing password."
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="mt-2 text-right">
                                              <button type="submit" class="btn secondary-button">
                                                Forgot password
                                              </button>
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Continue
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid logo-invitation"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1 text-center">
                                              <h1 class="m-auto">Welcome to SustainLab</h1>
                                              <p class="text-justify my-2">
                                                < inviterFirstName inviterLastName > have selected you
                                                as the primary person to confirm the detail
                                                information about < partnerCompanyName >. Please help
                                                us to identify < partnerCompanyName > correctly. Is <
                                                managementCompanyName > the same as a company that you
                                                are affiliated in SustainLab platform now or in past?
                                              </p>
                                              <select
                                                class="form-control"
                                                id="location1"
                                                name="location"
                                              >
                                                <option selected>Select an answer</option>
                                                <option value="1">
                                                  No, I have never been affiliated with < mCN >
                                                  through SustainLab Platform
                                                </option>
                                                <option value="2">
                                                  Yes, it is existedUserCompanies[0].name
                                                </option>
                                                <option value="3">
                                                  Yes, it is existedUserCompanies[1].name
                                                </option>
                                              </select>
                                            </div>
                                            <div class="mt-2 text-right">
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Continue
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid logo-invitation"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1 text-center">
                                              <p class="text-justify my-2">
                                                Is the below information provided for <
                                                partnerCompanyName > correct? Please modify the values
                                                if something is not accurate.
                                              </p>
                                              <div class="col-12">
                                                <div class="form-group text-left">
                                                  <label for="companyName" class="text-left"
                                                    >The complete legal name</label
                                                  >
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="companyName"
                                                    placeholder="Partner Company Name"
                                                    value="< partnerCompanyName >"
                                                  />
                                                </div>
                                              </div>
                        
                                              <div class="col-12">
                                                <div class="form-group text-left">
                                                  <label for="companyAddress" class="text-left"
                                                    >Industry group</label
                                                  >
                                                  <select
                                                    class="form-control"
                                                    id="location1"
                                                    name="location"
                                                  >
                                                    <option selected>Industy Group</option>
                                                    <option value="1">
                                                      No, I have never been affiliated with < mCN >
                                                      through SustainLab Platform
                                                    </option>
                                                    <option value="2">
                                                      Yes, it is existedUserCompanies[0].name
                                                    </option>
                                                    <option value="3">
                                                      Yes, it is existedUserCompanies[1].name
                                                    </option>
                                                  </select>
                                                  <label for="companyAddress" class="text-left"
                                                    >Industry</label
                                                  >
                                                  <select
                                                    class="form-control"
                                                    id="location1"
                                                    name="location"
                                                  >
                                                    <option selected>Industy</option>
                                                    <option value="1">
                                                      No, I have never been affiliated with < mCN >
                                                      through SustainLab Platform
                                                    </option>
                                                    <option value="2">
                                                      Yes, it is existedUserCompanies[0].name
                                                    </option>
                                                    <option value="3">
                                                      Yes, it is existedUserCompanies[1].name
                                                    </option>
                                                  </select>
                                                  <label for="companyAddress" class="text-left"
                                                    >In which country is < pCN > registered? If it is
                                                    registered in more than one country, select the
                                                    branch from which < pCN > serves < mCN>
                                                  </label>
                                                  <select
                                                    class="form-control"
                                                    id="location1"
                                                    name="location"
                                                  >
                                                    <option selected>Select country</option>
                                                    <option value="1">
                                                      No, I have never been affiliated with < mCN >
                                                      through SustainLab Platform
                                                    </option>
                                                    <option value="2">
                                                      Yes, it is existedUserCompanies[0].name
                                                    </option>
                                                    <option value="3">
                                                      Yes, it is existedUserCompanies[1].name
                                                    </option>
                                                  </select>
                                                  <label for="companyName" class="text-left"
                                                    >Complete website address (e.g.,
                                                    https://www.example.com)</label
                                                  >
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    id="companyName"
                                                    placeholder="Partner wesite"
                                                    value="www.partner.com"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="mt-2 text-right">
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Continue
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form action="#">
                                            <div class="form-body">
                                              <p class="py-1 text-justify">
                                                We identified another company in our system with a
                                                very similar information as your's. Please compare
                                                their details and help us to know whether both are
                                                related to the same company or not.
                                              </p>
                        
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <h2>Your company Information</h2>
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <h2>Similar company identified</h2>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Complete legal name</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<correctedPartnerCompanyName>"
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Complete legal name</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<idClashingCompany.name>"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Industry group</label>
                                                    <select type="text" class="form-control">
                                                      <option selected>
                                                        < correctedPartnerIndustryGroup >
                                                      </option>
                                                      <option value="1">
                                                        No, I have never been affiliated with < mCN >
                                                        through SustainLab Platform
                                                      </option>
                                                      <option value="2">
                                                        Yes, it is existedUserCompanies[0].name
                                                      </option>
                                                      <option value="3">
                                                        Yes, it is existedUserCompanies[1].name
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Industry group</label>
                                                    <select type="text" class="form-control">
                                                      <option selected>
                                                        < idClashingCompany.industryGroup >
                                                      </option>
                                                      <option value="1">
                                                        No, I have never been affiliated with < mCN >
                                                        through SustainLab Platform
                                                      </option>
                                                      <option value="2">
                                                        Yes, it is existedUserCompanies[0].name
                                                      </option>
                                                      <option value="3">
                                                        Yes, it is existedUserCompanies[1].name
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Industry</label>
                                                    <select type="text" class="form-control">
                                                      <option selected>
                                                        < correctedPartnerIndustry >
                                                      </option>
                                                      <option value="1">
                                                        No, I have never been affiliated with < mCN >
                                                        through SustainLab Platform
                                                      </option>
                                                      <option value="2">
                                                        Yes, it is existedUserCompanies[0].name
                                                      </option>
                                                      <option value="3">
                                                        Yes, it is existedUserCompanies[1].name
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Industry</label>
                                                    <select
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<idClashingCompany.industry>"
                                                    >
                                                      <option selected>
                                                        < idClashingCompany.industry >
                                                      </option>
                                                      <option value="1">
                                                        No, I have never been affiliated with < mCN >
                                                        through SustainLab Platform
                                                      </option>
                                                      <option value="2">
                                                        Yes, it is existedUserCompanies[0].name
                                                      </option>
                                                      <option value="3">
                                                        Yes, it is existedUserCompanies[1].name
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Registered country</label>
                                                    <select
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<correctedPartnerCountry>"
                                                    >
                                                      <option selected>
                                                        < correctedPartnerCountry >
                                                      </option>
                                                      <option value="1">
                                                        No, I have never been affiliated with < mCN >
                                                        through SustainLab Platform
                                                      </option>
                                                      <option value="2">
                                                        Yes, it is existedUserCompanies[0].name
                                                      </option>
                                                      <option value="3">
                                                        Yes, it is existedUserCompanies[1].name
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Registered country</label>
                                                    <select
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<idClashingCompany.country>"
                                                    >
                                                      <option selected>
                                                        < idClashingCompany.country >
                                                      </option>
                                                      <option value="1">
                                                        No, I have never been affiliated with < mCN >
                                                        through SustainLab Platform
                                                      </option>
                                                      <option value="2">
                                                        Yes, it is existedUserCompanies[0].name
                                                      </option>
                                                      <option value="3">
                                                        Yes, it is existedUserCompanies[1].name
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Website</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<correctedPartnerWebsite>"
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label>Website</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="<idClashingCompany.website>"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="row mt-2">
                                                <div class="col-md-8">
                                                  <div class="form-group">
                                                    <select type="text" class="form-control">
                                                      <option selected>
                                                        Which option is correct
                                                      </option>
                                                      <option value="1">
                                                        Both refer to the same company, but my
                                                        information is more accurate
                                                      </option>
                                                      <option value="2">
                                                        Both refer to the same company, but other
                                                        information is more accurate
                                                      </option>
                                                      <option value="3">
                                                        Each of the information refer to a separate
                                                        company
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div class="col-md-4">
                                                  <div class="form-group text-right float-end">
                                                    <button
                                                      type="submit"
                                                      class="btn primary-button custom-button"
                                                    >
                                                      Submit
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form action="#">
                                            <div class="form-body">
                                              <p class="py-1 text-justify">
                                                Unfortunately we cannot register <
                                                correctedPartnerName >. Please contact our
                                                administrator at hello@sustainlab.co
                                              </p>
                                              <div class="mt-2 text-right">
                                                <button
                                                  type="submit"
                                                  class="btn primary-button custom-button"
                                                >
                                                  Home
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <br />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal">
                                            <h4 class="form-section">
                                              <i class="la la-eye"></i> Profile Info
                                            </h4>
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="firstName"
                                                >First Name</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="text"
                                                  formControlName="firstName"
                                                  class="form-control"
                                                />
                                                <div class="invalid-feedback">
                                                  <div>First Name is required</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="lastName"
                                                >Last Name</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="text"
                                                  formControlName="lastName"
                                                  class="form-control"
                                                />
                                                <div class="invalid-feedback">
                                                  <div>Last Name is required</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="companyName"
                                                >Company</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="text"
                                                  formControlName="companyName"
                                                  class="form-control"
                                                  readonly
                                                />
                                              </div>
                                            </div>
                        
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="position"
                                                >Position</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="Your position"
                                                />
                                                <div class="invalid-feedback">
                                                  <div>Position required</div>
                                                </div>
                                              </div>
                                            </div>
                                            <h4 class="form-section">
                                              <i class="ft-mail"></i> Credential's Info
                                            </h4>
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="email"
                                                >Your Email</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="text"
                                                  formControlName="email"
                                                  class="form-control"
                                                  readonly
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="password"
                                                >Password</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="password"
                                                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                                  formControlName="password"
                                                  class="form-control"
                                                  placeholder="Enter Password"
                                                />
                                                <div class="invalid-feedback">
                                                  <div>Password is required</div>
                                                  <div>
                                                    Must contain at least one number and one uppercase
                                                    and lowercase letter, and at least 6 or more
                                                    characters.
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group row">
                                              <label class="col-md-3 label-control" for="cpassword"
                                                >Confirm Password</label
                                              >
                                              <div class="col-md-9 mx-auto">
                                                <input
                                                  type="password"
                                                  formControlName="cpassword"
                                                  class="form-control"
                                                  placeholder="Confirm Password"
                                                />
                                                <div class="invalid-feedback">
                                                  <div>Password is required</div>
                                                  <div>
                                                    Password & Confirm Password does not match.
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-footer m-0 px-0 py-1">
                                              <button
                                                type="submit"
                                                class="btn secondary-button custom-button"
                                                id="btn"
                                              >
                                                Reset
                                              </button>
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Sign Up
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <hr />
                              <hr />
                              <br />
                        
                              <hr />
                              <hr />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1">
                                              <p class="text-justify mb-2">
                                                Are you sure you want to reject the invitation of
                                                <b>Simon Iaffa Nylén</b> from
                                                <b>Brunswick Real Estate</b>? Can you please specfy
                                                the reason or write a message for Simon?
                                              </p>
                                              <label
                                                >Please specify the reason for reject the
                                                invitation</label
                                              >
                                              <select
                                                class="form-control"
                                                id="location1"
                                                name="location"
                                              >
                                                <option selected>
                                                  Reason for rejecting the invite
                                                </option>
                                                <option value="1">
                                                  I don't know this person or their company
                                                </option>
                                                <option value="2">
                                                  I wasn't aware that we are going to connect through
                                                  SustainLab, I need to check it first
                                                </option>
                                                <option value="3">Other reasons</option>
                                              </select>
                                              <label class="mt-2"
                                                >Kindly provide below your reasons for rejecting this
                                                invitation.</label
                                              >
                                              <textarea
                                                class="form-control"
                                                rows="5"
                                                id="comment"
                                                placeholder="It would be great if you can write a message to Simon that he knows  why you are rejecting this invitation."
                                              ></textarea>
                                            </div>
                                            <div class="mt-25 float-end">
                                              <button type="submit" class="btn primary-button">
                                                Reject Invitation
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <hr />
                              <hr />
                              <br />
                              <section class="row flexbox-container">
                                <div class="col-12 d-flex align-items-center justify-content-center">
                                  <div class="col-lg-6 col-md-12 col-10 box-shadow-2 p-0">
                                    <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                      <div class="card-header pb-0">
                                        <div class="card-title text-center">
                                          <img
                                            class="img-responsive img-fluid"
                                            src="/assets/img/logos/SL_Logo_gradient@2x.png"
                                            alt="logo"
                                            style="height: 70px; width: 200px"
                                          />
                                        </div>
                                      </div>
                                      <div class="card-content">
                                        <div class="card-body pb-0 pt-0">
                                          <form class="form form-horizontal text-center p-1">
                                            <div class="row p-1">
                                              <p class="text-justify mb-1">
                                                Thank you for letting us know you that this was not a
                                                right invitation. We will inform
                                                <b>Simon Iaffa Nylén</b> from
                                                <b>Brunswick Real Estate</b> about it.
                                              </p>
                                            </div>
                                            <div class="mt-25 text-right">
                                              <button
                                                type="submit"
                                                class="btn primary-button custom-button"
                                              >
                                                Home
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                        
                              <hr />
                              <hr />
                              <br />
                        
                              <div class="col-lg-8 m-auto">
                                <div class="row" style="background: #6dc49b">
                                  <br />
                                  <div class="col-lg-11 bg-white m-auto p-3 m-1">
                                    <div class="card-content">
                                      <div class="card-body">
                                        <div class="card-text text-center">
                                          <h4 class="form-section">Welcome to SustainLab</h4>
                                          <p>
                                            Please fill in your personal details to create your
                                            account
                                          </p>
                                        </div>
                                        <form class="form task-wrapper p-1">
                                          <div class="form-body">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput1">First Name</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput1"
                                                    class="form-control"
                                                    placeholder="First Name"
                                                    name="fname"
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput2">Last Name</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput2"
                                                    class="form-control"
                                                    placeholder="Last Name"
                                                    name="lname"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput3">E-mail</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput3"
                                                    class="form-control"
                                                    placeholder="E-mail"
                                                    name="email"
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput4">Phone</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput4"
                                                    class="form-control"
                                                    placeholder="Phone"
                                                    name="phone"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput3">Password</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput3"
                                                    class="form-control"
                                                    placeholder="***********"
                                                    name="password"
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput4">Confirm password</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput4"
                                                    class="form-control"
                                                    placeholder="************"
                                                    name="confirm-password"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                        
                                          <div class="text-right mt-2">
                                            <button type="button" class="btn primary-button">
                                              Create account
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="col-lg-8 m-auto">
                                <div class="row" style="background: #6dc49b">
                                  <br />
                                  <div class="col-lg-11 bg-white m-auto p-3 m-1">
                                    <div class="card-content collapse show">
                                      <div class="card-body">
                                        <div class="card-text">
                                          <h4>Hello Sally Miller</h4>
                                          <p>
                                            You have been added by Regio to our platform as a part of
                                            their supply chain. Welcome to our community of
                                            gamechangers that want to accelerate the sustainability
                                            transition through better data management! To get started
                                            we need you to confirm your relationship with Regio and
                                            answer some questions for us.
                                          </p>
                                          <p>
                                            Please confirm your affiliation to the following company
                                          </p>
                                        </div>
                                        <form class="form">
                                          <div class="form-body">
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput1">Company</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput1"
                                                    class="form-control"
                                                    placeholder="RVM Management"
                                                    name="fname"
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput2">Role</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput2"
                                                    class="form-control"
                                                    placeholder="CEO"
                                                    name="lname"
                                                  />
                                                </div>
                                              </div>
                                              <input
                                                class="ml-2 mr-50 mb-1"
                                                type="checkbox"
                                                name="vehicle"
                                                value="Bike"
                                              />
                                              I confirm that the above information is correct.
                                            </div>
                                            <p>
                                              Simon Iaffa, sustainability manager at Regio, added your
                                              company, RVM Mgmt as part of their supply chain in
                                              quality of "LAMP". Can you confirm this collaboration?
                                            </p>
                                            <p>
                                              <input type="radio" class="mt-50" /> Yes, I confirm that
                                              Regio and RVM Mgmt work together.
                                            </p>
                                            <p><input type="radio" /> No</p>
                                            <h4 class="form-section">
                                              <i class="la la-building-o mr-50"></i> Company
                                            </h4>
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput3">Company Name</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput3"
                                                    class="form-control"
                                                    placeholder="Company Name"
                                                    name="company-name"
                                                  />
                                                </div>
                                              </div>
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput4">Headquarters</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput4"
                                                    class="form-control"
                                                    placeholder="Headquarters"
                                                    name="headquarter"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                        
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput5">Industry Group</label>
                                                  <select
                                                    id="projectinput5"
                                                    name="interested"
                                                    class="form-control"
                                                  >
                                                    <option value="none" selected="" disabled="">
                                                      Real Estate & Construction
                                                    </option>
                                                    <option value="design">design</option>
                                                    <option value="development">development</option>
                                                    <option value="illustration">illustration</option>
                                                    <option value="branding">branding</option>
                                                    <option value="video">video</option>
                                                  </select>
                                                </div>
                                              </div>
                        
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput6">Industry</label>
                                                  <select
                                                    id="projectinput6"
                                                    name="budget"
                                                    class="form-control"
                                                  >
                                                    <option value="0" selected="" disabled="">
                                                      Budget
                                                    </option>
                                                    <option value="less than 5000$">
                                                      less than 5000$
                                                    </option>
                                                    <option value="5000$ - 10000$">
                                                      5000$ - 10000$
                                                    </option>
                                                    <option value="10000$ - 20000$">
                                                      10000$ - 20000$
                                                    </option>
                                                    <option value="more than 20000$">
                                                      more than 20000$
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col-md-6">
                                                <div class="form-group">
                                                  <label for="projectinput3">Web Address</label>
                                                  <input
                                                    type="text"
                                                    id="projectinput3"
                                                    class="form-control"
                                                    placeholder="Company Name"
                                                    name="company-name"
                                                  />
                                                </div>
                                              </div>
                                              <p>
                                                <input
                                                  class="ml-2 mr-50 mb-1"
                                                  type="checkbox"
                                                  name="vehicle"
                                                  value="Bike"
                                                />
                                                I confirm that the above information is correct.
                                              </p>
                                            </div>
                                            <div class="form-group">
                                              <label for="projectinput8"
                                                >Is there anything you want to communicate to the
                                                person who added you to the system?</label
                                              >
                                              <textarea
                                                id="projectinput8"
                                                rows="5"
                                                class="form-control"
                                                name="comment"
                                                placeholder="About Project"
                                              >
                        Comment</textarea
                                              >
                                            </div>
                                          </div>
                        
                                          <div class="form-actions text-right">
                                            <button
                                              type="button"
                                              class="btn primary-button custom-button"
                                              data-bs-toggle="modal"
                                              data-target="#success"
                                            >
                                              Finish
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Modal -->
                        
                              <div
                                class="modal fade text-left"
                                id="success"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="myModalLabel12"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header bg-success white">
                                      <h4 class="modal-title white" id="myModalLabel12">
                                        <i class="la la-check-circle white mr-50"></i>Congratulations!
                                      </h4>
                                      <button
                                        type="button"
                                        class="btn close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span
                                          aria-hidden="false"
                                          class="white"
                                          style="
                                            font-size: 26px;
                                            font-weight: 300;
                                            color: white !important;
                                          "
                                          >&times;</span
                                        >
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <p>
                                        Great! RVM Mgmt will now be added to the SL platform as part
                                        of Regio's supply chain. You will get a confirmation email
                                        very soon.
                                      </p>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-add white custom-button">
                                        Ok
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Invitations Forms Themes end -->
          <!-- Alert Modal Themes start-->
          <section id="modal-themes">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Notification Modal Components</h4>

                    <a class="heading-elements-toggle"
                      ><i class="la la-ellipsis font-medium-3"></i
                    ></a>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i class="ft-minus"></i></a>
                        </li>
                        <li>
                          <a data-action="reload"
                            ><i class="ft-rotate-cw"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="expand"
                            ><i class="ft-maximize"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="close"><i class="las la-timesla-times"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row my-2">
                      
                        <div class="col-lg-4 col-md-6 col-sm-12">
                          <div class="form-group">
                            <h5>Success Modal </h5>
                            <p>
                              To use success modal, add
                              <code>.bg-success</code> class to the
                              <code>.modal-header</code> container
                            </p>
                            <!-- Button trigger modal -->
                            <button
                              type="button"
                              class="btn btn-outline-success block btn-lg"
                              data-bs-toggle="modal"
                              data-target="#success"
                            >
                              Launch Modal
                            </button>

                            <!-- Modal -->
                            <div
                              class="modal fade text-left"
                              id="success"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="myModalLabel9"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header bg-success white">
                                    <h4
                                      class="modal-title white"
                                      id="myModalLabel9"
                                    >
                                      Success Modal
                                    </h4>
                                    <button
                                      type="button"
                                      class="btn close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true"><i class="las la-timesla-timesla-timesla-timesla-times white" style="color: white !important;"></i></span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <h5>
                                      Congratulations, you have now completed first step!
                                    </h5>
                                    <p>Would you like to continue with the setup?</p>
                                    
                                   
                                    
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                    type="button"
                                    class="btn btn-outline-danger  custom-button"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-info custom-button"
                                  >
                                    Continue
                                  </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                          <div class="form-group">
                            <h5>Error Modal</h5>
                            <p>
                              To use Error modal, add
                              <code>.bg-danger</code> class to the
                              <code>.modal-header</code> container
                            </p>
                            <!-- Button trigger modal -->
                            <button
                              type="button"
                              class="btn btn-outline-danger block btn-lg"
                              data-bs-toggle="modal"
                              data-target="#danger"
                            >
                              Launch Modal
                            </button>

                            <!-- Modal -->
                            <div
                              class="modal fade text-left"
                              id="danger"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="myModalLabel10"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header bg-danger white">
                                    <h4
                                      class="modal-title white"
                                      id="myModalLabel10"
                                    >
                                      Delete "Material"
                                    </h4>
                                    <button
                                      type="button"
                                      class="btn close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true"><i class="las la-timesla-times white" style="color: white !important;"></i></span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <h5 >Are you sure you want to delete this "Material"?</h5>
                                   
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      class="btn btn-outline-danger  custom-button"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-outline-info custom-button"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                          <div class="form-group">
                            <h5>Warning Modal </h5>
                            <p>
                              To use warning modal, add
                              <code>.bg-warning</code> class to the
                              <code>.modal-header</code> container
                            </p>
                            <!-- Button trigger modal -->
                            <button
                              type="button"
                              class="btn btn-outline-warning block btn-lg"
                              data-bs-toggle="modal"
                              data-target="#warning"
                            >
                              Launch Modal
                            </button>

                            <!-- Modal -->
                            <div
                              class="modal fade text-left"
                              id="warning"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="myModalLabel12"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header bg-warning white">
                                    <h4
                                      class="modal-title white"
                                      id="myModalLabel12"
                                    >
                                     Warning Modal
                                    </h4>
                                    <button
                                      type="button"
                                      class="btn close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true"><i class="las la-times white" style="color: white !important;"></i></span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <h5>
                                       Some Warning message!
                                    </h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                    
                                    
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      class="btn btn-outline-danger  custom-button"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-outline-info custom-button"
                                    >
                                      Continue
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- Form Modal Themes -->
          <section id="modal-themes">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Form Modal Components</h4>

                    <a class="heading-elements-toggle"
                      ><i class="la la-ellipsis font-medium-3"></i
                    ></a>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i class="ft-minus"></i></a>
                        </li>
                        <li>
                          <a data-action="reload"
                            ><i class="ft-rotate-cw"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="expand"
                            ><i class="ft-maximize"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="close"><i class="las la-timesla-times"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row my-2">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <h5>Form Modal - Dark Header</h5>
                            <p>
                                To use black modal theme, add
                                <code>. bg-dark</code> class to the
                                <code>.modal-header</code> container
                              </p>
                              <!-- Button trigger modal -->
                            
                            <button
                              type="button"
                              class="btn btn-outline-dark block btn-lg"
                              data-bs-toggle="modal"
                              data-backdrop="false"
                              data-target="#primary"
                            >
                              Launch Modal
                            </button>

                            <!-- Modal -->
                            <div
                              class="modal fade text-left"
                              id="primary"
                              tabindex="-1"
                              role="dialog"
                              aria-labelledby="myModalLabel8"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-lg" role="document">
                                <div class="modal-content ">
                                  <div class="modal-header" style="background:#405369">
                                    <h4
                                      class="modal-title white"
                                      id="myModalLabel8"
                                    >
                                      Add new supplier(Generic Form Modal)
                                    </h4>
                                    <button
                                      type="button"
                                      class="btn close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true" class="white"><i class="las la-times white" style="color: white !important;"></i></span>
                                    </button>
                                  </div>
                                  <form action="#">
                                    <div class="modal-body">
                                        
                                            <div class="content clearfix">
                                              <div class="form-group">
                                                <div class="row mb-1">
                                                  <div class="col-md-12">
                                                   <h4 class="mb-0 pb-0"><i class="la la-building"></i> Supplier info</h4>
                                                   <hr class="mt-0 pt-0">
                                                   <div class="row">
                                                       <div class="col-6">
                                                              <div class="form-group">
                                                                <label>Company Name*</label>
                                                                <input type="text" class="form-control" placeholder="Company Name" required>
                                                              </div>
                                                       </div>
                                                       <div class="col-6">
                                                        <div class="form-group">
                                                          <label>Industry*</label>
                                                          <input type="text" class="form-control" placeholder="Industry" required>
                                                        </div>
                                                    </div>
                                                   </div>
                                                   </div>
                                                   <div class="col-6">
                                                    <div class="form-group">
                                                      <label>Locations*</label>
                                                      <input type="text" class="form-control" placeholder="Locations">
                                                    </div>
                                                    
                                                  </div>

                                                </div>
                                              
                                                <div class="row mt-1 mb-1">
                                                    <div class="col-md-12">
                                                     <h4 class="mb-0 pb-0"><i class="la la-user"></i> Primary Contact Person</h4>
                                                     <hr class="mt-0 pt-0">
                                                     <div class="row">
                                                         <div class="col-6">
                                                                <div class="form-group">
                                                                  <label>First Name*</label>
                                                                  <input type="text" class="form-control" placeholder="First Name" required>
                                                                </div>
                                                         </div>
                                                         <div class="col-6">
                                                          <div class="form-group">
                                                            <label>Last Name*</label>
                                                            <input type="text" class="form-control" placeholder="Last Name" required>
                                                          </div>
                                                      </div>

                                                      <div class="col-6">
                                                        <div class="form-group">
                                                          <label>Email*</label>
                                                          <input type="text" class="form-control" placeholder="Email" required>
                                                        </div>
                                                 </div>
                                                 <div class="col-6">
                                                  <div class="form-group">
                                                    <label>Phone*</label>
                                                    <input type="text" class="form-control" placeholder="Phone" required>
                                                  </div>
                                              </div>
                                                     </div>
                                                     </div>
                                                     
  
                                                  </div>
                                                  
                                                  <div class="row mt-1 mb-1">
                                                    <div class="col-md-12">
                                                     <h4 class="mb-0 pb-0"><i class="la la-user"></i> Professional information</h4>
                                                     <hr class="mt-0 pt-0">
                                                     <div class="row">
                                                         <div class="col-6">
                                                                <div class="form-group">
                                                                  <label>Company(Completed)</label>
                                                                  <input type="text" class="form-control" placeholder="First Name">
                                                                </div>
                                                         </div>
                                                         <div class="col-6">
                                                          <div class="form-group">
                                                            <label>Organization</label>
                                                            <select class="custom-select form-control" id="location1" name="location">
                                                                <option value="">Select Organization</option>
                                                                <option value="Amsterdam">Amsterdam</option>
                                                                <option value="Berlin">Berlin</option>
                                                                <option value="Frankfurt">Frankfurt</option>
                                                            </select>
                                                          </div>
                                                      </div>

                                                      
                                                     </div>
                                                     </div>
                                                     
  
                                                  </div>
                                                  
                                                  <h5 class="mb-0 pb-0">Platform User</h5>
                                                  <hr class="mt-0 pt-0">
                                                  <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                      <input type="radio" name="platform" value="Yes" >
                                                        <label class="ml-75" >Yes</label>

                                                        <input class="ml-1" type="radio" name="platform" value="No" selected>
                                                        <label class="ml-75">No</label>
                                                        <br>
                                                        <label>Welcome email</label>
                                                            <textarea class="form-control" placeholder="Welcome to SustainLab"></textarea>
                                                        
                                                        </div>
                                                        </div>
                                                        
                                                  </div>

                                               
                                      
                                              </div>
                                            </div>
                                            
                                          
                                    </div>
                                    
                                </form>
                                  <div class="modal-footer">
                                    <button
                                      type="button"
                                      class="btn btn-outline-danger  custom-button"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      class="btn custom-button white btn-add"
                                    >
                                     Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="card m-0" >
                            <div class="card-header">
                              <div class="card-title">
                                <!-- <h4 >Create New Dashboard</h4> -->
                                <h4 >Edit Dashboard</h4>
                              </div>
                              <div class="heading-elements">
                                <ul class="list-inline">
                                  <li><a ><i class="las la-times"></i></a></li>
                                </ul>
                              </div>
                            </div>
                            <div class="card-body">
                              <form >
                                <div class="content clearfix">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <label>Short Title</label>
                                        <input type="text" class="form-control" formControlName="title" id="title" />
                                        <label>Description</label>
                                        <textarea type="text" class="form-control" formControlName="description" id="Description" ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-actions">
                                  <div class="d-flex float-end">
                                    <!-- <button type="submit" class="btn btn-outline-info btn-block custom-button" 
                                      >Create</button> -->
                                    <button type="submit" class="btn btn-outline-info btn-block custom-button" 
                                      >Save Changes</button>
                                    <button type="submit" class="btn btn-outline-danger btn-block custom-button mt-0 ml-1" 
                                      >Delete</button>
                                    <button type="submit" class="btn btn-outline-danger btn-block custom-button mt-0 ml-1"
                                      >Cancel</button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
   
                       
                      </div>
                      
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        <!-- Info Modal -->
        <section id="modal-themes">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Tips Modal Components</h4>

                    <a class="heading-elements-toggle"
                      ><i class="la la-ellipsis font-medium-3"></i
                    ></a>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i class="ft-minus"></i></a>
                        </li>
                        <li>
                          <a data-action="reload"
                            ><i class="ft-rotate-cw"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="expand"
                            ><i class="ft-maximize"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="close"><i class="las la-times"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row my-2">
                       
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            
                            <div class="form-group">
                                <h5>Tips Modal Slide In Right Animation</h5>
                            <p>Tips Slide In Right Animation using <code>.slideInRight</code> class</p>
                            <!-- Button trigger modal -->
                           
                                <i class="la la-info-circle ml-5" data-bs-toggle="modal" data-target="#slideInRight" ></i>
                               
                                
                
                               
                            </div>
                            <div class="modal animated slideInRight text-left h-100" id="slideInRight" tabindex="-1" role="dialog" aria-labelledby="myModalLabel76" aria-hidden="true" data-backdrop="false">
                                <div class="modal-dialog" role="document" style="width:400px; height: 900px;">
                                    <div class="modal-content">
                                        <div class="main-modal-header modal-header">
                                            <h4 class="modal-title white" id="myModalLabel76">Tips</h4>
                                            <button type="button" class="btn close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true"><i class="las la-times white" style="color: white !important;"></i></span>
                                            </button>
                                        </div>
                                        <div class="modal-body tips">
                                            <h4 class="mt-2">Add content to your personal library so you can use it later.</h4>

                                            <p>To add content <b>right click</b> and select <br>
                                            
                                                <b>Add to personal library</b>
                                            </p>

                                            <p>You can add:</p>
                                            <ul>
                                                <li>
                                                    <b>Groups, areas, frameworks</b>
                                                </li>
                                                <li>
                                                    <b>Selection of multiple elements</b>
                                                </li>
                                                <li>
                                                    <b>Drawings</b>
                                                </li>
                                            </ul>
                                            
                                            
                                        </div>
                                        <div class="modal-footer">
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Buttons Modal-->
          
          <!-- Outline Button with Icons start -->
          <section id="outline-button-with-icons">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Action buttons:</h4>
                            <a class="heading-elements-toggle"><i class="la la-ellipsis font-medium-3"></i></a>
                            <div class="heading-elements">
                                <ul class="list-inline mb-0">
                                    <li><a data-action="collapse"><i class="ft-minus"></i></a></li>
                                    <li><a data-action="reload"><i class="ft-rotate-cw"></i></a></li>
                                    <li><a data-action="expand"><i class="ft-maximize"></i></a></li>
                                    <li><a data-action="close"><i class="las la-times"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-content collapse show">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ol>
                                            <li>    
                                                <label>Button when clicked adds/deletes input fields like in Create New KPI modal for value and filter field:</label>
                                                <div class="row">
                                                    <input class="form-control col-4" type="text">
                                                    <a><i class="la la-minus" style="font-size: 2rem;"></i></a> (with tooltip)
                                                </div>
                                                <div class="row">
                                                    <input class="form-control col-4" type="text">
                                                    <a><i class="la la-minus" style="font-size: 2rem;"></i></a> (with tooltip)
                                                </div>
                                                <div class="row">
                                                    <input class="form-control col-4" type="text">
                                                    <a><i class="la la-minus" style="font-size: 2rem;"></i></a> (with tooltip)
                                                </div>
                                                <div class="row">
                                                    <input class="form-control col-4" type="text">
                                                    <a><i class="la la-minus" style="font-size: 2rem;"></i></a>
                                                </div>
                                                <div class="row">
                                                    <button type="button" class="mr-1 mb-1 btn btn-outline-info btn-min-width">Add field</button>
                                                </div>
                                            </li>                  
                                            <br> 
                                            <li>
                                                <label>Buttons that opens a modal to add or delete or confirm something</label>
                                                <p><button type="button" class="mr-1 mb-1 btn btn-outline-info btn-min-width"><i class="la la-plus mr-25"></i>Upload</button>(outline with icon) Ex: Upgrade , Upload </p>
                                            </li>
                                            <li>
                                                <label>Button that adds something directly</label>
                                                <p>
                                                    <button type="button" class="btn btn-outline-secondary btn-min-width dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="la la-plus mr-50"></i>Calculation</button>
                                                    <span class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Another action</a>
                                                        <a class="dropdown-item" href="#">Something else here</a>
                                                    </span>
                                                    Ex: Calculation, Main Class in Data Taxonomy 
                                                </p>
                                            </li>
                                            <li>
                                                <label>Buttons that are on main screen and directly triggers backend changes</label>
                                                <p>
                                                    <a><i class="la la-trash" style="font-size: 2rem;"></i></a>
                                                    <button type="button" class="mrl-2 mb-1 btn btn-info btn-min-width">Save Draft</button>
                                                    (outline) Ex: Save Changes, Save Draft 
                                                </p>
                                            </li>
                                            <li>
                                                <label>Button that performs two steps in one</label>
                                                <p>
                                                    <button type="button" class="btn btn-info btn-min-width">Save & Upgrade</button> 
                                                    Ex: Save & Upgrade
                                                </p>    
                                            </li>
                                            <li>
                                                <label>Button that changes mode like view to edit mode</label>
                                                <p>
                                                    <a><i class="las la-edit" style="font-size: 2rem;"></i></a> or  
                                                    <button type="button" class="btn btn-outline-info btn-min-width"><i class="las la-edit mr-50"></i>Start a new draft</button> 
                                                    Ex: Start a new draft , Continue editing draft
                                                </p>    
                                            </li>
                                            <li>
                                                <label>Buttons to guide user</label>
                                                <p>
                                                    <a><i class="la la-info-circle" style="font-size: 2rem;"></i></a>
                                                    <a><i class="la la-question-circle" style="font-size: 2rem;"></i></a>
                                                </p>
                                            </li>
                                            <li>
                                                <label>Buttons with functions like copy, download</label>
                                                <p>
                                                    <a><i class="la la-copy" style="font-size: 2rem;"></i></a>
                                                    <a><i class="las la-download" style="font-size: 2rem;"></i></a>
                                                    <a><i class="ft-crop" style="font-size: 2rem;"></i></a>
                                                    <a> <i class="las la-file-alt" style="font-size: 2rem;"></i></a>
                                                        
                                                    Ex:  download image , download data , copy(icons with tooltip)
                                                </p>
                                            </li>
                                            <li>
                                                <label>Buttons that filters or sorts out something</label>
                                                <p>
                                                    <button type="button" class="mr-1 mb-1 btn btn-outline-dark btn-min-width"><i class="la la-sort"></i>Sort</button>
                                                    <button type="button" class="mr-1 mb-1 btn btn-outline-dark btn-min-width"><i class="la la-filter"></i>Filter</button>

                                                    || 
                                                    <button type="button" class="mr-1 mb-1 btn  btn-min-width"><i class="la la-sort"></i>Sort</button>
                                                    <button type="button" class="mr-1 mb-1 btn  btn-min-width"><i class="la la-filter"></i>Filter</button>
                                                    Ex: Sort, Filter 
                                                </p>
                                            </li>
                                            <li>
                                                <label>Buttons that sometimes opens modal sometimes just changes UI</label>
                                                <p>
                                                    <button type="button" class="mr-1 mb-1 btn btn-outline-info btn-min-width">Add entity</button>
                                                    <i class="ft-rewind" style="font-size: 2rem;"></i>
                                                     Ex: Add entity, Select another entity, Edit Existing, Back to previous entity
                                                </p>
                                            </li>                  
                                            <li>
                                                <label>Master Button</label>
                                        <div class="form-group">
                                            <!-- Buttons Shadow -->
                                            <button type="button" class="btn btn-warning btn-min-width box-shadow-1 mr-1 mb-1"><i class="la la-plus"></i>Add</button>
                                            
                                        </div>
                                                
                                                
                                            </li>
                                        </ol>
                                    </div>
                                   
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Outline Button with Icons end -->


       
        
       
              

           <!-- Colors Themes start-->
           <section id="modal-themes">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Colors Modal Demo</h4>

                    <a class="heading-elements-toggle"
                      ><i class="la la-ellipsis font-medium-3"></i
                    ></a>
                    <div class="heading-elements">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"><i class="ft-minus"></i></a>
                        </li>
                        <li>
                          <a data-action="reload"
                            ><i class="ft-rotate-cw"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="expand"
                            ><i class="ft-maximize"></i
                          ></a>
                        </li>
                        <li>
                          <a data-action="close"><i class="las la-timesla-timesla-times"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  <div class="card-content collapse show">
                    <div class="card-body">
                      <div class="row my-2" >

                          <div class="col-lg-3" *ngFor="let color of colors">
                              <h4>{{color}}</h4>
                              <div class="color"  [ngStyle]="{backgroundColor:color}">
                                  
                               
                              </div>
                              <br>
                          </div>
                        
                       
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          
          <!-- Colors Themes end -->
        
        <!-- </div> -->
         
        </div>
      </div>
    </div>
  </div>
 
</body>
