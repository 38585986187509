import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { StateServiceFe } from './StateServiceFe';

@Injectable({
  providedIn: 'root',
})
export class CacheStateGuardFe implements CanActivate {
  
  constructor(private stateService: StateServiceFe, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.stateService.checkCacheStatus()
    return true
  }

}