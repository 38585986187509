import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { ColumnModifiedSubjectFe } from '../model/ColumnModifiedSubjectFe';
import { DataGridColumnType } from '../model/DataGridColumnType';
import { DataGridRow } from '../model/DataGridRow';
import { DataGridStatus } from '../model/DataGridStatus';
import { DataGridTableData } from '../model/DataGridTableData';

export interface PageDetailsType{
  totalPages: number;
  currentPage: number;
  pageLimit: number ;
  pages: number[];
}

@Injectable({
    providedIn: 'root'
})

export class DataService{

  pageDetails!: PageDetailsType ;
  tableData!: DataGridTableData;
  isPagination = true;
  paginatedRowData: DataGridRow[] = [];
  paginatedRowDataChanged = new Subject<boolean>();
  columnModified = new Subject<ColumnModifiedSubjectFe>();

  updatedRowInfo: {[key:string]: DataGridRow[]} = {};
  updatedRowInfo$ = new Subject<boolean>();
  selectedRowData!:{row: DataGridRow , allRowIds:any[]};
  selectedRow$= new Subject<boolean>();

  rowHandlerModalResultRow!: {
    row: DataGridRow ,
    rowWithAllExactValues:DataGridRow ,
    allRowIds:any[], 
    updatedParentRowWithAllExactValues:DataGridRow,
    rowInMainTable:DataGridRow,
    allAddedRowsInfo:{[key:string]: DataGridRow}
  };

  rowHandlerModalResult$ = new Subject<boolean>();
  isModifyRefRowModalOpen: boolean = false;
  detailsOfRowToBeHandled! : {
    row : DataGridRow , 
    allRowIds:any[] , 
    allExactValues:any[] , 
    rowInMainTable:DataGridRow
  };
  
  rowInRefTableHandlerType :string = 'add';

  // setPageDetails(details: PageDetailsType): void{
  //   this.pageDetails = details;
  // }

  setTableData(table: DataGridTableData): void{
    this.tableData = table;
    // this.paginateData();
    // this.changePages(0);
  }

  // pageLimitChanged(): void{
  //   this.pageDetails.currentPage = 1;
  //   this.pageDetails.pages = [];
  //   this.changeTotalPages();
  // }

  // changeTotalPages(): void{
  //   this.pageDetails.totalPages = Math.ceil(this.tableData.rowCount as number / this.pageDetails.pageLimit);
  //   if (this.pageDetails.currentPage > this.pageDetails.totalPages) {
  //       this.pageDetails.currentPage = this.pageDetails.totalPages;
  //   }
  // }

  // paginateData(): void{
  //   this.paginatedRowData = [];

  //   this.tableData.rows.map((x, index) => {
  //   this.paginatedRowData.push({
  //       rowId: x.rowId,
  //       status: x.status,
  //       values: [...x.values],
  //       ordinal: index});
  //   });
  //   this.paginatedRowDataChanged.next(true);
  // }

  

  // changePages(index: number): void{
  // }


  // getPaginatedData(): DataGridRow[]{
  //   return [...this.paginatedRowData];
  // }

  saveData(rowArray: FormArray, addedData: DataGridRow[]): void{

    // updating rows data with new data if changed
    addedData.map((rows, j) => {
      if (addedData[j].status === DataGridStatus.MODIFIED || addedData[j].status === DataGridStatus.ADDED){
        addedData[j].status = DataGridStatus.STORED;
        const newData: any[] = [];
        this.tableData.dataSchema?.forEach((col, colNo) => {
          if (col.type === DataGridColumnType.MEASUREMENT) {
            newData.push(rowArray.value[j]['col' + colNo] + ' ' + addedData[j].values[colNo].split(/(\s+)/)[2]);
          }
          else {
            newData.push(rowArray.value[j]['col' + colNo]);
          }
        });
        addedData[j].values = [...newData];
      }
    });


    // updating whole data
    this.tableData.rows = [...addedData];
    // this.paginateData();

  }

  getUpdatedData(): DataGridTableData{
    return this.tableData;
  }

  getPageDetails(): PageDetailsType{
    return this.pageDetails;
  }

  setUpdatedRefRowInfo(rowInfo: DataGridRow[] , key:string){
    this.updatedRowInfo[key]?this.updatedRowInfo[key].push(...rowInfo): this.updatedRowInfo[key]=rowInfo;
    this.updatedRowInfo$.next(true);
  }

}
