import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe';
import { ContactFe } from 'src/app/model/user/ContactFe';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { Subject, Subscription } from 'rxjs';
import { RequestFe } from 'src/app/model/data-suppliers/request/RequestFe';
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe';
import { RowFe } from 'src/app/model/schema/RowFe';
import { SourceFe } from 'src/app/model/schema/SourceFe';
import { StageTableDataGridIntergationService } from '../data/StageTableDataGridIntergationService';
import { MasterTableDataGridIntergationService } from '../../model/taxonomy/MasterTableDataGridIntergationService';
import { EntityFe } from 'src/app/model/taxonomy/EntityFe';
import { RouterFe } from 'src/app/route/RouterFe';
import { RouteServiceFe } from 'src/app/route/RouteServiceFe';
import { RoutesFe } from 'src/app/route/RoutesFe';
import { KpiDataGridIntergationService } from '../kpi/KpiDataGridIntergationService';
import { DatahubService } from '../data-hub/DatahubService';
import { Router } from '@angular/router';
import { DirectEntryInfoFe, OLD_STAGE_TABLE_KEYWORD, STAGE_TABLE_KEYWORD } from 'src/app/model/directEntry/DirectEntryInfoFe';
import { ChartWrapperFeNew } from 'src/app/model/chart/ChartWrapperFeNew';
import { ChartSettingFeNew } from 'src/app/model/chart/ChartSettingFeNew';
import { InsightDefFe } from 'src/app/model/insight/InsightDefFe';
import { TableDataFe } from 'src/app/model/schema/TableDataFe';
import { DataGridTableMode } from '../projects/data-grid-ui/model/DataGridTableMode';

@Component({
  selector: 'app-trace-data',
  templateUrl: './trace-data.component.html',
  styleUrls: ['./trace-data.component.scss']
})
export class TraceDataComponent extends AbstractLanguageComponent implements OnInit {
  
  @Input() chartWrapper: ChartWrapperFeNew;
  @Input() masterTableDataGridServices: MasterTableDataGridIntergationService[] = []
  taxonomyInfo: TaxonomyInfoFe;
  detailsData: any
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInProgress: boolean = false
  showDefinition = true
  isshowMoreButton: boolean = true
  isShowMoreButton1: boolean = true
  isShowMoreButton2: boolean = true
  tableDataGridService: KpiDataGridIntergationService
  kpiDataGridService: KpiDataGridIntergationService
  stageInfo: DirectEntryInfoFe
  stageTableDataGridService: StageTableDataGridIntergationService
  loadingMasterTable = false;
  loadingSource = false;
  masterTableSearchWord: string
  source: string
  requestGroup: RequestGroupFe
  request: RequestFe
  tableSub: Subscription
  masterTableSub: Subscription[]
  isDashboardPage = false
  isTracingKPI = false
  isTracingMasterTable = false
  exportResult = new Subject<boolean>()
  kpiToolbarModal = [
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_kpi",
        visible: () => true,
        disabled: false,
      },
    ],
  ]

  masterToolbarModal = [
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_master",
        visible: () => true,
        disabled: false,
      },
    ],
  ]

  sourceToolbar = [
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.export"),
        longLabel: this.locale("locale_key.general.toolbar.button.export"),
        tooltip: this.locale("locale_key.general.toolbar.button.export"),
        icon: "las la-download",
        actionName: "export_source",
        visible: () => true,
        disabled: false,
      }
    ]
  ]

  userOptions = [
    { label: this.locale("locale_key.pages.data_hub.master_table.data_categories.title"), value: "DATA_CATEGORIES" },
    { label: this.locale("locale_key.pages.data_hub.master_table.sub_categories.title"), value: "SUB_CATEGORIES" },
  ]
  constructor(languageService: LanguageService, private responsive: ResponsiveService, private backendService: RouterFe, private routeService: RouteServiceFe, 
    public datahubService: DatahubService,  private router: Router,
    private stateService: StateServiceFe, private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService
  ) {
    super(languageService)
    this.loadCompanyTaxonomy()
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize;

    this.isDashboardPage = this.routeService.activeRoute == RoutesFe.DASHBOARD
  }

  async ngOnInit() {
    if (this.chartWrapper) {
      this.copyChartWrapper()
      this.setKPIData()
    } else {
      this.setMasterTable()
    }
  }

  copyChartWrapper() {
    let copy = ChartWrapperFeNew.build(this.chartWrapper.insight, this.chartWrapper.setting, this.stateService);
    copy.setting = ChartSettingFeNew.fromTransfer(this.chartWrapper.setting)
    copy.setting.options = this.chartWrapper.setting.options
    this.chartWrapper = copy;
  }

  public async loadCompanyTaxonomy() {
    this.loadingInProgress = true
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
    this.loadingInProgress = false
  }


  async handleToolbarAction(actionName: string, exportType: string) {
    switch (actionName) {
      case 'export_kpi':
        await this.kpiDataGridService.downloadCurrentPage(exportType);
        break;
      case 'export_master':
        this.masterTableDataGridServices.forEach(async service => await service.downloadCurrentPage(exportType))
        break;
      case 'export_source':
        if (this.isSourceDataRequest()) 
          this.exportResult.next(true)
        else 
          await this.stageTableDataGridService.downloadDataEntry(exportType);
        break;
    }
  }

  updateDetailsData(data: ContactFe) {
    this.detailsData = { data }
    this.displayService.updateDetailsData(this.detailsData)
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = "modal-xl") {
    let config = {
      backdrop: false,
      ignoreBackdropClick: false,
      class: className,
    }
    this.modalRef = this.modalService.show(modalTemplateRef, config)
  }

  addActiveClass(event: any) {
    const elements = document.querySelectorAll('[id^="acc"]')
    elements.forEach((element: any) => {
      element.classList.remove("active-accordion")
    })
    let elementId = event.currentTarget.id
    let element = document.getElementById(elementId)
    element.classList.toggle("active-accordion")
  }

  closeModal(close: boolean) {
    if (close) {
      this.modalService.hide(this.modalRef.id)
      document.body.classList.remove("modal-open")
    }
  }

  getTaxonomyEntitiesText() {
    if (this.chartWrapper.insight && this.taxonomyInfo) {
      return this.chartWrapper.insight?.entities?.map(entity => entity.getTaxonomyTitle(this.taxonomyInfo)).join(', ')
    }
  }

  // getMeasurementFields() {
  //   let measurementFields = [];
  //   let entity = this.taxonomyInfo.entityByKey(this.chartWrapper.insight.taxonomyKey);
  //   this.chartWrapper.insight.pipeline.definition.valueFields.forEach((valueField) => {
  //     let valueFieldCol = entity.columns.find(c => c.key == valueField)
  //     if (valueFieldCol) {
  //       measurementFields.push(valueFieldCol)
  //     }
  //   })
  //   return measurementFields
  // }

  expandModal() {
    let modalId = document.getElementById('traceModal')
    modalId.classList.toggle('kpiModalHeight')
  }

  showMoreShowLess(varName: string, event: MouseEvent) {
    this[varName] = !this[varName]
    event.stopPropagation();
  }

  setKPIData(): void {
    this.isTracingKPI = true;
    this.tableDataGridService = new KpiDataGridIntergationService(this.chartWrapper.insight, this.stateService);
    this.tableDataGridService.mode = DataGridTableMode.VIEW
    this.kpiDataGridService = new KpiDataGridIntergationService(this.chartWrapper.insight, this.stateService); 
    this.setKpiTables();
    this.tableSub?.unsubscribe();
    this.tableSub = this.kpiDataGridService.openMasterTable.subscribe(openMasterTable => this.loadMasterTables())
  }

  setMasterTable() {
    this.isTracingMasterTable = true;
    this.traceSource(this.masterTableDataGridServices[0].currentPageData.rows[0])
    this.masterTableSub = [this.masterTableDataGridServices[0].traceSource.subscribe(row => this.traceSource(row))]
  }

  updateTable() {
    this.resetMasterTable();
    this.resetSource();
    this.setKpiTables();
  }

  setKpiTables() {
    this.tableDataGridService.setTable( this.chartWrapper.setting)
    this.kpiDataGridService.setTable( this.chartWrapper.setting)
  }

  resetMasterTable() {
    this.masterTableDataGridServices = [];
    this.masterTableSub = []
  }

  async loadMasterTables() {
    setTimeout(()=> {
      document.getElementById('acc0')?.classList.remove("active-accordion")
      document.getElementById('item')?.classList.add('collapsed');
      document.getElementById('accordion')?.classList.remove('show');
      document.getElementById('acc1')?.classList.add("active-accordion");
      document.getElementById('item1')?.classList.remove('collapsed');
      document.getElementById('accordion1')?.classList.add('show');
      this.isShowMoreButton1 = false;
    })
    this.resetMasterTable();
    this.resetSource();
    this.loadingMasterTable = true;
    let kpi = this.kpiDataGridService.kpi
    let kpiRow = this.kpiDataGridService.selectedRow
    let tables = await this.loadMasterTablesForInsightKpiRow(this.chartWrapper.insight, kpi.id, kpiRow.rowId)
    tables.forEach(table => {
      if (table.entity instanceof EntityFe) {
        let masterTableDataGridService = new MasterTableDataGridIntergationService(table.entity, this.stateService, this.languageService)
        masterTableDataGridService.mode = DataGridTableMode.SELECT_TABLE_ROW
        masterTableDataGridService.currentPageData = table
        this.masterTableDataGridServices.push(masterTableDataGridService)
        let sub = masterTableDataGridService.traceSource.subscribe(row => this.traceSource(row))
        this.masterTableSub.push(sub)
      }
    })
    // await this.masterTableDataGridService.loadMasterTableRowsForKpiRow(this.chartWrapper.insight, kpi.id, kpiRow.rowId);
    this.loadingMasterTable = false;
  }

  async loadMasterTablesForInsightKpiRow(insightDef: InsightDefFe, kpiId: string, kpiRowId: string): Promise<TableDataFe[]> {
    let tables = await this.stateService.getMasterTablesForInsightKpiRow(insightDef, kpiId, kpiRowId);
    return tables;
  }


  resetSource() {
    this.request = null;
    this.requestGroup = null;
    this.stageTableDataGridService = null;
    this.stageInfo = null;
  }

  async traceSource(row: RowFe) {
    setTimeout(()=> {
      document.getElementById('acc1')?.classList.remove("active-accordion")
      document.getElementById('item1')?.classList.add('collapsed');
      document.getElementById('accordion1')?.classList.remove('show');
      document.getElementById('acc2')?.classList.add("active-accordion")
      document.getElementById('item2')?.classList.remove('collapsed');
      document.getElementById('accordion2')?.classList.add('show');
      this.isShowMoreButton2 = false;
    })
    this.loadingSource = true;
    this.source = row.mgmtRowValues[1]
    if (this.isSourceDataRequest()) {
      let rid = SourceFe.getRequestId(row.mgmtRowValues[2])  //mgmtRowValues[2] = `${requestTaskId}.${sectionId}`
      let allr = await this.stateService.getAllRequests();
      this.request = allr.find(r => r.id === rid);
      let allrg = await this.stateService.getRequestGroups();
      this.requestGroup = allrg.find(rg => rg.id == this.request.requestGroupId);
    } else if (this.isSourceDirectEntry()) {
      let tableName = row.mgmtRowValues[2].replaceAll(OLD_STAGE_TABLE_KEYWORD, STAGE_TABLE_KEYWORD)
      let allStageInfos = await this.stateService.getStageTableInfos();
      this.stageInfo = allStageInfos.find(info => info.dbTableName() == tableName)
      let entity = this.taxonomyInfo.entityByKey(this.stageInfo.taxonomyKey)
      this.stageTableDataGridService = new StageTableDataGridIntergationService(this.stageInfo, entity, this.backendService, this.languageService)
      if (!this.stageInfo.hasContent()) {
        await this.stageTableDataGridService.loadMainTablePage()
      } else {
        this.stageTableDataGridService.currentPageData = this.stageInfo.tableData
      }
      this.stageTableDataGridService.mode = DataGridTableMode.VIEW
    }
    this.loadingSource = false;
  }

  isSourceDataRequest() {
    return this.source == SourceFe.DATA_REQUEST
  }

  isSourceDirectEntry() {
    return this.source == SourceFe.DIRECT_ENTRY
  }

  isDownloading(): boolean {
    return this.kpiDataGridService?.downloading || !!this.masterTableDataGridServices.find(service => service.downloading) || this.stageTableDataGridService?.downloading
  }

  openInReq() {
    this.displayService.setRequestFromHome(this.request.timelineItems[0]);
    this.router.navigate([RoutesFe.REQUESTS.fullPath()]);
  }

  openKpiLibrary() {
    // this.stateService.selectInsight(this.chartWrapper.insight);
    this.router.navigate([RoutesFe.USER_KPI.fullPath()]);
  }

  openDirectEntry() {
    this.datahubService.showDataEntry(this.stageInfo)
    this.router.navigate([RoutesFe.DATA_HUB.fullPath()]);
  }

  openMasterTable() {
    // this.datahubService.showEntityMasterTable(this.entity)
    this.router.navigate([RoutesFe.DATA_HUB.fullPath()]);
  }
}
