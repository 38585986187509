import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject, Subscription } from 'rxjs'
import { CompanyFe } from './../../model/data-suppliers/company/CompanyFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { ValidationMessages } from './../../model/form-validation/FormValidationMessages'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { ManagerInvitationFe } from 'src/app/model/invitation/ManagerInvitationFe'
import { AccessibleCompanyFe } from 'src/app/model/org/AccessibleCompanyFe'
import { ManagerInvitation_AddFe } from 'src/app/model/invitation/ManagerInvitation_AddFe'
import { RouterFe } from 'src/app/route/RouterFe'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { InvitationLinkFe } from 'src/app/model/email/InvitationLinkFe'

import { ManagerFe } from 'src/app/model/user/ManagerFe'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { Router } from '@angular/router'
import { Language } from 'src/app/utils/language/Language'
import { RoutesFe } from 'src/app/route/RoutesFe'

@Component({
  selector: 'app-manager-entry',
  templateUrl: './manager-entry.component.html',
  styleUrls: ['./manager-entry.component.scss'],
})
export class ManagerEntryComponent extends AbstractLanguageComponent implements OnInit {
  form2 = new FormGroup({
    managerFirstName: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.NonWhitespaceRegExp), Validators.minLength(1)]),
    managerLastName: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.NonWhitespaceRegExp), Validators.minLength(1)]),
    managerEmail: new FormControl('', [Validators.required, Validators.pattern(ValidationRegex.EmailRegex)]),
    companyId: new FormControl('', [Validators.required]),
  })

  form3 = new FormGroup({
    emailSubject: new FormControl('', [Validators.required]),
    emailContent: new FormControl('', [Validators.required]),
  })
  activeFormNo = 2
  inviterCompany: string = 'SL'
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  companyNameMessage = this.locale('locale_key.general.validation_message.company_name_required')
  companyWebFromatMessage = this.locale('locale_key.general.validation_message.invalid_web_address')
  firstNameMessage = this.locale('locale_key.general.validation_message.first_name_required')
  firstNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_first_name')
  lastNameMessage = this.locale('locale_key.general.validation_message.last_name_required')
  lastNameCapitalMessage = this.locale('locale_key.general.validation_message.invalid_last_name')
  emailMessage = this.locale('locale_key.general.validation_message.email_required')
  lowerCaseEmail = this.locale('locale_key.general.validation_message.invalid_email')
  industryGroupChangeVal: boolean = false
  industryChangeVal: boolean = false

  managementCompanies: AccessibleCompanyFe[] = []
  isLoading = false

  modalHeaderText = this.locale('locale_key.pages.dashboard.email_template.message.title')
  modalBodyText = this.locale('locale_key.pages.dashboard.email_template.message.body')
  cancelButtonText = this.locale('locale_key.delete_confirmation_modal.button.cancel')
  actionButtonText = this.locale('locale_key.pages.dashboard.email_template.action.button')

  @Output() supplierInfo = new Subject<ManagerInvitationFe>()
  @Input() selectedSupplier!: ManagerInvitationFe | CompanyFe
  @Input() mode = 'add'
  invitationLink: InvitationLinkFe
  invitationLinkGenerated = false
  newManager: ManagerInvitation_AddFe
  inviteAgainManager: ManagerFe
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  activeLanguage: Language
  lang: string
  selectedLang: Language
  disableEnglish: boolean
  includeEnglish: boolean = false
  showNavigateModal = false
  private languageSubscription: Subscription
  languages = this.languageService.availableLanguages

  steps = [
    { title: this.locale('locale_key.pages.clients.invite_manager_wizard.manager_info'), completed: false },
    { title: this.locale('locale_key.pages.clients.invite_manager_wizard.invitation_email.title'), completed: false },
  ]

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    private stateService: StateServiceFe,
    private loginService: LoginServiceFe,
    private routerFe: RouterFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private router: Router,
  ) {
    super(languageService)
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize
  }

  async ngOnInit(): Promise<void> {
    let companies = this.loginService.getUserInfo().accessibleCompanies
    this.managementCompanies = companies.filter((c) => c.companyType == 'MANAGEMENT')
    if (this.stateService.inviteAgainManager) {
      const user = this.stateService.inviteAgainManager
      this.form2.setValue({
        managerFirstName: user.userFirstName,
        managerLastName: user.userLastName,
        managerEmail: user.affiliationEmail,
        companyId: user.affiliationCompanyId,
      })
    }

    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    let langCode: string = await this.getCompanyEmailLanguage()

    this.selectedLang = this.languageService.getLanguageObject(langCode)
    this.updateDisableEnglish(this.selectedLang.code)
  }

  updateIncludeEnglish(langCode) {
    this.includeEnglish = langCode !== 'en'
  }

  updateDisableEnglish(langCode) {
    this.disableEnglish = langCode === 'en'
  }

  updateLanguage(lang: Language) {
    this.selectedLang = lang
    this.updateDisableEnglish(lang.code)
  }

  async getCompanyEmailLanguage(): Promise<any> {
    try {
      let langCode = await this.stateService.getCompanyEmailLanguage()
      return langCode ? langCode : 'en'
    } catch (err) {}
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  onIncludeEnglishChange(event: Event) {
    this.includeEnglish = event.target['checked']
  }

  navigateToEditTemplate() {
    this.onCancel()
    this.closeModal()
    this.router.navigate([RoutesFe.PROFILE.fullPath()], { queryParams: { tab: 'company-settings' } });
  }

  openModal() {
    this.showNavigateModal = true
  }

  onCancel() {
    this.showNavigateModal = false
  }

  async addNewManager() {
    this.isLoading = true
    this.steps[this.currentActive - 1].completed = true
    this.newManager.emailLanguage = this.selectedLang.code
    this.newManager.includeEnglish = this.includeEnglish
    await this.stateService.inviteNewManager(this.newManager)
    this.isLoading = false
    this.closeModal()
  }

  async getLink() {
    this.isLoading = true
    this.invitationLinkGenerated = false
    this.invitationLink = await this.routerFe.generateInvitation()
    let inviteeCompany = this.managementCompanies.find((c) => c.companyId == this.form2.value.companyId)
    this.newManager = new ManagerInvitation_AddFe(
      this.invitationLink.uuid,
      this.form2.value.companyId,
      inviteeCompany.companyName,
      this.form2.value.managerFirstName,
      this.form2.value.managerLastName,
      this.form2.value.managerEmail,
      '',
      '',
      `${this.invitationLink.serverAddress}/op/register/manager/${this.invitationLink.uuid}`,
      this.selectedLang.code,
      this.includeEnglish,
    )
    this.invitationLinkGenerated = true
    this.isLoading = false
    this.next()
  }

  closeModal(): void {
    this.modalService.hide()
    document.body.classList.remove('modal-open')
  }

  next(): void {
    this.steps[this.currentActive - 1].completed = true
    const circles = this._document.querySelectorAll('.circle1')
    const steps = this._document.querySelectorAll('.stepper')
    this.currentActive++
    if (this.currentActive > circles.length) {
      if (this.currentActive > steps.length) this.currentActive = circles.length
      this.currentActive = this.steps.length
    }
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.steps[this.currentActive - 1].completed = false
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  isCompleted(index: number) {
    return this.steps[index - 1].completed
  }

  update() {
    this.progress = this._document.getElementById('progress1') as HTMLElement
    this.circles = this._document.querySelectorAll('.circle1')
    this.prevButton = this._document.getElementById('prev') as HTMLButtonElement
    this.nextButton = this._document.getElementById('next') as HTMLButtonElement

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add('active-circle1')
      } else {
        circle.classList.remove('active-circle1')
      }
    })

    const actives = this._document.querySelectorAll('.active-circle1')

    this.progress.style.width = ((actives.length - 1) / (this.circles.length - 1)) * 62 + '%'
  }
}
