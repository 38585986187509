<div *ngIf="isLoadingData">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div class="card m-0" *ngIf="!isLoadingData">
  <div class="main-modal-header modal-header py-0 ps-3 pe-0">
    <h4 *ngIf="!edit && !duplicate && !kpi" class="modal-title text-white">{{locale('locale_key.pages.dashboard.add_modal.title')}}</h4>
    <h4 *ngIf="edit" class="modal-title text-white">{{locale('locale_key.pages.dashboard.edit_modal.title')}}</h4>
    <h4 *ngIf="duplicate" class="modal-title text-white">{{locale('locale_key.pages.dashboard.duplicate_modal.title')}}</h4>
    <h4 *ngIf="kpi" class="modal-title text-white">{{locale('locale_key.pages.dashboard.add_kpi_modal.title')}}</h4>
    <button type="button" class="btn ms-auto close" (click)="cancel()" *ngIf="!inProgress">
      <span aria-hidden="false" class="list-inline text-white"><a><i class="las la-times text-white"></i></a></span>
    </button>
  </div>
  <form [formGroup]="form">
    <div class="modal-body">
      <div class="content clearfix">
        <div class="form-group">
          <div class="row px-3">
            <div class="col-md-12">
              <ng-container *ngIf="!kpi">
                <label>{{locale('locale_key.pages.dashboard.modal.short_title')}}</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="title"
                  id="title"
                  placeholder="{{locale('locale_key.pages.dashboard.modal.short_title.placeholder')}}"
                  [ngClass]="{
                    'is-invalid': form.get('title').errors && (form.get('title').touched || form.get('title').dirty)
                  }"
                />
                <div *ngIf="form.get('title').errors && (form.get('title').touched || form.get('title').dirty)" class="invalid-feedback">
                  <div *ngIf="form.get('title').errors?.required">
                    {{ titleMessage }}
                  </div>
                </div>
                <label class="mb-0">{{locale('locale_key.pages.dashboard.description.title')}}</label>
                <textarea type="text" class="form-control" formControlName="description" id="Description"></textarea>
              </ng-container>
              <label class="mb-0 pb-0">{{locale('locale_key.pages.dashboard.modal.new_kpis.title')}}</label>
              <div class="modal-body mh-300 mt-0 px-0 pt-0">
                <small>{{locale('locale_key.pages.dashboard.modal.select_kpi')}}</small>
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover" style="font-size: 0.8rem; font-weight: 400">
                    <thead>
                      <tr>
                        <th class="p-1" style="font-weight: 500">KPI {{locale('locale_key.pages.dashboard.modal.select_kpi')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let kpi of kpis">
                        <tr *ngIf="!kpi.errorCode">
                          <td class="p-1 kpiItems" style="font-weight: 400">
                            <button type="button" class="btn btn-sm btn-light p-0 mb-2 me-2" 
                              id="{{ kpi.title }}"                          
                              (click)="addChartToDashboard(kpi)"
                            >
                              <i class="la la-plus"></i>                           
                            </button> 
                            <span class="input-kpi inline-block me-3 option-input"> {{ kpi.title }}</span>                                             
                          </td>
                        </tr>
                      </ng-container>

                    </tbody>
                  </table>
                </div>
                <small>{{locale('locale_key.pages.dashboard.modal.add_kpis_to_dashboard')}}</small>
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover" style="font-size: 0.8rem; font-weight: 400">
                    <thead>
                      <tr>
                        <th class="p-1" style="font-weight: 500">KPI {{locale('locale_key.pages.dashboard.modal.select_kpi.name_column.title')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let chart of charts; let i = index">
                        <tr class="">
                          <td class="p-1 kpiItems d-flex flex-row justify-content-between w-100" style="font-weight: 400" *ngIf="!chart.wrapper.hasError" >
                            <span class="input-kpi me-3 option-input me-auto" >{{ chart.wrapper.insight.title }}</span> <i class="la la-trash ms-auto me-3" id="{{chart.wrapper.insight.id }}" (click)=" deleteChart(i)"></i>                       
                          </td>
                          <td class="p-1 kpiItems d-flex flex-row justify-content-between w-100" style="font-weight: 400" *ngIf="chart.wrapper.hasError" >
                            <span class="input-kpi danger me-3 option-input me-auto" *ngIf="chart.wrapper.hasError" >{{ locale("locale_key.pages.dashboard.details.incomplete_kpi") }}</span> <i class="la la-trash ms-auto me-3" (click)=" deleteChart(i)"></i>                       
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>          
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="modal-footer" style="border-top: 1px solid lightgray !important">
    <div class="ball-grid-pulse loader-primary" *ngIf="inProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!inProgress">
      <button type="submit" class="btn secondary-button custom-button" *ngIf="!duplicate" (click)="cancel()">{{locale('locale_key.pages.dashboard.add_kpi_modal.button.cancel')}}</button>
      <button
        type="submit"
        class="btn btn-add custom-button"
        (click)="saveChages()"
        *ngIf="!edit && !duplicate && !kpi"
        [disabled]="(form.get('title').value == '' && form.get('title').value == null && form.get('title').value == undefined) || form.get('title').pristine || form.get('title').errors?.required"
      >
        {{locale('locale_key.pages.dashboard.add_dashboard_modal.button.add')}}
      </button>
      <button type="submit" class="btn btn-add custom-button" (click)="saveChages()" *ngIf="edit">{{locale('locale_key.pages.dashboard.add_kpi_modal.button.save')}}</button>
      <button type="submit" class="btn btn-add custom-button" (click)="saveChages()" *ngIf="duplicate">{{locale('locale_key.pages.dashboard.add_kpi_modal.button.duplicate')}}</button>
      <button type="submit" class="btn btn-add custom-button" (click)="saveChages()" *ngIf="kpi">{{locale('locale_key.pages.dashboard.add_kpi_modal.button.add-kpis')}}</button>
    </ng-container>
  </div>
</div>
