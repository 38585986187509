import { FormControl, FormGroup, Validators } from "@angular/forms"
import { QuestionFe } from "./QuestionFe"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { QuestionTypeConversion } from "./QuestionTypeConversionFe"
import { QuestionDateFe } from "./QuestionDateFe"
import { QuestionDataTableFe } from "./QuestionDataTableFe"
import { IdUtil } from "src/app/utils/IdUtil"
import { DataTableRequestFieldFe } from "./DataTableRequestFieldFe"

export class SectionFe {
  //Extra properties for the frontent elements
  nameControl = new FormControl(null, Validators.required)
  isTaxonomyConnected = true
  entity: EntityFe
  editTaxonomy = true

  isConnected: boolean

  constructor(public id: string, public name: string | null, public taxonomyKey: string | null, public taxonomyVersion: number | null, public questions: QuestionFe[], public taxonomyInfo?: TaxonomyInfoFe) {
    this.nameControl.patchValue(this.name)
    this.isTaxonomyConnected = taxonomyKey ? true : false
    if (this.taxonomyKey && taxonomyInfo) {
      this.entity = taxonomyInfo.entityByKey(this.taxonomyKey)
    }
  }

  setTaxonomy(entity: EntityFe, taxonomy: TaxonomyInfoFe) {
    this.taxonomyKey = entity.key;
    this.taxonomyVersion = taxonomy.version;
    this.entity = entity;
    this.questions.forEach((ques) => {
      ques.isMapped = false;
      ques.mappedToColKeys = null;
      if (ques instanceof QuestionDateFe && ques.range) {
        ques.mappedToColKeysRange = null;
        ques.mappedToColsRange = [];
      }
      ques.mappedToCols = [];
      if (ques instanceof QuestionDataTableFe) {
        ques.fields = [];
        let field = new DataTableRequestFieldFe(IdUtil.next(), null, null, "STRING", true, [], [], true, null, null, null);
        ques.customFields = [field];
        ques.addEntityColumn(field);
      }
    });
  }

  public static fromTransfer(transfer: any, taxonomyInfo?: TaxonomyInfoFe): SectionFe {
    let questions = transfer.questions.map((q) => QuestionTypeConversion.fromTransfer(q, transfer.id))
    let sectio = new SectionFe(transfer.id, transfer.name, transfer.taxonomyKey, transfer.taxonomyVersion, questions, taxonomyInfo)
    return sectio
  }

  intializeNameControl() {
    this.isTaxonomyConnected = false
    this.entity = null
    this.taxonomyKey = null
    this.taxonomyVersion = null
  }

  initializeTaxonomy() {
    this.isTaxonomyConnected = true
    this.nameControl.patchValue(null)
    this.name = null
  }

  confirmSectionName() {
    if (this.nameControl.errors) {
      this.nameControl.markAllAsTouched()
      return
    }
    this.name = this.nameControl.value
    this.editTaxonomy = false
  }

  getQuestionsWithoutParent(): QuestionFe[] {
    return this.questions.filter((question) => !question.isFollowUpQues)
  }

  getChildrenOfParent(parentId: string): QuestionFe[] {
    return this.questions.filter((question) => question.parentQuestionId === parentId)
  }
}
