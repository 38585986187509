import { BooleanStatementFe } from "./BooleanStatementFe";
import { ValueStatementFe } from "./ValueStatementFe";

export class BooleanValueStatementFe extends ValueStatementFe {
  public static TYPE_KEY = 'BOOLEAN' 
  public type = BooleanValueStatementFe.TYPE_KEY 
  public static START_KEYWORD = '@'
  
  constructor(value: boolean) {
    super(value)
  }
  
  public toFormulaString(): string {
    let str = [`${BooleanValueStatementFe.START_KEYWORD}`,
               `${this.value}`].join('');
    return str
  }

  public static fromFormulaString(formula: string): BooleanValueStatementFe {
    let nextSpace = formula.indexOf( BooleanStatementFe.SPACE);
    let value = formula.substring(BooleanValueStatementFe.START_KEYWORD.length, nextSpace);
    if (value == 'true') {
      return new BooleanValueStatementFe(true)
    } else if (value == 'false') {
      return new BooleanValueStatementFe(true)
    } else {
      // throw new ServerError(ServerErrorCode.UNRECOGNIZED_BOOLEAN_VALUE)
    }
  }

  public evaluate(values: Map<string, any>): boolean {
    return this.value
  }

  public static fromTransfer (transfer: any): BooleanValueStatementFe {
    let value = transfer['value']
    let statement = new BooleanValueStatementFe(value)
    return statement
  }
}
