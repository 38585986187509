import { FormControl, Validators } from "@angular/forms";
import { DateUtil } from "src/app/utils/DateUtil";
import { QUESTION_TYPE } from "../../../request/questionnaire/QuestionTypeFe";
import { AnswerFe } from "../../answer/AnswerFe";
import { Answer_AddFe } from "./Answer_AddFe";

export class AnswerDate_AddFe extends Answer_AddFe{
  dateFrom: Date
  dateFromInput: string = ''
  dateTo: Date | null
  dateToInput: string = ''
  range: boolean

 constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    dateFrom: Date,
    dateTo: Date | null,
    range: boolean
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.DATE,
      comments
    )
    this.dateFrom = dateFrom ? new Date(dateFrom) : dateFrom
    this.dateFromInput = dateFrom ? dateFrom.toISOString().split('T')[0] : ''    
    this.dateTo = dateTo ? new Date(dateTo) : dateTo
    this.dateToInput = dateTo ? dateTo.toISOString().split('T')[0] : ''    
    this.range = range
  }

  getDateFromString () {
    if (this.dateFromInput != '') {
      this.dateFrom = new Date(this.dateFromInput)
      return DateUtil.toString2(this.dateFrom)
    } else if (this.dateFrom) {
      this.dateFrom = new Date(this.dateFrom)
      return DateUtil.toString2(this.dateFrom)
    }
  }

  getDateToString () {
    if (this.dateToInput != '') {
      this.dateTo = new Date(this.dateToInput)
      return DateUtil.toString2(this.dateTo)
    } else if (this.dateTo) {
      this.dateTo = new Date(this.dateTo)
      return DateUtil.toString2(this.dateTo)
    }
  }

  setDateFrom (date: string) {
    this.dateFromInput = date;
    if (date!= '')
      this.dateFrom = new Date(this.dateFromInput)
    else
      this.dateFrom = null  
  }

  setDateTo (date: string) {
    this.dateToInput = date;
    if (date!= '')
      this.dateTo = new Date(this.dateToInput)
    else
      this.dateFrom = null
  }

  public isInvalid (isRequired: boolean): boolean {
    if (isRequired) {
      return !this.hasAnyValue()
    }
    return false
  }

  public hasAnyValue(): boolean {
    return (this.dateFrom && this.dateFromInput != '')  && (this.range ? this.dateTo && this.dateToInput != '' : true)
  }

  public reset (): void {
    this.dateFrom = null
    this.dateFromInput = ''
    this.dateTo = null
    this.dateToInput = ''
  }

  public toTransfer() {
    let ans = new AnswerDate_AddFe(
      this.id,
      this.sectionId,
      this.questionId,
      this.comments,
      this.dateFrom,
      this.dateTo,
      this.range
    )
    return ans
  }
}
