<div class="modal-content">
    <div class="main-modal-header modal-header p-2">
      <h4 class="modal-title white"> {{type == "add" ? "Add new "+table.name : "Edit "+ (type == "edit" ? table.name : parentTableToBeUpdated.name)}}</h4>
      <button type="button" class="btn ms-auto close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="false" class="list-inline white" ><a (click)='closeModal()'><i class="las la-times"></i></a></span>
      </button>
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="tableData" >
        <div class="form-body">
          <div class="card-text">
            <p *ngIf="type =='edit'">Warning! All modifications you apply to this {{table.name}} will be reflected in all other data where you are using this {{table.name}}.</p>
          </div>
          <h4 class="form-section mb-50">{{table.name}}</h4>
          <div class="row" [formGroupName]="table.key!">
              <div class="col-md-6" *ngFor='let col of table.dataSchema;let i =index'>
                  <div class="form-group">
                      <label>{{col.label}}</label>
                      <input class='form-control' [formControlName]='"col"+i' [readOnly]='type == "add Parent"'>
                  </div>
              </div>
          </div>
          <div class="card-text">
            <p class="mt-1" *ngIf="type =='add Parent'"> Warning! All modifications you apply to the {{parentTableToBeUpdated.name}} will be reflected in all other {{table.name}} where you are using this {{table.name}}.</p>
          </div>
          <ng-container *ngFor='let refTable of allRefTables'>
            <h4 class="form-section mb-50">
              {{refTable.name}}
              <ng-container *ngIf='this.isANewEntry[this.parentInfo[refTable.key!]]'>
                <button class="btn btn-add custom-button text-truncate float-end d-inline mt-0 mr-1" (click)="addNew(refTable.key!)"
                  *ngIf="this.isANewEntry[this.parentInfo[refTable.key!]] && this.isSelectingFromTable[refTable.key!]" >
                  Add new {{refTable.name}} 
                </button>
                <button class="btn btn-add custom-button text-truncate float-end d-inline mt-0 mr-1" (click)='selectFromTable(refTable.key!)'
                  *ngIf="this.isANewEntry[this.parentInfo[refTable.key!]] && this.isANewEntry[refTable.key!]" >
                  Select from {{refTable.name}}
                </button>
              </ng-container>
            </h4>
            <div class="row" [formGroupName]="refTable.key!">
              <div class="col-12 mb-1" *ngIf="isSelectingFromTable[refTable.key!] && !selectedRows[refTable.key!]">
                <datagrid-table-editor [dataGrid]="refTableService[refTable.key!]"  [isInsideRefRowHandlerModal]="true"></datagrid-table-editor>
              </div>
              <div class="col-md-6" *ngFor='let col of refTable.dataSchema;let i =index'>
                  <div class="form-group">
                      <label>{{col.label}}</label>
                      <input class='form-control' [formControlName]='"col"+i' [readOnly]="!this.isANewEntry[refTable.key!]">
                  </div>
              </div>
            </div>
          </ng-container>
        </div>
    </form>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger btn-block custom-button" (click)='closeModal()'>
        Cancel</button>
      <button type="submit" class="btn btn-add custom-button" (click)='handleRow()'>
          {{type=="add" ? "Add" : "Save"}}
      </button>
  </div>
</div>
