<div class="csrdInfoTab">
    <div class="datapointName">
        <div class="status phaseIn" *ngIf="datapoint.phaseIn">
            <i class="la la-clock icon"></i>
        </div>
        <div class="status mayDisclose" *ngIf="datapoint.isVoluntary">
            <i class="la la-question icon"></i>
        </div>
        <div class="status conditional" *ngIf="datapoint.isConditional">
            <i class="las la-code-branch icon"></i>
        </div>
        <div class="label">{{ datapoint.id }} {{ datapoint.name }}</div>
    </div>
    <div class="accordion">
        <div class="accordion-item phaseIn" *ngIf="datapoint.phaseIn">
            <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    <i class="la la-clock icon"></i> {{ slocale("Phase-in or effective date") }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                <div class="accordion-body">
                    {{ datapoint.phaseIn }}
                </div>
            </div>
        </div>
        <div class="accordion-item conditional" *ngIf="datapoint.conditional">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                    <i class="las la-code-branch icon"></i> {{ slocale("Conditional") }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                <div class="accordion-body">
                    {{ datapoint.conditional }}
                </div>
            </div>
        </div>
        <div class="accordion-item paragraphInformation" *ngIf="datapoint.description">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    {{ slocale("Paragraph information") }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                <div class="accordion-body">
                    {{ datapoint.description }}
                </div>
            </div>
        </div>
        <div class="accordion-item applicationRequirements" *ngIf="datapoint.applicationRequirements">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#applicationRequirementsBody" aria-expanded="false" aria-controls="applicationRequirementsBody">
                    {{ slocale("Application Requirements") }}
                </button>
            </h2>
            <div id="applicationRequirementsBody" class="accordion-collapse collapse" aria-labelledby="applicationRequirementsBody">
                <div class="accordion-body">
                    <ng-container *ngFor="let applicationRequirement of datapoint.applicationRequirements">
                        <p>{{ applicationRequirement }}</p>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="accordion-item additionalReferences" *ngIf="datapoint.supplementalReference">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#additionalReferencesBody" aria-expanded="false" aria-controls="additionalReferencesBody">
                    {{ slocale("Additional References") }}
                </button>
            </h2>
            <div id="additionalReferencesBody" class="accordion-collapse collapse" aria-labelledby="additionalReferencesBody">
                <div class="accordion-body">
                    {{ datapoint.supplementalReference }}
                </div>
            </div>
        </div>
        <div class="accordion-item footnotes" *ngIf="datapoint.applicationFootnotes">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#footnotesBody" aria-expanded="false" aria-controls="footnotesBody">
                    {{ slocale("Footnotes") }}
                </button>
            </h2>
            <div id="footnotesBody" class="accordion-collapse collapse" aria-labelledby="footnotesBody">
                <div class="accordion-body">
                    {{ datapoint.applicationFootnotes }}
                </div>
            </div>
        </div>
        <div class="accordion-item relationToOtherStandards" *ngIf="datapoint.LegislationLocation">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#relationToOtherStandardsBody" aria-expanded="false" aria-controls="relationToOtherStandardsBody">
                    {{ slocale("Relation to other standards") }}
                </button>
            </h2>
            <div id="relationToOtherStandardsBody" class="accordion-collapse collapse" aria-labelledby="relationToOtherStandardsBody">
                <div class="accordion-body">
                    {{ datapoint.LegislationLocation }}
                </div>
            </div>
        </div>
    </div>
</div>