import { TaskDataGridIntegrationService } from "src/app/components/data-suppliers/data-supplier-task/TaskDataGridIntegrationService";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { QUESTION_TYPE } from "../../../request/questionnaire/QuestionTypeFe";
import { AnswerFe } from "../../answer/AnswerFe";
import { Answer_AddFe } from "./Answer_AddFe";
import { DataGridTableData } from "src/app/components/projects/data-grid-ui/model/DataGridTableData";

export class AnswerDataTable_AddFe extends Answer_AddFe{
  managementCompanyId: string
  dataStorageId: string

  draftTable: TableDataFe
  table: DataGridTableData
  dataGridService = new TaskDataGridIntegrationService(new TableDataFe())
  isLoading = false
  isOpenedOnce = false

  constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    managementCompanyId: string,
    dataStorageId: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.DATA_TABLE,
      comments
    )
    this.managementCompanyId = managementCompanyId
    this.dataStorageId = dataStorageId    
  }

  public isInvalid (isRequired: boolean): boolean {
    if (this.dataGridService.observer) {
      this.dataGridService.isDataValid = this.dataGridService.observer.checkDataValidity();
      if (!this.dataGridService.isDataValid)
        return true;
      if (isRequired)
        return !this.hasAnyValue();
    }
    return false
  }

  public hasAnyValue(): boolean {
    if (this.dataGridService.observer) {
      let table = this.dataGridService.table
      return table.rows.length != 0;
    }
    return !!this.dataStorageId
  }

  public reset (): void {
    if (this.table)
      this.table.rows = []
    this.dataGridService = new TaskDataGridIntegrationService(new TableDataFe())
  }

  public toTransfer () {
    let ans = new AnswerDataTable_AddFe(
      this.id, 
      this.sectionId,
      this.questionId, 
      this.comments, 
      this.managementCompanyId, 
      this.dataStorageId
    );
    ans.dataGridService = null
    return ans
  }

}
