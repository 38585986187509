<!-- Top Menu -->
<div class="d-flex flex-row justify-content-between align-items-center mb-1 header-footer">
  <div class="flex-fill">
    <div class="d-flex justify-content-end h-100">
      <a (click)="openModal(logoutModal, 'modal-md')">
        <i class="la la-power-off header-button-icon" type="button" title="{{ locale('locale_key.main_navbar.logout') }}"> </i>
      </a>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #logoutModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel12"><i class="la la-exclamation-circle fs-4" style="color: var(--warning-color)"></i> {{ locale("locale_key.main_navbar.logout") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ locale("locale_key.modal.logout_confirmation_modal.description") }}</h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <div class="ball-pulse loader-primary text-center m-auto" *ngIf="isLoading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="close()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="logout()">{{ locale("locale_key.general.buttons.continue") }}</button>
    </ng-container>
  </div>
</ng-template>
