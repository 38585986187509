import { Subject } from "rxjs";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { DataGridRow } from "../../projects/data-grid-ui/model/DataGridRow";
import { DataGridTableMode } from "../../projects/data-grid-ui/model/DataGridTableMode";
import { DataGridObserverInterface } from "../../projects/data-grid-ui/service/DataGridObserverInterface";
import { DataGridServiceInterface } from "../../projects/data-grid-ui/service/DataGridServiceInterface";
import { DataGridTableData } from "../../projects/data-grid-ui/model/DataGridTableData";

export class TaskDataGridIntegrationService implements DataGridServiceInterface {
  observer!: DataGridObserverInterface;
  table: TableDataFe;
  selectedRows = new Subject<DataGridRow[]>();
  modified = false
  isDataValid = true
  modifiedSubject = new Subject<boolean>();
  mode = DataGridTableMode.EDIT_TABLE_DATA

  constructor(table: TableDataFe) {
    this.table = table;
  }

  getMode(): string {
    return this.mode
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer;
  }

  getMainTableData(): DataGridTableData {
    let tableData = this.table.toDataGridTableData();
    return tableData;
  }

  getReferenceTables(): DataGridTableData[] {
    return [];
  }

  hasMainTablePagination(): boolean {
    return false;
  }

  async loadMainTablePage(pageSize: number, pageNumber: number): Promise<DataGridTableData> {
   let tableData = this.table.toDataGridTableData();
    return tableData;
  }

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
    this.selectedRows.next(rows);
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {
    this.modified = modified
    this.isDataValid = isDataValid
    this.modifiedSubject.next(modified)
  }

  hasDataModifiedElseWhere(): boolean {
    return false;
  }
  saveReferenceTable(table: DataGridTableData): void {}

  getNoOfMinRows(): number {
    return 5;
  }

  updateTableData (): void {
   let updated = this.observer.getModifiedTableData();
   this.isDataValid = this.observer.checkDataValidity();
   this.table = TableDataFe.fromDataGridTableData(updated);
  }
}
