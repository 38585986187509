import { Component, OnInit, TemplateRef } from "@angular/core";
import { RequestGroupFe } from "../../../model/data-suppliers/request/RequestGroupFe";
import { AbstractActivityFe } from "../../../model/data-suppliers/timeline/AbstractActivityFe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { DateUtil } from "src/app/utils/DateUtil";
import { TableStorageServiceFe } from "src/app/services/TableStorageServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { RejectSubmissionActivityFe } from "src/app/model/data-suppliers/timeline/RejectSubmissionActivityFe";
import { AcceptSubmissionActivityFe } from "src/app/model/data-suppliers/timeline/AcceptSubmissionActivityFe";
import { DomSanitizer } from "@angular/platform-browser";
import { READ_STATUS, RequestFe, REQUEST_TASK_STATUS } from "src/app/model/data-suppliers/request/RequestFe";
import { QUESTIONNAIRE_STATUS } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe";
import { SubmitAnswersActivityFe } from "src/app/model/data-suppliers/timeline/SubmitAnswersActivityFe";
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { SupplierCompanyFe } from "src/app/model/data-suppliers/company/SupplierCompanyFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { ContactFe } from "src/app/model/user/ContactFe";
import { ActivityTypeFe } from "src/app/model/data-suppliers/timeline/ActivityTypeFe";
import { RoutesFe } from "src/app/route/RoutesFe";
import { Router } from "@angular/router";
import { RequestServiceFe } from "src/app/services/RequestServiceFe";
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ["./inbox.component.scss","../../common/data-suppliers.component.scss"]
})


export class SMInboxComponent extends AbstractLanguageComponent implements OnInit {
  allRequestGroups: RequestGroupFe[] = []
  requestGroups: RequestGroupFe[] = [];
  requests: RequestFe[] = [];
  detailsData: any;
  loadingInfo = false;
  toDoActivitiesGroupedByReq: { [key: string]: { activities: AbstractActivityFe[]; isRead: boolean } } = {};
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  isChatOpen = false;
  isToDoListOpen = true;
  isExpanded = false;
  searchKeywordRg = "";

  pageToolbar = [
    [
      { shortLabel: this.locale('locale_key.general.toolbar.button.info'), longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'), tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'), icon: "la la-info fs-4", actionName: "toggle_quick_tips", visible: () => true, disabled: false }
    ],
  ];

  selectedRequestGroup: RequestGroupFe;
  selectedRequest: RequestFe;
  taxonomy: TaxonomyInfoFe
  searchKeyword: string = '';
  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date'), value: 'createdDate', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date_desc'), value: 'createdDateDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline'), value: 'deadline', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline_desc'), value: 'deadlineDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]

  selectedStatus = null;
  customMultipleFilters: {name: string, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number}[] = []
  filteredCompanies: SupplierCompanyFe[] = []
  filteredDataReporters: ContactFe[] = []
  filteredEntities: EntityFe[] = []
  filteredRequestGroups: RequestGroupFe[] = [];
  selectedActivity: AbstractActivityFe;
  loadingInProgress = false;


  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    private displayService: DisplayServiceFe,
    public loginService: LoginServiceFe,
    private router: Router, 
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private requestService: RequestServiceFe
  ) {
    super(languageService);

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
    
    this.screenSize = responsive.currentScreenWidthSize;
  }

  async ngOnInit(): Promise<void> {
    this.loadingInfo = true;
    let {depTaxonomy} = await this.stateService.getTaxonomyInfos();
    this.taxonomy = depTaxonomy
    await this.renderNewData();
    this.stateService.requestTimelineItemsUpdatedSubject.subscribe((activity) => {
      let index = this.selectedRequest.timelineItems.findIndex(act => act.id == activity.id);
      this.selectedRequest.timelineItems[index] = activity;
    });
    this.displayService.closeTips();
    this.loadingInfo = false;
  }

  async renderNewData(): Promise<void> {
    this.allRequestGroups = await this.stateService.getRequestGroups();
    this.requests = await this.stateService.getAllRequests();
    this.setFilterData()
  }
  
  setFilterData() {
    this.applyFilters();
    this.filterCompanies();
    this.filterDataReporters();
    this.filterEntities();
  }

  async adjustInboxData() {
    let allRequests = [];
    this.filteredRequestGroups.forEach(rg => allRequests.push(...rg.requests));
    let allReqActivities = [];
    allRequests.forEach(request => allReqActivities.push(...request.timelineItems));
    this.toDoActivitiesGroupedByReq = {};
    let unreadActivities = [];
    allReqActivities.forEach((a) => {
      if (a.readStatus == READ_STATUS.UNREAD && a.submitterAffId != this.stateService.activeWorkspace.affiliationId) {
        unreadActivities.push(a);
      }
    });
    let toReviewActivities: AbstractActivityFe[] = []

    allRequests.forEach(r => {
      if (r.status == REQUEST_TASK_STATUS.IN_PROGRESS) {
        let latestSubmission = r.timelineItems.slice().reverse().find(a => this.requestService.isSubmitAnswer(a) || this.requestService.isReject(a) || this.requestService.isAccept(a));
        if (latestSubmission instanceof SubmitAnswersActivityFe && latestSubmission.readStatus == READ_STATUS.READ) {
          toReviewActivities.push(latestSubmission);
        }
      }
    })
    let toDoActivities = [...unreadActivities, ...toReviewActivities];
    toDoActivities.sort((a, b) => new Date(b.submissionDate).getTime() - new Date(a.submissionDate).getTime());
    toDoActivities.forEach((a) => {
      if (this.toDoActivitiesGroupedByReq[a.requestId]) {
        this.toDoActivitiesGroupedByReq[a.requestId].activities.push(a);
      } else {
        this.toDoActivitiesGroupedByReq[a.requestId] = { activities: [a], isRead: a.readStatus == READ_STATUS.READ };
      }
    });
    allRequests.forEach((r) => {
      let unreadActivities = allReqActivities.filter((a) => a.readStatus == READ_STATUS.UNREAD && a.requestId == r.id);
      r.noOfUnreadActivities = unreadActivities.length;
    });
  }

  async updateDetailsData(requestGroup: RequestGroupFe, request: RequestFe = null) {
    this.detailsData = {
      requestGroup: requestGroup,
      request: request,
    };
    this.displayService.updateDetailsData(this.detailsData);
    //TODO
    //this.displayService.updateQuickTipsData({ viewMode: `view_${this.viewNo}`, page: "requests" });
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  getToDoActivitiesGroup() {
    return Object.values(this.toDoActivitiesGroupedByReq);
  }

  getDoneRequestList() {
    let completedReq =  [];
    this.filteredRequestGroups.forEach(rg=> {
      let reqs = rg.requests.filter(req => req.questionnaire.status != QUESTIONNAIRE_STATUS.SUBMITTED)
       if (this.customMultipleFilters.length > 0) {
        reqs = reqs.filter(request => {
          for (let f of this.customMultipleFilters) {
            let value = f.value
            if (value instanceof SupplierCompanyFe) {
              if (request.supplierCompanyId == value['supplierCompanyId']) {
                return true;
              }
            } else if (value instanceof ContactFe) {
              if (request.assigneeAffId == value['affiliationId']) {
                return true;
              }
            }
          }
          return false;
        });
      }
      completedReq.push(...reqs)
    });
    let readRequests = completedReq.filter(req => req.readStatus == READ_STATUS.READ)
    return readRequests.sort((a, b) => DateUtil.getTimeDifference(this.getLastActivity(b)?.submissionDate, this.getLastActivity(a)?.submissionDate))
  }

  selectDoneRequest(request: RequestFe) {
    this.selectedActivity = this.getLastActivity(request)
    this.selectedRequest = request;
    this.selectedRequestGroup = this.getRequestGroup(request.requestGroupId);
  }

  reset() {
    this.selectedRequestGroup = null;
    this.selectedRequest = null;
  }

  getLastActivity(request: RequestFe) {
    return request.timelineItems[request.timelineItems.length - 1];
  }
 
  async selectNewActivity(groupIndex: number) {
    this.selectedActivity = Object.values(this.toDoActivitiesGroupedByReq)[groupIndex].activities[0]
    let selectedReqId = Object.keys(this.toDoActivitiesGroupedByReq)[groupIndex];
    this.selectedRequestGroup = this.requestGroups.find((rg) => rg.id == Object.values(this.toDoActivitiesGroupedByReq)[groupIndex].activities[0].requestGroupId);
    this.selectedRequest = this.selectedRequestGroup.requests.find((r) => r.id == selectedReqId);
    if (this.toDoActivitiesGroupedByReq[selectedReqId].activities[0].readStatus == READ_STATUS.UNREAD && 
      this.toDoActivitiesGroupedByReq[selectedReqId].activities[0].type!= ActivityTypeFe.SUBMIT_MESSAGE) {
      await this.markAsRead()
    }
  }

  async markAsRead() {
    await this.stateService.markAllRequestActivitiesRead(this.selectedRequestGroup, this.selectedRequest);
  }


  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "toggle_quick_tips":
        this.toggleTips()
        break;
    }
  }

  getRequestGroup(id: string) {
    return this.requestGroups.find(r => r.id == id)
  }

  openMessageModal() {
    this.isChatOpen = !this.isChatOpen;
    this.markAsRead()
  }

  getRequestTitle(request: RequestFe): string {
    let rg = this.getRequestGroup(request.requestGroupId);
    return rg.title;
  }

  openModal(template: TemplateRef<any>, size: string ='modal-md') {
    this.modalRef = this.modalService.show(template, { class: size, backdrop: "static", keyboard: true });
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  getUnreadActivities(request: RequestFe) {
    let acts = request.timelineItems.filter(item => item.readStatus == READ_STATUS.UNREAD);
    return acts;
  }

  applyFilters() {
    this.applyShowOnlyFilter();
  }

  applyShowOnlyFilter() {
    if (this.selectedStatus) {
      this.requestGroups = this.allRequestGroups.filter(f => f.status == this.selectedStatus)
    } else {
      this.requestGroups = this.allRequestGroups;
    }
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedStatus = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof SupplierCompanyFe) {
        this.customMultipleFilters.push({name: value.supplierCompanyname, value})
      } else if (value instanceof ContactFe) {
        this.customMultipleFilters.push({name: value.getName(), value})
      } else if (value instanceof EntityFe) {
        this.customMultipleFilters.push({ name: value.getTaxonomyTitle(this.taxonomy), value, level})
      } 
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.requestGroups = this.requestGroups.filter(requestGroup => {
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof SupplierCompanyFe) {
            for (let request of requestGroup.requests) {
              if (request.supplierCompanyId == value['supplierCompanyId']) {
                return true;
              }
            }
          } else if (value instanceof ContactFe) {
            for (let request of requestGroup.requests) {
              if (request.assigneeAffId == value['affiliationId']) {
                return true;
              }
            }
          } else if (value instanceof EntityFe) {
            let taxonomyKeys = new Set<string>();
            if (f.level == 0) {
              let parent = this.taxonomy.childrenSortedByOrdinal(value.key)
              let child = []
              parent.forEach(item => child.push(...this.taxonomy.childrenSortedByOrdinal(item.key)))
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else if (f.level == 1) {
              let child = this.taxonomy.childrenSortedByOrdinal(value.key)
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else {
              taxonomyKeys.add(value.key)
            }
            if (requestGroup.requests[0]?.questionnaire?.sections) {
              for (let section of requestGroup.requests[0].questionnaire.sections) {
                if (section.taxonomyKey && taxonomyKeys.has(section.taxonomyKey)) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword;
    }
    this.filteredRequestGroups = this.requestGroups.filter(rg => {
      if (rg.title.match(new RegExp(this.searchKeyword, "i"))) {
        return true;
      }
      let requests =rg.requests.filter(req=> req.getAssigneeName().match(new RegExp(this.searchKeyword, "i")))
      return requests.length > 0
    })
    this.adjustInboxData();
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Set();
    this.requestGroups.forEach(rg => rg.requests.forEach(request => relatedCompanyIds.add(request.supplierCompanyId)));
    let companies = await this.stateService.getPartners()
    let relatedCompanies = companies.filter(company => relatedCompanyIds.has(company.supplierCompanyId));
    this.filteredCompanies = relatedCompanies.filter(f => f.supplierCompanyname.match(new RegExp(keyword, "i")))
  }

  async filterDataReporters(keyword: string = '') {
    let relatedContactAffIds = new Set();
    this.requestGroups.forEach(rg => rg.requests.forEach(request => relatedContactAffIds.add(request.assigneeAffId)));
    let contacts = await this.stateService.getActiveContacts()
    let relatedContacts = contacts.filter(contact => relatedContactAffIds.has(contact.affiliationId));
    this.filteredDataReporters = relatedContacts.filter(f => f.getName().match(new RegExp(keyword, "i")))
  }

  async filterEntities(keyword: string = '') {
    let allRootEntities = this.taxonomy.childrenSortedByOrdinal()
    this.filteredEntities = allRootEntities.filter(root => {
      if (root.getLabel().match(new RegExp(keyword, "i"))) {
        return true
      }
      let parents = this.taxonomy.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.taxonomy.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != company)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != contact)
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
      })
    }
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        if (!this.customMultipleFilters.find(f => f.value == company)) {
          this.customMultipleFilters.push({name: company.supplierCompanyname, value: company})
        } 
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        if (!this.customMultipleFilters.find(f => f.value == contact)) {
          this.customMultipleFilters.push({name: contact.getName(), value: contact})
        } 
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        if (!this.customMultipleFilters.find(f => f.value == enitity)) {
          this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.taxonomy), value: enitity})
        } 
      })
    }
    this.applyFilters();
  }

  getCustomFilterText() {
    let text = '';
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }

  openRequestPortal() {
    this.displayService.setRequestFromHome(this.selectedRequest.timelineItems[0]);
    this.router.navigate([RoutesFe.REQUESTS.fullPath()]);
  }
  
  async disconnectAllSections() {
    try {
      this.loadingInProgress = true;
      await this.requestService.disconnectSections(this.selectedRequest, this.selectedRequest.questionnaire.sections);
      this.closeModal();
      this.loadingInProgress = false;
    } catch (err) {
      this.closeModal();
      this.loadingInProgress = false;
    }
  }

  async connectAllSections() {
    try {
      this.loadingInProgress = true;
      await this.requestService.connectAllSections(this.selectedRequest)
      this.closeModal();
      this.loadingInProgress = false;
    } catch (err) {
      this.closeModal();
      this.loadingInProgress = false;
    }
  }

}