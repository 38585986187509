import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { TaxonomyInfoFe } from "../model/taxonomy/TaxonomyInfoFe";
import { StateServiceFe } from "./StateServiceFe";
import { EntityFe } from "../model/taxonomy/EntityFe";
import { TaxonomyAttributeFe } from "../model/taxonomy/TaxonomyAttributeFe";
import { Language } from "../utils/language/Language";
import { LanguageService } from "./LanguageServiceFe";

@Injectable({
  providedIn: "root",
})

export class DataCategoryServiceFe {

  private editedTaxonomy: TaxonomyInfoFe;

  newEntities: EntityFe[] = [];
  newDataPoints = new Map<EntityFe, TaxonomyAttributeFe[]>();

  constructor(private stateService: StateServiceFe){
    stateService.workspaceSubject.subscribe(changed => this.setTaxonomy())
    stateService.depTaxonomyInfoSubject.subscribe(changed => this.setTaxonomy())
  }
  
  async getTaxonomy() {
    if (!this.editedTaxonomy) {
      await this.setTaxonomy();
    } 
    return this.editedTaxonomy;
  }

  async setTaxonomy() {
    let taxonomies = await this.stateService.getTaxonomyInfos()
    this.editedTaxonomy =  _.cloneDeep(taxonomies).depTaxonomy;
    this.editedTaxonomy.version = this.editedTaxonomy.version + 1;
    this.newEntities = []
    this.newDataPoints.clear();
  }

  addEntity(label: { [key: string]: string }, parentKey: string) {
    let entity = this.editedTaxonomy.addCustomEntityByParentKey(label, parentKey);
    this.newEntities.push(entity);
  }

  totalChanges(): number {
    let changes = this.newEntities.length + this.dataPointsLength();
    return changes;
  }

  dataPointsLength(): number {
    let changes = 0
    this.newDataPoints.forEach(dps => changes += dps.length);
    return changes
  }

  resetNewEntitiesAndDatapoints() {
    this.setTaxonomy();
  }

  addDataPoint(entity: EntityFe, dataPoint: TaxonomyAttributeFe) {
    entity.columns.push(dataPoint);
    entity.adjustKey(dataPoint);
    if (!this.newDataPoints.has(entity)) {
      this.newDataPoints.set(entity, []);
    }
    this.newDataPoints.get(entity).push(dataPoint);
  }

  getNewDataPointEntites() {
    return Array.from(this.newDataPoints.keys());
  }

  getNewDataPoints(entity: EntityFe) {
    return this.newDataPoints.get(entity);
  }

  async deploy() {
    await this.stateService.updateNewTaxonomyInfo(this.editedTaxonomy);
    await this.stateService.upgradeTaxonomyInfo();
    await this.setTaxonomy();
  }
}
