<div class="m-0 p-0">
  <div class="vertical-scroll pt-0 px-3">
    <ng-container *ngFor="let question of questions; let i =index">
      <div class="thin-border-b pt-3 pb-3" *ngIf="isQuesVisible(question)">
        <div class="row ps-3">
          <div class="col-11">
            <h6 class="mt-2">{{i+1}}. {{ question.question }} <sup class="text-danger">{{question.required ? '*' : ''}}</sup></h6>
            <div class="d-flex flex-column justify-content-start ps-3" *ngIf="question.answer">
              <ng-container *ngIf="requestService.isMultipleChoiceQues(question)">
                <h5 class="text-muted" *ngFor="let optionId of question.answer.selectedOptionIds">
                  <input type="checkbox" checked disabled/>
                  {{question.getOption(optionId).value}}
                </h5>
              </ng-container>
              <ng-container *ngIf="requestService.isSingleChoiceQues(question)">
                <h5 class="text-muted" *ngIf="question.answer && question.answer.selectedOptionId">
                  <input type="radio" checked disabled/>
                  {{question.getOption(question.answer.selectedOptionId).value}}
                </h5>
              </ng-container>
              <h5 class="text-muted" *ngIf="requestService.isNumberQues(question)">{{question.answer.value + ' ' + getUnitString(question.answer.unit)}}  </h5>
              <div *ngIf="requestService.isDateQues(question)">
                <h5 class="text-muted d-inline"> {{question.answer.getDateFromString()}} </h5> 
                <h5 class="mx-3" *ngIf="question.range"> -</h5> 
                <h5 class="text-muted d-inline" *ngIf="question.range">{{question.answer.getDateToString()}}</h5>
              </div>
              <small class="text-muted d-inline multiline" *ngIf="requestService.isTextQues(question)">{{question.answer.text}}</small>
              <small class="text-muted d-inline multiline" *ngIf="requestService.isEmissionFactorQues(question)">{{question.answer.text}}</small>
              <label class="text-muted m-0 d-inline multiline" *ngIf="requestService.isEmissionFactorQues(question)">
                <emission-factor [selectedEF]="question.answer.selectedEmissionFactor"></emission-factor>
              </label>
              
              <div class="col-5 d-flex flex-row justify-content-center py-3 open-bx" *ngIf="(requestService.isAttachmentQues(question) && question.answer.attachmentStorageId) || requestService.isDataTableQues(question)">
                <ng-container *ngIf="!question.answer.isLoading">
                  <div class="col-2 d-flex justify-content-center ps-0 me-0 pe-0">
                    <i class="la la-file-text-o fs-1 me-auto ms-0 ps-0" *ngIf="requestService.isAttachmentQues(question)"></i>
                    <i class="la la-table fs-1 me-auto ms-0 ps-0" *ngIf="requestService.isDataTableQues(question)"></i>
                  </div>
                  <div class="col-8 d-flex justify-content-center text-start ms-0 ps-0 text-truncate">
                    <p class="text-muted my-auto me-auto text-truncate" data-bs-toggle="tooltip" data-placement="top" title="{{ requestService.isDataTableQues(question) ? 'Data Table' : question.answer['attachmentLabel'] }}">{{ requestService.isDataTableQues(question) ? locale('locale_key.pages.data_request.question_type.data_table') : question.answer['attachmentLabel'] }}</p>
                  </div>
                  <div class="col-2 d-flex justify-content-center" >
                    <p class="text-muted m-auto underline cursor me-2" (click)="requestService.isDataTableQues(question) ? openTableFile(question.answer): openFile(question.answer)">{{locale('locale_key.general.buttons.open')}}</p>
                  </div>
                </ng-container>
                <div class="ball-pulse loader-primary" *ngIf="question.answer.isLoading">
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                </div>
              </div>
              <small class="mt-2 text-muted" *ngIf="question.answer.comments">{{question.answer.comments}}</small>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>