<div class="modal-header main-modal-header" style="border-bottom: none" *ngIf="showHeader">
  <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">{{ requestTitle }} [{{ locale("locale_key.pages.data_request.action.blank") }}]</h4>
  <button type="button" class="btn close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="loadingInfo">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingInfo">
    <div class="row mb-0">
      <div class="col-12 mt-0 pt-0 mx-0 px-0" style="max-height: 70vh">
        <div class="m-0 p-0">
          <div class="scroll pt-0 px-3" style="max-height: 70vh; height: 70vh">
            <div class="row">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="my-auto">
                  <span class="my-auto" *ngIf="questionStack.length === 0">{{ slocale("Main questions") }}</span>
                  <button class="btn tertiary-button ms-1" *ngIf="questionStack.length > 1" (click)="backToPreviousQuestion()"><i class="la la-arrow-left me-1"></i>{{ slocale("Back to previous question") }}</button>
                  <button class="btn tertiary-button ms-1" *ngIf="questionStack.length > 0" (click)="backToMainQuestions()"><i class="la la-arrow-left me-1"></i>{{ slocale("Back to main questions") }}</button>
                </div>
                <div class="my-auto">
                  <a class="underline mx-1 my-auto" href="#">{{ slocale("Expand all") }}</a>
                  <a class="underline mx-1 my-auto" href="#">{{ slocale("Collapse all") }}</a>
                </div>
              </div>
            </div>

            <div class="row text-end my-auto" *ngIf="showMapping">
              <a class="p-1 cursor mt-2" (click)="editMapping = true">
                <span class="ms-auto p-1 border">
                  <i class="las la-sitemap py-1 size-15 my-auto" style="position: relative; top: 3px; transform: rotate(-90deg)"></i>
                  <span class="text-muted my-auto cursor"> {{ locale("locale_key.modal.questionnaire_preview.show_mapping_info") }}</span>
                </span>
              </a>
            </div>
            <div class="accordion mt-2" id="sectionParentPreview" *ngIf="questionStack.length === 0">
              <div class="accordion-item" *ngFor="let section of sections; let sectionIndex = index">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button px-0 pe-3 bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#sectionAccordion{{ sectionIndex }}Preview"
                    aria-expanded="true"
                    attr.aria-controls="sectionAccordion{{ sectionIndex }}Preview"
                    id="section{{ sectionIndex }}Preview"
                  >
                    <div class="d-flex flex-row my-auto p-2">
                      <span class="me-2">
                        <h5 class="my-auto ms-2">{{ locale("locale_key.modal.questionnaire_preview.section_number", { sectionNumber: sectionIndex + 1 }) }}</h5>
                      </span>
                      <ng-container *ngIf="editMapping">
                        <span class="badge badge-info w-auto py-auto my-auto" *ngIf="section.entity"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }} </span>
                        <span class="badge badge-info w-auto py-auto my-auto" *ngIf="section.name"> {{ section.name }} </span>
                        <button class="dropdown-item ms-0 ps-1" type="button" *ngIf="!section.taxonomyKey" (click)="mappingEdited = true; section.editTaxonomy = true">
                          <i class="las la-edit mr-25" style="position: relative; top: 1px"></i>
                        </button>
                      </ng-container>
                    </div>
                  </button>
                </h2>
                <div id="sectionAccordion{{ sectionIndex }}Preview" class="accordion-collapse collapse show" data-bs-parent="#sectionParent">
                  <div class="accordion-body ps-2 pe-0">
                    <div class="d-flex w-100 flex-column">
                      <div class="row-design mx-2 border p-2" *ngIf="section.editTaxonomy">
                        <h6>
                          {{ locale("locale_key.modal.questionnaire_preview.store_answers_with_data_categories") }}?
                          <i class="la la-info-circle dropdown-toggle ms-2 info-icon" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ locale("locale_key.modal.questionnaire_preview.create_table_confirmation") }}</p>
                          </div>
                        </h6>
                        <div class="d-flex flex-row px-1 mt-1">
                          <button class="btn tertiary-button custom-button me-2" (click)="section.initializeTaxonomy()" [ngClass]="{ 'btn-pink': section.isTaxonomyConnected }">{{ locale("locale_key.general.buttons.yes") }}</button>
                          <button class="btn tertiary-button custom-button" (click)="section.intializeNameControl()" [ngClass]="{ 'btn-pink': !section.isTaxonomyConnected }">{{ locale("locale_key.general.buttons.no") }}</button>
                        </div>
                        <ng-container *ngIf="section.isTaxonomyConnected">
                          <p class="pt-3 pb-0 mb-0">{{ locale("locale_key.modal.questionnaire_preview.related_category_question") }}</p>
                          <div class="w-100" [ngClass]="{ 'pe-3': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
                            <category-selection [entityKey]="section.taxonomyKey" [editedTaxonomy]="true" (entitySelected)="section.setTaxonomy($event, taxonomyInfo)"></category-selection>
                          </div>
                          <div class="d-flex text-end">
                            <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.editTaxonomy = false">
                              {{ locale("locale_key.general.buttons.confirm") }}
                            </button>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!section.isTaxonomyConnected">
                          <p class="pt-3 pb-0 mb-0">{{ locale("locale_key.modal.questionnaire_preview.section_name_instruction") }}</p>
                          <input class="w-50" [formControl]="section.nameControl" />
                          <div class="d-flex text-end">
                            <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.name = section.nameControl.value; section.editTaxonomy = false">
                              {{ locale("locale_key.general.buttons.confirm") }}
                            </button>
                          </div>
                        </ng-container>
                      </div>
                      <div class="m-2" *ngFor="let question of section.getQuestionsWithoutParent(); let i = index">
                        <div class="row ps-2">
                          <div class="col-7 px-0 w-100">
                            <div class="d-flex flex-column flex-lg-row justify-content-between">
                              <div class="text-wrap px-2 w-75">
                                <h5 class="mt-2">{{ i + 1 }}. {{ question.question }}</h5>
                                <small class="text-muted multiline">{{ question.desc }}</small>
                              </div>
                              <div class="d-flex flex-row justify-content-start justify-content-lg-end px-2">
                                <a class="btn my-2 me-2 p-2 tertiary-button mb-auto" *ngIf="question.hasFollowUpQuestion" (click)="selectQuestion(section, question)">
                                  {{ slocale("Go to follow up questions") }}
                                </a>
                              </div>
                            </div>
                            <span class="col-6" style="font-size: 12px; text-align: end" *ngIf="editMapping && section.taxonomyKey && !question.editMapping && !requestService.isAttachmentQues(question)">
                              <ng-container *ngIf="question.isMapped && question.mappedToCols">
                                <span class="text-muted small">{{ locale("locale_key.modal.questionnaire_preview.data_added_to") }}</span>
                                <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }} </span>
                                <span class="badge badge-info w-auto py-auto my-auto">
                                  <ng-container *ngFor="let mappedToCol of question.mappedToCols; let firstCol = first"> <span *ngIf="!firstCol">/</span>{{ mappedToCol?.getLabel() }}</ng-container>
                                </span>
                              </ng-container>
                              <ng-container *ngIf="question.isMapped && question.range && question.mappedToColsRange">
                                <span class="badge badge-info w-auto py-auto my-auto">
                                  <ng-container *ngFor="let mappedToCol of question.mappedToColsRange; let firstCol = first"> <span *ngIf="!firstCol">/</span>{{ mappedToCol?.getLabel() }}</ng-container>
                                </span>
                              </ng-container>
                              <span class="text-muted small" *ngIf="section.taxonomyKey && !question.isMapped">{{ locale("locale_key.modal.questionnaire_preview.not_added_to_data_categories") }}</span>
                              <a (click)="mappingEdited = true; question.setEditMapping()" *ngIf="section.taxonomyKey && !question.isMapped"><i class="la la-edit size-15 my-auto" style="position: relative; top: 2px"></i></a>
                            </span>
                          </div>
                          <ng-container *ngIf="question.editMapping">
                            <div class="row-design border p-2" *ngIf="section.taxonomyKey && !requestService.isDataTableQues(question)">
                              <h6>
                                {{ locale("locale_key.modal.questionnaire_preview.which_data_points_in") }}
                                <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}</span>
                                {{ locale("locale_key.modal.questionnaire_preview.collect_with_this_question") }}
                              </h6>
                              <div class="d-flex flex-row mt-1">
                                <div class="flex-grow-1 d-flex">
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      [checked]="!question.isMapped"
                                      (click)="requestService.toggleQuesMapping($event, question)"
                                      id="sec{{ sectionIndex }}ques{{ i }}"
                                      style="position: relative; top: 1px"
                                    />
                                    <label class="form-check-label" for="sec{{ sectionIndex }}ques{{ i }}">
                                      {{ locale("locale_key.modal.questionnaire_preview.exclude_from_mapping") }}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-2" *ngIf="question.isMapped">
                                <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isFieldSelected(c) }" (click)="question.toggleField(c)">
                                  <div class="row px-3">
                                    <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                    <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                                      <i class="la la-check fieldSelected"></i>
                                    </span>
                                  </div>
                                  <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                  <span
                                    >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ c.datatype | titlecase }}</b></span
                                  >
                                  <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.modal.questionnaire_preview.new") }}</div>
                                </div>
                                <ng-container *ngIf="question.range">
                                  <div>{{ locale("locale_key.wizard.create_questionnaire.map_second_data_input") }}</div>
                                  <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isRangeFieldSelected(c) }" (click)="question.toggleRangeField(c)">
                                    <div class="row px-3">
                                      <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                      <span class="col-2 p-0" *ngIf="question.isRangeFieldSelected(c)">
                                        <i class="la la-check fieldSelected"></i>
                                      </span>
                                    </div>
                                    <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                    <span
                                      >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ c.datatype | titlecase }}</b></span
                                    >
                                    <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.wizard.create_questionnaire.new") }}</div>
                                  </div>
                                </ng-container>
                              </div>
                              <div class="d-flex justify-content-end">
                                <button
                                  class="btn primary-button custom-button me-2"
                                  [disabled]="question.isMapped ? question.mappedToColKeys.length == 0 && (question.range ? question.mappedToColKeysRange.length == 0 : true) : false"
                                  (click)="question.confirmMapping()"
                                >
                                  {{ locale("locale_key.general.buttons.confirm") }}
                                </button>
                              </div>
                            </div>
                            <div class="row-design border p-2" *ngIf="requestService.isDataTableQues(question)">
                              <h6 *ngIf="section.taxonomyKey">
                                {{ locale("locale_key.modal.questionnaire_preview.which_data_points_in") }}
                                <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}</span>
                                {{ locale("locale_key.modal.questionnaire_preview.collect_with_this_question") }}
                              </h6>
                              <div class="row">
                                <div class="col-7 me-0 pe-1">
                                  <div class="col-12 px-1" *ngIf="question.isMapped">
                                    <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isFieldSelected(c) }" (click)="question.toggleField(c, question)">
                                      <div class="row px-3">
                                        <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                        <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                                          <i class="la la-check fieldSelected"></i>
                                        </span>
                                      </div>
                                      <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                      <span
                                        >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ locale("locale_key.general.data_type.number") }}</b></span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-5 ms-0 ps-1">
                                  <div class="row my-5 px-0 mx-0">
                                    <div class="col-1 mx-0 px-0 m-auto">
                                      <span class="mx-0 px-0"><i class="la la-caret-right"></i></span>
                                    </div>
                                    <div class="col-11 mx-0 px-0">
                                      <datagrid-table-editor [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex justify-content-end">
                                <button class="btn primary-button custom-button me-2" [disabled]="question.isMapped ? question.mappedToColKeys.length == 0 : false" (click)="question.confirmMapping()">
                                  {{ locale("locale_key.general.buttons.confirm") }}
                                </button>
                              </div>
                            </div>
                          </ng-container>
                          <div class="d-flex flex-column justify-content-start ps-3 mb-2">
                            <div class="col mt-1 mx-0 px-0" *ngIf="requestService.isTextQues(question)">
                              <textarea class="form-control w-50" rows="3" placeholder="Write answer" disabled> </textarea>
                            </div>
                            <div class="row w-50" *ngIf="requestService.isEmissionFactorQues(question)">
                              <div class="w-50 ms-0 me-4">
                                <emission-factor isDisabled="true"></emission-factor>
                              </div>
                            </div>
                            <div class="row w-50" *ngIf="requestService.isDateQues(question)">
                              <div class="col-12">
                                <input type="date" class="form-control" placeholder="12/12/2022" disabled />
                              </div>
                              <ng-container *ngIf="question['range']">
                                <div class="col-12 ml-1">
                                  <p class="mb-2">{{ locale("locale_key.pages.task_portal.answer_wizard.date.options.range.to") }}</p>
                                </div>
                                <div class="col-12">
                                  <input type="date" class="form-control" placeholder="12/12/2022" disabled />
                                </div>
                              </ng-container>
                            </div>
                            <div class="row w-50" *ngIf="requestService.isNumberQues(question)">
                              <div class="col-6 ms-0 ps-3">
                                <input type="text" class="form-control" [placeholder]="locale('locale_key.pages.data_request.create.question.value.placeholder')" disabled />
                              </div>
                              <div class="col-6 ms-0 ps-3">
                                <p>{{question.unit}}</p>
                              </div>
                            </div>
                            <div class="row w-50" *ngIf="requestService.isAttachmentQues(question)">
                              <div class="col-12 m-1 ps-3">
                                <button class="btn primary-button" disabled>{{ locale("locale_key.pages.data_request.review_answer_wizard.file.button.select_file") }}</button>
                              </div>
                            </div>
                            <div class="row" *ngIf="requestService.isDataTableQues(question)">
                              <div class="col-12 ms-0 ps-3">
                                <datagrid-table-editor [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
                              </div>
                              <div class="col-12 m-1 ps-3">
                                <button class="btn primary-button" disabled>{{ locale("locale_key.general.buttons.extract_from_file") }}</button>
                              </div>
                            </div>
                            <div *ngIf="requestService.isMultipleChoiceQues(question)">
                              <div class="row me-auto mx-1" *ngFor="let option of question['options']">
                                <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
                                  <input type="checkbox" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" disabled />
                                  <p class="d-inline">{{ option.value }}</p>
                                  <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
                                  <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
                                </a>
                                <div id="menu-1" class="dropdown-menu position-relative dropdown-demo px-1" [ngClass]="{ 'show mb-2': option.showDesc }">
                                  {{ option.desc }}
                                </div>
                              </div>
                            </div>
                            <div *ngIf="requestService.isSingleChoiceQues(question)">
                              <div class="row me-auto mx-1" *ngFor="let option of question['options']">
                                <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
                                  <input type="radio" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" [value]="option.id" disabled />
                                  <p class="d-inline">{{ option.value }}</p>
                                  <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
                                  <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
                                </a>
                                <div id="menu-1" class="dropdown-menu position-relative dropdown-demo px-1" [ngClass]="{ 'show mb-2': option.showDesc }">
                                  {{ option.desc }}
                                </div>
                              </div>
                            </div>
                            <div class="col mt-1 ms-0 ps-0" *ngIf="question.comments">
                              <label class="mt-1">{{ locale("locale_key.modal.questionnaire_preview.comments") }}</label>
                              <textarea class="form-control w-50" rows="1" disabled></textarea>
                            </div>
                          </div>
                          <hr class="mx-0 px-0 w-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- First Level Follow-up Questions -->
            <div class="py-3 scroll-y" *ngIf="questionStack.length > 0">
              <div class="accordion" id="followUpAccordion">
                <ng-container *ngIf="selectedQuestion">
                  <div class="accordion-item mb-3 thin-border-t rounded" [id]="selectedQuestion.id">
                    <h2 class="accordion-header" [id]="'headingFollowUp' + selectedQuestion.id">
                      <button class="accordion-button p-2 bg-white" type="button" [attr.aria-expanded]="true" [attr.aria-controls]="'collapseFollowUp' + selectedQuestion.id">
                        <div class="w-100">
                          <div class="d-flex justify-content-between">
                            <div class="text-wrap px-2 w-75">
                              <h5 class="mt-2">{{ selectedQuestion.question }}</h5>
                            </div>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div [id]="'collapseFollowUp' + selectedQuestion.id" class="accordion-collapse collapse show" [attr.aria-labelledby]="'headingFollowUp' + selectedQuestion.id">
                      <div class="accordion-body p-3">
                        <p class="text-muted">{{ selectedQuestion.desc }}</p>
                        <div [innerHTML]="renderQuestionOptions(selectedQuestion)"></div>
                      </div>
                    </div>
                  </div>
                  <i class="la la-undo" style="transform: rotate(180deg); position: relative; top: -10px"></i>
                  <ng-container *ngFor="let followUp of getDirectChildren(selectedQuestion)">
                    <div class="accordion-item mb-3 thin-border-t rounded" [ngStyle]="{ 'margin-left': getIndentation(getQuestionLevel(followUp)) }" style="position: relative; top: -25px" [id]="followUp.id">
                      <h2 class="accordion-header" [id]="'headingFollowUp' + followUp.id">
                        <button
                          class="accordion-button collapsed p-2 bg-white"
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapseFollowUp' + followUp.id"
                          [attr.aria-expanded]="expanded[followUp.id]"
                          [attr.aria-controls]="'collapseFollowUp' + followUp.id"
                        >
                          <div class="w-100">
                            <div class="d-flex justify-content-between">
                              <div class="text-wrap px-2 w-75">
                                <h5 class="mt-2 bold">If the answer is ({{ followUp.followUpCondition }})</h5>
                                <h5 class="mt-2">{{ followUp.question }}</h5>
                              </div>
                              <div class="d-flex flex-row justify-content-start justify-content-lg-end px-2">
                                <button *ngIf="hasFollowUpQuestions(followUp)" class="btn my-auto me-2 p-2 tertiary-button mb-auto" (click)="toggleFollowUpQuestions(followUp, $event)">
                                  {{ expanded[followUp.id] ? slocale("Hide follow up questions") : slocale("Show follow up questions") }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div [id]="'collapseFollowUp' + followUp.id" class="accordion-collapse collapse" [ngClass]="{ show: expanded[followUp.id] }" [attr.aria-labelledby]="'headingFollowUp' + followUp.id" data-bs-parent="#followUpAccordion">
                        <div class="accordion-body p-3">
                          <p class="text-muted">{{ followUp.desc }}</p>
                          <div [innerHTML]="renderQuestionOptions(followUp)"></div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="expanded[followUp.id]">
                      <ng-container *ngTemplateOutlet="followUpQuestions; context: { parentQuestion: followUp }"></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-template #followUpQuestions let-parentQuestion="parentQuestion">
                  <i class="la la-undo" style="transform: rotate(180deg); position: relative; top: -35px; left: 20px"></i>
                  <ng-container *ngFor="let childFollowUp of getDirectChildren(parentQuestion)">
                    <div class="accordion-item mb-3 thin-border-t rounded" [ngStyle]="{ 'margin-left': getIndentation(getQuestionLevel(childFollowUp)) }" style="position: relative; top: -50px" [id]="childFollowUp.id">
                      <h2 class="accordion-header" [id]="'headingFollowUp' + childFollowUp.id">
                        <button
                          class="accordion-button collapsed p-2 bg-white"
                          type="button"
                          data-bs-toggle="collapse"
                          [attr.data-bs-target]="'#collapseFollowUp' + childFollowUp.id"
                          [attr.aria-expanded]="expanded[childFollowUp.id]"
                          [attr.aria-controls]="'collapseFollowUp' + childFollowUp.id"
                        >
                          <div class="w-100">
                            <div class="d-flex justify-content-between">
                              <div class="text-wrap px-2 w-75">
                                <h5 class="mt-2 bold">If the answer is ({{ childFollowUp.followUpCondition }})</h5>
                                <h5 class="mt-2">{{ childFollowUp.question }}</h5>
                              </div>
                              <div class="d-flex flex-row justify-content-start justify-content-lg-end px-2">
                                <button *ngIf="hasFollowUpQuestions(childFollowUp)" class="btn my-auto me-2 p-2 tertiary-button mb-auto" (click)="toggleFollowUpQuestions(childFollowUp, $event)">
                                  {{ expanded[childFollowUp.id] ? slocale("Hide follow up questions") : slocale("Show follow up questions") }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        [id]="'collapseFollowUp' + childFollowUp.id"
                        class="accordion-collapse collapse"
                        [ngClass]="{ show: expanded[childFollowUp.id] }"
                        [attr.aria-labelledby]="'headingFollowUp' + childFollowUp.id"
                        data-bs-parent="#followUpAccordion"
                      >
                        <div class="accordion-body p-3">
                          <p class="text-muted">{{ childFollowUp.desc }}</p>
                          <div [innerHTML]="renderQuestionOptions(childFollowUp)"></div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="expanded[childFollowUp.id]">
                      <ng-container *ngTemplateOutlet="followUpQuestions; context: { parentQuestion: childFollowUp }"></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <p *ngIf="toConfirmUpdate">{{ locale("locale_key.modal.questionnaire_preview.confirm_edit_mappings") }}</p>
    <button class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
    <button *ngIf="!toConfirmUpdate && mappingEdited" class="btn primary-button custom-button" (click)="toConfirmUpdate = true">{{ locale("locale_key.modal.questionnaire_preview.save_mapping") }}</button>
    <button *ngIf="toConfirmUpdate" class="btn primary-button custom-button" (click)="updateMapping()">{{ locale("locale_key.general.buttons.confirm") }}</button>
  </div>
</div>
