import { DashboardChartFe } from "../dashboard/DashboardChartFe"
import { AbstractSharedResourceFe } from "./AbstractSharedResourceFe"
import { SharedResourceTypeFe } from "./SharedResourceTypeFe"

export class SharedDashboardFe extends AbstractSharedResourceFe {
  name: string
  description: string
  charts: DashboardChartFe[]

  constructor(
    name: string,
    description: string,
    charts: DashboardChartFe[],
    objectSharedId: string,
    sharingPersonCompanyId: string,
    sharingPersonCompanyName: string,
    sharingPersonAffId: string, 
    sharingPersonFirstName: string,
    sharingPersonLastName: string,
    accessType: string
  ) {
    super(
      objectSharedId,
      SharedResourceTypeFe.DASHBOARD,
      sharingPersonCompanyId,
      sharingPersonCompanyName,
      sharingPersonAffId,
      sharingPersonFirstName,
      sharingPersonLastName,
      accessType
    )  
    this.name = name
    this.description = description
    this.charts = charts
  }

  public static fromTransfer (transfer: any) {
    let dashboard = new SharedDashboardFe (
      transfer.name,
      transfer.description,
      transfer.charts.map(c => DashboardChartFe.fromTransfer(c)),
      transfer.objectSharedId,
      transfer.sharingPersonCompanyId,
      transfer.sharingPersonCompanyName,
      transfer.sharingPersonAffId,
      transfer.sharingPersonFirstName,
      transfer.sharingPersonLastName,
      transfer.accessType
    )
    return dashboard
  }

  sortChartsByOrdinal() {
    this.charts = this.charts.sort((c1, c2) => {
      if (c1.ordinal < c2.ordinal) return -1 
      else return 1 
    })
  }

  updateChartsOrdinal(oldIndex, newIndex) {
    this.charts.splice(newIndex, 0, this.charts.splice(oldIndex, 1)[0])
    this.charts.forEach((chart, index) => chart.ordinal = index)
  }

}