export class RequestFolderFe {
  id: string;
  name: string;
  creatorAffId: string;
  requestGroups: string[];

  constructor(
    id: string,
    name: string,
    creatorAffId: string,
    requestGroups: string[]
  ) {
    this.id = id;
    this.name = name;
    this.creatorAffId = creatorAffId;
    this.requestGroups = requestGroups;
  }

  public static fromTransfer(transfer: any) : RequestFolderFe{
    const requestGroups = JSON.parse(transfer.requestGroups);
    const requestFolder= new RequestFolderFe(
      transfer.id,
      transfer.name,
      transfer.creatorAffId,
      requestGroups
    );
    return requestFolder
  }

}