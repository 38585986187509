import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import _ from "lodash";
import { ValidationRegex } from "src/app/model/form-validation/ValidationRegex";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { DataCategoryServiceFe } from "src/app/services/DataCategoryServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { UnitFe } from "../../unit-systems/model/UnitFe";
import { groupUnitsByMeasurementTypeAndSystem } from "../../unit-systems/model/utils";
import { DatapointProvidedBy } from "src/app/model/taxonomy/DatapointProvidedByFe";
import { UnitMeasurementTypes, getUnitMeasurementTypeName, getUnitMeasurementTypeDefaultUnitSymbol } from "../../unit-systems/model/UnitMeasurementType";
import { DatapointDatatype } from "src/app/model/taxonomy/DatapointDatatypeFe";
import { CustomEmissionFactorFe } from "src/app/model/emissions/CustomEmissionFactorFe";
import { TaxonomyEmissionFactorFe } from "src/app/model/taxonomy/TaxonomyEmissionFactorFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { UnitServiceFe } from "src/app/services/UnitServiceFe";

@Component({
  selector: "add-data-point",
  templateUrl: "./add-data-point.component.html",
  styleUrls: ["./add-data-point.component.scss"],
})

export class AddDatapointComponent extends AbstractLanguageComponent implements OnInit {
 
  @Input() entityKey: string;
  @Input() isCalculatedColumn = true;
  dataCategory: EntityFe;

  dataPointFormGroup: FormGroup

  unit: UnitFe;
  taxonomy: TaxonomyInfoFe;
  showCalculationBuilder = false;
  @Output() closeEvent = new EventEmitter<boolean>();
  hasCalculationErrors: boolean = false;
  showCalculationErrors: boolean = false;

  selectedDataCategory: any
  dataPointToCreate: TaxonomyAttributeFe

  units: UnitFe[] = []
  unitsByMeasurementType;
  customUnits = []

  providedByInput = "user";
  conversionFactorMeasurementTypeInput = "";
  sourceMeasurementTypeInput = "";
  measurementTypes = [];
  filteredMeasurementTypes = [];
  
  constructor(private stateService: StateServiceFe, public languageService: LanguageService, public categoryService: DataCategoryServiceFe, private formBuilder: FormBuilder, private unitService: UnitServiceFe) { 
    super(languageService)

    this.unit = this.unitService.getNoUnit();

    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.bootstrap();
    });

    this.dataPointFormGroup = this.formBuilder.group({
      descriptionEn: new FormControl(null, [Validators.required]),
      shortNameEn: new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.DatapointLabelEnglish), this.checkDatapointDuplicateName.bind(this)]),
      dataType: new FormControl(null, [Validators.required])
    });

    if (this.activeLanguage.code !== 'en') {
      this.dataPointFormGroup.addControl('descriptionLang', new FormControl(null, [Validators.required]));
      this.dataPointFormGroup.addControl('shortNameLang', new FormControl(null, [Validators.required]));
    }
  }

  async ngOnInit(): Promise<void> {
    await this.bootstrap();
  }

  async bootstrap() {
    this.taxonomy = await this.categoryService.getTaxonomy()
    if (this.entityKey) {
      this.dataCategory = (await this.categoryService.getTaxonomy()).entityByKey(this.entityKey);
      this.selectedDataCategory = {level_3 : this.dataCategory}
    }

    let attr = {taxonomyKey: this.dataCategory.key}    
    this.dataPointToCreate = new TaxonomyAttributeFe(attr, this.languageService);
    this.dataPointToCreate.isNew = true;
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units);
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard && unit.shouldDisplay);
  
    Object.keys(UnitMeasurementTypes).forEach((key) => {
      const measurementType = {
        key,
        name: getUnitMeasurementTypeName(UnitMeasurementTypes[key]),
        defaultUnitSymbol: getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[key])
      };
      this.measurementTypes.push(measurementType);
    });

    let measurementTypes = _.cloneDeep(this.measurementTypes);
    //filter out measurement type
    const exludeMeausurementTypes = [UnitMeasurementTypes.NO_UNIT, UnitMeasurementTypes.BINARY];
    measurementTypes = measurementTypes.filter((measurementType) => !exludeMeausurementTypes.includes(measurementType.key));
    this.filteredMeasurementTypes = measurementTypes;
  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  addDatapoint() {
    if (this.dataPointFormGroup.controls.dataType.value == 'NUMERIC' && !this.unit) {
      return;
    }
    if (this.dataPointFormGroup.controls.dataType.value == DatapointDatatype.EMISSION_FACTOR && (!this.sourceMeasurementTypeInput || !this.conversionFactorMeasurementTypeInput)) {
      return;
    }
    this.dataPointToCreate.label = {}
    this.dataPointToCreate.description = {}
    
    // Added in en later can edit them in data point page
    if (this.activeLanguage.code !== 'en') {
      this.dataPointToCreate.description[this.activeLanguage.code] = this.dataPointFormGroup.controls.descriptionLang.value?.trim()
      this.dataPointToCreate.label[this.activeLanguage.code] = this.dataPointFormGroup.controls.shortNameLang.value?.trim()
    }

    this.dataPointToCreate.description['en'] = this.dataPointFormGroup.controls.descriptionEn.value?.trim()
    this.dataPointToCreate.label['en'] = this.dataPointFormGroup.controls.shortNameEn.value?.trim()
    this.dataPointToCreate.datatype = this.dataPointFormGroup.controls.dataType.value
    this.dataPointToCreate.unit = this.unit

    
    if(this.dataPointToCreate.datatype == DatapointDatatype.EMISSION_FACTOR){
      // create example emission factor
      const sourceMeasurementType = _.find(this.measurementTypes, { 'key': this.sourceMeasurementTypeInput }) 
      const sourceUnit = _.find(this.units, { 'symbol': sourceMeasurementType.defaultUnitSymbol })

      const conversionMeasurementType = _.find(this.measurementTypes, { 'key': this.conversionFactorMeasurementTypeInput }) 
      const conversionUnit = _.find(this.units, { 'symbol': conversionMeasurementType.defaultUnitSymbol })
      
      const emissionFactor = new CustomEmissionFactorFe(IdUtil.next(), '', sourceUnit?.name ?? "", sourceUnit?.symbol ?? "", 1, conversionUnit?.symbol ?? "", "SYSTEM_EXAMPLE", {});
      const ef: TaxonomyEmissionFactorFe = {
        isFallback: false,
        value: emissionFactor
      };
      this.dataPointToCreate.emissionFactors[0] = ef;
    }

    this.categoryService.addDataPoint(this.dataCategory, this.dataPointToCreate);

    this.closeModal();
  }

  builderChanged(event) {
    if ((event.target as HTMLInputElement).checked) {
      this.dataPointToCreate.providedBy =  DatapointProvidedBy['calculation'];
    } else {
      this.dataPointToCreate.providedBy = DatapointProvidedBy['user'];
    }
  }

  updateHasCalculationErrors(value: boolean) {
    this.hasCalculationErrors = value;
    if (!this.hasCalculationErrors && this.showCalculationErrors) {
      this.showCalculationErrors = false;
    }
  }

  getTaxonomyTitle() {
    return this.dataCategory?.getTaxonomyTitle(this.taxonomy)
  }

  isLanguageEnglish() {
    return this.languageService.activeLanguage.code === 'en'
  }

  getCategoryLanguageFlag() {
    return `fs-4 fi fi-${this.activeLanguage.icon}`
  }

  checkDatapointDuplicateName(control: AbstractControl): ValidationErrors | null {
    const shortName = control.value;
    if (this.isDuplicateDatapoint(shortName)) {
      return { duplicateDatapointName: true };
    }
    return null;
  }

  isDuplicateDatapoint(name): boolean {
    return this.selectedDataCategory?.level_3.columns.filter(col => col.label['en'] === name).length >= 1;
  }
  
  getMeasurementTypes() {
    let finalMeasurementTypes = this.filteredMeasurementTypes;
    // filter out measurement types without default unit symbol
    finalMeasurementTypes = finalMeasurementTypes.filter( measurementType => !_.isEmpty(measurementType.defaultUnitSymbol) )
    // map unit name
    finalMeasurementTypes = finalMeasurementTypes.map( measurementType => {
      const unit = _.find(this.units, { 'symbol': measurementType.defaultUnitSymbol })
      if(unit){
        measurementType.defaultUnitName = unit.name
      }
      return measurementType;
    })
    return finalMeasurementTypes;
  }

}
