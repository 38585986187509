import { AbstractSignInMethodFe } from "./AbstractSignInMethodFe"
import { SignInMethodProviderFe } from "./SignInMethodProviderFe"
import { TenantFe } from "./TenantFe"

export class CompanyMicrosoftSignInMethodFe extends AbstractSignInMethodFe{

  tenants: TenantFe[]
  
  constructor (
    isEnabled: boolean,
    tenants: TenantFe[]
  ) {
    super (
      SignInMethodProviderFe.MICROSOFT,
      isEnabled
    )
    this.tenants = tenants
  }
  
  public static fromTransfer (transfer: any): CompanyMicrosoftSignInMethodFe {
    let singInMethod = new CompanyMicrosoftSignInMethodFe(
      transfer.isEnabled,
      transfer.tenants.map(t => TenantFe.fromTransfer(t))
    )
    return singInMethod
  }
}