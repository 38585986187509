import { StringUtilFe } from "src/app/utils/StringUtilFe"

export abstract class AbstractRecipientInfoFe {
  email: string
  action: string
  
  constructor(
    email: string,
    action: string
  ) {
    email = email ? StringUtilFe.trimEmail(email) : email;
    this.email = email
    this.action = action
  }

}