import { AbstractProjectInfoAddFe } from "./AbstractProjectInfoAddFe";
import { PROJECT_TYPE } from "../ProjectTypeFe";
import { CSRDProjectDefinition } from "../CSRDProjectInfoFe";

export class CSRDProjectInfoAddFe extends AbstractProjectInfoAddFe {
  definition: CSRDProjectDefinition;
  constructor(name: string, draft: boolean, definition : CSRDProjectDefinition, createdAt: string) {
    super(PROJECT_TYPE.CSRD, name, draft, createdAt);
    this.definition = definition;
  }
}
