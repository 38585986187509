export class BooleanStatementFe {
  public static TYPE_KEY = 'EMPTY' 
  public static SPACE = ' '

  constructor() {
  }  

  public toFormulaString(): string {
    return 
  }
  
  public evaluate(values: Map<string, any>): any {
  }

}