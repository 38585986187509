import { Component, Input, OnInit } from "@angular/core"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"
import { DirectEntryInfoFe } from "src/app/model/directEntry/DirectEntryInfoFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { StageTableDataGridIntergationService } from "../data/StageTableDataGridIntergationService"
import { TableDataFe } from "src/app/model/schema/TableDataFe"
import { RowStatusFe } from "src/app/model/schema/RowStatusFe"
import { RouterFe } from "src/app/route/RouterFe"
import { AutomatedFeedInfoFe } from "src/app/model/automatedFeed/AutomatedFeedInfoFe"
import { AutomatedFeedDataGridIntergationService } from "./AutomatedFeedDataGridIntergationService"
import { DatahubService } from "./DatahubService"
import { DataGridTableMode } from "../projects/data-grid-ui/model/DataGridTableMode"

@Component({
  selector: "automated-feed",
  templateUrl: "./automatedFeed.component.html",
  styleUrls: ["./data-hub.component.scss"],
})

export class AutomatedFeedComponent extends AbstractLanguageComponent implements OnInit {
  
  automatedFeed: AutomatedFeedInfoFe;
  menuCollapsed: boolean

  detailsData: any
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  loadingInfo: boolean = false
  loadingInProgress: boolean = false
  isDataExtractor = false
  toCopyFeedTableService: StageTableDataGridIntergationService; 
  taxonomyInfo: TaxonomyInfoFe;

  constructor(languageService: LanguageService, private responsive: ResponsiveService, private stateService: StateServiceFe, public datahubService: DatahubService,
    private displayService: DisplayServiceFe, private modalRef: BsModalRef, private modalService: BsModalService, private backendService: RouterFe) {
    super(languageService)
    
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.screenSize = this.responsive.currentScreenWidthSize
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
  }

  async ngOnInit() {
    this.automatedFeed = this.datahubService.selectedAutomatedFeed
    this.automatedFeed.loadInProgress = true      
    let {depTaxonomy, newTaxonomy} = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy;
    if (!this.automatedFeed.hasContent()) {
      let entity = this.taxonomyInfo.entityByKey(this.automatedFeed.taxonomyKey)
      let dataGridService = new AutomatedFeedDataGridIntergationService(this.automatedFeed, entity, this.backendService, this.languageService)
      await dataGridService.loadMainTablePage()
      this.automatedFeed.dataGridService = dataGridService
    }
    this.displayService.updateQuickTipsData({viewMode:'table_viewer', page:'data'})
    this.automatedFeed.loadInProgress = false
  }

  async dataExtracted (newTableData: TableDataFe) {
    newTableData.rows.forEach(row => row.status = RowStatusFe.ADDED)
    this.automatedFeed.tableData.rows = this.automatedFeed.tableData.rows.concat(newTableData.rows)
    await this.automatedFeed.dataGridService.observer.renderNewDataTable()
    let isValid = this.automatedFeed.dataGridService.observer.checkDataValidity()
    this.automatedFeed.dataGridService.markDataModified(newTableData.rows.length>0, isValid)
    this.closeFileExtractor(this.automatedFeed);
  } 

  closeFileExtractor (info: AutomatedFeedInfoFe) {
    this.isDataExtractor = false;
    info.dataGridService.mode = DataGridTableMode.EDIT_TABLE_DATA
  }


}
