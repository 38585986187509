import { KpiFilterFe } from "../kpi/filter/KpiFilterFe";
import { DataPointFe } from "./DataPointFe";

export class TimeGroupingFe {

  public dataPoints: DataPointFe[] = []
  public timeScales: string[] = []
  public filter: KpiFilterFe | undefined
  
  constructor(){}
  
  public static fromTransfer(transfer: any): TimeGroupingFe {
    let grouping = new TimeGroupingFe();
    grouping.dataPoints = transfer.dataPoints.map(dataPoint => DataPointFe.fromTransfer(dataPoint))
    grouping.timeScales = transfer.timeScales
    grouping.filter = transfer.filter ? KpiFilterFe.fromTransfer(transfer.filter) : undefined;
    return grouping
  }
}
