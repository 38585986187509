import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'csrd-dr-requests',
  templateUrl: './dr-requests.component.html',
  styleUrls: ['./dr-requests.component.scss']
})
export class DrRequestsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
