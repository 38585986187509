import { AbstractRecipientInfoFe } from "../add/AbstractRecipientInfoFe"
import { RecipientInfo_ActionFe } from "../add/RecipientInfo_ActionFe"

export class RecipientInfo_DeleteFe extends AbstractRecipientInfoFe {

  constructor(
    email: string
  ) {
    super(
      email,
      RecipientInfo_ActionFe.DELETE
    )
  }
}