import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";

@Component({
  selector: "ef-filter",
  templateUrl: "./ef-filter.component.html",
  styleUrls: ["./ef-filter.component.scss"]
})

export class EmissionFactorFilterComponent extends AbstractLanguageComponent {

  @Input() valuesToFilterFrom = new Set<string>()
  filteredValues = []
  @Input() appliedFilter = new Set<string>();
  @Output() filterUpdated = new EventEmitter<Set<string>>();

  constructor(public languageService: LanguageService){
    super(languageService)
  }

  ngOnInit() {
    this.applySearch()
  }

  updateFilter(term: string, event: any) {
    if (event.target.checked) {
      this.appliedFilter.add(term);
    } else {
      this.appliedFilter.delete(term);
    }
    this.markFilterUpdated()
  }

  markFilterUpdated() {
    this.filterUpdated.emit(this.appliedFilter)
  }
  
  selectAll() {
    this.appliedFilter = new Set(this.filteredValues)
    this.markFilterUpdated()
  }
  
  deselectAll() {
    this.filteredValues.forEach(val => this.appliedFilter.delete(val))
    this.markFilterUpdated()
  }
  
  applySearch(keyword: string = '') {
    this.filteredValues = [...this.valuesToFilterFrom].filter((value: string) => 
      value.match(new RegExp(keyword, "i"))
    );
  }
  
  isSelectAllBtnDisabled() {
    return this.appliedFilter?.size == this.filteredValues.length
  }

  isDeSelectAllBtnDisabled() {
    return this.appliedFilter?.size == 0
  }
}
