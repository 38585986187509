import { stat } from "fs"
import { TextUtils } from "src/app/utils/text.utils"
import { BooleanStatementBuilderFe } from "../../data-suppliers/request/questionnaire/condition/BooleanStatementBuilderFe"
import { BooleanStatementFe } from "../../data-suppliers/request/questionnaire/condition/BooleanStatementFe"
import { NullValueStatementFe } from "../../data-suppliers/request/questionnaire/condition/NullValueStatementFe"
import { TwoOperandStatementFe } from "../../data-suppliers/request/questionnaire/condition/TwoOperandStatementFe"
import { ValueStatementFe } from "../../data-suppliers/request/questionnaire/condition/ValueStatementFe"
import { VariableStatementFe } from "../../data-suppliers/request/questionnaire/condition/VariableStatementFe"


export class KpiFilterConditionFe {
  public static INCLUDE = 'include'
  public static EXCLUDE = 'exclude'
}
export class KpiFilterFe {
  public filterCondition: string
  public statement: BooleanStatementFe
  
  public unitTwoOperandStatements: TwoOperandStatementFe[] = []

  constructor (filterCondition: string, statement: BooleanStatementFe) {
    this.filterCondition = filterCondition
    this.statement = statement
    this.setUnitTwoOperandStatements();
  }
  
  static fromTransfer (transfer: any): KpiFilterFe {
    if (transfer) {
      let filterCondition = transfer['filterCondition']
      let statement = BooleanStatementBuilderFe.fromTransfer(transfer['statement'])
      let pipelineDef = new KpiFilterFe(filterCondition, statement)
      return pipelineDef
    } else {
      return null
    }
  }

  setUnitTwoOperandStatements () {
    let filterStatements: TwoOperandStatementFe[] = []
    let condition = this.statement;
    while (condition instanceof TwoOperandStatementFe) {
      if (condition.leftStatement instanceof TwoOperandStatementFe && condition.rightStatement instanceof TwoOperandStatementFe) {
        filterStatements.push(condition.rightStatement);
      } else {
        filterStatements.push(condition);
      }
      condition = condition.leftStatement
    }
    this.unitTwoOperandStatements = filterStatements.reverse();
  }

  getInnerHTML () {
    let label = `${this.filterCondition == KpiFilterConditionFe.INCLUDE ? 'Only includes' : 'Excludes'} the data where`;
    let statementsConnector = (this.statement as TwoOperandStatementFe).operator
    this.unitTwoOperandStatements.forEach((statement, index) => {
      if (index!=0) {
        label += ` ${statementsConnector.label.toLowerCase()}`
      }
      if (statement.leftStatement instanceof VariableStatementFe) {
        let field = TextUtils.capitalizeFirstLetterAllWords(statement.leftStatement.reference)
        label += ` <b>${field}</b>`
      }
      label += ` ${statement.operator.label}`
      if (statement.rightStatement instanceof ValueStatementFe && !(statement.rightStatement instanceof NullValueStatementFe)) {
        label += ` <b>${statement.rightStatement.value}</b>`
      }
    })
    return label
  }

  
  getLabel () {
    let label = `${this.filterCondition == KpiFilterConditionFe.INCLUDE ? 'Only includes' : 'Excludes'} the data where`;
    let statementsConnector = (this.statement as TwoOperandStatementFe).operator
    this.unitTwoOperandStatements.forEach((statement, index) => {
      if (index!=0) {
        label += ` ${statementsConnector.label.toLowerCase()}`
      }
      if (statement.leftStatement instanceof VariableStatementFe) {
        let field = TextUtils.capitalizeFirstLetterAllWords(statement.leftStatement.reference)
        label += ` ${field}`
      }
      label += ` ${statement.operator.label}`
      if (statement.rightStatement instanceof ValueStatementFe && !(statement.rightStatement instanceof NullValueStatementFe)) {
        label += ` ${statement.rightStatement.value}`
      }
    })
    return label
  }
}

