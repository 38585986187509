<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">

    </div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 class="text-uppercase">{{locale('locale_key.pages.admins.title')}}</h3>
           <!-- dropdown menu instead of toolbar for smaller pages-->
      <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
        <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg;"></i>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
          <ng-container *ngFor="let toolbarGroup of pageToolbar ; let groupIndex=index">
            <div>
              <ng-container *ngFor="let toolbarButton of toolbarGroup">
                <li role="menuitem">
                  <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)"
                  *ngIf="toolbarButton.visible()"
                  >
                    <i [class]="toolbarButton.icon"></i>
                    <span>{{ toolbarButton.shortLabel }}</span>
                  </button>
                </li>
              </ng-container>
              <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
            </div>
          </ng-container>
        </ul>
      </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
             
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper mt-5" *ngIf="isLoadingData">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex w-100 flex-row me-2" *ngIf="!isLoadingData">
            <div class="w-100 pt-1 me-2" style="overflow-x: hidden; overflow-y: auto; max-height: 80vh;">
              <div class="card mb-4 pb-4">
                <div class="row" style="overflow: hidden">
                  <div class="card-body p-0 mx-3">
                    <div class="row m-1">
                      <div class="col-lg-8 col-md-12 text-start">
                        <h4 class="ps-3 pt-3 d-inline make-col">{{locale('locale_key.pages.admins.lists_of.title')}}</h4>
                        <select class="form-select form-control d-inline w-25 mt-2 ms-2" [(ngModel)]="selectedOption"
                        [ngClass]="{'w-100': screenSize.isXSmallSize()}">
                          <option value="{{o.value}}" *ngFor="let o of options">{{o.label}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="ms-3">
                      <ng-container *ngIf="selectedOption=='CSA'">
                        <div class="d-flex p-3 btn-add mx-1 text-white mt-2">
                          <div class="col-lg-3 col-md-4 col-4 px-3">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.name_column.title')}}</h5>
                          </div>
                          <div class="col-lg-3 col-md-4 col-4  px-3 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.email_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-4 col-4 px-3 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.status_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 px-3" 
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.joined_on_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 px-3" 
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.last_login_column.title')}}</h5>
                          </div>
                        </div>
                        <div class="vertical-scroll">
                          <div class="d-flex flex-column my-0">
                            <ng-container *ngFor="let admin of csadmins" >
                              <div class="row border-shade pt-4 pb-3 m-1 bg-transparent mb-2 border" *ngIf="admin.affiliationStatus  =='ACTIVE'" (click)="selectedUser = admin" [ngClass]="{ selected: selectedUser== admin}" (click)="selectedUser=admin">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-6 ps-0">
                                  <div class="d-flex flex-row m-0 p-0">
                                    <div class="circle-icon me-1 ms-2" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                                    <span class="text-truncate ms-2"> {{admin.getName()}}</span>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-4 text-truncate d-none d-sm-block">
                                  <span class="text-truncate">{{admin.affiliationEmail}}</span>
                                </div>
                                <div class="col-lg-2 col-md-2 col-2 d-none d-sm-block">
                                  <p class="badge badge-green" style="width: 100px; padding: 5px">{{locale('locale_key.general.state.connected')}}</p>
                                </div>
                                <div class="col-2" 
                                [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                                  <p class="text-truncate">{{admin.getActivationDate()}}</p>
                                </div>
                                <div class="col-sm-2 col-6 d-flex">
                                  <p class="d-inline text-truncate" 
                                  [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">{{admin.getlastLoggedAt()}}</p>
                                  <span class="ms-auto me-0 pe-0">
                                    <div class="btn-group me-0 pe-0">
                                      <button type="button" class="description-btn ms-auto me-0 pe-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
                                      </button>
                                      <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                        <button class="dropdown-item ms-0 ps-1" type="button" (click)="openSignInSettings(signInSettings, 'user')"><i class="la la-shield me-1"></i>{{locale('locale_key.pages.admins.button.sign_in_methods')}}</button>
                                        <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(deactivateAff, 'modal-md')"><i class="la la-user"></i> {{locale('locale_key.pages.admins.button.deactivate_user')}}</button>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="selectedOption=='CS'">
                        <div class="d-flex p-3 btn-add mx-1 text-white mt-2">
                          <div class="col-lg-2 col-md-4 col-sm-4 col-6 text-start">
                            <h5 class="text-white text-start">{{locale('locale_key.pages.admins.user_table.name_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-4 col-4 text-start d-none d-sm-block">
                            <h5 class="text-white text-start">{{locale('locale_key.pages.admins.user_table.email_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-4 col-sm-4 col-12 text-start">
                            <h5 class="text-white text-start">{{locale('locale_key.pages.admins.user_table.assigned_company_to_customer_success_user_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-2 text-start"
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.status_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 text-start"
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white text-start">{{locale('locale_key.pages.admins.user_table.joined_on_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 text-start"
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white text-start">{{locale('locale_key.pages.admins.user_table.last_login_column.title')}}</h5>
                          </div>
                        </div>
                        <div class="vertical-scroll" style="max-height: 75vh;">
                          <div class="border d-flex flex-column m-1" *ngFor="let user of cs" (click)="selectedUser = user" [ngClass]="{ selected: selectedUser == user }">
                            <div class="row border-shade pt-2 pb-2 px-1 m-1 bg-transparent mb-2 border-b">
                              <div class="col-lg-2 col-md-4 col-sm-4 col-6 ps-0">
                                <div class="d-flex flex-row m-0 p-0">
                                  <div class="circle-icon me-1 ms-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                                  <span class="text-truncate ms-2"> {{user.getName()}}</span>
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-4 col-4 d-none d-sm-block">
                                <span>{{user.email}}</span>
                              </div>
                              <div class="col-lg-2 col-md-3 col-sm-3 col-5">
                                <div [ngClass]="'text-white numberCircleDark m1-1'"><span class="circleSpan">{{user.accessibleCompanies.length}}</span></div>
                              </div>
                              <div class="col-2"
                              [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                                <p class="badge" [ngClass]="{ 'badge-green': 'APPROVED' }">{{locale('locale_key.general.state.connected')}}</p>
                              </div>
                              <div class="col-2 d-flex" 
                              [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                                <p>{{user.getJoinedOn()}}</p>
                              </div>
                              <div class="col-lg-2 col-md-1 col-1 d-flex justify-content-between me-0 pe-0">
                                <span 
                                [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">{{user.getLastLoggedAt()}}</span>
                                <span class="ms-auto me-0 pe-0">
                                  <div class="btn-group me-0 pe-0">
                                    <button type="button" class="description-btn ms-auto me-0 pe-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
                                    </button>
                                    <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                      <button class="dropdown-item ms-0 ps-1" type="button" (click)="openSignInSettings(signInSettings, 'user')"><i class="la la-shield me-1"></i>{{locale('locale_key.pages.admins.button.sign_in_methods')}}</button>
                                      <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(assignCompany, 'modal-lg')"><i class="la la-edit me-1"></i>{{locale('locale_key.pages.admins.customer_success_admins.button.assign_companies')}}</button>
                                      <div class="dropdown-divider"></div>
                                      <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(deactivateAff, 'modal-md')"><i class="la la-user"></i> {{locale('locale_key.pages.admins.button.deactivate_user')}}</button>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="row ps-3">
                              <table class="table table-responsive table-borderless">
                                <tbody>
                                  <ng-container *ngFor="let company of user.accessibleCompanies; let i=index; let last = last">
                                    <tr *ngIf="i>2 ? user.showAllCompanies : true">
                                      <td>{{company.companyName}}</td>
                                      <td class="text-muted">{{locale('locale_key.pages.admins.assign_company_modal.access_received_on')}} {{company.getAccessReceivedAt()}}</td>
                                    </tr>
                                    <td *ngIf="!user.showAllCompanies && i==2" (click)="user.showAllCompanies = true">{{locale('locale_key.button.see_more')}}...</td>
                                    <td *ngIf="user.showAllCompanies && last" (click)="user.showAllCompanies = false">{{locale('locale_key.button.see_less')}}...</td>
                                  </ng-container>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="selectedOption=='DEACTIVATED_CS'">
                        <div class="d-flex p-3 btn-add mx-1 text-white mt-2">
                          <div class="col-lg-2 col-md-4 col-sm-4 col-12">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.name_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-4 col-4 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.email_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-4 col-4 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.assigned_company_to_customer_success_user_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-3"
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.status_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2" 
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.joined_on_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2"
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.deactivated_on.title')}}</h5>
                          </div>
                        </div>
                        <div class="vertical-scroll" style="max-height: 75vh;">
                          <div class="d-flex flex-column my-0">
                            <div class="row border-shade pt-2 pb-75 m-1 bg-transparent mb-2 border" *ngFor="let user of inactiveCS" (click)="selectedUser = user" [ngClass]="{ selected: selectedUser == user }">
                              <div class="col-2 ps-0">
                                <div class="row m-0 p-0">
                                  <div class="circle-icon me-1 ms-2" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                                  <span class="text-truncate ms-2"> {{user.getName()}}</span>
                                </div>
                              </div>
                              <div class="col-2">
                                <span>{{user.email}}</span>
                              </div>
                              <div class="col-2">
                                <p>{{user.accessibleCompanies[0].companyName}}</p>
                              </div>
                              <div class="col-2">
                                <span class="badge badge-gray gray-bg deactivated">{{locale('locale_key.general.state.deactivated')}}</span>
                              </div>
                              <div class="col-2">
                                <p>{{user.getJoinedOn()}}</p>
                              </div>
                              <div class="col-2">
                                <p>{{user.getDeactivationDate()}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="selectedOption=='DEACTIVATED_CSA'">
                        <div class="d-flex p-3 btn-add mx-1 text-white mt-2">
                          <div class="col-lg-3 col-md-4 col-sm-4 col-12">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.name_column.title')}}</h5>
                          </div>
                          <div class="col-lg-3 col-md-4 col-4 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.email_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2 col-md-4 col-4 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.status_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2" 
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.joined_on_column.title')}}</h5>
                          </div>
                          <div class="col-lg-2"
                          [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.admins.user_table.deactivated_on.title')}}</h5>
                          </div>
                        </div>
                        <div class="vertical-scroll" style="max-height: 75vh;">
                          <div class="d-flex flex-column my-0">
                            <ng-container *ngFor="let user of csadmins">
                              <!-- pb-75 should be changed to the latest version -->
                              <div class="row border-shade py-2 m-1 bg-transparent mb-2 border" *ngIf="user.affiliationStatus !='ACTIVE'" (click)="selectedUser = user" [ngClass]="{ selected: selectedUser == user }">
                                <div class="col-lg-3 col-md-4 col-sm-4 col-12 ps-0">
                                  <div class="d-flex flex-row m-0 p-0">
                                    <div class="circle-icon me-1 ms-2"><i class="las la-user fs-4 text-white"></i></div>
                                    <span class="text-truncate ms-2 my-auto"> {{user.getName()}}</span>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-4 text-truncate d-none d-sm-block">
                                  <span>{{user.affiliationEmail}}</span>
                                </div>
                                <div class="col-lg-2 col-md-4 col-4 d-none d-sm-block">
                                  <span class="badge badge-gray gray-bg deactivated">{{locale('locale_key.general.state.deactivated')}}</span>
                                </div>
                                <div class="col-2"
                                [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                                  <p>{{user.getActivationDate()}}</p>
                                </div>
                                <div class="col-2"
                                [ngClass]="{'d-none': screenSize.isMediumSize(), 'display-none': screenSize.isSmallerSize()}">
                                  <p>{{user.getDeactivationDate()}}</p>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>           
        </div>       
      </div>
    </div>
  </div>
</div>

<ng-template #inviteCustomerSuccessModal>
  <app-customer-success-invite [mode]="inviteMode"></app-customer-success-invite>
</ng-template>
<ng-template #assignCompany>
  <div class="supplier-modal">
    <div class="main-modal-header modal-header">
      <h4 class="modal-title text-white"><i class="la la-edit fs-4"></i>{{locale('locale_key.pages.admins.assign_company_modal.title')}}</h4>
      <button type="button" class="btn close" *ngIf="!isLoading" (click)="closeModal()">
        <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body scroll-example" style="border-top: none">
      <div class="card-header pb-0">
        <p class="pb-2">{{locale('locale_key.pages.admins.assign_company_modal.description')}}</p>
        <h5 class="ms-0 ps-0">{{locale('locale_key.pages.admins.title')}}</h5>
        <div class="d-flex flex-row border py-3 d-flex justify-content-center align-items-center mb-2">
          <div class="col-6">
            <div class="d-flex flex-row m-0 p-0">
              <div class="circle-icon me-1 ms-2" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
              <span class="text-truncate ms-2">{{selectedUser.getName()}}</span>
            </div>
          </div>
          <div class="col-6 text-end d-flex flex-row justify-content-center">
            <div class="text-white numberCircleDark mt-0 d-inline"><span class="circleSpan">{{selectedUser.accessibleCompanies.length}}</span></div>
            <span class="ms-2">{{locale('locale_key.pages.admins.assign_company_modal.assigned_companies')}}</span>
          </div>
        </div>
        <h5 class="ms-0 ps-0">{{locale('locale_key.pages.admins.assign_company_modal.assign_companies')}}</h5>
        <div class="row border scroll-example mh-300">
          <table class="table table-responsive table-borderless">
            <tbody>
              <tr>
                <td>
                  <div class="d-flex">
                    <input type="checkbox" class="form-check-inline" (click)="toggleAllCompanies($event)"/>
                    <span><p class="me-2 mb-0">{{locale('locale_key.pages.admins.assign_company_modal.select_all_companies')}}</p></span>
                  </div>
                </td>
              </tr>
              <tr *ngFor="let company of selectedUser.accessibleCompanies">
                <td><input type="checkbox" class="form-check-inline" checked (change)="toggleAssignedCompany($event, company)" />{{company.companyName}}</td>
                <td class="text-muted">{{locale('locale_key.pages.admins.assign_company_modal.access_received_on')}} {{company.getAccessReceivedAt()}}</td>
              </tr>
              <ng-container *ngFor="let company of mgmtCompanies">
                <tr *ngIf="!isAlreadyAssigned(company)">
                  <td><input type="checkbox" class="form-check-inline" [(ngModel)]="company.isSelected" (change)="toggleMgmtCompany($event, company)" />{{company.name}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <span class="me-auto me-3 pe-3"><i class="la la-info-circle pe-2"></i>{{locale('locale_key.pages.admins.assign_company_modal.saving_instructions')}}</span>
        <ng-container *ngIf="!isLoading">
          <button class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.pages.admins.assign_company_modal.button.cancel')}}</button>
          <button class="btn primary-button custom-button ms-3" (click)="assignCompanies()">{{locale('locale_key.pages.admins.assign_company_modal.button.save')}}</button>
        </ng-container>
        <div class="ball-pulse loader-primary" *ngIf="isLoading">
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal -->
<ng-template #deactivateAff>
  <div class="modal-header alert-modal-header">
    <div class="icon-background d-inline mt-2" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidtext-white">
      <i class="las la-exlamation-circle text-white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: -2px; top: -1px"></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 my-auto">{{locale('locale_key.pages.admins.deactivating_user_modal.title', {user_name: selectedUser.getName()})}}</h4>
    <button type="button" class="btn close ms-auto" *ngIf="!isLoading" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h5> {{locale('locale_key.pages.admins.deactivating_user_modal.description', {'user_name': selectedUser.getName()})}} </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!isLoading">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.pages.admins.deactivating_user_modal.button.cancel')}}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="deactivate()">{{locale('locale_key.pages.admins.deactivating_user_modal.button.deactivate')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="isLoading">
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div>
  </div>
</ng-template>

<ng-template #signInSettings>
  <sign-in-setting-modal [type]="signInSettingsType" [selectedUser]="selectedUser" (closeEvent)="closeSignInSettings()"></sign-in-setting-modal>
</ng-template>