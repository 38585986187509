export class PredefinedDateOptionFe {
  from: Date
  to: Date | undefined

  constructor();
  constructor(from: Date, to?: Date);
  constructor(from?: Date, to?: Date) {
    this.from = from
    this.to = to
  }

  public static fromJsonObj(obj: any): PredefinedDateOptionFe {
    return new PredefinedDateOptionFe(
      obj.from,
      obj.to
    )
  }
}