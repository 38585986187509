import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { InterceptorSkipAuth } from '../route/RouterFe';
import { AlertServiceFe } from './AlertServiceFe';
import { LoginServiceFe } from './LoginServiceFe';
import { ErrorsFe, OpenErrorsFe } from '../utils/KNOWN_ERRORS';
import { UserServiceFe} from './UserServiceFe';
import { VisitorServiceFe } from './VisitorServiceFe';
import { AbstractLanguageComponent } from '../utils/language/AbstractLanguageComponent';
import { LanguageService } from './LanguageServiceFe';


@Injectable()
export class GlobalInterceptorFe extends AbstractLanguageComponent implements HttpInterceptor {

  constructor(private alertService: AlertServiceFe, private loginService: LoginServiceFe, private userService: UserServiceFe, 
    private visitorService: VisitorServiceFe, private ErrorsFe: ErrorsFe, public languageService: LanguageService) {
      super(languageService)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers && req.headers.has(InterceptorSkipAuth)) {
      let headers = req.headers.delete(InterceptorSkipAuth);
      let cloned = req.clone({ headers })
      return next.handle(cloned).pipe(catchError((error: any, caught: Observable<any>) => this.openRoutesErrorHandler(error, caught)))
    } else {
      let userInfo
      if (this.userService.isUserAVisitor()) {
        userInfo = this.visitorService.user;
      } else {
        userInfo = this.loginService.loginUser;
      }
      if (userInfo) {
        const cloned = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + userInfo.token) });
        return next.handle(cloned).pipe(catchError((error: any, caught: Observable<any>) => this.authRoutesErrorHandler(error, caught)))
      } else {
        return next.handle(req).pipe(catchError((error: any, caught: Observable<any>) => this.authRoutesErrorHandler(error, caught)))
      }    
    }
  }

  openRoutesErrorHandler(error: any, caught: Observable<any>): Observable<any>  {
    let knowError = this.ErrorsFe.matchError(error.error)
    if (!knowError) {
      this.alertService.showError(this.locale('locale_key.general.error_message.server_cannot_process_request'))
    } else if (knowError && knowError.name == OpenErrorsFe.USER_SESSION_ERROR.name) {
      this.loginService.handleSessionError()
    }  else if (knowError && knowError.hasHandler) {
      return throwError(error)
    } else {
      this.alertService.showError(knowError.message)
      return throwError(error)
    }
  }

  authRoutesErrorHandler(error: any, caught: Observable<any>): Observable<any>  {
    let knowError = this.ErrorsFe.matchError(error.error) 
    if (!knowError) {
      this.alertService.showError(this.locale('locale_key.general.error_message.server_cannot_process_request'))
    } else if (knowError && (knowError.name == OpenErrorsFe.USER_SESSION_ERROR.name || knowError.name == OpenErrorsFe.JWT_TOKEN_EXPIRED_ERROR.name)) {
      this.loginService.handleSessionError()
    } else if (knowError && knowError.hasHandler) {
      return throwError(error)
    } else {
      this.alertService.showError(knowError.message)
      return throwError(error)
    }
  }

}