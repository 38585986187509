<div class="modal fade" tabindex="-1" role="dialog" [ngClass]="{ show: isVisible }" [style.display]="isVisible ? 'block' : 'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header p-0" style="border-bottom: none">
        <h4 class="modal-title ms-2 me-auto"><strong>{{ headerText }}</strong></h4>
        <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true"><i class="las la-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div id="swal2-content" style="display: block pl-2">{{ bodyText }}</div>
      </div>
      <div class="modal-footer" style="border-top: none">
        <div class="float-start">
          <div class="ball-pulse loader-primary" *ngIf="inProgress">
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div>
        </div>
        <div class="float-end">
          <ng-container *ngIf="!inProgress">
            <button type="button" class="btn secondary-button custom-button me-3 mb-1" (click)="cancel()">{{ cancelButtonText }}</button>
            <button type="button" class="btn primary-button custom-button me-3 mb-1" (click)="action()">{{ actionButtonText }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
