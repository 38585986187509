import { AbstractProjectInfoFe } from "./AbstractProjectInfoFe";
import { CCProjectInfoFe } from "./CCProjectInfoFe";
import { CSRDProjectInfoFe } from "./CSRDProjectInfoFe";

export class PROJECT_TYPE {
  public static readonly CC = "CC";
  public static readonly CSRD = "CSRD";

  public static fromTransfer(transfer: any): AbstractProjectInfoFe | undefined {
    switch (transfer.type) {
      case PROJECT_TYPE.CC:
        return CCProjectInfoFe.fromTransfer(transfer);
      case PROJECT_TYPE.CSRD:
        return CSRDProjectInfoFe.fromTransfer(transfer);
    }
  }
}
