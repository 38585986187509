export class Partner_RegisterReuseExistedUserCompanyIdFe {
  invitationUuid: string
  existedUserCompanyId: string
  existedPrimaryContactUserEmail: string
  googleUserCreds: any

  constructor(
    invitationUuid: string,
    existedUserCompanyId: string,
    existedPrimaryContactUserEmail: string,
    googleUserCreds: any
  ) {
    this.invitationUuid = invitationUuid
    this.existedUserCompanyId = existedUserCompanyId
    this.existedPrimaryContactUserEmail = existedPrimaryContactUserEmail
    this.googleUserCreds = googleUserCreds
  }

  public static fromTransfer(transfer: any) {
    let invitation = new Partner_RegisterReuseExistedUserCompanyIdFe(
      transfer.invitationUuid,
      transfer.existedUserCompanyId,
      transfer.existedPrimaryContactUserEmail,
      transfer.googleUserCreds
    )

    return invitation
  }
}