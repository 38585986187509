import { EmailCategoryFe } from './EmailCategoryFe'

export class EmailResponseFe {
  categories: EmailCategoryFe[]

  constructor(categories: any[]) {
    this.categories = categories
  }

  public static async fromTransfer(transfer: any): Promise<EmailResponseFe> {
    const cats = transfer.categories || []
    const catArrayPromise: Promise<EmailCategoryFe>[] = cats.map((e) => EmailCategoryFe.fromTransfer(e))
    const catArray = await Promise.all(catArrayPromise)
    return new EmailResponseFe(catArray)
  }
}
