<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <h3 class="">{{ locale("locale_key.pages.file_explorer.title") }}</h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group ms-4" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button *ngIf="toolbarButton.visible()" type="button" class="btn page-toolbar-button" (click)="handleToolbarAction(toolbarButton.actionName)" data-bs-toggle="tooltip" data-placement="top" [title]="toolbarButton.tooltip" show-delay="3000">
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column me-2">
          <ul class="nav nav-tabs" style="border: none">
            <li class="nav-item">
              <a class="nav-link py-0" [ngClass]="{ active: homeActive() }" data-bs-toggle="tab" (click)="viewHome()">
                <i class="la la-home me-1"></i>
                {{ locale("locale_key.main_navbar.home") }}</a
              >
            </li>
            <ng-container *ngFor="let tabFile of allFiles; let fileIndex = index">
              <li class="nav-item" *ngIf="tabFile.isOpen">
                <a class="nav-link py-0" [ngClass]="{ active: tabFile.isActive }" data-bs-toggle="tab" (click)="viewFile(fileIndex)">
                  <i class="la {{ tabFile.type.icon }} me-1" [ngStyle]="{ color: tabFile.type.color }" *ngIf="!tabFile.inProgress()"></i>
                  <i class="la la-spinner spinner" *ngIf="tabFile.inProgress()"></i>
                  {{ tabFile.label }}
                  <i class="las la-times ml-1" (click)="closeTab(tabFile)"></i>
                </a>
              </li>
            </ng-container>
          </ul>
          <div class="tab-content mx-0">
            <div class="card-body p-0" id="tab-body">
              <div id="home" class="tab-pane" [ngClass]="{ active: homeActive() }" *ngIf="homeActive()">
                <div class="card" style="overflow: auto; height: 88vh">
                  <div class="card-header row p-3 pb-0 bg-white border-bottom-0">
                    <div class="d-flex col-6">
                      <button class="btn dropdown-toggle sorting" type="button" id="sortDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="las la-filter"></i>
                        <span>{{ locale("locale_key.general.buttons.sort") }}</span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-right p0" aria-labelledby="sortDropdown" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(113px, 36px, 0px)">
                        <button class="dropdown-item ascending" (click)="sort('name')">{{ locale("locale_key.pages.file_explorer.sort_option.name") }}</button>
                        <button class="dropdown-item descending" (click)="sort('time')">{{ locale("locale_key.pages.file_explorer.sort_option.upload_time") }}</button>
                      </div>
                    </div>
                    <div class="d-flex col-6">
                      <div *ngFor="let alert of deleteAlerts; let i = index">
                        <div *ngIf="alert.type == 'danger'" class="alert alert-danger alert-dismissible fade show" role="alert">
                          <strong>{{ alert.message }}</strong>
                          <button type="button" class="btn close" data-dismiss="alert" (click)="closeAlert(i)" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <button *ngIf="alert.type == 'success'" (click)="closeAlert(i)" type="button" class="btn btn-outline-success mr-1" id="auto-close">{{ alert.message }} &times;</button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body py-0 ps-4" *ngIf="!inProgress && allFiles.length > 0">
                    <ng-container *ngFor="let fileData of allFiles; let i = index">
                      <div class="card my-3 custom-card">
                        <div class="card-head d-flex justify-content-between" style="padding: 0.5rem">
                          <a role="button" (click)="viewFile(i)" data-bs-toggle="tooltip" data-placement="top" title="Open" show-delay="300">
                            <i
                              class="la {{ fileData.type.icon }} fs-5 me-1"
                              [ngStyle]="{
                                color: fileData.type.color
                              }"
                            ></i>
                            <span class="m-0 text-truncate">{{ fileData.name }}</span>
                          </a>
                          <div class="heading-elements">
                            <div class="d-felx flex-row btn-group time-removed">
                              <span class="font-small-3 me-3">
                                {{ fileData.localTimeCreated() }}
                              </span>
                              <button type="button" class="btn btn-icon action-icon p-0 border-0" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.general.buttons.download')}}" show-delay="300" (click)="downloadFile(i)">
                                <i class="las la-download font-small-3 me-1"></i>
                              </button>
                              <button type="button" class="btn btn-icon action-icon p-0 border-0" data-bs-toggle="tooltip" data-placement="top" title="{{locale('locale_key.general.buttons.delete')}}" show-delay="300" (click)="deletePopup(deleteWindow, i)">
                                <i class="las la-trash-alt font-small-3 me-1"></i>
                              </button>
                            </div>
                            <!-- three dots appears -->
                            <div class="btn-group ms-4" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
                              <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle mt-0 pt-0" aria-controls="dropdown-alignment">
                                <i class="la la-ellipsis-h fs-5 mt-0 pt-0" style="rotate: 90deg"></i>
                              </button>
                              <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
                                <ng-container *ngFor="let btn of btnGroup; let i = index">
                                  <li role="menuItem">
                                    <button type="buttn" class="btn" (click)="handleBtnGroup(btn.actionBtn, i)">
                                      <i [ngClass]="btn.icon"></i>
                                      <span>{{ btn.title }}</span>
                                    </button>
                                  </li>
                                </ng-container>
                              </ul>
                            </div>
                            <!-- three dots end -->
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <ng-container *ngFor="let tabFile of allFiles; let fileIndex = index">
                <div class="tab-pane" [ngClass]="{ active: tabFile.isActive }" *ngIf="tabFile.isActive">
                  <div class="card main-card m-0" style="overflow: scroll !; height: 100vh; max-width: 90vw">
                    <div *ngIf="tabFile.inProgress()">
                      <div class="loader-wrapper mt-5">
                        <div class="loader-container">
                          <div class="ball-grid-pulse loader-primary">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="tabFile.isMainViewer() && !tabFile.inProgress()">
                      <div *ngIf="tabFile.isPdf() || tabFile.isImage()">
                        <div class="row">
                          <div class="col-11 main-section p-0">
                            <div class="card-body p-0" style="height: 100%">
                              <data-extractor [imgExtractor]="tabFile.dataExtractorService"></data-extractor>
                            </div>
                          </div>
                          <div class="col-1 tool-bar-col"></div>
                        </div>
                      </div>
                      <div class="card-body p-0" style="height: 100vh" *ngIf="tabFile.isDoc()">
                        <ngx-doc-viewer [url]="tabFile.publicUrl" viewer="office"> </ngx-doc-viewer>
                      </div>
                      <div class="card-body p-0" style="height: 100vh" *ngIf="tabFile.isExcel()">
                        <datagrid-excel-viewer [excelViewerService]="getExcelViewerService(tabFile)"></datagrid-excel-viewer>
                      </div>
                    </div>
                    <div *ngIf="tabFile.isTableViewer() && !tabFile.inProgress()">
                      <div class="card-body p-0" style="height: 100vh">
                        <datagrid-table-editor [dataGrid]="tabFile.dataGridService"></datagrid-table-editor>
                      </div>
                    </div>
                    <div *ngIf="tabFile.isTextViewer() && !tabFile.inProgress()">
                      <div class="row">
                        <div class="col-11 main-section p-0">
                          <div class="card-body p-0">
                            <pre style="font-family: monospace">{{ tabFile.txtContent }}</pre>
                          </div>
                        </div>
                        <div class="col-1 tool-bar-col">
                          <button
                            type="button"
                            class="btn btn-icon action-icon back-button mb-1 mr-2"
                            container="body"
                            containerClass="tooltipClass"
                            data-bs-toggle="tooltip"
                            data-placement="right"
                            title="Back to Original File"
                            (click)="tabFile.setMainViewer()"
                          >
                            <i class="ft-rewind"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-icon action-icon crop-button mb-1 mr-1"
                            container="body"
                            containerClass="tooltipClass"
                            data-bs-toggle="tooltip"
                            data-placement="right"
                            title="Back to cropper"
                            (click)="tabFile.setCropperViewer()"
                          >
                            <i class="ft-crop"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteWindow>
  <div class="modal-content">
    <div class="modal-header" style="border: none">
      <div class="icon-background d-inline mt-2" style="height: 18px; width: 18px; background-color: #c65c5c; border-radius: 50%">
        <i class="la la-times fs-6 text-white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: 2px; top: -2px"></i>
      </div>
      <h4 class="modal-title ms-2">{{ locale("locale_key.pages.file_explorer.delete_file_confirmation_modal.title") }}</h4>
      <button type="button" class="ms-auto btn close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="false" class="list-inline"
          ><a (click)="modalRef.hide()"><i class="las la-times"></i></a
        ></span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ locale("locale_key.pages.file_explorer.delete_file_confirmation_modal.description") }}</p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <button type="button" id="pullRight" (click)="deleteFileFromStorage(); modalRef.hide()" class="btn btn-add custom-button">{{ locale("locale_key.pages.file_explorer.delete_file_confirmation_modal.button.delete") }}</button>
    </div>
  </div>
</ng-template>

<ng-template #upload>
  <div class="card m-0">
    <div class="main-modal-header modal-header py-0 px-3">
      <h4 class="modal-title text-white">{{ locale("locale_key.pages.file_upload.upload_file.title") }}</h4>
      <button type="button" class="ms-auto btn close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="false" class="list-inline text-white"
          ><a (click)="closeModal()"><i class="las la-times text-white"></i></a
        ></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row mt-0 px-1">
        <div class="col-sm-12">
          <div class="card hoverable dropZone mt-0 pt-0 mb-1 pb-1">
            <div class="card-body border border-dotted" id="dotted">
              <input type="file" id="uploadCaptureInputFile" multiple (change)="selectFilesForUpload($event)" title=" " />
              <h5>{{ locale("locale_key.pages.file_explorer.upload_modal.drag_drop.title") }}</h5>
            </div>
            <h5>{{ locale('locale_key.general.or') }}</h5>
            <div class="row justify-content-center">
              <input type="file" id="uploadCaptureInputFile" #fileInput style="display: none" (change)="selectFilesForUpload($event)" title=" "/>
              <button id="browsefilebutton" (click)="fileInput.click()" class="btn btn-block custom-button"><span class="glyphicon glyphicon-upload"></span> {{ locale("locale_key.pages.file_explorer.upload_modal.button.browse_files") }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row px-1 mh-200">
        <div class="col-sm-12">
          <div class="files-list">
            <div class="single-file" *ngFor="let fileToUpload of filesToUpload; let i = index">
              <div class="fonticon-container">
                <div class="fonticon-wrap mr-0 mb-0">
                  <i class="las la-file-medical"></i>
                </div>
              </div>
              <div class="info">
                <label class="fonticon-classname mb-0">{{ fileToUpload?.label }}</label>
                &nbsp;
                <label class="fonticon-unit mb-0">{{ formatBytes(fileToUpload?.size) }}</label>
                <div class="progress form-group me-0 ms-0">
                  <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress[i]"></div>
                </div>
                <h4 class="name">
                  {{ fileUploadMessages[i] }}
                </h4>
              </div>
              <div class="delete" *ngIf="uploadProgress[i] == 0" (click)="deleteFileFromUpload(i)">
                <div class="fonticon-container">
                  <div class="fonticon-wrap me-0 mb-0">
                    <i class="ft-trash-2"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form class="uploadForm" method="post" enctype="multipart/form-data">
      <div class="modal-footer">
        <button type="any" class="btn secondary-button custom-button" id="btn" (click)="closeModal()"><i class="glyphicon glyphicon-refresh"></i> {{ locale("locale_key.general.buttons.close") }}</button>
        <button (click)="uploadFiles()" type="submit" class="btn btn-add custom-button" [disabled]="isDisabled"><i class="fa fa-user"></i>{{ locale("locale_key.pages.file_explorer.upload_modal.button.upload_files") }}</button>
      </div>
    </form>
  </div>
</ng-template>
