import { UserCredentialWithCustomClaims } from "src/app/services/GoogleIdentityPlatformService"

export class Manager_RegisterFe {
  invitationUuid: string
  correctedFirstName: string
  correctedLastName: string
  existedUserEmail: string | undefined
  existedUserPassword: string | undefined
  newUserPassword: string | undefined
  newUserConfirmedPassword: string | undefined
  googleUserCreds : UserCredentialWithCustomClaims
  
  constructor(
    invitationUuid: string, 
    correctedFirstName: string,
    correctedLastName: string,
    existedUserEmail: string | undefined,
    existedUserPassword: string | undefined,
    newUserPassword: string | undefined,
    newUserConfirmedPassword: string | undefined
    ) {
    this.invitationUuid = invitationUuid
    this.correctedFirstName = correctedFirstName
    this.correctedLastName = correctedLastName
    this.existedUserEmail = existedUserEmail
    this.existedUserPassword = existedUserPassword
    this.newUserPassword = newUserPassword
    this.newUserConfirmedPassword = newUserConfirmedPassword
  }

  public static fromTransfer(transfer: any): Manager_RegisterFe{
    let invitationUuid = transfer['invitationUuid'];
    let correctedFirstName = transfer['correctedFirstName'];
    let correctedLastName = transfer['correctedLastName'];
    let existedUserEmail = transfer['existedUserEmail'];
    let existedUserPassword = transfer['existedUserPassword'];
    let newUserPassword = transfer['newUserPassword'];
    let newUserConfirmedPassword = transfer['newUserConfirmedPassword'];
    let managerRegister = new Manager_RegisterFe(invitationUuid, correctedFirstName, correctedLastName, existedUserEmail, existedUserPassword, 
      newUserPassword, newUserConfirmedPassword)
    return managerRegister
  }

}