import { Component, Inject, Input, Output, OnInit, TemplateRef, ViewChild, EventEmitter } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as UUID from "uuid";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { ValidationMessages } from "src/app/model/form-validation/FormValidationMessages";
import { ContactFe } from "src/app/model/user/ContactFe";
import { Request_AddFe } from "src/app/model/data-suppliers/request/add/Request_AddFe";
import { RequestGroup_AddFe } from "src/app/model/data-suppliers/request/add/RequestGroup_AddFe";
import { DateUtil } from "src/app/utils/DateUtil";
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { RequestFe } from "src/app/model/data-suppliers/request/RequestFe";
import { DOCUMENT } from "@angular/common";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe";
import { QuestionAttachmentFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionAttachmentFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { QuestionSingleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe";
import { QuestionMultipleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe";
import { QuestionDateFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDateFe";
import { QuestionTextFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionTextFe";
import { QuestionNumberFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe";
import { QuestionChoiceOptionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionChoiceOptionFe";
import { DataTableRequestFieldFe } from "src/app/model/data-suppliers/request/questionnaire/DataTableRequestFieldFe";
import { BooleanStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/BooleanStatementFe";
import { TwoOperandStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandStatementFe";
import { VariableStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/VariableStatementFe";
import { TwoOperandOperatorFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandOperatorFe";
import { BooleanValueStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/BooleanValueStatementFe";
import { QuestionnaireFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe";
import { QUESTION_TYPE } from "src/app/model/data-suppliers/request/questionnaire/QuestionTypeFe";
import { QuestionTypeConversion } from "src/app/model/data-suppliers/request/questionnaire/QuestionTypeConversionFe";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import moment from "moment";
import parser from "cron-parser";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { SchedulingOccurenceGeneratorFe, SchedulingOccurenceGeneratorFromTypes } from "src/app/model/scheduling/SchedulingOccurenceGeneratorFe";
import { RecurrenceFe } from "src/app/model/data-suppliers/request/recurrence/RecurrenceFe";
import { IntervalFe } from "src/app/model/data-suppliers/request/recurrence/IntervalFe";
import { RepeatScheduleFe } from "src/app/model/data-suppliers/request/recurrence/RepeatScheduleFe";
import { CustomRepeatScheduleFe } from "src/app/model/data-suppliers/request/recurrence/CustomRepeatScheduleFe";
import { ReminderScheduleFe } from "src/app/model/data-suppliers/request/recurrence/ReminderScheduleFe";
import { NamedOccurenceFe } from "src/app/model/data-suppliers/request/recurrence/NamedOccurenceFe";
import { SectionFe } from "src/app/model/data-suppliers/request/questionnaire/SectionFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { DataCategoryServiceFe } from "src/app/services/DataCategoryServiceFe";
import { RequestServiceFe } from "src/app/services/RequestServiceFe";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { groupUnitsByMeasurementTypeAndSystem } from "../../unit-systems/model/utils";
import { UnitFe } from "../../unit-systems/model/UnitFe";
import { NgSelectComponent } from "@ng-select/ng-select";
import { QuestionEmissionFactorFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionEmissionFactorFe";
import { SubmissionDetailsRequestComponent } from "./submission-details-questionnaire.component";

interface Period {
  label: string
  name: string
  startDate: string
  endDate: string
}

@Component({
  selector: "data-supplier-questionaire",
  templateUrl: "./data-supplier-questionaire.component.html",
  styleUrls: ["./data-supplier-questionaire.component.scss", "../data-suppliers.component.scss"],
  providers: [{ provide: BsDropdownDirective }],
})
export class DataSupplierQuestionaireComponent extends AbstractLanguageComponent implements OnInit {
  @Input() mode: string = "add"
  @Input() reqGroupToBeDuplicated: RequestGroupFe
  @Output() close = new Subject<boolean>()
  @Output() createdRequest = new EventEmitter<RequestGroupFe>()
  @ViewChild("questionnairePreview", { static: true })
  questionnairePreview: TemplateRef<any>;
  @Input() isFromDraft = false;
  @ViewChild("confirmSendRequest") confirmSendRequest: TemplateRef<any>;
  @ViewChild("confirmSaveAsDraft") confirmSaveAsDraft: TemplateRef<any>;
  @ViewChild("confirmDeploy") confirmDeploy: TemplateRef<any>;
  @ViewChild("parentSelect", { static: false }) parentSelect: NgSelectComponent;
  @ViewChild("childrenSelect", { static: false }) childrenSelect: NgSelectComponent;
  @ViewChild(SubmissionDetailsRequestComponent) submissionDetailsRequestComponent!: SubmissionDetailsRequestComponent;
  placeholder: boolean = false;

  form3 = new FormGroup({
    // deadline: new FormControl(null, [Validators.required]),
    dataConnection: new FormControl("autoConnect"),
  })

  reqDesc: string = ""
  allDataOwners: ContactFe[] = []
  filteredDataOwners: ContactFe[] = []
  filterText: string = ""

  activeFormNo = 1
  progress: HTMLElement | undefined
  circles: any
  prevButton: HTMLButtonElement | undefined
  nextButton: HTMLButtonElement | undefined
  currentActive: number = 1
  inProgress = true
  taxonomyInfo: TaxonomyInfoFe = undefined

  dataScopeMessage = this.locale("locale_key.general.validation_message.data_category_required")
  mappingMessage = this.locale("locale_key.general.validation_message.mapping_required")
  dataOwnerMessage = this.locale("locale_key.general.validation_message.dataowner_required")
  requestTitleMessage = this.locale("locale_key.general.validation_message.request_title_required")
  requestDeadlineMessage = this.locale("locale_key.general.validation_message.request_deadline_required")
  questionMessage = this.locale("locale_key.general.validation_message.title_for_question_required")
  choiceTitleMessage = this.locale("locale_key.general.validation_message.title_for_option_required")
  choiceDescMessage = this.locale("locale_key.general.validation_message.description_required")
  choiceLengthMessage = this.locale("locale_key.general.validation_message.two_options_required")
  unitMessage = this.locale("locale_key.general.validation_message.unit_for_number_question_required")
  dataFieldMessage = this.locale("locale_key.general.validation_message.one_data_field_required")
  followUpConditionMessage = this.locale("locale_key.general.validation_message.follow_up_condition_required")
  questionnaireMessage = this.locale("locale_key.general.validation_message.incorrect_questionnaire")
  questionnaireLengthMessage = this.locale("locale_key.general.validation_message.one_question_required")
  isDueDatePassedConfirmed = false
  loadingInProgress = false
  loadingInfo: any
  title: any
  description: any
  todaysDate = moment().utc().startOf("day").toISOString().split("T")[0]

  //NEW
  reqDetailsForm = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    desc: new FormControl(null),
  })

  saveAsDraft = false

  sections: SectionFe[] = []
  selectedDataOwner: ContactFe
  editDOTable = true
  selectedQuestion: any
  selectedChildQuestion: any
  questionHasFollowUp: any
  parentQuestionIndex: number = -1
  children: QuestionFe[] = []
  isQuestionnaireInvalid = false
  requestForPreview: RequestFe
  allRequestGroups: RequestGroupFe[] = []

  selectedValues: string[] = []
  selectedOption: any
  showAdditionalInputs = false
  recurrences: number = 0
  periods: Period[] = [
    { label: "1st request period", name: "", startDate: "", endDate: "" },
    { label: "2nd request period", name: "", startDate: "", endDate: "" },
  ]
  intervals: boolean = false
  intervalsView: boolean = false
  morePeriods: boolean = true
  oneTimeRequest: boolean = true
  recurringRequest: boolean = false
  reminder: boolean = false
  customRepeat: boolean = false

  initCacheInProgress: boolean
  menuCollapsed: boolean
  url: string = ""
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode
  recurringMode = "oneTime"
  pageToolbar = [
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.back"),
        longLabel: this.locale("locale_key.general.toolbar.button.back"),
        tooltip: this.locale("locale_key.general.toolbar.button.back"),
        icon: "la la-arrow-left",
        actionName: "back",
        visible: () => true,
        disabled: () => this.loadingInfo || this.loadingInProgress,
        additionalClasses: "nav-link",
      },
    ],
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.preview"),
        longLabel: this.locale("locale_key.general.toolbar.button.tooltip.preview_questionnaire"),
        tooltip: this.locale("locale_key.general.toolbar.button.tooltip.preview_questionnaire"),
        icon: "la la-eye",
        actionName: "preview_questionnaire",
        visible: () => this.activeFormNo !== 1,
        disabled: () => this.loadingInfo || this.loadingInProgress,
      },
    ],
  ]

  recurrenceType = "ONETIME"
  sendOutDate = new FormControl(moment().format("YYYY-MM-DD"))
  repeatPresets = []
  repeatPresetsValueMap = { CUSTOM: "" }
  selectedRepeatPreset = "WEEKLY"
  customRepeatMonthlyPresets = []
  customRepeatMonthlyPresetsValueMap = {}
  selectedCustomRepeatUnit = "DAILY"
  selectedCustomRepeatValue = 1
  selectedCustomRepeatDayOfWeek = ""
  selectedCustomRepeatMonthType = ""
  selectedRecurrenceEnds = "NEVER"
  nextDeadline = moment().add(1, "month").startOf("day").toISOString().split("T")[0]
  maxOccurrences = 0
  sendOutType = "DATE"
  sendOutIntervalType = "BEFORE_DEADLINE"
  sendOutIntervalUnit = "DAY"
  sendOutIntervalValue = 1
  repeatType = "PRESET"
  occurrences = []
  occurrencesMap = {}
  occurrencesNameCache = {}
  neverOccurrences = []
  neverOccurrencesMap = {}
  onDateOccurrences = []
  onDateOccurrencesMap = {}
  onDateOccurrencesNameCache = {}
  maxDateUI = ""
  maxDate = ""
  reminders: ReminderScheduleFe[] = [new ReminderScheduleFe("BEFORE_DEADLINE", "WEEK", 1, "WEEK_1")]
  sendReminderToCreator: boolean = false
  showDeadlineError: boolean = false
  deadlineErrorMsg = this.locale("locale_key.general.validation_message.deadline_required")
  showRecurrenceTypeError: boolean = false
  recurrenceTypeErrorMsg = this.locale("locale_key.general.validation_message.request_frequency_required")
  sendOutDayErrorMsg = this.locale("locale_key.general.validation_message.past_send_out_dates")
  showSendOutDayError: boolean
  maxDateErrorMsg = this.locale("locale_key.general.validation_message.max_date_required")
  showMaxDateError: boolean = false
  mapped: boolean = false
  mergeSubmission: boolean = false
  selectedEntityKey: EntityFe

  units = []
  customUnits = []
  unitsByMeasurementType = []
  selectedQuestionIndex: number
  selectedSection: SectionFe

  stepsQuest = [
    { title: this.locale("locale_key.pages.data_request.create.title_desc"), completed: false },
    { title: this.locale("locale_key.pages.data_request.create.questions"), completed: false },
    { title: this.locale("locale_key.pages.data_request.create.submission_details"), completed: false },
  ]

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe,
    public categoryService: DataCategoryServiceFe,
    public requestService: RequestServiceFe
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize

    this.updateRepeatPresets()
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits()
    })
  }

  async ngOnInit(): Promise<void> {
    this.inProgress = true
    this.loadUnits()
    this.taxonomyInfo = await this.categoryService.getTaxonomy()
    this.allDataOwners = await this.stateService.getActiveContacts()
    this.allRequestGroups = await this.stateService.getRequestGroups()
    this.filteredDataOwners = this.allDataOwners
    this.filteredDataOwners.forEach((d) => (d.isSelected = false))
    if (this.reqGroupToBeDuplicated) {
      this.populateData()
      this.validateQuestionnaire()
    } else {
      this.reqDetailsForm.reset()
      this.form3.reset()
      this.form3.patchValue({ dataConnection: "autoConnect" })
      this.sections = []
    }
    this.displayService.closeTips()
    this.displayService.closeDetails()
    this.inProgress = false
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits()
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units)
    this.unitsByMeasurementType = unitsByMeasurementType
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard)
  }

  filterRows() {
    let newRegEx = new RegExp(this.filterText, "i")
    this.filteredDataOwners = this.allDataOwners.filter(
      (dataOwner) =>
        dataOwner.userFirstName.match(newRegEx) ||
        dataOwner.userLastName.match(newRegEx) ||
        `${dataOwner.userFirstName} ${dataOwner.userLastName}`.match(newRegEx) ||
        dataOwner.affiliationEmail.match(newRegEx) ||
        (dataOwner.supplierCompanyname ? dataOwner.supplierCompanyname.match(newRegEx) : this.stateService.activeWorkspace.companyName.match(newRegEx))
    )
  }

  next() {
    if (this.activeFormNo == 1) {
      this.reqDetailsForm.markAllAsTouched()
      if (this.reqDetailsForm.invalid) {
        return
      }
    } else {
      this.validateQuestionnaire()
      if (this.isQuestionnaireInvalid) {
        return
      }
    }
    this.currentActive++
    this.activeFormNo++
    this.update()
  }

  prev(): void {
    this.currentActive--
    if (this.currentActive < 1) {
      this.currentActive = 1
    }
    this.activeFormNo--
    this.update()
  }

  validateQuestionnaire() {
    this.isQuestionnaireInvalid = false
    if (this.sections.length == 0) {
      this.isQuestionnaireInvalid = true
      return
    }
    this.sections.forEach((section) => {
      section.questions.forEach((q) => {
        q.titleControl.markAsTouched()
        if (q.titleControl.invalid) {
          this.isQuestionnaireInvalid = true
        }
        if (q instanceof QuestionSingleChoiceFe || q instanceof QuestionMultipleChoiceFe) {
          q.options.forEach((o) => {
            o.valueControl.markAsTouched()
            if (o.valueControl.invalid) {
              this.isQuestionnaireInvalid = true
            }
            if (o.showDesc && o.descControl.invalid) {
              o.descControl.markAsTouched()
              this.isQuestionnaireInvalid = true
            }
          })
          if (q.options.length < 2) {
            this.isQuestionnaireInvalid = true
          }
        }
        if (q instanceof QuestionDataTableFe && q.fields.length == 0) {
          this.isQuestionnaireInvalid = true
        }
        if (q.isFollowUpQues && !q.followUpCondition) {
          this.isQuestionnaireInvalid = true
        }
        if (q instanceof QuestionNumberFe && q.isUnitRequiredFromSM() && !q.unit) {
          this.isQuestionnaireInvalid = true
        }
      })
    })
  }

  update() {
    this.circles = this._document.querySelectorAll(".circle1")

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add("active-circle1")
      } else {
        circle.classList.remove("active-circle1")
      }
    })

    const actives = this._document.querySelectorAll(".active-circle1")
  }

  isForm3Valid(): boolean {
    this.form3.markAllAsTouched()

    if (_.isEmpty(this.recurrenceType)) {
      this.showRecurrenceTypeError = true
      return false
    }

    if (_.isEmpty(this.nextDeadline)) {
      this.showDeadlineError = true
      return false
    }

    if (this.selectedRecurrenceEnds == "ON_DATE" && _.isEmpty(this.maxDateUI)) {
      this.showMaxDateError = true
      return false
    }

    if (this.form3.invalid) {
      return false
    }
    if (this.anyDOSelected() == false) {
      return false
    }
    return true
  }

  openModal(template: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(template, {
      class: size,
      backdrop: "static",
      keyboard: true,
    })
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove("modal-open")
  }

  async sendRequest(deleteDraft: boolean, keepDraft: boolean = false): Promise<void> {
    const rg = this.getRequestGroup()
    const requestGroupFe = await this.processRequestGroup(rg, true, deleteDraft, keepDraft)
    this.createdRequest.emit(requestGroupFe)
  }

  async saveDraft(saveAsNew: boolean): Promise<void> {
    const rg = this.getRequestGroup()
    if (saveAsNew) {
      await this.handleDuplicateTitle(rg)
    }
    await this.processRequestGroup(rg, false, !saveAsNew)
  }

  async processRequestGroup(rg: RequestGroup_AddFe, isSendRequest: boolean, deleteDraft: boolean, keepDraft: boolean = false): Promise<RequestGroupFe> {
    this.loadingInProgress = true;

    const shouldDeleteDraft = deleteDraft && (!isSendRequest || (isSendRequest && this.isFromDraft));
    if (shouldDeleteDraft) {
      await this.stateService.deleteDraftRequestGroup(this.reqGroupToBeDuplicated?.id);
    }

    const requestGroupFe = await this.stateService.addRequestGroup(rg, keepDraft);

    this.loadingInProgress = false;
    this.closeQuestionnaire();
    this.closeModal();

    return requestGroupFe;
  }

  async handleDuplicateTitle(rg: RequestGroup_AddFe): Promise<void> {
    let counter = 0;
    let existingDraftsWithSameTitle = [];
    const title = rg.title;

    do {
      existingDraftsWithSameTitle = this.allRequestGroups.filter((r) => r.draft && r.title === rg.title);
      if (existingDraftsWithSameTitle.length > 0) {
        counter++;
        rg.title = `${title} (${counter})`;
      }
    } while (existingDraftsWithSameTitle.length > 0);
  }

  getRequestGroup(): RequestGroup_AddFe {
    const requests: Request_AddFe[] = [];
    let autoConnect = this.form3.controls.dataConnection.value == "autoConnect" ? true : false;

    let questionnaire = new QuestionnaireFe(this.sections, autoConnect, IdUtil.next());
    questionnaire.sections.forEach((section) => {
      section.questions.forEach((q) => {
        if (q instanceof QuestionDataTableFe) {
          q.setPredefinedOptions();
          q.taxonomyInfo = null;
          q.requestItemDataGridService = null;
          q.newAnswer = null;
        }
      });
    });

    this.allDataOwners.forEach((dataOwner) => {
      if (dataOwner.isSelected) {
        const newReq = new Request_AddFe(UUID.v4(), dataOwner.affiliationCompanyId, dataOwner.affiliationId);
        requests.push(newReq);
      }
    });

    let deadline = moment(this.nextDeadline).utc(true).toDate();

    const requestGroup = new RequestGroup_AddFe(
      IdUtil.next(),
      this.reqDetailsForm.controls.title.value,
      this.reqDetailsForm.controls.desc.value,
      questionnaire,
      requests,
      deadline,
      this.saveAsDraft,
      this.submissionDetailsRequestComponent.genRecurrence(),
      false,
      null,
      this.stateService.activeWorkspace.affiliationId,
      this.loginService.loginUser.firstName,
      this.loginService.loginUser.lastName,
      this.stateService.activeWorkspace.companyId,
      this.stateService.activeWorkspace.companyName,
      this.loginService.loginUser.userId
    );

    return requestGroup;
  }


  anyDOSelected() {
    return this.filteredDataOwners.some((d) => d.isSelected)
  }

  closeQuestionnaire() {
    // No need to keep data category when request is closed
    this.categoryService.resetNewEntitiesAndDatapoints()
    this.close.next(true)
  }

  setDescContent($event) {
    let newValue = $event.target.value
    this.reqDesc = newValue
  }

  isDueDatePassed(): boolean {
    let today = new Date()
    let selectedDate = new Date(this.form3.get("deadline").value)
    let diff = DateUtil.getTimeDifference(selectedDate, today)
    if (diff < 0) {
      return true
    }
    return false
  }

  getTaxonomyChildren(value: string) {
    return this.taxonomyInfo?.childrenSortedByOrdinal(value)
  }

  async populateData() {
    let duplicateRequestGroup = this.reqGroupToBeDuplicated
    this.reqDetailsForm.patchValue({
      title: duplicateRequestGroup.title,
      desc: duplicateRequestGroup.description,
    })

    let questionnaire = duplicateRequestGroup.draft ? duplicateRequestGroup.questionnaire : duplicateRequestGroup.requests[0].questionnaire
    questionnaire.sections.forEach((section) => {
      let newSection = new SectionFe(section.id, section.name, section.taxonomyKey, section.taxonomyVersion, [], this.taxonomyInfo)
      for (let question of section.questions) {
        let newQuestion = QuestionTypeConversion.setQuestion(question)
        if (newQuestion.isFollowUpQues) {
          let formulaString = newQuestion.followUpCondition.toFormulaString()
          let index1 = formulaString.indexOf("$"),
            index2 = formulaString.indexOf(".")
          let parentQuesId = formulaString.substring(index1 + 1, index2)
          formulaString = formulaString.substring(index2)
          newQuestion.parentQuestionId = parentQuesId
          let parentQues = newSection.questions.find((q) => q.id == parentQuesId)
          parentQues.hasFollowUpQuestion = true
          parentQues.childrenNumber += 1
          if (parentQues instanceof QuestionSingleChoiceFe) {
            let optionStartIndex = formulaString.indexOf("."),
              optionEndIndex = formulaString.indexOf(" ", optionStartIndex)
            let optionId = formulaString.substring(optionStartIndex + 1, optionEndIndex)
            let option = parentQues.options.find((o) => o.id == optionId)
            newQuestion.followUpOptions = option
          } else if (parentQues instanceof QuestionMultipleChoiceFe) {
            let optionStartIndex = formulaString.indexOf(".")
            while (optionStartIndex != -1) {
              optionStartIndex = formulaString.indexOf(".")
              let optionEndIndex = formulaString.indexOf(" ", optionStartIndex)
              let optionId = formulaString.substring(optionStartIndex + 1, optionEndIndex)
              formulaString = formulaString.substring(optionEndIndex + 1)
              let option = parentQues.options.find((o) => o.id == optionId)
              if (option) {
                if (newQuestion.followUpOptions instanceof Array) {
                  newQuestion.followUpOptions.push(option)
                } else {
                  newQuestion.followUpOptions = [option]
                }
              }
            }
          }
        }
        if (newQuestion instanceof QuestionDataTableFe && question instanceof QuestionDataTableFe) {
          newQuestion.taxonomyInfo = this.taxonomyInfo
          newQuestion.setQuestionFromOld(question, section)
        }
        if (newQuestion.mappedToColKeys && newSection.entity) {
          newQuestion.setMappedToCols(newSection.entity)
        }
        if (newQuestion instanceof QuestionDateFe && newQuestion.mappedToColKeysRange) {
          newQuestion.setMappedToColsRange(newSection.entity)
        }
        newQuestion.confirmMapping()
        newSection.questions.push(newQuestion)
      }
      newSection.editTaxonomy = false
      this.sections.push(newSection)
    })

    let requests = duplicateRequestGroup.draft ? duplicateRequestGroup.draftRequests : duplicateRequestGroup.requests
    for (let request of requests) {
      let dataOwner = this.allDataOwners.find((d) => d.affiliationId == request.assigneeAffId)
      dataOwner.isSelected = true
    }

    this.form3.patchValue({
      dataConnection: questionnaire.autoConnect ? "autoConnect" : "manualConnect",
    })

    // copy deadline
    this.nextDeadline = moment(duplicateRequestGroup.dueDate).toISOString().split("T")[0]

    // copy reminders
    this.reminder = duplicateRequestGroup.recurrence.sendScheduledReminders
    this.sendReminderToCreator = duplicateRequestGroup.recurrence.sendReminderToCreator
    this.reminders = duplicateRequestGroup.recurrence.reminders
  }

  compareFn(item, selected) {
    return item.value === selected.value
  }

  showMenu(id: string, isParentSelect: boolean) {
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    menu.classList.toggle("show")
    if (menuBtn.id === "btn-arrange") {
      menu.classList.toggle("hide")
    }

    let selectElement
    if (isParentSelect) {
      selectElement = this.parentSelect
    } else {
      selectElement = this.childrenSelect
    }

    if (selectElement) {
      selectElement.open()
      selectElement.close = () => {}
    }
  }

  deleteDescription(option: QuestionChoiceOptionFe) {
    option.showDesc = false
    option.desc = null
  }

  deleteOption(options: QuestionChoiceOptionFe[], index: number) {
    options.splice(index, 1)
  }

  addOption(options: QuestionChoiceOptionFe[]) {
    let option = new QuestionChoiceOptionFe(IdUtil.next(), null, null)
    options.push(option)
  }

  changeDateRange(question: QuestionDateFe, isRange: boolean) {
    question.range = isRange
  }

  //NEW CODE
  isParentMultipleChoiceQues(quesId: string, section: SectionFe) {
    let ques = section.questions.find((q) => q.id == quesId)
    return this.requestService.isMultipleChoiceQues(ques)
  }

  addNewQuestion(section: SectionFe, type: string, index: number = null, isFollowUpQues = false, parentQues: QuestionFe = null, isRangeQuestion?: boolean) {
    if (index == null || index == undefined) {
      index = section.questions.length
    }
    let question: QuestionFe = this.getNewQuestion(type, section, isRangeQuestion)
    question.isFollowUpQues = isFollowUpQues
    question.isEditMode = true
    if (isFollowUpQues) {
      question.followUpCondition = new BooleanStatementFe()
      question.parentQuestionId = parentQues.id
      if (parentQues != null) {
        let parentQuestion = section.questions.find((q) => q.id == parentQues.id)
        parentQuestion.hasFollowUpQuestion = true
        parentQuestion.childrenNumber += 1
        parentQues.isEditMode = false
        const totalChildren = this.getTotalChildren(parentQues, section)
        index += totalChildren + 1
      } else {
        index += 1
      }
    }
    section.questions.splice(index, 0, question)
    let menu = document.getElementById("addNewQuesMenu") as HTMLElement
    let menuFollow = document.getElementById("addNewQuesMenu-follow") as HTMLElement
    menu?.classList.remove("show")
    menuFollow?.classList.remove("show")
    setTimeout(() => {
      document.getElementById(`question${question.id}`)?.scrollIntoView({ block: "end" })
      this.enableEditMode(question, section)
    })
  }

  getTotalChildren(parentQuest: QuestionFe, section: SectionFe) {
    let total = 0
    let childQuestions = section.questions.filter((q) => q.parentQuestionId == parentQuest.id)
    total += childQuestions.length
    childQuestions.forEach((ques) => {
      total += this.getTotalChildren(ques, section)
    })
    return total
  }

  getNewQuestion(type: string, section: SectionFe, isRangeQuestion?: boolean): QuestionFe {
    let question: QuestionFe
    switch (type) {
      case QUESTION_TYPE.ATTACHMENT:
        question = new QuestionAttachmentFe(IdUtil.next(), section.id, null, null, null, null, null, null)
        break
      case QUESTION_TYPE.DATA_TABLE:
        question = new QuestionDataTableFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], [], null, null)
        ;(question as QuestionDataTableFe).taxonomyInfo = this.taxonomyInfo
        break
      case QUESTION_TYPE.SINGLE_CHOICE:
        question = new QuestionSingleChoiceFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], null)
        ;(question as QuestionSingleChoiceFe).adjustNewQuestion()
        break
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        question = new QuestionMultipleChoiceFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], null)
        ;(question as QuestionMultipleChoiceFe).adjustNewQuestion()
        break
      case QUESTION_TYPE.DATE:
        question = new QuestionDateFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], isRangeQuestion, isRangeQuestion ? [] : null)
        break
      case QUESTION_TYPE.TEXT:
        question = new QuestionTextFe(IdUtil.next(), section.id, null, null, null, null, null, null, [])
        break
      case QUESTION_TYPE.NUMBER:
        question = new QuestionNumberFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], null, null, true)
        break
      case QUESTION_TYPE.EMISSION_FACTOR:
        question = new QuestionEmissionFactorFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], null, [])
        break
    }
    question.isMapped = section.isTaxonomyConnected
    return question
  }

  openDeleteQuesModal(index: number, section: SectionFe, template: TemplateRef<any>) {
    this.selectedQuestionIndex = index
    this.selectedSection = section
    let ques = section.questions[index]
    if (ques.hasFollowUpQuestion) {
      this.openModal(template, "modal-md")
    } else {
      this.deleteQues(index, section)
    }
  }

  deleteQues(index: number, section: SectionFe) {
    let ques = section.questions[index]
    while (ques.hasFollowUpQuestion) {
      section.questions.filter((q, index) => {
        if (q.parentQuestionId == ques.id) {
          this.deleteQues(index, section)
        }
      })

      if (index == this.selectedQuestionIndex) {
        this.closeModal()
      }
    }
    if (ques.isFollowUpQues) {
      let parentQues = section.questions.find((q) => q.id == ques.parentQuestionId)
      parentQues.childrenNumber -= 1
      if (parentQues.childrenNumber == 0) {
        parentQues.hasFollowUpQuestion = false
      }
    }
    section.questions.splice(index, 1)
  }

  getParentQuesOptions(quesId: string, section: SectionFe) {
    let question = section.questions.find((q) => q.id == quesId)
    if (question instanceof QuestionSingleChoiceFe || question instanceof QuestionMultipleChoiceFe) {
      return question.options
    }
  }

  handleCondition(options: QuestionChoiceOptionFe | QuestionChoiceOptionFe[] | Event, question: QuestionFe) {
    if (options instanceof Event) return
    let ls = new BooleanValueStatementFe(true)
    let o = TwoOperandOperatorFe.AND
    let condition = new TwoOperandStatementFe(ls, o, null)
    if (options instanceof QuestionChoiceOptionFe) {
      let reference = `${question.parentQuestionId}.${options.id}`
      condition.rightStatement = new VariableStatementFe(reference)
    } else if (Array.isArray(options)) {
      let lastStatement: TwoOperandStatementFe = condition
      options.forEach((option, i) => {
        let reference = `${question.parentQuestionId}.${option.id}`
        if (lastStatement.rightStatement instanceof VariableStatementFe) {
          let leftStatement = new VariableStatementFe(lastStatement.rightStatement.reference)
          let operator = TwoOperandOperatorFe.AND
          let rightStatement = new VariableStatementFe(reference)
          let statement = new TwoOperandStatementFe(leftStatement, operator, rightStatement)
          lastStatement.rightStatement = statement
          lastStatement = statement
        } else {
          lastStatement.rightStatement = new VariableStatementFe(reference)
        }
      })
    }
    question.followUpCondition = condition
  }

  hasCondition(question) {
    if (question.followUpCondition instanceof BooleanStatementFe) {
      return !!question.followUpCondition.rightStatement
    }
  }

  enableEditMode(question, section: SectionFe) {
    this.sections.forEach((section) => section.questions.forEach((q) => (q.isEditMode = false)))
    question.isEditMode = true
    if (question.isFollowUpQues) {
      let parentQues = section.questions.find((q) => q.id == question.parentQuestionId)
      this.parentQuestionIndex = section.questions.findIndex((q) => q.id == question.parentQuestionId)
      this.children = section.questions.slice(this.parentQuestionIndex + 1, this.parentQuestionIndex + 1 + parentQues.childrenNumber)
    }
  }

  reorderParentQuestions(index: number, section: SectionFe) {
    let questionId = this.selectedQuestion
    let menuBtn = document.getElementById("btn-arrange") as HTMLElement
    let dropdown = menuBtn.nextElementSibling as HTMLElement
    let selectedQuestionIndex = section.questions.findIndex((q) => questionId && q.id == questionId)
    let question = section.questions[index]

    if (index !== selectedQuestionIndex && index !== null && index >= 0) {
      //case 1: If question is a parent but not a child, it moves its children as well move question with children
      if (question.hasFollowUpQuestion && !question.isFollowUpQues) {
        this.questionHasFollowUp = section.questions.splice(index, question.childrenNumber + 1)
        section.questions.splice(selectedQuestionIndex, 0, ...this.questionHasFollowUp)
        //case 3: If it's single question, it moves only the question
      } else {
        section.questions.splice(index, 1)
        section.questions.splice(selectedQuestionIndex, 0, question)
      }
    }
    dropdown.classList.toggle("hide")
    dropdown.classList.toggle("show")
  }

  reorderChildQuestions(section: SectionFe, index: number) {
    let menuBtn = document.getElementById("btn-arrange") as HTMLElement
    let dropdown = menuBtn.nextElementSibling as HTMLElement
    let questionId = this.selectedChildQuestion
    let selectedQuestionIndex = section.questions.findIndex((q) => q.id == questionId)
    let question = section.questions[index]
    let parentQues = section.questions.find((q) => q.id == section.questions[index].parentQuestionId)
    this.parentQuestionIndex = section.questions.findIndex((q) => q.id == section.questions[index].parentQuestionId)
    if (index !== selectedQuestionIndex && index !== null && index >= 0) {
      //case 3: If it's single question, it moves only the question
      if (question.isFollowUpQues) {
        let questionToMove = section.questions.splice(index, 1)
        section.questions.splice(selectedQuestionIndex, 0, ...questionToMove)
      }
    }
    dropdown.classList.toggle("hide")
    dropdown.classList.toggle("show")
  }

  duplicateQues(question: QuestionFe, i: number, section) {
    let newQuestion = QuestionTypeConversion.fromTransfer(question, section.id)
    if (newQuestion instanceof QuestionDataTableFe && question instanceof QuestionDataTableFe) {
      newQuestion.taxonomyInfo = this.taxonomyInfo
      newQuestion.setQuestionFromOld(question, section)
    }
    if (section.entity) {
      newQuestion.mappedToCols = []
    }

    newQuestion.id = IdUtil.next()

    if (newQuestion instanceof QuestionSingleChoiceFe || newQuestion instanceof QuestionMultipleChoiceFe) {
      newQuestion.options.forEach((o) => (o.id = IdUtil.next()))
    }

    section.questions.splice(i, 0, newQuestion)
  }

  isColumnSelected(column: TaxonomyAttributeFe, question: QuestionDataTableFe) {
    let isSelected = question.fields.some((f) => f.key == column.key)
    return isSelected
  }

  readMore(event: any) {
    let id = event.currentTarget.id
    let idIndex = event.currentTarget.id.split("-")[1]
    let readLess = document.getElementById("readLess-" + idIndex) as HTMLElement
    let readMore = document.getElementById("readMore-" + idIndex) as HTMLElement
    let moreBtn = document.getElementById("morebtn-" + idIndex) as HTMLButtonElement
    let icon = document.getElementById("btnIcon-" + idIndex)

    if (readMore.classList.contains("hide")) {
      icon.classList.remove("la-ellipsis-h")
      icon.classList.add("la-sort-asc")
      readMore.classList.remove("hide")
      readLess.classList.add("hide")
    } else {
      readMore.classList.add("hide")
      readLess.classList.remove("hide")
      icon.classList.remove("la-sort-asc")
      icon.classList.add("la-ellipsis-h")
    }
  }

  openPreview(template: TemplateRef<any>) {
    let questionnaire = new QuestionnaireFe(this.sections, false, IdUtil.next())
    this.requestForPreview = new RequestFe(IdUtil.next(), null, null, null, null, null, null, null, null, questionnaire)
    this.openModal(template, "request-modal modal-xl")
  }

  autoGrowTextZone(e) {
    e.target.style.height = "0px"
    e.target.style.height = (e.target.scrollHeight < 575 ? e.target.scrollHeight + 25 : 600) + "px"
    e.target.maxHeight = "600px"
  }

  selectAllDataOwners(event: Event) {
    if ((event.target as HTMLInputElement).checked) this.filteredDataOwners.forEach((d) => (d.isSelected = true))
    else this.filteredDataOwners.forEach((d) => (d.isSelected = false))
  }

  // selectAllColumnEntities(question: QuestionDataTableFe, event: Event) {
  //   question.fields.forEach(f => (question.mappingForm.get("mappings") as FormGroup).removeControl(f.key));
  //   question.customFields.forEach(c => c.isSelected = false);
  //   question.fields = [];
  //   if (event.currentTarget instanceof HTMLInputElement && event.currentTarget.checked) {
  //     let columns = this.taxonomyInfo.entityByKey(question.taxonomyForm.controls.item2.value).columns;
  //     columns.forEach(col => {
  //       let field = new DataTableRequestFieldFe(col.key, col.label, col.description, col.datatype, true, [], false);
  //       (question.mappingForm.get("mappings") as FormGroup).addControl(field.key, new FormControl(field.key, Validators.required));
  //       question.fields.push(field)
  //     })

  //     question.customFields.forEach(col => {
  //       col.isCustomField = true
  //       question.adjustDataFieldRequestKey(col);
  //       (question.mappingForm.get("mappings") as FormGroup).addControl(col.key, new FormControl("doNotConnect", Validators.required));
  //       col.isSelected = true;
  //       question.fields.push(col)
  //     })
  //   }
  //   question.setRequestDataGridService();
  // }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "back":
        this.activeFormNo == 1 ? this.closeQuestionnaire() : this.prev()
        break
      case "preview_questionnaire":
        this.openPreview(this.questionnairePreview)
        break
    }
  }

  addReminder(type) {
    const reminder = new ReminderScheduleFe(type, "WEEK", 1, "WEEK_1")
    this.reminders.push(reminder)
  }

  removeReminder(index: number) {
    // Remove the reminder from the reminders array
    this.reminders.splice(index, 1)
  }

  onSelectChange(event: Event, index: number): void {
    const selectElement = event.target as HTMLSelectElement
    this.selectedValues[index] = selectElement.value
  }

  onDateChange() {
    this.showAdditionalInputs = true
  }

  getArray(n: number): number[] {
    this.showAdditionalInputs = false
    return Array(n)
      .fill(0)
      .map((_, i) => i)
  }

  addPeriod() {
    this.showAdditionalInputs = false
    const label = `${this.periods.length + 1}th request period`
    const newPeriod: Period = { label, name: "", startDate: "", endDate: "" }
    this.periods.push(newPeriod)
  }

  showIntervals() {
    this.morePeriods == false ? (this.morePeriods = true) : (this.morePeriods = false)
  }

  setCustomRepeatDayOfWeek(dayOfWeek) {
    this.selectedCustomRepeatDayOfWeek = dayOfWeek
    this.repeatValueChanged()
  }

  isSelectedCustomRepeatDayOfWeek(dayOfWeek) {
    return this.selectedCustomRepeatDayOfWeek == dayOfWeek
  }
  getRepeatPresets(nextDeadline = moment().toISOString()) {
    const momentObj = moment(nextDeadline)

    const isoWeekDays = {
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday",
      "7": "Sunday",
    }
    const weekly_day = momentObj.isoWeekday()
    const weekly = this.locale(`locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.weekly_on`, { weekly_day: isoWeekDays[weekly_day] })
    const weekly_cron = `5 0 * * ${weekly_day}`

    const monthly_date = momentObj.date()
    const monthly = this.locale(`locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.monthly_on`, { monthly_date: monthly_date })
    const monthly_cron = `5 0 ${monthly_date} * *`

    const quaterly_month_pos = {
      January: { quarter: 1, monthPos: 1 },
      February: { quarter: 1, monthPos: 2 },
      March: { quarter: 1, monthPos: 3 },
      April: { quarter: 2, monthPos: 1 },
      May: { quarter: 2, monthPos: 2 },
      June: { quarter: 2, monthPos: 3 },
      July: { quarter: 3, monthPos: 1 },
      August: { quarter: 3, monthPos: 2 },
      September: { quarter: 3, monthPos: 3 },
      October: { quarter: 4, monthPos: 1 },
      November: { quarter: 4, monthPos: 2 },
      December: { quarter: 4, monthPos: 3 },
    }
    const months_in_a_year = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const month_pos_sufx = { "1": "st", "2": "nd", "3": "rd" }

    // Maps which months are the 1st, 2nd or 3rd in a quater
    const month_pos_to_cron = {
      "1": "1,4,7,10",
      "2": "2,5,8,11",
      "3": "3,6,9,12",
    }

    const yearly_month = momentObj.month()
    // cron months start from 1, while moment months start from 0

    const yearly_cron_month = yearly_month + 1
    const monthPos = quaterly_month_pos[months_in_a_year[yearly_month]].monthPos
    const quarterly_month = `${monthPos}${month_pos_sufx[monthPos]}`

    const quarterly = this.locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.quarterly_on", { monthly_date: monthly_date, quarterly_month: quarterly_month })
    const quarterly_cron = `5 0 ${monthly_date} ${month_pos_to_cron[monthPos]} *`

    const yearly = this.locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.yearly_on", {
      monthly_date: monthly_date,
      yearly_month: months_in_a_year[yearly_month],
    })
    const yearly_cron = `5 0 ${monthly_date} ${yearly_cron_month} *`

    return [
      {
        human: weekly,
        cron: weekly_cron,
        value: "WEEKLY",
      },
      {
        human: monthly,
        cron: monthly_cron,
        value: "MONTHLY",
      },
      {
        human: quarterly,
        cron: quarterly_cron,
        value: "QUATERLY",
      },
      {
        human: yearly,
        cron: yearly_cron,
        value: "YEARLY",
      },
    ]
  }

  getCustomRepeatMonthlyPresets(nextDeadline = moment().toISOString()) {
    const get_date_of_month = (isodate) => {
      const date = moment(isodate).date()
      const human = this.locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.on_date_of_month", { date })
      const cron = `5 0 ${date} * *`
      return {
        human,
        cron,
      }
    }

    const get_day_occurence_in_month = (isodate) => {
      const days_in_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      const day = moment(isodate).day()
      const day_str = days_in_week[day]

      const start_month = moment(isodate)
      start_month.set("date", 1)
      const startIsoDate = start_month.toISOString()

      const interval = parser.parseExpression(`0 3 * * ${day}`, {
        currentDate: startIsoDate,
      })
      const occurences = {}
      const occurencesArray = []
      if (start_month.day() == day) {
        occurencesArray.push(startIsoDate.split("T")[0])
      }
      for (let i = 0; i < 5; i++) {
        const date = interval.next().toISOString()
        const monthStr = date.split("T")[0]
        occurencesArray.push(monthStr)
      }
      occurencesArray.forEach((occurence, idx) => {
        occurences[occurence] = idx + 1
      })

      const month_str = isodate.split("T")[0]
      const day_occurence_pfix = occurences[month_str]
      const day_occurence_sfix = { "1": "st", "2": "nd", "3": "rd", "4": "th" }
      const day_occurence = `${day_occurence_pfix}${day_occurence_sfix[day_occurence_pfix] || "th"}`

      const human = this.locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.on_day_of_month", { day_occurence, day_str })

      const cron = `5 0 0 ? * ${day}#${day_occurence_pfix}`

      return {
        human,
        cron,
      }
    }

    const get_last_day_occurence_in_month = (isodate) => {
      const days_in_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      const day = moment(isodate).day()
      const day_str = days_in_week[day]

      const human = this.locale("locale_key.pages.data_request.create_wizard.deadline_frequency.frequencey_type.last_day_of_month", { day_str })
      const cron = `5 0 0 * * ${day}L`
      return {
        human,
        cron,
      }
    }

    const isodate = moment(nextDeadline).toISOString()
    const dom = get_date_of_month(isodate)
    const doim = get_day_occurence_in_month(isodate)
    const ldoim = get_last_day_occurence_in_month(isodate)

    return [
      {
        ...dom,
        value: "DATE_OF_MONTH",
      },
      {
        ...doim,
        value: "DAY_OCCURENCE_IN_MONTH",
      },
      {
        ...ldoim,
        value: "LAST_DAY_OCCURENCE_IN_MONTH",
      },
    ]
  }

  sendOutDateChanged() {
    let sendOutDate = new Date(this.sendOutDate.value)
    let deadline = new Date(this.nextDeadline)

    if (DateUtil.getTimeDifference(deadline, sendOutDate) < 0) {
      this.nextDeadline = null
    }
  }

  nextDeadlineChanged() {
    this.showDeadlineError = false
    if (_.isEmpty(this.nextDeadline)) {
      this.showDeadlineError = true
    }
    this.maxDateUI = ""
    this.maxDate = ""
    this.updateRepeatPresets()
    this.clearOccurences()
    this.validateSendOut()
  }

  sendOutChanged() {
    this.updateRepeatPresets()
    this.clearOccurences()
    this.validateSendOut()
  }

  validateSendOut() {
    const occurences = this.getNextOccurrences({
      numOccurrences: this.maxOccurrences,
    })

    let firstOccurenceDate: any = Object.keys(occurences)[0]
    firstOccurenceDate = new Date(firstOccurenceDate)
    let todaysDate: any = new Date().toDateString()
    todaysDate = new Date(todaysDate)

    if (DateUtil.getTimeDifference(firstOccurenceDate, todaysDate) < 0) {
      this.showSendOutDayError = true
    } else {
      this.showSendOutDayError = false
    }
  }

  recurringTypeChanged(type) {
    this.showRecurrenceTypeError = false
    switch (type) {
      case "onetime":
        this.oneTimeRequest = true
        this.recurringRequest = false
        this.sendOutType = "DATE"
        this.recurrenceType = "ONETIME"
        break
      case "recurring":
        this.oneTimeRequest = false
        this.recurringRequest = true
        this.sendOutType = "INTERVAL"
        this.recurrenceType = "FIXED"
        break
    }
  }

  reRenderNamedOccurrences() {
    if (this.maxDateUI) {
      this.maxDateChanged()
    }
    if (this.maxOccurrences) {
      this.maxOccurrencesChanged()
    }
  }

  repeatTypeChanged() {
    this.repeatType = "PRESET"
    if (this.selectedRepeatPreset == "CUSTOM") {
      this.repeatType = "CUSTOM"
    }
    this.clearMaxDate()
    this.clearOccurences()
    this.reRenderNamedOccurrences()
  }

  repeatValueChanged() {
    this.clearMaxDate()
    this.clearOccurences()
    this.reRenderNamedOccurrences()
  }

  clearMaxDate() {
    this.maxDateUI = ""
    this.maxDate = ""
  }

  updateRepeatPresets() {
    //timezone issues
    const moment_str = moment(this.nextDeadline).utc(true).toISOString()
    this.repeatPresets = this.getRepeatPresets(moment_str)
    this.repeatPresets.forEach((preset) => {
      this.repeatPresetsValueMap[`${preset.value}`] = preset
    })
    this.customRepeatMonthlyPresets = this.getCustomRepeatMonthlyPresets(moment_str)
    this.customRepeatMonthlyPresets.forEach((preset) => {
      this.customRepeatMonthlyPresetsValueMap[`${preset.value}`] = preset
    })
  }

  formatUIOccurrence(occurence) {
    const date_format = "DD MMM, YYYY"
    occurence.SEND_OUT_DATE_UI = moment(occurence.SEND_OUT_DATE).format(date_format)
    occurence.NEXT_DEADLINE_UI = moment(occurence.NEXT_DEADLINE).format(date_format)
    return occurence
  }

  private genRecurrence(): RecurrenceFe {
    let namedOccurences = {}
    let namedOccurenceFe: NamedOccurenceFe[] = []
    if (this.selectedRecurrenceEnds == "NEVER") {
      namedOccurences = this.neverOccurrencesMap
    }
    if (this.selectedRecurrenceEnds == "ON_DATE") {
      namedOccurences = this.onDateOccurrencesMap
    }
    if (this.selectedRecurrenceEnds == "AFTER_MAX_OCCURENCES") {
      namedOccurences = this.occurrencesMap
    }

    Object.keys(namedOccurences).forEach((key) => {
      const occurence_raw = namedOccurences[key]
      const occurence = new NamedOccurenceFe(occurence_raw.SEND_OUT_DATE, occurence_raw.NEXT_DEADLINE, occurence_raw.NAME)
      namedOccurenceFe.push(occurence)
    })

    const nextDeadline = moment(this.nextDeadline).utc(true).startOf("day").toISOString()
    let sendOutDate = moment(this.sendOutDate.value).utc(true).startOf("day").toISOString()
    if (this.sendOutType == "INTERVAL") {
      const unitToMomentMap: any = {
        DAY: "d",
        WEEK: "w",
        MONTH: "M",
        YEAR: "y",
        weekly: "w",
        monthly: "M",
        quaterly: "Q",
        yearly: "y",
        daily: "d",
      }
      const sendOutIntervalUnit = this.sendOutIntervalUnit
      const sendOutIntervalValue = this.sendOutIntervalValue
      const sendOutMomentDuration = unitToMomentMap[sendOutIntervalUnit]
      sendOutDate = moment(nextDeadline).subtract(sendOutIntervalValue, sendOutMomentDuration).utc(true).startOf("day").toISOString()
    }

    const deduplicatedRemindersMap: { [key: string]: ReminderScheduleFe } = {}
    const deduplicatedReminders: ReminderScheduleFe[] = []
    this.reminders.forEach((reminder) => {
      const key = `${reminder.type}_${reminder.unit}_${reminder.value}_${reminder.preset}`
      deduplicatedRemindersMap[key] = reminder
    })
    Object.keys(deduplicatedRemindersMap).forEach((key) => {
      deduplicatedReminders.push(deduplicatedRemindersMap[key])
    })

    const recurrence = new RecurrenceFe(
      this.recurrenceType,
      this.sendOutType,
      sendOutDate,
      new IntervalFe(this.sendOutIntervalType, this.sendOutIntervalUnit, this.sendOutIntervalValue),
      nextDeadline,
      namedOccurenceFe,
      new RepeatScheduleFe(
        nextDeadline,
        this.repeatType,
        this.selectedRepeatPreset,
        "",
        new CustomRepeatScheduleFe(this.selectedCustomRepeatUnit, this.selectedCustomRepeatDayOfWeek, this.selectedCustomRepeatMonthType, this.selectedCustomRepeatValue, "")
      ),
      this.selectedRecurrenceEnds,
      this.maxDate,
      this.maxOccurrences,
      deduplicatedReminders,
      this.sendReminderToCreator,
      this.reminder,
      false
    )
    return recurrence
  }

  maxOccurrencesChanged() {
    if (this.maxOccurrences < 1) {
      this.maxOccurrences = 1
    }
    const occurences = this.getNextOccurrences({
      numOccurrences: this.maxOccurrences,
    })
    const consolidated = {}
    Object.keys(occurences).forEach((SEND_OUT_DATE) => {
      consolidated[SEND_OUT_DATE] = this.occurrencesMap[SEND_OUT_DATE] || occurences[SEND_OUT_DATE]
    })
    this.occurrencesMap = consolidated
    this.occurrences = Object.keys(consolidated)
      .sort()
      .map((date, idx) => {
        const occurence = consolidated[date]

        const occurenceNo = idx + 1
        const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" }
        const occurenceNoSuffix = occurenceNoSuffixes[occurenceNo] || "th"
        const defaultName = `${occurenceNo}${occurenceNoSuffix} requesting period`
        occurence.NAME = defaultName
        this.occurrencesMap[date].NAME = defaultName

        const cachedName = this.occurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`]
        if (!_.isEmpty(cachedName)) {
          occurence.NAME = cachedName
          this.occurrencesMap[date].NAME = cachedName
        }

        return this.formatUIOccurrence(occurence)
      })
  }

  generateCustomRepeatCron({ unit, value }) {
    let cron = ""
    switch (unit) {
      case "DAILY":
        cron = `5 0 */${value} * *`
        break
      case "WEEKLY":
        break
      case "MONTHLY":
        break
      case "YEARLY":
        break
    }
    return cron
  }

  getNextOccurrences({ numOccurrences, nextDeadline }: { numOccurrences: number; nextDeadline?: string }) {
    const recurrence = this.submissionDetailsRequestComponent.genRecurrence();

    if (nextDeadline) {
      recurrence.nextDeadline = nextDeadline;
    }

    const occurenceGenerator = new SchedulingOccurenceGeneratorFe();

    const occurences = {};
    occurenceGenerator
      .generateOccurences({
        fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
        recurrence,
        numOccurrences,
      })
      .forEach((occurence) => {
        const sendOutDate = occurence.sendOutDay;
        occurences[sendOutDate] = {
          SEND_OUT_DATE: sendOutDate,
          NEXT_DEADLINE: occurence.nextDeadline,
          NAME: "",
        };
      });

    return occurences;
  }


  occurenceUpdated(occurence) {
    this.occurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`] = occurence.NAME
    this.occurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME
  }

  neverOccurrenceUpdated(occurence) {
    this.neverOccurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME
  }

  addNeverOccurence() {
    let nextDeadline = ""
    let SEND_OUT_DATE = ""

    if (this.neverOccurrences.length == 0) {
      //timezone issues
      nextDeadline = moment(this.nextDeadline).utc(true).toISOString()
    } else {
      nextDeadline = this.neverOccurrences[this.neverOccurrences.length - 1].NEXT_DEADLINE
    }

    const occurences = this.getNextOccurrences({
      numOccurrences: 2,
      nextDeadline,
    })

    if (this.neverOccurrences.length == 0) {
      SEND_OUT_DATE = Object.keys(occurences)[0]
    } else {
      SEND_OUT_DATE = Object.keys(occurences).pop()
    }

    const occurence = occurences[SEND_OUT_DATE]

    const occurence_no = this.neverOccurrences.length + 1
    const occurence_no_suffixes = { "1": "st", "2": "nd", "3": "rd" }
    const occurence_no_suffix = occurence_no_suffixes[occurence_no] || "th"
    const default_occurence_name = `${occurence_no}${occurence_no_suffix} requesting period`
    occurence.NAME = default_occurence_name

    this.neverOccurrencesMap[SEND_OUT_DATE] = this.formatUIOccurrence(occurence)
    this.neverOccurrences.push(occurence)
  }

  removeLastNeverOccurrence() {
    this.neverOccurrences.pop()
  }

  maxDateChanged() {
    //System crashes if we let empty maxDateUI past this point
    if (_.isEmpty(this.maxDateUI)) {
      this.clearOccurences()
      this.showMaxDateError = true
      return
    }

    this.showMaxDateError = false
    //timezone issues
    this.maxDate = moment(this.maxDateUI).utc(true).toISOString()

    const occurenceGenerator = new SchedulingOccurenceGeneratorFe();
    const recurrence = this.submissionDetailsRequestComponent.genRecurrence();
    const recurrenceBuffer = { ...recurrence };
    const rawOccurrences = [];

    let occurenceBuffer = occurenceGenerator.generateOccurences({
      recurrence: recurrenceBuffer,
      fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
      numOccurrences: 2,
    })
    let shouldContinue = true
    do {
      rawOccurrences.push(occurenceBuffer[0])
      if (moment(occurenceBuffer[1].nextDeadline).isAfter(recurrence.maxDate)) {
        shouldContinue = false
      } else {
        recurrenceBuffer.nextDeadline = occurenceBuffer[1].nextDeadline
        occurenceBuffer = occurenceGenerator.generateOccurences({
          recurrence: recurrenceBuffer,
          fromType: SchedulingOccurenceGeneratorFromTypes.RECURRENCE,
          numOccurrences: 2,
        })
      }
    } while (shouldContinue)
    const occurrences = {}
    rawOccurrences.forEach((occurrence) => {
      const sendOutDate = occurrence.sendOutDay
      occurrences[sendOutDate] = {
        SEND_OUT_DATE: sendOutDate,
        NEXT_DEADLINE: occurrence.nextDeadline,
        NAME: "",
      }
    })

    const consolidated = {}
    Object.keys(occurrences).forEach((SEND_OUT_DATE) => {
      consolidated[SEND_OUT_DATE] = this.onDateOccurrencesMap[SEND_OUT_DATE] || occurrences[SEND_OUT_DATE]
    })
    this.onDateOccurrencesMap = consolidated
    this.onDateOccurrences = Object.keys(consolidated)
      .sort()
      .map((date, idx) => {
        const occurence = consolidated[date]
        const occurenceNo = idx + 1
        const occurenceNoSuffixes = { "1": "st", "2": "nd", "3": "rd" }
        const occurenceNoSuffix = occurenceNoSuffixes[occurenceNo] || "th"
        const defaultName = `${occurenceNo}${occurenceNoSuffix} requesting period`
        occurence.NAME = defaultName
        this.onDateOccurrencesMap[date].NAME = defaultName

        const cachedName = this.onDateOccurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`]
        if (!_.isEmpty(cachedName)) {
          occurence.NAME = cachedName
          this.onDateOccurrencesMap[date].NAME = cachedName
        }

        return this.formatUIOccurrence(occurence)
      })
  }

  onDateOccurrenceUpdated(occurence) {
    this.onDateOccurrencesNameCache[`${occurence.SEND_OUT_DATE}_${occurence.NEXT_DEADLINE}`] = occurence.NAME
    this.onDateOccurrencesMap[occurence.SEND_OUT_DATE].NAME = occurence.NAME
  }

  clearOccurences() {
    this.occurrences = []
    this.occurrencesMap = {}
    this.occurrencesNameCache = {}
    this.neverOccurrences = []
    this.neverOccurrencesMap = {}
    this.onDateOccurrences = []
    this.onDateOccurrencesMap = {}
    this.onDateOccurrencesNameCache = {}
  }

  toggleEditDOTable() {
    this.editDOTable = !this.editDOTable
  }

  reminderValueChanged({ idx, value }) {
    if (value <= 0) {
      this.reminders[idx].value = 1
    }
  }

  addSection() {
    let section = new SectionFe(IdUtil.next(), null, null, null, [], this.taxonomyInfo)
    section.isTaxonomyConnected = true
    this.sections.push(section)
  }

  deleteSection(section: SectionFe, event) {
    this.stopImmediatePropagation(event)
    this.sections = this.sections.filter((sec) => sec.id != section.id)
  }

  duplicateSection(section: SectionFe, event) {
    this.stopImmediatePropagation(event)
    let newSection = new SectionFe(IdUtil.next(), section.name, section.taxonomyKey, section.taxonomyVersion, [], this.taxonomyInfo)
    if (section.taxonomyKey) {
      newSection.entity = this.taxonomyInfo.entityByKey(section.taxonomyKey)
    }
    for (let question of section.questions) {
      let newQuestion = QuestionTypeConversion.setQuestion(question)
      if (newQuestion.isFollowUpQues) {
        let formulaString = newQuestion.followUpCondition.toFormulaString()
        let index1 = formulaString.indexOf("$"),
          index2 = formulaString.indexOf(".")
        let parentQuesId = formulaString.substring(index1 + 1, index2)
        formulaString = formulaString.substring(index2)
        newQuestion.parentQuestionId = parentQuesId
        let parentQues = section.questions.find((q) => q.id == parentQuesId)
        parentQues.childrenNumber += 1
        if (parentQues instanceof QuestionSingleChoiceFe) {
          let optionStartIndex = formulaString.indexOf("."),
            optionEndIndex = formulaString.indexOf(" ", optionStartIndex)
          let optionId = formulaString.substring(optionStartIndex + 1, optionEndIndex)
          let option = parentQues.options.find((o) => o.id == optionId)
          newQuestion.followUpOptions = option
        } else if (parentQues instanceof QuestionMultipleChoiceFe) {
          let optionStartIndex = formulaString.indexOf(".")
          while (optionStartIndex != -1) {
            optionStartIndex = formulaString.indexOf(".")
            let optionEndIndex = formulaString.indexOf(" ", optionStartIndex)
            let optionId = formulaString.substring(optionStartIndex + 1, optionEndIndex)
            formulaString = formulaString.substring(optionEndIndex + 1)
            let option = parentQues.options.find((o) => o.id == optionId)
            if (option) {
              if (newQuestion.followUpOptions instanceof Array) {
                newQuestion.followUpOptions.push(option)
              } else {
                newQuestion.followUpOptions = [option]
              }
            }
          }
        }
      }
      if (newQuestion instanceof QuestionDataTableFe && question instanceof QuestionDataTableFe) {
        newQuestion.taxonomyInfo = this.taxonomyInfo
        newQuestion.setQuestionFromOld(question, section)
      }
      if (newQuestion.mappedToColKeys && newSection.entity) {
        newQuestion.setMappedToCols(newSection.entity)
      }
      if (newQuestion instanceof QuestionDateFe && newQuestion.mappedToColKeysRange) {
        newQuestion.setMappedToColsRange(newSection.entity)
      }
      newQuestion.sectionId = newSection.id
      newQuestion.confirmMapping()
      newSection.questions.push(newQuestion)
    }
    newSection.editTaxonomy = false
    this.sections.push(newSection)
  }

  hasDataTableQues(section: SectionFe) {
    let ques = section.questions.find((q) => this.requestService.isDataTableQues(q))
    return ques
  }

  stopImmediatePropagation(event) {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()
  }

  async openConnectedActionsModal() {
    if (!this.saveAsDraft) {
      let isValid = this.isForm3Valid()
      if (!isValid) {
        return
      }
    }
    if (this.categoryService.totalChanges() > 0) {
      this.openModal(this.confirmDeploy, "modal-md")
    } else {
      this.handleRequest()
    }
  }

  handleRequest() {
    if (this.saveAsDraft) {
      if (this.isFromDraft) {
        this.openModal(this.confirmSaveAsDraft, "modal-md")
      } else {
        this.saveDraft(true)
      }
    } else {
      this.openModal(this.confirmSendRequest, "modal-md")
    }
  }

  async deployTaxonomy() {
    this.loadingInProgress = true
    await this.categoryService.deploy()
    this.closeModal()
    this.handleRequest()
    this.loadingInProgress = false
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits
  }

  getMeasurementType(question: QuestionFe) {
    const includeMeasurementTypes = new Set()
    if (question.mappedToCols) {
      question.mappedToCols.forEach((col) => {
        if (col.unit) includeMeasurementTypes.add(col.unit.measurementType)
      })
    }
    return includeMeasurementTypes
  }

  setUnit(question: QuestionNumberFe, event: UnitFe) {
    question.unit = event.symbol
    question.measurementKey = event.measurementType
  }

  isConnectedAction() {
    return this.displayService.openTab != "CONNECTED_ACTIONS" && this.categoryService.totalChanges() > 0
  }

  sameMeasurementType(col: TaxonomyAttributeFe, question: QuestionFe) {
    if (question instanceof QuestionEmissionFactorFe) {
      if (question.mappedToCols && question.mappedToCols[0]) {
        if (col.emissionFactors[0].value && question.mappedToCols[0].emissionFactors[0].value) {
          const colSourceUnit = _.find(this.units, { symbol: col.emissionFactors[0].value["sourceUnit"] })
          const colConversionUnit = _.find(this.units, { symbol: col.emissionFactors[0].value["conversionUnit"] })
          const mapppedToColSourceUnit = _.find(this.units, { symbol: question.mappedToCols[0].emissionFactors[0].value["sourceUnit"] })
          const mapppedToColConversionUnit = _.find(this.units, { symbol: question.mappedToCols[0].emissionFactors[0].value["conversionUnit"] })
          return colSourceUnit?.measurementType == mapppedToColSourceUnit?.measurementType && colConversionUnit?.measurementType == mapppedToColConversionUnit?.measurementType
        }
      }
    } else if (question instanceof QuestionNumberFe) {
      if (question.mappedToCols && question.mappedToCols[0]) {
        return col.unit?.measurementType == question.mappedToCols[0].unit?.measurementType
      }
    }
    return true
  }

  isDraftExists(): boolean {
    return !!this.reqGroupToBeDuplicated
  }
}
