import { RequestEntryDataGridIntegrationService } from "src/app/components/data-suppliers/data-supplier-request/RequestEntryDataGridIntegrationService";
import { TaskDataGridIntegrationService } from "src/app/components/data-suppliers/data-supplier-task/TaskDataGridIntegrationService";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { QUESTION_TYPE } from "../../request/questionnaire/QuestionTypeFe";
import { AnswerFe } from "./AnswerFe";

export class AnswerDataTableFe extends AnswerFe{
  dataStorageId: string
  status: string
  
  isTableOpen : boolean
  dataTable: TableDataFe
  dataGridService: RequestEntryDataGridIntegrationService | TaskDataGridIntegrationService
  isLoading = false;

  constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    dataStorageId: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.DATA_TABLE,
      comments
    )
    this.dataStorageId = dataStorageId;
    this.isTableOpen = false;
    this.dataTable = null;
  }

  public static fromTransfer(transfer: any): AnswerDataTableFe {
    let answer = new AnswerDataTableFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.dataStorageId
    )
    return answer;
  } 

  public hasAnyValue(): boolean {
    return !!this.dataStorageId
  }

}
