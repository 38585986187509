<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">

    </div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 class=" text-uppercase">{{locale('locale_key.pages.clients.title')}}</h3>
           <!-- dropdown menu instead of toolbar for smaller pages-->
      <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
        <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg;"></i>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
          <ng-container *ngFor="let toolbarGroup of pageToolbar ; let groupIndex=index">
            <div>
              <ng-container *ngFor="let toolbarButton of toolbarGroup">
                <li role="menuitem">
                  <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)"
                  *ngIf="toolbarButton.visible()"
                  >
                    <i [class]="toolbarButton.icon"></i>
                    <span>{{ toolbarButton.longLabel }}</span>
                  </button>
                </li>
              </ng-container>
              <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
            </div>
          </ng-container>
        </ul>
      </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
             
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="loader-wrapper mt-5" *ngIf="isLoading">
          <div class="loader-container">
            <div class="ball-grid-pulse loader-primary">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="d-flex h-100 flex-row me-2" *ngIf="!isLoading">
          <div class="w-100 pt-1 me-2" style="overflow: auto !important; max-height: 80vh">
              <div id="accordionWrap1" role="tablist" aria-multiselectable="true" class="card px-3 pt-3">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12 text-start">
                    <h4 class="ps-2 mt-4 mb-0 pb-0">{{locale('locale_key.pages.clients.main_table.title')}}</h4>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12 text-end">
                    <input class="form-control mt-3 w-50 me-2 ms-auto" type="text" placeholder="{{locale('locale_key.general.search')}}" [(ngModel)]="searchKeyword" (input)="filterCompanies()" 
                    [ngClass]="{'w-100 mb-2': screenSize.isSmallerSize()}"/>
                  </div>
                </div>
                <div class="accordion accordion-icon-rotate mb-2" *ngFor="let company of filteredMgmtCompanies; let i = index" (click)="setSelectedCompany({company,idx:i})">
                  <a [id]="'item'" class="p-0 collapsed" data-bs-toggle="collapse" href="#accordion{{ i }}" aria-expanded="true" attr.aria-controls="accordion{{ i }}">
                    <div class="row mx-auto w-100 row-design my-1 py-3 flex-wrap">
                      <div class="col-xl-2 col-lg-2 col-sm-6 col-md-2 col-6">
                        <div class="d-flex flex-column justify-content-center h-100">
                          <!-- <i class="la la-building building-icon"></i>  -->
                          <small class="text-muted">{{locale('locale_key.pages.clients.main_table.company_column.title')}}</small>
                          <h6 class="text-truncate">{{ company.name | titlecase }}</h6>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-sm-5 col-md-2 col-5 d-flex align-items-center" style="display: inline-flex">
                        <div class="icon-circle me-3">
                          <span>
                            {{
                              company.selectedOption == "SM"
                                ? company.managers.length
                                : company.selectedOption == "DO"
                                ? company.dataOwners.length
                                : company.selectedOption == "DEACTIVATED_DO"
                                ? company.deactivatedDOs.length
                                : company.deactivatedManagers.length
                            }}
                          </span>
                        </div>
                        <h6 class=" my-auto" 
                        [ngClass]="{'text-truncate': !screenSize.isXSmallSize(), 'text-wrap': screenSize.isXSmallSize()}">{{ company.selectedOption == "SM" ? locale('locale_key.pages.clients.main_table.managers_column.title') : company.selectedOption == "DO" ? locale('locale_key.pages.clients.main_table.data_reporters_column.title') : company.selectedOption == "DEACTIVATED_DO" ? locale('locale_key.pages.clients.main_table.user_types_option.deactivated_data_reporters') : locale('locale_key.pages.clients.main_table.user_types_option.deactivated_managers') }}</h6>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-sm-4 col-md-2 col-4 country">
                        <div class="d-flex flex-column justify-content-center h-100 align-content-center">
                          <small class="text-muted">{{locale('locale_key.pages.clients.main_table.country_column.title')}}</small>
                          <h6 class="text-truncate">{{ company.country | titlecase }}</h6>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-sm-4 col-md-2 col-4 industry">
                        <div class="d-flex flex-column justify-content-center h-100 align-content-center">
                          <small class="text-muted">{{locale('locale_key.pages.clients.main_table.industry_column.title')}}</small>
                          <h6 class="text-truncate">{{ company.industry | titlecase }}</h6>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-sm-4 col-md-2 col-4 d-flex justify-content-end drop-down">
                        <div class="d-flex flex-column justify-content-center h-100 align-content-center drop-menu">
                          <select id="drop" class="form-select mt-2 ms-2 me-auto" [(ngModel)]="company.selectedOption">
                            <option *ngFor="let option of userOptions" [value]="option.value">{{ option.label }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xl-1 col-lg-1 col-sm-1 col-md-2 col-1 d-flex justify-content-end">
                        <a [id]="'item' + i" class="p-0 my-auto ms-auto me-3 collapsed" data-bs-toggle="collapse" [href]="'#accordion' + i" aria-expanded="true" [attr.aria-controls]="'accordion' + i" style="max-height: 75px">
                          <i class="la la-angle-down float-end" style="color: black"></i>
                        </a>
                        <div class="btn-group">
                          <div class="description-btn d-flex justify-content-center" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="la la-ellipsis-h fs-2 my-auto" style="rotate: 90deg"></i>
                          </div>
                          <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openSignInSettings(signInSettings, 'company')"><i class="la la-shield"></i> {{locale('locale_key.pages.clients.button.sign_in_methods')}}</button>
                            <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(duplicateCompanyModal, 'modal-lg')"><i class="la la-list-alt me-1 mt-2"></i> {{locale('locale_key.pages.clients.button.duplicate_company')}}</button>
                            <div *ngIf="screenSize.isSmallerSize()">
                              <li class="divider dropdown-divider"></li>
                              <!-- <li class="dropdown-item" *ngFor="let item of userOptions;">ss</li> -->
                              <ng-container *ngFor="let option of userOptions; let j = index">
                                <div>
                                  <div class="d-flex flex-row justify-content-start align-content-center my-auto">
                                    <a class="dark-text me-3 ms-1" 
                                      (click)="(company.selectedOption === 'SM' && company.managers?.length > 0) ||
                                      (company.selectedOption === 'DO' && company.dataOwners?.length > 0) ||
                                      (company.selectedOption === 'DEACTIVATED_DO' && company.deactivatedDOs?.length > 0) ||
                                      (company.selectedOption === 'DEACTIVATED_SM' && company.deactivatedManagers?.length > 0) ? isCollapsed[i] = true : isCollapsed[i] = false; company.selectedOption=option.value">
                                      {{ option.label }}                              
                                    </a>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div id="accordion{{ i }}" role="tabpanel" data-bs-parent="#accordionWrap1" attr.aria-labelledby="item" class="collapse bg-lightgray">
                    <div class="card-content" *ngIf="company.selectedOption == 'SM' && company.managers.length + company.managerInvitations.length > 0">
                      <div class="card-body px-1">
                        <div class="row p-3 suppliers-filter btn-add text-white m-1 sticky mt-2">
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 ps-0">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.managers_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-5 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.status_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 d-none d-lg-block invited-on">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.invited_on_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 last-login" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.last_login_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 last-login">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.signin_method_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 last-login">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.mfa_column.title')}}</h5>
                          </div>
                        </div>
                        <div class="row py-3 px-1 m-1 bg-white border-b" *ngFor="let manager of company.managers; let userIdx = index" (click)="setSelectedUser({ user: manager, idx: userIdx })">
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-11 ps-0">
                            <div class="row m-0 p-0 ms-2 flex-nowrap">
                              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                              <p class=" " data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ manager.getName() | titlecase }} ({{ manager.affiliationEmail }})" 
                              [ngClass]="{'text-truncate': !screenSize.isXSmallSize(), 'text-wrap my-auto': screenSize.isXSmallSize()}">
                                <i class="la la-graduation-cap me-1" *ngIf="manager.isMainManager"></i>
                                {{ manager.getName() | titlecase }} ({{ manager.affiliationEmail }})
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-5 col-5 d-none d-sm-block">
                            <p class="badge badge-green" style="width: 100px; padding: 5px">{{locale('locale_key.general.state.connected')}}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 invited-on" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <p>{{ manager.getActivationDate() }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-3 last-login" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <p>{{ manager.getlastLoggedAt() }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 last-login">
                            <div class="my-0 py-0">
                              <img src="../../../assets/img/logos/Logo-Sustainlab.png" class="ms-1 me-2 pt-1" style="height: 30px" *ngIf="isPasswordEnabled(manager)"/>
                              <img src="../../../assets/img/logos/google_logo.png" style="height: 30px; width: 30px" class="mx-50" *ngIf="isGoogleEnabled(manager)"/>
                              <img src="../../../assets/img/logos/microsoft_logo.jpg" class="mx-2 pt-1" style="height: 27px; width: 27px" *ngIf="isMicrosoftEnabled(manager)"/>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-1 col-1 d-flex flex-row justify-content-between m-auto">
                            <a class="my-auto d-no-show">
                              <i class="las la-mobile-alt fs-4 my-auto " [ngClass]="{ green: manager.mfaEnabled }"></i>
                            </a>
                            <div class="dropdown-buttons">
                              <button class="btn btn-outline-dark ms-0 ps-1" type="button" (click)="openModal(loggingAsUser)"><i class="la la-sign-in fs-5 me-1"></i> {{locale('locale_key.pages.clients.clients_table.user_sub_table.button.login_as_user')}}</button>
                            </div>
                            <div class="btn-group" [ngClass]="{'ms-auto': screenSize.isSmallerSize()}">
                              <div class="description-btn d-flex justify-content-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-h fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(assignMainUserModal)" *ngIf="!manager.isMainManager">
                                  <i class="la la-graduation-cap me-1" style="position: relative; top: 3px"></i> {{locale('locale_key.pages.clients.clients_table.managers_sub_table.button.assign_as_main_manager')}}
                                </button>
                                <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(removeMainUserModal)" *ngIf="manager.isMainManager">
                                  <i class="la la-graduation-cap me-1" style="position: relative; top: 3px"></i> {{locale('locale_key.pages.clients.clients_table.managers_sub_table.button.remove_as_main_manager')}}
                                </button>
                                <button class="dropdown-item ms-0 ps-1" type="button" (click)="openSignInSettings(signInSettings, 'user')"><i class="la la-shield"></i> {{locale('locale_key.pages.clients.clients_table.managers_sub_table.button.authentication_settings')}}</button>
                                <div class="dropdown-divider"></div>
                                <button class="dropdown-item ms-0 ps-1" (click)="openModal(deactivateAffModal)"><i class="la la-user me-1" style="color: var(--danger-color)"></i>{{locale('locale_key.pages.clients.clients_table.users_sub_table.button.deactivate_user')}}</button>
                                <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize()">
                                  <button class="dropdown-item ms-0 ps-1" type="button" (click)="openModal(loggingAsUser)"><i class="la la-sign-in"></i> {{locale('locale_key.pages.clients.clients_table.user_sub_table.button.login_as_user')}}</button>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row py-3 px-1 m-1 bg-white border-b" *ngFor="let invitation of company.managerInvitations; let userIdx = index" (click)="selected = invitation">
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-11 ps-0">
                            <div class="row m-0 p-0 ms-2 flex-nowrap">
                              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                              <p class="" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ invitation.getName() | titlecase }} ({{ invitation.managerEmail }})" 
                              [ngClass]="{'text-truncate': !screenSize.isXSmallSize(), 'text-wrap my-auto': screenSize.isXSmallSize()}">
                                {{ invitation.getName() | titlecase }} ({{ invitation.managerEmail }})
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-none d-sm-block">
                            <span class="badge text-white" [ngClass]="invitation.invitationStatus == 'ACTIVE' ? 'badge-turquoise' : 'badge-red'">
                              {{ invitation.getStatus() }}
                            </span>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4 d-none d-md-flex">
                            <p class="mx-3">-</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 d-none d-lg-flex"> - </div>
                          <div class="col-xl-2 col-lg-2 col-md-1 d-none d-md-flex"> - </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 d-flex flex-row justify-content-between">
                            <div class="dropdown-buttons my-auto">
                              <button type="button" class="btn btn-outline-dark" [disabled]="invitation.invitationStatus != 'ACTIVE'" style="max-height: 33.5px" (click)="openModal(confirmCancellation)">{{locale('locale_key.pages.clients.clients_table.users_sub_table.button.cancel_invitation')}}</button>
                            </div>
                            <div class="btn-group ms-auto" *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize()">
                              <div class="description-btn d-flex justify-content-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-h fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item ms-0 ps-1" (click)="openModal(confirmCancellation)">
                                  <i class="la la-exclamation-circle me-1">
                                  </i>{{locale('locale_key.pages.clients.clients_table.users_sub_table.button.cancel_invitation')}}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-content" *ngIf="company.selectedOption == 'DO' && company.dataOwners.length + company.doInvitations.length > 0">
                      <div class="card-body px-1">
                        <div class="row p-3 suppliers-filter btn-add text-white m-1 sticky mt-2">
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 ps-0">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.data_reporters_sub_table.data_reporter_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-5 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.data_reporters_sub_table.partner_company_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 d-no-show">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.data_reporters_sub_table.status_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 no-show">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.data_reporters_sub_table.joined_on_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 no-show">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_sub_table.last_login_column.title')}}</h5>
                          </div>
                        </div>
                        <div class="row py-3 px-1 m-1 bg-white border-b" *ngFor="let owner of company.dataOwners; let userIdx = index" (click)="setSelectedUser({ user: owner, idx: userIdx })">
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-11 ps-0">
                            <div class="row m-0 p-0 ms-2 flex-nowrap">
                              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                              <p class="" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ owner.getName() | titlecase }} ({{ owner.affiliationEmail }})"
                              [ngClass]="{'text-wrap': screenSize.isXSmallSize(), 'text-truncate': !screenSize.isXSmallSize()}">
                                {{ owner.getName() | titlecase }} ({{ owner.affiliationEmail }})
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-5 d-none d-sm-block">
                            <p>{{ owner.supplierCompanyname ? owner.supplierCompanyname : (company.name | titlecase) }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 d-no-show">
                            <p class="badge badge-success" style="width: 100px; padding: 5px">{{locale('locale_key.general.state.connected')}}</p>
                          </div>

                          <div class="col-xl-2 col-lg-2 no-show">
                            <p>{{ owner.getActivationDate() }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 no-show">
                            <p>{{ owner.getlastLoggedAt() }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-1 col-1 responsive-icon d-flex flex-row justify-content-between m-auto">
                            <div class="dropdown-buttons my-auto">
                              <button type="button" class="btn btn-outline-dark ms-0 ps-1" (click)="openModal(loggingAsUser)"><i class="la la-sign-in fs-5 me-1"></i>{{locale('locale_key.pages.clients.clients_table.user_sub_table.button.login_as_user')}}</button>
                            </div>
                            <div class="btn-group ms-auto">
                              <div class="description-btn d-flex justify-content-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-h fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item ms-0 ps-1" (click)="openModal(deactivateAffModal)"><i class="la la-user me-1" style="color: var(--danger-color)"></i>{{locale('locale_key.pages.clients.clients_table.users_sub_table.button.deactivate_user')}}</button>
                                <button class="dropdown-item ms-0 ps-1" (click)="openSignInSettings(signInSettings, 'user')"><i class="la la-shield"></i> {{locale('locale_key.pages.clients.clients_table.managers_sub_table.button.authentication_settings')}}</button>
                                <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize()">
                                  <button class="dropdown-item ms-0 ps-1" (click)="openModal(loggingAsUser)">
                                    <i class="la la-sign-in"></i>
                                    {{locale('locale_key.pages.clients.clients_table.user_sub_table.button.login_as_user')}}
                                  </button>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row py-3 px-1 m-1 bg-white border-b" *ngFor="let invitation of company.doInvitations; let userIdx = index" (click)="selected = invitation">
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-11 ps-0">
                            <div class="row m-0 p-0 ms-2 flex-nowrap">
                              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                              <p class="" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ invitation.getName() | titlecase }} ({{ invitation.contactEmail }})" 
                              [ngClass]="{'text-truncate': !screenSize.isXSmallSize(), 'text-wrap':screenSize.isXSmallSize()}">
                                {{ invitation.getName() | titlecase }} ({{ invitation.contactEmail }})
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-4 d-none d-sm-block">
                            <p>{{ invitation.contactCompanyName | titlecase }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 d-no-show">
                            <span class="badge text-white" [ngClass]="invitation.invitationStatus == 'ACTIVE' ? 'badge-turquoise' : 'badge-red'">
                              {{ invitation.getStatus() }}
                            </span>
                          </div>

                          <div class="col-xl-2 col-lg-2 no-show">
                            <p class="mx-3">-</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 no-show">
                            <p class="mx-3">-</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 responsive-icon d-flex flex-row justify-content-between d-none d-md-block">
                            <div class="dropdown-buttons my-auto">
                              <button type="button" class="btn btn-outline-dark" [disabled]="invitation.invitationStatus != 'ACTIVE'" (click)="openModal(confirmCancellation)">{{locale('locale_key.pages.clients.clients_table.users_sub_table.button.cancel_invitation')}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-content" *ngIf="company.selectedOption == 'DEACTIVATED_SM' && company.deactivatedManagers.length > 0">
                      <div class="card-body px-1">
                        <div class="row p-3 suppliers-filter btn-add text-white m-1 sticky mt-2">
                          <div class="col-xl-2 col-lg-2 col-md-2 col-5 ps-0">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.managers_column')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-5">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.status_column')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 invited-on">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.invited_on_column')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 last-login">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.data_reporters_sub_table.deactivated_on_column.title')}}</h5>
                          </div>
                        </div>
                        <div class="row py-3 px- m-1 bg-white border-b" *ngFor="let user of company.deactivatedManagers; let userIdx = index" (click)="setSelectedUser({ user: user, idx: userIdx })">
                          <div class="col-xl-2 col-lg-2 col-md-2 col-5 ps-0">
                            <div class="row m-0 p-0 ms-2 flex-nowrap">
                              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                              <p class="text-truncate" data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ user.getName() | titlecase }} ({{ user.affiliationEmail }})" >
                                {{ user.getName() | titlecase }} ({{ user.affiliationEmail }})
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-5">
                            <p class="badge badge-gray" style="width: 100px; padding: 5px">{{locale('locale_key.general.state.deactivated')}}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 invited-on">
                            <p>{{ user.getActivationDate() }}</p>
                          </div>

                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-3 last-login">
                            <p>{{ user.getDeactivationDate() }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-1 empty">
                            <!-- <p>{{ users.deactivatedOn }}</p> -->
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-2 d-flex flex-row justify-content-between">
                            <div class="dropdown-buttons my-auto">
                              <button type="button" class="btn btn-outline-dark" (click)="inviteAgain(user)"><i class="la la-location-arrow float-start me-1" style="rotate: 90deg"></i>{{locale('locale_key.pages.clients.clients_table.data_deactivated_users_table.button.invite_again')}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-content" *ngIf="company.selectedOption == 'DEACTIVATED_DO' && company.deactivatedDOs.length > 0">
                      <div class="card-body px-1">
                        <div class="row p-3 suppliers-filter btn-add text-white m-1 sticky mt-2">
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 ps-0">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.main_table.deactivated_data_reporters_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-5 d-none d-sm-block">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.deactivated_data_reporters_column.status_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 invited-on" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.deactivated_data_reporters_column.invited_on_column.title')}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 last-login" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <h5 class="text-white">{{locale('locale_key.pages.clients.clients_table.deactivated_data_reporters_column.deactivated_on_column.title')}}</h5>
                          </div>
                        </div>
                        <div class="row py-3 px-1 m-1 bg-white border-b" *ngFor="let user of company.deactivatedDOs; let userIdx = index" (click)="setSelectedUser({ user: user, idx: userIdx })">
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-11 ps-0">
                            <div class="row m-0 p-0 ms-2 flex-nowrap">
                              <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user fs-4 text-white"></i></div>
                              <p class=" " data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ user.getName() | titlecase }} ({{ user.affiliationEmail }})" 
                              [ngClass]="{'text-truncate': !screenSize.isXSmallSize(), 'text-wrap': screenSize.isXSmallSize()}">
                                {{ user.getName() | titlecase }} ({{ user.affiliationEmail }})
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-5 d-none d-sm-block">
                            <p class="badge badge-gray" style="width: 100px; padding: 5px">{{locale('locale_key.general.state.deactivated')}}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 invited-on" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <p>{{ user.getActivationDate() }}</p>
                          </div>

                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-3 last-login" [ngClass]="{'d-none': screenSize.isMediumSize()}">
                            <p>{{ user.getDeactivationDate() }}</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-1 empty">
                            <!-- <p>{{ users.deactivatedOn }}</p> -->
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 col-2 d-flex justify-content-end">
                            <!-- <div class="dropdown-buttons">
                              <button type="button" class="btn btn-outline-dark" (click)="inviteAgain(user)"><i class="la la-location-arrow float-start mr-25" style="rotate: 90deg"></i>Invite again</button>
                            </div>
                            <div class="btn-group ms-auto">
                              <div class="description-btn m-auto" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-h fs-2 ellipsis-mgr my-auto" style="rotate: 90deg"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button type="button" class="dropdown-item ms-0 ps-1" (click)="inviteAgain(user)"><i class="la la-location-arrow float-start mr-25" style="rotate: 90deg; position: relative; top: 3px"></i>Invite again</button>
                                <button class="dropdown-item ms-0 ps-1" type="button"><i class="la la-info"></i> See more details</button>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>               
        </div>       
      </div>
    </div>
  </div>
</div>

<!-- Deactivating user Modal -->
<ng-template #deactivateAffModal>
  <div class="modal-header alert-modal-header">
    <i class="la la-exclamation-circle mt-2 color-red" style="font-size: 2rem; color: red"></i>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 mt-2 me-auto" id="myModalLabel12">{{locale('locale_key.pages.clients.deactivating_modal.title', {'user_name': selectedUser.getName()})}}</h4>

    <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body mb-1 text-justify">
    <h5 *ngIf="selectedUser && selectedCompany">
      {{locale('locale_key.pages.clients.deactivating_modal.description', {'user_name': selectedUser.getName(), 'company_name': selectedCompany.name})}}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadInProgress">
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" (click)="closeModal()">{{locale('locale_key.pages.clients.deactivating_modal.button.cancel')}}</button>
      <button type="button" class="btn primary-button custom-button" (click)="deactivateAff()">{{locale('locale_key.pages.clients.deactivating_modal.button.deactivate')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>

<!-- Main user Modal -->
<ng-template #assignMainUserModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 mt-2 me-auto" id="myModalLabel12">{{locale('locale_key.pages.clients.assign_as_main_manager_modal.title')}}</h4>
    <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body mb-1 text-justify">
    <h5 *ngIf="selectedUser && selectedCompany" >
      {{locale('locale_key.pages.clients.assign_as_main_manager_modal.description', {'user_name': selectedUser.getName(), 'company_name': selectedCompany.name})}}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadInProgress">
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" (click)="closeModal()">{{locale('locale_key.pages.clients.assign_as_main_manager_modal.button.cancel')}}</button>
      <button type="button" class="btn primary-button custom-button" (click)="assignMainManager()">{{locale('locale_key.pages.clients.assign_as_main_manager_modal.button.confirm')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>

<ng-template #removeMainUserModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 mt-2 me-auto" id="myModalLabel12">{{locale('locale_key.pages.clients.remove_as_main_manager_modal.title')}}</h4>
    <button type="button" class="btn close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body mb-1 text-justify">
    <h5 *ngIf="selectedUser && selectedCompany">  
      {{locale('locale_key.pages.clients.remove_as_main_manager_modal.description', {'user_name': selectedUser.getName(), 'company_name': selectedCompany.name})}}

    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadInProgress">
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" (click)="closeModal()">{{locale('locale_key.pages.clients.remove_as_main_manager_modal.button.cancel')}}</button>
      <button type="button" class="btn primary-button custom-button" (click)="removeMainManager()">{{locale('locale_key.pages.clients.remove_as_main_manager_modal.button.confirm')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>

<!-- Duplicate Company -->
<ng-template #duplicateCompanyModal>
  <app-customer-entry mode="duplicate" [company]="selectedCompany"></app-customer-entry>
</ng-template>

<!-- Logging in as user -->
<ng-template #loggingAsUser>
  <div class="modal-header alert-modal-header">
    <i class="la la-sign-out mt-1" style="font-size: 25px"></i>
    <h4 class="modal-title mb-0 pb-0 ms-2 mt-1 me-auto" >{{locale('locale_key.pages.clients.login_modal.title')}}</h4>

    <button type="button" class="btn close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times"></i></span>
    </button>
  </div>
  <div class="modal-body mb-1 text-justify">
    <h5 >
      {{locale('locale_key.pages.clients.login_modal.description', {'user_name': selectedUser.getName(), 'company_name': selectedCompany.name})}}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.general.buttons.cancel')}}</button>
    <button type="button" class="btn primary-button custom-button" (click)="loginAs()">{{locale('locale_key.pages.clients.login_modal.button.login')}}</button>
  </div>
</ng-template>

<ng-template #confirmCancellation>
  <div class="modal-header" style="border-bottom: none">
    <div class="icon-background d-inline mt-1" style="height: 18px; width: 18px; background-color: var(--orange); border-radius: 50%; border: 2px solidtext-white">
      <i class="las la-exclamation-circle text-white p-0" style="font-size: 1.5rem; background-color: transparent; border-radius: 50%; position: relative; left: -2px; top: -1px"></i>
    </div>
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0 me-auto"> {{locale('locale_key.pages.clients.cancel_invitation_modal.title')}}</h4>
    <button type="button" class="btn close" *ngIf="!loadInProgress" (click)="closeModal()">
      <span aria-hidden="true" class="black" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      {{locale('locale_key.pages.clients.cancel_invitation_modal.description', {'user_name': selected.getName()})}}   
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{locale('locale_key.pages.clients.cancel_invitation_modal.button.cancel')}}</button>
      <button type="button" class="btn primary-button custom-button" (click)="cancelInvitation()">{{locale('locale_key.pages.clients.cancel_invitation_modal.button.yes')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadInProgress">
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
      <div style="background-color: #405369"></div>
    </div>
  </div>
</ng-template>

<!-- MFA settings -->
<ng-template #signInSettings>
  <sign-in-setting-modal [type]="signInSettingsType" [selectedUser]="selectedUser" [selectedCompany]="selectedCompany" (closeEvent)="closeSignInSettings()"></sign-in-setting-modal>
</ng-template>

<ng-template #inviteClientModal>
  <app-customer-entry></app-customer-entry>
</ng-template>
<ng-template #inviteManagerModal>
  <app-manager-entry></app-manager-entry>
</ng-template>

