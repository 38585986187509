import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { ChangePasswordFe } from 'src/app/model/org/ChangePasswordFe'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { Subscription } from 'rxjs'
import { Language } from 'src/app/utils/language/Language'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg'
import { ActivatedRoute } from '@angular/router'
import { AffiliationRoleFe } from 'src/app/model/data-suppliers/company/AffiliationRoleFe'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends AbstractLanguageComponent implements OnInit {
  currentPass = new FormControl(null, [Validators.required])
  inProgress = false
  passChange = false
  selectedLang: Language
  flagClass: string = 'flag-icon-se'
  verificationCode: any
  addNewPhone: boolean = true
  private languageSubscription: Subscription
  activeLanguage: Language
  lang: string
  activeTabIndex: number = 0

  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode

  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  PhoneNumberFormat = PhoneNumberFormat
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  })

  pageToolbar = [
    ,
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info-circle',
        actionName: 'toggle_quick_tips',
        visible: () => true,
        disabled: false,
      },
    ],
  ]

  constructor(languageService: LanguageService, private stateService: StateServiceFe, private displayService: DisplayServiceFe, private responsive: ResponsiveService, private loginService: LoginServiceFe, private route: ActivatedRoute) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.tab === 'company-settings') {
        this.activateCompanySettingsTab();
      }
    });
  }

  activateCompanySettingsTab(): void {
    this.activeTabIndex = 1;
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  toggleTips() {
    this.displayService.toggleTips()
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  isDO() {
    return this.stateService.activeWorkspace.affiliationRole == AffiliationRoleFe.DATA_OWNER
  }
}
