import { DataGridServiceInterface } from "src/app/components/projects/data-grid-ui/service/DataGridServiceInterface";
import { FileDataFe } from "../file/FileDataFe";

export class AttachmentFe {
  id: string;
  isTable: boolean = false;
  tableDataGridService: DataGridServiceInterface
  isFile: boolean = false;
  file: FileDataFe;
  isActive = false;
  submissionDate: Date;

  constructor (id: string, dataGridService: DataGridServiceInterface | null, file: FileDataFe | null, submissionDate: Date) {
    this.id = id;
    this.tableDataGridService = dataGridService;
    this.file = file;
    this.submissionDate = submissionDate;
  }
}