<div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
  <h5 class="modal-title text-white">{{locale('locale_key.pages.data_request.create_modal.title')}}</h5>
  <button type="button" class="btn close" (click)="closeModal()" aria-label="Close" *ngIf="!loadingInProgress">
    <span aria-hidden="false" style="font-size: 26px; font-weight: 300;" class="text-white">&times;</span>
  </button>
</div>
<div class="modal-body" style="border-top:none;">
  <div class="col-lg-12 mt-0 pt-0">
    <div class="row px-3 mt-0 pt-0">
      <div class=" px-3 w-100"
        style=" width:100%;position:sticky; top:0;z-index:1000 !important;">
        <h5 class="text-start my-2 dark-text">{{locale('locale_key.pages.data_request.create_modal.desc')}}</h5>
        <div class="d-flex flex-row ms-3 my-3" [ngClass]="{ 'row': screenSize.isXSmallSize() || screenSize.isSmallerSize() || screenSize.isMediumSize()}" >
          <div [ngClass]="{ 'col-12': screenSize.isXSmallSize() || screenSize.isSmallerSize() || screenSize.isMediumSize()}"  (click)="viewNo = 1">
            <input type="radio" [(ngModel)]="type" class=" border-0 fw-normal me-2" id="new" value="new"> 
              <label for="new" class="border-0 fw-normal active dark-text cursor">{{ locale('locale_key.pages.request_creator.option.new')}}</label>
          </div>
          <div [ngClass]="screenSize.isXSmallSize() || screenSize.isSmallerSize() || screenSize.isMediumSize() ? 'col-12' : 'ms-5'" (click)="viewNo = 2">
            <input type="radio" [(ngModel)]="type"  class=" border-0 fw-normal me-2 active" id="existing" value="existing">
              <label for="existing" class=" border-0 fw-normal active dark-text cursor"> {{ locale('locale_key.pages.request_creator.option.existing')}}</label>
          </div>
        </div>
        <div class="d-flex flex-row py-2 bg-selected w-100" style="border: 1px solid #19bfd3" *ngIf="type == 'new'">
           <h6 class="ms-3 my-auto ">{{locale('locale_key.pages.request_creator.new.desc')}}</h6>
        </div>
      </div>
      <div class="row pt-1 px-2 w-100 mx-auto" style="max-height: 150px !important" *ngIf="type == 'existing'">
        <div class="row pt-3">
          <div class="col-md-6 mb-3 col-lg-3">
            <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}" #search (input)="applySearchFilter(search.value)" data-test="search-request-input"/>
          </div>
          <div class="col-md-12 mb-3 btn-group  col-lg-6" dropdown container="body" [insideClick]="true">
            <button type="button" class="form-control filter-button" id="filter-button" dropdownToggle aria-controls="filter-dropdown-menu" style="text-align: left;" data-test="request-filter-dropdown">
              <p class="d-inline m-0"><i class="la la-filter"></i> {{ locale("locale_key.pages.datahub.filter_dropdown") }}</p>
              <p class="text-muted text-truncate col-7  m-0" [title]="getCustomFilterText()"
              *ngIf="customMultipleFilters.length > 0 || selectedStatus"> :{{getCustomFilterText()}} </p>
            </button>
            <div class="ms-3 dropdown-menu" id="filter-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="filter-button" [ngClass]="{'width-dropdown': screenSize.isXSmallSize()}">
              <div class="accordion-body px-0 pt-0">
                <div class="bg-light-gray col pt-3">
                  <div class="d-flex flex-row justify-content-between px-2">
                    <span class="bold">{{ locale("locale_key.pages.datahub.applied_filters") }}</span>
                    <a class=" underline" (click)="clearAllFilters()">{{ locale("locale_key.pages.datahub.button.clear_all_filters") }}</a>
                  </div>
                  <div class="row px-2">
                    <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">{{ locale('locale_key.pages.datahub.filter_none') }}</i>
                    <p class="text-muted text-truncate" *ngIf="customMultipleFilters.length > 0 || selectedStatus">
                      {{getCustomFilterText()}}
                    </p>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
                  <div class="d-flex " [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="CREATED" id="sent" />
                      <label class="form-check-label" for="sent">
                        {{ locale("locale_key.pages.datahub.do_submissions.filter.show_requests_sent") }}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="APPROVED" id="completed" />
                      <label class="form-check-label" for="completed">
                        {{ locale("locale_key.pages.datahub.do_submissions.filter.show_completed_requests") }}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="IN_PROGRESS" id="inProgress" />
                      <label class="form-check-label" for="inProgress">
                        {{ locale("locale_key.pages.datahub.do_submissions.filter.show_in_progress_requests") }}
                      </label>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <a class=" underline" (click)="selectedStatus = null; applyFilters()">{{ locale('locale_key.pages.datahub.button.clear_filter') }}</a>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="accordion" id="accordionExample1">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" 
                        type="button" data-bs-toggle="collapse" data-bs-target="#companyCollapse" aria-expanded="false" aria-controls="companyCollapse" data-test="data-category-section">
                          <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.pages.data_hub.master_table.data_categories.title') }}</span>
                        </button>
                      </h2>
                      <div id="companyCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body scroll" style="max-height:20rem">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 mb-3">
                              <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterEntities(entitySearch.value)" #entitySearch>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-3 text-end">
                              <a class=" underline" (click)="selectAll('entityCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                              <a class=" underline" (click)="deselectAll('entityCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                            </div>
                          </div>
                          <div *ngFor="let item0 of filteredEntities; let i = index">
                            <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item0.key}}' [checked]="isCustomFilterSelected(item0)" (change)="changeCustomFilters($event, item0, 0)">
                            <i class="{{ taxonomyInfo.icon(item0)}} me-2 fs-5" [ngStyle]="{ color: taxonomyInfo.color(item0) }"></i>
                            <label>{{item0.getLabel()}}</label>
                            <div class="ms-3">
                              <div *ngFor="let item1 of taxonomyInfo.childrenSortedByOrdinal(item0.key); let i = index">
                                <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item1.key}}' [checked]="isCustomFilterSelected(item1)" (change)="changeCustomFilters($event, item1, 1)">
                                <label>{{item1.getLabel()}}</label>
                                <div class="ms-3">
                                  <div *ngFor="let item2 of taxonomyInfo.childrenSortedByOrdinal(item1.key); let i = index">
                                    <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item2.key}}' [checked]="isCustomFilterSelected(item2)" (change)="changeCustomFilters($event, item2, 2)">
                                    <label>{{item2.getLabel()}}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" data-test="data-reporter-section">
                          <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.general.user_role.data_reporters') }}</span>
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body scroll" style="max-height:20rem">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 mb-3">
                              <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterDataReporters(contactSearch.value)" #contactSearch>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-3 text-end">
                              <a class=" underline" (click)="selectAll('contactCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                              <a class=" underline" (click)="deselectAll('contactCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                            </div>
                          </div>
                          <div *ngFor="let contact of filteredDataReporters; let i = index">
                            <input type="checkbox" class="form-check-input" class="contactCheckbox" id='company{{i}}' [checked]="isCustomFilterSelected(contact)" (change)="changeCustomFilters($event, contact)">
                            <label class="col-4">{{contact.getName()}}</label>
                            <label class="col-4">{{contact.getCompanyName(stateService.activeWorkspace)}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" data-test="company-section">
                          <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.pages.datahub.do_submissions.button.companies') }}</span>
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body scroll" style="max-height:20rem">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 mb-3">
                              <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterCompanies(companySearch.value)" #companySearch>
                            </div>
                            <div class="col-md-6 col-lg-6 mb-3 text-end">
                              <a class=" underline" (click)="selectAll('companyCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                              <a class=" underline" (click)="deselectAll('companyCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                            </div>
                          </div>
                          <div *ngFor="let company of filteredCompanies; let i = index">
                            <input type="checkbox" class="form-check-input" class="companyCheckbox" id='company{{i}}' [checked]="isCustomFilterSelected(company)" (change)="changeCustomFilters($event, company)">
                            <label>{{company.supplierCompanyname}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
            <button type="button" class="form-control" id="group-button" dropdownToggle aria-controls="group-dropdown-menu" style="text-align: left;" data-test="request-sortBy-dropdown">
              {{ locale('locale_key.pages.datahub.filter_sort_by') }} {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none')}}
            </button>
            <div class="ms-3 dropdown-menu" id="group-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="group-button">
              <ng-container *ngFor='let option of sortedByOptions' >
                <div class="dropdown-divider" *ngIf="option.divider"></div>
                <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
              </ng-container>
            </div>
          </div>
        </div>
      <div class="w-100 px-3">       
        <div class="active card pb-5 mb-5" style="max-height: 400px;overflow: auto;" data-test="request-table">
          <div class="row p-3 suppliers-filter btn-add text-white m-1 m-auto"
            style="width:100% !important;position:sticky; top:0px;z-index:1000 !important;">
            <div class="col-8">
              <h6 class="text-white">{{locale('locale_key.pages.data_request.request.title')}}</h6>
            </div>
            <div class="col-4">
              <h6 class="text-white">{{locale('locale_key.general.state.created')}}</h6>
            </div>
          </div>
          <ng-container *ngFor="let requestGroup of filteredRequestGroups;">
            <div class="row border-shade p-3 suppliers-filter m-1 bg-text-white mb-5 pb-5" *ngIf="!requestGroup.draft" (click)="selectedReqGroup = requestGroup" [ngClass]="{'selected': selectedReqGroup == requestGroup}" data-test="request-table-row">
              <div class="col-8 clickable-div" data-test="request-name-col">
                <p>
                  <span>{{ requestGroup.title}}</span>
                </p>
              </div>
              <div class="col-4" data-test="request-created-date-col">
                <div>{{requestGroup.getDate() }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>

<div class="modal-footer" >
  <ng-container *ngIf="!loadingInProgress">
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{locale('locale_key.general.buttons.cancel')}}</button>
    <button class="btn primary-button custom-button" (click)="create()" data-test="request-create-button">{{locale('locale_key.general.buttons.create')}}</button>
  </ng-container>
  <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
    <div style="background-color: var(--main-dark-color1)"></div>
    <div style="background-color: var(--main-dark-color1)"></div>
    <div style="background-color: var(--main-dark-color1)"></div>
  </div>
</div>