import { AbstractProjectInfoAddFe } from "./AbstractProjectInfoAddFe";
import { PROJECT_TYPE } from "../ProjectTypeFe";

export class CCProjectInfoAddFe extends AbstractProjectInfoAddFe{

  constructor(
    public name: string,
    public draft: boolean,
    public emissionCategoryKeys: string[], 
    public createdAt: string,
  ) {
    super(
      PROJECT_TYPE.CC,
      name, 
      draft,
      createdAt
    )
  }
}
