<div class="d-flex flex-column h-100" style="overflow-y: hidden;">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">

    </div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" [class.ps-2]="screenSize.isSmallerSize()">
        <h3 class="text-uppercase">{{locale('locale_key.pages.emails.title')}}</h3>
           <!-- dropdown menu instead of toolbar for smaller pages-->
      <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
        <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg;"></i>
        </button>
        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
          <ng-container *ngFor="let toolbarGroup of pageToolbar ; let groupIndex=index">
            <div>
              <ng-container *ngFor="let toolbarButton of toolbarGroup">
                <li role="menuitem">
                  <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)"
                  *ngIf="toolbarButton.visible()"
                  >
                    <i [class]="toolbarButton.icon"></i>
                    <span>{{ toolbarButton.longLabel }}</span>
                  </button>
                </li>
              </ng-container>
            </div>
          </ng-container>
        </ul>
      </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i class="fs-4" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
             
      </div>
      <!-- content column-->
      <div class="flex-fill">      
        <div class="d-flex ">
          <div class=" card" style="overflow: hidden;" [ngClass]="{'w-100': screenSize.isXSmallSize()}">         
             <div class="card-body p-0 me-3 mb-5" >
                <div id="accordionWrap1" role="tablist" aria-multiselectable="true" style="max-height: 75vh; overflow-y: auto" class="card px-3 pt-3 mb-0 border-0" *ngIf="!isLoadingData">
                  <div class="accordion accordion-icon-rotate mb-2" *ngFor="let email of emails; let i = index">
                    <a [id]="'item'" class="p-0 collapsed"  data-bs-toggle="collapse" href="#accordion{{i}}" aria-expanded="true" attr.aria-controls="accordion{{i}}" style="max-height: 75px">
                      <div class="row mx-auto w-100 row-design my-2 py-3">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 d-none d-lg-block">
                          <div class="d-flex flex-column justify-content-center h-100">
                            <h6>{{locale('locale_key.pages.emails_table.date_column.title')}}</h6>
                            <small class="text-muted">{{email.date}}</small>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 d-none d-sm-block">
                          <div class="d-flex flex-column justify-content-center h-100 align-content-center">
                            <h6 class="text-muted">{{locale('locale_key.pages.emails_table.from_column.title')}}</h6>
                            <small class="text-muted">{{email.getName()}}</small>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-5">
                          <div class="d-flex flex-column justify-content-center h-100 align-content-center">                                     
                            <h6>{{locale('locale_key.pages.emails_table.company_column.title')}}</h6>                                        
                            <small class="text-muted">{{email.requesterCompanyName}}</small>                                    
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                          <div class="d-flex flex-column justify-content-center h-100 my-auto">
                            <h6>{{locale('locale_key.pages.emails_table.to_column.title')}}</h6>
                            <small class="text-muted">{{email.receiverAddress}}</small>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 col-4 d-none d-sm-block">
                          <div class="d-flex flex-column justify-content-center h-100 align-content-center">
                            <h6>{{locale('locale_key.pages.emails_table.subject_column.title')}}</h6>
                            <small class="text-muted">{{email.title}}</small>
                          </div>
                        </div>
                        <div class="col-1 my-auto float-end">
                          <div class="d-flex flex-row ms-auto h-100 w-100">
                            <i class="la la-angle-down mt-3 ms-auto float-end"></i>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div id="accordion{{i}}" role="tabpanel" data-bs-parent="#accordionWrap1" attr.aria-labelledby="item" class="collapse bg-lightgray">
                      <div class="card-content">
                        <div class="card-body px-1">
                          <form>
                            <div class="form-group">
                              <div class="col-12">
                                <label class="d-block" for="subject">{{locale('locale_key.pages.emails_table.subject')}}:</label>
                                <input class="form-control " readonly [value]="email.title" name="subject"
                                [ngClass]="{'w-75': !screenSize.isXSmallSize(), 'w-100': screenSize.isXSmallSize()}"><br>
                              </div>
                              <br/>
                              <div class="col-12">
                                <iframe *ngIf="email.sanitizedBody" width="600" height="800" [srcdoc]="email.sanitizedBody" frameborder="0"
                                [ngClass]="{'w-75': !screenSize.isXSmallSize(), 'w-100': screenSize.isXSmallSize()}"></iframe>
                            
                                <label *ngIf="!email.sanitizedBody" for="body">{{locale('locale_key.pages.emails_table.content')}}:</label>
                                <textarea *ngIf="!email.sanitizedBody" class="form-control" readonly [value]="email.body" name="body" rows="15"
                                [ngClass]="{'w-75': !screenSize.isXSmallSize(), 'w-100': screenSize.isXSmallSize()}"></textarea><br>
                              </div>
                            </div>
                          </form> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="isLoadingData">
                  <div class="loader-wrapper mt-5">
                    <div class="loader-container">
                      <div class="ball-grid-pulse loader-primary">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
             </div>                   
          </div>              
        </div>       
      </div>
    </div>
  </div>
</div>