<ng-template #addDatapointModal>
  <div class="add_datapoint_modal">
    <div class="header" style="display: flex; height: 46px; padding: 0px 16px; align-items: center; justify-content: space-between; background: var(--primary-colors-primary, #374659)">
      <div class="title" style="color: var(--grey-white, #fff); font-family: Open Sans; font-size: 16px; font-style: normal; font-weight: 400; line-height: normal">
        {{ locale("locale_key.add_data_point.add_new_data_point") }}
      </div>
      <i class="control close las la-times" style="font-size: 18px; color: var(--grey-white, #fff)" (click)="cancelAddDatapoint()"></i>
    </div>
    <div class="body" style="padding: 20px 16px">
      <div class="heading" style="display: flex; align-items: center; margin-bottom: 20px">
        <div class="title" style="margin-right: 10px; white-space: nowrap">
          {{ locale("locale_key.add_data_point.adding_to_data_category.title") }}
        </div>
        <div
          class="breadcrumbs"
          style="flex: 1; display: flex; align-items: center; padding: 8px; border-radius: 4px; border: 1px solid var(--complementary-blue, #2d9bf0); background: var(--complementary-blue-300, #def2f5); flex-wrap: wrap">
          <span style="white-space: nowrap">
            {{ resolveLabel(selectedDataCategory.level_1.label) }}
          </span>
          <span style="margin: 0 8px">></span>
          <span style="white-space: nowrap">
            {{ resolveLabel(selectedDataCategory.level_2.label) }}
          </span>
          <span style="margin: 0 8px">></span>
          <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 200px;">
            {{ resolveLabel(selectedDataCategory.level_3.label) }}
          </span>
        </div>
      </div>
      <div class="section description" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.add_data_point.description.title") }}
          </span>
          <i style="font-size: 20px" class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">{{ locale("locale_key.pages.data_category.description.create.message") }}</div>
        </div>
          <div class="d-flex flex-row" *ngIf="!isLanguageEnglish()">
            <i [class]="getCategoryLanguageFlag()" style="margin-right: 12px"></i>
            <input
              type="text"
              placeholder="{{ locale('locale_key.add_data_point.description.placeholder') }}"
              [(ngModel)]="descriptionActiveLanguage"
              style="font-size: 14px; border: 1px solid var(--primary-colors-secondary, #d3d3d3); background: var(--grey-white, #fff); box-shadow: 0px 0px 0px 0px #cbdafc; width: 100%; height: 38px; padding: 0 12px; outline: none" />
          </div>
         <div
          class="error_box warning-message"
          [style.display]="hasError['descriptionActiveLang'] ? 'block' : 'none'">
          {{ errorMsg.descriptionActiveLang }}
        </div>
          <br *ngIf="!isLanguageEnglish()">
          <div class="d-flex flex-row">
            <i class='fs-4 fi fi-us' style="margin-right: 12px"></i>
            <input
            type="text"
            placeholder="{{ locale('locale_key.add_data_point.description.placeholder') }}"
            [(ngModel)]="descriptionEnglish"
            style="font-size: 14px; border: 1px solid var(--primary-colors-secondary, #d3d3d3); background: var(--grey-white, #fff); box-shadow: 0px 0px 0px 0px #cbdafc; width: 100%; height: 38px; padding: 0 12px; outline: none" />
          </div>
        <div
          class="error_box warning-message"
          [style.display]="hasError['description'] ? 'block' : 'none'">
          {{ errorMsg.description }}
        </div>
      </div>
      <div class="section short_name" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.add_data_point.short_name.title") }}
          </span>
          <i style="font-size: 20px" class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">
            {{
              locale("locale_key.pages.data_category.shortname.create.message")
            }}
          </div>
        </div>
        <div class="d-flex flex-row" *ngIf="!isLanguageEnglish()">
          <i [class]=getCategoryLanguageFlag() style="margin-right: 12px"></i>
          <input
            type="text"
            placeholder="{{ locale('locale_key.add_data_point.short_name.placeholder') }}"
            [(ngModel)]="shortnameActiveLanguage"
            style="font-size: 14px; border: 1px solid var(--primary-colors-secondary, #d3d3d3); background: var(--grey-white, #fff); box-shadow: 0px 0px 0px 0px #cbdafc; width: 100%; height: 38px; padding: 0 12px; outline: none" />  
        </div>
        <div
          class="error_box warning-message"
          [style.display]="hasError['shortnameActiveLang'] ? 'block' : 'none'">
          {{ errorMsg.shortnameActiveLang }}
        </div>
        <br *ngIf="!isLanguageEnglish()">
        
        <div class="d-flex flex-row">
          <i class='fs-4 fi fi-us' style="margin-right: 12px"></i>
          <input
            type="text"
            placeholder="{{ locale('locale_key.add_data_point.short_name.placeholder') }}"
            [(ngModel)]="shortnameEnglish"
            style="font-size: 14px; border: 1px solid var(--primary-colors-secondary, #d3d3d3); background: var(--grey-white, #fff); box-shadow: 0px 0px 0px 0px #cbdafc; width: 100%; height: 38px; padding: 0 12px; outline: none" />  
        </div>
        <div
          class="error_box warning-message"
          [style.display]="hasError['shortname'] ? 'block' : 'none'">
          {{ errorMsg.shortname }}
        </div>
      </div>
      <div class="section data_type" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.add_data_point.data_type.title") }}
          </span>
          <i style="font-size: 20px" class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">
            {{
              locale("locale_key.pages.data_category.data_point.message.yes_no")
            }}
          </div>
        </div>
        <select class="custom-datatype-input" [(ngModel)]="datatypeInput">
          <option value="" disabled selected>
            {{ locale("locale_key.pages.data_request.select_option") }}
          </option>
          <option value="STRING">{{ locale("locale_key.general.data_type.text") }}</option>
          <option value="NUMERIC">{{ locale("locale_key.general.data_type.number") }}</option>
          <option value="DATE">{{ locale("locale_key.general.data_type.date") }}</option>
          <option value="BOOLEAN">{{ locale("locale_key.general.data_type.boolean") }}</option>
          <option value="EMISSION_FACTOR">{{ locale("locale_key.pages.emission.emission_factor") }}</option>
        </select>
        <div
          class="error_box warning-message"
          [style.display]="hasError['datatype'] ? 'block' : 'none'">
          {{ errorMsg.datatype }}
        </div>
      </div>

      <!-- BEGIN : EMISSION FACTOR INPUTS -->
      <div class="section emission_factor mb-4" *ngIf="datatypeInput == 'EMISSION_FACTOR'">
        <div class="name mb-2">
          <span class="label fw-bold me-2">
            {{ locale("locale_key.pages.emission.how_will_emission_factor_be_added") }}
          </span>
          <i class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">{{ locale("locale_key.pages.data_category.you_can_define_specific_conditions") }}</div>
        </div>

        <div class="options">
          <div class="option user" (click)="changeDatapointProvidedBy('user')" *ngIf="datatypeInput == 'EMISSION_FACTOR'" [class.option_selected]="providedByInput == 'user' && datatypeInput == 'EMISSION_FACTOR'">
            {{ locale("locale_key.pages.emission.added_when_entering_data") }}
          </div>
          <div class="option pre_determined" (click)="changeDatapointProvidedBy('pre_determined')" *ngIf="datatypeInput == 'EMISSION_FACTOR'" [class.option_selected]="providedByInput == 'pre_determined' && datatypeInput == 'EMISSION_FACTOR'">
            {{ locale("locale_key.pages.emission.pre_determine_factors_here") }}
          </div>
        </div>

      </div>

      <div class="section providedBy d-flex flex-column align-items-start mb-4" *ngIf="providedByInput == 'user' && datatypeInput == 'EMISSION_FACTOR'">
        <div class="name mb-2">
          <span class="label fw-bold me-2">
            {{ locale("locale_key.pages.data_category.specify_acceptable_measurement_types") }}
          </span>
          <i class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">{{ locale("locale_key.pages.data_category.you_can_define_specific_conditions") }}</div>
        </div>
      
        <select class="measurement-type-input" [(ngModel)]="conversionFactorMeasurementTypeInput">
          <option value="" disabled selected>
            {{ locale("locale_key.pages.data_category.select_conversion_factor.measurement_type") }}
          </option>
          <ng-container *ngFor="let measurementType of getMeasurementTypes()">
            <option [value]="measurementType.key"> {{ measurementType.name }} <span *ngIf="measurementType.defaultUnitName">(e.g. {{measurementType.defaultUnitName  }})</span> </option>
          </ng-container>
        </select>

        
        <div class="error_box warning-message" [style.display]="hasError['conversionFactorMeasurementType'] ? 'block' : 'none'" >
          {{ errorMsg.conversionFactorMeasurementType }}
        </div>

        <span class="per mt-3 mb-3">per</span>

        <select class="measurement-type-input" [(ngModel)]="sourceMeasurementTypeInput">
          <option value="" disabled selected>
            {{ locale("locale_key.pages.data_category.select_source_factor.measurement_type") }}
          </option>
          <ng-container *ngFor="let measurementType of getMeasurementTypes()">
            <option [value]="measurementType.key"> {{ measurementType.name }} <span *ngIf="measurementType.defaultUnitName">(e.g. {{measurementType.defaultUnitName  }})</span> </option>
          </ng-container>
        </select>

        <div class="error_box warning-message" [style.display]="hasError['sourceMeasurementType'] ? 'block' : 'none'">
          {{ errorMsg.sourceMeasurementType }}
        </div>
      </div>

      <div class="section providedBy mb-4" *ngIf="providedByInput == 'pre_determined' && datatypeInput == 'EMISSION_FACTOR'">
        <div class="name mb-2">
          <span class="label fw-bold me-2">
            {{ locale("locale_key.pages.emission.emission_factor_will_be") }}
          </span>
          <i class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">{{ slocale("...") }}</div>
        </div>

        <emission-factor [selectedEF]="emissionFactorInput" isEditable="true" (emissionFactorSelected)="emissionFactorChanged({ emissionFactor: $event })"></emission-factor>

        <div class="error_box warning-message" [style.display]="hasError['emissionFactor'] ? 'block' : 'none'">
          {{ errorMsg.emissionFactor }}
        </div>
      </div>
      <!-- END : EMISSION FACTOR INPUTS -->


      <div class="section unit" style="margin-bottom: 20px" *ngIf="datatypeInput == 'NUMERIC'">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.add_data_point.unit.title") }}
          </span>
          <i style="font-size: 20px" class="info las la-info-circle dropdown-toggle my-auto ms-auto" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
          <div class="dropdown-menu arrow p-2 info-popover">{{ locale("locale_key.pages.data_category.data_point.description.you_will_add") }}</div>
        </div>
        <unit-selector [mode]="'select'" [selectBy]="'symbol'" [selectedUnitContainer]="unitInput" [source]="'add_datapoint_modal'" [customUnits]="getCustomUnits()" [unitsByMeasurementType]="getUnitsByMeasurementType()"></unit-selector>
        <div
          class="error_box"
          [style.display]="hasError['unit'] ? 'block' : 'none'"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.unit }}
        </div>
      </div>
      <div class="info" style="display: flex; align-items: center">
        <i class="info las la-info-circle" style="font-size: 20px; margin-right: 6px"></i>
        <span style="color: var(--grey-black-600, #6b6e80); font-family: Open Sans; font-size: 14px; font-style: italic; font-weight: 400; line-height: normal">
          {{ locale("locale_key.add_data_point.after_data_point_added") }}
        </span>
      </div>
    </div>
    <div class="footer" style="padding: 12px; display: flex; justify-content: flex-end; align-items: center; gap: 16px">
      <div
        class="control cancel_add_datapoint cancel_add_datapoint-custom"
        (click)="cancelAddDatapoint()">
        {{ locale("locale_key.pages.insight_detail.cancel") }}
      </div>
      <div
        class="control confirm_add_datapoint confirm_add_datapoint-custom"
        (click)="confirmAddDatapoint()">
        {{ locale("locale_key.general.buttons.add") }}
      </div>
    </div>
  </div>
</ng-template>
