export class CustomRepeatScheduleFe {
  
  unit: string
  dayOfWeek: string
  monthType: string
  value: number
  customCron: string
  
  constructor(
    unit: string,
    dayOfWeek: string,
    monthType: string,
    value: number,
    customCron: string
  ) {
    this.unit = unit
    this.dayOfWeek = dayOfWeek
    this.monthType = monthType
    this.value = value
    this.customCron = customCron
  }

  public static fromTransfer(transfer: any): CustomRepeatScheduleFe {
    let occurence = new CustomRepeatScheduleFe (
      transfer.unit,
      transfer.dayOfWeek,
      transfer.monthType,
      transfer.value,
      transfer.customCron
    )
    return occurence
  }
}