import { ValueColumnFe } from "../pipeline/ValueColumnFe";
import { InsightFe } from "./InsightFe";
import { KpiRowFe } from "../kpi/KpiRowFe";
import { ValueRowFeNew } from "../pipeline/ValueRowFeNew";
import { ChartSettingFeNew } from "../chart/ChartSettingFeNew";

export class KpiFe2 {
  public id: string;
  public timeField: string;
  public timeScale: string;
  private orderedTimeLables: string[] | undefined = undefined;
  public rows: KpiRowFe[];
  public errorCode: string;

  constructor(values) {
    this.id = values['id'];
    this.rows = values["data"].map(row => KpiRowFe.fromTransfer(row))
    this.timeField = values["time_field"];
    this.timeScale = values["time_scale"];
    this.errorCode = values["errorCode"];
  }

  cloneWithFiltertedData(data) {
    let values = { data: data, time_field: this.timeField, time_scale: this.timeScale }
    return new KpiFe2(values)
  }

  public valuesOfFirstElementToArray(insight: InsightFe): number[] {
    var vals = []
    let onlyElement = this.rows[0].rowValues
    if (insight.definition.dataSeries) {
      insight.definition.dataSeries.forEach((dataSeries) => {
        vals.push(onlyElement[dataSeries.id])
      });
    }
    return vals
  }

  public uniqueTimeValuesOrdered(): string[] {
    if (!this.orderedTimeLables) {
      let timeLabels: string[] = []
      this.rows.forEach((item, d) => {
        if (!timeLabels.includes(item.rowValues[this.timeField])) {
          timeLabels.push(item.rowValues[this.timeField])
        }
      })

      if (this.timeScale == 'week' || this.timeScale == 'month' || this.timeScale == 'quarter') {
        timeLabels.sort((a, b) => {
          if (a == null) {
            return -1
          } else if (b == null) {
            return 1
          } else {
            const as = a.split('\'')
            const bs = b.split('\'')
            const ay = parseInt(as[1], 10);
            const by = parseInt(bs[1], 10);

            if (ay > by) {
              return 1
            } else if (ay == by) {
              let weeks = [...Array(52).keys()].map(k => 'W' + String(k).padStart(2, '0'));
              let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              let quarters = ['Q1', 'Q2', 'Q3', 'Q4']
              let keys = weeks.concat(months).concat(quarters)
              let ls = {}
              keys.forEach((w, i) => ls[w] = i)
              const al = ls[as[0]]
              const bl = ls[bs[0]]
              if (al >= bl)
                return 1
              else
                return -1
            } else {
              return -1
            }
          }

        })
      } else if (this.timeScale == 'year') {
        timeLabels.sort((a, b) => {
          if (a == null) {
            return -1
          } else if (b == null) {
            return 1
          } else {
            if (a >= b)
              return 1
            else
              return -1
          }
        })
      }
      this.orderedTimeLables = timeLabels
    }
    return this.orderedTimeLables
  }

  public applyFilters(chartSetting: ChartSettingFeNew): KpiRowFe[] {
    let rows: KpiRowFe[] = this.rows.filter(row => {
      let satisfies = true
      chartSetting.categories.forEach((category, index) => {
        if (chartSetting.principalCategoryFieldIndex == -1 ||
          (chartSetting.principalCategoryFieldIndex > -1 && chartSetting.principalCategoryFieldIndex != index)) {
          satisfies = satisfies && (row.rowValues[category.field] == category.value)
        }
      })
      if (chartSetting.principalCategoryFieldIndex > -1) {
        satisfies = satisfies && (row.rowValues[this.timeField] == chartSetting.timeValue)
      }
      return satisfies
    })
    return rows
  }

  public selectValuesFilterCategoriesOrderByTime(chartSetting: ChartSettingFeNew, insight: InsightFe): ValueRowFeNew[] {
    let ordredTimeLabels = this.uniqueTimeValuesOrdered()
    let filteredRows: any = this.applyFilters(chartSetting)
    filteredRows = filteredRows.map(row => row.rowValues);
    let sortedrows = filteredRows.sort((i1, i2) => (ordredTimeLabels.indexOf(i1[this.timeField]) < ordredTimeLabels.indexOf(i2[this.timeField]) ? -1 : 1))
    let valueRows: ValueRowFeNew[] = []
    if (insight.definition.dataSeries) {
      sortedrows.forEach(row => {
        let valData = insight.definition.dataSeries.map(ds => row[ds.id]);
        valueRows.push(new ValueRowFeNew(this, valData))
      });
    }
    return valueRows
  }

  public selectValuesFilterCategoriesOrderByCategory(chartSetting: ChartSettingFeNew, insight: InsightFe): ValueRowFeNew[] {
    let categoryIndex = chartSetting.principalCategoryFieldIndex
    let category = chartSetting.options.categoryOptions[categoryIndex]
    let orderedCategoryValues: string[] = category.values
    let filteredRows: any = this.applyFilters(chartSetting)
    filteredRows = filteredRows.map(row => row.rowValues);
    let sortedrows = filteredRows.sort((i1, i2) => (orderedCategoryValues.indexOf(i1[category.field]) < orderedCategoryValues.indexOf(i2[category.field]) ? -1 : 1))
    orderedCategoryValues.forEach((c, index) => {
      if (sortedrows.length <=index || sortedrows[index][category.field]!=c) {
        sortedrows.splice(index, 0, [])
      }
    })
    let valueRows: ValueRowFeNew[] = []
    if (insight.definition.dataSeries) {
      sortedrows.forEach(row => {
        let valData = insight.definition.dataSeries.map(ds => row[ds.id]);
        valueRows.push(new ValueRowFeNew(this, valData))
      });  
    }
    return valueRows
  }


  public projectValuesFilteredCategoriesOrderedByTime(chartSetting: ChartSettingFeNew, insight: InsightFe): ValueColumnFe[] {
    let ordredTimeLabels = this.uniqueTimeValuesOrdered()
    let filteredRows: any = this.applyFilters(chartSetting)
    filteredRows = filteredRows.map(row => row.rowValues);
    let valueRows: ValueRowFeNew[] = []
    if (insight.definition.dataSeries) {
      filteredRows.forEach(row => {
        let valData = insight.definition.dataSeries.map(ds => row[ds.id]);
        valueRows.push(new ValueRowFeNew(this, valData))
      });  
    }

    let datasets: ValueColumnFe[] = []
    insight.definition.dataSeries.forEach((dataSeries, dataSeriesIndex) => {
      let valData = new Array(ordredTimeLabels.length).fill(0);
      let percData = new Array(ordredTimeLabels.length).fill('');
      let unitData = new Array(ordredTimeLabels.length).fill(insight.definition.getUnit());

      filteredRows.forEach((row, rowIndex) => {
        let timeLabel = row[this.timeField]
        let index = ordredTimeLabels.indexOf(timeLabel)
        valData[index] = row[dataSeries.id]
        percData[index] = valueRows[rowIndex].percentageVector[dataSeriesIndex]
      });
      datasets.push(new ValueColumnFe(dataSeries.id, dataSeries.label, valData, percData, unitData))
    });
    return datasets
  }

  public projectValuesFilteredCategoriesOrderedByCategory(chartSetting: ChartSettingFeNew, insight: InsightFe): ValueColumnFe[] {
    let categoryIndex = chartSetting.principalCategoryFieldIndex
    let category = chartSetting.options.categoryOptions[categoryIndex]
    let orderedCategoryValues: string[] = category.values
    let filteredRows: any = this.applyFilters(chartSetting)
    filteredRows = filteredRows.map(row => row.rowValues);
    let valueRows: ValueRowFeNew[] = []
    if (insight.definition.dataSeries) {
      filteredRows.forEach(row => {
        let valData = insight.definition.dataSeries.map(ds => row[ds.id]);
        valueRows.push(new ValueRowFeNew(this, valData))
      });  
    }

    let datasets: ValueColumnFe[] = []
    if (insight.definition.dataSeries) {
      insight.definition.dataSeries.forEach((dataSeries, dataSeriesIndex) => {
        let valData = new Array(orderedCategoryValues.length).fill(0);
        let percData = new Array(orderedCategoryValues.length).fill('');
        let unitData = new Array(orderedCategoryValues.length).fill(insight.definition.getUnit());
        filteredRows.forEach((row, rowIndex) => {
          let categotyValue = row[category.field]
          let index = orderedCategoryValues.indexOf(categotyValue)
          valData[index] = row[dataSeries.id]
          percData[index] = valueRows[rowIndex].percentageVector[dataSeriesIndex]
        });
        datasets.push(new ValueColumnFe(dataSeries.id, dataSeries.label, valData, percData, unitData))
      });  
    }
    return datasets
  }
}
