import { Component, AfterViewChecked } from "@angular/core";
import { AccessibleCompanyFe } from "src/app/model/org/AccessibleCompanyFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { StateServiceFe } from "src/app/services/StateServiceFe";


@Component({
  selector: "workspace-layout",
  templateUrl: "./workspace-layout.component.html",
  styleUrls: ["./workspace-layout.component.scss"],
})
export class WorkspaceLayoutComponent implements AfterViewChecked {
  initCacheInProgress: boolean;
  menuCollapsed: boolean; 
  screenSize: ScreenWidthSizeFe;

  translateX = null;
  transition = null;
  display = null;
  opacity = null;

  constructor(private stateService: StateServiceFe, private responsive: ResponsiveService) {
    
    this.responsive.menuCollapsedSubject.subscribe(collapsed => {
      if (collapsed) {
        this.translateX = 'translateX(-5.5rem)';
        this.display = 'flex';
        this.transition = 'all 1s ease-in-out';
      } else {
        this.translateX = 'translateX(0)';
        this.opacity = 1;
        this.display = 'flex';
        this.transition = 'all 1s ease-in-out';
      }
    })

    if (responsive.menuCollapsed) {
      this.translateX = 'translateX(-5.5rem)';
      this.display = 'flex';
      this.transition = 'all 1s ease-in-out';
    } else {
      this.translateX = 'translateX(0)';
      this.opacity = 1;
      this.display = 'flex';
      this.transition = 'all 1s ease-in-out';
    }

    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });
    this.menuCollapsed = responsive.menuCollapsed;
    
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
    this.screenSize = responsive.currentScreenWidthSize
  }
  async ngOnInit() {
  }

  ngAfterViewChecked(): void {
  }
}
