export class DataGridTaxonomyEntity {
  public key: string;
  public fqn: string;
  public label: string;
  public ordinal: number;

  constructor(key: string, fqn: string, label: string, ordinal: number) {
    this.key = key
    this.fqn = fqn
    this.label = label
    this.ordinal = ordinal
  }

}
